import React, { useEffect, useState } from "react";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import * as Icon from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";

const PartnerForm = ({
  categories,
  formData,
  setFormData,
  isUploading,
  setIsUploading,
  file,
  setFile,
}) => {
  // const [file, setFile] = useState(null);

  const [touchedFields, setTouchedFields] = useState({
    name: false,
  });

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleWebsiteChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (value.trim() === "") {
      updatedValue = "";
    } else if (!value.startsWith("https://")) {
      updatedValue = "https://" + value;
    } else if (
      value.startsWith("https://") &&
      value.indexOf("https://", 1) !== -1
    ) {
      updatedValue = value.replace(/^https:\/\/https:\/\//i, "https://");
    } else if (value === "https://") {
      updatedValue = "";
    }

    setFormData({
      ...formData,
      [name]: updatedValue,
    });
  };

  const handleInputBlur = (field) => {
    setTouchedFields({
      ...touchedFields,
      [field]: true,
    });
  };

  useEffect(() => {
    if (file !== null && file !== "") {
      setIsUploading(true);
      if (file.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile(null);
        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setFormData({ ...formData, image: resp.data?.[0] });
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile(null);
        requiredAlert("Please Select Image File");
        setIsUploading(false);
      }
    }
  }, [file]);

  const uploadFile = (e) => {
    e.preventDefault();
    document.getElementById("real-file1").click();
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  return (
    <div className="">
      <div className="mb-2">
        <label htmlFor="category">Category</label>
        <select
          className="form-control"
          id="category"
          name="category"
          placeholder="select category"
          value={formData?.category}
          onChange={handleInputChange}
        >
          {categories?.map((category) => (
            <option key={category.id} value={category.id}>
              {category?.name}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-2">
        <label htmlFor="name">Partner Name</label>
        <input
          type="text"
          className={`form-control ${
            touchedFields.name && !formData.name ? "is-invalid" : ""
          }`}
          id="name"
          name="name"
          placeholder="John Doe Ltd"
          value={formData?.name}
          onChange={handleInputChange}
          onBlur={() => handleInputBlur("name")}
        />
        {touchedFields.name && !formData.name && (
          <div className="invalid-feedback">Name is required.</div>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="contact_name">
          Contact Person <span className="optional">(Optional)</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="contact_name"
          name="contact_name"
          placeholder="John Doe"
          value={formData?.contact_name}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-2">
        <label htmlFor="contact_email">
          Contact Email <span className="optional">(Optional)</span>
        </label>
        <input
          type="email"
          className="form-control"
          id="contact_email"
          placeholder="abc@gmail.com"
          name="contact_email"
          value={formData?.contact_email}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-2">
        <label htmlFor="website">
          Website <span className="optional">(Optional)</span>
        </label>
        <input
          type="url"
          className="form-control"
          id="website"
          placeholder="https://www.johndoeltd.com"
          name="website"
          value={formData?.website}
          onChange={handleWebsiteChange}
        />
      </div>
      <div className="mb-2">
        <label htmlFor="description">
          Description <span className="optional">(Optional)</span>
        </label>
        <textarea
          className="form-control"
          id="description"
          name="description"
          placeholder="Enter a  description"
          value={formData?.description}
          onChange={handleInputChange}
        />
      </div>

      <div className="mb-2">
        <label htmlFor="file">
          Image <span className="optional">(Optional)</span>
        </label>

        <div className="media">
          <div className="media-aside align-self-start">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : formData?.image
                  ? formData?.image
                  : "https://placehold.jp/90x90.png"
              }
              style={{
                width: "90px",
                height: "90px",
                objectFit: "cover",
              }}
              className="rounded"
              alt=""
            />
          </div>
          <div>
            <input
              id="real-file1"
              name="file"
              type="file"
              hidden="hidden"
              onChange={handleFileChange}
            />
          </div>

          <div className="media-body mt-2">
            {isUploading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Uploading...</span>
              </div>
            ) : (
              <button
                id="custom-button"
                onClick={uploadFile}
                className="btn btn-secondary me-2 btn-sm"
              >
                Upload
              </button>
            )}
            <p className="card-text mt-2">
              <Icon.InfoCircle style={{ color: "currentcolor" }} />
              &nbsp; Allowed Max size of 10Mb
              {/* <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                }
              >
                <Button variant="white" className="mx-2 p-0">
                  <Icon.InfoCircle style={{ color: "currentcolor" }} />
                </Button>
              </OverlayTrigger> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
