import React, { useEffect, useState } from 'react'
import { ReactComponent as EditIcon } from '../../Assets/EditIcon.svg'
import {
  Button,
  Form,
  OverlayTrigger,
  Pagination,
  Row,
  Tooltip
} from 'react-bootstrap'
import { Link, Outlet } from 'react-router-dom'
import * as Icon from 'react-bootstrap-icons'
import { usePackageListQuery } from '../../Slices/spaceApi'

import Loader from '../Loader/Loader'

import PackageUpdate from './PackageUpdate'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'

const PackageList = ({ account, taxRate }) => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  //.log({ account, taxRate })
  const [searchValue, setSearchValue] = useState('')
  const [sidePage, setSidePage] = useState(true)
  const [sideName, setName] = useState('')
  const [list, setList] = useState([])
  const [operateId, setOperateId] = useState(null)
  const [operateObject, setOperateObject] = useState(null)
  const { data, isLoading, isError, error, refetch, isFetching } =
    usePackageListQuery({
      spaceId: spaceId || currentSpaceId,

      name: searchValue
    })
  useEffect(() => {
    if (account?.data && taxRate?.data) {
      //.log({ account: account.data, taxRate: taxRate.data })
    }
  }, [account, taxRate])
  const handleClose = () => {
    setSidePage(state => !state)
  }
  const handleSearchInputChange = e => {
    setSearchValue(e.target.value)
  }
  useEffect(() => {
    refetch()
  }, [searchValue])

  useEffect(() => {
    if (data) {
      setList(data)
      //.log(data)
    }
  }, [data, isLoading, isError, error])

  const sort = (sortIn, sortTo) => {
    if (sortTo === 'id') {
      if (sortIn === 'asc') {
        setList(state => [...state].sort((a, b) => a.id - b.id))
      } else if (sortIn === 'dsc') {
        setList(state => [...state].sort((b, a) => a.id - b.id))
      }
    } else if (sortTo === 'space') {
      if (sortIn === 'asc') {
        setList(state => [...state].sort((a, b) => a.space - b.space))
      } else if (sortIn === 'dsc') {
        setList(state => [...state].sort((b, a) => a.space - b.space))
      }
    } else if (sortTo === 'name') {
      if (sortIn === 'asc') {
        setList(state =>
          [...state].sort((a, b) => a.name.localeCompare(b.name))
        )
      } else if (sortIn === 'dsc') {
        setList(state =>
          [...state].sort((b, a) => a.name.localeCompare(b.name))
        )
      }
    } else if (sortTo === 'rental') {
      if (sortIn === 'asc') {
        setList(state => [...state].sort((a, b) => a.rentalFee - b.rentalFee))
      } else if (sortIn === 'dsc') {
        setList(state => [...state].sort((b, a) => a.rentalFee - b.rentalFee))
      }
    } else if (sortTo === 'value') {
      if (sortIn === 'asc') {
        setList(state =>
          [...state].sort((a, b) => a.quantity * a.value - b.quantity * b.value)
        )
      } else if (sortIn === 'dsc') {
        setList(state =>
          [...state].sort((b, a) => a.quantity * a.value - b.quantity * b.value)
        )
      }
    } else if (sortTo === 'notes') {
      if (sortIn === 'asc') {
        setList(state =>
          [...state].sort((a, b) => a.notes.localeCompare(b.notes))
        )
      } else if (sortIn === 'dsc') {
        setList(state =>
          [...state].sort((b, a) => a.notes.localeCompare(b.notes))
        )
      }
    }
  }

  return (
    <>
      <div className='container-fluid card  m-0 mt-3 p-0 w-100 col-12'>
        {/* <div className='row'>
          <div className='col-md-2'>
            <Form.Control
              type='text'
              placeholder='Search...'
              className='form-control '
              value={searchValue}
              onChange={handleSearchInputChange}
            />
          </div>
        </div> */}
        <div className='table-responsive'>
          {!isFetching ? (
            <table className='table mt-4'>
              <thead>
                <tr>
                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div>#</div>
                      <div
                        className=' d-flex'
                        style={{ flexDirection: 'column' }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort('asc', 'id')}
                          style={{ fontSize: '10px' }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort('dsc', 'id')}
                          style={{ fontSize: '10px' }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div>Package </div>
                      <div
                        className=' d-flex'
                        style={{ flexDirection: 'column' }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort('asc', 'name')}
                          style={{ fontSize: '10px' }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort('dsc', 'name')}
                          style={{ fontSize: '10px' }}
                        />
                      </div>
                    </div>
                  </th>

                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div>Account</div>
                      <div
                        className=' d-flex'
                        style={{ flexDirection: 'column' }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort('asc', 'value')}
                          style={{ fontSize: '10px' }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort('dsc', 'value')}
                          style={{ fontSize: '10px' }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      <div>Tax Rate</div>
                      <div
                        className=' d-flex'
                        style={{ flexDirection: 'column' }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort('asc', 'notes')}
                          style={{ fontSize: '10px' }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort('dsc', 'notes')}
                          style={{ fontSize: '10px' }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className='d-flex align-items-center sort heading'
                      style={{ justifyContent: 'space-between' }}
                    >
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isFetching ? (
                  <>
                    <Loader />
                  </>
                ) : isError ? (
                  <>
                    <tr>
                      <td>{error}</td>
                    </tr>
                  </>
                ) : (
                  <>
                    {list?.length === 0 ? (
                      <>
                        <tr>
                          <td>No Data</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {list?.map((data, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td className='serial bold'>#{data?.id}</td>
                              <td className='table-avatar bold'>
                                <div className='d-flex flex-column'>
                                  {' '}
                                  <h6 className=' table-avatar bold  text-nowrap mb-0'>
                                    {data?.name}
                                  </h6>
                                </div>
                              </td>

                              <td className='table-avatar'>
                                {data?.account && account?.data
                                  ? (
                                      account?.data?.accounts?.find(
                                        item => item.id === data?.account
                                      ) || {}
                                    ).name || '-'
                                  : '-'}
                              </td>
                              <td className='table-avatar '>
                                {' '}
                                {data?.taxRate && taxRate?.data
                                  ? (
                                      taxRate?.data?.taxRates?.find(
                                        item => item.id === data?.taxRate
                                      ) || {}
                                    ).name || '-'
                                  : '-'}
                              </td>
                              <td className='d-flex flex-nowrap'>
                                <div>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip id='tooltip'>
                                        Edit Package
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant='white'
                                      className='p-0 mx-2'
                                      onClick={() => {
                                        setName('update')
                                        setOperateId(data?.id)
                                        setOperateObject(data)
                                        setSidePage(true)
                                      }}
                                    >
                                      <EditIcon />
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          ) : (
            <Loader />
          )}
        </div>
      </div>
      {sidePage && sideName === 'update' && operateId && operateObject ? (
        <>
          <PackageUpdate
            handleClose={handleClose}
            operateId={operateId}
            account={account}
            taxRate={taxRate}
            packageData={operateObject}
          ></PackageUpdate>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default PackageList
