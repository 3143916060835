import React, { useEffect, useMemo } from "react";
import "./Dashboard.css";
import { Bar, Doughnut, Line, Pie, Radar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { arrowSvg } from "../../Assets/InvoiceSvgs/Icons";
import { User } from "../../Assets/MemberSvgs/Icon";
import * as Icon from "react-bootstrap-icons";
import ProgressCard from "./ProgressCard";
import {
  Dollar,
  FoodCard,
  Package,
  Tick,
  Wallet,
} from "../../Assets/DashboardSvgs/Icon";
import {
  useGetAllUpComingBookingQuery,
  useGetSpaceDashboardQuery,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import Chart from "react-apexcharts";
import { useGlobalContext } from "../../Context/GlobalContext";
import PermissionPage from "../MainShared/PermissionPage";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DashboardBookingModal from "./DashboardBookingModal";
import { useSortableData } from "../../utils/SortingFunction";
// import { convertUTCTimestampToLocal } from "../../utils/convertUTCTimestampToLocal";
ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const {
    InvoicesPermissions,
    bookingsPermissions,
    setInvoiceStatus,
    setDashBoardTitle,
  } = useGlobalContext();
  const [dashboardCheck, setDashboardCheck] = useState(false);

  const { data: dashboardData, isLoading } = useGetSpaceDashboardQuery(
    spaceId || currentSpaceId
  );

  useEffect(() => {
    if (!dashboardData?.users > 0) {
      setDashBoardTitle("Welcome to Dashboard");
    } else {
      setDashBoardTitle("Dashboard");
    }
  }, [dashboardData]);
  const { isLoading: loading, data: FetchUpComingBooking } =
    useGetAllUpComingBookingQuery({
      spaceId: spaceId || currentSpaceId,
      past: "False",
    });
  const { items, requestSort, sortConfig } = useSortableData(
    FetchUpComingBooking?.bookingss || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  useEffect(() => {
    if (dashboardData) {
      setDashboardCheck(true);
    }
  }, [dashboardData]);
  const [showModal, setShowModal] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [dataFinalized, setDataFinalized] = useState(false);
  useEffect(() => {
    if (
      (!dataFinalized && !isLoading && !loading) ||
      dashboardData?.pending_revenue > 0 ||
      dashboardData?.total_revenue > 0
    ) {
      setDataFinalized(true);
    }
  }, [isLoading, dashboardData]);

  const data = useMemo(() => {
    return {
      labels: ["Total Revenue", "Pending Revenue"],
      datasets: [
        {
          data: [
            dashboardData?.total_revenue?.toFixed(2),
            dashboardData?.pending_revenue?.toFixed(2),
          ],
          backgroundColor: ["rgb(40, 199, 111)", "rgba(54, 162, 235, 0.7)"],
          hoverBackgroundColor: ["rgb(40, 199, 111)", "rgba(54, 162, 235, 1)"],
        },
      ],
    };
  }, [dashboardData]);
  const options = {
    legend: null,
  };
  const [chartData, setChartData] = useState({
    series: [79],
    options: {
      chart: {
        height: 200,
        type: "radialBar",
        offsetY: -50,
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: "16px",
              color: undefined,
              offsetY: 80,
            },
            value: {
              offsetY: 40,
              fontSize: "22px",
              color: undefined,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91],
        },
      },
      stroke: {
        dashArray: 2,
      },
      labels: ["Completed Tasks"],
    },
  });
  const [taskCompleted, setTaskCompleted] = useState(0);
  useEffect(() => {
    setTaskCompleted(
      (dashboardData?.ticket?.closedTickets /
        dashboardData?.ticket?.newTickets) *
        100
    );
  }, [dashboardData]);
  useEffect(() => {
    setChartData({
      // series: [parseInt(taskCompleted)],
      series: [parseInt(isNaN(taskCompleted) ? 0 : taskCompleted)],
      options: {
        chart: {
          height: 200,
          type: "radialBar",
          offsetY: -50,
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "16px",
                color: undefined,
                offsetY: 80,
              },
              value: {
                offsetY: 40,
                fontSize: "22px",
                color: undefined,
                formatter: function (val) {
                  return val + "%";
                },
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 2,
        },
        labels: ["Completed Tasks"],
      },
    });
  }, [taskCompleted]);
  function timeStampToTime(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);
    date = date.toLocaleTimeString();
    let split = date.split(":");
    let set = split[2].split(" ");
    let convert = split[0] + ":" + split[1] + " " + set[1];
    return convert;
  }
  // function timeStampToDate(timeStamp) {
  //   let timeMili = timeStamp * 1000;
  //   let date = new Date(+timeMili);

  //   const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  //   let formattedDate = date.toLocaleDateString(undefined, options);

  //   return formattedDate;
  // }
  function timeStampToDate(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formatter = new Intl.DateTimeFormat("en-GB", options);

    let formattedDate = formatter.format(date);

    return formattedDate;
  }

  const navigate = useNavigate();
  const navigateMember = () => {
    navigate("/member/list");
  };
  const navigateBooking = () => {
    navigate("/booking/list");
  };
  const navigateInvoicePaid = () => {
    setInvoiceStatus({ label: "Paid", value: 1 });
    navigate("/invoice/list");
  };
  const navigateInvoicePending = () => {
    setInvoiceStatus({ label: "Un Paid", value: 0 });

    navigate("/invoice/list");
  };
  const [showDoughnut, setShowDoughnut] = useState(false);

  useEffect(() => {
    if (dataFinalized && !isLoading) {
      const timeoutId = setTimeout(() => {
        setShowDoughnut(true);
      }, 2000);

      // Cleanup the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [dataFinalized, isLoading]);

  // if (isLoading) {
  //   return <Loader height={6.6} />;
  // }

  return (
    <div className="container-fluid" style={{ padding: "0px 16px" }}>
      <div className="row match-height">
        <div className="col-lg-7">
          <div
            className="card"
            style={{ border: "1px solid rgba(47, 43, 61, 0.16)" }}
          >
            <div className="card-header">
              <h4 className="card-title mb-0">Statistics</h4>
              <small className=" mb-0 textMuted">Last 30 Days</small>
            </div>
            <div className="card-body">
              <div className="card-header d-block">
                <div className="row">
                  <div className="col-md-4 col-12 mb-3 mb-md-0">
                    <div
                      className="media"
                      style={{ cursor: "pointer" }}
                      onClick={navigateMember}
                    >
                      <div className="media-aside mr-2 align-self-start">
                        <span
                          className="b-avatar badge-light-primary rounded-circle"
                          style={{ width: "48px", height: "48px" }}
                        >
                          <span className="b-avatar-custom size">
                            {arrowSvg}
                          </span>
                        </span>
                      </div>
                      <div className="media-body my-auto">
                        <h4 className="mb-0">
                          {dashboardData?.new_users || 0}
                        </h4>
                        <p className="card-text mb-0 fontSmall2">New Users</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3 mb-md-0">
                    <div
                      className="media"
                      style={{ cursor: "pointer" }}
                      onClick={navigateMember}
                    >
                      <div className="media-aside mr-2 align-self-start">
                        <span
                          className="b-avatar badge-light-info rounded-circle"
                          style={{ width: "48px", height: "48px" }}
                        >
                          <span className="b-avatar-custom size ml-2">
                            {User}
                          </span>
                        </span>
                      </div>
                      <div className="media-body my-auto">
                        <h4 className="mb-0">
                          {dashboardData?.active_members || 0}
                        </h4>
                        <p className="card-text fontSmall2 mb-0">
                          Active Members
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mb-3 mb-md-0">
                    <div
                      className="media"
                      style={{ cursor: "pointer" }}
                      onClick={navigateBooking}
                    >
                      <div className="media-aside mr-2 align-self-start">
                        <span
                          className="b-avatar badge-light-danger rounded-circle"
                          style={{ width: "48px", height: "48px" }}
                        >
                          <span className="b-avatar-custom">
                            <Icon.Bookmark />
                          </span>
                        </span>
                      </div>
                      <div className="media-body my-auto">
                        <h4 className="mb-0">
                          {bookingsPermissions[0]?.read
                            ? dashboardData?.upcomming_bookings || 0
                            : "Locked"}
                        </h4>
                        <p className="card-text fontSmall2 mb-0">
                          Upcoming Bookings
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div
            className="card"
            // style={{ border: "1px solid rgba(47, 43, 61, 0.16)" }}
          >
            <div
              // className="card-header"
              className="card-header"
            >
              <h4 className="card-title mb-0">Earnings</h4>
              <small className=" mb-0 textMuted">Last 30 Days</small>
            </div>
            <div className="card-body mt-3 d-flex flex-column flex-md-row">
              {/* <div className="row"> */}
              <div className="col-12 col-md-6">
                <div
                  className="media"
                  style={{ cursor: "pointer" }}
                  onClick={navigateInvoicePaid}
                >
                  <div className="media-aside mr-2 align-self-start">
                    <span
                      className="b-avatar badge-light-info rounded-circle"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <span className="b-avatar-custom size">{Dollar}</span>
                    </span>
                  </div>
                  <div className="media-body my-auto">
                    <h6 className="mb-0 text-nowrap">Total Sales</h6>
                    <small className="text-muted">
                      {InvoicesPermissions[0]?.read
                        ? dashboardData?.total_revenue
                          ? dashboardData?.total_revenue?.toFixed(2) +
                            " " +
                            dashboardData?.currency
                          : "--:--"
                        : "Locked"}
                    </small>
                  </div>
                </div>
                <div
                  className="media mt-3"
                  style={{ cursor: "pointer" }}
                  onClick={navigateInvoicePending}
                >
                  <div className="media-aside mr-2 align-self-start">
                    <span
                      className="b-avatar badge-light-danger rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    >
                      <span className="b-avatar-custom size">
                        <Icon.CreditCard />
                      </span>
                    </span>
                  </div>
                  <div
                    className="media-body my-auto"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <h6 className="mb-0 text-nowrap">Pending Revenue</h6>
                    <small className="text-muted">
                      {InvoicesPermissions[0]?.read
                        ? dashboardData?.pending_revenue
                          ? dashboardData?.pending_revenue?.toFixed(2) +
                            " " +
                            dashboardData?.currency
                          : "--:--"
                        : "Locked"}
                    </small>
                  </div>
                </div>
              </div>
              {InvoicesPermissions[0]?.read ? (
                <div className="col-12 col-md-6 mt-sm-0">
                  {showDoughnut ? (
                    // <Doughnut data={data} style={{ maxHeight: "150px" }} />
                    <Pie
                      data={data}
                      options={options}
                      style={{ maxHeight: "150px" }}
                    />
                  ) : null}
                  {loading || isLoading ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row match-height">
        <div className="col-lg-7">
          <div
            className="card"
            // style={{ border: "1px solid rgba(47, 43, 61, 0.16)" }}
          >
            <div
              // className="card-header"
              className="card-header"
            >
              <h4 className="card-title mb-0">Support Tracker</h4>
              <small className=" mb-0 textMuted">Last 30 Days</small>
            </div>
            <div className="card-body mt-3 d-flex flex-column flex-md-row">
              {/* <div className="row"> */}

              <div className="col-12 col-md-6">
                <div
                  className="ml-3 align-self-start"
                  style={{ lineHeight: "2px" }}
                >
                  <h3 className="ml-4">
                    {dashboardData?.ticket?.totalTickets}
                  </h3>
                  <span className="mb-2 justify-self-center">
                    Total Tickets
                  </span>
                </div>
                <div
                  className="media mt-4"
                  style={{ cursor: "pointer" }}
                  // onClick={navigateInvoicePaid}
                  onClick={() => {
                    navigate("/tickets");
                  }}
                >
                  <div className="media-aside mr-2 align-self-start">
                    <span
                      className="b-avatar badge-light-info rounded-circle"
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    >
                      <span className="b-avatar-custom size">
                        <Icon.Ticket />
                      </span>
                    </span>
                  </div>
                  <div className="media-body my-auto">
                    <h6 className="mb-0 text-nowrap">New Tickets</h6>
                    <small className="text-muted">
                      {dashboardData?.ticket?.newTickets}
                    </small>
                  </div>
                </div>
                <div
                  className="media mt-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/tickets");
                  }}
                >
                  <div className="media-aside mr-2 align-self-start">
                    <span
                      className="b-avatar badge-light-primary rounded-circle "
                      style={{ width: "40px", height: "40px" }}
                    >
                      <span className="b-avatar-custom size ">
                        <Icon.Check2Circle />
                      </span>
                    </span>
                  </div>
                  <div
                    className="media-body my-auto"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <h6 className="mb-0 text-nowrap">Open Tickets</h6>
                    <small className="text-muted">
                      {dashboardData?.ticket?.openTickets}
                    </small>
                  </div>
                </div>
                <div
                  className="media mt-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/tickets");
                  }}
                >
                  <div className="media-aside mr-2 align-self-start">
                    <span
                      className="b-avatar badge-light-warning rounded-circle "
                      style={{ width: "40px", height: "40px" }}
                    >
                      <span className="b-avatar-custom size ">
                        <Icon.Clock />
                      </span>
                    </span>
                  </div>
                  <div
                    className="media-body my-auto"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <h6 className="mb-0 text-nowrap">Response Time</h6>
                    <small className="text-muted">
                      {dashboardData?.ticket?.avgResponseTime} Day
                    </small>
                  </div>
                </div>
              </div>
              {InvoicesPermissions[0]?.read ? (
                <div className="col-12 col-md-6 mb-0">
                  {showDoughnut ? (
                    // <Doughnut data={data} style={{ maxHeight: "150px" }} />
                    <Chart
                      options={chartData.options}
                      series={chartData.series}
                      type="radialBar"
                      width="300"
                    />
                  ) : null}
                  {loading || isLoading ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  ) : (
                    ""
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {!isLoading &&
      dashboardData &&
      (!dashboardData?.amenities > 0 ||
        !dashboardData?.meetingRooms > 0 ||
        !dashboardData?.spacePackages > 1 ||
        !dashboardData?.users > 0) ? (
        <div className="card">
          <ProgressCard dashboardData={dashboardData} currentStep={0} />
        </div>
      ) : null}

      <div className="row match-height">
        <div className="col-md-12">
          {bookingsPermissions[0]?.read ? (
            <div
              className="card card-table"
              // style={{ overflow: "scroll" }}
              style={{
                border: "1px solid rgba(47, 43, 61, 0.16)",
                overflow: "scroll",
              }}
            >
              <div className="card-header pb-0">
                <h4 className="mb-0">Upcoming Bookings</h4>
                <button
                  onClick={navigateBooking}
                  className="btn btn-link custom-link"
                >
                  All Bookings
                </button>
              </div>
              <div className="card-body px-0 pb-0">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col"> Client</th>

                      <th>
                        {" "}
                        <div
                          className="d-flex align-items-center sort"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div>Date</div>
                          <div
                            className="d-flex"
                            style={{ flexDirection: "column" }}
                          >
                            <Icon.ChevronUp
                              onClick={() => requestSort("startTime")}
                              className={getClassNamesFor("startTime")}
                              style={{ fontSize: "10px" }}
                            />

                            <Icon.ChevronDown
                              onClick={() => requestSort("startTime")}
                              className={getClassNamesFor("startTime")}
                              style={{ fontSize: "10px" }}
                            />
                          </div>
                        </div>
                      </th>

                      <th scope="col">Time</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td colSpan={5} className="fs-5">
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          ></div>
                        </td>
                      </tr>
                    )}
                    {!loading && items?.length > 0 ? (
                      items
                        ?.slice()
                        .reverse()
                        ?.map((item, index) => {
                          const {
                            meetingRoomTitle = item.meetingRoom?.title,
                            price = item.meetingRoom?.price,
                            bookingCapacity = item.meetingRoom?.capacity,
                            meetingRoomImagesUrls = item.meetingRoom?.imageUrls,
                            startTime,
                            endTime,
                            id,
                            meetingRoomId = item.meetingRoom?.id,
                            memberId = item.member?.id,
                            cancel = item.cancel,
                          } = item;
                          if (cancel == false) {
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="media">
                                    <div className="media-aside mr-2">
                                      <img
                                        src={meetingRoomImagesUrls}
                                        alt=""
                                        className="rounded-circle"
                                        style={{
                                          width: "32px",
                                          height: "32px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      className="mb-0 table-avatar"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectedBooking(item);
                                        setShowModal(true);
                                      }}
                                    >
                                      <span>
                                        <b> {item?.meetingRoom?.title}</b>
                                      </span>
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  {item.member?.name || item.publicUser.name}
                                </td>

                                <td>{timeStampToDate(startTime)}</td>
                                <td>
                                  {timeStampToTime(startTime)}-
                                  {timeStampToTime(endTime)}
                                </td>
                                <td>
                                  <Badge
                                    bg={
                                      !cancel
                                        ? "badge bg-label-success"
                                        : "badge bg-label-danger"
                                    }
                                  >
                                    {!cancel ? "Active" : "Cancelled"}
                                  </Badge>
                                </td>
                              </tr>
                            );
                          }
                        })
                    ) : (
                      <tr>
                        <td colSpan={5} className="text-left fs-5">
                          No Bookings !
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {selectedBooking?.id && (
                <DashboardBookingModal
                  {...selectedBooking}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              )}
            </div>
          ) : (
            <PermissionPage />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
