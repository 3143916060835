import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useSelector } from "react-redux";
import { useUpdateSpaceMutation } from "../../Slices/spaceApi";
import toast from "react-hot-toast";
import { requiredAlert } from "../../utils/Alerts";
import { Lock2 } from "../../Assets/MemberSvgs/Icon";

const SlotSize = ({ spaceData }) => {
  const { settingsPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();

  const [allowedSlots, setAllowedSlots] = useState(0);

  useEffect(() => {
    setAllowedSlots(spaceData?.slotSize);
  }, [spaceData]);

  const [slotChanged, setSlotChanged] = useState(false);

  const handleSlotChanged = (e) => {
    setSlotChanged(true);
    setAllowedSlots(e.target.value);
  };

  const updateSlotChanged = () => {
    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: { slotSize: parseInt(allowedSlots) },
    });
  };

  const handleClose = () => {
    setSlotChanged(false);
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Slot Size Configured SuccessFully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 1000);

      handleClose();
    }
  }, [resultStats]);
  return (
    <div>
      <div className="card">
        <div className="row p-4">
          <h4>Slot Size Configuration</h4>

          <div className="form-group">
            <label htmlFor="phoneNo">
              Set slot size for meeting rooms bookings and tours. Slot size
              could be of any minutes 1-60.
            </label>
            <input
              type="number"
              className="form-control col-sm-12 col-md-3"
              placeholder="Please add slot size for a booking"
              name="phoneNo"
              value={allowedSlots}
              onChange={handleSlotChanged}
              min={0}
              required
              // pattern="\d{11,15}"
            />

            <div className="row mt-5">
              <div className="col-md-12">
                <button
                  className="btn btn-primary mt-2 "
                  disabled={!slotChanged || !settingsPermissions[0]?.update}
                  onClick={
                    settingsPermissions[0]?.update ? updateSlotChanged : null
                  }
                >
                  {settingsPermissions[0]?.update ? (
                    "Save Changes"
                  ) : (
                    <span>Save Changes{Lock2}</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotSize;
