import React, { useState } from "react";

import {
  Button,
  Col,
  Dropdown,
  Form,
  Nav,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";

import { Percent, Receipt } from "react-bootstrap-icons";
import BookingCredit from "./BookingCredit";
import BookingByMonth from "./BookingByMonth";
import BookingByMember from "./BookingByMember";
import BookingResource from "./BookingResource";
import BookingList from "./BookingList";
import RoomBooking from "./RoomBooking";
import TodayBooking from "./TodayBooking";
import * as Icon from "react-bootstrap-icons";
const BookingShared = () => {
  const [page, setPage] = useState(0);

  return (
    <>
      <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
        <Tabs
          defaultActiveKey="bymember"
          id="justify-tab-example"
          className="mb-3"
          variant="underline"
          justify
        >
          <Tab
            // style={{ marginRight: "5px" }}
            eventKey="bymember"
            title={
              <span className="d-flex align-items-center">
                <Icon.PersonBadge /> &nbsp;Booking By Member
              </span>
            }
          >
            {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <BookingByMember />
          </Tab>
          <Tab
            eventKey="bymonth"
            title={
              <span className="d-flex align-items-center">
                <Icon.BarChartLine /> &nbsp;Booking By Month
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <BookingByMonth />
          </Tab>
          <Tab
            eventKey="credits"
            title={
              <span className="d-flex align-items-center">
                <Icon.CreditCard /> &nbsp;Booking Credits
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <BookingCredit />
          </Tab>
          <Tab
            eventKey="bookings"
            title={
              <span className="d-flex align-items-center">
                <Icon.PersonRolodex /> &nbsp;Bookings
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <BookingList />
          </Tab>
          <Tab
            eventKey="resource"
            title={
              <span className="d-flex align-items-center">
                <Icon.FileEarmarkBarGraph /> &nbsp;Booking Resources
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <BookingResource />
          </Tab>
          <Tab
            eventKey="room"
            title={
              <span className="d-flex align-items-center">
                <Icon.BookmarkCheck /> &nbsp;Room Booking
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <RoomBooking />
          </Tab>
          <Tab
            eventKey="todays"
            title={
              <span className="d-flex align-items-center">
                <Icon.CalendarDay /> &nbsp;Todays Booking
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <TodayBooking />
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default BookingShared;
