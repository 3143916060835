import React from "react";
import { useGlobalContext } from "../../Context/GlobalContext";

const getSlotLabel = (slotSize) => {
  if (slotSize === 30) return "Half Hour Slot";
  if (slotSize === 60) return "Hour Slot";
  if ([10, 15, 25, 29].includes(slotSize)) return `${slotSize} Minutes Slot`;
  if (slotSize > 30) {
    const hours = Math.floor(slotSize / 60);
    const minutes = slotSize % 60;
    const hourLabel = hours > 0 ? `${hours} Hour${hours > 1 ? "s" : ""}` : "";
    const minuteLabel =
      minutes > 0 ? `${minutes} Minute${minutes > 1 ? "s" : ""}` : "";
    return `${hourLabel} ${minuteLabel} Slot`.trim();
  }
  return `${slotSize} Minutes Slot`;
};

const MeetingInputs = () => {
  const { billingOption, slotSize, currentSlotSize } = useGlobalContext();

  console.log(getSlotLabel(slotSize), "label?");
  console.log(slotSize, "slotSize?");
  return [
    {
      id: 1,
      name: "title",
      type: "text",
      placeholder: "Enter Resource Name (1-25 Characters)",
      label: "Name",
      errorMessage: "Name Must Have a Letter or Digit! (1-25 Characters)",
      required: true,
      pattern: "^(?=.*[A-Za-z0-9]).{1,25}$",
    },
    {
      id: 2,
      name: "capacity",
      type: "number",
      placeholder: "150",
      label: "Capacity",
      min: 1,
      errorMessage: "Add Valid Capacity!",
      required: true,
    },
    {
      id: 3,
      name: "price",
      type: "number",
      placeholder: "1000",
      label:
        billingOption === 1
          ? `Price [${getSlotLabel(slotSize)}]`
          : `Coins [${getSlotLabel(slotSize)}]`,
      min: 0,
    },
  ];
};

export default MeetingInputs;
