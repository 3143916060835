import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  useBookingSlotsMutation,
  useCreateBookingMutation,
  useCreateHotdeskBookingMutation,
  useGetAvailableHotDesksQuery,
  useUpdateMeetingBookingMutation,
} from "../../Slices/spaceApi";
import { requiredAlert } from "../../utils/Alerts";
import { DataOfTime, colorStyles } from "../../utils/constant";
import Select from "react-select";
import { useSelector } from "react-redux";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
// import { convertLocalTimestampToUTC } from "../../utils/convertLocalTimestampToUTC";
import * as Icon from "react-bootstrap-icons";
const AddHotDeskBooking = ({
  companies,
  meetingData,
  handleClose,
  Users,
  bookingDate,
  calenderSelect,
  selectedTime,
}) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [createBooking, resultStats] = useCreateHotdeskBookingMutation();
  const [updateBooking, resultStats2] = useUpdateMeetingBookingMutation();

  const [apiSpinner, setApiSpinner] = useState(false);
  const [meetingRoomId, setMeetingRoomId] = useState(null);
  const [meetingSlots, setMeetingSlots] = useState([]);
  const [slotError, setSlotError] = useState("");
  const [bookingId, setBokingId] = useState("");
  const [rows, setRows] = useState([
    {
      name: "",
    },
  ]);
  const utcTime = new Date();

  const localTime = new Date(
    utcTime.getTime() - utcTime.getTimezoneOffset() * 60000
  );

  const minute = localTime.getMinutes();

  if (minute % 30 !== 0) {
    const diff = minute < 30 ? 30 - minute : 60 - minute;
    localTime.setTime(localTime.getTime() + diff * 60 * 1000);
  }
  const [data, setData] = useState({
    capacity: 1,
    date: calenderSelect ? bookingDate : getCurrentDate(),
    startTime: calenderSelect
      ? Math.floor(new Date(bookingDate).setHours(0, 1, 0, 0) / 1000)
      : Math.floor(new Date().setHours(0, 1, 0, 0) / 1000),
    // startTime: bookingDate,
    endTime: calenderSelect
      ? Math.floor(new Date(bookingDate).setHours(23, 59, 59, 999) / 1000)
      : Math.floor(new Date().setHours(23, 59, 59, 999) / 1000),
    paidByCard: false,
    id: companies?.[0]?.id,
  });
  const {
    data: FreeHotDesks,
    isLoading,
    isError,
  } = useGetAvailableHotDesksQuery(
    {
      spaceId: spaceId || currentSpaceId,
      startTime: data?.startTime,
      endTime: data?.endTime,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [availableHotDesks, setAvailableHotDesks] = useState([]);
  useEffect(() => {
    if (!isError && FreeHotDesks) {
      setAvailableHotDesks(FreeHotDesks?.allResources);
    } else {
      setAvailableHotDesks([]);
    }
  }, [isError, FreeHotDesks]);

  function handleDate(e) {
    const name = e.target.name;
    const value = e.target.value;
    const startDate = new Date(value);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(startDate);
    endDate.setHours(23, 59, 59, 999);

    const startTime = startDate?.setHours(0, 1, 0, 0);
    const endTime = endDate.setHours(23, 59, 59, 999);
    setData((prev) => ({
      ...prev,
      [name]: value,
      startTime: Math.floor(startTime / 1000),
      endTime: Math.floor(endTime / 1000),
    }));
  }

  // useEffect(() => {
  //   if ((companies && companies.length > 0) || (Users && Users.length > 0)) {
  //     setData({ ...data, id: companies?.[0]?.id || Users?.[0]?.id });
  //   }
  // }, [companies, Users]);

  const handleMeetingRoomId = (e) => {
    setMeetingRoomId(e.id);
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const [visitorModal, setVisitorModal] = useState(false);

  const handleCompany = (e) => {
    setData({ ...data, id: e.id });
  };

  const postMeetingPackage = (e) => {
    e.preventDefault();

    let capacity = data?.capacity;
    capacity = +capacity;
    let startTime = data?.startTime;
    let date = data?.date;
    let endTime = data?.endTime;
    let member = +data?.id;
    let paidByCard = data?.paidByCard;
    let meetingRoom = meetingRoomId;
    // let meetingRoom = null;

    let updateData = {
      capacity,
      startTime,
      meetingRoom,
      endTime,
      date,
      member,
      paidByCard,
    };

    setApiSpinner(true);
    createBooking({ spaceId: spaceId || currentSpaceId, updateData });
  };

  const postVisitors = (e) => {
    e.preventDefault();
    const filteredData = rows.filter((item) => item.name !== "");

    updateBooking({
      spaceId: spaceId || currentSpaceId,
      id: bookingId,
      updateData: {
        visitor: filteredData,
      },
    });
  };

  useEffect(() => {
    if (resultStats2?.isError) {
      requiredAlert(resultStats2?.error?.data?.message);
    }

    if (resultStats2?.isSuccess) {
      // setTimeout(() => {
      handleClose();
      setVisitorModal(false);
      setBokingId("");
      // }, 1500);
      toast.success("Visitor(s) added successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats2]);

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      setVisitorModal(false);
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }

    if (resultStats?.isSuccess) {
      setBokingId(resultStats?.data?.id);
      //   setVisitorModal(true);
      setApiSpinner(false);

      // handleClose();
      //   setSlotError("");

      setTimeout(() => {
        setData({
          ...data,
          capacity: 1,
          date: getCurrentDate(),
          startTime: new Date().toLocaleString(),
          endTime: new Date(Date.now() + 30 * 60 * 1000).toLocaleString(),
          paidByCard: false,
          id: companies?.[0]?.id,
        });
        setMeetingRoomId(meetingData?.[0]?.id);
      }, 1500);
      toast.success("Hot Desk Booking Created Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      handleClose();
    }
  }, [resultStats]);

  const formatTime = (timeString) => {
    const date = new Date(`2023-10-29T${timeString}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${amOrPm}`;
  };

  const [selectedStart, setSelectedStart] = useState(meetingSlots[0]?.start);
  const [selectedEnd, setSelectedEnd] = useState(meetingSlots[0]?.end);

  //   useEffect(() => {
  //     if (calenderSelect) {
  //       const selectedSlotIndex = meetingSlots.findIndex(
  //         (slot) =>
  //           slot.start === selectedTime.start && slot.end === selectedTime.end
  //       );
  //       if (selectedSlotIndex >= 0) {
  //         setSelectedStart(selectedSlotIndex);
  //         setSelectedEnd(selectedSlotIndex);
  //         setSlotError("");
  //       } else {
  //         setSlotError(
  //           "Selected slot is not available.Please select from the following slots."
  //         );
  //       }
  //     }
  //   }, [calenderSelect, selectedTime, meetingSlots]);

  const handleSlotClick = (index) => {
    // setSlotError("");
    if (selectedStart === null) {
      setSelectedStart(index);
      setSelectedEnd(index);
    } else {
      const previousSlot = meetingSlots[selectedEnd];
      const currentSlot = meetingSlots[index];

      if (
        selectedEnd === index - 1 &&
        previousSlot?.end === currentSlot?.start
      ) {
        setSelectedEnd(index);
      } else {
        setSelectedStart(index);
        setSelectedEnd(index);
      }
    }
  };
  const [selectedOption, setSelectedOption] = useState("company");

  const handleOptionChange = (option) => {
    setData(
      option == "company"
        ? { ...data, id: companies?.[0]?.id }
        : { ...data, id: Users?.[0]?.id }
    );
    setSelectedOption(option);
  };
  const [key, setKey] = useState("home");
  return (
    <div>
      <Modal
        show={true}
        onHide={() => {
          setSlotError("");
          setVisitorModal(false);
          handleClose();
        }}
        backdrop="static"
        keyboard={false}
        id="meetingPopUp"
        size="lg"
      >
        <Modal.Header className="" closeButton>
          <Modal.Title as="h5">Create Hot Desk Booking</Modal.Title>
        </Modal.Header>

        <form onSubmit={postMeetingPackage}>
          <Modal.Body>
            <>
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <div className="d-flex">
                    <div className="col-12 col-md-6">
                      <input
                        type="radio"
                        id="companyOption"
                        name="option"
                        value="company"
                        checked={selectedOption === "company"}
                        onChange={() => handleOptionChange("company")}
                      />
                      <label htmlFor="companyOption" className="ml-2">
                        Company
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="individualOption"
                        name="option"
                        value="individual"
                        checked={selectedOption === "individual"}
                        onChange={() => handleOptionChange("individual")}
                      />
                      <label htmlFor="individualOption" className="ml-2">
                        Individual
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                {selectedOption === "company" && (
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="companySelect" className="mb-1">
                      Company
                    </label>
                    <Select
                      options={companies}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={colorStyles}
                      value={companies?.filter((obj) => obj.id == data?.id)}
                      onChange={handleCompany}
                      id="companySelect"
                    />
                  </div>
                )}

                {selectedOption === "individual" && (
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="individualSelect" className="mb-1">
                      Individual
                    </label>
                    <Select
                      options={Users}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={colorStyles}
                      value={Users?.filter((obj) => obj.id == data?.id)}
                      onChange={handleCompany}
                      id="individualSelect"
                    />
                  </div>
                )}

                {/* <div className="form-group">
                  <label htmlFor="capacity">
                    No of Guests <span className="optional">(Optional)</span>
                  </label>
                  <input
                    type="number"
                    name="capacity"
                    id="capacity"
                    value={data.capacity}
                    onChange={handle}
                    className="form-control"
                    min="0"
                  />
                </div> */}
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="meetingId" className="mb-1">
                    Hot Desk
                  </label>
                  <Select
                    options={availableHotDesks}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    styles={colorStyles}
                    value={availableHotDesks?.filter(
                      (obj) => obj.id == meetingRoomId
                    )}
                    onChange={handleMeetingRoomId}
                    id="meetingId"
                  />
                  {isLoading
                    ? "Loading..."
                    : availableHotDesks?.length == 0
                    ? "No Hot Desk available on this date"
                    : ""}
                </div>
              </div>
              <div className="form-group col-12 col-md-6">
                <label htmlFor="startTime">Select Date:</label>
                <div className="input-group ">
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      style={{
                        borderTopLeftRadius: "5px",
                        borderBottomLeftRadius: "5px",
                      }}
                    >
                      <Icon.Calendar2Check />
                    </span>
                  </div>
                  <input
                    type="date"
                    name="date"
                    id="date"
                    className="form-control"
                    placeholder=""
                    value={data?.date}
                    min={getCurrentDate()}
                    onChange={handleDate}
                  />
                </div>
              </div>

              {/* <div
                className="meeting-slots-container h-25"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {slotError}
                {meetingSlots.length > 0
                  ? meetingSlots?.map((slot, index) => {
                      return (
                        <div
                          key={index}
                          className={`meeting-slot ${
                            index >= selectedStart && index <= selectedEnd
                              ? "selected"
                              : ""
                          }`}
                          style={{
                            border: "1px solid #358EF1",
                            cursor: "pointer",
                            margin: "8px",
                            padding: "6px",
                            borderRadius: "25px",
                            // justifyContent: "center",
                            maxHeight: "38px",
                            // alignItems: "center",
                            backgroundColor:
                              index >= selectedStart && index <= selectedEnd
                                ? "#007bff"
                                : "transparent",
                            color:
                              index >= selectedStart && index <= selectedEnd
                                ? "#fff"
                                : "initial",
                          }}
                          onClick={() => handleSlotClick(index)}
                        >
                          {`${formatTime(slot.start)} - ${formatTime(
                            slot.end
                          )}`}
                        </div>
                      );
                    })
                  : "No Booking Slots Available on This Date."}
              </div> */}
            </>
          </Modal.Body>
          <div className="ml-3 mb-3 mt-4">
            <button
              className="btn btn-primary"
              disabled={
                apiSpinner ||
                //  || !meetingSlots[selectedStart]?.start
                !data?.id
              }
              type="submit"
            >
              Create Booking
              {apiSpinner ? (
                <div
                  className=" spinner-border text-primary"
                  role="status"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "5px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              Cancel
            </button>
          </div>
          {/* <Modal.Footer className="">
              <div className="mt-4">
                <Button variant="primary" disabled={apiSpinner} type="submit">
                  Create Booking
                  {apiSpinner ? (
                    <div
                      className=" text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                <button
                  className="btn btn-secondary ml-3"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer> */}
        </form>

        {/* {visitorModal && (
          <form onSubmit={postVisitors}>
            <Modal.Body>
              <>
                <div className="card-body invoice-padding ">
                  {rows?.map((input, index) => (
                    <div key={index} className="repeater-wrapper my-2">
                      <div className="">
                        <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                          <div className="row w-100 pe-lg-0 pe-3 ">
                            <div className="col-10  my-2">
                              <p className="card-text col-title mb-0">
                                Visitor {index + 1}{" "}
                                <span className="optional">(Optional)</span>
                              </p>
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Visitor Name"
                                  min={1}
                                  name="name"
                                  value={input?.name}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                                {index != 0 ? (
                                  <div className="ml-3">
                                    <p
                                      className="bg-white"
                                      onClick={() => handleRemoveRow(index)}
                                    >
                                      <CancelIcon />
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="">
                  <div className="px-0 mt-3">
                    <button
                      type="button"
                      className="btn btn-primary send-invoice-btn"
                      data-repeater-create=""
                      onClick={handleAddRow}
                    >
                      Add More Visitors
                    </button>
                  </div>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer className="mt-auto">
              <div className="mt-4">
                <Button variant="primary" disabled={apiSpinner} type="submit">
                  Save Visitor(s)
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                <button
                  className="btn btn-secondary ml-3"
                  onClick={(e) => {
                    e.preventDefault();

                    handleClose();
                    setVisitorModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          </form>
        )} */}
      </Modal>
    </div>
  );
};

export default AddHotDeskBooking;
