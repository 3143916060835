import React from "react";
import { Link } from "react-router-dom";
import image from "../../Assets/Images/404.png";

function Page404() {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
    flexDirection: "column",
  };

  const contentStyle = {
    textAlign: "center",
    maxWidth: "400px",
  };

  const imageStyle = {
    maxWidth: "100%",
    maxHeight: "40vh", // Adjust the max height as needed
    marginBottom: "20px",
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h1 style={{ fontSize: "3rem", color: "#ff6347" }}>
          Page Not Found :(
        </h1>
        <p style={{ fontSize: "1.5rem", marginTop: "10px" }}>
          Oops! The page you are looking for doesn't exist.
        </p>
        <Link
          to="/"
          style={{
            display: "block",
            marginTop: "20px",
            fontSize: "1.2rem",
            textDecoration: "none",
            color: "#007bff",
            transition: "color 0.3s",
          }}
        >
          Go Back to Home
        </Link>
        <div className="p-4">
          <img src={image} alt="Not Found" style={imageStyle} />
        </div>
      </div>
    </div>
  );
}

export default Page404;
