import React, { useState } from "react";
import { useDeleteDeactivateMutation } from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";
import { requiredAlert } from "../../utils/Alerts";
import toast from "react-hot-toast";
function DeleteAccount() {
  const { settingsPermissions } = useGlobalContext();

  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState("");
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const { currentSpaceId, spaceId } = useSelector((item) => item.spaceReducer);
  const [deleteAccount, resultStats] = useDeleteDeactivateMutation();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setPassword("");
    setIsButtonActive(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setIsButtonActive(event.target.value.trim().length > 0);
  };

  const handleDeactivate = () => {
    setApiCall(true);
    deleteAccount({
      spaceId: spaceId || currentSpaceId,
      type: 3,
      data: { password: password },
    });
  };
  useEffect(() => {
    if (apiCall) {
      setApiCall(false);
    }
  }, [apiCall]);
  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );
      // requiredAlert(resultStats?.error?.data?.error);
      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setIsChecked(false);
      toast.success(
        "Your account delete request has been received. Please check your email and verify!",
        {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }
      );
    }
  }, [resultStats]);

  return (
    <div className="card p-3">
      <h5>
        Are you sure you want to delete your account? This action cannot be
        undone. If you proceed, your account and all associated data will be
        permanently deleted.
      </h5>
      <div className="form-check mt-3">
        <input
          type="checkbox"
          className="form-check-input"
          id="deleteCheckbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label className="form-check-label" htmlFor="deactivateCheckbox">
          I confirm my account deletion
        </label>
      </div>

      {isChecked && (
        <div className="password-container mt-3">
          <div className="form-group">
            <label htmlFor="passwordField">Password:</label>
            <input
              type="password"
              className="form-control"
              id="passwordField"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
            />
          </div>
          <button
            className="btn bg-danger text-white"
            onClick={settingsPermissions[0]?.update ? handleDeactivate : null}
            disabled={!isButtonActive || !settingsPermissions[0]?.update}
          >
            {settingsPermissions[0]?.update ? (
              "Delete"
            ) : (
              <span>Delete{Lock2}</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default DeleteAccount;
