import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Dropdown, Modal } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import {
  useDeletePartnerMutation,
  useUpdatePartnerMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useSelector } from "react-redux";
import PartnerForm from "./PartnerForm";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
const PartnersList = ({ partners, categories }) => {
  const navigate = useNavigate();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { partnerAndOffersPermissions } = useGlobalContext();
  const [file, setFile] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [deletePartner, resultStats] = useDeletePartnerMutation();
  const [updatePartner, resultStats1] = useUpdatePartnerMutation();
  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const initialFormData = {
    category: categories?.[0]?.id,
    name: "",
    image: "",
    description: "",
    contact_name: "",
    contact_email: "",
    website: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      setFile(null);
      setFormData(initialFormData);
    }, 1000);
  };

  const handleActionClick = (partner) => {
    setSelectedPartner(partner);
    setFormData({ ...partner, category: partner.category.id });
    setShow(true);
  };

  const handleDeletePartner = (partner) => {
    confirmationAlert("Want to delete partner?").then((willDelete) => {
      if (willDelete) {
        deletePartner({
          spaceId: spaceId || currentSpaceId,
          partnerId: partner.id,
        });
      }
    });
  };

  const handleUpdatePartner = (e) => {
    e.preventDefault();

    let dataWithoutImage;

    if (formData?.image.startsWith("https")) {
      // Create a copy of formData excluding the "image" key
      dataWithoutImage = { ...formData };
      delete dataWithoutImage.image;
    } else {
      // If image does not start with "https", use formData as is
      dataWithoutImage = formData;
    }
    setApiSpinner(true);
    updatePartner({
      spaceId: spaceId || currentSpaceId,
      partnerId: selectedPartner.id,
      data: dataWithoutImage,
    });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Partner Deleted Successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);
      const errorMessage = Object?.values(resultStats1?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats1?.isSuccess) {
      setApiSpinner(false);

      // setTimeout(() => {
      handleClose();
      // }, 1500);
      toast.success("Partner Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats1]);

  function getRandomColor() {
    // e.preventDefault();
    const getRandomComponent = () => Math.floor(Math.random() * 128);
    const color = `rgb(${getRandomComponent()}, ${getRandomComponent()}, ${getRandomComponent()})`;
    return color;
  }
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const getRandomColor = () => {
      const getRandomComponent = () => Math.floor(Math.random() * 128);
      return `rgb(${getRandomComponent()}, ${getRandomComponent()}, ${getRandomComponent()})`;
    };

    // Generate dark colors based on the length of the partners array
    const newColors = Array.from({ length: partners?.length }, () =>
      getRandomColor()
    );
    setColors(newColors);
  }, [partners]);

  function getInitials(name) {
    const nameParts = name.split(" ");
    let initials = "";

    for (let i = 0; i < Math.min(2, nameParts.length); i++) {
      initials += nameParts[i].charAt(0).toUpperCase();
    }

    return initials;
  }

  return (
    <div className="d-flex flex-wrap" style={{ padding: "0px 16px" }}>
      {partners?.map((partner, index) => (
        <Card key={index} className="p-2 mr-2" style={{ width: "300px" }}>
          <Card.Body
            style={{ cursor: "pointer" }}
            onClick={() =>
              partnerAndOffersPermissions[0]?.read
                ? navigate(`/${partner.id}/offers`)
                : null
            }
          >
            <Card.Title>
              {partner?.image ? (
                <div className="media">
                  <div className="logo-list">
                    <img
                      className="rounded-circle"
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                      }}
                      src={partner.image}
                      alt={partner.name}
                    />
                  </div>
                </div>
              ) : (
                <div
                  className="rounded-circle"
                  style={{
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: colors[index],
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  {getInitials(partner.name)}
                </div>
              )}
            </Card.Title>
            <Card.Title>
              <b>{partner.name}</b>
            </Card.Title>

            <Card.Subtitle className="mb-2 text-muted">
              {partners?.category?.name}
            </Card.Subtitle>

            {partner.description && (
              <div className="mb-1" style={{ fontSize: "13px" }}>
                {partner.description}
              </div>
            )}

            {partner.contact_name && (
              <div className="mb-1" style={{ fontSize: "13px" }}>
                <b>Contact Name:</b> {partner.contact_name}
              </div>
            )}

            {partner.contact_email && (
              <div className="mb-1" style={{ fontSize: "13px" }}>
                <b>Contact Email:</b> {partner.contact_email}
              </div>
            )}
            <div className="mb-1" style={{ fontSize: "13px" }}>
              <b>ActiveOffers:</b>{" "}
              {partner?.activeOffers > 0 ? (
                <Badge className="ml-2" bg="badge bg-label-success">
                  {partner?.activeOffers}
                </Badge>
              ) : (
                <Badge className="ml-2" bg="badge bg-label-danger">
                  0
                </Badge>
              )}
            </div>

            {partner.website && (
              <Card.Link
                href={partner.website}
                target="_blank"
                style={{ fontSize: "13px" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                Visit Website
              </Card.Link>
            )}
          </Card.Body>
          <div
            className="position-absolute"
            style={{
              top: "10px",
              right: "10px",
            }}
          >
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                variant="none"
                // className="rounded-circle"
              >
                <Icon.ThreeDotsVertical
                  style={{
                    cursor: "pointer",
                    // color: "black",
                  }}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    partnerAndOffersPermissions[0]?.update
                      ? handleActionClick(partner)
                      : null
                  }
                >
                  {partnerAndOffersPermissions[0]?.update ? (
                    "Update"
                  ) : (
                    <span>
                      Update <Lock />
                    </span>
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    partnerAndOffersPermissions[0]?.delete
                      ? handleDeletePartner(partner)
                      : null
                  }
                >
                  {partnerAndOffersPermissions[0]?.delete ? (
                    "Delete"
                  ) : (
                    <span>
                      Delete <Lock />
                    </span>
                  )}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    partnerAndOffersPermissions[0]?.read
                      ? navigate(`/${partner.id}/offers`)
                      : null
                  }
                >
                  {partnerAndOffersPermissions[0]?.read ? (
                    "View Offers"
                  ) : (
                    <span>
                      View Offers <Lock />
                    </span>
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Card>
      ))}

      {selectedPartner?.id && (
        <Modal
          show={show}
          onHide={handleClose}
          id="packagePopUp"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="" closeButton>
            <Modal.Title as="h5">Update Partner</Modal.Title>
          </Modal.Header>
          <form>
            <Modal.Body>
              <PartnerForm
                categories={categories}
                setFormData={setFormData}
                formData={formData}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                file={file}
                setFile={setFile}
              />

              <div className="mt-4">
                {" "}
                <Button
                  variant="primary"
                  onClick={handleUpdatePartner}
                  disabled={!formData?.name || apiSpinner || isUploading}
                >
                  Update{" "}
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                {/* <Button variant="primary" disabled={apiSpinner} type="submit">
                  Save Changes{" "}
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button> */}
                <button
                  className="btn btn-secondary ml-3"
                  disabled={apiSpinner}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
             
            </Modal.Footer> */}
          </form>
        </Modal>
      )}
      {partners?.length <= 0
        ? "Currently, there are no partners. Please add."
        : ""}
    </div>
  );
};

export default PartnersList;
