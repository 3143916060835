import React, { useEffect } from "react";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useDeleteMeetingRoomMutation } from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useState } from "react";

const DeleteMeetingPopUp = ({ items, index }) => {
  const { resourcesPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { id, title } = items[index];
  const [deleteMeetingRoom, resultStats] = useDeleteMeetingRoomMutation();
  const [apiSpinner, setApiSpinner] = useState(false);
  const deleteMeeting = (e) => {
    e.preventDefault();
    confirmationAlert("Do You Want to Delete This Meeting Room?").then(
      (willDelete) => {
        if (willDelete) {
          setApiSpinner(true);
          deleteMeetingRoom({ spaceId: spaceId || currentSpaceId, id: id });
        }
      }
    );
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert(`Meeting Room (${title}) is Deleted Successfully.`);
      setTimeout(() => {
        setApiSpinner(false);
      }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
      setTimeout(() => {
        setApiSpinner(false);
      }, 500);
    }
  }, [resultStats]);

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {resourcesPermissions[0].delete ? (
              "Delete"
            ) : (
              <span>
                Delete <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          onClick={resourcesPermissions[0]?.delete ? deleteMeeting : null}
          disabled={apiSpinner}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </>
  );
};

export default DeleteMeetingPopUp;
