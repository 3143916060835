import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetAllLocationsQuery,
  useGetAllTeamsQuery,
} from "../../Slices/spaceApi";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import DeleteTeam from "./DeleteTeam";
import TeamForm from "./TeamForm";
import Loader from "../Loader/Loader";
import ViewTeam from "./ViewTeam";
import { Badge } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
const Teams = () => {
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];
  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const handlePage = (e) => {
    setitemsPerPage(e);
  };
  const { data: GetTeams, isLoading: loading } = useGetAllTeamsQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: Locations, isLoading: locationLoading } =
    useGetAllLocationsQuery({
      spaceId: spaceId || currentSpaceId,
    });

  const { items, requestSort, sortConfig } = useSortableData(
    GetTeams?.teams || []
  );

  const filteredItems = items
    ?.slice()
    .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (
        val?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
        val?.name?.toLowerCase()?.includes(search.toLowerCase())
      ) {
        return val;
      }
    });

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  if (loading || locationLoading) {
    return <Loader height={6.6} />;
  }

  return (
    <section className="app-user-list1">
      <div className="card">
        <div className="col-12 mt-4">
          <div className="d-flex justify-content-between ">
            <div className="col-12  col-md-4 col-lg-6">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Team"
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
              <TeamForm
                // teamData={{
                //   name: "",
                //   email: "",
                //   profileImage: "",
                //   more_info: "",
                //   location: "",
                //   status: true,
                // }}
                Locations={Locations?.spaces}
                isReadable={false}
                onCloseModal={() => setShowModal(false)} // Close modal from here
                onOpenModal={() => setShowModal(true)}
                showModal={showModal}
                title="Add Team"
                submitButtonText="Add Team"
              />
            </div>
          </div>
        </div>

        <div className="table-responsive mt-3">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Team</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("name")}
                        className={getClassNamesFor("name")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("customer:name")}
                        className={getClassNamesFor("customer:name")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Email</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("subTotal")}
                        className={getClassNamesFor("subTotal")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("subTotal")}
                        className={getClassNamesFor("subTotal")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Details</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("dueDate")}
                        className={getClassNamesFor("dueDate")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("dueDate")}
                        className={getClassNamesFor("dueDate")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Status</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("status")}
                        className={getClassNamesFor("status")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("status")}
                        className={getClassNamesFor("status")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={8} className="fs-5">
                    <Loader />
                  </td>
                </tr>
              )}
              {!loading &&
                (filteredItems?.length > 0 ? (
                  filteredItems?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="media">
                            <div className="media">
                              <div className="media-aside logo-list mr-2">
                                <img
                                  src={
                                    item?.profileImage ||
                                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                  }
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="media-body my-auto d-flex flex-column">
                              <span className="mb-0 table-avatar">
                                <b> {item?.name}</b>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media">
                            <div className="media-body my-auto d-flex flex-column">
                              <span className="mb-0 table-avatar">
                                {item?.email || (
                                  <Badge bg={"badge bg-label-danger"}>
                                    {"No E-mail Provided"}
                                  </Badge>
                                )}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="media">
                            <div className="media-body my-auto d-flex flex-column">
                              <span className="mb-0 table-avatar">
                                {item?.members?.length} Team Members
                              </span>
                            </div>
                          </div>
                        </td>

                        <td>
                          <Badge
                            bg={
                              item?.status === true
                                ? "badge bg-label-success"
                                : "badge bg-label-danger"
                            }
                          >
                            {item?.status === true ? "Active" : "Inactive"}
                          </Badge>
                        </td>

                        <td className="d-flex flex-nowrap">
                          <ViewTeam {...item} />

                          <TeamForm
                            teamData={{
                              id: item?.id,
                              name: item?.name,
                              email: item?.email,
                              profileImage: item?.profileImage,
                              more_info: item?.more_info,
                              locations: item?.locations,
                              status: item?.status,
                            }}
                            Locations={Locations?.spaces}
                            isReadable={true}
                            onCloseModal={() => setUpdateModalVisible(false)}
                            onOpenModal={() => setUpdateModalVisible(true)}
                            showModal={updateModalVisible}
                            title="Update Team"
                            submitButtonText="Update Team"
                          />
                          <DeleteTeam {...item} />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-left fs-5">
                      {search == "" ? "No Record !" : "No team found"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Teams;
