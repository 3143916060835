import React, { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { useSortableData } from "../../utils/SortingFunction";
import {
  PackageModal,
  ViewPackagePopup,
  UpdatePackagePopUp,
  DeletePackagePopUp,
} from "./";
import {
  useGetMeetingRoomQuery,
  useGetPackageQuery,
  useGetRelatedMeetingRoomsQuery,
  useGetSpaceDataQuery,
} from "../../Slices/spaceApi";
import {
  useGetFacilitiesQuery,
  useGetSpaceTypesQuery,
} from "../../Slices/constantApi";
import { useGetSpaceDurationsQuery } from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import packagePng from "../../Assets/dummyImages/package.png";
import Loader from "../Loader/Loader";
import { useEffect } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Pagination } from "react-bootstrap";
import Select from "react-select";

import { colorStyles } from "../../utils/constant";
import { getRandomColorDark } from "../../utils/getRandomColorDark";
import { getInitials } from "../../utils/getInitials";
import SearchNotFound from "../../Pages/SearchNotFound";

const ReactDataTable4 = () => {
  const { billingOption } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { isLoading: loading, data: FetchPackageData } = useGetPackageQuery(
    spaceId || currentSpaceId
  );
  // const updatedFacilities = [];

  const { data: updatedFacilities, isLoading: loadingUpdatedFacilities } =
    useGetSpaceDataQuery({ spaceId: spaceId || currentSpaceId });

  const { data: FetchFacilities } = useGetFacilitiesQuery();
  const { data: FetchSpaceTypes } = useGetSpaceTypesQuery();
  const { data: spaceDurations, durationsLoading } = useGetSpaceDurationsQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const [searchPackage, setSearchPackage] = useState("");

  const { items, requestSort, sortConfig } = useSortableData(
    FetchPackageData || []
  );

  const { isLoading: relatedLoading, data: FetchMeetingRoom } =
    useGetRelatedMeetingRoomsQuery({ id: spaceId || currentSpaceId });

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const handlePage = (e) => {
    setitemsPerPage(e);
  };
  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];
  const filteredItems = items
    ?.slice()
    .reverse()
    ?.filter((val) => {
      if (searchPackage == "") {
        return val;
      } else if (val.name.toLowerCase().includes(searchPackage.toLowerCase())) {
        return val;
      }
    });

  // if (loadingUpdatedFacilities) {
  //   return <Loader height={6.6} />;
  // }

  return (
    <>
      <div className="col-12 mt-4">
        <div className="d-flex justify-content-between ">
          <div className="col-12  col-md-4 col-lg-6">
            <input
              type="text"
              value={searchPackage}
              onChange={(e) => setSearchPackage(e.target.value)}
              placeholder="Search Space Package"
              className="form-control"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
            <PackageModal
              FetchFacilities={updatedFacilities?.facilities}
              FetchSpaceTypes={FetchSpaceTypes}
              FetchSpaceDurations={spaceDurations?.spaceDurations}
              showButton={true}
            />
          </div>
        </div>
      </div>

      <div className="spacePackage table-responsive mt-4">
        <table className="table  table-stripedCustom">
          <thead>
            <tr>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Name</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("name")}
                      className={getClassNamesFor("name")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("name")}
                      className={getClassNamesFor("name")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Duration</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("duration")}
                      className={getClassNamesFor("duration")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("duration")}
                      className={getClassNamesFor("duration")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Price</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("price")}
                      className={getClassNamesFor("price")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("price")}
                      className={getClassNamesFor("price")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
              {billingOption == 1 ? <th>Meeting Hours</th> : <th>Coins</th>}

              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Space Type</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("spaceType")}
                      className={getClassNamesFor("spaceType")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("spaceType")}
                      className={getClassNamesFor("spaceType")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={6} className="text-left fs-5">
                  Loading..
                </td>
              </tr>
            )}
            {!loading &&
              filteredItems?.length > 0 &&
              filteredItems?.map((item, index) => {
                let originalIndex = filteredItems.length - index - 1;
                const { name, durationString, images, price, spaceTypeString } =
                  item;

                return (
                  <tr key={index}>
                    <td>
                      <div className="media">
                        <div className="media-aside logo-list mr-2">
                          {images?.[0] ? (
                            <img
                              // style={{
                              //   borderRadius: "50%",
                              //   width: "38px",
                              //   height: "38px",
                              //   objectFit: "cover",
                              // }}
                              src={images?.[0]}
                              alt={name}
                            />
                          ) : (
                            <div
                              className="rounded-circle"
                              style={{
                                width: "48px",
                                height: "48px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: getRandomColorDark(),
                                color: "#fff",
                                fontSize: "16px",
                              }}
                            >
                              {getInitials(name)}
                            </div>
                          )}
                        </div>
                        <div className="media-body my-auto">
                          <span className="mb-0 table-avatar">
                            <b>{name}</b>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{durationString}</td>

                    {/* <td>{price}</td> */}
                    <td>
                      <div className="text-center">
                        {item?.meetingRoomAllowedHours?.[0]?.hours || "-"}
                      </div>
                    </td>

                    <td>{spaceTypeString}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <ViewPackagePopup
                          items={items}
                          index={originalIndex}
                          SpaceTypes={FetchSpaceTypes}
                          // viewIndex={viewIndex}
                        />
                        <UpdatePackagePopUp
                          items={items}
                          index={originalIndex}
                          FetchFacilities={updatedFacilities?.facilities}
                          FetchSpaceTypes={FetchSpaceTypes}
                          FetchSpaceDurations={spaceDurations?.spaceDurations}
                          FetchMeetingRoom={FetchMeetingRoom}
                        />
                        <DeletePackagePopUp
                          items={items}
                          index={originalIndex}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!loading && filteredItems.length == 0 ? (
          <div className="text-center">
            <SearchNotFound />

            <div className="d-flex text-center">
              <div className="col-12 d-flex">
                <p className="col-6 mb-0 text-end">Click to Add record</p>
                <div className="btn-link col-6">
                  <PackageModal
                    FetchFacilities={updatedFacilities?.facilities}
                    FetchSpaceTypes={FetchSpaceTypes}
                    FetchSpaceDurations={spaceDurations?.spaceDurations}
                    showButton={false}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ReactDataTable4;
