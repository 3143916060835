export function getInitials(name) {
  const nameParts = name?.split(" ");
  let initials = "";

  for (let i = 0; i < Math.min(2, nameParts?.length); i++) {
    initials += nameParts[i]?.charAt(0)?.toUpperCase();
  }

  return initials;
}
