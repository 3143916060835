import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import forgetPswImg from "../../Assets/forgetPswImg.svg";
import "./index.css";
import axios from "axios";
import { Back } from "../../Assets/SettingSvgs/Icon";
import { useGlobalAuthContext } from "../../AuthContext/AuthContext";
import GetCookie from "../../utils/getCookie";
import logo1 from "../../Assets/nDesk/LogoFull.png";

const Forgetpsw = () => {
  const { currentUserSessionStorage, currentUserCookie, currentSessionCookie } =
    useGlobalAuthContext();
  const Navigate = useNavigate();
  const [dataSending, setDataSending] = useState(false);
  const [email, setEmail] = useState("");
  const [apiCheck, setApiCheck] = useState(false);

  const [code, setCode] = useState("");
  const [newpsw, setNewPsw] = useState("");

  const sendLink = async (e) => {
    e.preventDefault();
    try {
      let emailJSON = { email: email };
      const response = await axios.post("/reset-password/v2/", emailJSON);
      setDataSending(true);
      setApiCheck(true);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const updatePsw = (e) => {
    e.preventDefault();
    try {
      let url = `/reset-password/v2/${code}`;
      const newpswJSON = {
        email: email,
        password: newpsw,
      };
      axios.post(url, newpswJSON);
      alert("Password Change");
      Navigate("/login");
    } catch (error) {
      alert(error?.data?.message);
    }
  };

  const resendLink = async () => {
    let emailJSON = { email: email };

    try {
      const response = await axios.post("/reset-password/v2/", emailJSON);

      setApiCheck(true);

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (GetCookie("token") || GetCookie("session-token")) {
      Navigate("/");
    }
  }, []);
  const [remainingTime, setRemainingTime] = useState(120);

  const timerIdRef = useRef();
  const intervalIdRef = useRef();

  useEffect(() => {
    const startTimer = () => {
      timerIdRef.current = setTimeout(() => {
        setApiCheck(false);
        setRemainingTime(120);
      }, remainingTime * 1000);

      intervalIdRef.current = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    };

    if (apiCheck) {
      startTimer();
    }

    return () => {
      clearTimeout(timerIdRef.current);
      clearInterval(intervalIdRef.current);
    };
  }, [apiCheck, remainingTime]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    const formattedTime = `${minutes} min ${seconds} sec`;

    return formattedTime;
  };
  return (
    <>
      <section id="forgetPsw">
        <div className="row w-100 row-length m-0">
          <Link to="/" className="login-logo d-md-block d-none">
            <img src={logo1} alt={"full-logo"} />
          </Link>
          <div
            className="col-md-8 p-2 d-flex justify-content-center d-md-block d-none"
            style={{ height: "100vh", overflow: "hidden" }}
          >
            <div className="px-5">
              <img src={forgetPswImg} alt="login-img" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-4 p-5 bg-white relative">
            <div className="center-form">
              <Link to="/" className="d-block d-md-none text-center">
                <img style={{ width: "145px" }} src={logo1} alt="login-logo" />
              </Link>
              <h4 className="card-title mb-1 text-center text-md-start">
                {" "}
                Forgot Password? 🔒{" "}
              </h4>
              <p className="card-text mb-2 text-center text-md-start">
                Enter your email and we'll send you instructions to reset your
                password
              </p>
              <span>
                <form
                  className="auth-forgot-password-form mt-2"
                  onSubmit={sendLink}
                >
                  <div role="group" className="form-group" id="__BVID__866">
                    <label
                      htmlFor="forgot-password-email"
                      className="d-block"
                      id="__BVID__866__BV_label_"
                    >
                      Email
                    </label>
                    <div>
                      <span>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          id="forgot-password-email"
                          name="forgot-password-email"
                          type="email"
                          placeholder="john@example.com"
                          className="form-control"
                        />
                        <small className="text-danger"></small>
                      </span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={dataSending || !email}
                  >
                    Send reset link
                  </button>
                </form>
              </span>

              {dataSending && (
                <div className="mt-5">
                  <form onSubmit={updatePsw}>
                    {/* <div className="">
                      <label htmlFor="code" className="mb-1">
                        Code:
                      </label>
                      <input
                        type="text"
                        id="code"
                        className="form-control"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>

                    <div className="">
                      <label htmlFor="new-psw" className="mb-1">
                        New Password:
                      </label>
                      <input
                        type="text"
                        id="new-psw"
                        className="form-control"
                        value={newpsw}
                        onChange={(e) => setNewPsw(e.target.value)}
                      />
                    </div>

                    <div className=" mt-3">
                      <input
                        type="submit"
                        value="Update Password"
                        disabled={!code || !newpsw}
                        className="btn btn-primary"
                      />
                    </div> */}
                    <h6>
                      An email has been sent to your registered email address.
                      Click the link provided in your email in order to set your
                      new password.
                    </h6>

                    {apiCheck ? (
                      <p className="mt-4" style={{ cursor: "pointer" }}>
                        You can resend link after{" "}
                        <b>{formatTime(remainingTime)}</b>
                      </p>
                    ) : (
                      <p className="mt-4">Link can be resent now</p>
                    )}

                    <p
                      onClick={!apiCheck ? () => resendLink() : null}
                      className={`text-decoration-underline ${
                        !apiCheck ? "btn-link" : ""
                      } text-center mt-1`}
                      disabled={!apiCheck}
                      style={{ cursor: "pointer", userSelect: "none" }}
                    >
                      Resend Link
                    </p>
                  </form>
                </div>
              )}
              <p className="text-center" style={{ marginTop: "auto" }}>
                <Link to="/login" className="" target="_self">
                  {Back}
                  Back to login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Forgetpsw;
