import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllLocationsQuery } from "../../Slices/spaceApi";
import { useSortableData } from "../../utils/SortingFunction";
import { Modal, Button } from "react-bootstrap";

import Loader from "../Loader/Loader";

import LocationForm from "./LocationForm";

import DeleteLocation from "./DeleteLocation";
import LocationView from "./LocationView";

const Locations = () => {
  const [showModal, setShowModal] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [modalView, setModalView] = useState(false);
  const handleOpenUpdateModal = (team) => {
    setSelectedTeam(team);
    setUpdateModalVisible(true);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const [currentView, setCurrentView] = useState("");
  const handleViewModal = (item) => {
    setOpenViewModal(true);
  };

  const { data: getLocations, locationLoading } = useGetAllLocationsQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const [search, setSearch] = useState("");

  const { items } = useSortableData(getLocations?.spaces || []);

  const filteredItems = items?.filter((val) => {
    if (search == "") {
      return val;
    } else if (val.name.toLowerCase().includes(search.toLowerCase())) {
      return val;
    }
  });

  if (locationLoading) {
    return <Loader height={6.6} />;
  }

  return (
    <>
      <div className="card bottom-card mb-3">
        <div className="col-12 mt-4">
          <div className="d-flex justify-content-between ">
            <div className="col-12  col-md-4 col-lg-6">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Location"
                className="form-control"
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
              <LocationForm
                // teamData={
                //   {
                //     name: "",
                //     address: "",
                //     imageUrls: "",
                //     description: "",
                //     capacity: 0,
                //   }
                // }
                isReadable={false}
                onCloseModal={() => setShowModal(false)} // Close modal from here
                onOpenModal={() => setShowModal(true)}
                showModal={showModal}
                title="Add Location"
                submitButtonText="Add Location"
              />
            </div>
          </div>
        </div>
        <hr />

        <div className="d-flex px-4 flex-wrap justify-content-around mt-4 mb-4">
          {locationLoading && <h4>Loading...</h4>}
          {!locationLoading && filteredItems?.length > 0 ? (
            filteredItems?.map((item) => {
              return (
                <div
                  className="col-md-6 col-lg-4 mb-3"
                  key={item.id}
                  // style={{ cursor: "pointer", height: "100%" }}
                >
                  <div
                    // className="card p-3 d-flex col-md-4"
                    className="card"
                    style={{
                      cursor: "pointer",
                      height: "100%",
                      padding: "10px 10px 10px 10px",
                    }}
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   setCurrentView(item);
                    //   setShow(true);
                    // }}
                  >
                    {/* <div className="card-body"> */}
                    <h5 className="card-title">
                      {item.name || "No Name Provided By User"}
                    </h5>
                    <img
                      src={
                        item?.imageUrls ||
                        "https://img.freepik.com/free-psd/location-icon-composing-maps-regions_314999-2204.jpg?w=740&t=st=1700575499~exp=1700576099~hmac=8753f3d9b1842b64b862a9b4651c9987032d3074ab2035aa6c03f81afbe12392"
                      }
                      alt=""
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                      onClick={() => setModalView(true)}
                      className="img-fluid d-flex mx-auto my-4 rounded"
                    />

                    <p className="d-flex card-subtitle  align-item-start mt-1">
                      Address:&nbsp;{" "}
                      <p className="card-text fontSmall2 mt-1">
                        {item.address}
                      </p>
                    </p>
                    {item.description && (
                      <p className="d-flex card-subtitle align-items-start mt-1">
                        Description:&nbsp;{" "}
                        <p className="card-text fontSmall2 mt-1">
                          {item.description}
                        </p>
                      </p>
                    )}
                    {item.capacity >= 0 && (
                      <p className="d-flex card-subtitle align-items-start mt-1">
                        Capacity:&nbsp;{" "}
                        <p className="card-text fontSmall2 mt-1">
                          {item.capacity}
                        </p>
                      </p>
                    )}

                    <div className="d-flex justify-content-center">
                      <div className="d-flex flex-nowrap ">
                        {/* {modalView && (
                          <LocationView {...item} setModalView={setModalView} />
                        )} */}

                        <LocationForm
                          teamData={{
                            id: selectedTeam?.id,
                            name: selectedTeam?.name,
                            address: selectedTeam?.address,
                            imageUrls: selectedTeam?.imageUrls,
                            description: selectedTeam?.description,
                            capacity: selectedTeam?.capacity || 0,
                          }}
                          isReadable={true}
                          onCloseModal={() => setUpdateModalVisible(false)}
                          onOpenModal={() => handleOpenUpdateModal(item)}
                          showModal={updateModalVisible}
                          title="Update Location"
                          submitButtonText="Update Location"
                        />
                        <DeleteLocation {...item} />
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              );
            })
          ) : !locationLoading && filteredItems?.length == 0 ? (
            <h4>No Record !</h4>
          ) : null}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentView?.name && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Name: <span className="text-muted">{currentView?.name}</span>
                </h5>
              </div>
            </div>
          )}
          {currentView?.address && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Address:{" "}
                  <span className="text-muted">{currentView?.address}</span>
                </h5>
              </div>
            </div>
          )}

          {currentView?.description && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Description:{" "}
                  <span className="text-muted" style={{ fontSize: "14px" }}>
                    {currentView?.description}
                  </span>
                </h5>
              </div>
            </div>
          )}
          {currentView?.capacity && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Capacity:{" "}
                  <span className="text-muted">{currentView?.capacity}</span>
                </h5>
              </div>
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Locations;
