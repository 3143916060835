import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EmojiPicker from "react-emoji-picker";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";

import { useSelector } from "react-redux";
import {
  useCreateAnnouncementMutation,
  useGetAllMembersQuery,
} from "../../Slices/spaceApi";
import Select from "react-select";
import { colorStyles } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import {
  FaApple,
  FaCloudUploadAlt,
  FaFileExport,
  FaFileUpload,
  FaPaperPlane,
  FaTimes,
} from "react-icons/fa";
import Loader from "../Loader/Loader";
import toast from "react-hot-toast";
import { requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Paperclip } from "react-bootstrap-icons";

const AnnouncementModal = ({}) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: allMembers, isLoading: loading } = useGetAllMembersQuery(
    spaceId || currentSpaceId
  );

  const [createAnnouncement, resultStats] = useCreateAnnouncementMutation();

  const [message, setMessage] = useState("");
  const [activeTab, setActiveTab] = useState("message");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [emailTo, setEmailTo] = useState([]);
  const [emailToError, setEmailToError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [apiCallCheck, setApiCallCheck] = useState(false);

  const handle = (e) => {
    setEmailToError("");
    if (e.some((option) => option.id === "all")) {
      setEmailTo(allMembers?.users?.map((facility) => facility.id));
    } else {
      setEmailTo(e.map((x) => x?.id));
    }
  };
  const handleSubject = (e) => {
    setSubjectError("");
    setSubject(e.target.value);
  };
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const [uploadFiles, setUploadFiles] = useState([]);

  const handleFileChange = (e) => {
    const filesArray = Array.from(e.target.files);
    setUploadFiles([...uploadFiles, ...filesArray]);
  };
  const uploadFile = (e) => {
    e.preventDefault();
    document.getElementById("attachment").click();
  };

  const removeFile = (index) => {
    const newFiles = [...uploadFiles];
    newFiles.splice(index, 1);
    setUploadFiles(newFiles);
  };
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const insertEmoji = (emoji) => {
    const emojiChar = emoji.native || emoji;
    const cursorPos = message.length;
    const newMessage =
      message.slice(0, cursorPos) + emojiChar + message.slice(cursorPos);
    setMessage(newMessage);
    setShowEmojiPicker(false);
  };

  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!emailTo?.length > 0) {
      setEmailToError("*Please Select Members");
      return;
    } else if (!subject) {
      setSubjectError("*Please Enter Subject");
    } else if (!message) {
      setMessageError("*Please Write Message");
    } else {
      const fd = new FormData();
      uploadFiles.forEach((file, index) => {
        fd.append(`images[${index}]`, file);
      });
      setApiCallCheck(true);
      axios
        .post("/uploadImage/", fd)
        .then((response) => {
          const data = {
            to: emailTo,
            subject: subject,
            content: message,
            attachments: response.data,
          };
          createAnnouncement({
            spaceId: spaceId || currentSpaceId,
            data: data,
          });
        })
        .catch((error) => {
          setApiCallCheck(false);
          console.error("Error uploading files:", error);
          // Handle error as needed
        });
    }
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiCallCheck(false);

      requiredAlert(resultStats?.error?.data?.message);
    }

    if (resultStats?.isSuccess) {
      setApiCallCheck(false);

      toast.success("Announcement Created Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setTimeout(() => {
        navigate("/announcements");
      }, 1000);
    }
  }, [resultStats]);

  return (
    <div className="app-email-compose">
      <Button
        variant="primary"
        onClick={
          // membersPermissions[0]?.create ?
          handleShow
          // : null
        }
        className="send-invoice-btn"
      >
        {/* {membersPermissions[0]?.create ? ( */}
        <span className="invoice">
          <Plus /> Create Announcement
        </span>
        {/* ) : (
          <span className="invoice">
            <Plus /> Create Announcement
            <Lock />
          </span>
        )} */}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        // animation={false}
        className="modal-bottom-right"
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h3">Compose Email</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="to" className="form-label">
                To
              </label>
              {allMembers?.users?.length > 0 ? (
                <Select
                  options={[{ id: "all", name: "All" }, ...allMembers?.users]}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  styles={colorStyles}
                  closeMenuOnSelect={false}
                  value={allMembers?.users?.filter((obj) =>
                    emailTo?.includes(obj.id)
                  )}
                  placeholder={"Select Member"}
                  onChange={handle}
                  isMulti
                  id="email-to"
                />
              ) : (
                <div
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    // navigate("/member/list");
                    navigate("/");
                  }}
                >
                  Please Add Members First
                </div>
              )}
              {emailToError && (
                <p style={{ color: "red", marginLeft: "10px" }}>
                  {emailToError}
                </p>
              )}
            </div>

            <div className="mb-3">
              {/* <label htmlFor="subject" className="form-label">
                Subject
              </label> */}
              <input
                type="text"
                className="form-control"
                placeholder="Subject"
                id="subject"
                name="subject"
                required
                value={subject}
                onChange={(e) => handleSubject(e)}
              />
              {subjectError && (
                <p style={{ color: "red", marginLeft: "10px" }}>
                  {subjectError}
                </p>
              )}
            </div>

            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>

              <ReactQuill
                value={message}
                onChange={(content) => {
                  setMessageError("");
                  setMessage(content);
                }}
                style={{ maxHeight: "20rem", overflowY: "auto" }}
                // style={{ height: "20rem" }}
                className=""
                placeholder="Write Your Email Here....."
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ["bold", "italic", "underline", "strike"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link"],
                    ["clean"],
                  ],
                }}
              />
              {messageError && (
                <p style={{ color: "red", marginLeft: "10px" }}>
                  {messageError}
                </p>
              )}
            </div>

            {/* <div className="mt-2">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "attachFile" ? "active" : ""
                }`}
                type="button"
                onClick={() => setActiveTab("attachFile")}
              >
                Attach File
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "emoji" ? "active" : ""}`}
                type="button"
                onClick={() => setActiveTab("emoji")}
              >
                😊
              </button>
            </li>
          </ul>
        </div> */}

            <div className="mt-3">
              <label
                htmlFor="attachment_a"
                className="d-flex"
                title="Attachment"
              >
                <Paperclip
                  className="svg-attachment p-1 bg-label-secondary rounded"
                  style={{
                    marginRight: "5px",
                    width: "24px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  onClick={uploadFile}
                />

                <span>({uploadFiles?.length})</span>
              </label>
              <input
                type="file"
                className="form-control  "
                style={{ display: "none" }}
                id="attachment"
                name="attachment"
                multiple
                onChange={handleFileChange}
              />
              <div className="selected-files">
                {uploadFiles?.map((file, index) => (
                  <div key={index} className="selected-file">
                    {`${file?.name?.substring(0, 10)}...`}
                    <FaTimes onClick={() => removeFile(index)} />
                  </div>
                ))}
              </div>
            </div>

            <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={(e) => sendEmail(e)}
              disabled={apiCallCheck}
            >
              Send Email{" "}
              <FaPaperPlane
                style={{ marginLeft: "5px", marginBottom: "5px" }}
              />
              {apiCallCheck ? (
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "5px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </button>
          </form>
        </Modal.Body>
        {/* <Modal.Footer> */}
      </Modal>
    </div>
  );
};

export default AnnouncementModal;
