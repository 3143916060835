import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { Amenities, Select } from "./";
import { errorAlertImage } from "../../utils/Alerts";
import { DataOfTime } from "../../utils/constant";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { DashLg } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import {
  useGetCurrencyQuery,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";
import TimeHours from "./timeHours.json";
import Logo1 from "../../Assets/dummyImages/Logo1.png";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";
import ColorPicker from "./ColorPicker";

function OperatingHours({ spaceData }) {
  const { settingsPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();
  const [dayStartTimeValue, setDayStartTimeValue] = useState();
  const [dayEndTimeValue, setDayEndTimeValue] = useState();
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    const dayStartTimeArray = [0, 0, 0, 0, 0, 0, 0];

    spaceData?.openDays?.forEach((dayObj) => {
      if (dayObj.day >= 1 && dayObj.day <= 7) {
        const startTime_hours = new Date(dayObj.start_time * 1000).getHours();
        const startTime_minutes = new Date(
          dayObj.start_time * 1000
        ).getMinutes();
        const startTime_hours_minutes =
          startTime_hours * 60 + startTime_minutes;
        const apiStartTime = startTime_hours_minutes / 30 + 1;
        dayStartTimeArray[dayObj.day - 1] = apiStartTime;
      }
    });

    setDayStartTimeValue(dayStartTimeArray);
  }, [spaceData]);

  useEffect(() => {
    const dayEndTimeArray = [1, 1, 1, 1, 1, 1, 1];

    spaceData?.openDays?.forEach((dayObj) => {
      if (dayObj.day >= 1 && dayObj.day <= 7) {
        let endTime_hours = new Date(dayObj?.end_time * 1000).getHours();
        const endTime_minutes = new Date(dayObj?.end_time * 1000).getMinutes();
        endTime_hours = endTime_hours * 60 + endTime_minutes;
        const apiEndTime = endTime_hours / 30 + 1;
        dayEndTimeArray[dayObj.day - 1] = apiEndTime;
      }
    });

    setDayEndTimeValue(dayEndTimeArray);
  }, [spaceData]);

  const [timeDifferenceArray, setTimeDifferenceArray] = useState([]);
  useEffect(() => {
    // Check if spaceData and openDays are defined
    if (spaceData && spaceData.openDays) {
      const newArray = spaceData.openDays.map((dayData) => {
        const day = dayData.day;
        const startTime = dayData.start_time;
        const endTime = dayData.end_time;
        const timeDifferenceInSeconds = endTime - startTime;

        return { day, timeDifferenceInSeconds };
      });

      setTimeDifferenceArray(newArray);
    }
  }, [spaceData]);

  let dateObj = new Date();
  let month = dateObj.getMonth() + 1;
  let day = dateObj.getDate();
  let year = dateObj.getFullYear();

  function toTimestamp(year, month, day, hour, minute, second) {
    var datum = new Date(year, month - 1, day, hour, minute, second);
    return datum.getTime() / 1000;
  }

  const [timePost, setTimePost] = useState({
    openDays: spaceData?.openDays || [],
  });

  const [dummyOperatingHours, setDummyOperatingHours] = useState(TimeHours);

  const [startIndex, setStartIndex] = useState(null);
  const [targetedValue, setTargetedValue] = useState("");
  const handleTime = (value, dayId, index) => {
    setTargetedValue(value);
    setStartIndex(value);
    setDayStartTimeValue((prevDayStartTimeValue) => {
      const updatedStartTimeValue = [...prevDayStartTimeValue];
      if (index >= 0 && index < updatedStartTimeValue.length) {
        updatedStartTimeValue[index] = value;
      }
      return updatedStartTimeValue;
    });
    if (value > 0) {
      let val = parseInt(value) + 1;
      // handleEndTime(val, dayId, index);
    }
    let id = value;
    var dateObj = new Date();
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();
    var hours = 0;
    var minutes = 0;
    var second = 0;

    if (id == 0) {
      hours = 0;
      minutes = 0;
      second = 0;
    } else if (id % 2 == 0) {
      hours = Math.floor((id - 1) / 2);
      minutes = 30;
      second = 0;
    } else {
      hours = Math.floor((id - 1) / 2);
      minutes = 0;
    }

    let waitForTimeStamp = toTimestamp(
      year,
      month,
      day + 1,
      hours,
      minutes,
      second
    );

    const setEndTimeStamp = toTimestamp(year, month, day + 2, 0, 0, 0);
    const setEndStartTimeStamp = toTimestamp(year, month, day + 1, 1, 0, 0);

    const updatedOpenDays = timePost.openDays.map((dayObj) => {
      if (dayObj.day == dayId) {
        const isMidnight =
          new Date(dayObj.end_time * 1000).toLocaleTimeString() ==
          "12:00:00 AM";

        if (value != 0 && isMidnight) {
          setDayEndTimeValue((prevDayStartTimeValue) => {
            const updatedStartTimeValue = [...prevDayStartTimeValue];
            if (index >= 0 && index < updatedStartTimeValue.length) {
              updatedStartTimeValue[index] = 2;
            }
            return updatedStartTimeValue;
          });
        }

        return {
          ...dayObj,
          start_time: waitForTimeStamp,
          end_time:
            value == 0
              ? setEndTimeStamp
              : value != 0 && isMidnight
              ? setEndStartTimeStamp
              : dayObj.end_time,
        };
      }
      return dayObj;
    });

    const updatedTimePost = {
      openDays: updatedOpenDays,
    };

    setTimePost(updatedTimePost);
    setChanged(true);
  };

  const handleEndTime = (value, dayId, index) => {
    setDayEndTimeValue((prevDayEndTimeValue) => {
      const updatedEndTimeValue = [...prevDayEndTimeValue];
      if (index >= 0 && index < updatedEndTimeValue.length) {
        updatedEndTimeValue[index] = value;
      }
      return updatedEndTimeValue;
    });

    setStartIndex(null);

    let hours = Math.floor(value / 2);
    // let minutes = value % 2 === 0 ? 0 : 30;
    let minutes = value % 2 === 0 ? -30 : 0;

    var dateObj = new Date();
    var month = dateObj.getMonth() + 1;
    var day = dateObj.getDate();
    var year = dateObj.getFullYear();

    let waitForTimeStamp = toTimestamp(year, month, day + 1, hours, minutes, 0);

    const updatedOpenDays = timePost.openDays.map((dayObj) => {
      if (dayObj.day === dayId) {
        return {
          ...dayObj,
          end_time: waitForTimeStamp,
        };
      }
      return dayObj;
    });

    const updatedTimePost = {
      ...timePost,
      openDays: updatedOpenDays,
    };

    setTimePost(updatedTimePost);
    setChanged(true);
  };

  // console.log("Day start time value is", dayStartTimeValue);
  // console.log("Day End time value is", dayEndTimeValue);

  // const handleEndTime = (value, dayId, index) => {
  //   console.log(
  //     "End time function is called. value is ",
  //     value,
  //     " day id is ",
  //     dayId,
  //     " index is ",
  //     index
  //   );
  //   setDayEndTimeValue((prevDayStartTimeValue) => {
  //     const updatedStartTimeValue = [...prevDayStartTimeValue];
  //     if (index >= 0 && index < updatedStartTimeValue.length) {
  //       updatedStartTimeValue[index] = value;
  //     }
  //     return updatedStartTimeValue;
  //   });

  //   setStartIndex(null);

  //   let id = value;
  //   var dateObj = new Date();
  //   var month = dateObj.getMonth() + 1;
  //   var day = dateObj.getDate();
  //   var year = dateObj.getFullYear();

  //   var hours = 0;
  //   var minutes = 0;
  //   var second = 0;
  //   if (id % 2 == 0) {
  //     hours = Math.floor(id / 2);
  //     minutes = 0;
  //     second = 0;
  //   } else {
  //     hours = Math.floor(id / 2);
  //     minutes = 30;
  //   }
  //   let waitForTimeStamp = toTimestamp(
  //     year,
  //     month,
  //     day + 1,
  //     hours,
  //     minutes,
  //     second
  //   );

  //   const updatedOpenDays = timePost.openDays.map((dayObj) => {
  //     if (dayObj.day === dayId) {
  //       return {
  //         ...dayObj,
  //         end_time: waitForTimeStamp,
  //       };
  //     }
  //     return dayObj; // Return the unchanged object if id doesn't match
  //   });

  //   const updatedTimePost = {
  //     ...timePost,
  //     openDays: updatedOpenDays,
  //   };

  //   setTimePost(updatedTimePost);
  //   setChanged(true);
  // };

  const handleChange = (e) => {
    const { checked, value, id } = e.target;
    setChanged(true);
    if (checked) {
      setTimePost({
        ...timePost,
        openDays: [
          ...timePost.openDays,
          {
            day: +id,
            start_time: toTimestamp(year, month, day + 1, 0, 0, 0),
            // end_time: toTimestamp(year, month, day + 1, 0, 0, 0),
            end_time: toTimestamp(year, month, day + 2, 0, 0, 0),
          },
        ],
      });
    } else {
      setTimePost({
        ...timePost,
        openDays: timePost.openDays.filter((e) => e.day !== +id),
      });
    }
  };
  const getTime = () => {
    return DataOfTime?.map((time, index) => {
      return (
        <option key={index} value={index}>
          {time.name}
        </option>
      );
    });
  };

  let endTimeSelect = DataOfTime.slice(1);

  const endTime = (selectedStartTimeId, dayId) => {
    const filteredEndTimeSelect = DataOfTime?.filter(
      (time) => time.id > selectedStartTimeId
    );

    return filteredEndTimeSelect?.map((time) => (
      <option key={time.id} value={time.id}>
        {time.name}
      </option>
    ));
  };

  const [hoursLoading, setHoursLoading] = useState(false);
  const postOperatingHours = (e) => {
    e.preventDefault();
    setHoursLoading(true);
    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: timePost,
      startTime: timePost.openDays?.[0]?.start_time,
      endTime: timePost.openDays?.[0]?.end_time,
    });
  };

  const handleClose = () => {
    setChanged(false);
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
      setHoursLoading(false);
    }

    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Operating Hours Updated Successfully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 2000);
      setHoursLoading(false);

      handleClose();
    }
  }, [resultStats]);
  return (
    <div>
      <div className="card">
        <Form className="operatingHours p-4">
          <h2>Operating Hours</h2>
          {TimeHours?.map((item, index) => {
            return (
              <div className="row align-items-center mb-4" key={index}>
                <div className="col-xl-3 col-12">
                  <Form.Check
                    type="switch"
                    defaultChecked={timePost?.openDays?.find(
                      (uff) => uff.day == item.id
                    )}
                    value={item.day}
                    id={item.id}
                    label={item.day}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-xl-2 col-12">
                  <p
                    className={`mb-0 ${
                      timePost?.openDays?.some((yahoo) => yahoo.day === item.id)
                        ? "operate-active"
                        : "operate-inactive"
                    }`}
                  >
                    {timePost?.openDays?.some((yahoo) => yahoo.day === item.id)
                      ? "Opens"
                      : "closed"}
                  </p>
                </div>

                {timePost?.openDays
                  ? timePost?.openDays?.find(
                      (uff, index) => uff.day == item.id
                    ) && (
                      <>
                        <div className="col-xl-3 col-5">
                          <select
                            name={`startTime${index}`}
                            id={`startTime${index}`}
                            className="form-control"
                            value={
                              dayStartTimeValue?.[index] -
                                dayEndTimeValue?.[index] ==
                              0
                                ? dayStartTimeValue?.[index] -
                                  dayStartTimeValue?.[index]
                                : dayStartTimeValue?.[index]
                            }
                            onChange={(e) =>
                              handleTime(e.target.value, item.id, index)
                            }
                          >
                            {getTime()}
                          </select>
                        </div>
                        {dayStartTimeValue?.[index] != 0 &&
                          dayStartTimeValue?.[index] -
                            dayEndTimeValue?.[index] !=
                            0 && (
                            <div className="col-xl-1 col-1 text-center ">
                              <DashLg />
                            </div>
                          )}

                        {dayStartTimeValue?.[index] != 0 &&
                          dayStartTimeValue?.[index] -
                            dayEndTimeValue?.[index] !=
                            0 && (
                            <div className="col-xl-3 col-5 offset-1 offset-xl-0">
                              <select
                                // name="endTime"
                                name={`endTime${index}`}
                                id={`endTime${index}`}
                                className="form-control"
                                value={dayEndTimeValue?.[index]}
                                onChange={(e) =>
                                  handleEndTime(e.target.value, item.id, index)
                                }
                              >
                                {endTime(dayStartTimeValue?.[index], item.id)}
                              </select>
                            </div>
                          )}
                      </>
                    )
                  : dummyOperatingHours?.find(
                      (yahoo) => yahoo.day == item.id
                    ) && (
                      <>
                        <div className="col-xl-3">
                          <select
                            name="startTime"
                            id="startTime1"
                            className="form-control"
                            value={dayStartTimeValue?.[index]}
                            onChange={(e) =>
                              handleTime(e.target.value, item.id, index)
                            }
                          >
                            {getTime()}
                          </select>
                        </div>

                        {dayStartTimeValue?.[index] != 0 && (
                          <div className="col-xl-1 col-1 text-center">
                            <DashLg />
                          </div>
                        )}
                      </>
                    )}
              </div>
            );
          })}

          <div className="row mt-5">
            <div className="col-md-12">
              <button
                className="btn btn-primary"
                onClick={
                  settingsPermissions[0]?.update ? postOperatingHours : null
                }
                disabled={
                  !changed || hoursLoading || !settingsPermissions[0]?.update
                }
              >
                {settingsPermissions[0]?.update ? (
                  <>
                    Save Changes{" "}
                    {hoursLoading ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "5px",
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <span>Save Changes{Lock2}</span>
                )}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default OperatingHours;
