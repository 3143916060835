import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";

import {
  useAllSpacesQuery,
  useGetProfitAndLossStatementQuery,
  useOwnerLocationQuery,
  usePaymentByIncomeTypeReportQuery,
  useSpaceGetQuery,
} from "../../../Slices/spaceApi";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { handleChange } from "../../../utils/sharedReports";
import ChartsFilter from "../Visitors/ChartsFilter";
import { Table } from "react-bootstrap";
import { useGlobalContext } from "../../../Context/GlobalContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ProfitAndLoss = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { currency } = useGlobalContext();
  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const tableRef = useRef(null);

  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [page, setPage] = useState(1);
  const [page_size, setPage_size] = useState(10);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  const [time, setTime] = useState({ start, end });
  const [revenueData, setRevenueData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const handleLocation = (event) => {
    setLocation(event.target.value);
  };

  const { data, isLoading, isError } = useGetProfitAndLossStatementQuery({
    spaceId: mySpace?.spaceId || spaceId || currentSpaceId,
    start,
    end,
    location,
  });

  useEffect(() => {
    if (selectedOption) {
      const locationString = selectedOption
        ?.map((option) => option.id)
        .join(",");

      setLocation(locationString);
    } else {
      setLocation("");
    }
  }, [selectedOption]);

  useEffect(() => {
    if (data) {
      setRevenueData([
        {
          category: "Resource Booking Revenue",
          amount: data?.revenue?.resourceBooking,
        },
        {
          category: "Space Packages Service Charges",
          amount: data?.revenue?.spacePackagesServiceCharges,
        },
        {
          category: "Space Packages Rental Fee",
          amount: data?.revenue?.spacePackagesRentalFee,
        },
        {
          category: "Meeting Packages Service Charges",
          amount: data?.revenue?.meetingPackagesServiceCharges,
        },
        {
          category: "Meeting Packages Rental Fee",
          amount: data?.revenue?.meetingPackagesRentalFee,
        },
        { category: "Total Purchase", amount: data?.revenue?.purchaseTotal },
      ]);
    }
  }, [data]);

  if (isLoading) return <Loader />;
  if (isError) return <p>Error loading data...</p>;
  return (
    <>
      <ChartsFilter
        location={location}
        setLocation={setLocation}
        time={time}
        setTime={setTime}
        allSpaces={allSpaces}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        setStart={setStart}
        setEnd={setEnd}
        handleChange={handleChange}
        mySpace={mySpace}
        chartExport={true}
        tableRef={tableRef}
      />
      <div ref={tableRef} className="card card-datatable p-3 text-nowrap mt-4">
        <div>
          <h1 className="mt-4 text-center mb-3">Profit and Loss Statement</h1>

          <h3 className="mt-4 mb-3">Expenses</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Category Name</th>
                <th className="col-4">Total Expense</th>
              </tr>
            </thead>
            <tbody>
              {data?.expenses?.map((expense, index) => (
                <tr key={index}>
                  <td>{expense.categoryName}</td>
                  <td className="col-4">
                    {currency} {expense.totalExpense}
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Total Expense</strong>
                </td>
                <td className="col-4">
                  <strong>
                    {currency}{" "}
                    {data?.expenses
                      ?.reduce((total, item) => total + item?.totalExpense, 0)
                      .toFixed(2)}
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>

          <h3 className="mt-4 mb-3">Revenue</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th> Name</th>
                <th className="col-4">Amount</th>
              </tr>
            </thead>
            <tbody>
              {revenueData?.map((revenue, index) => (
                <tr key={index}>
                  <td>{revenue.category}</td>
                  <td>
                    {currency} {revenue.amount}
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Total Revenue</strong>
                </td>
                <td>
                  <strong>
                    {currency}{" "}
                    {revenueData
                      ?.reduce((total, item) => total + item?.amount, 0)
                      .toFixed(2)}
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>

          <h3 className="mt-4 mb-3">Summary</h3>
          <Table striped bordered hover>
            <thead>
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Tax Amount</strong>
                </td>
                <td className="col-4">
                  <strong>
                    {currency} {data?.tax}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Profit/Loss Before Tax:</strong>
                </td>
                <td className="col-4">
                  <strong>
                    {currency} {data?.profitLossBeforeTax}
                  </strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Profit/Loss After Tax:</strong>
                </td>
                <td className="col-4">
                  <strong>
                    {currency} {data?.profitLossAfterTax?.toFixed(2)}
                  </strong>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default ProfitAndLoss;
