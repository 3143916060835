import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  useAllSpacesQuery,
  useBookingResourceUsageAndRevReportQuery,
  useOwnerLocationQuery,
  useSpaceGetQuery,
} from "../../../Slices/spaceApi";
import LineBarChart from "./LineBarChart";
import { Modal } from "react-bootstrap";
import Loader from "../../Loader/Loader";
import { handleChange } from "../../../utils/sharedReports";
import ChartsFilter from "../Visitors/ChartsFilter";

const ResourceAndRev = () => {
  const ref = useRef(null);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [time, setTime] = useState({ start, end });
  const [selectedOption, setSelectedOption] = useState(null);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  useEffect(() => {
    if (selectedOption) {
      const locationString = selectedOption
        ?.map((option) => option.id)
        .join(",");

      setLocation(locationString);
    } else {
      setLocation("");
    }
  }, [selectedOption]);
  const { data, isLoading, isError } = useBookingResourceUsageAndRevReportQuery(
    {
      spaceId: spaceId || currentSpaceId,
      start,
      end,
      location,
    }
  );

  if (isLoading) return <Loader />;
  if (isError) return <p>Error loading data...</p>;
  return (
    <>
      <div className="container-fluid with-bottom-padding p_16 mt-3">
        <ChartsFilter
          location={location}
          setLocation={setLocation}
          time={time}
          setTime={setTime}
          allSpaces={allSpaces}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setStart={setStart}
          setEnd={setEnd}
          handleChange={handleChange}
          mySpace={mySpace}
        />
        <div className="row mt-4">
          {!isLoading &&
            data?.map((item, index) => (
              <LineBarChart key={index} data={item}></LineBarChart>
            ))}
        </div>
      </div>
    </>
  );
};

export default ResourceAndRev;
