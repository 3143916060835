const PackageInputs = [
  {
    id: 1,
    name: "name",
    type: "text",
    placeholder: "Enter Package Name",
    label: "Package Name",
    errorMessage: "Name Must Have A Letter or Digit!(1-25 Characters)",
    required: true,
    pattern: "^(?=.*[A-Za-z0-9]).{1,25}$",
  },
  {
    id: 2,
    name: "serviceCharges",
    type: "number",
    placeholder: "Enter Service Charges",
    label: "Service Charges",
    errorMessage: "Add Service Charges",
    required: true,
    min: 0,
    step: 1,
  },
  {
    id: 3,
    name: "rentalFee",
    type: "number",
    placeholder: "Enter Rental Fee",
    label: "Rental Fee",
    errorMessage: "Add Rental Fee",
    required: true,
    min: 0,
    step: 1,
  },
  {
    id: 4,
    name: "person",
    type: "number",
    placeholder: "Enter Persons",
    label: "Persons",
    errorMessage: "Add A Person!",
    required: true,
    min: 1,
    step: 1,  
  },
  // {
  //   id: 4,
  //   name: "hours",
  //   type: "number",
  //   placeholder: "Enter Hours",
  //   label: "Hours",
  //   errorMessage: "Add Hours!",
  //   required: true,
  //   min: 0,
  //   step: 1,
  // },
];

export default PackageInputs;
