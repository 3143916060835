import React from "react";
import { useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import { useDeleteMeetingRoomPackageMutation } from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useState } from "react";

const DeleteMeetingPackage = ({ items, index }) => {
  const { packagesPermissions } = useGlobalContext();
  const [apiSpinner, setApiSpinner] = useState(false);
  const { id, name } = items[index];
  const [deleteMeetingRoomPackage, resultStats] =
    useDeleteMeetingRoomPackageMutation();

  const deleteMeetingPackage = (e) => {
    e.preventDefault();
    confirmationAlert("Want to Delete Meeting Room Package?").then(
      (willDelete) => {
        setApiSpinner(true);
        if (willDelete) {
          deleteMeetingRoomPackage(id);
        }
      }
    );
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert(`Meeting Package (${name}) is Deleted Successfully.`);
      setTimeout(() => {
        setApiSpinner(false);
      }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
      setTimeout(() => {
        setApiSpinner(false);
      }, 500);
    }
  }, [resultStats]);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {packagesPermissions[0]?.delete ? (
              "Delete Package"
            ) : (
              <span>
                Delete <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          onClick={packagesPermissions[0]?.delete ? deleteMeetingPackage : null}
          disabled={apiSpinner}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
};
export default DeleteMeetingPackage;
