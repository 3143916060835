import React, { useEffect, useState } from "react";
import { Select } from "../AddInvoices";
import { colorStyles } from "../../utils/constant";
import {
  useCreateRoleMutation,
  useGetModulesQuery,
  useUpdateRoleMutation,
} from "../../Slices/spaceApi";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import ReactQuill from "react-quill";
import { Accordion } from "react-bootstrap";
// const PermissionCheckboxGroup = ({
//   label,
//   handlePermissionChange,
//   permissions,
// }) => {
//   const [localPermissions, setLocalPermissions] = useState({});
//   const [check, setCheck] = useState(false);

//   const handleCheckboxChange = (action) => {
//     setLocalPermissions((prevPermissions) => {
//       const updatedPermissions = {
//         ...prevPermissions,
//         [action]: !prevPermissions[action],
//       };
//       console.log("Updated permissions are", updatedPermissions);
//       return updatedPermissions;
//     });
//     setCheck(true);
//   };

//   const notifyChanges = () => {
//     setCheck(false);
//     handlePermissionChange(label, localPermissions);
//   };

//   useEffect(() => {
//     if (check) {
//       notifyChanges();
//     }
//   }, [check]);
//   console.log("Check is", check);
//   useEffect(() => {

//     if (
//       permissions &&
//       permissions.length > 0 &&
//       typeof permissions[0] === "object"
//     ) {
//       setLocalPermissions(permissions[0]);
//     } else {
//       setLocalPermissions({});
//     }
//   }, [permissions]);

//   return (
//     <div className="d-flex justify-content-around">
//       <h6>{label}</h6>
//       <div className="d-flex">
//         {["create", "read", "update", "delete"]?.map((action, index) => (
//           <div className="form-check ml-3" key={`${label}-${action}`}>
//             <input
//               className="form-check-input"
//               type="checkbox"
//               id={`${label}${action}Checkbox`}
//               checked={localPermissions[action]}
//               onChange={() => handleCheckboxChange(action)}
//             />

//             <label
//               className="form-check-label"
//               htmlFor={`${label}${action}Checkbox`}
//             >
//               {action.charAt(0).toUpperCase() + action.slice(1)}
//             </label>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
const PermissionCheckboxGroup = ({
  label,
  handlePermissionChange,
  permissions,
}) => {
  const initialPermission = permissions?.length > 0 ? permissions[0] : {};

  const [permission, setPermissions] = useState({
    create: initialPermission?.create ? true : false,
    read: initialPermission?.read ? true : false,
    update: initialPermission?.update ? true : false,
    delete: initialPermission?.delete ? true : false,
  });
  useEffect(() => {
    setPermissions({
      create: initialPermission?.create ? true : false,
      read: initialPermission?.read ? true : false,
      update: initialPermission?.update ? true : false,
      delete: initialPermission?.delete ? true : false,
    });
  }, [permissions]);

  const handleCheckboxChange = (type) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = {
        ...prevPermissions,
        [type]: !prevPermissions[type],
      };
      notifyChanges(updatedPermissions);
      return updatedPermissions;
    });
  };

  const notifyChanges = (updatedPermissions) => {
    handlePermissionChange(label, updatedPermissions);
  };
  //   const notifyChanges = () => {
  //     handlePermissionChange(label, permissions);
  //   };

  return (
    // <div className="d-flex justify-content-around">
    //   <h6>{label}</h6>
    //   <div className="d-flex ">
    //     {Object?.keys(permission)?.map((type) => (
    //       <div className="form-check ml-3" key={`${label}-${type}`}>
    //         <input
    //           className="form-check-input"
    //           type="checkbox"
    //           id={`${label}${type}Checkbox`}
    //           checked={permission[type]}
    //           onChange={() => handleCheckboxChange(type)}
    //         />
    //         <label
    //           className="form-check-label"
    //           htmlFor={`${label}${type}Checkbox`}
    //         >
    //           {type.charAt(0).toUpperCase() + type.slice(1)}
    //         </label>
    //       </div>
    //     ))}
    //   </div>
    // </div>
    <div className="d-flex justify-content-between align-items-center mb-3">
      <h6>{label}</h6>
      <div className="d-flex">
        {Object?.keys(permission)?.map((type) => (
          <div className="form-check ml-3" key={`${label}-${type}`}>
            <input
              className="form-check-input"
              type="checkbox"
              id={`${label}${type}Checkbox`}
              checked={permission[type]}
              onChange={() => handleCheckboxChange(type)}
            />
            <label
              className="form-check-label"
              htmlFor={`${label}${type}Checkbox`}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const UpdateRoleModal = ({ roleData }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  // console.log("item in update role is", roleData);

  // const { data: Modules, isLoading: loading } = useGetModulesQuery();
  const [updateRole, resultStats] = useUpdateRoleMutation();

  const { setKey, rolesPermissions } = useGlobalContext();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [activeBox, setActiveBox] = useState(1);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setName("");
    setDescription("");
    setSelectedPermissionsWPM({
      floors: [],
      locations: [],
      resources: [],
      community: [],
      announcements: [],
    });
    setSelectedPermissionsPM({
      team: [],
      employees: [],
      roles: [],
      visit: [],
    });
    setSelectedPermissionsAF({
      invoices: [],
      members: [],
      packages: [],
      quotations: [],
      partnersAndOffers: [],
      tours: [],
      tickets: [],
    });
    setSelectedPermissionsMR({
      bookings: [],
    });
    setSelectedPermissionsSettings({
      update: 0,
      read: 1,
    });
  };

  const handleShow = () => setShow(true);
  const [selectedPermissionsWPM, setSelectedPermissionsWPM] = useState({
    floors: [],
    locations: [],
    resources: [],
    community: [],
    announcements: [],
  });
  // console.log("Floor Permission are ", selectedPermissionsWPM);

  const [selectedPermissionsPM, setSelectedPermissionsPM] = useState({
    team: [],
    employees: [],
    roles: [],
    visit: [],
  });

  const [selectedPermissionsAF, setSelectedPermissionsAF] = useState({
    invoices: [],
    members: [],
    packages: [],
    quotations: [],
    partnersAndOffers: [],
    tours: [],
    tickets: [],
  });
  const [selectedPermissionsMR, setSelectedPermissionsMR] = useState({
    bookings: [],
  });
  const [selectedPermissionsSettings, setSelectedPermissionsSettings] =
    useState({
      update: 0,
      read: 1,
    });

  useEffect(() => {
    if (show) {
      setName(roleData?.name);
      setDescription(roleData?.description);
      roleData?.permissions?.map((item) => {
        if (item.code === "WPM-F") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsWPM((prevState) => ({
            ...prevState,
            floors: [...prevState.floors, actions],
          }));
        }

        if (item.code === "WPM-L") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsWPM((prevState) => ({
            ...prevState,
            locations: [...prevState.locations, actions],
          }));
        }
        if (item.code === "WPM-R") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsWPM((prevState) => ({
            ...prevState,
            resources: [...prevState.resources, actions],
          }));
        }
        if (item.code === "WPM-C") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsWPM((prevState) => ({
            ...prevState,
            community: [...prevState.community, actions],
          }));
        }
        if (item.code === "WPM-A") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsWPM((prevState) => ({
            ...prevState,
            announcements: [...prevState.announcements, actions],
          }));
        }

        if (item.code === "PM-T") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsPM((prevState) => ({
            ...prevState,
            team: [...prevState.team, actions],
          }));
        }
        if (item.code === "PM-E") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsPM((prevState) => ({
            ...prevState,
            employees: [...prevState.employees, actions],
          }));
        }

        if (item.code === "PM-R") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsPM((prevState) => ({
            ...prevState,
            roles: [...prevState.roles, actions],
          }));
        }
        if (item.code === "PM-V") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsPM((prevState) => ({
            ...prevState,
            visit: [...prevState.visit, actions],
          }));
        }
        if (item.code === "AF-I") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsAF((prevState) => ({
            ...prevState,
            invoices: [...prevState.invoices, actions],
          }));
        }

        if (item.code === "AF-M") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsAF((prevState) => ({
            ...prevState,
            members: [...prevState.members, actions],
          }));
        }

        if (item.code === "AF-P") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsAF((prevState) => ({
            ...prevState,
            packages: [...prevState.packages, actions],
          }));
        }

        if (item.code === "AF-Q") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsAF((prevState) => ({
            ...prevState,
            quotations: [...prevState.quotations, actions],
          }));
        }
        if (item.code === "AF-PO") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsAF((prevState) => ({
            ...prevState,
            partnersAndOffers: [...prevState.partnersAndOffers, actions],
          }));
        }

        if (item.code === "AF-T") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsAF((prevState) => ({
            ...prevState,
            tours: [...prevState.tours, actions],
          }));
        }

        if (item.code === "AF-TI") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsAF((prevState) => ({
            ...prevState,
            tickets: [...prevState.tickets, actions],
          }));
        }

        if (item.code === "MRM-B") {
          const actions = {
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete,
          };
          setSelectedPermissionsMR((prevState) => ({
            ...prevState,
            bookings: [...prevState.bookings, actions],
          }));
        }
        if (item.code === "S-S") {
          const actions = {
            read: item.read,
            update: item.update,
          };
          setSelectedPermissionsSettings((prevState) => ({
            ...prevState,
            update: actions.update,
          }));
        }
      });
    }
  }, [roleData, show]);

  const handlePermissionChange = (moduleLabel, permissions) => {
    switch (moduleLabel) {
      case "Floors":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          floors: [permissions],
        }));
        break;
      case "Locations":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          locations: [permissions],
        }));
        break;
      case "Resources":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          resources: [permissions],
        }));
        break;
      case "Community":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          community: [permissions],
        }));
        break;
      case "Announcements":
        setSelectedPermissionsWPM((prevPermissions) => ({
          ...prevPermissions,
          announcements: [permissions],
        }));
        break;
      case "Team":
        setSelectedPermissionsPM((prevPermissions) => ({
          ...prevPermissions,
          team: [permissions],
        }));
        break;
      case "Employees":
        setSelectedPermissionsPM((prevPermissions) => ({
          ...prevPermissions,
          employees: [permissions],
        }));
        break;
      case "Roles":
        setSelectedPermissionsPM((prevPermissions) => ({
          ...prevPermissions,
          roles: [permissions],
        }));
        break;
      case "Visit":
        setSelectedPermissionsPM((prevPermissions) => ({
          ...prevPermissions,
          visit: [permissions],
        }));
        break;
      case "Invoices":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          invoices: [permissions],
        }));
        break;
      case "Members":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          members: [permissions],
        }));
        break;
      case "Packages":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          packages: [permissions],
        }));
        break;
      case "Quotations":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          quotations: [permissions],
        }));
        break;
      case "PartnersAndOffers":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          partnersAndOffers: [permissions],
        }));
        break;
      case "Tours":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          tours: [permissions],
        }));
        break;
      case "Tickets":
        setSelectedPermissionsAF((prevPermissions) => ({
          ...prevPermissions,
          tickets: [permissions],
        }));
        break;
      case "Bookings":
        setSelectedPermissionsMR((prevPermissions) => ({
          ...prevPermissions,
          bookings: [permissions],
        }));
        break;
      default:
        break;
    }
  };

  const generatePermissionObject = (permissionsData) => {
    if (permissionsData?.length == 0) {
      return processPermission({
        create: false,
        read: false,
        update: false,
        delete: false,
      });
    }

    return processPermission(permissionsData[0]);
  };

  const processPermission = (permission) => {
    if (
      !(
        permission.create ||
        permission.read ||
        permission.update ||
        permission.delete
      )
    ) {
      return {
        create: 0,
        read: 0,
        update: 0,
        delete: 0,
      };
    }

    let permissionsValue = {
      create: permission.create ? 1 : 0,
      read: permission.read ? 1 : 0,
      update: permission.update ? 1 : 0,
      delete: permission.delete ? 1 : 0,
    };

    return permissionsValue;
  };

  const postRoles = (e) => {
    e.preventDefault();

    const permissions = [];

    permissions?.push(
      {
        code: "WPM-F",
        ...generatePermissionObject(selectedPermissionsWPM.floors),
      },
      {
        code: "WPM-L",
        ...generatePermissionObject(selectedPermissionsWPM.locations),
      },
      {
        code: "WPM-R",
        ...generatePermissionObject(selectedPermissionsWPM.resources),
      },
      {
        code: "WPM-C",
        ...generatePermissionObject(selectedPermissionsWPM.community),
      },
      {
        code: "WPM-A",
        ...generatePermissionObject(selectedPermissionsWPM.announcements),
      },
      {
        code: "PM-T",
        ...generatePermissionObject(selectedPermissionsPM.team),
      },
      {
        code: "PM-E",
        ...generatePermissionObject(selectedPermissionsPM.employees),
      },
      {
        code: "PM-R",
        ...generatePermissionObject(selectedPermissionsPM.roles),
      },
      {
        code: "PM-V",
        ...generatePermissionObject(selectedPermissionsPM.visit),
      },
      {
        code: "AF-I",
        ...generatePermissionObject(selectedPermissionsAF.invoices),
      },
      {
        code: "AF-M",
        ...generatePermissionObject(selectedPermissionsAF.members),
      },
      {
        code: "AF-P",
        ...generatePermissionObject(selectedPermissionsAF.packages),
      },
      {
        code: "AF-Q",
        ...generatePermissionObject(selectedPermissionsAF.quotations),
      },
      {
        code: "AF-PO",
        ...generatePermissionObject(selectedPermissionsAF.partnersAndOffers),
      },
      {
        code: "AF-T",
        ...generatePermissionObject(selectedPermissionsAF.tours),
      },
      {
        code: "AF-TI",
        ...generatePermissionObject(selectedPermissionsAF.tickets),
      },
      {
        code: "MRM-B",
        ...generatePermissionObject(selectedPermissionsMR.bookings),
      },
      {
        code: "S-S",
        // update: parseInt(selectedPermissionsSettings.update),
        update: 1,

        read: 1,
      }
    );

    const updatedData = {
      name: name,
      description: description,
      permissions: permissions,
    };
    setApiSpinner(true);

    updateRole({
      spaceId: spaceId || currentSpaceId,
      id: roleData?.id,
      updateData: updatedData,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      if (resultStats?.error?.data?.error) {
        requiredAlert(`Role ${resultStats?.error?.data?.error}`);
      } else {
        requiredAlert(`Role Name Must Not Be Empty`);
      }
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Role Updated SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      handleClose();
    }
  }, [resultStats]);

  return (
    <>
      <div
        // variant="primary"
        onClick={rolesPermissions[0]?.create ? handleShow : null}
        // className="send-invoice-btn"
      >
        {rolesPermissions[0]?.create ? (
          <span className="btn-link">Update Role</span>
        ) : (
          <span className="btn-link">
            Update Role
            <Lock />
          </span>
        )}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        fade={true}
      >
        <Modal.Header closeButton className="">
          <Modal.Title className="mt-3" as="h3">
            Update Role
            <p className="text-muted">Set role permissions</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row p-4">
            <div className="col-md-6 ">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter a role name"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description <span className="optional">(Optional)</span>
                </label>
                {/* <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Enter role's description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                /> */}
                <textarea
                  rows={2}
                  className="form-control"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {/* <ReactQuill
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  // style={{ height: "20rem" }}
                  className=""
                  placeholder="Write Your Description Here....."
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                /> */}
              </div>
            </div>
          </div>
          <div className="p-4">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>WorkPlace Management</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12 mt-2">
                    <PermissionCheckboxGroup
                      label="Floors"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsWPM?.floors}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Locations"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsWPM?.locations}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Resources"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsWPM.resources}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Community"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsWPM.community}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Announcements"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsWPM.announcements}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Peoples' Management</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12 mt-2">
                    <PermissionCheckboxGroup
                      label="Team"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsPM.team}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Employees"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsPM.employees}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Roles"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsPM.roles}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Visit"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsPM.visit}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Administrative Functions</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12 mt-2">
                    <PermissionCheckboxGroup
                      label="Invoices"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsAF.invoices}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Members"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsAF.members}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Packages"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsAF.packages}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Quotations"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsAF.quotations}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="PartnersAndOffers"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsAF.partnersAndOffers}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Tours"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsAF.tours}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />

                    <PermissionCheckboxGroup
                      label="Tickets"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsAF.tickets}
                    />
                    <hr style={{ margin: "0.5rem 0" }} />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <Accordion className="mt-3">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Meeting Room Management</Accordion.Header>
                <Accordion.Body>
                  <div className="col-12 mt-2">
                    <PermissionCheckboxGroup
                      label="Bookings"
                      handlePermissionChange={handlePermissionChange}
                      permissions={selectedPermissionsMR.bookings}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Settings
            <div className="col-12">
              <PermissionCheckboxGroup
                label="Settings"
                handlePermissionChange={handlePermissionChange}
              />
            </div> */}
          </div>
          <button
            className="btn btn-secondary ml-5"
            disabled={apiSpinner}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary ml-5"
            // disabled={apiSpinner}
            onClick={postRoles}
          >
            Save{" "}
            {apiSpinner ? (
              <div
                className="spinner-border text-primary"
                role="status"
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                }}
              ></div>
            ) : (
              ""
            )}
          </button>
        </Modal.Body>

        {/* <Modal.Footer> */}
      </Modal>
    </>
  );
};

export default UpdateRoleModal;
