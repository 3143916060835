import React, { useRef, useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import * as Icon from "react-bootstrap-icons";
import {
  useAssignPackageMutation,
  useAssignPackageToMemberMutation,
  useCreateCompanyMutation,
  useGetUnassignedResourcesQuery,
  useMultiplePackagesCompanyMutation,
} from "../../Slices/spaceApi";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import AsignPackage from "./AsignPackage";
import axios from "axios";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { localDateTimeToUTC } from "../../utils/localDateTimeToUTC";
import { localDateToUTC } from "../../utils/localDateToUtc";
import AssignOccupancy from "./AssignOccupancy";

const AddPackageForMember = ({
  id,
  FetchPackageData,
  FetchMeetingPackageData,
  memberName,
  SpaceTypes,
  Resources,
}) => {
  const { membersPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const [assignPackage, resultAssign] = useAssignPackageToMemberMutation();
  const { data: FetchResources, isLoading: resourceLoading } =
    useGetUnassignedResourcesQuery({ spaceId: spaceId || currentSpaceId });

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }

  const [data, setData] = useState({
    meetingPackageIds: [
      {
        packageId: 1,
        quantity: 1,
      },
    ],
    spacePackageIds: [
      {
        packageId: 1,
        quantity: 1,
      },
    ],
    startTime: getCurrentDate(),
    meetingPackageIds: [],
    discount: 0,
    carryForward: "",
    companyShare: "",
  });
  const resetForm = () => {
    setData({
      packageId: FetchPackageData?.[0]?.id,
      spacePackageIds: [],
      startTime: getCurrentDate(),
      meetingPackageIds: [],
      carryForward: "",
      companyShare: "",
      discount: 0,
    });
    setCompanyResponse("");
  };

  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  const [companyResponse, setCompanyResponse] = useState(null);
  const [apiSpinner, setApiSpinner] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    resetForm();
    setRows([
      {
        packageId: 0,
        quantity: 1,
        isRecurring: false,
        resources: [],
      },
    ]);
    setMeetingRows([
      {
        packageId: 0,
        quantity: 1,
        isRecurring: false,
      },
    ]);
  };

  const handleShow = () => setShow(true);

  const [rows, setRows] = useState([
    {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
      resources: [],
    },
  ]);

  const updateRowResources = (selectedResources, rowIndex) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex].resources = selectedResources?.map(
      (resource) => resource.id
    );
    setRows(updatedRows);
  };

  const updateResourcesCallback = (spaceType, resourceIds) => {
    const updatedRows = rows.map((row) => {
      if (row.packageId === spaceType) {
        return { ...row, resources: resourceIds };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const [meetingRows, setMeetingRows] = useState([
    {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
    },
  ]);

  const handleAddRow = () => {
    const newRow = {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
      resources: [],

      // price: FetchPackageData?.[0]?.price,
      // amount: FetchPackageData?.[0]?.price,
    };
    setRows([...rows, newRow]);
  };
  const handleAddMeetingRow = () => {
    const newRow = {
      packageId: 0,
      quantity: 1,
      isRecurring: false,
      // price: FetchMeetingPackageData?.[0]?.price,
      // amount: FetchMeetingPackageData?.[0]?.price,
    };
    setMeetingRows([...meetingRows, newRow]);
  };
  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRemoveMeetingRow = (index) => {
    const updatedRows = [...meetingRows];
    updatedRows.splice(index, 1);
    setMeetingRows(updatedRows);
  };

  const handleInputChange = (event, index) => {
    let data = [...rows];
    data[index].packageId = event.id;
    setRows(data);
  };
  const handleRowQuantity = (event, index) => {
    let data = [...rows];
    data[index].quantity = event.target.value;
    setRows(data);
  };
  const handlePackageRecurring = (event, index) => {
    let data = [...rows];
    data[index].isRecurring = !data[index].isRecurring;
    setRows(data);
  };
  const handleMeetingInputChange = (event, index) => {
    let data = [...meetingRows];
    data[index].packageId = event.id;
    setMeetingRows(data);
  };
  const handleMeetingQuantityChanged = (event, index) => {
    let data = [...meetingRows];
    data[index].quantity = event.target.value;
    setMeetingRows(data);
  };
  const handleMeetingPackageRecurring = (event, index) => {
    let data = [...meetingRows];
    data[index].isRecurring = !data[index].isRecurring;
    setMeetingRows(data);
  };
  useEffect(() => {
    if (resultAssign?.isError) {
      setApiSpinner(false);
      requiredAlert(resultAssign?.error?.data?.message);
    }

    if (resultAssign?.isSuccess) {
      setTimeout(() => {
        resetForm();
      }, 1500);
      setApiSpinner(false);

      handleClose();

      toast.success("Package Added Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      //   navigate("/member/list");
    }
  }, [resultAssign]);

  const handleChange = (event) => {
    setData({ ...data, startTime: event.target.value });
  };
  const handleDiscountChange = (event) => {
    setData({ ...data, discount: event.target.value });
  };

  const postAsignPackage = (e) => {
    e.preventDefault();
    const { spacePackageIds, meetingPackageIds, discount, startTime } = data;
    let timeStampStartTime = toTimestamp(startTime);

    // const updateSpacePackageIds = rows?.map((item) => ({
    //   quantity: parseInt(item.quantity),
    //   package: parseInt(item.packageId),
    // }));

    // const updateMeetingPackageIds = meetingRows?.map((item) => ({
    //   quantity: parseInt(item.quantity),
    //   package: parseInt(item.packageId),
    // }));
    const updateSpacePackageIds = rows
      ?.map((item) => ({
        quantity: parseInt(item.quantity),
        package: parseInt(item.packageId),
        isRecurring: item.isRecurring,
        resources: item.resources,
      }))
      .filter((item) => item.quantity !== 0 && item.package !== 0);

    const updateMeetingPackageIds = meetingRows
      ?.map((item) => ({
        quantity: parseInt(item.quantity),
        package: parseInt(item.packageId),
        isRecurring: item.isRecurring,
      }))
      .filter((item) => item.quantity !== 0 && item.package !== 0);

    let updateData = {
      member: parseInt(id),
      space: spaceId || currentSpaceId,
      packages: updateSpacePackageIds,
      meeting_packages: updateMeetingPackageIds,
      discount: parseInt(discount),
      // start_date: localDateToUTC(startTime),
      start_date: startTime,
    };

    setApiSpinner(true);
    assignPackage({
      spaceId: spaceId || currentSpaceId,
      userId: id,
      data: updateData,
    });
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={membersPermissions[0]?.create ? handleShow : null}
        className="send-invoice-btn ml-4 mb-2"
      >
        {membersPermissions[0]?.create ? (
          <span className="invoice">
            <Plus /> Assign Package
          </span>
        ) : (
          <span className="invoice">
            <Plus /> Assign Package
            <Lock />
          </span>
        )}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">
            Assign Package to <b>{memberName}</b>
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={postAsignPackage}>
          <Modal.Body>
            <section>
              <>
                <div className="form-group">
                  <label htmlFor="startTime">Joining Date:</label>
                  <div className="input-group ">
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                        }}
                      >
                        <Icon.Calendar />
                      </span>
                    </div>
                    <input
                      type="date"
                      name="startTime"
                      id="startTime"
                      className="form-control"
                      value={data?.startTime}
                      onChange={handleChange}
                      min={getCurrentDate()}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="package-facilities" className="">
                    Assign Space Package(s)*
                  </label>
                  {FetchPackageData?.length > 0 &&
                    rows?.map((input, index) => (
                      <div key={index} className=" my-2">
                        <div className="">
                          <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                            <div className="row w-100 pe-lg-0 pe-3">
                              <div className="col-12 mb-lg-0 mb-2 mt-lg-0">
                                <p className="card-text col-title mb-md-50 mb-0">
                                  Select package to assign
                                </p>
                                {/* <select
                                  name="packageId"
                                  value={input?.packageId}
                                  onChange={(e) => handleInputChange(e, index)}
                                  className="form-control"
                                >
                                  {FetchPackageData?.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select> */}
                                <Select
                                  name="packageId"
                                  options={FetchPackageData}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  styles={colorStyles}
                                  value={FetchPackageData?.find(
                                    (obj) => obj.id === rows?.[index]?.packageId
                                  )}
                                  onChange={(selectedOption) =>
                                    handleInputChange(selectedOption, index)
                                  }
                                />
                              </div>

                              <div className="col-12 my-lg-0 my-2">
                                <p className="card-text col-title mb-0 mt-2">
                                  Number of Packages/Seats
                                </p>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="1"
                                  min={1}
                                  name="quantity"
                                  value={input?.quantity}
                                  onChange={(e) => handleRowQuantity(e, index)}
                                />
                              </div>
                              <div className="form-check mt-3">
                                <label
                                  className="form-check-label"
                                  htmlFor="recurringPackage"
                                >
                                  Is this Package Recurring!
                                </label>
                                <input
                                  type="checkbox"
                                  className="form-check-input ml-4"
                                  id="recurringPackage"
                                  checked={input?.isRecurring}
                                  onChange={(e) =>
                                    handlePackageRecurring(e, index)
                                  }
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                              <p
                                className="bg-white"
                                onClick={() => handleRemoveRow(index)}
                              >
                                <CancelIcon />
                              </p>
                            </div>
                          </div>
                        </div>
                        {input?.packageId ? (
                          <AssignOccupancy
                            packageId={input?.packageId}
                            packageQuantity={input?.quantity}
                            AllSpacePackages={FetchPackageData}
                            // Resources={Resources}
                            Resources={FetchResources}
                            SpaceTypes={SpaceTypes}
                            updateRowResources={(selectedResources) =>
                              updateRowResources(selectedResources, index)
                            }
                            // updateRowResources={updateResourcesCallback}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                  {FetchPackageData?.length <= 0 && (
                    <p>No Package is Available</p>
                  )}

                  <button
                    type="button"
                    className="btn btn-link "
                    onClick={handleAddRow}
                  >
                    Associate More Packages
                  </button>
                </div>
                <hr />
                <div className="form-group">
                  <label htmlFor="meetingPackageIds" className="mb-1">
                    Assign Meeting Room Package(s)*
                  </label>

                  {FetchMeetingPackageData?.length > 0 &&
                    meetingRows?.map((input, index) => (
                      <div key={index} className="my-2">
                        <div className="">
                          <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                            <div className="row w-100 pe-lg-0 pe-3">
                              <div className="col-12 mb-lg-0 mb-2 mt-lg-0">
                                <p className="card-text col-title mb-md-50 mb-0">
                                  Select package to assign
                                </p>
                                {/* <select
                                  name="packageId"
                                  value={input?.packageId}
                                  onChange={(e) =>
                                    handleMeetingInputChange(e, index)
                                  }
                                  className="form-control"
                                >
                                  {FetchMeetingPackageData?.map(
                                    (item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    }
                                  )}
                                </select> */}
                                <Select
                                  name="packageId"
                                  options={FetchMeetingPackageData}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  styles={colorStyles}
                                  value={FetchMeetingPackageData?.find(
                                    (obj) =>
                                      obj.id === meetingRows?.[index]?.packageId
                                  )}
                                  onChange={(selectedOption) =>
                                    handleMeetingInputChange(
                                      selectedOption,
                                      index
                                    )
                                  }
                                />
                              </div>

                              <div className="col-12 my-lg-0 my-2">
                                <p className="card-text col-title mb-0 mt-2">
                                  Number of Packages/Seats
                                </p>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="1"
                                  min={1}
                                  name="quantity"
                                  value={input?.quantity}
                                  onChange={(e) =>
                                    handleMeetingQuantityChanged(e, index)
                                  }
                                />
                              </div>
                              <div className="form-check mt-3">
                                <label
                                  className="form-check-label"
                                  htmlFor="recurringPackage"
                                >
                                  Is this Package Recurring!
                                </label>
                                <input
                                  type="checkbox"
                                  className="form-check-input ml-4"
                                  id="recurringPackage"
                                  checked={input?.isRecurring}
                                  onChange={(e) =>
                                    handleMeetingPackageRecurring(e, index)
                                  }
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                              <p
                                className="bg-white"
                                onClick={() => handleRemoveMeetingRow(index)}
                              >
                                <CancelIcon />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {FetchMeetingPackageData?.length <= 0 && (
                    <p>No Meeting Package is Available</p>
                  )}
                  <div className="">
                    <div className="px-0 mt-3">
                      <button
                        type="button"
                        className="btn btn-link send-invoice-btn"
                        data-repeater-create=""
                        onClick={handleAddMeetingRow}
                      >
                        Associate More Packages
                      </button>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="discount">Discount (%):</label>
                  <input
                    type="number"
                    name="discount"
                    id="discount"
                    placeholder="Discount %"
                    className="form-control"
                    value={data?.discount}
                    onChange={handleDiscountChange}
                    min={0}
                  />
                </div>
              </>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <>
              <Button
                variant="primary"
                disabled={apiSpinner}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="primary" disabled={apiSpinner} type="submit">
                Assign Package{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
            </>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddPackageForMember;
