import React, { useState } from "react";

import { useSelector } from "react-redux";
import {
  useGetAllSpaceUserQuery,
  useGetAllUpComingBookingQuery,
  useGetCompaniesQuery,
  useGetIndividualsQuery,
  useGetMeetingRoomQuery,
} from "../../Slices/spaceApi";
import BookingMeetingTable from "./BookingTable";
import { Toaster } from "react-hot-toast";
import "react-calendar/dist/Calendar.css";
const BookingList = ({
  companies,
  meetingData,
  FetchUpComingBooking,
  Users,
}) => {
  // const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  // const { data: FetchCompanies, isLoading: loading } = useGetCompaniesQuery({
  //   spaceId: spaceId || currentSpaceId,
  // });
  // const { data: FetchUsers, isLoading: userLoading } = useGetIndividualsQuery({
  //   spaceId: spaceId || currentSpaceId,
  // });
  // const { data: FetchMeetingData, isLoading } = useGetMeetingRoomQuery({
  //   id: spaceId || currentSpaceId,
  //   room_type: 1,
  // });

  // const { isLoading: bookingLoading, data: FetchUpComingBooking } =
  //   useGetAllUpComingBookingQuery({
  //     spaceId: spaceId || currentSpaceId,
  //     past: "False",
  //   });
  // if (bookingLoading || loading || isLoading || userLoading) {
  //   return <Loader height={6.6} />;
  // }
  const [selectedDate, setSelectedDate] = useState(new Date());

  return (
    <section
      id="booking"
      className="container-fluid with-bottom-padding"
      style={{ padding: "0px 16px" }}
    >
      {/* <div className="datepicker-container">
        <Calendar
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
        />
      </div> */}
      <div className="card">
        <BookingMeetingTable
          companies={companies}
          meetingData={meetingData}
          FetchUpComingBooking={FetchUpComingBooking}
          Users={Users}
        />

        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </section>
  );
};

export default BookingList;
