import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDeleteDeactivateMutation } from "../../Slices/spaceApi";
import { useEffect } from "react";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";
import toast from "react-hot-toast";
import { requiredAlert } from "../../utils/Alerts";
function DeactivateAccount() {
  const { settingsPermissions } = useGlobalContext();

  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState(null);
  const [isButtonActive, setIsButtonActive] = useState(false);

  const { currentSpaceId, spaceId } = useSelector((item) => item.spaceReducer);
  const [deactivateAccount, resultStats] = useDeleteDeactivateMutation();

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setPassword("");
    setIsButtonActive(false);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setIsButtonActive(event.target.value.trim().length > 0);
  };

  const handleDeactivate = () => {
    deactivateAccount({
      spaceId: spaceId || currentSpaceId,
      type: 2,
      data: { password: password },
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );
      // requiredAlert(resultStats?.error?.data?.error);
      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setIsChecked(false);

      toast.success(
        "Your account deactivate request has been received. Please check your email and verify!",
        {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }
      );
    }
  }, [resultStats]);
  return (
    <div className="card p-3">
      <h5>
        Are you sure you want to deactivate your user account temporarily? This
        action will temporarily suspend your account, and you can reactivate it
        at any time by contacting administrator.
      </h5>
      <div className="form-check mt-3">
        <input
          type="checkbox"
          className="form-check-input"
          id="deactivateCheckbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label className="form-check-label" htmlFor="deactivateCheckbox">
          I confirm my account deactivation
        </label>
      </div>

      {isChecked && (
        <div className="password-container mt-3">
          <div className="form-group">
            <label htmlFor="passwordField">Password:</label>
            <input
              type="password"
              className="form-control"
              id="passwordField"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter your password"
            />
          </div>
          <button
            className="btn bg-danger text-white"
            onClick={settingsPermissions[0]?.update ? handleDeactivate : null}
            disabled={!isButtonActive || !settingsPermissions[0]?.update}
          >
            {settingsPermissions[0]?.update ? (
              "Deactivate"
            ) : (
              <span>Deactivate{Lock2}</span>
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default DeactivateAccount;
