import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useUpdateVisitsDataMutation,
  useUpdateVisitsQuery,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import * as Icon from "react-bootstrap-icons";
import { useState } from "react";
import { useEffect } from "react";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { isError } from "react-query";
import { useGlobalContext } from "../../Context/GlobalContext";
function VisitUpdate() {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { id } = useParams();
  const navigate = useNavigate();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Update Visit");
  }, []);
  const { data: visitData, isLoading: loading } = useUpdateVisitsQuery({
    spaceId: spaceId || currentSpaceId,
    id: id,
  });
  const [updateVisitsApi, resultStats] = useUpdateVisitsDataMutation();
  const [apiSpinner, setApiSpinner] = useState(false);

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDateTime;
  };
  const [visitsData, setVisitData] = useState({
    expected_visit_datetime: "",
    location: "",
  });

  const [locationError, setLocationError] = useState("");
  const [dateError, setDateError] = useState("");

  useEffect(() => {
    setVisitData({
      expected_visit_datetime: visitData?.expected_visit_datetime,
      location: visitData?.location,
    });
  }, [visitData]);

  const calculateMinDateTime = () => {
    const currentDateTime = new Date();
    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateTime.getDate()).padStart(2, "0");
    const hours = String(currentDateTime.getHours()).padStart(2, "0");
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");

    const minDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return minDateTime;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVisitData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "expected_visit_datetime") {
      const selectedDateTime = new Date(value);
      const currentDateTime = new Date();

      if (selectedDateTime < currentDateTime) {
        setDateError("Date or time cannot be in the past");
        return;
      } else {
        setDateError("");
      }
    }
  };

  const handleSubmit = () => {
    const hasLocationError = visitsData.location.trim() === "";
    if (hasLocationError) {
      setLocationError("*Enter Spot Reserved");
      return;
    }
    if (dateError) {
      return;
    }
    const updatedData = {
      expected_visit_datetime: visitsData?.expected_visit_datetime,
      location: visitsData?.location,
    };
    setApiSpinner(true);
    updateVisitsApi({
      spaceId: spaceId || currentSpaceId,
      id: id,
      updateData: updatedData,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Visit Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      setVisitData({
        expected_visit_datetime: "",
        location: "",
      });
      navigate("/visit/list");
    }
  }, [resultStats]);
  if (loading) {
    return <Loader height={6.6} />;
  }
  return (
    <div className="registration" style={{ padding: "0px 16px" }}>
      <div className={`card  mx-auto`}>
        <div className="card-body">
          <div className="col-md-10 mb-3">
            <label htmlFor="visitor">Visitor</label>
            <select id="selectVisitor" name="visitor" className="form-control">
              {/* <option disabled value="">
                Select Visitor
              </option> */}
              <option value="">{visitData?.visitor?.name}</option>
            </select>
            <Link to="/visitors/add">
              <p
                className="text-primary mt-1 fw-bold ml-2"
                style={{ cursor: "pointer" }}
              >
                Add New Visitor
              </p>
            </Link>

            <div className="col-md-10">
              <div className="row">
                <div className="col-md-6 ">
                  <div className="form-group">
                    <label htmlFor="date">Date</label>
                    <div className="input-group ">
                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          style={{
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                          }}
                        >
                          <Icon.Calendar2Check />
                        </span>
                      </div>
                      <input
                        type="datetime-local"
                        className="form-control"
                        name="expected_visit_datetime"
                        value={formatDateTime(
                          visitsData?.expected_visit_datetime
                        )}
                        onChange={handleChange}
                        min={calculateMinDateTime()}
                      />
                    </div>
                    {dateError && (
                      <span className="text-danger ml-2">*{dateError}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="location">Location</label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      value={visitsData?.location}
                      className={`form-control ${
                        locationError ? "is-invalid" : ""
                      }`}
                      onChange={handleChange}
                    />
                    {locationError && (
                      <div className="invalid-feedback">{locationError}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="row mt-lg-5 mt-md-4 mt-sm-3 mt-2"></div>
          <div className="row mt-lg-5 mt-md-4 mt-sm-3 mt-2"></div>
        </div>

        <div className="text-right mb-4 mr-4">
          <button
            className="btn btn-primary"
            disabled={apiSpinner}
            onClick={handleSubmit}
          >
            Update Visit{" "}
            {apiSpinner ? (
              <div
                className="spinner-border text-primary"
                role="status"
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                }}
              ></div>
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default VisitUpdate;
