import React, { useEffect, useState } from "react";
import {
  useCreateMessageMutation,
  useDeleteMessageMutation,
  useGetMessagesQuery,
  useGetSpecficTicketQuery,
  useUpdateMessageMutation,
  useUpdateTicketMutation,
} from "../Slices/spaceApi";
import * as Icon from "react-bootstrap-icons";

import { requiredAlert, userDeleteAlert } from "../utils/Alerts";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetSupportCategoriesQuery } from "../Slices/constantApi";
import Loader from "../Components/Loader/Loader";
import {
  Accordion,
  Badge,
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Form,
} from "react-bootstrap";
import UserIcon from "../Assets/UserIcon.svg";
// import Accordion from "react-bootstrap/Accordion";

import "react-quill/dist/quill.snow.css";

import ReactQuill from "react-quill";
import { TimeAgo } from "../utils/timeAgo";
import { useGlobalContext } from "../Context/GlobalContext";

const ViewTicketPage = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Ticket View");
  }, []);
  const { id } = useParams();

  const { data, isLoading } = useGetSpecficTicketQuery({
    spaceId: spaceId || currentSpaceId,
    ticketId: id,
  });

  const { data: messagesData, isLoading: messagingLoading } =
    useGetMessagesQuery({
      spaceId: spaceId || currentSpaceId,
      ticketId: id,
    });

  const { data: supportCategories, isLoading: categoriesLoading } =
    useGetSupportCategoriesQuery();

  const [updateTicketByAPi, resultStats2] = useUpdateTicketMutation();
  const [createMessageByApi, resultStats3] = useCreateMessageMutation();
  const [deleteMessageByApi, resultStats4] = useDeleteMessageMutation();
  const [updateMessageByApi, resultStats5] = useUpdateMessageMutation();

  const [apiSpinner, setApiSpinner] = useState(false);
  const [editApiSpinner, setEditApiSpinner] = useState(false);

  const [text, setText] = useState("");
  const [editReply, setEditReply] = useState("");
  const [editId, setEditId] = useState();

  const [checked, setChecked] = useState(false);
  const [statusAction, setStatusAction] = useState(false);
  const [statusValue, setStatusValue] = useState(data?.status);

  useEffect(() => {
    setStatusValue(data?.status);
  }, [data]);

  const handleStatusAction = (value) => {
    setStatusValue(value);
    setStatusAction(true);
  };

  const changeStatusByApi = (e) => {
    e.preventDefault();
    setStatusAction(false);
    updateTicketByAPi({
      spaceId: spaceId || currentSpaceId,
      data: { status: statusValue },
      ticketId: id,
    });
  };

  const handleChange = () => {
    if (!checked) {
      updateTicketByAPi({
        spaceId: spaceId || currentSpaceId,
        data: { status: 4 },
        ticketId: id,
      });
    } else {
      updateTicketByAPi({
        spaceId: spaceId || currentSpaceId,
        data: { status: 2 },
        ticketId: id,
      });
    }

    setChecked(!checked);
  };

  useEffect(() => {
    if (resultStats2?.isError) {
      const errorMessage = Object.values(resultStats2?.error?.data).find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
    if (resultStats2.isSuccess) {
      toast.success("Status Changed Successfully.");
    }
  }, [resultStats2]);

  const postRelpyComment = () => {
    setApiSpinner(true);

    createMessageByApi({
      spaceId: spaceId || currentSpaceId,
      ticketId: id,
      data: { message: text },
    });
  };

  useEffect(() => {
    if (resultStats3?.isError) {
      setApiSpinner(false);
      const errorMessage = Object?.values(resultStats3?.error?.data).find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }

    if (resultStats3?.isSuccess) {
      setApiSpinner(false);

      toast.success("Reply Added SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setText("");

      updateTicketByAPi({
        spaceId: spaceId || currentSpaceId,
        data: { status: 3 },
        ticketId: id,
      });
    }
  }, [resultStats3]);

  const handleEditReply = () => {
    setEditApiSpinner(true);
    updateMessageByApi({
      spaceId: spaceId || currentSpaceId,
      ticketId: id,
      messageId: editId,
      data: { message: editReply },
    });
  };
  const getVariant = () => {
    switch (statusValue) {
      case 1:
        return "success";
      case 2:
        return "warning";
      case 3:
        return "info";
      case 4:
        return "secondary";
      default:
        return "primary";
    }
  };
  const handleEditReplyCheck = (item) => {
    setEditReply(item.message);
    setEditId(item.id);
  };

  const handleDeleteReply = (item) => {
    deleteMessageByApi({
      spaceId: spaceId || currentSpaceId,
      ticketId: id,
      messageId: item.id,
    });
  };

  useEffect(() => {
    if (resultStats4?.isSuccess) {
      userDeleteAlert("Reply Deleted Successfully.");
    }
    if (resultStats4?.isError) {
      const errorMessage = Object.values(resultStats4?.error?.data).find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
  }, [resultStats4]);

  useEffect(() => {
    if (resultStats5?.isError) {
      setEditApiSpinner(false);
      const errorMessage = Object.values(resultStats5?.error?.data).find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats5?.isSuccess) {
      setEditApiSpinner(false);

      toast.success("Reply Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setEditReply("");
      setEditId("");
    }
  }, [resultStats5]);
  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}, ${formattedTime}`;
  }
  if (isLoading || messagingLoading || categoriesLoading) {
    return <Loader height={6.6} />;
  }
  return (
    <div className="container-fluid" style={{ padding: "0px 18px" }}>
      <Breadcrumb className="custom-breadcrumb ml-auto">
        <Breadcrumb.Item>
          <Link to="/tickets">Tickets List</Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item active>View Ticket #{id}</Breadcrumb.Item>
      </Breadcrumb>
      <h4 className="mb-4">
        Ticket #{id} - {data?.purpose}
      </h4>

      <div>
        <div className="row">
          <h6>Ticket Information</h6>
          <div className="col-md-5">
            {/* <Card sx={{ display: "flex", flexDirection: "column", mt: 1 }}> */}
            <div className="card">
              <div className="d-flex flex-column">
                <div style={{ padding: "16px 16px" }}>
                  <p className="mb-2 fw-bold">Creator</p>
                  <div className="d-flex align-items-center">
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      {data?.raisedBy?.name}
                    </p>
                    <Badge bg="badge bg-label-primary" className="ms-2">
                      {data?.raisedBy?.type}
                    </Badge>
                  </div>
                </div>
                <hr className="my-0" />
                <div style={{ padding: "16px 16px" }}>
                  <p className="mb-2 fw-bold">Raised By</p>
                  <div className="d-flex align-items-center">
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      {data?.creator?.name}
                    </p>
                  </div>
                </div>
                <hr className="my-0" />
                <div style={{ padding: "16px 16px" }}>
                  <p className="mb-2 fw-bold">Category</p>
                  <div>
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      {supportCategories?.find((category) =>
                        category.children.some(
                          (child) => child.id === data.category
                        )
                      )?.name || "N/A"}
                    </p>
                  </div>
                </div>
                <hr className="my-0" />
                <div style={{ padding: "16px 16px" }}>
                  <p className="mb-2 fw-bold">Submitted</p>
                  <div>
                    <p className="mb-0" style={{ fontWeight: "500" }}>
                      {formatDateTime(data?.submit_date).toLocaleString()}
                    </p>
                  </div>
                </div>
                <hr className="my-0" />
                <div style={{ padding: "16px 16px" }}>
                  <Badge bg="badge bg-label-info" className="mb-2  fw-bold">
                    Description
                  </Badge>
                  <div>
                    <p className="mb-0 ml-1" style={{ fontWeight: "500" }}>
                      {data.description ? (
                        data.description
                      ) : (
                        <Badge bg="danger">No Description Provided</Badge>
                      )}
                    </p>
                  </div>
                </div>

                <hr className="my-0" />
                <div
                  className="d-flex justify-content-between"
                  style={{ padding: "16px 16px" }}
                >
                  <p className="mb-2 mt-1 fw-bold">Status</p>
                  <div className="d-flex flex-column">
                    <Dropdown>
                      <Dropdown.Toggle
                        // variant={getVariant().toLowerCase()}
                        // variant="badge bg-label-success"
                        variant={
                          statusValue == 1
                            ? "badge bg-label-success"
                            : statusValue == 2
                            ? "badge bg-label-warning"
                            : statusValue == 3
                            ? "badge bg-label-info"
                            : statusValue == 4
                            ? "badge bg-label-secondary"
                            : "badge bg-label-primary"
                        }
                        id="dropdown-basic"
                      >
                        {statusValue == 1
                          ? "Open"
                          : statusValue == 2
                          ? "Waiting for Response"
                          : statusValue == 3
                          ? "Answered"
                          : statusValue == 4
                          ? "On Hold"
                          : "Closed"}
                        <Icon.CaretDownFill className="ml-1" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleStatusAction(1)}>
                          Open
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleStatusAction(2)}>
                          Waiting for Response
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleStatusAction(3)}>
                          Answered
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleStatusAction(4)}>
                          On Hold
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleStatusAction(5)}>
                          Close
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <button
                  className="btn btn-primary btn-sm col-2 ml-auto mr-4 mb-3"
                  disabled={!statusAction}
                  onClick={changeStatusByApi}
                >
                  Save
                </button>
                {/* <hr />
                <div className="d-flex mt-2 mb-2 justify-content-around">
                  <Form className="ml-2">
                    <Form.Switch
                      id="custom-switch"
                      label="Want to hold ticket?"
                      checked={checked}
                      onChange={handleChange}
                      disabled={resultStats2?.isLoading}
                    />
                  </Form>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <Card>
              <Accordion>
                <Accordion.Header>Reply</Accordion.Header>
                <Accordion.Body>
                  <Card.Body>
                    <ReactQuill
                      theme="snow"
                      value={text}
                      onChange={(value) => setText(value)}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          ["bold", "italic", "underline", "strike"],
                          ["link"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ align: [] }],
                        ],
                      }}
                    />
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        disabled={apiSpinner || text.length <= 0}
                        className="mt-3"
                        //   style={{ float: "right" }}
                        onClick={postRelpyComment}
                      >
                        Add Reply
                        {apiSpinner ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginLeft: "5px",
                            }}
                          ></div>
                        ) : (
                          ""
                        )}
                      </Button>
                    </div>
                  </Card.Body>
                </Accordion.Body>
              </Accordion>
            </Card>

            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              {messagingLoading ? (
                <div style={{ padding: "24px 24px" }}>Loading...</div>
              ) : messagesData?.messages?.length > 0 ? (
                messagesData?.messages?.map((item, idx) => (
                  <>
                    <Card.Header className="p-3 pb-0">
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div>
                          <p className="mb-0 fw-bold">
                            <img
                              src={UserIcon}
                              alt=""
                              width={22}
                              style={{ marginRight: "10px" }}
                            />
                            {item.owner ? item.owner.name : data?.creator.name}
                            <Badge
                              bg="badge bg-label-success"
                              style={{
                                marginLeft: "10px",
                                fontWeight: "normal",
                                fontSize: "13px",
                              }}
                            >
                              <TimeAgo date={item.created_at} />
                            </Badge>
                          </p>
                        </div>
                        {item.owner && (
                          <div className="align-items-end d-flex flex-column">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <Icon.ThreeDotsVertical
                                  style={{ cursor: "pointer" }}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleEditReplyCheck(item)}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleDeleteReply(item)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        )}
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <div
                        style={{
                          overflow: "auto",
                          wordWrap: "break-word",
                          // lineHeight: "16px",
                        }}
                        dangerouslySetInnerHTML={{ __html: item.message }}
                      />
                    </Card.Body>

                    {editReply && editId == item.id && (
                      <div style={{ padding: "10px 24px" }}>
                        <ReactQuill
                          theme="snow" // Other themes are available
                          value={editReply}
                          onChange={(value) => setEditReply(value)}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              ["bold", "italic", "underline", "strike"],
                              ["link"],
                              [{ list: "ordered" }, { list: "bullet" }],
                              [{ align: [] }],
                            ],
                          }}
                        />

                        <div className="d-flex justify-content-end">
                          <Button
                            variant="primary"
                            disabled={editApiSpinner || editReply?.length <= 0}
                            className="mt-3"
                            onClick={handleEditReply}
                          >
                            Edit Reply
                            {editApiSpinner ? (
                              <div
                                className="spinner-border text-primary"
                                role="status"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginLeft: "5px",
                                }}
                              ></div>
                            ) : (
                              ""
                            )}
                          </Button>
                        </div>
                      </div>
                    )}

                    <hr className="my-0" />
                  </>
                ))
              ) : (
                <div style={{ padding: "24px 24px" }}>No Reply Yet.</div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTicketPage;
