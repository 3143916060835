import React, { useState } from "react";
import { useEffect } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";

const PackageFields = (props) => {
  const { currency, isOverAllTax } = useGlobalContext();

  const { label, onChange, id, errorMessage, submitted, ...inputProps } = props;
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };

  const hasValue = !!inputProps.value;

  useEffect(() => {
    if (!hasValue && submitted) {
      setFocused(true);
    }
  }, [submitted]);
  const containerClassName = id === 1 ? "col-12 mb-2" : "mb-2";
  return (
    <div className="mb-2">
      <label htmlFor={id} className="mb-1">
        {label}
      </label>
      {id == 2 || id == 3 ? (
        <div className="form-group">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">{currency}</span>
            </div>
            <input
              {...inputProps}
              onChange={onChange}
              className="form-control"
              onBlur={handleFocus}
              focused={focused?.toString()}
            />
          </div>
        </div>
      ) : (
        <input
          {...inputProps}
          onChange={onChange}
          className="form-control"
          onBlur={handleFocus}
          focused={focused?.toString()}
        />
      )}

      <span className={"error"}>{errorMessage}</span>
    </div>
  );
};

export default PackageFields;
