import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";

import {
  useAllSpacesQuery,
  useGetAllCheckinsQuery,
  useGetAllMembersQuery,
  useOwnerLocationQuery,
  useSpaceGetQuery,
} from "../../../Slices/spaceApi";

import TabularReport from "../TabularReports";
import { useSelector } from "react-redux";
const InvoiceDetails = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const tableRef = useRef(null);

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: allMembers, isLoading: allMembersIsLoading } =
    useGetAllMembersQuery(spaceId || currentSpaceId);
  const [member, setMember] = useState(0);

  useEffect(() => {
    if (allMembers) {
      setMember(allMembers?.users?.[0]?.id);
    } else {
      setMember(0);
    }
  }, [allMembers]);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [page_size, setPage_size] = useState(10);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const { data, isLoading, refetch, isFetching } = useGetAllCheckinsQuery({
    spaceId: spaceId || currentSpaceId,
    start,
    end,
    page_size,
    page: page,
    location: location,
    member: member,
  });

  const formatTimeToAMPM = (timeString) => {
    const time = new Date(`2000-01-01T${timeString}`);
    const formattedTime = time.toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return formattedTime;
  };

  useEffect(() => {
    if ((data, !isLoading)) {
      setList(data?.checkIns);
    }
  }, [data]);

  const body = isFetching ? (
    <Loader />
  ) : (
    <div className="table-responsive" ref={tableRef}>
      <h5 className="ml-4">
        {allMembers?.users?.find((m) => m.id === member)?.name}
      </h5>

      <table className="table  dataTable">
        <thead>
          <tr>
            <th>#</th>
            <th> Date</th>
            <th>No of Checkins</th>
            <th>Checkin Timings</th>
          </tr>
        </thead>
        <tbody className="table-border-bottom-0">
          {!isLoading && data && list.length > 0 ? (
            list?.map((data, index) => (
              <React.Fragment key={index}>
                <tr key={index}>
                  <td className="serial bold">#{data.memberId}</td>

                  <td className="table-avatar">{data.date}</td>

                  <td className="table-avatar">{data.numberOfCheckIns}</td>
                  <td className="table-avatar">
                    {data?.checkInTimings?.length > 0 ? (
                      <ul>
                        {data.checkInTimings.map((item, index) => (
                          <li key={index}>{formatTimeToAMPM(item)}</li>
                        ))}
                      </ul>
                    ) : (
                      "----"
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))
          ) : list.length === 0 ? (
            <>
              <tr>
                <td>No Data</td>
              </tr>
            </>
          ) : (
            <tr>
              <td>Data is Loading</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
  return (
    <>
      {data && !isLoading && list ? (
        <TabularReport
          key={"bookingByWeekDays"}
          Child={() => body}
          data={data}
          isLoading={isLoading}
          mySpace={mySpace}
          allSpaces={allSpaces}
          tableRef={tableRef}
          list={list}
          setList={setList}
          ownerLocation={ownerLocation}
          page={page}
          setPage={setPage}
          // hideDate={true}
          //   hideSearch={true}

          member={member}
          setMember={setMember}
          memberSearch={true}
          membersList={allMembers?.users}
          page_size={page_size}
          setPage_size={setPage_size}
          location={location}
          setLocation={setLocation}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          refetch={refetch}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          setOptions={setOptions}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default InvoiceDetails;
