import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../Context/GlobalContext";
import {
  useGetAllCompaniesQuery,
  useGetAllIndividualsQuery,
  useGetAllUpComingBookingQuery,
  useGetMeetingRoomQuery,
} from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import BookingTableView from "./BookingTableView";
import FlexDeskBookingPage from "./FlexDeskBookingPage";

const HotDeskBookingList = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Bookings");
  }, []);

  const { data: FetchCompanies, isLoading: loading } = useGetAllCompaniesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: FetchUsers, isLoading: userLoading } =
    useGetAllIndividualsQuery({
      spaceId: spaceId || currentSpaceId,
    });
  const { data: FetchMeetingData, isLoading } = useGetMeetingRoomQuery({
    id: spaceId || currentSpaceId,
    room_type: 1,
    // hotDesk: "True",
  });

  const { isLoading: bookingLoading, data: FetchUpComingBooking } =
    useGetAllUpComingBookingQuery({
      spaceId: spaceId || currentSpaceId,
      past: "False",
      type: 3,
    });

  console.log("Upcoming Bookings are", FetchUpComingBooking);
  if (loading || isLoading || userLoading || bookingLoading) {
    return <Loader height={6.6} />;
  }

  return (
    <section
      id="booking"
      className="container-fluid with-bottom-padding"
      style={{ padding: "0px 16px" }}
    >
      {" "}
      <FlexDeskBookingPage
        companies={FetchCompanies?.users}
        meetingData={FetchMeetingData?.meetingRooms}
        FetchUpComingBooking={FetchUpComingBooking?.bookingss}
        Users={FetchUsers?.users}
      />
    </section>
  );
};

export default HotDeskBookingList;
