import React, { useState } from "react";
import MeetingPackageModal from "./MeetingPackageModal";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import DeleteMeetingPackage from "./DeleteMeetingPackage";
import UpdateMeetingPackage from "./UpdateMeetingPackage";
import ViewMeetingPackage from "./ViewMeetingPackage";
import { useGetSpaceDurationsQuery } from "../../Slices/spaceApi";
import {
  useGetMeetingRoomPackageQuery,
  useGetMeetingRoomQuery,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Loader from "../Loader/Loader";
import { Pagination } from "react-bootstrap";
import Select from "react-select";

import { colorStyles } from "../../utils/constant";
import { useGlobalContext } from "../../Context/GlobalContext";
import SearchNotFound from "../../Pages/SearchNotFound";
const MeetingPackage = () => {
  const { billingOption } = useGlobalContext();

  const [search, setSearch] = useState("");
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { isLoading: loading, data: FetchMeetingPackageData } =
    useGetMeetingRoomPackageQuery(spaceId || currentSpaceId);

  // const { isLoading: loadingSpaceTypes, data: FetchSpaceDurations } =
  //   useGetSpaceDurationsQuery();

  const { data: spaceDurations, durationsLoading } = useGetSpaceDurationsQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { isLoading: meetingRoomLoading, data: FetchMeetingRoom } =
    useGetMeetingRoomQuery({ id: spaceId || currentSpaceId, room_type: 1 });
  const { items, requestSort, sortConfig } = useSortableData(
    FetchMeetingPackageData || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  // if (loading || loadingSpaceTypes || meetingRoomLoading) {
  //   return <Loader height={6.6} />;
  // }
  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const handlePage = (e) => {
    setitemsPerPage(e);
  };
  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];
  const filteredItems = items
    ?.slice()
    .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (val.name.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
    });

  return (
    <>
      <div className="col-12 mt-4">
        <div className="d-flex justify-content-between ">
          <div className="col-12  col-md-4 col-lg-6">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Meeting Package"
              className="form-control"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
            <MeetingPackageModal
              FetchSpaceDurations={spaceDurations?.spaceDurations}
              showButton={true}
            />
          </div>
        </div>
      </div>

      <div className="memberPackage table-responsive">
        <table className="table table-stripedCustom">
          <thead>
            <tr>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Name</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("name")}
                      className={getClassNamesFor("name")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("name")}
                      className={getClassNamesFor("name")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Price</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("price")}
                      className={getClassNamesFor("price")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("price")}
                      className={getClassNamesFor("price")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
              {billingOption == 1 ? <th>Meeting Hours</th> : <th>Coins</th>}
              {/* <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Hours</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("hours")}
                      className={getClassNamesFor("hours")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("hours")}
                      className={getClassNamesFor("hours")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th> */}
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Duration</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("expiryDuration")}
                      className={getClassNamesFor("expiryDuration")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("expiryDuration")}
                      className={getClassNamesFor("expiryDuration")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={5} className="fs-5">
                  Loading...
                </td>
              </tr>
            )}
            {!loading &&
              filteredItems.length > 0 &&
              filteredItems?.map((item, index) => {
                let originalIndex = filteredItems.length - index - 1;
                const { name, price, hours, expiryDuration } = item;
                return (
                  <tr key={index}>
                    <td>
                      <span>
                        {" "}
                        <b>{name}</b>
                      </span>
                    </td>
                    {/* <td>{price}</td> */}
                    <td>
                      <div className="">
                        {item?.meetingRoomAllowedHours?.[0]?.hours || "-"}
                      </div>
                    </td>
                    <td>{expiryDuration}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        {/* <ViewMeetingPackage
                          index={originalIndex}
                          items={items}
                        /> */}
                        <UpdateMeetingPackage
                          items={items}
                          index={originalIndex}
                          FetchSpaceDurations={spaceDurations?.spaceDurations}
                          FetchMeetingRoom={FetchMeetingRoom}
                        />
                        <DeleteMeetingPackage
                          items={items}
                          index={originalIndex}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {!loading && filteredItems.length == 0 ? (
          <div className="text-center">
            <SearchNotFound />

            <div className="d-flex text-center">
              <div className="col-12 d-flex">
                <p className="col-6 mb-0 text-end">Click to Add record</p>
                <div className="btn-link col-6">
                  <MeetingPackageModal
                    FetchSpaceDurations={spaceDurations?.spaceDurations}
                    showButton={false}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MeetingPackage;
