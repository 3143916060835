import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Nav,
  Pagination,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import AllDesks from "./AllDesks";
import AvailableDesks from "./AvailbleDesks";
import DeskByCompany from "./DeskByCompany";

const DeskShared = () => {
  const [page, setPage] = useState(0);

  return (
    <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
      <Tabs
        defaultActiveKey="allDesk"
        id="justify-tab-example"
        // className='mb-3'
        variant="underline"
        justify
      >
        <Tab
          // style={{ marginRight: '5px' }}
          eventKey="allDesk"
          title={
            <span className="d-flex align-items-center">
              <Icon.PcDisplay /> &nbsp;All Desks
            </span>
          }
        >
          <AllDesks />
        </Tab>
        <Tab
          // style={{ marginRight: '5px' }}
          eventKey="byCompany"
          title={
            <span className="d-flex align-items-center">
              <Icon.Building /> &nbsp;Desks By Company
            </span>
          }
        >
          <DeskByCompany />
        </Tab>
        <Tab
          // style={{ marginRight: '5px' }}
          eventKey="available"
          title={
            <span className="d-flex align-items-center">
              <Icon.Easel2 /> &nbsp; Available Desks
            </span>
          }
        >
          <AvailableDesks />
        </Tab>
      </Tabs>
    </section>
  );
};

export default DeskShared;
