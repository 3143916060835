import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import "./index.css";

import { useState } from "react";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import { useGetQuotationsQuery, useGetRolesQuery } from "../../Slices/spaceApi";
import DeleteQuotationPopup from "../Quotations/DeleteQuotationPopup";
import { useSelector } from "react-redux";
import { Badge, Pagination } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets//Lock.svg";

import Loader from "../Loader/Loader";

import UpdateQuotations from "../Quotations/UpdateQuotations";
import ViewQuotations from "../Quotations/ViewQuotations";
import DeleteRole from "./DeleteRole";
import ViewRole from "./ViewRole";
import RoleUpdate from "./RoleUpdate";
import { useGlobalContext } from "../../Context/GlobalContext";
import AddRoleModal from "./AddRoleModal";
import tabImage from "../../Assets/Images/Tab1/tab-image1.png";
import tabImage1 from "../../Assets/Images/Tab1/view-image1.png";
import UpdateRoleModal from "./UpdateRoleModal";

const RolesAndPermissions = () => {
  const { rolesPermissions } = useGlobalContext();

  const [searchQuery, setSearchQuery] = useState("");
  const [activePage, setActivePage] = useState(1);

  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const [status, setStatus] = useState({
    label: "Select All",
    value: 0,
  });
  const [loading, setLoading] = useState(false);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  // const { data: quotationData, isLoading: fetchingData } =
  //   useGetQuotationsQuery({
  //     spaceId: spaceId || currentSpaceId,
  //     customer_name: searchQuery,
  //     page_size: itemsPerPage.value,
  //     page: activePage,
  //     isCompany:
  //       status.value === 2 ? "True" : status.value == 0 ? null : "False",
  //   });
  const { data: roleData, rolesLoading } = useGetRolesQuery({
    spaceId: spaceId || currentSpaceId,
  });

  useEffect(() => {
    setLoading(false);
  }, [roleData]);
  const { items, requestSort, sortConfig } = useSortableData(
    roleData?.roles || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const handlePage = (e) => {
    setitemsPerPage(e);
    setLoading(true);
  };

  const handleStatus = (e) => {
    setStatus(e);
    setLoading(true);
  };
  const formateDate = (createdAt) => {
    const date = new Date(createdAt);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];

  const optionsStatus = [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Individual",
      value: 1,
    },
    {
      label: "Company",
      value: 2,
    },
  ];
  const filteredItems = items?.filter((val) => {
    if (searchQuery == "") {
      return val;
    } else if (val.name.toLowerCase().includes(searchQuery.toLowerCase())) {
      return val;
    }
  });
  if (rolesLoading) {
    return <Loader height={6.6} />;
  }
  return (
    <>
      <section className="invoice-list-wrapper with-bottom-padding">
        <div className="">
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-between ">
              <div className="col-12  col-md-4 col-lg-6">
                <input
                  type="search"
                  className="form-control mr-2"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    // setLoading(true);
                  }}
                  placeholder="Search Role"
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
                <AddRoleModal />
              </div>
            </div>
          </div>

          <div className="d-flex px-4 flex-wrap justify-content-around mt-4 mb-4">
            {rolesLoading && <h4>Loading...</h4>}
            {!rolesLoading && filteredItems?.length > 0 ? (
              filteredItems?.map((item) => {
                return (
                  <div
                    className="col-12 col-md-6 col-xl-4  mb-3"
                    key={item.id}
                    // style={{ cursor: "pointer", height: "100%" }}
                  >
                    <div
                      // className="card p-3 d-flex col-md-4"
                      className="card"
                      style={{
                        cursor: "pointer",
                        height: "100%",
                        padding: "10px 10px 10px 10px",
                      }}
                    >
                      <div className="card-body">
                        <h3 className="mb-1">{item.name}</h3>

                        <div className="d-flex justify-content-between">
                          <h6 className="fw-normal mb-2">
                            Total {item?.owners?.length || 0} users
                          </h6>

                          <ul className="list-unstyled d-flex align-items-center avatar-group mb-0">
                            {item?.owners?.map((user, index) => {
                              if (index < 5) {
                                return (
                                  <li
                                    data-bs-toggle="tooltip"
                                    data-popup="tooltip-custom"
                                    data-bs-placement="top"
                                    className="avatar avatar-sm pull-up"
                                    aria-label="Vinnie Mostowy"
                                    data-bs-original-title="Vinnie Mostowy"
                                  >
                                    <img
                                      className="rounded-circle"
                                      src={user?.profileImage || tabImage1}
                                      alt="Avatar"
                                      title={user?.name}
                                    />
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                        <div className="d-flex justify-content-between align-items-end mt-2">
                          {/* <span className="btn-link">Edit Role</span> */}
                          <UpdateRoleModal roleData={item} />

                          <DeleteRole {...item} />
                        </div>

                        {/* <div className="d-flex justify-content-center">
                          <div className="d-flex flex-nowrap ">
                            {modalView && (
                          <LocationView {...item} setModalView={setModalView} />
                        )}
                            
                      <LocationForm
                        teamData={{
                          id: selectedTeam?.id,
                          name: selectedTeam?.name,
                          address: selectedTeam?.address,
                          imageUrls: selectedTeam?.imageUrls,
                          description: selectedTeam?.description,
                          capacity: selectedTeam?.capacity || 0,
                        }}
                        isReadable={true}
                        onCloseModal={() => setUpdateModalVisible(false)}
                        onOpenModal={() => handleOpenUpdateModal(item)}
                        showModal={updateModalVisible}
                        title="Update Location"
                        submitButtonText="Update Location"
                      />
                            <DeleteLocation {...item} />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : !rolesLoading && filteredItems?.length == 0 ? (
              <h4>No Record !</h4>
            ) : null}
          </div>
          {/* <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Roles</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("customer:name")}
                          className={getClassNamesFor("customer:name")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("customer:name")}
                          className={getClassNamesFor("customer:name")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Description</div>
                    </div>
                  </th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8} className="fs-5">
                      <Loader />
                    </td>
                  </tr>
                )}
                {!loading &&
                  (items?.length > 0 ? (
                    items?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="media">
                              <div className="media-body my-auto d-flex flex-column">
                                <span className="mb-0 table-avatar">
                                  <b>{item.name}</b>
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>{item.description}</td>
                          <td className="d-flex flex-nowrap">
                            <ViewRole {...item} />
                            <RoleUpdate {...item} />
                            <DeleteRole {...item} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-left fs-5">
                        {searchQuery == ""
                          ? "No Record !"
                          : "No Quotation found"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div> */}

          {!loading && roleData?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing {roleData?.roles?.length} of {roleData?.totalCount}{" "}
                  entires
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: roleData?.totalPages }).map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i + 1 === activePage}
                      onClick={() => handlePageSelect(i + 1)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={activePage === roleData?.totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default RolesAndPermissions;
