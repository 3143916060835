import React, { useState } from "react";
import DiscountCode from "./DiscountCode";

import {
  Button,
  Col,
  Dropdown,
  Form,
  Nav,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import DiscountInvoice from "./DiscountInvoice";
import * as Icon from "react-bootstrap-icons";
const DiscountShared = () => {
  const [page, setPage] = useState(0);

  return (
    <>
      <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
        <Tabs
          defaultActiveKey="code"
          id="justify-tab-example"
          // className='mb-3'
          variant="underline"
          justify
        >
          <Tab
            // style={{ marginRight: '5px' }}
            eventKey="code"
            title={
              <span className="d-flex align-items-center">
                <Icon.Upc /> &nbsp;Discount Code
              </span>
            }
          >
            {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <DiscountCode />
          </Tab>
          <Tab
            style={{ marginRight: "5px" }}
            eventKey="invoice"
            title={
              <span className="d-flex align-items-center">
                <Icon.ReceiptCutoff /> &nbsp;Discounted Invoice
              </span>
            }
          >
            {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <DiscountInvoice />
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default DiscountShared;
