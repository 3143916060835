import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

import { ReactComponent as EditIcon } from "../../Assets/Box.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useUpdateInvoiceMutation,
  useUpdateSpaceInvoiceMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  paidConfirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useEffect } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

const UpdateInvoicePopup = ({ id }) => {
  const { InvoicesPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [updateInvoiceApi, resultStats] = useUpdateInvoiceMutation();

  const updateInvoice = (e) => {
    e.preventDefault();
    paidConfirmationAlert().then((willDelete) => {
      if (willDelete) {
        updateInvoiceApi({
          spaceId: spaceId || currentSpaceId,
          id: id,
          updateData: { paid: true },
        });
      }
    });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Invoice is paid successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error || "An Error Occured!");
    }
  }, [resultStats]);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {InvoicesPermissions[0]?.update ? (
              "Confirm Paid"
            ) : (
              <span>
                Confirm Paid <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="mx-2 p-0"
          onClick={InvoicesPermissions[0]?.update ? updateInvoice : null}
        >
          <EditIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default UpdateInvoicePopup;
