import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_API_URL;

const createRequest = (url) => ({
  method: "GET",
  url,
});

const createPostRequest = (url, data) => ({
  method: "POST",
  url,
  body: data,
});

const createDeleteRequest = (url, data) => ({
  method: "DELETE",
  url,
  body: data,
});

const createUpdateRequest = (url, data) => ({
  method: "PUT",
  url,
  body: data,
});

export const spaceApi = createApi({
  reducerPath: "apisSpace",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const states = getState();
      headers.set(
        "credentials",
        states.spaceReducer.spaceCrede || states.spaceReducer.currentCredentials
      );
    },
  }),
  tagTypes: [
    "dashboard",
    "meetingRoom",
    "package",
    "meetingRoomPackage",
    "khani",
    "individual",
    "ownerLocations",
    "companies",
    "notifications",
    "spaceInvoice",
    "specficInvoice",
    "quotation",
    "visits",
    "visitors",
    "floorPlan",
    "floorRooms",
    "companyUser",
    "accountdeactivation",
    "roles",
    "tourQuestions",
    "teams",
    "employees",
    "locations",
    "setPassword",
    "currentuserrole",
    "currency",
    "members",
    "partners",
    "singleTour",
    "offers",
    "tickets",
    "announcements",

    "inventory",
    "inventorywithCounts",
    "vendorCatList",
    "vendorCatGet",
    "vendorList",
    "vendorGet",
    "assetList",
    "assetGet",
    "assetCatList",
    "assetCatGet",
    "accountList",
    "packageList",
    "cancellationPolicy",
    "spaceDurations",
    "accessgroup",
  ],
  endpoints: (builder) => ({
    changePsw: builder.mutation({
      query: ({ data }) => createPostRequest(`/change-password/`, data),
    }),
    createCancellationPolicy: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/bookingCancellationPolicy/`, data),
      invalidatesTags: ["cancellationPolicy"],
    }),
    updateCancellationPolicy: builder.mutation({
      query: ({ spaceId, policyId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/bookingCancellationPolicy/${policyId}/`,
          data
        ),
      invalidatesTags: ["cancellationPolicy"],
    }),
    getCancellationPolicies: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/space/${spaceId}/bookingCancellationPolicy/`),
      providesTags: ["cancellationPolicy"],
    }),
    deleteCancellationPolicy: builder.mutation({
      query: ({ spaceId, policyId }) =>
        createDeleteRequest(
          `/space/${spaceId}/bookingCancellationPolicy/${policyId}/`
        ),
      invalidatesTags: ["cancellationPolicy"],
    }),
    createPackageDuration: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/customSpaceDuration/`, data),
      invalidatesTags: ["spaceDurations"],
    }),
    getSpaceDurations: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/space/${spaceId}/customSpaceDuration/`),
      providesTags: ["spaceDurations"],
    }),
    updatePackageDuration: builder.mutation({
      query: ({ spaceId, durationId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/customSpaceDuration/${durationId}/`,
          data
        ),
      invalidatesTags: ["spaceDurations"],
    }),
    deletePackageDuration: builder.mutation({
      query: ({ spaceId, durationId }) =>
        createDeleteRequest(
          `/space/${spaceId}/customSpaceDuration/${durationId}/`
        ),
      invalidatesTags: ["spaceDurations"],
    }),

    // /space/1/bookingCancellationPolicy/5/
    getSpaceData: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}`),
      providesTags: ["khani"],
    }),
    updateSpace: builder.mutation({
      query: ({ spaceId, data }) =>
        createUpdateRequest(`/space/${spaceId}/`, data),
      invalidatesTags: ["khani", "dashboard"],
    }),
    getCurrency: builder.query({
      query: () => createRequest(`/list-currency/`),
      // providesTags: ["currency"],
    }),
    // getMeetingRoom: builder.query({
    //   query: ({ id, room_type, page, page_size }) =>
    //     createRequest(
    //       `/spaces/${id}/meetingRoom/?room_type=${room_type}&page_size=${page_size}&page=${page}`
    //     ),
    //   providesTags: ["meetingRoom"],
    // }),
    getMeetingRoom: builder.query({
      query: ({ id, room_type, page, page_size, hotDesk, flexDesk }) => {
        let url = `/spaces/${id}/meetingRoom/?room_type=${room_type}`;
        if (page) {
          url += `&page=${page}`;
        }
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (hotDesk) {
          url += `&hotDesk=${hotDesk}`;
        }
        if (flexDesk) {
          url += `&flexDesk=${flexDesk}`;
        }
        return createRequest(url);
      },

      // createRequest(
      //   `/spaces/${id}/meetingRoom/?room_type=${room_type}&page_size=${page_size}&page=${page}`
      // ),
      providesTags: ["meetingRoom"],
    }),

    // getBookingsOfCompanyEmployee: builder.query({
    //   query: ({ spaceId, companyId }) => {
    //     let url = `/space/${spaceId}/v2/booking/`;
    //     if (companyId >= 0) {
    //       url += `?company=${companyId}`;
    //     }
    //     return createRequest(url);
    //   },
    //   // createRequest(`/space/${spaceId}/v2/booking/?company=${companyId}`),
    //   providesTags: ["individual", "companies"],
    // }),

    getRelatedMeetingRooms: builder.query({
      query: ({ id }) => createRequest(`/meeting-rooms/${id}/related`), //?roomtype etc
      providesTags: ["meetingRoom"],
    }),

    getAvailableHotDesks: builder.query({
      query: ({ spaceId, startTime, endTime }) =>
        createRequest(
          `/space/${spaceId}/v2/booking/getHotDeskAvailaibility/?startTime=${startTime}&endTime=${endTime}`
        ),
      providesTags: ["meetingRoom"],
    }),

    createMeetingRoom: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/spaces/${spaceId}/meetingRoom/`, data),
      invalidatesTags: ["meetingRoom", "dashboard"],
    }),
    deleteMeetingRoom: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/spaces/${spaceId}/meetingRoom/${id}/`),
      invalidatesTags: ["meetingRoom", "dashboard"],
    }),
    //Stripe Key

    revokeStripeKey: builder.mutation({
      query: ({ spaceId }) =>
        createDeleteRequest(`/space/${spaceId}/revokeStripe/`),
      invalidatesTags: ["khani"],
    }),

    ///////
    updateMeetingRoom: builder.mutation({
      query: ({ spaceId, id, updateMeeting }) =>
        createUpdateRequest(
          `/spaces/${spaceId}/meetingRoom/${id}/`,
          updateMeeting
        ),
      invalidatesTags: ["meetingRoom", "dashboard"],
    }),
    getPackage: builder.query({
      query: (spaceId) => createRequest(`/space-id/${spaceId}/package/`),
      providesTags: ["package"],
    }),
    createPackage: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space-id/${spaceId}/package/`, data),
      invalidatesTags: ["package", "dashboard"],
    }),
    deletePackage: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space-id/${spaceId}/package-id/${id}/`),
      invalidatesTags: ["package", "dashboard"],
    }),
    updatePackage: builder.mutation({
      query: ({ spaceId, id, data }) =>
        createUpdateRequest(`/space-id/${spaceId}/package-id/${id}/`, data),
      invalidatesTags: ["package", "dashboard"],
    }),
    getMeetingRoomPackage: builder.query({
      query: (id) => createRequest(`/space/${id}/meetingRoom/packages/`),
      providesTags: ["meetingRoomPackage"],
    }),
    createMeetingRoomPackage: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/meetingRoom/packages/`, data),
      invalidatesTags: ["meetingRoomPackage"],
    }),
    deleteMeetingRoomPackage: builder.mutation({
      query: (id) => createDeleteRequest(`/meetingRoom/packages/${id}`),
      invalidatesTags: ["meetingRoomPackage"],
    }),
    updateMeetingRoomPackage: builder.mutation({
      query: ({ id, data }) =>
        createUpdateRequest(`/meetingRoom/packages/${id}/`, data),
      invalidatesTags: ["meetingRoomPackage"],
    }),

    //APIs' SET-UP FOR COMPANY
    createCompany: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/profile/`, data),
      invalidatesTags: ["companies", "dashboard"],
    }),
    updateCompany: builder.mutation({
      query: ({ spaceId, companyId, updateData }) =>
        createUpdateRequest(
          `/space/${spaceId}/profile/${companyId}/`,
          updateData
        ),
      invalidatesTags: ["companies", "spaceInvoice ", "dashboard"],
    }),
    deleteCommpany: builder.mutation({
      query: ({ spaceId, companyId }) =>
        createDeleteRequest(`/space/${spaceId}/profile/${companyId}/`),
      invalidatesTags: ["companies", "spaceInvoice", "dashboard"],
    }),
    getSingleCompany: builder.query({
      query: ({ spaceId, companyId }) =>
        createRequest(`/space/${spaceId}/profile/${companyId}/`),
      providesTags: ["companies"],
    }),
    // getCompanies: builder.query({
    //   query: ({ spaceId, page, page_size }) => {
    //     let url = `/space/${spaceId}/profile/?company=True&page_size=${page_size}&page=${page}`;
    //     return createRequest(url);
    //   },
    //   providesTags: ["companies"],
    // }),
    getCompanies: builder.query({
      query: ({ spaceId, page, page_size, plan, paymentMethod, status }) => {
        let url = `/space/${spaceId}/profile/?company=True&page_size=${page_size}&page=${page}`;
        if (status) {
          url += `&status=${status}`;
        }
        if (plan) {
          url += `&plan=${plan}`;
        }
        if (paymentMethod) {
          url += `&paymentMethod=${paymentMethod}`;
        }
        return createRequest(url);
      },
      providesTags: ["companies"],
    }),

    getAllCompanies: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/space/${spaceId}/profile/?company=True`),

      providesTags: ["companies"],
    }),

    // getIndividuals: builder.query({
    //   query: ({ spaceId, page, page_size }) => {
    //     let url = `/space/${spaceId}/profile/?company=False&page_size=${page_size}&page=${page}`;
    //     return createRequest(url);
    //   },
    //   providesTags: ["companies", "individual"],
    // }),
    //////////////////////////////////////////////////////
    //APIS  For:: Users for Company
    createUserForCompany: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/profile/`, data),
      invalidatesTags: ["companyUser", "dashboard", "companies"],
    }),

    getCompanyUsers: builder.query({
      query: ({ spaceId, companyId }) =>
        createRequest(`/space/${spaceId}/profile/?companyId=${companyId}`),
      providesTags: ["companyUser"],
    }),

    getIndividuals: builder.query({
      query: ({
        spaceId,
        page,
        page_size,
        filter,
        plan,
        paymentMethod,
        status,
      }) => {
        let url = `/space/${spaceId}/profile/?company=False&page_size=${page_size}&page=${page}`;
        if (filter == 0) {
          url = `/space/${spaceId}/profile/?individual=True`;
        }
        if (filter != "all" && filter != 0) {
          url = `/space/${spaceId}/profile/?companyId=${filter}&page_size=${page_size}&page=${page}`;
        }
        if (plan) {
          url += `&plan=${plan}`;
        }
        if (paymentMethod) {
          url += `&paymentMethod=${paymentMethod}`;
        }
        if (status) {
          url += `&status=${status}`;
        }
        return createRequest(url);
      },
      providesTags: ["companies", "individual"],
    }),
    getAllIndividuals: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/space/${spaceId}/profile/?company=False`),

      providesTags: ["companies", "individual"],
    }),
    updateCompanyUser: builder.mutation({
      query: ({ spaceId, memberId, updateData }) =>
        createUpdateRequest(
          `/space/${spaceId}/profile/${memberId}/`,
          updateData
        ),
      invalidatesTags: ["companyUser", "dashboard", "individual"],
    }),

    deleterCompanyUser: builder.mutation({
      query: ({ spaceId, memberId }) =>
        createDeleteRequest(`/space/${spaceId}/profile/${memberId}/`),
      invalidatesTags: ["companyUser", "dashboard", "companies"],
    }),

    getSingleCompanyUsers: builder.query({
      query: ({ spaceId, memberId }) =>
        createRequest(`/space/${spaceId}/member/${memberId}/`),
      providesTags: ["companyUser"],
    }),

    /////////////////////////////////
    assignPackageToMember: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/assignedPackage/`, data),
      invalidatesTags: ["individual", "companies"],
    }),

    updateMemberPackage: builder.mutation({
      query: ({ spaceId, itemId, packageId, packageType, updateData }) =>
        createUpdateRequest(
          `/space/${spaceId}/assignedPackage/${itemId}/update/${packageId}/type/${packageType}/`,
          updateData
        ),
      invalidatesTags: ["individual", "companies"],
    }),

    cancelMemberPackage: builder.mutation({
      query: ({ spaceId, itemId, packageId, packageType }) =>
        createDeleteRequest(
          `/space/${spaceId}/assignedPackage/${itemId}/delete/${packageId}/type/${packageType}/`
        ),
      invalidatesTags: ["individual", "companies", "package"],
    }),

    getAllMembers: builder.query({
      query: (spaceId) => createRequest(`/space/${spaceId}/profile/`),
      providesTags: ["companyUser", "companies"],
    }),
    ///////////////////////////////////////////
    assignPackage: builder.mutation({
      query: ({ spaceId, companyId, data }) =>
        createPostRequest(`/space/${spaceId}/company/${companyId}/`, data),
      invalidatesTags: ["companies", "package"],
    }),

    getPackageDetailsForMember: builder.query({
      query: ({ spaceId, memberId }) =>
        createRequest(`/space/${spaceId}/assignedPackage/?member=${memberId}`),
      providesTags: ["companies", "package"],
    }),
    getLoggedInAdmin: builder.query({
      query: (spaceId) =>
        createRequest(`/space/${spaceId}/spaceowner/loggedIn/`),
      // providesTags: ["companyUser", "companies"],
    }),
    ////////////////////////////////////////////

    multiplePackagesCompany: builder.mutation({
      query: ({ spaceId, userId, data }) =>
        createPostRequest(`/space/${spaceId}/company/${userId}/bundles`, data),
      invalidatesTags: ["companies", "spaceInvoice"],
    }),
    //////////////////////////////////////
    //Apis for User For Space(Individual)

    createUserForSpace: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/profile/`, data),
      invalidatesTags: ["individual", "companies", "spaceInvoice", "dashboard"],
    }),
    updateUser: builder.mutation({
      query: ({ spaceId, userId, updateData }) =>
        createUpdateRequest(`/space/${spaceId}/profile/${userId}/`, updateData),
      invalidatesTags: [
        "individual",
        "companies",
        "spaceInvoice ",
        "dashboard",
      ],
    }),

    deleteUserForSpace: builder.mutation({
      query: ({ spaceId, userId }) =>
        createDeleteRequest(`/space/${spaceId}/profile/${userId}/`),
      invalidatesTags: ["individual", "companies", "spaceInvoice", "dashboard"],
    }),

    getSingleIndividual: builder.query({
      query: ({ spaceId, memberId }) =>
        createRequest(`/space/${spaceId}/profile/${memberId}/`),
      providesTags: ["individual", "companies"],
    }),
    // Common Member Apis
    getBookingsOfSingleMember: builder.query({
      query: ({ spaceId, memberId }) =>
        createRequest(`/space/${spaceId}/v2/booking/?member=${memberId}`),
      providesTags: ["individual", "companies"],
    }),

    getBookingsOfCompanyEmployee: builder.query({
      query: ({ spaceId, companyId }) => {
        let url = `/space/${spaceId}/v2/booking/`;
        if (companyId >= 0) {
          url += `?company=${companyId}`;
        }
        return createRequest(url);
      },
      // createRequest(`/space/${spaceId}/v2/booking/?company=${companyId}`),
      providesTags: ["individual", "companies"],
    }),
    ////////////////////////////////////////////////////////

    //NEW APIS FOR INVOICES
    createInvoiceV2: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/v2/invoice/`, data),
      invalidatesTags: [
        "spaceInvoice",
        "individual",
        "companies",
        "spaceInvoice",
        "dashboard",
      ],
    }),
    addPayment: builder.mutation({
      query: ({ spaceId, invoiceId, data }) =>
        createPostRequest(
          `/space/${spaceId}/invoice/${invoiceId}/payment/`,
          data
        ),
      invalidatesTags: [
        "spaceInvoice",
        "individual",
        "companies",
        "spaceInvoice",
        "dashboard",
      ],
    }),

    sentInvoice: builder.mutation({
      query: ({ spaceId, invoiceId }) =>
        createPostRequest(`/space/${spaceId}/v2/invoice/${invoiceId}/email/`),
      // invalidatesTags: ["companyUser", "dashboard"],
    }),
    sentQuotation: builder.mutation({
      query: ({ spaceId, quotationId }) =>
        createPostRequest(
          `/space/${spaceId}/quotation/${quotationId}/sendEmail/`
        ),
      // invalidatesTags: ["companyUser", "dashboard"],
    }),

    getInvoicesOfSingleMember: builder.query({
      query: ({ spaceId, memberId, name, page, page_size, status }) => {
        let url = `/space/${spaceId}/v2/invoice/?member=${memberId}&page_size=${page_size}&page=${page}&status=1`;
        if (status === 0) {
          url += `&paid=0`;
        }
        if (status === 1) {
          url += `&paid=1`;
        }
        if (status === 2) {
          url += `&due=1`;
        }
        if (status === 3) {
          url += `&due=0`;
        }
        if (status === 4) {
          url += ``;
        }
        if (name) {
          url += `&name=${name}`;
        }
        return createRequest(url);
      },

      providesTags: [
        "individual",
        "companies",
        "spaceInvoice",
        "specficInvoice",
      ],
    }),

    getAllInvoices: builder.query({
      query: ({ spaceId, name, page, page_size, status }) => {
        let url = `/space/${spaceId}/v2/invoice/?page_size=${page_size}&page=${page}&status=1`;
        if (status === 0) {
          url += `&paid=0`;
        }
        if (status === 1) {
          url += `&paid=1`;
        }
        if (status === 2) {
          url += `&overDue=1`;
        }
        if (status === 3) {
          url += `&due=0`;
        }
        if (status === 4) {
          url += ``;
        }
        if (name) {
          url += `&name=${name}`;
        }
        return createRequest(url);
      },

      providesTags: ["spaceInvoice"],
    }),

    updateInvoice: builder.mutation({
      query: ({ spaceId, id, updateData }) =>
        createUpdateRequest(`/space/${spaceId}/v2/invoice/${id}/`, updateData),
      invalidatesTags: [
        "spaceInvoice",
        "specficInvoice",
        "dashboard",
        "package",
        "companies",
        "individual",
      ],
    }),
    getSingleInvoice: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/v2/invoice/${id}/`),
      providesTags: ["spaceInvoice", "specficInvoice"],
    }),
    deleteInvoiceV2: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/v2/invoice/${id}/`),
      invalidatesTags: [
        "individual",
        "companies",
        "spaceInvoice",
        "specficInvoice",
      ],
    }),
    //
    //////////////////
    //below is old one. will remove soon
    getAllSpaceUsers: builder.query({
      query: ({ spaceId, statusVal: { company, status } }) =>
        createRequest(
          `owner/spaces-members/${spaceId}?isCompanyFilter=${company}${
            status !== undefined && status !== null
              ? `&packagesAssignStatus=${status}`
              : ""
          }`
        ),
      providesTags: ["companies"],
    }),

    getAllSpaceUser: builder.query({
      query: (spaceId) =>
        createRequest(`/owner/spaces-members/${spaceId}/all?status=2`),
      providesTags: ["companies"],
    }),
    ///////////////////////////////////////////////
    // createBooking: builder.mutation({
    //   query: ({ meetingRoomId, updateData }) =>
    //     createPostRequest(`/booking/meetingRoom/${meetingRoomId}/`, updateData),
    //   invalidatesTags: ["upComingBooking", "spaceInvoice"],
    // }),
    bookingSlots: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/meeting-rooms/${spaceId}/slots`, data),
      invalidatesTags: ["upComingBooking"],
    }),
    tourSlots: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/tour/slots/`, data),
      invalidatesTags: ["tours"],
    }),

    createBooking: builder.mutation({
      query: ({ spaceId, updateData }) =>
        createPostRequest(`/space/${spaceId}/v2/booking/`, updateData),
      invalidatesTags: ["upComingBooking", "spaceInvoice"],
    }),

    createHotdeskBooking: builder.mutation({
      query: ({ spaceId, updateData }) =>
        createPostRequest(`/space/${spaceId}/v2/booking/hotDesk/`, updateData),
      invalidatesTags: ["upComingBooking", "spaceInvoice"],
    }),
    createFlexDeskBooking: builder.mutation({
      query: ({ spaceId, updateData }) =>
        createPostRequest(`/space/${spaceId}/v2/booking/flexDesk/`, updateData),
      invalidatesTags: ["upComingBooking", "spaceInvoice"],
    }),
    // updateFlexDeskBooking: builder.mutation({
    //   query: ({ spaceId, id, updateData }) =>
    //     createUpdateRequest(`/space/${spaceId}/v2/booking/${id}/`, updateData),
    //   invalidatesTags: ["upComingBooking", "spaceInvoice"],
    // }),
    // getAllUpComingBooking: builder.query({
    //   query: ({ spaceId }) =>
    //     createRequest(`/booking/meetingRoom/?space=${spaceId}`),
    //   providesTags: ["upComingBooking"],
    // }),
    // getAllUpComingBooking: builder.query({
    //   query: ({ spaceId, past,companyId }) =>
    //     createRequest(`/space/${spaceId}/v2/booking/?past=${past}`),
    //   providesTags: ["upComingBooking"],
    // }),

    getAllUpComingBooking: builder.query({
      query: ({ spaceId, past, companyId, type }) => {
        let url = `/space/${spaceId}/v2/booking/?past=${past}`;
        if (companyId && companyId !== "all") {
          url += `&companyId=${companyId}`;
        }
        if (type) {
          url += `&type=${type}`;
        }
        return createRequest(url);
      },

      // createRequest(`/space/${spaceId}/v2/booking/?past=${past}`),
      providesTags: ["upComingBooking"],
    }),
    deleteMeetingBooking: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/v2/booking/${id}`),
      invalidatesTags: ["upComingBooking", "spaceInvoice"],
    }),
    updateMeetingBooking: builder.mutation({
      query: ({ spaceId, id, updateData }) =>
        createUpdateRequest(`/space/${spaceId}/v2/booking/${id}/`, updateData),
      invalidatesTags: ["upComingBooking", "spaceInvoice"],
    }),
    getSpaceInvoice: builder.query({
      query: ({ spaceId, userName, pageNumber, numberOfItems, status }) => {
        let url = `/invoice/space/${spaceId}?numberOfItems=${numberOfItems}&pageNumber=${pageNumber}&status=1`;
        if (status === 0) {
          url += `&paid=0`;
        }
        if (status === 1) {
          url += `&paid=1`;
        }
        if (status === 2) {
          url += `&due=1`;
        }
        if (status === 3) {
          url += `&due=0`;
        }
        if (status === 4) {
          url += ``;
        }
        if (userName) {
          url += `&userName=${userName}`;
        }
        return createRequest(url);
      },
      providesTags: ["spaceInvoice"],
    }),
    getSpaceInvoiceById: builder.query({
      query: ({ id }) => createRequest(`/invoice/${id}`),
      providesTags: ["specficInvoice"],
    }),
    createManuallyInvoice: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(
          `/space/${spaceId}/subscriptionInvoice/?default=false&discounted=1`,
          data
        ),
      invalidatesTags: ["spaceInvoice", "specficInvoice"],
    }),
    updateSpaceInvoice: builder.mutation({
      query: ({ spaceId, id, updateData }) =>
        createUpdateRequest(
          `/invoice/space/${spaceId}/invoice/${id}/`,
          updateData
        ),
      invalidatesTags: ["spaceInvoice"],
    }),
    // floor plans apis
    createFloorPlan: builder.mutation({
      query: ({ spaceId, floorPlanData }) =>
        createPostRequest(`/space/${spaceId}/floorplans/`, floorPlanData),
      invalidatesTags: ["floorPlan"],
    }),
    getFloorPlans: builder.query({
      query: (spaceId) => createRequest(`/space/${spaceId}/floorplans/`),
      providesTags: ["floorPlan"],
    }),
    getSpecificFloorPlans: builder.query({
      query: ({ spaceId, floorId }) =>
        createRequest(`/space/${spaceId}/floorplans/${floorId}/`),
      providesTags: ["floorPlan"],
    }),

    updateFloorPlan: builder.mutation({
      query: ({ spaceId, floorId, updatedData }) =>
        createUpdateRequest(
          `/space/${spaceId}/floorplans/${floorId}/`,
          updatedData
        ),
      invalidatesTags: ["floorPlan"],
    }),

    getSpecificCompany: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/member/${id}/`),
      providesTags: ["companies"],
    }),
    getBanner: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/banner/`),
      providesTags: ["dashboard"],
    }),

    deleteFloorPlan: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/floorplans/${id}/`),
      invalidatesTags: ["floorPlan"],
    }),

    createFloorRooms: builder.mutation({
      query: ({ floorId, updatedData }) =>
        createPostRequest(`/floorplans/${floorId}/floorRooms/`, updatedData),
      invalidatesTags: ["floorRooms"],
    }),
    getFloorRooms: builder.query({
      query: (floorId) => createRequest(`/floorplans/${floorId}/floorRooms/`),
      providesTags: ["floorRooms"],
    }),
    getFloorRoomsForMember: builder.query({
      query: ({ floorId, room_type }) =>
        createRequest(
          `/floorplans/${floorId}/floorRooms/?room_type=${room_type}&assignedTo=False`
        ),
      providesTags: ["floorRooms"],
    }),

    updateFloorRoom: builder.mutation({
      query: ({ id, updateData }) =>
        createUpdateRequest(`/floorRooms/${id}/`, updateData),
      invalidatesTags: ["floorRooms"],
    }),

    deleteFloorRoom: builder.mutation({
      query: ({ id }) => createDeleteRequest(`/floorRooms/${id}/`),
      invalidatesTags: ["floorRooms"],
    }),
    createQuotations: builder.mutation({
      query: ({ spaceId, quotationData }) =>
        createPostRequest(`/space/${spaceId}/quotations/`, quotationData),
      invalidatesTags: ["quotation"],
    }),

    createVisitors: builder.mutation({
      query: ({ spaceId, visitorData }) =>
        createPostRequest(`/space/${spaceId}/visitors/`, visitorData),
      invalidatesTags: ["visitors"],
    }),
    getAllVisitors: builder.query({
      query: ({ spaceId, page, page_size, name }) => {
        let url = `/space/${spaceId}/visitors/?page=${page}`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (name) {
          url += `&name=${name}`;
        }
        return createRequest(url);
      },
      providesTags: ["visits", "visitors"],
    }),

    getVisitorsWithoutQuery: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/visitors/`),
      providesTags: ["visits", "visitors"],
    }),
    updateVisitor: builder.mutation({
      query: ({ spaceId, id, data }) =>
        createUpdateRequest(`/space/${spaceId}/visitors/${id}/`, data),

      invalidatesTags: ["visitors", "visits"],
    }),
    deletevisitor: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/visitors/${id}/`),
      invalidatesTags: ["visits", "visitors"],
    }),

    createVisit: builder.mutation({
      query: ({ spaceId, visitData }) =>
        createPostRequest(`/space/${spaceId}/visits/`, visitData),
      invalidatesTags: ["visits"],
    }),
    getAllVisits: builder.query({
      query: ({ spaceId, page, page_size, name, past }) => {
        let url = `/space/${spaceId}/visits/?page=${page}&page_size=${page_size}`;
        // if (page_size) {
        //   url += `&page_size=${page_size}`;
        // }
        if (name) {
          url += `&name=${name}`;
        }
        if (past) {
          if (past == 1) {
            url += `&past=True`;
          } else if (past == 2) {
            url += `&past=False`;
          } else {
            url = `/space/${spaceId}/visits/?page=${page}`;
          }
        }
        return createRequest(url);
      },
      providesTags: ["visits", "visitors"],
    }),
    updateVisits: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/visits/${id}`),

      invalidatesTags: ["visits"],
    }),
    updateVisitsData: builder.mutation({
      query: ({ spaceId, id, updateData }) =>
        createUpdateRequest(`/space/${spaceId}/visits/${id}/`, updateData),

      invalidatesTags: ["visitors", "visits"],
    }),
    deletevisits: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/visits/${id}/`),
      invalidatesTags: ["visits"],
    }),

    getQuotations: builder.query({
      query: ({ spaceId, customer_name, page_size, page, isCompany }) => {
        let url = `/space/${spaceId}/quotations/?page=${page}`;
        if (customer_name) {
          url += `&customer_name=${customer_name}`;
        }
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (isCompany !== null) {
          url += `&isCompany=${isCompany}`;
        }

        return createRequest(url);
      },

      providesTags: ["quotation"],
    }),
    updateQuotations: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/quotations/${id}`),
      providesTags: ["quotation"],
    }),

    updateQuotationData: builder.mutation({
      query: ({ spaceId, id, updateData }) =>
        createUpdateRequest(`/space/${spaceId}/quotations/${id}/`, updateData),
      invalidatesTags: ["quotation"],
    }),
    //////////////
    // convertToInvoice: builder.query({
    //   query: ({ spaceId, id, data }) =>
    //     createUpdateRequest(`/space/${spaceId}/quotations/${id}/accept`, data),
    //   providesTags: ["spaceInvoice"],
    // }),

    deleteQuotation: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/quotations/${id}/`),
      invalidatesTags: ["quotation"],
    }),

    deleteSpaceInvoice: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/invoice/space/${spaceId}/invoice/${id}/`),
      invalidatesTags: ["spaceInvoice"],
    }),
    updateBookingInvoice: builder.mutation({
      query: ({ id, data }) => createUpdateRequest(`invoice/edit/${id}/`, data),
      invalidatesTags: ["spaceInvoice", "specficInvoice"],
    }),
    getSpaceDashboard: builder.query({
      query: (spaceId) => createRequest(`/space/${spaceId}/v2/dashboard/`),
      providesTags: ["dashboard"],
    }),

    getNotification: builder.query({
      query: (spaceId) => createRequest(`space/${spaceId}/notifications`),
      providesTags: ["notifications"],
    }),
    updateNotifications: builder.mutation({
      query: ({ spaceId, id, data }) =>
        createUpdateRequest(`/space/${spaceId}/notifications/${id}/`, data),
      invalidatesTags: ["notifications"],
    }),
    markAsReadNotifications: builder.mutation({
      query: (spaceId) =>
        createUpdateRequest(`/space/${spaceId}/notifications/read/`),
      invalidatesTags: ["notifications"],
    }),
    deleterNotification: builder.mutation({
      query: ({ spaceId, notificationId }) =>
        createDeleteRequest(
          `space/${spaceId}/notifications/${notificationId}/`
        ),
      invalidatesTags: ["notifications"],
    }),
    // deleteDeactivate: builder.query({
    //   query: ({ spaceId, type }) =>
    //     createRequest(`/owner/${spaceId}/account/${type}`),
    //   providesTags: ["accountdeactivation"],
    // }),

    deleteDeactivate: builder.mutation({
      query: ({ spaceId, type, data }) =>
        createPostRequest(`/owner/${spaceId}/account/${type}`, data),
      invalidatesTags: ["accountdeactivation"],
    }),

    createAdmin: builder.mutation({
      query: ({ spaceId, adminData }) =>
        createPostRequest(`/space/${spaceId}/spaceowner/`, adminData),
      invalidatesTags: ["roles"],
    }),

    createTeam: builder.mutation({
      query: ({ spaceId, teamData }) =>
        createPostRequest(`/space/${spaceId}/team/`, teamData),
      invalidatesTags: ["teams"],
    }),
    getAllTeams: builder.query({
      query: ({ spaceId }) => createRequest(`space/${spaceId}/team/`),
      // let url = `/space/${spaceId}/team/`;
      // if (page_size) {
      //   url += `&page_size=${page_size}`;
      // }
      // if (name) {
      //   url += `&name=${name}`;
      // }
      // return
      // createRequest(url);

      providesTags: ["teams"],
    }),
    getSingleTeam: builder.query({
      query: ({ spaceId, teamId }) =>
        createRequest(`space/${spaceId}/team/${teamId}`),
      providesTags: ["teams"],
    }),
    updateTeam: builder.mutation({
      query: ({ spaceId, id, updateData }) =>
        createUpdateRequest(`/space/${spaceId}/team/${id}/`, updateData),
      invalidatesTags: ["teams"],
    }),
    deleteTeam: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/team/${id}/`),
      invalidatesTags: ["teams"],
    }),
    createEmployee: builder.mutation({
      query: ({ spaceId, employeeData }) =>
        createPostRequest(`/space/${spaceId}/spaceowner/`, employeeData),
      invalidatesTags: ["employees", "teams"],
    }),
    getAllEmployees: builder.query({
      query: ({ spaceId }) => createRequest(`space/${spaceId}/spaceowner/`),
      // let url = `/space/${spaceId}/team/`;
      // if (page_size) {
      //   url += `&page_size=${page_size}`;
      // }
      // if (name) {
      //   url += `&name=${name}`;
      // }
      // return
      // createRequest(url);

      providesTags: ["employees", "teams"],
    }),
    updateEmployee: builder.mutation({
      query: ({ spaceId, id, updateData }) =>
        createUpdateRequest(`/space/${spaceId}/spaceowner/${id}/`, updateData),
      invalidatesTags: ["employees", "teams"],
    }),
    deleteEmployee: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/spaceowner/${id}/`),
      invalidatesTags: ["employees", "teams"],
    }),
    createLocation: builder.mutation({
      query: ({ spaceId, locationData }) =>
        createPostRequest(`/space/${spaceId}/spaces/`, locationData),
      invalidatesTags: ["locations", "ownerLocations"],
    }),
    getAllLocations: builder.query({
      query: ({ spaceId }) => createRequest(`space/${spaceId}/spaces/`),
      // let url = `/space/${spaceId}/team/`;
      // if (page_size) {
      //   url += `&page_size=${page_size}`;
      // }
      // if (name) {
      //   url += `&name=${name}`;
      // }
      // return
      // createRequest(url);

      providesTags: ["locations"],
    }),

    getOwnerLocations: builder.query({
      query: () => createRequest(`/owner-locations/`),
      providesTags: ["ownerLocations", "khani"],
    }),
    updateLocation: builder.mutation({
      query: ({ spaceId, id, updateData }) =>
        createUpdateRequest(`/space/${spaceId}/spaces/${id}/`, updateData),
      invalidatesTags: ["locations", "ownerLocations", "khani"],
    }),
    deleteLocation: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/spaces/${id}/`),
      invalidatesTags: ["locations", "ownerLocations"],
    }),
    getModules: builder.query({
      query: () => createRequest(`/modules/`),
      // let url = `/space/${spaceId}/team/`;
      // if (page_size) {
      //   url += `&page_size=${page_size}`;
      // }
      // if (name) {
      //   url += `&name=${name}`;
      // }
      // return
      // createRequest(url);

      // providesTags: ["locations"],
    }),

    createRole: builder.mutation({
      query: ({ spaceId, roleData }) =>
        createPostRequest(`/space/${spaceId}/role/`, roleData),
      invalidatesTags: ["roles"],
    }),
    getRoles: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/role/`),
      // let url = `/space/${spaceId}/team/`;
      // if (page_size) {
      //   url += `&page_size=${page_size}`;
      // }
      // if (name) {
      //   url += `&name=${name}`;
      // }
      // return
      // createRequest(url);

      providesTags: ["roles"],
    }),
    getSingleRoles: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/role/${id}/`),

      providesTags: ["roles"],
    }),
    getAllResources: builder.query({
      query: ({ spaceId }) => createRequest(`/spaces/${spaceId}/meetingRoom/`),

      providesTags: ["meetingRoom"],
    }),

    updateRole: builder.mutation({
      query: ({ spaceId, id, updateData }) =>
        createUpdateRequest(`/space/${spaceId}/role/${id}/`, updateData),
      invalidatesTags: ["roles"],
    }),
    deleteRole: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/role/${id}`),
      invalidatesTags: ["roles"],
    }),
    setPassword: builder.mutation({
      query: ({ spaceId, uuid, password }) =>
        createPostRequest(
          `/space/${spaceId}/spaceowner/${uuid}/setPassword/`,
          password
        ),
      invalidatesTags: ["setPassword"],
    }),
    getCurrentUserRole: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/space/${spaceId}/role/permissions`),

      providesTags: ["currentuserrole"],
    }),
    addTour: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/tour/`, data),
      invalidatesTags: ["tours"],
    }),
    getAlltours: builder.query({
      query: ({ spaceId, page, page_size, name, status, time, visited }) => {
        let url = `/space/${spaceId}/tour/?page=${page}`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (name) {
          url += `&name=${name}`;
        }
        if (status && status != "all") {
          url += `&status=${status}`;
        }
        if (time && time != "all") {
          url += `&time=${time}`;
        }
        if (visited && visited != "all") {
          url += `&visited=${visited}`;
        }
        return createRequest(url);
      },
      providesTags: ["tours"],
    }),

    getTour: builder.query({
      query: ({ spaceId, tourId }) =>
        createRequest(`/space/${spaceId}/tour/${tourId}/`),
      providesTags: ["singleTour"],
    }),
    deleteTour: builder.mutation({
      query: ({ spaceId, tourId }) =>
        createDeleteRequest(`/space/${spaceId}/tour/${tourId}/`),
      invalidatesTags: ["tours"],
    }),
    updateTour: builder.mutation({
      query: ({ spaceId, tourId, data }) =>
        createUpdateRequest(`/space/${spaceId}/tour/${tourId}/`, data),
      invalidatesTags: ["tours", "singleTour"],
    }),
    getPartners: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/partner/`),
      providesTags: ["partners"],
    }),
    addPartner: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/partner/`, data),
      invalidatesTags: ["partners"],
    }),
    deletePartner: builder.mutation({
      query: ({ spaceId, partnerId }) =>
        createDeleteRequest(`/space/${spaceId}/partner/${partnerId}/`),
      invalidatesTags: ["partners"],
    }),
    updatePartner: builder.mutation({
      query: ({ spaceId, partnerId, data }) =>
        createUpdateRequest(`/space/${spaceId}/partner/${partnerId}/`, data),
      invalidatesTags: ["partners"],
    }),
    getOffers: builder.query({
      query: ({ spaceId, partnerId }) =>
        createRequest(`/space/${spaceId}/offer/?partner=${partnerId}`),
      providesTags: ["offers"],
    }),
    addOffer: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/offer/`, data),
      invalidatesTags: ["offers", "partners"],
    }),
    deleteOffer: builder.mutation({
      query: ({ spaceId, offerId }) =>
        createDeleteRequest(`/space/${spaceId}/offer/${offerId}/`),
      invalidatesTags: ["offers", "partners"],
    }),
    updateOffer: builder.mutation({
      query: ({ spaceId, offerId, data }) =>
        createUpdateRequest(`/space/${spaceId}/offer/${offerId}/`, data),
      invalidatesTags: ["offers", "partners"],
    }),
    // getAllTicket: builder.query({
    //   query: ({ spaceId }) => createRequest(`/space/${spaceId}/supportTicket/`),
    //   providesTags: ["tickets"],
    // }),
    getAllTicket: builder.query({
      query: ({ spaceId, page, page_size, search, status }) => {
        let url = `/space/${spaceId}/supportTicket/?page=${page}`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (search) {
          url += `&search=${search}`;
        }
        if (status && status != "all") {
          url += `&status=${status}`;
        }
        return createRequest(url);
      },
      providesTags: ["tickets"],
    }),
    createTicket: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/supportTicket/`, data),
      invalidatesTags: ["tickets", "dashboard"],
    }),
    deleteTicket: builder.mutation({
      query: ({ spaceId, ticketId }) =>
        createDeleteRequest(`/space/${spaceId}/supportTicket/${ticketId}/`),
      invalidatesTags: ["tickets", "dashboard"],
    }),
    updateTicket: builder.mutation({
      query: ({ spaceId, ticketId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/supportTicket/${ticketId}/`,
          data
        ),
      invalidatesTags: ["tickets", "dashboard"],
    }),
    createMessage: builder.mutation({
      query: ({ spaceId, ticketId, data }) =>
        createPostRequest(
          `/space/${spaceId}/ticket/${ticketId}/ticketMessage/`,
          data
        ),
      invalidatesTags: ["ticketMessages"],
    }),
    getMessages: builder.query({
      query: ({ spaceId, ticketId }) =>
        createRequest(`/space/${spaceId}/ticket/${ticketId}/ticketMessage/`),
      providesTags: ["ticketMessages"],
    }),
    deleteMessage: builder.mutation({
      query: ({ spaceId, messageId, ticketId }) =>
        createDeleteRequest(
          `/space/${spaceId}/ticket/${ticketId}/ticketMessage/${messageId}/`
        ),
      invalidatesTags: ["ticketMessages"],
    }),
    updateMessage: builder.mutation({
      query: ({ spaceId, messageId, ticketId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/ticket/${ticketId}/ticketMessage/${messageId}/`,
          data
        ),
      invalidatesTags: ["ticketMessages"],
    }),
    getSpecficTicket: builder.query({
      query: ({ spaceId, ticketId }) =>
        createRequest(`/space/${spaceId}/supportTicket/${ticketId}/`),
      providesTags: [`"tickets"`],
    }),

    ///////////////////////////////////

    getPosts: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/post/`),
      providesTags: ["posts"],
    }),
    createPost: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/post/`, data),
      invalidatesTags: ["posts"],
    }),
    deletePost: builder.mutation({
      query: ({ spaceId, postId }) =>
        createDeleteRequest(`/space/${spaceId}/post/${postId}/`),
      invalidatesTags: ["posts"],
    }),
    updatePost: builder.mutation({
      query: ({ spaceId, postId, data }) =>
        createUpdateRequest(`/space/${spaceId}/post/${postId}/`, data),
      invalidatesTags: ["posts"],
    }),
    createComment: builder.mutation({
      query: ({ spaceId, postId, data }) =>
        createPostRequest(`/space/${spaceId}/post/${postId}/comment/`, data),
      invalidatesTags: ["posts"],
    }),
    deleteComment: builder.mutation({
      query: ({ spaceId, postId, commentId }) =>
        createDeleteRequest(
          `/space/${spaceId}/post/${postId}/comment/${commentId}/`
        ),
      invalidatesTags: ["posts"],
    }),
    updateComment: builder.mutation({
      query: ({ spaceId, postId, data, commentId }) =>
        createUpdateRequest(
          `/space/${spaceId}/post/${postId}/comment/${commentId}/`,
          data
        ),
      invalidatesTags: ["posts"],
    }),
    getBlocksUsers: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/block/`),
    }),
    /////////////////Tour Questions Api/////////////////tourQuestions
    createTourQuestion: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/tourQuestion/`, data),
      invalidatesTags: ["tourQuestions"],
    }),

    getTourQuestions: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/tourQuestion/`),
      providesTags: ["tourQuestions"],
    }),
    updateTourQuestion: builder.mutation({
      query: ({ spaceId, id, data }) =>
        createUpdateRequest(`/space/${spaceId}/tourQuestion/${id}/`, data),
      invalidatesTags: ["tourQuestions"],
    }),
    deleteTourQuestion: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/tourQuestion/${id}/`),
      invalidatesTags: ["tourQuestions"],
    }),

    /////////////////////
    createAnnouncement: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/announcement/`, data),
      invalidatesTags: ["announcements"],
    }),
    getAnnouncements: builder.query({
      query: ({ spaceId, page, page_size, name }) => {
        let url = `/space/${spaceId}/announcement/?page=${page}`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (name) {
          url += `&name=${name}`;
        }
        return createRequest(url);
      },
      providesTags: ["announcements"],
    }),

    deleteAnnouncements: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/announcement/${id}/`),
      invalidatesTags: ["announcements"],
    }),

    ////////////////////////
    createNotes: builder.mutation({
      query: ({ spaceId, tourId, data }) =>
        createPostRequest(`/space/${spaceId}/tour/${tourId}/note/`, data),
      invalidatesTags: ["singleTour", "tours"],
    }),
    getTourNotes: builder.query({
      query: ({ spaceId, tourId }) =>
        createRequest(`/space/${spaceId}/tour/${tourId}/note/`),
      providesTags: ["singleTour"],
    }),
    updateTourNote: builder.mutation({
      query: ({ spaceId, tourId, noteId, data }) =>
        createUpdateRequest(
          `space/${spaceId}/tour/${tourId}/note/${noteId}/`,
          data
        ),
      invalidatesTags: ["singleTour"],
    }),
    deleteNote: builder.mutation({
      query: ({ spaceId, tourId, noteId }) =>
        createDeleteRequest(`space/${spaceId}/tour/${tourId}/note/${noteId}/`),
      invalidatesTags: ["singleTour"],
    }),

    // FINANCIALS APIS

    vendorCategoryList: builder.query({
      query: ({ spaceId, page_size = 10, page = 1, name }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/vendorCategory/?page=${page}&page_size=${page_size}`;
        if (name) {
          url += `&name=${name}`;
        }
        return createRequest(url);
      },
      providesTags: ["vendorCatList"],
    }),
    vendorCategorySearch: builder.query({
      query: ({ spaceId, searchValue }) =>
        createRequest(`/space/${spaceId}/vendorCategory?name=${searchValue}`),
      providesTags: ["vendorCatSearch"],
    }),
    vendorCategoryCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/vendorCategory/`, input),
      invalidatesTags: ["vendorCatList", "vendorList"],
    }),
    vendorCategoryGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/vendorCategory/${id}/`),
      providesTags: ["vendorCatGet"],
    }),
    vendorCategoryUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/vendorCategory/${id}/`, input),
      invalidatesTags: ["vendorCatList", "vendorList"],
    }),
    vendorCategoryDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/vendorCategory/${id}/`),
      invalidatesTags: ["vendorCatList", "vendorList"],
    }),
    ///////// Asset Category
    assetCategoryList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        let url = `/space/${spaceId}/assetCategory/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (name) {
          url += `?name=${name}`;
        }
        if (page) {
          url += `&page=${page}`;
        }

        return createRequest(url);
      },
      providesTags: ["assetCatList"],
    }),

    assetCategoryCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/assetCategory/`, input),
      invalidatesTags: ["assetCatList", "assetList"],
    }),
    assetCategoryGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/assetCategory/${id}/`),
      providesTags: ["assetCatGet"],
    }),
    assetCategoryUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/assetCategory/${id}/`, input),
      invalidatesTags: ["assetCatList", "assetList"],
    }),
    assetCategoryDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/assetCategory/${id}/`),
      invalidatesTags: ["assetCatList", "assetList"],
    }),
    // aset
    assetList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/asset/`;
        if (name) {
          url += `?name=${name}`;
          if (page_size) {
            url += `&page_size=${page_size}`;
          }
          if (page) {
            url += `&page=${page}`;
          }
        } else {
          if (page_size) {
            url += `?page_size=${page_size}`;
            if (page) {
              url += `&page=${page}`;
            }
          } else {
            if (page) {
              url += `?page=${page}`;
            }
          }
        }
        return createRequest(url);
      },
      providesTags: ["assetList"],
    }),
    assetCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/asset/`, input),
      invalidatesTags: ["assetList"],
    }),
    assetGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/asset/${id}/`),
      providesTags: ["assetGet"],
    }),
    assetUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/asset/${id}/`, input),
      invalidatesTags: ["assetList"],
    }),
    assetDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/asset/${id}/`),
      invalidatesTags: ["assetList"],
    }),
    // vendor
    vendorList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/vendor/`;
        if (name) {
          url += `?name=${name}`;
          if (page_size) {
            url += `&page_size=${page_size}`;
          }
          if (page) {
            url += `&page=${page}`;
          }
        } else {
          if (page_size) {
            url += `?page_size=${page_size}`;
            if (page) {
              url += `&page=${page}`;
            }
          } else {
            if (page) {
              url += `?page=${page}`;
            }
          }
        }
        return createRequest(url);
      },
      providesTags: ["vendorList"],
    }),
    vendorCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/vendor/`, input),
      invalidatesTags: ["vendorList"],
    }),
    vendorGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/vendor/${id}/`),
      providesTags: ["vendorGet"],
    }),
    vendorUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/vendor/${id}/`, input),
      invalidatesTags: ["vendorList"],
    }),
    vendorDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/vendor/${id}/`),
      invalidatesTags: ["vendorList"],
    }),
    ///////// Expense Category
    expenseCategoryList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        let url = `/space/${spaceId}/expenseCategory/`;
        if (name) {
          url += `?name=${name}`;
          if (page_size) {
            url += `&page_size=${page_size}`;
          }
          if (page) {
            url += `&page=${page}`;
          }
        } else {
          if (page_size) {
            url += `?page_size=${page_size}`;
            if (page) {
              url += `&page=${page}`;
            }
          } else {
            if (page) {
              url += `?page=${page}`;
            }
          }
        }

        return createRequest(url);
      },
      providesTags: ["expenseCatList"],
    }),

    expenseCategoryCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/expenseCategory/`, input),
      invalidatesTags: ["expenseCatList", "vendorList"],
    }),
    expenseCategoryGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/expenseCategory/${id}/`),
      providesTags: ["expenseCatGet"],
    }),
    expenseCategoryUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/expenseCategory/${id}/`, input),
      invalidatesTags: ["expenseCatList", "vendorList"],
    }),
    expenseCategoryDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/expenseCategory/${id}/`),
      invalidatesTags: ["expenseCatList", "vendorList"],
    }),

    expenseList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/expense/`;
        if (name) {
          url += `?name=${name}`;
          if (page_size) {
            url += `&page_size=${page_size}`;
          }
          if (page) {
            url += `&page=${page}`;
          }
        } else {
          if (page_size) {
            url += `?page_size=${page_size}`;
            if (page) {
              url += `&page=${page}`;
            }
          } else {
            if (page) {
              url += `?page=${page}`;
            }
          }
        }
        return createRequest(url);
      },
      providesTags: ["expenseList"],
    }),
    expenseCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/expense/`, input),
      invalidatesTags: ["expenseList"],
    }),
    expenseGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/expense/${id}/`),
      providesTags: ["expenseGet"],
    }),
    expenseUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/expense/${id}/`, input),
      invalidatesTags: ["expenseList"],
    }),
    expenseDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/expense/${id}/`),
      invalidatesTags: ["expenseList"],
    }),
    duration: builder.query({
      query: () => createRequest(`/spaceDurations/`),
      providesTags: ["durationList"],
    }),
    //account
    AccountList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        let url = `/space/${spaceId}/account/`;
        if (name) {
          url += `?name=${name}`;
          if (page_size) {
            url += `&page_size=${page_size}`;
          }
          if (page) {
            url += `&page=${page}`;
          }
        } else {
          if (page_size) {
            url += `?page_size=${page_size}`;
            if (page) {
              url += `&page=${page}`;
            }
          } else {
            if (page) {
              url += `?page=${page}`;
            }
          }
        }

        return createRequest(url);
      },
      providesTags: ["accountList"],
    }),

    AccountCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/account/`, input),
      invalidatesTags: ["accountList"],
    }),
    AccountGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/account/${id}/`),
      providesTags: ["accountGet"],
    }),
    AccountUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/account/${id}/`, input),
      invalidatesTags: ["accountList"],
    }),
    AccountDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/account/${id}/`),
      invalidatesTags: ["accountList"],
    }),
    // taxRates
    TaxRatesList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        let url = `/space/${spaceId}/taxRate/`;
        if (name) {
          url += `?name=${name}`;
          if (page_size) {
            url += `&page_size=${page_size}`;
          }
          if (page) {
            url += `&page=${page}`;
          }
        } else {
          if (page_size) {
            url += `?page_size=${page_size}`;
            if (page) {
              url += `&page=${page}`;
            }
          } else {
            if (page) {
              url += `?page=${page}`;
            }
          }
        }

        return createRequest(url);
      },
      providesTags: ["taxRatesList"],
    }),

    TaxRatesCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/taxRate/`, input),
      invalidatesTags: ["taxRatesList"],
    }),
    TaxRatesGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/taxRate/${id}/`),
      providesTags: ["taxRatesGet"],
    }),
    TaxRatesUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/taxRate/${id}/`, input),
      invalidatesTags: ["taxRatesList"],
    }),
    TaxRatesDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/taxRate/${id}/`),
      invalidatesTags: ["taxRatesList"],
    }),
    PackageList: builder.query({
      query: ({ spaceId }) => {
        let url = `/space-id/${spaceId}/package/`;
        return createRequest(url);
      },
      providesTags: ["packageList"],
    }),
    PackageUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space-id/${spaceId}/package-id/${id}/`, input),
      invalidatesTags: ["packageList"],
    }),
    PackageGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space-id/${spaceId}/package-id/${id}/`),
      providesTags: ["taxRatesGet"],
    }),
    MeetingPackageList: builder.query({
      query: ({ spaceId }) => {
        let url = `/space/${spaceId}/meetingRoom/packages/`;
        return createRequest(url);
      },
      providesTags: ["meetingPackageList"],
    }),

    // product
    productList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        let url = `/space/${spaceId}/product/`;
        if (name) {
          url += `?name=${name}`;
          if (page_size) {
            url += `&page_size=${page_size}`;
          }
          if (page) {
            url += `&page=${page}`;
          }
        } else {
          if (page_size) {
            url += `?page_size=${page_size}`;
            if (page) {
              url += `&page=${page}`;
            }
          } else {
            if (page) {
              url += `?page=${page}`;
            }
          }
        }
        return createRequest(url);
      },
      providesTags: ["productList"],
    }),
    productCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/product/`, input),
      invalidatesTags: ["productList"],
    }),
    productGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/product/${id}/`),
      providesTags: ["productGet"],
    }),
    productUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/product/${id}/`, input),
      invalidatesTags: ["productList"],
    }),
    productDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/product/${id}/`),
      invalidatesTags: ["productList"],
    }),
    relatedMeetingList: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/meeting-rooms/${spaceId}/related`),
      providesTags: ["relatedmeeting"],
    }),
    discountList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        let url = `/space/${spaceId}/discountCode/`;
        if (name) {
          url += `?name=${name}`;
          if (page_size) {
            url += `&page_size=${page_size}`;
          }
          if (page) {
            url += `&page=${page}`;
          }
        } else {
          if (page_size) {
            url += `?page_size=${page_size}`;
            if (page) {
              url += `&page=${page}`;
            }
          } else {
            if (page) {
              url += `?page=${page}`;
            }
          }
        }

        return createRequest(url);
      },
      providesTags: ["discountCodeList"],
    }),

    discountCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/discountCode/`, input),
      invalidatesTags: ["discountCodeList"],
    }),

    discountGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/discountCode/${id}/`),
      providesTags: ["discountCodeGet"],
    }),
    discountUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/discountCode/${id}/`, input),
      invalidatesTags: ["discountCodeList"],
    }),
    discountDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/discountCode/${id}/`),
      invalidatesTags: ["discountCodeList"],
    }),
    spaceTypes: builder.query({
      query: () => createRequest(`/spaceTypes/`),
      providesTags: ["spaceTypesGet"],
    }),

    createPurchase: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/purchase/`, data),
      invalidatesTags: ["purchaseList", "spaceInvoice"],
    }),
    purchaseList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        let url = `/space/${spaceId}/purchase/`;
        if (name) {
          url += `?name=${name}`;
          if (page_size) {
            url += `&page_size=${page_size}`;
          }
          if (page) {
            url += `&page=${page}`;
          }
        } else {
          if (page_size) {
            url += `?page_size=${page_size}`;
            if (page) {
              url += `&page=${page}`;
            }
          } else {
            if (page) {
              url += `?page=${page}`;
            }
          }
        }
        return createRequest(url);
      },
      providesTags: ["purchaseList"],
    }),

    getMemberPurchases: builder.query({
      query: ({ spaceId, memberId }) =>
        createRequest(`/space/${spaceId}/purchase/?member=${memberId}`),
      providesTags: ["purchaseList"],
    }),
    profileGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/profile/${id}`),
      providesTags: ["profileGet"],
    }),

    visitReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/visitorReport-visit/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start} 00:00:00`;
        }
        if (end) {
          url += `&endDate=${end} 00:00:00`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitReport"],
    }),
    visitorReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/visitorReport-visitor/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start} 00:00:00`;
        }
        if (end) {
          url += `&endDate=${end} 00:00:00`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    DiscountCodeReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/discountReport-discountCodeUsage/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    DiscountInvoiceReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/discountReport-invoicedDiscounts/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    ProductByMemberReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/productReport-invoicedProductsByMember/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    InvoicedProductReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/productReport-invoicedProducts/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    revenueMemberReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/revenueReport-revenueByMember/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    revenueMonthReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/revenueReport-revenueByMonth/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    revenueInvoiceReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/revenueReport-paidInvoices/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    BookingCreditsReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/bookingReport-bookingCredit/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    BookingMemberReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/bookingReport-bookingByMember/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    BookingMonthReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/bookingReport-bookingByMonth/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    BookingResourceReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/bookingReport-bookingByResource/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    BookingListReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/bookingReport-bookingList/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    RoomBookingReport: builder.query({
      query: ({
        spaceId,
        page_size,
        page,
        start,
        end,
        location,
        meetingRoom,
      }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/bookingReport-bookingList/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }
        if (meetingRoom) {
          url += `&meetingRoom=${meetingRoom}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    TodayBookingReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/bookingReport-todayBookings/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    IncomeEarnedReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/financeReport-incomeEarnedByAccount/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    PaymentSummaryReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/financeReport-payments/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    PaymentByIncomeTypeReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/financeReport-paymentsByIncomeType/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    getProfitAndLossStatement: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/financeReport-profitAndLossStatement/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),

    getInvoiceDetailsReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/invoiceReport-invoiceDetails/?page_size=${page_size}&page=${page}`;
        // if (page_size) {
        //   url += `&page_size=${page_size}`;
        // }
        // if (page) {
        //   url += `&page=${page}`;
        // }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["invoiceReport"],
    }),

    getOccupancyReport: builder.query({
      query: ({
        spaceId,
        page_size,
        page,
        start,
        end,
        location,
        spaceType,
      }) => {
        let url = `/space/${spaceId}/financialReporting/occupancyReport/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }
        if (spaceType != 0) {
          url += `&spaceType=${spaceType}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),

    InvoiceMemberMyMonthReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/invoiceReport-invoicedMembersByMonth/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    allDesksReport: builder.query({
      query: ({
        spaceId,
        page_size,
        page,
        start,
        end,
        location,
        available,
        companyId,
      }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/deskReport-allDesks/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }
        if (available) {
          url += `&available=${true}`;
        }
        if (companyId) {
          url += `&companyId=${companyId}`;
        }
        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    bookingAvgRevenueReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/kpiReport-bookingAvgRevenue/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    bookingByWeekDayReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/kpiReport-bookingByWeekDay/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    bookingResourceUsageReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/kpiReport-bookingResourceUsage/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    bookingResourceUsageAndRevReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/kpiReport-bookingResourceUsageAndRev/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    bookingUserTypeReport: builder.query({
      query: ({ spaceId, page_size, page, start, end, location }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/kpiReport-bookingUserType/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (page) {
          url += `&page=${page}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),
    ///////////////////////////////////////

    LoggedinInUser: builder.query({
      query: ({ spaceId }) => {
        let url = `/space/${spaceId}/spaceowner/loggedIn/`;

        return createRequest(url);
      },

      providesTags: ["LoggedInUser"],
    }),
    SpaceGet: builder.query({
      query: ({ spaceId }) => {
        let url = `/space/${spaceId}/`;

        return createRequest(url);
      },

      providesTags: ["Space"],
    }),
    ownerLocation: builder.query({
      query: ({ spaceId }) => {
        let url = `/owner-locations/`;

        return createRequest(url);
      },

      providesTags: ["ownerLocation"],
    }),
    AllSpaces: builder.query({
      query: ({ spaceId }) => {
        let url = `/space/${spaceId}/spaces/`;

        return createRequest(url);
      },

      providesTags: ["AllSpaces"],
    }),
    getIntegryHash: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/space/${spaceId}/getIntegryHash/`),

      providesTags: ["settings"],
    }),
    getNotificationSettings: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/space/${spaceId}/notificationSettings/`),

      providesTags: ["settings"],
    }),

    updateNotificationsSettings: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/notificationSettings/`, data),

      invalidatesTags: ["settings"],
    }),
    postCallBackUrl: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/addIntegryCallback/`, data),
      invalidatesTags: [""],
    }),
    Companies: builder.query({
      query: ({ spaceId }) => {
        let url = `/space/${spaceId}/profile/?company=True`;

        return createRequest(url);
      },

      providesTags: ["companies"],
    }),
    ListPaymentMethods: builder.query({
      query: () => {
        let url = `/list-payment-methods/`;

        return createRequest(url);
      },

      providesTags: ["ListPaymentMethods"],
    }),

    //product cat
    // assetCategoryList: builder.query({
    //   query: ({ spaceId, page_size, page, name }) => {
    //     let url = `/space/${spaceId}/productCategory/?1`;
    //     if (page_size) {
    //       url += `&page_size=${page_size}`;
    //     }
    //     if (name) {
    //       url += `?name=${name}`;
    //     }
    //     if (page) {
    //       url += `&page=${page}`;
    //     }

    //     return createRequest(url);
    //   },
    //   providesTags: ["productCatList"],
    // }),

    productCategoryCreate: builder.mutation({
      query: ({ spaceId, input }) =>
        createPostRequest(`/space/${spaceId}/productCategory/`, input),
      invalidatesTags: ["productCatList", "productList"],
    }),
    productCategoryGet: builder.query({
      query: ({ spaceId, id }) =>
        createRequest(`/space/${spaceId}/productCategory/${id}/`),
      providesTags: ["productCatGet"],
    }),
    getUnassignedResources: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/meeting-rooms/${spaceId}/unassignedResources`),
      // providesTags: ["productCatGet"],
    }),

    productCategoryUpdate: builder.mutation({
      query: ({ spaceId, id, input }) =>
        createUpdateRequest(`/space/${spaceId}/productCategory/${id}/`, input),
      invalidatesTags: ["productCatList", "productList"],
    }),
    productCategoryList: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        let url = `/space/${spaceId}/productCategory/?1`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (name) {
          url += `?name=${name}`;
        }
        if (page) {
          url += `&page=${page}`;
        }

        return createRequest(url);
      },
      providesTags: ["productCatList"],
    }),
    productCategoryDelete: builder.mutation({
      query: ({ spaceId, id }) =>
        createDeleteRequest(`/space/${spaceId}/productCategory/${id}/`),
      invalidatesTags: ["productCatList", "productList"],
    }),

    getQuickBookURL: builder.query({
      query: ({ spaceId }) =>
        createRequest(`/space/${spaceId}/initiateQuickbookOauthFlow/`),
      // providesTags: [""],
    }),
    getKisiGroups: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/kisi/groups`),
      // providesTags: [""],
    }),

    postQuickBookCallBackUrl: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(
          `/space/${spaceId}/callBackQuickbookOauthFlow/`,
          data
        ),
      invalidatesTags: ["khani"],
    }),
    getAllCheckins: builder.query({
      query: ({ spaceId, page_size, page, start, end, location, member }) => {
        let url = `/space/${spaceId}/financialReporting/checkInReport?page=${page}`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&end_date=${end}`;
        }
        if (member) {
          url += `&member=${member}`;
        }
        return createRequest(url);
      },

      providesTags: ["checkins"],
    }),

    getCheckinByDay: builder.query({
      query: ({ spaceId, page_size, page, day, location }) => {
        let url = `/space/${spaceId}/financialReporting/checkInReport-ByDay?page=${page}`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (day) {
          url += `&day=${day}`;
        }
        if (location) {
          url += `&location=${location}`;
        }
        return createRequest(url);
      },

      providesTags: ["checkins"],
    }),

    getChekinsByMember: builder.query({
      query: ({ spaceId, page_size, page, start, end, location, member }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/financialReporting/checkInReport-ByMember?page=${page}`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (start) {
          url += `&startDate=${start}`;
        }
        if (end) {
          url += `&endDate=${end}`;
        }
        if (location) {
          url += `&locations=${location}`;
        }
        if (member) {
          url += `&member=${member}`;
        }

        return createRequest(url);
      },
      providesTags: ["visitorReport"],
    }),

    getAllLocks: builder.query({
      query: ({ spaceId }) => createRequest(`/space/${spaceId}/kisi/locks`),
    }),
    getLocksByGroupId: builder.query({
      query: ({ spaceId, groupId }) =>
        createRequest(`/space/${spaceId}/kisiSecurityGroups/${groupId}/`),
    }),

    getAccessGroups: builder.query({
      query: ({ spaceId, page_size, page, name }) => {
        // eslint-disable-next-line camelcase
        let url = `/space/${spaceId}/kisiSecurityGroups/?page=${page}`;
        if (page_size) {
          url += `&page_size=${page_size}`;
        }
        if (name) {
          url += `&name=${name}`;
        }

        return createRequest(url);
      },
      providesTags: ["accessgroup"],
    }),

    createAccessGroup: builder.mutation({
      query: ({ spaceId, data }) =>
        createPostRequest(`/space/${spaceId}/kisiSecurityGroups/`, data),
      invalidatesTags: ["accessgroup"],
    }),
    updateAccessGroup: builder.mutation({
      query: ({ spaceId, groupId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/kisiSecurityGroups/${groupId}/`,
          data
        ),
      invalidatesTags: ["accessgroup"],
    }),

    deleteGroup: builder.mutation({
      query: ({ spaceId, groupId }) =>
        createDeleteRequest(`/space/${spaceId}/kisiSecurityGroups/${groupId}/`),
      invalidatesTags: ["accessgroup"],
    }),
    getMemberAccessGroup: builder.query({
      query: ({ spaceId, memberId }) =>
        createRequest(
          `/space/${spaceId}/kisi/attachedGroup?member=${memberId}`
        ),
    }),

    updateMeetingRoomKisiGroups: builder.mutation({
      query: ({ spaceId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/kisiSecurityGroups/batchUpdate/MeetingRoomSecurityGroups/`,
          data
        ),
      invalidatesTags: ["meetingRoom"],
    }),
    updatePackageKisiGroups: builder.mutation({
      query: ({ spaceId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/kisiSecurityGroups/batchUpdate/PackageSecurityGroups/`,
          data
        ),
      invalidatesTags: ["package"],
    }),

    updateMeetingPackageKisiGroups: builder.mutation({
      query: ({ spaceId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/kisiSecurityGroups/batchUpdate/MeetingPackageSecurityGroups/`,
          data
        ),
      invalidatesTags: ["meetingRoomPackage"],
    }),

    updateFloorKisiGroups: builder.mutation({
      query: ({ spaceId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/kisiSecurityGroups/batchUpdate/FloorSecurityGroups/`,
          data
        ),
      invalidatesTags: ["floorPlan"],
    }),

    updateDayPassKisiGroups: builder.mutation({
      query: ({ spaceId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/kisiSecurityGroups/Update/SecurityGroups/`,
          data
        ),
      invalidatesTags: ["khani"],
    }),

    updateRolesKisiGroups: builder.mutation({
      query: ({ spaceId, data }) =>
        createUpdateRequest(
          `/space/${spaceId}/kisiSecurityGroups/batchUpdate/RoleSecurityGroups/`,
          data
        ),
      invalidatesTags: ["roles"],
    }),
  }),
});

export const {
  useUpdateRolesKisiGroupsMutation,
  useUpdateDayPassKisiGroupsMutation,
  useUpdateFloorKisiGroupsMutation,
  useUpdateMeetingPackageKisiGroupsMutation,
  useUpdatePackageKisiGroupsMutation,
  useUpdateMeetingRoomKisiGroupsMutation,
  useGetMemberAccessGroupQuery,
  useDeleteGroupMutation,
  useUpdateAccessGroupMutation,
  useGetLocksByGroupIdQuery,
  useGetAllLocksQuery,
  useCreateAccessGroupMutation,
  useGetAccessGroupsQuery,
  useGetChekinsByMemberQuery,
  useGetCheckinByDayQuery,
  useGetAllCheckinsQuery,
  useGetAllResourcesQuery,
  useGetKisiGroupsQuery,
  useGetInvoiceDetailsReportQuery,
  useGetOccupancyReportQuery,
  useGetProfitAndLossStatementQuery,
  usePostQuickBookCallBackUrlMutation,
  useGetQuickBookURLQuery,
  useCreateFlexDeskBookingMutation,
  useGetAvailableHotDesksQuery,
  useGetUnassignedResourcesQuery,
  useCreateHotdeskBookingMutation,
  useListPaymentMethodsQuery,
  useCompaniesQuery,
  useDeletePackageDurationMutation,
  useUpdatePackageDurationMutation,
  useCreatePackageDurationMutation,
  useGetSpaceDurationsQuery,
  useDeleteCancellationPolicyMutation,
  useGetCancellationPoliciesQuery,
  useUpdateCancellationPolicyMutation,
  useCreateCancellationPolicyMutation,
  useUpdateNotificationsSettingsMutation,
  useGetNotificationSettingsQuery,
  useGetBannerQuery,
  usePostCallBackUrlMutation,
  useGetIntegryHashQuery,
  useCreatePurchaseMutation,
  useGetMemberPurchasesQuery,
  //BELOW FINANCIALS

  useInventoryListQuery,
  useVendorCategoryCreateMutation,
  useVendorCategoryListQuery,
  useVendorCategoryGetQuery,
  useVendorCategoryUpdateMutation,
  useVendorCategorySearchQuery,
  useVendorCategoryDeleteMutation,
  useAssetCategoryCreateMutation,
  useAssetCategoryListQuery,
  useAssetCategoryGetQuery,
  useAssetCategoryUpdateMutation,
  useAssetCategorySearchQuery,
  useAssetCategoryDeleteMutation,
  useAssetCreateMutation,
  useAssetDeleteMutation,
  useAssetGetQuery,
  useAssetListQuery,
  useAssetUpdateMutation,
  useVendorCreateMutation,
  useVendorDeleteMutation,
  useVendorGetQuery,
  useVendorListQuery,
  useVendorUpdateMutation,
  useExpenseCategoryCreateMutation,
  useExpenseCategoryListQuery,
  useExpenseCategoryGetQuery,
  useExpenseCategoryUpdateMutation,
  useExpenseCategorySearchQuery,
  useExpenseCategoryDeleteMutation,
  useExpenseCreateMutation,
  useExpenseDeleteMutation,
  useExpenseGetQuery,
  useExpenseListQuery,
  useExpenseUpdateMutation,
  useDurationQuery,
  useAccountCreateMutation,
  useAccountDeleteMutation,
  useAccountGetQuery,
  useAccountListQuery,
  useAccountUpdateMutation,
  useAssetListwithCountsQuery,
  useTaxRatesCreateMutation,
  useTaxRatesDeleteMutation,
  useTaxRatesGetQuery,
  useTaxRatesListQuery,
  useTaxRatesUpdateMutation,
  usePackageListQuery,
  usePackageUpdateMutation,
  usePackageGetQuery,
  useMeetingPackageListQuery,
  useMeetingPackageUpdateMutation,
  useProductCreateMutation,
  useProductDeleteMutation,
  useProductGetQuery,
  useProductListQuery,
  useProductUpdateMutation,
  useRelatedMeetingListQuery,
  useDiscountCreateMutation,
  useDiscountDeleteMutation,
  useDiscountGetQuery,
  useDiscountListQuery,
  useDiscountUpdateMutation,
  useSpaceTypesQuery,
  usePurchaseListQuery,
  useProfileGetQuery,
  useVisitReportQuery,
  useVisitorReportQuery,
  useDiscountCodeReportQuery,
  useDiscountInvoiceReportQuery,
  useProductByMemberReportQuery,
  useInvoicedProductReportQuery,
  useProductReportQuery,
  useRevenueInvoiceReportQuery,
  useRevenueMemberReportQuery,
  useRevenueMonthReportQuery,
  useBookingCreditsReportQuery,
  useBookingMemberReportQuery,
  useBookingMonthReportQuery,
  useBookingResourceReportQuery,
  useBookingListReportQuery,
  useRoomBookingReportQuery,
  useTodayBookingReportQuery,
  useIncomeEarnedReportQuery,
  usePaymentByIncomeTypeReportQuery,
  usePaymentSummaryReportQuery,
  useInvoiceMemberMyMonthReportQuery,
  useAllDesksReportQuery,
  useBookingAvgRevenueReportQuery,
  useBookingByWeekDayReportQuery,
  useBookingResourceUsageAndRevReportQuery,
  useBookingResourceUsageReportQuery,
  useBookingUserTypeReportQuery,
  /////////////////////
  useDiscountReportQuery,
  useRevenueReportQuery,
  useSpaceGetQuery,
  useAllSpacesQuery,
  useOwnerLocationQuery,
  useLoggedinInUserQuery,
  useBookingReportQuery,

  //ABOVE FINANCIALS
  useAddPaymentMutation,
  useDeleteNoteMutation,
  useUpdateTourNoteMutation,
  useGetTourNotesQuery,
  useCreateNotesMutation,
  useUpdateTourQuestionMutation,
  useDeleteTourQuestionMutation,
  useGetTourQuestionsQuery,
  useCreateTourQuestionMutation,
  //////////////////////
  useTourSlotsMutation,
  useGetBookingsOfCompanyEmployeeQuery,
  useSentQuotationMutation,
  useGetAllIndividualsQuery,
  useSentInvoiceMutation,
  useGetAllCompaniesQuery,
  /////////////////////
  useMarkAsReadNotificationsMutation,
  useDeleteAnnouncementsMutation,
  useGetAnnouncementsQuery,
  useCreateAnnouncementMutation,
  ///////////////////////
  useGetVisitorsWithoutQueryQuery,
  useUpdateVisitorMutation,
  useDeletevisitorMutation,
  useGetLoggedInAdminQuery,
  //////////////////////////////

  useGetPostsQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
  useCreatePostMutation,
  useDeletePostMutation,
  useUpdatePostMutation,
  useGetBlocksUsersQuery,
  ////////////////////////////////////
  useGetSpecficTicketQuery,
  useCancelMemberPackageMutation,
  useUpdateMemberPackageMutation,
  useGetAllTicketQuery,
  useCreateTicketMutation,
  useDeleteTicketMutation,
  useUpdateTicketMutation,
  useCreateMessageMutation,
  useGetMessagesQuery,
  useDeleteMessageMutation,
  useUpdateMessageMutation,
  useGetOwnerLocationsQuery,
  useRevokeStripeKeyMutation,
  useUpdateNotificationsMutation,
  useGetFloorRoomsForMemberQuery,
  useBookingSlotsMutation,
  useGetAllMembersQuery,
  useGetRelatedMeetingRoomsQuery,
  useGetCurrencyQuery,
  useDeleteInvoiceV2Mutation,
  useCreateInvoiceV2Mutation,
  useGetSingleInvoiceQuery,
  useUpdateInvoiceMutation,
  useGetAllInvoicesQuery,
  useGetInvoicesOfSingleMemberQuery,
  useGetBookingsOfSingleMemberQuery,
  useGetSingleIndividualQuery,
  useGetSingleCompanyQuery,
  useGetPackageDetailsForMemberQuery,
  useAssignPackageToMemberMutation,
  useGetIndividualsQuery,
  useDeleteUserForSpaceMutation,
  useUpdateFloorPlanMutation,
  useUpdateFloorRoomMutation,
  useGetCurrentUserRoleQuery,
  useSetPasswordMutation,
  useUpdateRoleMutation,
  useGetSingleRolesQuery,
  useDeleteRoleMutation,
  useGetRolesQuery,
  useCreateRoleMutation,
  useGetModulesQuery,
  useGetSingleTeamQuery,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
  useGetAllLocationsQuery,
  useCreateLocationMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetAllEmployeesQuery,
  useCreateEmployeeMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useGetAllTeamsQuery,
  useCreateTeamMutation,
  useCreateAdminMutation,
  // useDeleteDeactivateQuery,
  useDeleteDeactivateMutation,
  useUpdateCompanyUserMutation,
  useDeleterCompanyUserMutation,
  useGetSingleCompanyUsersQuery,
  useGetCompanyUsersQuery,
  useDeleteFloorRoomMutation,
  // useGetSpecificFloorRoomQuery,
  useGetFloorRoomsQuery,
  useCreateFloorRoomsMutation,
  useGetSpecificCompanyQuery,
  useDeleteFloorPlanMutation,
  useGetSpecificFloorPlansQuery,
  useGetCompaniesQuery,
  useCreateUserForCompanyMutation,
  useCreateFloorPlanMutation,
  useUpdateVisitsDataMutation,
  useUpdateVisitsQuery,
  useDeletevisitsMutation,
  useGetAllVisitsQuery,
  useDeleteVisitorMutation,
  useGetAllVisitorsQuery,
  useCreateVisitMutation,
  useCreateVisitorsMutation,
  useConvertToInvoiceQuery,
  useUpdateQuotationDataMutation,
  useUpdateQuotationsQuery,
  useDeleteQuotationMutation,
  useGetMeetingRoomQuery,
  useCreateQuotationsMutation,
  useGetQuotationsQuery,
  useGetSpaceDataQuery,
  useCreateMeetingRoomMutation,
  useDeleteMeetingRoomMutation,
  useUpdateMeetingRoomMutation,
  useGetPackageQuery,
  useCreatePackageMutation,
  useDeletePackageMutation,
  useUpdatePackageMutation,
  useGetMeetingRoomPackageQuery,
  useCreateMeetingRoomPackageMutation,
  useDeleteMeetingRoomPackageMutation,
  useUpdateMeetingRoomPackageMutation,
  useUpdateSpaceMutation,
  useCreateCompanyMutation,
  useAssignPackageMutation,
  useUpdateCompanyMutation,
  useUpdateUserMutation,
  useCreateUserForSpaceMutation,
  useGetAllSpaceUsersQuery,
  useCreateBookingMutation,
  useGetAllUpComingBookingQuery,
  useDeleteMeetingBookingMutation,
  useDeleteCommpanyMutation,
  useGetSpaceInvoiceQuery,
  useGetSpaceInvoiceByIdQuery,
  useUpdateSpaceInvoiceMutation,
  useUpdateMeetingBookingMutation,
  useMultiplePackagesCompanyMutation,
  useCreateManuallyInvoiceMutation,
  useGetSpaceDashboardQuery,
  useDeleteSpaceInvoiceMutation,
  useGetAllSpaceUserQuery,
  useGetFloorPlansQuery,
  useChangePswMutation,
  useDeleterNotificationMutation,
  useGetNotificationQuery,
  useUpdateBookingInvoiceMutation,
  useAddTourMutation,
  useGetAlltoursQuery,
  useGetTourQuery,
  useUpdateTourMutation,
  useDeleteTourMutation,
  useUpdatePartnerMutation,
  useAddPartnerMutation,
  useGetPartnersQuery,
  useDeletePartnerMutation,
  useGetOffersQuery,
  useAddOfferMutation,
  useUpdateOfferMutation,
  useDeleteOfferMutation,
  useProductCategoryCreateMutation,
  useProductCategoryDeleteMutation,
  useProductCategoryGetQuery,
  useProductCategoryUpdateMutation,
  useProductCategoryListQuery,
} = spaceApi;
