import { Link, Outlet } from "react-router-dom";
import "./styles.css";
import { useVendorCategoryListQuery } from "../../Slices/spaceApi";
import VendorContext from "../../Context/VendorContext";
import { Tab, Tabs } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import VendorList from "./VendorList";
import VendorCategory from "./VendorCategory";
import "./styles.css";
const Vendor = () => {
  // const { data: categoryList } = useVendorCategoryListQuery({
  //   spaceId: 1,
  // });
  return (
    <>
      {/* <VendorContext.Provider value={{ categoryList }}> */}{" "}
      <section className="container-fluid  with-bottom-padding  p-3 pt-0  mt-0">
        <Tabs
          defaultActiveKey="Account"
          id="justify-tab-example"
          className=""
          variant="underline"
          justify
        >
          <Tab
            eventKey="Account"
            title={
              <span className="d-flex align-items-center">
                <Icon.PersonCheck /> &nbsp;Vendor List
              </span>
            }
          >
            {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <VendorList />
          </Tab>
          <Tab
            eventKey="Social"
            title={
              <span className="d-flex align-items-center">
                <Icon.PersonRolodex /> &nbsp; Vendor Category
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <VendorCategory />
          </Tab>
        </Tabs>
      </section>
      {/* </VendorContext.Provider> */}
    </>
  );
};

export default Vendor;
