import React, { useState } from "react";
import { useEffect } from "react";

const MeetingFields = (props) => {
  const { label, onChange, id, errorMessage, submitted, ...inputProps } = props;
  const [focused, setFocused] = useState(false);

  const handleFocus = (e) => {
    setFocused(true);
  };

  const hasValue = !!inputProps.value;

  useEffect(() => {
    if (!hasValue && submitted) {
      setFocused(true);
    }
  }, [submitted]);

  return (
    <div className="mb-2">
      <label htmlFor={id} className="mb-1">
        {label}
      </label>
      <input
        {...inputProps}
        onChange={onChange}
        className="form-control"
        onBlur={handleFocus}
        focused={focused.toString()}
        required
      />
      <span className="error">{errorMessage}</span>
    </div>
  );
};

export default MeetingFields;
