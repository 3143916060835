import React, { useEffect, useState } from "react";
import PartnersList from "../Components/Partners/PartnersList";
import { useGetCategoriesQuery } from "../Slices/constantApi";
import { Button, Modal } from "react-bootstrap";
import PartnerForm from "../Components/Partners/PartnerForm";
import {
  useAddOfferMutation,
  useAddPartnerMutation,
  useGetPartnersQuery,
} from "../Slices/spaceApi";
import { useSelector } from "react-redux";
import { requiredAlert } from "../utils/Alerts";
import toast from "react-hot-toast";
import OfferForm from "../Components/Offers/OfferForm";
import { useGlobalContext } from "../Context/GlobalContext";
import { ReactComponent as Lock } from "../Assets/Lock.svg";
import { ReactComponent as Plus } from "../Assets/Plus.svg";

const Partners = () => {
  const { data } = useGetCategoriesQuery();
  const { partnerAndOffersPermissions } = useGlobalContext();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Partners List");
  }, []);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: fetchPartners } = useGetPartnersQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [file, setFile] = useState(null);
  const [createPartner, resultStats] = useAddPartnerMutation();
  const [createOffer, resultStats1] = useAddOfferMutation();
  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setFormData(initialFormData);
    setFile(null);
    setShow(false);
    setPartnerCheck(false);
  };

  const [showOffer, setShowOffer] = useState(false);

  const handleShowOffer = () => setShowOffer(true);
  const handleCloseOffer = () => {
    setFile(null);
    setShowOffer(false);
    setFormOfferData(initialFormOfferData);
    setPartnerCheck(false);
  };

  const initialFormData = {
    category: data?.[0]?.id,
    name: "",
    description: "",
    contact_name: "",
    image: "",
    contact_email: "",
    website: "",
  };

  const today = new Date();
  const tomorrow = new Date(today);
  const nextTomorrow = new Date(today);

  tomorrow.setDate(today.getDate() + 1); // Add one day to the current date
  nextTomorrow.setDate(today.getDate() + 2);

  const tomorrowFormatted = tomorrow.toISOString().slice(0, 10); // Format tomorrow's date in YYYY-MM-DD format

  const nextTomorrowFormatted = nextTomorrow.toISOString().slice(0, 10); // Format tomorrow's date in YYYY-MM-DD format

  const initialFormOfferData = {
    partner: fetchPartners?.partners?.[0]?.id,
    discount_percentage: "",
    start_date: tomorrowFormatted,
    end_date: nextTomorrowFormatted,
    description: "",
    image: "",
  };
  useEffect(() => {
    if (fetchPartners) {
      initialFormOfferData.partner = fetchPartners?.partners?.[0]?.id;
    }
  }, [fetchPartners]);

  const [formData, setFormData] = useState(initialFormData);

  const [formOfferData, setFormOfferData] = useState(initialFormOfferData);
  useEffect(() => {
    if (data) {
      setFormData(initialFormData);
    }
  }, [data]);
  const postPartner = (e) => {
    e.preventDefault();
    setApiSpinner(true);

    createPartner({ data: formData, spaceId: spaceId || currentSpaceId });
  };

  const postOffer = (e) => {
    e.preventDefault();
    setApiSpinner(true);
    createOffer({ data: formOfferData, spaceId: spaceId || currentSpaceId });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      // setTimeout(() => {
      handleClose();
      setFormData(initialFormData);
      // }, 1500);
      setApiSpinner(false);

      toast.success("Partner Added SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);

      const errorMessage = Object?.values(resultStats1?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
    if (resultStats1?.isSuccess) {
      // setTimeout(() => {
      handleCloseOffer();
      // to reset
      setFormOfferData(initialFormOfferData);
      // }, 1500);
      setApiSpinner(false);

      toast.success("Offer Added SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats1]);
  const [partnerCheck, setPartnerCheck] = useState(false);
  useEffect(() => {
    if (partnerCheck) {
      handleCloseOffer();
      handleShow();
    }
  }, [partnerCheck]);
  return (
    <div>
      <div className="row" style={{ padding: "1rem" }}>
        <div className="col-md-8 offset-md-4 pb-3">
          {/* <Button
            variant="primary"
            onClick={
              partnerAndOffersPermissions[0]?.create ? handleShowOffer : null
            }
            className="send-invoice-btn float-right "
          >
            {partnerAndOffersPermissions[0]?.create ? (
              <>
                <Plus /> Create Offer
              </>
            ) : (
              <span>
                Create Offer <Lock />
              </span>
            )}
          </Button> */}
          <Button
            variant="primary"
            onClick={partnerAndOffersPermissions[0]?.create ? handleShow : null}
            className="send-invoice-btn float-right me-2"
          >
            {partnerAndOffersPermissions[0]?.create ? (
              <>
                <Plus /> Create Partner
              </>
            ) : (
              <span>
                Create Partner <Lock />
              </span>
            )}
          </Button>
        </div>
        <span className="fw-bold mb-4 ml-2">
          To add offer you need to go on detail page for Partner.
        </span>
        <PartnersList partners={fetchPartners?.partners} categories={data} />

        <Modal
          show={show}
          onHide={handleClose}
          id="packagePopUp"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="" closeButton>
            <Modal.Title as="h5">Create Partner</Modal.Title>
          </Modal.Header>
          {/* <form> */}
          <Modal.Body>
            <PartnerForm
              categories={data}
              setFormData={setFormData}
              formData={formData}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              file={file}
              setFile={setFile}
            />
            <div className="mt-4">
              <Button
                variant="primary"
                onClick={postPartner}
                disabled={!formData?.name || apiSpinner || isUploading}
              >
                Create Partner{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                disabled={apiSpinner}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
           
            </Modal.Footer> */}
          {/* </form> */}
        </Modal>

        <Modal
          show={showOffer}
          onHide={handleCloseOffer}
          id="packagePopUp"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="" closeButton>
            <Modal.Title as="h5">Create Offer</Modal.Title>
          </Modal.Header>
          <form>
            <Modal.Body>
              <OfferForm
                partners={fetchPartners?.partners}
                formData={formOfferData}
                setFormData={setFormOfferData}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                file={file}
                setFile={setFile}
                setPartnerCheck={setPartnerCheck}
              />
              <div className="mt-4">
                <Button
                  variant="primary"
                  onClick={postOffer}
                  disabled={
                    (!formOfferData?.discount_percentage &&
                      !formOfferData?.description) ||
                    apiSpinner ||
                    isUploading
                  }
                >
                  Create Offer{" "}
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                <button
                  className="btn btn-secondary ml-3"
                  disabled={apiSpinner}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCloseOffer();
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
            
            
            </Modal.Footer> */}
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default Partners;
