import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useState } from "react";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import {
  useGetAllVisitorsQuery,
  useGetAllVisitsQuery,
} from "../../Slices/spaceApi";

import { useSelector } from "react-redux";
import { Badge, Pagination, Tab, Tabs } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import Loader from "../Loader/Loader";

import DeleteVisit from "./DeleteVisit";
import UpdateVisit from "./UpdateVisit";
import ViewVisitor from "./ViewVisitor";
import { useGlobalContext } from "../../Context/GlobalContext";

const ListVisitors = () => {
  const { visitsPermissions } = useGlobalContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const [status, setStatus] = useState({
    label: "Status",
    value: "all",
  });
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [loading, setLoading] = useState(false);
  const { data: visitsData, isLoading: fetchingData } = useGetAllVisitsQuery({
    spaceId: spaceId || currentSpaceId,
    page: activePage,
    page_size: itemsPerPage.value,
    name: searchQuery,
    past: status.value,
  });

  useEffect(() => {
    setLoading(false);
  }, [visitsData]);
  const { items, requestSort, sortConfig } = useSortableData(
    visitsData?.visits || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);

    setLoading(true);
  };
  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      setLoading(true);
      setSearchQuery(search);
      // setActivePage(1);
    };
    clearTimeout(timer);
    if (search.length > 0) {
      timer = setTimeout(debounceSearch, 1000);
    } else {
      setSearchQuery("");
      // setActivePage(1);
      setLoading(false);
    }
    return () => clearTimeout(timer);
  }, [search]);
  const handleStatus = (e) => {
    setStatus(e);
  };
  const formateDate = (createdAt) => {
    const date = new Date(createdAt);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];

  const optionsStatus = [
    {
      label: "Status",
      value: "",
    },
    {
      label: "Upcoming",
      value: 2,
    },
    {
      label: "Past",
      value: 1,
    },
  ];

  // if (fetchingData) {
  //   return <Loader height={6.6} />;
  // }
  // if (loading) {
  //   return <Loader height={6.6} />;
  // }
  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}, ${formattedTime}`;
  }
  return (
    <>
      <section
        className="container-fluid invoice-list-wrapper with-bottom-padding"
        style={{ padding: "0px 16px" }}
      >
        <div className="card">
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-between ">
              <div className="col-12  col-md-4 col-lg-6">
                <input
                  type="search"
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    // setLoading(true);
                  }}
                  placeholder="Search Visits"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
                {visitsPermissions[0]?.create ? (
                  <Link
                    to="/visit/add"
                    className="btn btn-primary"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Add Visit
                    </span>
                  </Link>
                ) : (
                  <div
                    className="btn btn-primary"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Add Visit
                      <Lock />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="col-12 d-flex flex-column flex-md-row justify-content-between ">
            <div className="col-12 col-md-6 my-3 my-lg-0 col-lg-4 d-flex">
              <span className="mt-2">Filter By &nbsp;</span>
              <Select
                options={optionsStatus}
                defaultValue={{
                  label: "Status",
                  value: "all",
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handleStatus}
                value={status}
                styles={colorStyles}
              />
            </div>

            <div className="col-12 col-md-2 mb-3">
              <div className="">
                <div className="col-12 d-flex justify-content-end ">
                  <label
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      fontWeight: "normal",
                    }}
                  >
                    <small className="mr-2 mt-2 title"> Show</small>
                    <Select
                      options={options}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={handlePage}
                      value={itemsPerPage}
                      id="pages-select"
                      styles={colorStyles}
                    />
                    <small className="ml-2 mt-2"> entries</small>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>#</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Visitor</div>
                      {/* <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("visitor:name")}
                          className={getClassNamesFor("visitor:name")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("visitor:name")}
                          className={getClassNamesFor("visitor:name")}
                          style={{ fontSize: "10px" }}
                        />
                      </div> */}
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Contact</div>
                      {/* <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("subTotal")}
                          className={getClassNamesFor("subTotal")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("subTotal")}
                          className={getClassNamesFor("subTotal")}
                          style={{ fontSize: "10px" }}
                        />
                      </div> */}
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Date and Time</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("expected_visit_datetime")}
                          className={getClassNamesFor(
                            "expected_visit_datetime"
                          )}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("expected_visit_datetime")}
                          className={getClassNamesFor(
                            "expected_visit_datetime"
                          )}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Spot Reserved</div>
                      {/* <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("total")}
                          className={getClassNamesFor("total")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("total")}
                          className={getClassNamesFor("total")}
                          style={{ fontSize: "10px" }}
                        />
                      </div> */}
                    </div>
                  </th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8} className="fs-5">
                      <Loader />
                    </td>
                  </tr>
                )}

                {!loading && items?.length > 0 ? (
                  items?.map((item, index) => {
                    {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              color: "#7367f0",
                              fontWeight: "bold",
                            }}
                          >
                            #{item.id}
                          </td>

                          <td>
                            <div className="media">
                              <div className="media-body my-auto d-flex flex-column">
                                <span className="mb-0 table-avatar">
                                  <b>
                                    {" "}
                                    {
                                      // item?.visitor?.name

                                      item?.visitor?.name?.length > 20
                                        ? item?.visitor?.name?.slice(0, 20) +
                                          "..."
                                        : item?.visitor?.name
                                    }
                                  </b>
                                </span>
                                <small className="mb-0 text-muted">
                                  {
                                    // item?.visitor?.email

                                    item?.visitor?.email?.length > 20
                                      ? item?.visitor?.email?.slice(0, 20) +
                                        "..."
                                      : item?.visitor?.email || (
                                          <Badge bg="badge bg-label-danger">
                                            No Email Provided
                                          </Badge>
                                        )
                                  }
                                </small>
                              </div>
                            </div>
                          </td>

                          <td>{item?.visitor.phone_number}</td>

                          <td>
                            {formatDateTime(item?.expected_visit_datetime)}
                          </td>

                          {/* <td>{item?.location}</td> */}
                          <td>
                            {item?.location?.length > 20
                              ? item?.location?.slice(0, 20) + "..."
                              : item?.location}
                          </td>

                          <td>
                            {new Date(item.expected_visit_datetime) >
                            new Date() ? (
                              <Badge bg={"badge bg-label-success"}>
                                Upcoming
                              </Badge>
                            ) : (
                              <Badge bg={"badge bg-label-danger"}>Past</Badge>
                            )}
                          </td>
                          <td className="d-flex flex-nowrap">
                            {new Date(item.expected_visit_datetime) >
                            new Date() ? (
                              <>
                                {" "}
                                <UpdateVisit {...item} />
                                <DeleteVisit {...item} />
                              </>
                            ) : (
                              <div className="ml-4">
                                <DeleteVisit {...item} />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-left fs-5">
                      {searchQuery == "" ? "No Record !" : "No Visitor found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {!loading && visitsData?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing {visitsData?.visits?.length} of{" "}
                  {visitsData?.totalCount}
                  &nbsp; entries
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: visitsData?.totalPages }).map(
                    (_, i) => (
                      <Pagination.Item
                        key={i}
                        active={i + 1 === activePage}
                        onClick={
                          i + 1 === activePage
                            ? ""
                            : () => handlePageSelect(i + 1)
                        }
                      >
                        {i + 1}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={activePage === visitsData?.totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ListVisitors;
