export const colorStyles = {
  control: (base, state) => ({
    ...base,
    color: "white",
    boxShadow: "none",
    minWidth: "90px",
    border: "1px solid #d8d6de",
    borderRadius: "0.357rem",
    "&:hover": {
      border: state.isFocused ? "1px solid #7367f0" : null,
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#7367f0" : "transparent",
      background:
        isFocused && !isSelected ? "rgba(115,103,240,.09)" : undefined,
      color:
        isFocused && !isSelected ? "#7367f0" : isSelected ? "white" : "black",
      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && !isSelected ? "rgba(115,103,240,.09)" : undefined,
      },
    };
  },
};

export const DataOfTime = [
  { id: 0, name: "24 Hours" },
  { id: 1, name: "12:00 AM" },
  { id: 2, name: "12:30 AM" },
  { id: 3, name: "1:00 AM" },
  { id: 4, name: "1:30 AM" },
  { id: 5, name: "2:00 AM" },
  { id: 6, name: "2:30 AM" },
  { id: 7, name: "3:00 AM" },
  { id: 8, name: "3:30 AM" },
  { id: 9, name: "4:00 AM" },
  { id: 10, name: "4:30 AM" },
  { id: 11, name: "5:00 AM" },
  { id: 12, name: "5:30 AM" },
  { id: 13, name: "6:00 AM" },
  { id: 14, name: "6:30 AM" },
  { id: 15, name: "7:00 AM" },
  { id: 16, name: "7:30 AM" },
  { id: 17, name: "8:00 AM" },
  { id: 18, name: "8:30 AM" },
  { id: 19, name: "9:00 AM" },
  { id: 20, name: "9:30 AM" },
  { id: 21, name: "10:00 AM" },
  { id: 22, name: "10:30 AM" },
  { id: 23, name: "11:00 AM" },
  { id: 24, name: "11:30 AM" },
  { id: 25, name: "12:00 PM" },
  { id: 26, name: "12:30 PM" },
  { id: 27, name: "1:00 PM" },
  { id: 28, name: "1:30 PM" },
  { id: 29, name: "2:00 PM" },
  { id: 30, name: "2:30 PM" },
  { id: 31, name: "3:00 PM" },
  { id: 32, name: "3:30 PM" },
  { id: 33, name: "4:00 PM" },
  { id: 34, name: "4:30 PM" },
  { id: 35, name: "5:00 PM" },
  { id: 36, name: "5:30 PM" },
  { id: 37, name: "6:00 PM" },
  { id: 38, name: "6:30 PM" },
  { id: 39, name: "7:00 PM" },
  { id: 40, name: "7:30 PM" },
  { id: 41, name: "8:00 PM" },
  { id: 42, name: "8:30 PM" },
  { id: 43, name: "9:00 PM" },
  { id: 44, name: "9:30 PM" },
  { id: 45, name: "10:00 PM" },
  { id: 46, name: "10:30 PM" },
  { id: 47, name: "11:00 PM" },
  { id: 48, name: "11:30 PM" },
];
