import React, { useEffect, useState } from "react";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";

import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import toast from "react-hot-toast";
import {
  useCreateAccessGroupMutation,
  useGetLocksByGroupIdQuery,
  useUpdateAccessGroupMutation,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { requiredAlert } from "../../utils/Alerts";

const AccessGroupForm = ({ isUpdate, AllLocks, id, name }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [getGroupsCall, setGroupsCall] = useState(false);
  const [show, setShow] = useState(false);

  const { isLoading: fetchingLocks, data: GroupLocks } =
    useGetLocksByGroupIdQuery(
      {
        spaceId: spaceId || currentSpaceId,
        groupId: id,
      },
      {
        skip: !getGroupsCall,
      }
    );

  useEffect(() => {
    if (show) {
      if (isUpdate && id) {
        setGroupsCall(true);
      }
    }
  }, [show, isUpdate, id]);

  const { membersPermissions } = useGlobalContext();
  const [apiSpinner, setApiSpinner] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [selectedDoors, setSelectedDoors] = useState([]);

  const [createGroup, resultStats] = useCreateAccessGroupMutation();
  const [updateGroup, resultStats1] = useUpdateAccessGroupMutation();

  useEffect(() => {
    if (show) {
      if (isUpdate) {
        setGroupName(name);
        setSelectedDoors(
          GroupLocks?.map((groupLock) => {
            const door = doorsList?.find(
              (door) => door.value === groupLock.lock.id.toString()
            );
            return (
              door || { value: groupLock.lock.id, label: groupLock.lock.name }
            );
          })
        );
      } else {
        setGroupName("");
        setSelectedDoors([]);
      }
    }
  }, [show, isUpdate, GroupLocks, name]);
  const doorsList = AllLocks?.map((lock) => ({
    value: lock.id,
    label: lock.name,
  }));

  const handleClose = () => {
    setApiSpinner(false);
    setShow(false);
    setGroupName("");
    setSelectedDoors([]);
  };

  const handleShow = () => setShow(true);

  const handleSelectChange = (selectedOptions) => {
    setSelectedDoors(selectedOptions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setApiSpinner(true);

    const groupData = {
      name: groupName,
      locks: selectedDoors?.map((door) => parseInt(door.value)), // Only include IDs
    };
    if (!isUpdate) {
      createGroup({
        spaceId: spaceId || currentSpaceId,
        data: groupData,
      });
    } else {
      updateGroup({
        spaceId: spaceId || currentSpaceId,
        groupId: id,
        data: groupData,
      });
    }
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.error);
    }

    if (resultStats?.isSuccess) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      setApiSpinner(false);

      toast.success("Access Group Added Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats1?.error?.data?.error);
    }

    if (resultStats1?.isSuccess) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      setApiSpinner(false);

      toast.success("Access Group Added Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats1]);

  const isFormValid = groupName?.trim() !== "" && selectedDoors?.length > 0;

  return (
    <>
      {isUpdate ? (
        <Button
          variant="white"
          className="mx-2 p-0"
          onClick={membersPermissions[0]?.update ? handleShow : null}
          style={{ transition: "none" }}
        >
          {" "}
          <EditIcon />
        </Button>
      ) : (
        <Button
          className="btn btn-primary mx-2 px-3"
          onClick={membersPermissions[0]?.update ? handleShow : null}
        >
          + Add Group
        </Button>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title as="h5">
            {isUpdate ? "Update" : "Add"} Access Group
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <section>
              <div>
                <Form.Group>
                  <Form.Label>
                    <h6>Group Name</h6>
                  </Form.Label>
                  <Form.Control
                    className="form-control"
                    required
                    type="text"
                    placeholder="Group Name"
                    value={groupName}
                    onChange={(e) => setGroupName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div>
                <Form.Group>
                  <Form.Label>
                    <h6>Doors</h6>
                  </Form.Label>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    options={doorsList}
                    value={selectedDoors}
                    onChange={handleSelectChange}
                  />
                </Form.Group>
              </div>
              <div className="mt-4">
                <Button
                  variant="primary"
                  disabled={!isFormValid || apiSpinner}
                  type="submit"
                >
                  {isUpdate ? "Update" : "Add"} Group
                  {apiSpinner && (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </Button>
                <Button
                  className="btn btn-secondary ml-3"
                  disabled={apiSpinner}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </section>
          </Modal.Body>
        </form>
      </Modal>
    </>
  );
};

export default AccessGroupForm;
