import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Preview } from "../Assets/Preview.svg";

import { useState } from "react";
import * as Icon from "react-bootstrap-icons";

import { useSelector } from "react-redux";
import {
  Badge,
  Button,
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Pagination,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { ReactComponent as Plus } from "../Assets/Plus.svg";
import {
  useCreateMessageMutation,
  useCreateTicketMutation,
  useDeleteMessageMutation,
  useDeleteTicketMutation,
  useDeleteTourMutation,
  useGetAllMembersQuery,
  useGetAllTicketQuery,
  useGetMessagesQuery,
  useUpdateMessageMutation,
  useUpdateTicketMutation,
} from "../Slices/spaceApi";
import { useSortableData } from "../utils/SortingFunction";
import { colorStyles } from "../utils/constant";
import { ReactComponent as DeleteIcon } from "../Assets/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../Assets/EditIcon.svg";

import Loader from "../Components/Loader/Loader";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../utils/Alerts";
import { useGetSupportCategoriesQuery } from "../Slices/constantApi";
import TicketForm from "../Components/Ticket/TicketForm";
import toast from "react-hot-toast";
import { TimeAgo } from "../utils/timeAgo";
import { useGlobalContext } from "../Context/GlobalContext";
import { ReactComponent as Lock } from "../Assets/Lock.svg";

const TourList = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const navigate = useNavigate();
  const { ticketsPermissions } = useGlobalContext();
  const { data: supportCategories, isLoading: categoriesLoading } =
    useGetSupportCategoriesQuery();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Tickets ");
  }, []);
  const { data: members, isLoading } = useGetAllMembersQuery(
    spaceId || currentSpaceId
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);

  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const optionsStatus = [
    {
      label: "All Status",
      value: "all",
    },
    {
      label: "Opened",
      value: 1,
    },
    {
      label: "Awaiting Response",
      value: 2,
    },
    {
      label: "Responded",
      value: 3,
    },
    {
      label: "On Hold",
      value: 4,
    },
    {
      label: "Closed",
      value: 5,
    },
  ];

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showFormUpdate, setShowFormUpdate] = useState(false);
  const [showMessaging, setShowMessaging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [purpose, setPurpose] = useState(false);

  const [formData, setFormData] = useState({
    mainCategory:
      supportCategories?.length > 0 ? supportCategories?.[0].id : "", // Selects the first category ID by default
    category:
      supportCategories?.length > 0 &&
      supportCategories?.[0]?.children?.length > 0
        ? supportCategories?.[0]?.children[0]?.id
        : "", // Selects the first child category ID of the first main category by default
    purpose: "",
    description: "",
    attach_photo: "",
    creator: members?.users?.[0]?.id,
  });

  useEffect(() => {
    if (members?.users?.length > 0 && !formData?.creator) {
      setFormData({
        ...formData,
        creator: members?.users?.[0]?.id,
      });
    }
  }, [members?.users]); // Update formData when categories change

  useEffect(() => {
    if (supportCategories?.length > 0 && !formData?.mainCategory) {
      setFormData({
        ...formData,
        mainCategory: supportCategories?.[0]?.id,
        category:
          supportCategories?.[0]?.children.length > 0
            ? supportCategories?.[0]?.children[0].id
            : "",
      });
    }
  }, [supportCategories]); // Update formData when categories change
  const [status, setStatus] = useState({
    label: "Status",
    value: "all",
  });
  const { data: tickets, isLoading: fetchingData } = useGetAllTicketQuery({
    spaceId: spaceId || currentSpaceId,
    page: activePage,
    page_size: itemsPerPage.value,
    search: searchQuery,
    status: status.value,
  });

  useEffect(() => {
    setLoading(false);
  }, [tickets]);

  const { items, requestSort, sortConfig } = useSortableData(
    tickets?.tickets || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);
    setLoading(true);
  };

  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];

  const handleStatus = (e) => {
    setStatus(e);
    setLoading(true);
  };

  const [deleteTicket, resultStats] = useDeleteTicketMutation();
  const [createTicketByAPi, resultStats1] = useCreateTicketMutation();
  const [updateTicketByAPi, resultStats2] = useUpdateTicketMutation();

  const [createMessageByApi, resultStats3] = useCreateMessageMutation();
  const [deleteMessageByApi, resultStats4] = useDeleteMessageMutation();
  const [updateMessageByApi, resultStats5] = useUpdateMessageMutation();

  const [viewTicket, setViewTicket] = useState();
  const [selectedComment, setSelectedComment] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [message, setMessage] = useState("");

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (e.key === "Enter") {
      createMessage(); // Call createMessage function when Enter key is pressed
    }
  };

  const [editMessage, setEditMessage] = useState("");

  const handleEditMessageChange = (e) => {
    setEditMessage(e.target.value);
    if (e.key === "Enter") {
      updateComment(); // Call createMessage function when Enter key is pressed
    }
  };

  const { data: messagesData } = useGetMessagesQuery(
    {
      spaceId: spaceId || currentSpaceId,
      ticketId: ticketId?.id,
    },
    {
      skip: !ticketId?.id,
      refetchOnMountOrArgChange: true,
      query: {
        ticketId: ticketId?.id,
      },
    }
  );

  const handleDeleteTicket = (item) => {
    confirmationAlert("Do You Want to Delete Ticket?").then((willDelete) => {
      if (willDelete) {
        deleteTicket({
          spaceId: spaceId || currentSpaceId,
          ticketId: item.id,
        });
      }
    });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Ticket Deleted Successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats1?.isSuccess) {
      // setTimeout(() => {
      handleCloseForm();
      // }, 1500);
      setApiSpinner(false);

      toast.success("Ticket Added SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats1]);
  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      setLoading(true);
      setSearchQuery(search);
      // setActivePage(1);
    };
    clearTimeout(timer);
    if (search.length > 0) {
      timer = setTimeout(debounceSearch, 1000);
    } else {
      setSearchQuery("");
      // setActivePage(1);
      setLoading(false);
    }
    return () => clearTimeout(timer);
  }, [search]);
  useEffect(() => {
    if (resultStats2?.isError) {
      setApiSpinner(false);
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats2?.isSuccess) {
      // setTimeout(() => {
      handleCloseFormUpdate();
      // }, 1500);
      setApiSpinner(false);

      toast.success("Ticket Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats2]);

  useEffect(() => {
    if (resultStats3?.isError) {
      setApiSpinner(false);
      const errorMessage = Object?.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }

    if (resultStats3?.isSuccess) {
      // setTimeout(() => {
      // handleCloseForm();
      // }, 1500);
      setApiSpinner(false);

      toast.success("Comment Added SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setMessage("");
    }
  }, [resultStats3]);

  useEffect(() => {
    if (resultStats4?.isSuccess) {
      userDeleteAlert("Comment Deleted Successfully.");
    }
    if (resultStats4?.isError) {
      requiredAlert(resultStats4?.error?.data?.message);
    }
  }, [resultStats4]);

  useEffect(() => {
    if (resultStats5?.isError) {
      setApiSpinner(false);
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats5?.isSuccess) {
      // setTimeout(() => {
      //   handleCloseFormUpdate();
      // }, 1500);
      setApiSpinner(false);

      toast.success("Comment Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setEditMessage("");
      setSelectedComment();
    }
  }, [resultStats5]);

  function getCategoryNameById(id) {
    for (const category of supportCategories) {
      if (category?.id === id) {
        return category?.name;
      }
      for (const childCategory of category?.children) {
        if (childCategory?.id === id) {
          return childCategory?.name;
        }
      }
    }
    return "Category not found"; // Handle the case where the ID is not found
  }

  const findMainCategoryByCategory = (selectedCategory) => {
    const foundMainCategory = supportCategories?.find((mainCategory) => {
      return mainCategory?.children?.some(
        (childCategory) => childCategory?.id == selectedCategory
      );
    });

    return foundMainCategory?.id || null;
  };

  const handleCreateTicket = () => {
    setViewTicket();
    setFormData({
      mainCategory:
        supportCategories?.length > 0 ? supportCategories?.[0].id : "",
      category:
        supportCategories?.length > 0 &&
        supportCategories?.[0].children.length > 0
          ? supportCategories?.[0].children[0].id
          : "",
      purpose: "",
      description: "",
      attach_photo: "",
      creator: members?.users?.[0]?.id,
    });
    setShowForm(true);
  };

  const handleEditComment = (item) => {
    setSelectedComment(item?.id);
    setEditMessage(item?.message);
  };

  const updateComment = (item) => {
    setApiSpinner(true);
    updateMessageByApi({
      spaceId: spaceId || currentSpaceId,
      ticketId: ticketId.id,
      messageId: selectedComment,
      data: { message: editMessage },
    });
  };

  const handleDeleteComment = (id) => {
    confirmationAlert("Do You Want to Delete Comment?").then((willDelete) => {
      if (willDelete) {
        deleteMessageByApi({
          spaceId: spaceId || currentSpaceId,
          ticketId: ticketId?.id,
          messageId: id,
        });
      }
    });
  };

  const handleView = (item) => {
    setShow(true);
    setViewTicket(item);
  };

  const handleUpdate = ({
    category,
    purpose,
    description,
    attach_photo,
    creator: { id },
    id: ticketId,
  }) => {
    setFormData({
      mainCategory: findMainCategoryByCategory(category),
      category,
      purpose,
      description,
      attach_photo,
      creator: id,
    });

    setShowFormUpdate(true);
    setViewTicket({ id: ticketId });
  };

  const handleMessaging = (item) => {
    setViewTicket(item);

    setTicketId({ id: item.id, creator: item.creator });

    setShowMessaging(true);
  };

  const handleClose = () => {
    setShow(false);
    setPurpose(false);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setPurpose(false);
  };

  const handleCloseFormUpdate = () => {
    setShowFormUpdate(false);
    setPurpose(false);
  };

  const handleCloseMessaging = () => {
    setShowMessaging(false);
  };

  const createMessage = () => {
    setApiSpinner(true);
    createMessageByApi({
      spaceId: spaceId || currentSpaceId,
      ticketId: ticketId?.id,
      data: { message },
    });
  };

  const createTicket = () => {
    if (!formData.purpose) {
      setPurpose(true);
      return;
    }
    const { mainCategory, ...formDataWithoutMainCategory } = formData;
    setApiSpinner(true);
    createTicketByAPi({
      spaceId: spaceId || currentSpaceId,
      data: formDataWithoutMainCategory,
    });
  };

  const updateTicket = () => {
    if (!formData.purpose) {
      setPurpose(true);
      return;
    }
    const { mainCategory, ...formDataWithoutMainCategory } = formData;

    let dataWithoutImage;

    if (formData?.attach_photo.startsWith("https")) {
      // Create a copy of formData excluding the "image" key
      dataWithoutImage = formDataWithoutMainCategory;
      delete dataWithoutImage.attach_photo;
    } else {
      // If image does not start with "https", use formData as is
      dataWithoutImage = formDataWithoutMainCategory;
    }
    setApiSpinner(true);
    updateTicketByAPi({
      spaceId: spaceId || currentSpaceId,
      data: dataWithoutImage,
      ticketId: viewTicket.id,
    });
  };

  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}, ${formattedTime}`;
  }

  const handleStatusAction = (item, value) => {
    updateTicketByAPi({
      spaceId: spaceId || currentSpaceId,
      data: { status: value },
      ticketId: item.id,
    });
  };

  if (fetchingData || categoriesLoading) {
    return <Loader height={6.6} />;
  }

  return (
    <>
      <section
        className="container-fluid invoice-list-wrapper with-bottom-padding"
        style={{ padding: "0px 16px" }}
      >
        <div className="card">
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-between ">
              <div className="col-12  col-md-4 col-lg-6">
                <input
                  type="search"
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    // setLoading(true);
                  }}
                  placeholder="Search Tickets"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
                <Button
                  onClick={
                    ticketsPermissions[0]?.create ? handleCreateTicket : null
                  }
                  className="btn btn-primary"
                  tabIndex="0"
                  aria-controls="DataTables_Table_0"
                  type="button"
                >
                  {ticketsPermissions[0]?.create ? (
                    <span className="invoice">
                      <Plus /> Add Ticket
                    </span>
                  ) : (
                    <span className="invoice">
                      <Plus /> Add Ticket
                      <Lock />
                    </span>
                  )}
                </Button>
              </div>
            </div>
          </div>

          <hr />
          <div className="col-12 d-flex flex-column flex-md-row justify-content-between ">
            <div className="col-12 col-md-6 my-3 my-lg-0 col-lg-4 d-flex">
              <span className="mt-2">Filter By &nbsp;</span>
              <Select
                options={optionsStatus}
                defaultValue={{
                  label: "All Status",
                  value: "all",
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handleStatus}
                value={status}
                className="col-12 col-md-4"
                // placeholder={"Select Status"}
                styles={colorStyles}
              />
            </div>
            <div className="col-12 col-md-2 mb-3 ">
              <div className="">
                <div className="col-12 d-flex justify-content-end ">
                  <label
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      fontWeight: "normal",
                    }}
                  >
                    <small className="mr-2 mt-2 title"> Show</small>
                    <Select
                      options={options}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={handlePage}
                      value={itemsPerPage}
                      id="pages-select"
                      styles={colorStyles}
                    />
                    <small className="ml-2 mt-2"> entries</small>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Id</div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Creator</div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Category</div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Purpose</div>
                    </div>
                  </th>
                  {/* <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Submit Date</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("submit_date")}
                          className={getClassNamesFor("submit_date")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("submit_date")}
                          className={getClassNamesFor("submit_date")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th> */}
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Last Activity</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("replies:created_at")}
                          className={getClassNamesFor("replies:created_at")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("replies:created_at")}
                          className={getClassNamesFor("replies:created_at")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8} className="fs-5">
                      <Loader />
                    </td>
                  </tr>
                )}
                {!loading &&
                  (items?.length > 0 ? (
                    items?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.id}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            className="table-avatar"
                            onClick={() => navigate(`/ticket/${item.id}`)}
                            // onClick={() => handleMessaging(item)}
                          >
                            <b>{item?.creator?.name}</b>
                          </td>
                          <td>{getCategoryNameById(item?.category)}</td>
                          <td>
                            {item?.purpose
                              ? item.purpose.length > 25
                                ? item.purpose.substring(0, 25) + "..."
                                : item.purpose
                              : "----"}
                          </td>

                          {/* <td>{formatDateTime(item?.submit_date)}</td> */}
                          <td>
                            {item.replies.length > 0
                              ? formatDateTime(
                                  item.replies.reduce((prev, current) =>
                                    current.created_at > prev.created_at
                                      ? current
                                      : prev
                                  ).created_at
                                )
                              : formatDateTime(item?.submit_date)}
                          </td>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                // variant={getVariant().toLowerCase()}
                                // variant="badge bg-label-success"
                                variant={
                                  item.status == 1
                                    ? "badge bg-label-success"
                                    : item.status == 2
                                    ? "badge bg-label-warning"
                                    : item.status == 3
                                    ? "badge bg-label-info"
                                    : item.status == 4
                                    ? "badge bg-label-secondary"
                                    : "badge bg-label-primary"
                                }
                                id="dropdown-basic"
                              >
                                {item.status == 1
                                  ? "Open"
                                  : item.status == 2
                                  ? "Waiting for Response"
                                  : item.status == 3
                                  ? "Answered"
                                  : item.status == 4
                                  ? "On Hold"
                                  : "Closed"}
                                <Icon.CaretDownFill className="ml-1" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleStatusAction(item, 1)}
                                >
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleStatusAction(item, 2)}
                                >
                                  Waiting for Response
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleStatusAction(item, 3)}
                                >
                                  Answered
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleStatusAction(item, 4)}
                                >
                                  On Hold
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleStatusAction(item, 5)}
                                >
                                  Close
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* {
                              <Badge
                                bg={
                                  item.status == 1
                                    ? "badge bg-label-success"
                                    : item.status == 2
                                    ? "badge bg-label-warning"
                                    : item.status == 3
                                    ? "badge bg-label-info"
                                    : item.status == 4
                                    ? "badge bg-label-secondary"
                                    : "badge bg-label-primary"
                                }
                              >
                                {item.status == 1
                                  ? "Open"
                                  : item.status == 2
                                  ? "Waiting for Response"
                                  : item.status == 3
                                  ? "Answered"
                                  : item.status == 4
                                  ? "On Hold"
                                  : "Closed"}
                              </Badge>
                            } */}
                          </td>
                          <td className="d-flex flex-nowrap">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  {" "}
                                  {ticketsPermissions[0]?.read ? (
                                    "View"
                                  ) : (
                                    <span>
                                      View <Lock />
                                    </span>
                                  )}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="white"
                                className="p-0"
                                onClick={() =>
                                  ticketsPermissions[0]?.read
                                    ? navigate(`/ticket/${item.id}`)
                                    : null
                                }
                                // onClick={() =>
                                //   ticketsPermissions[0]?.read
                                //     ? handleView(item)
                                //     : null
                                // }
                              >
                                <Preview />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  {ticketsPermissions[0]?.update ? (
                                    "Update"
                                  ) : (
                                    <span>
                                      Update <Lock />
                                    </span>
                                  )}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="white"
                                className="p-0 ml-1"
                                onClick={() =>
                                  ticketsPermissions[0]?.update
                                    ? handleUpdate(item)
                                    : null
                                }
                              >
                                <EditIcon />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  {" "}
                                  {ticketsPermissions[0]?.delete ? (
                                    "Delete"
                                  ) : (
                                    <span>
                                      Delete <Lock />
                                    </span>
                                  )}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="white"
                                className="p-0 ml-1"
                                onClick={() =>
                                  ticketsPermissions[0]?.delete
                                    ? handleDeleteTicket(item)
                                    : null
                                }
                              >
                                <DeleteIcon />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-left fs-5">
                        {searchQuery == "" ? "No Record !" : "No Ticket Found"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {!loading && tickets?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing {tickets?.tickets?.length} of {tickets?.totalCount}
                  &nbsp; entries
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: tickets?.totalPages }).map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i + 1 === activePage}
                      onClick={() => handlePageSelect(i + 1)}
                      disabled={i + 1 === activePage}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={activePage === tickets?.totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      </section>

      {viewTicket?.id && (
        <Modal
          show={show}
          onHide={handleClose}
          id="packagePopUp"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="" closeButton>
            <Modal.Title as="h5">View Ticket</Modal.Title>
          </Modal.Header>
          <form>
            <Modal.Body>
              {viewTicket?.attach_photo && (
                <div className="row">
                  <div className="col-md-12 form-group">
                    <h5>
                      Attach Photo:
                      <img
                        src={viewTicket?.attach_photo}
                        width={"100%"}
                        alt={viewTicket?.creator?.name}
                      />
                    </h5>
                  </div>
                </div>
              )}

              {viewTicket?.category && (
                <div className="row">
                  <div className="col-md-12 form-group">
                    <h5>
                      Category:
                      <span className="text-muted">
                        {getCategoryNameById(viewTicket?.category)}
                      </span>
                    </h5>
                  </div>
                </div>
              )}

              {viewTicket?.creator?.name && (
                <div className="row">
                  <div className="col-md-12 form-group">
                    <h5>
                      Name:{" "}
                      <span className="text-muted">
                        {viewTicket?.creator?.name}
                      </span>
                    </h5>
                  </div>
                </div>
              )}

              {viewTicket?.purpose && (
                <div className="row">
                  <div className="col-md-12 form-group">
                    <h5>
                      Purpose:{" "}
                      <span className="text-muted">{viewTicket?.purpose}</span>
                    </h5>
                  </div>
                </div>
              )}

              {viewTicket?.description && (
                <div className="row">
                  <div className="col-md-12 form-group">
                    <h5>
                      Description:{" "}
                      <span className="text-muted">
                        {viewTicket?.description}
                      </span>
                    </h5>
                  </div>
                </div>
              )}
            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer> */}
          </form>
        </Modal>
      )}

      <Modal
        show={showForm}
        onHide={handleCloseForm}
        id="packagePopUp"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="" closeButton>
          <Modal.Title as="h5">Create Ticket</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <TicketForm
              formData={formData}
              setFormData={setFormData}
              categories={supportCategories}
              members={members?.users}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              purpose={purpose}
              setPurpose={setPurpose}
            />
            <div className="mt-4">
              <Button
                variant="primary"
                disabled={
                  apiSpinner ||
                  isUploading ||
                  !formData?.creator ||
                  !formData?.category ||
                  !formData?.mainCategory ||
                  !formData?.purpose
                }
                onClick={createTicket}
              >
                Create Ticket{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                disabled={apiSpinner}
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseForm();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
           

          
          </Modal.Footer> */}
        </form>
      </Modal>

      {viewTicket?.id && (
        <Modal
          show={showFormUpdate}
          onHide={handleCloseFormUpdate}
          id="packagePopUp"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="" closeButton>
            <Modal.Title as="h5">Update Ticket</Modal.Title>
          </Modal.Header>
          <form>
            <Modal.Body>
              <TicketForm
                formData={formData}
                setFormData={setFormData}
                categories={supportCategories}
                members={members?.users}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                purpose={purpose}
                setPurpose={setPurpose}
              />
              <div className="mt-4">
                <Button
                  variant="primary"
                  disabled={isUploading || apiSpinner}
                  onClick={updateTicket}
                >
                  Save Changes{" "}
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                <button
                  className="btn btn-secondary ml-3"
                  disabled={apiSpinner}
                  onClick={(e) => {
                    e.preventDefault();
                    handleCloseFormUpdate();
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </form>
        </Modal>
      )}

      {viewTicket?.id && (
        <Modal
          show={showMessaging}
          onHide={handleCloseMessaging}
          id="packagePopUp"
        >
          <Modal.Header className="bg-light" closeButton>
            <Modal.Title as="h5">Comments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {messagesData?.messages?.map((item) => {
              const isOwnerPresent = item.owner !== null;
              return (
                <>
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: isOwnerPresent
                        ? "rgb(238 238 244)"
                        : "rgb(217 217 238)",
                      padding: "10px",
                      borderRadius: "8px",
                      marginTop: "10px",
                      position: "relative",
                    }}
                  >
                    <h5 style={{ marginBottom: "5px" }}>
                      {isOwnerPresent
                        ? item.owner.name
                        : ticketId?.creator.name}
                    </h5>

                    <div style={{ fontSize: "14px", marginBottom: "4px" }}>
                      {item.message}
                    </div>

                    <div
                      style={{
                        fontSize: "12px",
                        color: "#7367f0",
                        fontWeight: "bold",
                      }}
                    >
                      <TimeAgo date={item.created_at} />
                    </div>

                    {item?.owner?.id == (spaceId || currentSpaceId) && (
                      <Dropdown
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "0",
                          transform: "translateY(-50%)",
                          zIndex: 1000, // Set a higher z-index value
                        }}
                      >
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                          <Icon.ThreeDotsVertical
                            style={{ cursor: "pointer" }}
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleEditComment(item)}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleDeleteComment(item.id)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                  {selectedComment == item.id && (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <Form.Control
                        type="text"
                        placeholder="Enter your edit message..."
                        value={editMessage}
                        onChange={handleEditMessageChange}
                        onKeyDown={handleEditMessageChange} // Listen for Enter key press
                      />
                      <div
                        className=""
                        style={{ position: "absolute", right: 0, top: 0 }}
                      >
                        <Button
                          variant="light"
                          onClick={updateComment}
                          disabled={!editMessage || apiSpinner}
                          className="p-1"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="31"
                            height="31"
                            viewBox="0 0 48 48"
                          >
                            <path d="M 24 3 C 12.413858 3 3 12.413866 3 24 C 3 35.586134 12.413858 45 24 45 C 35.586142 45 45 35.586134 45 24 C 45 12.413866 35.586142 3 24 3 z M 24 5 C 34.505263 5 43 13.494744 43 24 C 43 34.505256 34.505263 43 24 43 C 13.494737 43 5 34.505256 5 24 C 5 13.494744 13.494737 5 24 5 z M 33.951172 15.990234 A 1.0001 1.0001 0 0 0 33.208984 16.388672 L 22.244141 30.568359 L 14.640625 24.232422 A 1.0001 1.0001 0 1 0 13.359375 25.767578 L 21.759766 32.767578 A 1.0001 1.0001 0 0 0 23.191406 32.611328 L 34.791016 17.611328 A 1.0001 1.0001 0 0 0 33.951172 15.990234 z"></path>
                          </svg>
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </Modal.Body>
          <Modal.Footer>
            <div style={{ position: "relative", width: "100%" }}>
              <Form.Control
                type="text"
                placeholder="Enter your message..."
                value={message}
                onChange={handleMessageChange}
                onKeyDown={handleMessageChange} // Listen for Enter key press
              />
              <div
                className=""
                style={{ position: "absolute", right: 0, top: 0 }}
              >
                <Button
                  variant="light"
                  onClick={createMessage}
                  disabled={!message || apiSpinner}
                  className="p-1"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="31"
                    height="31"
                    viewBox="0 0 48 48"
                    // stroke="blue"
                  >
                    <path d="M 24 3 C 12.413858 3 3 12.413866 3 24 C 3 35.586134 12.413858 45 24 45 C 35.586142 45 45 35.586134 45 24 C 45 12.413866 35.586142 3 24 3 z M 24 5 C 34.505263 5 43 13.494744 43 24 C 43 34.505256 34.505263 43 24 43 C 13.494737 43 5 34.505256 5 24 C 5 13.494744 13.494737 5 24 5 z M 33.951172 15.990234 A 1.0001 1.0001 0 0 0 33.208984 16.388672 L 22.244141 30.568359 L 14.640625 24.232422 A 1.0001 1.0001 0 1 0 13.359375 25.767578 L 21.759766 32.767578 A 1.0001 1.0001 0 0 0 23.191406 32.611328 L 34.791016 17.611328 A 1.0001 1.0001 0 0 0 33.951172 15.990234 z"></path>
                  </svg>
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default TourList;
