import React from "react";

import { Lock } from "../../Assets/MemberSvgs/Icon";
import * as Icon from "react-bootstrap-icons";

import { useSelector } from "react-redux";

import { useGlobalContext } from "../../Context/GlobalContext";
import PermissionPage from "../MainShared/PermissionPage";
import { Badge, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { useSortableData } from "../../utils/SortingFunction";

import DashboardBookingModal from "../Dashboard/DashboardBookingModal";

import {
  ConfirmationAlert,
  NewconfirmationAlert,
  bookingConfirmationAlert,
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import {
  useDeleteMeetingBookingMutation,
  useGetAllCompaniesQuery,
  useGetAllUpComingBookingQuery,
  useUpdateMeetingBookingMutation,
} from "../../Slices/spaceApi";
import { useEffect } from "react";
import Loader from "../Loader/Loader";
import AddHotDeskBooking from "./AddHotDeskBooking";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
// import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
// import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
// import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { formatDate } from "@fullcalendar/core";
import toast from "react-hot-toast";

function BookingTableView({
  companies,
  meetingData,
  FetchUpComingBooking,
  Users,
}) {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { InvoicesPermissions, bookingsPermissions, setInvoiceStatus } =
    useGlobalContext();

  const [status, setStatus] = useState("all");

  // const { isLoading: bookingLoading, data: FetchUpComingBooking } =
  //   useGetAllUpComingBookingQuery({
  //     spaceId: spaceId || currentSpaceId,
  //     past: "False",
  //     companyId: status,
  //     type: 2,
  //   });

  const { data: FetchCompanies, isLoading: CompaniesLoading } =
    useGetAllCompaniesQuery({
      spaceId: spaceId || currentSpaceId,
    });

  const companiesArray = Array.isArray(FetchCompanies?.users)
    ? FetchCompanies?.users
    : [FetchCompanies];

  const { items, requestSort, sortConfig } = useSortableData(
    FetchUpComingBooking?.bookingss || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const [selectedTime, setSelectedTime] = useState([]);
  const [bookingDate, setBookingDate] = useState(getCurrentDate());
  const [showModal, setShowModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  // const handleBookingButtonClick = () => {
  //   setShowBookingModal(true);
  // };

  const handleCloseBookingModal = () => {
    setShowBookingModal(false);
  };
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [dataFinalized, setDataFinalized] = useState(false);

  function timeStampToTime(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);
    date = date.toLocaleTimeString();
    let split = date.split(":");
    let set = split[2].split(" ");
    let convert = split[0] + ":" + split[1] + " " + set[1];
    return convert;
  }
  function timeStampToDate(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formatter = new Intl.DateTimeFormat("en-GB", options);

    let formattedDate = formatter.format(date);

    return formattedDate;
  }

  const navigate = useNavigate();
  const [updateBooking, resultStats2] = useUpdateMeetingBookingMutation();
  const [deleteMeetingBooking, resultStats] = useDeleteMeetingBookingMutation();

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
  };
  const optionsStatus = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Teams",
      value: 0,
    },
    {
      label: "Individuals",
      value: 0,
    },
  ];

  const handleStatus = (e) => {
    setStatus(e.id);
    // setLoading(true);
  };

  const handleApproveBooking = (id) => {
    bookingConfirmationAlert("Do You Want to Approve This Booking?").then(
      (approve) => {
        if (approve) {
          updateBooking({
            spaceId: spaceId || currentSpaceId,
            id: id,
            updateData: { approved: 1 },
          });
        } else if (approve == false) {
          updateBooking({
            spaceId: spaceId || currentSpaceId,
            id: id,
            updateData: { approved: 2 },
          });
        }
      }
    );
  };

  const handleDeleteBooking = (id) => {
    NewconfirmationAlert(
      "Want to Cancel This Booking?",
      "",
      "Yes, cancel it!",
      "No, Don't Cancel"
    ).then((willDelete) => {
      if (willDelete) {
        deleteMeetingBooking({
          spaceId: spaceId || currentSpaceId,
          id: id?.id,
        });
      }
    });
  };

  // useEffect(() => {
  //   if (resultStats?.isSuccess) {
  //     userDeleteAlert("Booking Cancelled successfully.");
  //   }
  //   if (resultStats?.isError) {
  //     requiredAlert(resultStats?.error?.data?.message);
  //   }
  // }, [resultStats]);
  useEffect(() => {
    if (resultStats2?.isSuccess) {
      userDeleteAlert("Booking updated successfully.");
    }
    if (resultStats2?.isError) {
      requiredAlert(resultStats2?.error?.data?.message);
    }
  }, [resultStats2]);

  const { openDays } = useGlobalContext();
  // const [deleteMeetingBooking, resultStats] = useDeleteMeetingBookingMutation();
  // const [updateBooking, resultStats2] = useUpdateMeetingBookingMutation();
  // const [bookingSlots, resultStats1] = useBookingSlotsMutation();
  const [meetingSlots, setMeetingSlots] = useState([]);
  const [finalSlots, setFinalSlots] = useState([]);
  const [events, setEvents] = useState(null);
  // const [selectedBooking, setSelectedBooking] = useState(null);
  const [updateBookingButton, setUpdateBookingButton] = useState(false);
  const [preSlots, setPreSlots] = useState([]);
  // const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [member, setMember] = useState(null);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [filteredResource, setFilteredResource] = useState(
    meetingData?.filter((item) => item.spaceType !== 19 && item.bookable)
  );
  const [calendarResource, setCalendarResource] = useState(filteredResource);

  // const [showBookingModal, setShowBookingModal] = useState(false);
  const [view, setView] = useState("dayGridMonth");
  const [meetingRoomId, setMeetingRoomId] = useState(
    FetchUpComingBooking?.[0]?.meetingRoom?.id
  );
  useEffect(() => {
    if (meetingData?.length > 0) {
      setFilteredResource(
        meetingData?.filter((item) => item.spaceType !== 19 && item.bookable)
      );
    }
  }, [meetingData]);

  useEffect(() => {
    const extractedResources = filteredResource?.map((resource) => ({
      id: resource.id,
      title: resource.title,
    }));

    // Set the extracted resources to the calendarResource state
    setCalendarResource(extractedResources);
  }, [filteredResource]);
  // const handleApproveBooking = (id, status) => {
  //   if (status !== 1) {
  //     bookingConfirmationAlert("Do You Want to Approve This Booking?").then(
  //       (approve) => {
  //         if (approve) {
  //           updateBooking({
  //             spaceId: spaceId || currentSpaceId,
  //             id: id,
  //             updateData: { approved: 1 },
  //           });
  //         } else {
  //           updateBooking({
  //             spaceId: spaceId || currentSpaceId,
  //             id: id,
  //             updateData: { approved: 2 },
  //           });
  //         }
  //       }
  //     );
  //   } else {
  //     NewconfirmationAlert(
  //       "Do You Want to Un-Approve This Booking?",
  //       "",
  //       "Yes",
  //       "No"
  //     ).then((approve) => {
  //       if (approve) {
  //         updateBooking({
  //           spaceId: spaceId || currentSpaceId,
  //           id: id,
  //           updateData: { approved: 2 },
  //         });
  //       }
  //     });
  //   }

  //   //  bookingConfirmationAlert("Do You Want to Approve This Booking?").then(
  //   //         (approve) => {
  //   //           if (approve) {
  //   //             updateBooking({
  //   //               spaceId: spaceId || currentSpaceId,
  //   //               id: id,
  //   //               updateData: { approved: 1 },
  //   //             });
  //   //           } else if (approve == false) {
  //   //             updateBooking({
  //   //               spaceId: spaceId || currentSpaceId,
  //   //               id: id,
  //   //               updateData: { approved: 2 },
  //   //             });
  //   //           }
  //   //         }
  //   //       );
  // };

  const handleMeetingRoomId = (e) => {
    setMeetingRoomId(e.id);
    // getBookingSlots(date, e.id);
  };
  useEffect(() => {
    const fetchEvents = async () => {
      const formattedEvents = FetchUpComingBooking?.bookingss?.map((event) => ({
        id: event?.id,
        title: event.meetingRoom?.title,
        start: new Date(event?.startTime * 1000),
        end: new Date(event?.endTime * 1000),
        capacity: event?.capacity,
        paidByCard: event.paidByCard,
        price: event?.meetingRoom?.price,
        member: event?.member?.id,
        userName: event?.member?.name,
        meetingRoom: event?.meetingRoom?.id,
        resourceId: event?.meetingRoom?.id,

        cancel: event?.cancel,
        approved: event?.approved,
      }));

      setEvents(formattedEvents || []);
    };
    fetchEvents();
  }, [FetchUpComingBooking]);

  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  const eventContent = (eventInfo) => {
    const start = formatDate(eventInfo.event.start, {
      hour: "numeric",
      minute: "2-digit",
      omitZeroMinute: true,
    });
    const end = formatDate(eventInfo?.event?.end, {
      hour: "numeric",
      minute: "2-digit",
      omitZeroMinute: true,
    });
    const timeRange = `${start} - ${end}`;

    return (
      <div className="fc-event-dot event-container">
        {/* <div className="fc-event-title event-title">
          Total Desks: {meetingData?.length}
        </div> */}
        <div className="fc-event-title event-title">
          {eventInfo?.event?.extendedProps?.userName}({eventInfo?.event?.title})
        </div>
        {/* <div className="fc-event-title event-title">
          
        </div>
        <div className="fc-event-time event-time">{timeRange}</div> */}
        <div className="">
          <Badge
            onClick={
              eventInfo.event.extendedProps.approved == 3
                ? (e) => {
                    e.stopPropagation();
                    handleApproveBooking(
                      eventInfo.event.id,
                      eventInfo.event.extendedProps.approved
                    );
                  }
                : null
            }
            bg={
              eventInfo.event.extendedProps.approved == 1
                ? "badge bg-label-info"
                : eventInfo.event.extendedProps.approved == 2
                ? "badge bg-label-warning"
                : "badge bg-label-primary"
            }
            style={{ maxWidth: "100px" }}
          >
            {eventInfo.event.extendedProps.approved == 1
              ? "Approved"
              : eventInfo.event.extendedProps.approved == 2
              ? "Not Approved"
              : "Pending"}
          </Badge>
        </div>
      </div>
    );
  };

  const handleBookingButtonClick = (event) => {
    setCalenderSelect(false);
    setShowBookingModal(true);
  };

  const customButtons = {
    bookingButton: bookingsPermissions[0]?.create
      ? {
          text: "+ Add Booking",
          click: handleBookingButtonClick,
        }
      : {
          text: "Add Booking(Locked)",
          click: null,
        },
  };

  // const handleCloseBookingModal = () => {
  //   setShowBookingModal(false);
  // };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      setApiSpinner(false);
      setSelectedBooking(null);
      userDeleteAlert("Booking Cancelled Successfully.");
    }
    if (resultStats?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);

  const handleEventClick = (event) => {
    const startDate = new Date(event.event.start);
    const endDate = new Date(event?.event?.end);

    const utcStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 16);
    const utcEndDate = new Date(
      endDate.getTime() - endDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .slice(0, 16);

    setSelectedBooking({
      start: utcStartDate,
      end: utcEndDate,
      id: event.event.id,
      title: event.event.title,
      capacity: event.event.extendedProps.capacity,
      price: event.event.extendedProps.price,
      paidByCard: event.event.extendedProps.paidByCard,
      // companyId: event.event.extendedProps.companyId,
      member: event.event.extendedProps.member,

      userName: event.event.extendedProps.userName,
      meetingRoom: event.event.extendedProps.meetingRoom,
    });
  };
  useEffect(() => {
    setMeetingRoomId(selectedBooking?.meetingRoom);
  }, [selectedBooking]);

  const [date, setDate] = useState(getCurrentDate);
  const [dateCheck, setDateCheck] = useState();
  useEffect(() => {
    const date = selectedBooking?.start?.split("T")[0];
    setDate(date);
    setDateCheck(date);
    const startTime = selectedBooking?.start?.split("T")[1].slice(0, 5); // Get HH:mm
    const endTime = selectedBooking?.end?.split("T")[1].slice(0, 5); // Get HH:mm

    const startDate = new Date(date + "T" + startTime);
    const endDate = new Date(date + "T" + endTime);

    const timeSlots = [];

    while (startDate < endDate) {
      const currentStartTime = startDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      startDate.setMinutes(startDate.getMinutes() + 30);

      const currentEndTime = startDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      timeSlots.push({ start: currentStartTime, end: currentEndTime });
    }

    setPreSlots(timeSlots);
  }, [selectedBooking]);

  const handleEditBooking = (event) => {
    setUpdateBookingButton(true);
  };

  // const handleDeleteBooking = (booking) => {
  //   confirmationAlert("Want to Delete This Booking?").then((willDelete) => {
  //     if (willDelete) {
  //       setApiSpinner(true);

  //       deleteMeetingBooking({
  //         spaceId: spaceId || currentSpaceId,
  //         id: booking.id,
  //       });
  //       setSelectedBooking(null);
  //     }
  //   });
  // };
  useEffect(() => {
    if (resultStats2?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats2?.error?.data?.message);
    }

    if (resultStats2?.isSuccess) {
      setApiSpinner(false);

      toast.success("Booking updated successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setUpdateBookingButton(false);
      setSelectedBooking(null);
    }
  }, [resultStats2]);

  const [minDatetime, setMinDatetime] = useState("");

  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, "0");
    const day = `${now.getDate()}`.padStart(2, "0");
    const hours = `${now.getHours()}`.padStart(2, "0");
    const minutes = `${Math.floor(now.getMinutes() / 30) * 30}`.padStart(
      2,
      "0"
    );
    const minDatetimeValue = `${year}-${month}-${day}T${hours}:${minutes}`;
    setMinDatetime(minDatetimeValue);
  }, []);

  function handleEventDropResize(event) {
    const updatedEvents = events?.map((e) => {
      if (e.id === event.event.id) {
        return {
          ...e,
          startTime: toTimestamp(event.event.start),
          endTime: toTimestamp(event.event.end),
        };
      }
      return e;
    });

    setEvents(updatedEvents);

    const id = event.event.id;
    const updateData = {
      startTime: toTimestamp(event.event.start),
      endTime: toTimestamp(event.event.end),
      paidByCard: event.event.extendedProps.paidByCard,
      capacity: event.event.extendedProps.capacity,
      meetingRoom: meetingRoomId,
      member: event.member,
    };

    // updateBooking({ spaceId: spaceId || currentSpaceId, id, updateData });
  }

  const handle = (e) => {
    const { name, value } = e.target;

    setSelectedBooking((prevBooking) => ({
      ...prevBooking,

      [name]: value,
    }));
  };

  function roundToNearest30Minutes(date) {
    // convert UTC time to local time
    const localTime = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );

    const minutes = localTime.getMinutes();
    if (minutes <= 29) {
      localTime.setMinutes(0);
    } else {
      localTime.setMinutes(30);
    }
    return localTime.toISOString().slice(0, 16);
  }

  function handleTime(e) {
    const name = e.target.name;
    const value = e.target.value;
    const year = value.slice(0, 4);
    const month = value.slice(5, 7);
    const day = value.slice(8, 10);
    const hour = value.slice(11, 13);
    const minute = value.slice(14, 16);
    const date = new Date(year, month - 1, day, hour, minute);
    setSelectedBooking((prev) => ({
      ...prev,
      [name]: roundToNearest30Minutes(date),
    }));
  }
  /////////////////////////

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const [preSlotsIndexes, setPreSlotsIndexes] = useState([]);

  const formatTime = (timeString) => {
    const date = new Date(`2023-10-29T${timeString}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${amOrPm}`;
  };
  function handleDate(e) {
    const name = e.target.name;
    const value = e.target.value;
    // setData((prev) => ({ ...prev, [name]: value }));
    setDate(value);
    // getBookingSlots(value, meetingRoomId);
  }

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const [calenderSelect, setCalenderSelect] = useState(false);
  const businessHours = openDays?.map((day) => {
    const startTime = new Date(day.start_time * 1000);
    const endTime = new Date(day.end_time * 1000);

    const formatTime = (date) => {
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      return `${hours}:${minutes}`;
    };

    // Check if both start and end times are '00:00'
    const isFullDay =
      formatTime(startTime) === "00:00" && formatTime(endTime) === "00:00";

    return {
      daysOfWeek: [day.day], // Map the "day" value to the corresponding day of the week
      startTime: isFullDay ? "" : formatTime(startTime),
      endTime: isFullDay ? "" : formatTime(endTime),
    };
  });
  /////////////////
  const allowDateSelection = (selectInfo) => {
    // if (selectInfo?.start < new Date()) {
    //   return;
    // }
    const selectedStart = selectInfo.start;
    const selectedEnd = selectInfo.end;
    setMeetingRoomId(selectInfo.resource?.id);
    const BookingDate = formatSlotDate(selectedStart);
    const selectedStartTime = timeToMinutes(formatSlotTime(selectedStart));
    const selectedEndTime = timeToMinutes(formatSlotTime(selectedEnd));
    const formattedTimes = {
      start: convertToTimeString(selectedStart),
      end: convertToTimeString(selectedEnd),
    };
    const businessHoursForDay = businessHours.find((hours) =>
      hours.daysOfWeek.includes(selectedStart.getDay())
    );

    if (businessHoursForDay) {
      const businessStartTime = timeToMinutes(businessHoursForDay.startTime);

      const businessEndTime = timeToMinutes(businessHoursForDay.endTime);

      const selectedStartTime = timeToMinutes(formatSlotTime(selectedStart));
      const selectedEndTime = timeToMinutes(formatSlotTime(selectedEnd));
      const formattedTimes = {
        start: convertToTimeString(selectedStart),
        end: convertToTimeString(selectedEnd),
      };

      const isRangeValid =
        selectedStartTime >= businessStartTime &&
        selectedEndTime <= businessEndTime;

      if (
        isRangeValid ||
        (isNaN(businessStartTime) && isNaN(businessEndTime))
      ) {
        setBookingDate(selectInfo?.startStr);

        setSelectedTime(formattedTimes);
        setShowBookingModal(true);
        setCalenderSelect(true);

        return true;
      } else {
        setBookingDate(selectInfo?.startStr);
        setSelectedTime(formattedTimes);
        setShowBookingModal(true);
        setCalenderSelect(true);
        return true;
      }
    } else {
      setBookingDate(selectInfo?.startStr);
      setSelectedTime(formattedTimes);
      setShowBookingModal(true);
      setCalenderSelect(true);
      return true;
    }
  };

  const convertToTimeString = (date) => {
    const timeString = date.toLocaleTimeString("en-US", { hour12: false });
    return timeString;
  };

  const formatSlotTime = (date) => {
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    return `${hours}:${minutes}:${seconds}`;
  };
  const formatSlotDate = (date) => {
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
  };

  const handleViewRender = (info) => {
    const parsedDate = new Date(info.view.title);
    const formattedDate = `${parsedDate.getFullYear()}-${(
      parsedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${parsedDate.getDate().toString().padStart(2, "0")}`;
    setBookingDate(formattedDate);
  };
  const dummyEvents = [{}];
  const dayCellContent = (arg) => {
    // const currentDate = arg.date.toISOString().split("T")[0];

    // const eventsOnDate = events?.filter((event) => {
    //   const eventStartDate = new Date(event.start).toISOString().split("T")[0];

    //   return currentDate === eventStartDate;
    // });
    const currentDate = arg.date.toISOString().split("T")[0];

    const eventsOnDate = events?.filter((event) => {
      const eventStartDate = new Date(event.start).toISOString().split("T")[0];
      return (
        currentDate === eventStartDate &&
        !event.cancel &&
        (event.approved == 1 || event.approved == 3)
      );
    });
    return (
      <>
        <div className="fc-daygrid-day-top">
          <h4 className="fc-daygrid-day-number">{arg.dayNumberText}</h4>
          {/* <br />
        <span className="text-center">
          <Badge bg="badge bg-label-success">
            {" "}
            Total Desks: {meetingData?.length}
          </Badge>
        </span>
        <br />
        <span className="">
          <Badge bg="badge bg-label-warning">
            Occupied : {eventsOnDate?.length}
          </Badge>
        </span> */}
        </div>
        <div
          className={`fc-event-dot event-container ${
            meetingData?.length - eventsOnDate?.length > 0
              ? "cursor-pointer"
              : ""
          }`}
        >
          <Badge
            bg={
              meetingData?.length - eventsOnDate?.length <= 0
                ? "badge bg-label-danger"
                : "badge bg-label-success"
            }
          >
            Available Desks :{" "}
            {meetingData?.length - eventsOnDate?.length > 0
              ? meetingData?.length - eventsOnDate?.length
              : 0}
          </Badge>
        </div>
      </>
    );
  };
  return (
    <section
      id="booking"
      className="container-fluid with-bottom-padding"
      style={{ padding: "0px 16px" }}
    >
      {/* <div className="container-fluid" style={{ padding: "0px 16px" }}>
        <div className="row match-height">
          <div className="col-md-12">
            {bookingsPermissions[0]?.read ? (
              <div className="col-12">
                <h4 className="mb-2">Hot Desk Bookings</h4>

                <div className="card ">
                  <div className=" d-flex justify-content-between">
                    <div className="col-12 col-md-6  col-lg-4">
                      <input
                        type="search"
                        className="form-control ms-1 mt-3"
                        value={search}
                        onChange={handleSearch}
                        placeholder="Search Bookings..."
                      />
                    </div>
                    <div className="col-12 text-end col-md-6 col-lg-4">
                      <div className="ms-3 mt-3">
                        <button
                          onClick={handleBookingButtonClick}
                          className="btn btn-primary"
                        >
                          Add Booking
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4 justify-content-between">
                    <div className="col-12 col-md-3 ms-4">
                      {FetchCompanies && (
                        <Select
                          options={[
                            { id: "all", name: "All" },
                            { id: "0", name: "Individuals" },
                            ...companiesArray,
                          ]}
                          defaultValue={{ id: "all", name: "All" }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          styles={colorStyles}
                          value={companiesArray?.find(
                            (obj) => obj.id === status
                          )}
                          onChange={handleStatus}
                          id="package-facilities"
                        />
                      )}
                    </div>
                  </div>
                  <div className="card-body px-0 pb-0">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col"> Client</th>
                          <th>
                            {" "}
                            <div
                              className="d-flex align-items-center sort"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div>Date</div>
                              <div
                                className="d-flex"
                                style={{ flexDirection: "column" }}
                              >
                                <Icon.ChevronUp
                                  onClick={() => requestSort("startTime")}
                                  className={getClassNamesFor("startTime")}
                                  style={{ fontSize: "10px" }}
                                />

                                <Icon.ChevronDown
                                  onClick={() => requestSort("startTime")}
                                  className={getClassNamesFor("startTime")}
                                  style={{ fontSize: "10px" }}
                                />
                              </div>
                            </div>
                          </th>

                          <th scope="col">Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items?.length > 0 ? (
                          items
                            ?.slice()

                            ?.filter((val) => {
                              if (search == "") {
                                return val;
                              } else if (
                                // val?.userDetails?.[0]?.name
                                val?.member?.name
                                  .toLowerCase()
                                  .includes(search.toLocaleLowerCase())
                              ) {
                                return val;
                              }
                            })
                            ?.map((item, index) => {
                              const {
                                meetingRoomTitle = item.meetingRoom?.title,
                                price = item.meetingRoom?.price,
                                bookingCapacity = item.meetingRoom?.capacity,
                                meetingRoomImagesUrls = item.meetingRoom
                                  ?.imageUrls,
                                startTime,
                                endTime,
                                id,
                                meetingRoomId = item.meetingRoom?.id,
                                memberId = item.member?.id,
                                status = item.approved,
                                cancel = item.cancel,
                              } = item;
                              if (cancel == false) {
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="media">
                                        <div className="media-aside mr-2">
                                          <img
                                            src={meetingRoomImagesUrls}
                                            alt=""
                                            className="rounded-circle"
                                            style={{
                                              width: "38px",
                                              height: "38px",
                                            }}
                                          />
                                        </div>
                                        <div
                                          className="mb-0 table-avatar align-self-center"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span>
                                            <b> {item.meetingRoom.title}</b>
                                          </span>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      {item.member?.name ||
                                        item.publicUser.name}
                                    </td>

                                    <td>{timeStampToDate(startTime)}</td>

                                    <td>
                                      <Badge
                                        bg={
                                          status == 1
                                            ? "badge bg-label-success"
                                            : status == 2
                                            ? "badge bg-label-danger"
                                            : "badge bg-label-primary"
                                        }
                                      >
                                        {status == 1
                                          ? "Approved"
                                          : status == 2
                                          ? "Not Approved"
                                          : "Pending"}
                                      </Badge>
                                    </td>
                                    <td className="d-flex flex-nowrap">
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            Approve
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="white"
                                          className="p-0"
                                          onClick={() =>
                                            bookingsPermissions[0]?.update
                                              ? handleApproveBooking(item)
                                              : null
                                          }
                                        >
                                          <Icon.BookmarkCheck />
                                        </Button>
                                      </OverlayTrigger>

                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            {bookingsPermissions[0]?.delete ? (
                                              "Cancel"
                                            ) : (
                                              <span>
                                                Cancel <Lock />
                                              </span>
                                            )}
                                          </Tooltip>
                                        }
                                      >
                                        <Button
                                          variant="white"
                                          className="p-0 ml-1 mt-1"
                                          onClick={() =>
                                            bookingsPermissions[0]?.delete
                                              ? handleDeleteBooking(item.id)
                                              : null
                                          }
                                        >
                                          X
                                        </Button>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                );
                              }
                            })
                        ) : items?.length <= 0 ? (
                          <tr>
                            <td colSpan={5} className="text-left fs-5">
                              No Bookings !
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>

                  {selectedBooking?.id && (
                    <DashboardBookingModal
                      {...selectedBooking}
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  )}
                </div>
              </div>
            ) : (
              <PermissionPage />
            )}
          </div>
        </div>
      </div> */}
      <div className="">
        {events && (
          <div className="calendar-container">
            <FullCalendar
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
                // resourceDayGridPlugin,
              ]}
              // resources={[
              //   { id: 1, title: "Resource A" },
              //   { id: 2, title: "Resource B" },
              //   { id: 3, title: "Resource C" },
              //   { id: 4, title: "Resource D" },
              // ]}
              // resources={calendarResource}
              initialView="dayGridMonth"
              events={events?.filter(
                (event) =>
                  !event.cancel && (event.approved == 1 || event.approved == 3)
              )}
              eventContent={eventContent}
              headerToolbar={{
                left: "prev,next",
                center: "title",
                // timeGridDay,
                right: "bookingButton",
              }}
              // views={{
              //   dayGridMonth: {
              //     buttonText: "Month",
              //   },
              //   timeGridWeek: {
              //     buttonText: "Week",
              //   },

              //   resourceDayGridDay: {
              //     buttonText: "Day",
              //   },
              //   listWeek: {
              //     buttonText: "List",
              //   },
              // }}

              dayMaxEventRows={true}
              // validRange={{ start: getCurrentDate() }}
              views={{
                dayGridMonth: {
                  dayMaxEventRows: 2,
                },
              }}
              customButtons={customButtons}
              selectable={true}
              eventDrop={handleEventDropResize}
              eventResize={handleEventDropResize}
              eventClick={handleEventClick}
              editable
              // aspectRatio={1.5}
              // height="auto"
              datesSet={handleViewRender}
              // slotLabelInterval={{ hours: 0.5 }}
              // slotDuration="00:30:00"
              // contentHeight="auto"
              selectAllow={(selectInfo) => allowDateSelection(selectInfo)}
              slotLabelContent={(slotInfo) => (
                <div
                  style={{
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {slotInfo.text}
                </div>
              )}
              businessHours={businessHours}
              dayCellContent={dayCellContent}
              // dayHeaderContent={(arg) => {
              //   // Calculate the total count of events for the current date
              //   const dateEvents = events.filter((event) => {
              //     const eventDate = new Date(event.start);
              //     return (
              //       eventDate.getDate() === arg.date.getDate() &&
              //       eventDate.getMonth() === arg.date.getMonth() &&
              //       eventDate.getFullYear() === arg.date.getFullYear()
              //     );
              //   });

              //   // Render the day header with the total count of events
              //   return (
              //     <div className="day-header">
              //       <span className="day-date">{arg.date.getDate()}</span>
              //       <span className="event-count">{dateEvents.length}</span>
              //     </div>
              //   );
              // }}
            />
          </div>
        )}

        {showBookingModal && (
          <AddHotDeskBooking
            handleClose={handleCloseBookingModal}
            companies={companies}
            meetingData={meetingData}
            Users={Users}
            bookingDate={bookingDate}
            calenderSelect={calenderSelect}
          />
        )}

        <Modal
          show={selectedBooking !== null}
          onHide={() => setSelectedBooking(null)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedBooking?.userName || "Booking Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedBooking && (
              <>
                <h5>
                  Resource:
                  <small> {selectedBooking?.title}</small>
                </h5>
                <h5>
                  Date:&nbsp;
                  <small>
                    {/* {formatDate(selectedBooking?.start, {
                    hour: "numeric",
                    minute: "2-digit",
                    omitZeroMinute: true,
                  })}
                  &nbsp; - &nbsp;
                  {formatDate(selectedBooking?.end, {
                    hour: "numeric",
                    minute: "2-digit",
                    omitZeroMinute: true,
                  })} */}
                    {new Date(selectedBooking?.start)?.toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }
                    )}
                  </small>
                </h5>
                {/* <h5>
                {billingOption == 1 ? "Price:" : "Credit"}
                <small>
                  {" "}
                  {billingOption == 1
                    ? selectedBooking?.price
                    : selectedBooking?.price}
                </small>
              </h5> */}
                <h5>
                  Capacity:
                  <small> {selectedBooking?.capacity}</small>
                </h5>
              </>
            )}

            <div className="mt-5">
              {/* <Button
              variant="primary"
              disabled={apiSpinner}
              onClick={
                bookingsPermissions[0]?.update ? handleEditBooking : null
              }
            >
              {bookingsPermissions[0]?.update ? (
                "Update Booking"
              ) : (
                <span>
                  Edit
                  <Lock />
                </span>
              )}
            </Button> */}
              <Button
                variant="danger"
                disabled={apiSpinner}
                className="ml-3 bg-danger"
                onClick={
                  bookingsPermissions[0]?.delete
                    ? () => handleDeleteBooking(selectedBooking)
                    : null
                }
              >
                {bookingsPermissions[0]?.delete ? (
                  <>
                    {" "}
                    Cancel{" "}
                    {apiSpinner ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "5px",
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <span>
                    Cancel <Lock />
                  </span>
                )}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </section>
  );
}

export default BookingTableView;
