import React from "react";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import { Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useDeleteCancellationPolicyMutation,
  useDeleteEmployeeMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useEffect } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useState } from "react";
function DeletePolicy({ id, name }) {
  const { employessPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [deletePolicy, resultStats] = useDeleteCancellationPolicyMutation();
  const [apiSpinner, setApiSpinner] = useState(false);
  const handleDelete = (e) => {
    e.preventDefault();
    confirmationAlert("Want to Delete This Booking Policy?").then(
      (willDelete) => {
        if (willDelete) {
          setApiSpinner(true);
          deletePolicy({
            spaceId: spaceId || currentSpaceId,
            policyId: id,
          });
        }
      }
    );
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert(`Booking Policy ${name} is Deleted Successfully.`);
      setTimeout(() => {
        setApiSpinner(false);
      }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
      setTimeout(() => {
        setApiSpinner(false);
      }, 500);
    }
  }, [resultStats]);
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" style={{ transition: "none" }}>
            {employessPermissions[0].delete ? (
              "Delete Policy"
            ) : (
              <span>
                Delete
                <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          onClick={employessPermissions[0].delete ? handleDelete : null}
          style={{ transition: "none" }}
          disabled={apiSpinner}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default DeletePolicy;
