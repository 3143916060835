import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";
import * as Icon from "react-bootstrap-icons";
import { formatDate } from "../../../utils/displayDateTime";

import {
  useAllSpacesQuery,
  useGetInvoiceDetailsReportQuery,
  useOwnerLocationQuery,
  useSpaceGetQuery,
  useVisitorReportQuery,
} from "../../../Slices/spaceApi";

import TabularReport from "../TabularReports";
import { useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
const InvoiceDetails = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const tableRef = useRef(null);
  // const { mySpace, ownerLocation, allSpaces } = useContext(AppContext);

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [page_size, setPage_size] = useState(10);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const { data, isLoading, refetch, isFetching } =
    useGetInvoiceDetailsReportQuery({
      spaceId: spaceId || currentSpaceId,
      start,
      end,
      page_size,
      page: page,
      location: location,
    });
  useEffect(() => {
    if ((data, !isLoading)) {
      setList(data?.invoices);
    }
  }, [data]);

  // useEffect(() => {
  //   refetch()
  // }, [selectedValue])
  const body = isFetching ? (
    <Loader />
  ) : (
    <div className="table-responsive">
      <table ref={tableRef} className="table  dataTable">
        <thead>
          <tr>
            <th>#</th>
            <th> Member</th>
            <th> Space</th>

            <th>Price</th>
            <th> Due Date</th>
            <th>Publish Date</th>
            <th>Paid Date</th>

            <th>Status</th>
          </tr>
        </thead>
        <tbody className="table-border-bottom-0">
          {!isLoading && data && list.length > 0 ? (
            list?.map((data, index) => (
              <React.Fragment key={index}>
                <tr key={index}>
                  <td className="serial bold">#{data.id}</td>
                  <td>
                    <div className="d-flex flex-column">
                      <span className="emp_name text-truncate">
                        {data?.member?.name}
                      </span>
                      <small className="emp_post text-truncate text-muted">
                        {data.email}
                      </small>
                    </div>
                  </td>
                  <td className="table-avatar maxw-200">
                    <div className="truncate-text">
                      {data?.space?.name || "-"}
                    </div>
                  </td>
                  <td className="table-avatar maxw-200">
                    <div className="truncate-text">{data.total || "-"}</div>
                  </td>

                  <td className="table-avatar">
                    {formatDate(data.due_date) || "-"}
                  </td>
                  <td className="table-avatar">
                    {formatDate(data.issued_date) || "-"}
                  </td>
                  <td className="table-avatar">
                    {data.paid_date ? formatDate(data.paid_date) : "--/--/----"}
                  </td>
                  <td>
                    <Badge
                      bg={
                        data?.paid
                          ? "badge bg-label-success"
                          : "badge bg-label-danger"
                      }
                    >
                      {data?.paid ? "Paid" : "Unpaid"}
                    </Badge>
                  </td>
                </tr>
              </React.Fragment>
            ))
          ) : list.length === 0 ? (
            <>
              <tr>
                <td>No Data</td>
              </tr>
            </>
          ) : (
            <tr>
              <td>Data is Loading</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
  return (
    <>
      {data && !isLoading && list ? (
        <TabularReport
          key={"bookingByWeekDays"}
          Child={() => body}
          data={data}
          isLoading={isLoading}
          mySpace={mySpace}
          allSpaces={allSpaces}
          tableRef={tableRef}
          list={list}
          setList={setList}
          ownerLocation={ownerLocation}
          page={page}
          setPage={setPage}
          page_size={page_size}
          setPage_size={setPage_size}
          location={location}
          setLocation={setLocation}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          refetch={refetch}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          setOptions={setOptions}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default InvoiceDetails;
