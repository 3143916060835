import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import * as Icon from "react-bootstrap-icons";

import GetAllCheckins from "./GetAllCheckins";
import GetCheckinsByDay from "./GetCheckinsByDay";
import GetCheckinByMember from "./GetCheckinByMember";
const CheckinShared = () => {
  return (
    <>
      <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
        <Tabs
          defaultActiveKey="checkins"
          id="justify-tab-example"
          variant="underline"
          justify
        >
          <Tab
            eventKey="checkins"
            title={
              <span className="d-flex align-items-center">
                <Icon.BoxArrowInRight /> &nbsp;Check Ins
              </span>
            }
          >
            <GetAllCheckins />
          </Tab>
          {/* <Tab
            eventKey="checkinbyday"
            title={
              <span className="d-flex align-items-center">
                <Icon.ArrowBarRight /> &nbsp;Checkin By Day
              </span>
            }
          >
            <GetCheckinsByDay />
          </Tab>
          <Tab
            eventKey="checkinbbymember"
            title={
              <span className="d-flex align-items-center">
                <Icon.PersonCheck /> &nbsp;Checkin By Member
              </span>
            }
          >
            <GetCheckinByMember />
          </Tab> */}
        </Tabs>
      </section>
    </>
  );
};

export default CheckinShared;
