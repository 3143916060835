import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Nav,
  Pagination,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

import BookingAvgRevenue from "./BookingAvgRevenue";
import ResourceUsage from "./ResourceUsage";
import UserType from "./UserType";
import BookingByWeekDays from "./BookingByWeekDay";
import ResourceUsageChart from "./ResourceUsageChart";
import ResourceAndRev from "./ResourceAndRev";
// import * as Icon from 'react-bootstrap-icons'
const KPIBookingShared = () => {
  const [page, setPage] = useState(0);

  return (
    <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
      <Tabs
        defaultActiveKey="AvgRev"
        id="justify-tab-example"
        // className='mb-3'
        variant="underline"
        justify
      >
        <Tab
          // style={{ marginRight: '5px' }}
          eventKey="AvgRev"
          title={
            <span className="d-flex align-items-center">
              <Icon.Wallet /> &nbsp;Average Revenue
            </span>
          }
        >
          {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
          <BookingAvgRevenue />
        </Tab>
        <Tab
          eventKey="weekly"
          title={
            <span className="d-flex align-items-center">
              <Icon.CalendarWeek /> &nbsp; Booking By Week Days
            </span>
          }
        >
          {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
          <BookingByWeekDays />
        </Tab>
        <Tab
          eventKey="resourceusage"
          title={
            <span className="d-flex align-items-center">
              <Icon.PersonVideo3 /> &nbsp;Resource Usage
            </span>
          }
        >
          {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
          <ResourceUsage />
        </Tab>
        <Tab
          eventKey="resourceusagechart"
          title={
            <span className="d-flex align-items-center">
              <Icon.BarChartLine /> &nbsp;Resource Usage Chart
            </span>
          }
        >
          {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
          <ResourceUsageChart />
        </Tab>
        <Tab
          eventKey="type"
          title={
            <span className="d-flex align-items-center">
              <Icon.PersonBoundingBox /> &nbsp; Booking By User Types
            </span>
          }
        >
          {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
          <UserType />
        </Tab>

        <Tab
          eventKey="resandrev"
          title={
            <span className="d-flex align-items-center">
              <Icon.GraphUpArrow /> &nbsp; Resource Usage and Revenue
            </span>
          }
        >
          {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
          <ResourceAndRev />
        </Tab>
      </Tabs>
    </section>
  );
};

export default KPIBookingShared;
