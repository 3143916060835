import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CompanyTab from "../AddMember/CompanyTab";
import UserTab from "../AddMember/UserTab";
import { Toaster } from "react-hot-toast";
import DeactivateAccount from "./DeactivateAccount";
import DeleteAccount from "./DeleteAccount";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";

function ManageAccount() {
  const { settingsPermissions } = useGlobalContext();

  return (
    <>
      <section className="app-user-list with-bottom-padding">
        <Tabs
          defaultActiveKey="Account"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          <Tab
            style={{ marginRight: "5px" }}
            eventKey="Account"
            title={
              settingsPermissions[0]?.update ? (
                <span className="d-flex align-items-center">Deactivate</span>
              ) : (
                <span className="d-flex align-items-center">
                  Deactivate{Lock}
                </span>
              )
            }
          >
            <DeactivateAccount />
          </Tab>
          <Tab
            eventKey="Social"
            title={
              settingsPermissions[0]?.update ? (
                <span className="d-flex align-items-center">Delete</span>
              ) : (
                <span className="d-flex align-items-center">Delete{Lock}</span>
              )
            }
          >
            <DeleteAccount />
          </Tab>
        </Tabs>
        {/* <Toaster position="top-right" reverseOrder={false} /> */}
      </section>
    </>
  );
}

export default ManageAccount;
