import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import {
  useAssetCategoryListQuery,
  useAssetCreateMutation,
  useAssetUpdateMutation,
} from "../../Slices/spaceApi";

import CreatableSelect from "react-select/creatable";
import Swal from "sweetalert2";
// import AssetContext from "../../context/AssetContext";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  isFirstCharacterDigit,
  isValidInteger,
  validPhoneNumber,
} from "../../utils/validation";
import { requiredAlert } from "../../utils/Alerts";
import { useGlobalContext } from "../../Context/GlobalContext";
const AssetAdd = ({ handleClose, operateId, operateObject }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { currency } = useGlobalContext();
  // const { data, isLoading, isError, error, refetch } =
  //   useAssetCategoryListQuery({
  //     spaceId:spaceId|| currentSpaceId
  //   })
  // const { categories } = useContext(AssetContext);
  const { data: categories } = useAssetCategoryListQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [category, setCategory] = useState([]);

  const [input, setInput] = useState({
    name: "",
    notes: "",
    value: "",
    quantity: "",
    sku: "",
    category: null,
  });
  useEffect(() => {
    if (categories?.categories) {
      const updatedArray = categories?.categories?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCategory(updatedArray);
    }
  }, [categories]);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [addAsset, result] = useAssetCreateMutation();
  const [updateAsset, updated] = useAssetUpdateMutation();

  const handleInput = (e) => {
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCategoryChange = (selectedOption) => {
    setInput((prevInput) => ({
      ...prevInput,
      category: selectedOption.value || null,
    }));
    //.log(input)
  };
  useEffect(() => {
    if (operateId && operateObject) {
      setInput(operateObject);
    }
  }, [operateId, operateObject]);
  useEffect(() => {
    if (result?.isSuccess || updated.isSuccess) {
      handleClose();
      if (!operateId) {
        toast.success("Asset Added Successfully", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      } else {
        toast.success("Asset Updated Successfully", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }
    }
  }, [result, updated]);
  const handleAdd = (event) => {
    setIsSubmit(true);
    const form = event.currentTarget;

    event.preventDefault();
    if (form.checkValidity() === false || !input.category) {
      event.stopPropagation();
      setValidated(true);
    } else {
      setValidated(false);
      !operateId
        ? addAsset({
            spaceId: spaceId || currentSpaceId,
            input,
          })
        : updateAsset({
            spaceId: spaceId || currentSpaceId,
            id: operateId,
            input,
          });
    }
  };
  useEffect(() => {
    if (result.isError) {
      // const firstError = result.error.data.error;

      // Swal.fire({
      //   title: "Required!",
      //   icon: "warning",
      //   html: firstError,
      //   confirmButtonText: "OK",
      // });

      const errorMessage =
        result &&
        result?.error &&
        result?.error?.data &&
        Object?.values(result?.error?.data).find(
          (error) => error?.length > 0 || "An Error Occured"
        );

      requiredAlert(errorMessage || "An Error Occured");
    }
    if (updated.isError) {
      // const firstError = updated.error.data.error;
      // Swal.fire({
      //   title: "Required!",
      //   icon: "warning",
      //   html: firstError,
      //   confirmButtonText: "OK",
      // });

      const errorMessage =
        updated &&
        updated?.error &&
        updated?.error?.data &&
        Object?.values(updated?.error?.data).find(
          (error) => error?.length > 0 || "An Error Occured"
        );

      requiredAlert(errorMessage || "An Error Occured");
    }
  }, [result, updated]);
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="w-10"
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">
            {operateId ? "Update Asset" : "Add Asset"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAdd}>
            <Row className="mb-3">
              <Form.Group controlId="assetname" as={Col}>
                <Form.Label className="mb-2">
                  <h6>Asset Name</h6>
                </Form.Label>
                <Form.Control
                  required
                  pattern="[A-Za-z].*"
                  type="text"
                  placeholder="John Doe"
                  value={input.name}
                  name="name"
                  onChange={handleInput}
                />
                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.name ? (
                      "This field is Required"
                    ) : isFirstCharacterDigit(input.name) ? (
                      "Must start with an alphabet"
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group md="5" controlId="category" as={Col}>
                <Form.Label className="mb-2">
                  <h6>Category</h6>
                </Form.Label>
                <CreatableSelect
                  onChange={handleCategoryChange}
                  placeholder={
                    categories && input.category && categories.categories
                      ? category.find((item) => item.value === input.category)
                          ?.label || "Add Category"
                      : "Add Category"
                  }
                  options={category}
                  className={
                    validated && !input.category
                      ? "border border-danger rounded "
                      : ""
                  }
                />

                {validated && !input.category ? (
                  <p className="text-danger fs-7 ">This field is required*</p>
                ) : (
                  <></>
                )}
              </Form.Group>
              <Form.Group md="5" controlId="sku" as={Col}>
                <Form.Label className="mb-2">
                  <h6>SKU</h6>
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="TB001"
                  value={input.sku}
                  name="sku"
                  onChange={handleInput}
                />
                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.sku ? "This field is Required" : <></>}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} md="5" controlId="value">
                <Form.Label className="mb-2">
                  <h6>Unit Value</h6>
                </Form.Label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{currency}</span>
                  </div>
                  <Form.Control
                    required
                    min={0.01}
                    step={0.01}
                    type="number"
                    placeholder="100"
                    value={input.value}
                    name="value"
                    onChange={handleInput}
                  />
                </div>
                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.value ? (
                      "This field is Required"
                    ) : // !isValidInteger(input.value)
                    input.value <= 0 ? (
                      "Must be a Positive Value"
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {!operateId ? (
                <Form.Group as={Col} md="5" controlId="quantity">
                  <Form.Label className="mb-2">
                    <h6>Number of Copies</h6>
                  </Form.Label>
                  <Form.Control
                    required
                    min={0}
                    type="number"
                    placeholder="10"
                    value={input.quantity}
                    name="quantity"
                    onChange={handleInput}
                  />
                  {validated && (
                    <Form.Control.Feedback type="invalid">
                      {!input.quantity ? (
                        "This field is Required"
                      ) : !isValidInteger(input.quantity) ? (
                        "Must be a Positive Integer"
                      ) : (
                        <></>
                      )}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              ) : (
                <>
                  <Form.Group as={Col} md="5">
                    <Form.Label className="mb-2">
                      <h6>Status</h6>
                    </Form.Label>
                    <Form.Select defaultValue={1} name="status">
                      <option value="1">Available </option>
                      <option value="2">Assigned </option>
                      <option value="3">Maintainance </option>
                      <option value="4">Retired </option>
                    </Form.Select>
                  </Form.Group>
                </>
              )}
            </Row>
            <Row className="mb-2"></Row>
            {/* <Row className='mb-2'>
              <Form.Group md='5' controlId='category' as={Col}>
                <Form.Label>Category (Optional)</Form.Label>
                <CreatableSelect
                  onChange={handleCategoryChange}
                  placeholder='Select a category'
                  options={category}
                  className={
                    validated && input.category
                      ? 'border border-success rounded '
                      : ''
                  }
                />
              </Form.Group>
            </Row> */}

            <Row className="mb-3">
              <Form.Group controlId="validationCustom01" as={Col}>
                <Form.Label className="mb-2">
                  <h6>Notes</h6>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  required
                  className="form-control dt-uname textarea"
                  placeholder="Enter Notes"
                  name="notes"
                  value={input.notes}
                  onChange={handleInput}
                  rows={3} // Set the number of rows to 3
                  style={{ overflowY: "scroll" }} // Add scrollbar style
                />
                {validated && (
                  <Form.Control.Feedback type="invalid">
                    {!input.notes ? "This field is Required" : <></>}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row></Row>
            {result.isLoading || updated.isLoading ? (
              <>
                <Button className="btn btn-primary ">
                  Loading...{" "}
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="primary"
                  />
                </Button>
              </>
            ) : (
              <Button className="btn btn-primary " type="submit">
                {operateId ? "Update Asset" : "Add Asset"}
              </Button>
            )}

            <Button className="btn btn-secondary  ml-3" onClick={handleClose}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssetAdd;
