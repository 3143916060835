const moment = require("moment");

export function localDateToUTC(localDateString) {
  const localDate = moment(localDateString, "YYYY-MM-DD");

  // If the input date string is not in a recognized format, moment might return an invalid date
  if (!localDate.isValid()) {
    // console.error("Invalid date format");
    return null; // or handle the error in a way that makes sense for your application
  }

  // Convert to UTC
  const utcDate = localDate.utc();

  return utcDate.format("YYYY-MM-DD");
}
