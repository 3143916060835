import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostQuickBookCallBackUrlMutation } from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import { requiredAlert } from "../../utils/Alerts";
import toast from "react-hot-toast";
import { useGlobalContext } from "../../Context/GlobalContext";
const QuickBookCallBack = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setSettingsKey } = useGlobalContext();
  const [postQuickbook, resultStats] = usePostQuickBookCallBackUrlMutation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const code = queryParams?.get("code");
  const state = queryParams?.get("state");
  const realmId = queryParams?.get("realmId");
  const [loader, setloader] = useState(true);
  const [count, setCount] = useState(0);
  const [apicall, setApiCall] = useState(false);
  useEffect(() => {
    if (code && state && realmId) {
      setApiCall(true);
      setCount(count + 1);
    }
  }, [code, state, realmId]);
  useEffect(() => {
    if (count == 1) {
      postQuickbook({
        spaceId: spaceId || currentSpaceId,
        data: {
          code: code,
          realmId: realmId,
          state: state,
        },
      });
    }
    setApiCall(false);
  }, [count]);

  const navigate = useNavigate();
  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert("Quickbook Integration Failed. Please Try again.");
      setSettingsKey("Integrations");
      navigate("/setting/accountsetting");
    }

    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Quickbook Integrated Successfully.", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 5000);

      setSettingsKey("Integrations");
      navigate("/setting/accountsetting");
    }
  }, [resultStats]);

  if (!code || !state || !realmId || loader) {
    return <Loader height={6.6} />;
  }
  return <div></div>;
};

export default QuickBookCallBack;
