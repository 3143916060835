import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useEffect } from "react";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import {
  useGetFacilitiesQuery,
  useGetSpaceTypesQuery,
} from "../../Slices/constantApi";
import Loader from "../Loader/Loader";
import {
  useCreateFloorPlanMutation,
  useGetCompanyUsersQuery,
  useGetSpaceDataQuery,
  useUpdateFloorRoomMutation,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";

const AssignDesk = ({
  shape,
  assignDeskOpen,
  setAssignDeskOpen,
  setShowDelete,
  setSelectedShape,
  FetchUsers,
  FetchCompanies,
  spaceId,
}) => {
  const [updateFloorRooms, resultStats] = useUpdateFloorRoomMutation();

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [assignId, setAssignId] = useState(null);

  const { data: companyUsers, isLoading: usersLoading } =
    useGetCompanyUsersQuery(
      {
        spaceId: spaceId,
        companyId: selectedCompany,
      },
      {
        skip: selectedCompany === null,
        refetchOnMountOrArgChange: true,
        query: {
          selectedCompany: selectedCompany,
        },
      }
    );

  const [assignToIndividual, setAssignToIndividual] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setAssignDeskOpen(false);
    setShowDelete(false);
    setSelectedShape(null);
  };
  const handleShow = () => setShow(true);

  const [roomValues, setRoomValues] = useState(shape);

  const assignIndividual = () => {
    setAssignToIndividual(true);
  };
  const assignCompany = () => {
    setAssignToIndividual(false);
  };
  const handleCompanyAssign = (e) => {
    const selectedCompanyId = e.target.value;

    setSelectedCompany(selectedCompanyId);
  };
  const handleInputChange = (e) => {
    setAssignId(e.target.value);
  };

  const handleAssign = () => {
    if (assignId == null) {
      requiredAlert("Please Select A User To Assign");
    } else {
      updateFloorRooms({
        id: shape?.id,
        updateData: {
          assignedTo: assignId,
        },
      });
    }
  };
  useEffect(() => {
    if (resultStats?.isSuccess) {
      toast.success("The Room has been Updated.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      handleClose();
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);

  return (
    <div>
      <Modal
        show={assignDeskOpen}
        onHide={handleClose}
        id="meetingPopUp"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title as="h5">
            {roomValues?.type == 1
              ? "Assign Dedicated Desk"
              : roomValues?.type == 3
              ? "Assign Private Office"
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <div className="d-flex">
            <button className="btn btn-primary mr-2" onClick={assignIndividual}>
              Individual
            </button>
            <button class="btn btn-primary" onClick={assignCompany}>
              Company
            </button>
          </div>
          {assignToIndividual && (
            <div class="form-group d-flex my-5">
              <label
                for="employeeInput"
                className="align-self-center mr-3 mt-2"
              >
                Employee:
              </label>

              <select
                name=""
                id="employee"
                className="form-control"
                onChange={handleInputChange}
              >
                <option value="" disabled selected>
                  Please Select Individual
                </option>
                {FetchUsers?.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {!assignToIndividual && (
            <div class="form-group my-5">
              <label for="employeeInput" className="align-self-center mr-3">
                Company:
              </label>

              <select
                name=""
                id="employee"
                className="form-control"
                value={selectedCompany}
                onChange={(e) => handleCompanyAssign(e)}
              >
                <option disabled selected value="null">
                  Please Select Comapny
                </option>
                {FetchCompanies?.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select>
              <label
                for="employeeInput"
                className="align-self-center mr-3 mt-2"
              >
                Employee:
              </label>

              <select
                name=""
                id="employee"
                className="form-control"
                onChange={handleInputChange}
              >
                <option value="" disabled selected>
                  Please Select Employee
                </option>
                {companyUsers?.users?.length > 0 &&
                  companyUsers?.users?.map((user) => (
                    <option key={user?.id} value={user?.id}>
                      {user?.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAssign}>
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AssignDesk;
