import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";
import * as Icon from "react-bootstrap-icons";
import { formatDate, formatDateNotTime } from "../../../utils/displayDateTime";

import {
  useAllSpacesQuery,
  useDiscountInvoiceReportQuery,
  useOwnerLocationQuery,
  useSpaceGetQuery,
} from "../../../Slices/spaceApi";
// import AppContext from '../../../context/AppContext'

import TabularReport from "../TabularReports";
import { useSelector } from "react-redux";
const DiscountInvoice = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const tableRef = useRef(null);
  // const { mySpace, ownerLocation, allSpaces } = useContext(AppContext)

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [page_size, setPage_size] = useState(10);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const { data, isLoading, refetch, isFetching } =
    useDiscountInvoiceReportQuery({
      spaceId: spaceId || currentSpaceId,
      start,
      end,
      page_size,
      page: page,
      location: location,
    });
  useEffect(() => {
    if ((data, !isLoading)) {
      setList(data?.invoicedDiscounts);
    }
  }, [data]);
  useEffect(() => {}, [data, isLoading, list]);

  // useEffect(() => {
  //   refetch()
  // }, [selectedValue])
  const body = isFetching ? (
    <Loader />
  ) : (
    <div className=" table-responsive">
      <table ref={tableRef} className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Member</th>
            <th>Price</th>
            <th> Due Date</th>
            <th> Issued Date</th>
            <th>Paid Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className="table-border-bottom-0">
          {!isLoading && data && list.length > 0 ? (
            list.map((invoice, index) => (
              <tr key={invoice.id || index}>
                <td>{invoice.id}</td>
                <td className="sorting_1">
                  <div className="d-flex justify-content-start align-items-center user-name">
                    <div className="d-flex flex-column">
                      <span className="emp_name text-truncate">
                        {invoice.member.name}
                      </span>
                      <small className="emp_post text-truncate text-muted">
                        {invoice.member.email}
                      </small>
                    </div>
                  </div>
                </td>
                <td>{invoice.price}</td>
                <td>{formatDateNotTime(invoice.due_date)}</td>
                <td>{formatDateNotTime(invoice.issued_date)}</td>
                <td>
                  {invoice.paid_date
                    ? formatDateNotTime(invoice.paid_date)
                    : "--/--/----"}
                </td>
                <td>
                  <div className="col-2">
                    {invoice.paid ? (
                      <span className="badge bg-label-success badge-fixed-width">
                        Paid
                      </span>
                    ) : (
                      <span className="badge bg-label-warning badge-fixed-width">
                        Unpaid
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))
          ) : isLoading ? (
            <tr>
              <td>Data is Loading</td>
            </tr>
          ) : list?.length === 0 ? (
            <tr>
              <td>No Data</td>
            </tr>
          ) : (
            <tr>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
  return (
    <>
      {data && !isLoading && list ? (
        <TabularReport
          key={"bookingByWeekDays"}
          Child={() => body}
          data={data}
          isLoading={isLoading}
          mySpace={mySpace}
          allSpaces={allSpaces}
          tableRef={tableRef}
          list={list}
          setList={setList}
          ownerLocation={ownerLocation}
          page={page}
          setPage={setPage}
          page_size={page_size}
          setPage_size={setPage_size}
          location={location}
          setLocation={setLocation}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          refetch={refetch}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          setOptions={setOptions}
        />
      ) : (
        <Loader></Loader>
      )}
    </>
  );
};

export default DiscountInvoice;
