import React, { useEffect, useState } from "react";
import axios from "axios";
import { Amenities, Select } from "./";
import { errorAlertImage } from "../../utils/Alerts";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import * as Icon from "react-bootstrap-icons";
import { Button, Modal } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useGetSpaceDashboardQuery,
  useGetSpaceDurationsQuery,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";

import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";
import ColorPicker from "./ColorPicker";
import OperatingHours from "./OperatingHours";
import BillingConfiguration from "./BillingConfiguration";
import Currency from "./Currency";
import TaxConfiguration from "./TaxConfiguration";
import BookingSlots from "./BookingSlots";
import BookingApproval from "./BookingApproval";
import LocationComponent from "./LocationComponent";
import BrandEmail from "./BrandEmail";
import ProgressCard from "../Dashboard/ProgressCard";
import TourPage from "./TourPage";
import AutoEmailSent from "./AutoEmailSent";
import SlotSize from "./SlotSize";
import IntegryWidget from "./IntegryWidget";
import SpaceDurations from "./SpaceDurations";
import BookingPage from "./BookingPage";
import BrandName from "./brandName";
import InvoiceInstructions from "./InvoiceInstructions";
const TabProfile = ({ spaceData, facilities, spaceDurations }) => {
  const { settingsPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();
  const { data: dashboardData, isLoading } = useGetSpaceDashboardQuery(
    spaceId || currentSpaceId
  );

  const [companyChanged, setCompanyChanged] = useState(false);

  const [file, setFile] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);
  const [generalData, setGeneralData] = useState({
    imageUrls: spaceData?.imageUrls?.[0],
    name: spaceData?.name,
  });
  const [nameValid, setNameValid] = useState(true);

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  const generalPost = (e) => {
    const { name, value } = e.target;
    setGeneralData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setNameValid(e.target.validity.valid);
    setCompanyChanged(true);
  };

  useEffect(() => {
    if (file !== null && file !== "") {
      setImageUploading(true);
      if (file.size >= 1e7) {
        setImageUploading(false);

        errorAlertImage("File is too big!");
        setFile("");
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setGeneralData({
              ...generalData,
              imageUrls: resp.data,
            });
            setImageUploading(false);
          })
          .catch((error) => {
            setImageUploading(false);
            setFile(null);
          });
      } else {
        setFile(null);
        setImageUploading(false);

        requiredAlert("Please Select Image File");
      }
    }
  }, [file]);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
    setCompanyChanged(true);
  };

  const postRequest = (e) => {
    e.preventDefault();

    const { name, imageUrls } = generalData;

    if (!file && !imageUrls) {
      return;
    } else if (!file) {
      const parts = imageUrls.split("/");
      const filename = parts[parts.length - 1].split("?")[0];

      updateGeneral({
        spaceId: spaceId || currentSpaceId,
        data: { imageUrls: [filename], name },
      });
    } else {
      updateGeneral({
        spaceId: spaceId || currentSpaceId,
        data: generalData,
      });
    }
  };

  const reset = () => {
    setGeneralData({ ...generalData, name: "", imageUrls: "" });
    setFile("");
    setCompanyChanged(true);
  };
  const handleClose = () => {
    setCompanyChanged(false);
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Account Information Updated Successfully.", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 1000);

      handleClose();
    }
  }, [resultStats]);
  return (
    <>
      {!isLoading &&
      dashboardData &&
      (!dashboardData?.amenities > 0 ||
        !dashboardData?.meetingRooms > 0 ||
        !dashboardData?.spacePackages > 1 ||
        !dashboardData?.users > 0) ? (
        <div className="card">
          <ProgressCard dashboardData={dashboardData} currentStep={1} />
        </div>
      ) : null}

      <div className="card">
        <div className="p-4">
          <h4 className="">Company Logo</h4>
          <form
            className="mt-3"
            onSubmit={settingsPermissions[0]?.update ? postRequest : null}
          >
            <div className="media">
              <div className="media-aside align-self-start">
                <img
                  src={
                    file
                      ? URL.createObjectURL(file)
                      : generalData?.imageUrls ||
                        // "/LogoPreview.png" ||
                        "https://placehold.jp/90x90.png"
                  }
                  // onError={(e) => (e.target.src = "/LogoPreview.png")}
                  style={{
                    width: "80px",
                    height: "80px",
                  }}
                  className="rounded"
                  alt=""
                />
              </div>
              <div>
                <input
                  id="real-file"
                  name="file"
                  type="file"
                  hidden="hidden"
                  onChange={handleFileChange}
                />
              </div>
              <div className="media-body mt-2">
                {imageUploading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Uploading...</span>
                  </div>
                ) : (
                  <button
                    id="custom-button"
                    onClick={upload}
                    className="btn btn-secondary me-2 btn-sm"
                    style={{ position: "relative" }}
                  >
                    Upload
                  </button>
                )}

                <p className="card-text mt-2">
                  {/* Allowed Max size of 10Mb */}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                    }
                  >
                    <Button variant="white" className="mx-2 p-0">
                      <Icon.InfoCircle style={{ color: "currentcolor" }} />
                    </Button>
                  </OverlayTrigger>
                </p>
                {!file && !generalData?.imageUrls ? (
                  <div className="invalid-feedback">
                    Please select an image.
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-6">
                <div role="group" className="form-group" id="__BVID__1899">
                  <label htmlFor="account-e-mail" className="d-block mb-1">
                    E-mail
                  </label>
                  <div>
                    <input
                      name="email"
                      type="text"
                      value={spaceData?.email}
                      placeholder="Email"
                      disabled
                      className="form-control"
                      id="account-e-mail"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div role="group" className="form-group" id="__BVID__1897">
                  <label htmlFor="account-name" className="d-block mb-1">
                    Company
                  </label>
                  <div>
                    <input
                      name="name"
                      value={generalData?.name}
                      required
                      type="text"
                      placeholder="Company"
                      className={`form-control ${
                        nameValid ? "" : "is-invalid"
                      }`}
                      onChange={generalPost}
                      id="account-name"
                    />
                  </div>
                  {!nameValid && (
                    <div className="invalid-feedback">
                      Please enter company name
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 mt-2">
                <button
                  className="btn me-3 btn-primary"
                  style={{ position: "relative" }}
                  disabled={
                    !companyChanged ||
                    !settingsPermissions[0]?.update ||
                    imageUploading
                  }
                >
                  {settingsPermissions[0]?.update ? (
                    "Save Changes"
                  ) : (
                    <span>Save Changes{Lock2}</span>
                  )}
                </button>
                <button
                  type="reset"
                  className="btn btn-outline-secondary"
                  onClick={settingsPermissions[0]?.update ? reset : null}
                >
                  {settingsPermissions[0]?.update ? (
                    "Reset"
                  ) : (
                    <span>Reset{Lock2}</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <TourPage spaceData={spaceData} />

      <BookingPage spaceData={spaceData} />

      <BrandEmail spaceData={spaceData} />

      <BrandName spaceData={spaceData} />

      <InvoiceInstructions spaceData={spaceData} />

      {/* <LocationComponent /> */}
      <AutoEmailSent spaceData={spaceData} />

      <BillingConfiguration
        spaceData={spaceData}
        packages={dashboardData?.spacePackages}
      />

      <Currency spaceData={spaceData} />

      <TaxConfiguration spaceData={spaceData} />

      <OperatingHours spaceData={spaceData} />
      {/* <SlotSize spaceData={spaceData} />
      <BookingSlots spaceData={spaceData} />
      <BookingApproval spaceData={spaceData} /> */}

      <SpaceDurations spaceDurations={spaceDurations} />

      <Amenities data={facilities} facilities={spaceData?.facilities} />
      <ColorPicker data={spaceData} />
    </>
  );
};

export default TabProfile;
