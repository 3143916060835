import React from "react";

const TourPage = ({ spaceData }) => {
  return (
    <>
      <div className="card">
        <div className="row p-4">
          <h4>Tours Configuration</h4>
          <h6 className="mt-2">
            You can configure your Space tours to your own website by using
            following URL link.
          </h6>

          <input
            className="form-control mt-1 w-50 ml-3"
            id="tourConfiguration"
            readOnly
            value={`https://members.ndesks.com/${spaceData?.username}/tour`}
          ></input>
        </div>
      </div>
    </>
  );
};

export default TourPage;
