import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { Amenities, Select } from "./";
import { errorAlertImage } from "../../utils/Alerts";
import { DataOfTime } from "../../utils/constant";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { DashLg } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import {
  useGetCurrencyQuery,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";
import TimeHours from "./timeHours.json";
import Logo1 from "../../Assets/dummyImages/Logo1.png";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";

function BillingConfiguration({ spaceData, packages }) {
  const { settingsPermissions, setBillingOption } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();

  const [billingMethodChanged, setBillingMethodChanged] = useState(false);
  const [taxIncluded, setTaxIncluded] = useState(
    spaceData?.creditPriceInclusiveTax
  );

  const [selectedMethod, setSelectedMethod] = useState(
    spaceData?.billing_option
  );
  const [coinPrice, setCoinPrice] = useState(spaceData?.creditPrice);
  useEffect(() => {
    setSelectedMethod(spaceData?.billing_option);
    setCoinPrice(spaceData?.creditPrice);
    setTaxIncluded(spaceData?.creditPriceInclusiveTax);
  }, [spaceData]);

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.value);
    setBillingMethodChanged(true);
  };

  const handleBillingDetails = (e) => {
    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: {
        billing_option: selectedMethod,
        creditPrice: selectedMethod == 1 ? 1 : coinPrice,
        creditPriceInclusiveTax: selectedMethod == 1 ? false : taxIncluded,
      },
    });
  };
  const handleCoinPrice = (e) => {
    setCoinPrice(e.target.value);
    setBillingMethodChanged(true);
  };
  const handleInclusiveTax = (event) => {
    setTaxIncluded(event.target.checked);
  };

  const handleClose = () => {
    setBillingMethodChanged(false);
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Billling Configuration Updated Successfully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 2000);

      handleClose();
      // setBillingOption(resultStats?.data.billing_option);
    }
  }, [resultStats]);

  return (
    <>
      <div className="card">
        <div className="row p-4">
          <h4>Billing Configuration</h4>
          <h6 className="mt-2">
            Would you like to define your packages based on hourly billing in
            your currency or Would like to you Coins system. In coins system you
            assign number of coins to each member at he can utilize them in
            amenities.
          </h6>
          {packages > 1 ? (
            <small>
              Currently you cannot change billing configuration as packages are
              present. Billing Configuration can be done if space has no
              Package.
            </small>
          ) : (
            ""
          )}
          <select
            className="form-control mt-1 w-50 ml-3"
            name="billingMethod"
            id="billingMethod"
            value={selectedMethod}
            onChange={handleMethodChange}
            disabled={packages > 1}
          >
            <option value="1">Hours</option>
            <option value="2">Coins</option>
          </select>

          {selectedMethod == 2 && (
            <>
              <div className="mt-2">
                <label htmlFor="overallTax">Price Per Coin</label>
                <input
                  type="number"
                  className="form-control mt-1 w-50 ml-1"
                  placeholder="Please add per coin price"
                  name="coinPrice"
                  id="coinPrice"
                  value={coinPrice}
                  onChange={handleCoinPrice}
                />
              </div>
              <div className="form-check mt-1">
                <label className="form-check-label" htmlFor="inclusiveTax">
                  Is this Price Includes Tax!
                </label>
                <input
                  type="checkbox"
                  className="form-check-input ml-2"
                  id="inclusiveTax"
                  checked={taxIncluded}
                  onChange={(e) => handleInclusiveTax(e)}
                />
              </div>
            </>
          )}
          <div className="row mt-5">
            <div className="col-md-12">
              <button
                className="btn btn-primary mt-2 "
                disabled={
                  !billingMethodChanged || !settingsPermissions[0]?.update
                }
                onClick={
                  settingsPermissions[0]?.update ? handleBillingDetails : null
                }
              >
                {settingsPermissions[0]?.update ? (
                  "Save Changes"
                ) : (
                  <span>Save Changes{Lock2}</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BillingConfiguration;
