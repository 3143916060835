import { useMemo, useState } from "react";

export const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    if (
      sortConfig?.key === "userDetails:name" ||
      sortConfig?.key === "userDetails:email" ||
      sortConfig?.key === "userDetails:packageId"
    ) {
      let point = sortConfig?.key.split(":");
      sortableItems.sort((a, b) => {
        if (
          a["userDetails"]?.[0]?.[point?.[1]] <
          b["userDetails"]?.[0]?.[point?.[1]]
        ) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (
          a["userDetails"]?.[0]?.[point?.[1]] >
          b["userDetails"]?.[0]?.[point?.[1]]
        ) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    if (
      sortConfig?.key === "details:bookingCapacity" ||
      sortConfig?.key === "details:meetingRoomTitle"
    ) {
      let point = sortConfig?.key.split(":");
      sortableItems.sort((a, b) => {
        if (a["details"]?.[point?.[1]] < b["details"]?.[point?.[1]]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a["details"]?.[point?.[1]] > b["details"]?.[point?.[1]]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    if (sortConfig?.key === "replies:created_at") {
      let point = sortConfig?.key.split(":");
      console.log("Sortable items are", sortableItems);
      if (sortableItems?.length > 0) {
        sortableItems.sort((a, b) => {
          return sortConfig.direction === "ascending" ? -1 : 1;
        });
      }
    }

    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
