const moment = require("moment");

export function utcDateToLocalDate(utcDateString) {
  const utcDate = moment?.utc(utcDateString, "YYYY-MM-DD");

  // If the input UTC date string is not in a recognized format, moment might return an invalid date
  if (!utcDate.isValid()) {
    // console.error("Invalid UTC date format");
    return null; // or handle the error in a way that makes sense for your application
  }

  // Convert to local date
  const localDate = utcDate?.local();

  return localDate?.format("YYYY-MM-DD");
}
