import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";

import {
  useAllSpacesQuery,
  useBookingAvgRevenueReportQuery,
  useBookingByWeekDayReportQuery,
  useBookingMemberReportQuery,
  useGetOccupancyReportQuery,
  useOwnerLocationQuery,
  useSpaceGetQuery,
  useVisitReportQuery,
  useVisitorReportQuery,
} from "../../../Slices/spaceApi";

import TabularReport from "../TabularReports";
import { useSelector } from "react-redux";
import { useIsFetching } from "react-query";
import { useGetSpaceTypesQuery } from "../../../Slices/constantApi";
const OccupancyReportTable = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { data: spaceTypes, isLoading: spaceTypeLoading } =
    useGetSpaceTypesQuery();

  const tableRef = useRef(null);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [page_size, setPage_size] = useState(10);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [spaceType, setSpaceType] = useState(0);
  const { data, isLoading, refetch, isFetching } = useGetOccupancyReportQuery({
    spaceId: spaceId || currentSpaceId,
    start,
    end,
    page_size,
    page: page,
    location: location,
    spaceType,
  });
  useEffect(() => {
    if ((data, !isLoading)) {
      setList(data?.resources);
    }
  }, [data]);

  // useEffect(() => {
  //   refetch()
  // }, [selectedValue])

  const body = isFetching ? (
    <Loader />
  ) : (
    <div className="table-responsive">
      <table ref={tableRef} className="table  ">
        <thead>
          <tr>
            {/* <th>#</th> */}
            <th>Resource</th>
            <th>Total</th>

            <th>Booked</th>
            <th>Unbooked</th>
            <th>Occupancy</th>
          </tr>
        </thead>
        <tbody className="table-border-bottom-0">
          {!isLoading && data && list?.length > 0 ? (
            list?.map((data, index) => (
              <tr key={index}>
                {/* <td>{data.id}</td> */}
                <td>{data.spaceType}</td>
                <td>{data.assignedResources + data?.freeResources}</td>
                <td>{data.assignedResources}</td>
                <td>{data?.freeResources}</td>
                <td>
                  {(data.assignedResources /
                    (data?.assignedResources + data?.freeResources)) *
                    100}{" "}
                  %
                </td>
              </tr>
            ))
          ) : list?.length === 0 ? (
            <tr>
              <td>No Data</td>
            </tr>
          ) : (
            <tr>
              <td>Data is Loading</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
  return (
    <>
      {data && !isLoading && list ? (
        <TabularReport
          key={"bookingByWeekDays"}
          Child={() => body}
          data={data}
          isLoading={isLoading}
          mySpace={mySpace}
          allSpaces={allSpaces}
          tableRef={tableRef}
          list={list}
          setList={setList}
          ownerLocation={ownerLocation}
          page={page}
          spaceTypes={spaceTypes}
          spaceType={spaceType}
          setSpaceType={setSpaceType}
          setPage={setPage}
          page_size={page_size}
          setPage_size={setPage_size}
          location={location}
          setLocation={setLocation}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          refetch={refetch}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          setOptions={setOptions}
        />
      ) : (
        <Loader></Loader>
      )}
    </>
  );
};

export default OccupancyReportTable;
