import React from "react";
export const ZoomMaximise = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-arrows-maximize"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    strokeWidth="2"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M16 4l4 0l0 4" />
    <path d="M14 10l6 -6" />
    <path d="M8 20l-4 0l0 -4" />
    <path d="M4 20l6 -6" />
    <path d="M16 20l4 0l0 -4" />
    <path d="M14 14l6 6" />
    <path d="M8 4l-4 0l0 4" />
    <path d="M4 4l6 6" />
  </svg>
);
export const ZoomMinimize = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-arrows-minimize"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    strokeWidth="2.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M5 9l4 0l0 -4" />
    <path d="M3 3l6 6" />
    <path d="M5 15l4 0l0 4" />
    <path d="M3 21l6 -6" />
    <path d="M19 9l-4 0l0 -4" />
    <path d="M15 9l6 -6" />
    <path d="M19 15l-4 0l0 4" />
    <path d="M15 15l6 6" />
  </svg>
);
export const WheelZoom = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-zoom-pan"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    strokeWidth="2.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
    <path d="M17 17l-2.5 -2.5" />
    <path d="M10 5l2 -2l2 2" />
    <path d="M19 10l2 2l-2 2" />
    <path d="M5 10l-2 2l2 2" />
    <path d="M10 19l2 2l2 -2" />
  </svg>
);
export const ArrowBack = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="m2.87 7.75l1.97 1.97a.75.75 0 1 1-1.06 1.06L.53 7.53L0 7l.53-.53l3.25-3.25a.75.75 0 0 1 1.06 1.06L2.87 6.25h9.88a3.25 3.25 0 0 1 0 6.5h-2a.75.75 0 0 1 0-1.5h2a1.75 1.75 0 1 0 0-3.5H2.87Z"
      clip-rule="evenodd"
    />
  </svg>
);
export const ArrowSelect = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-pointer"
    width="30"
    height="30"
    viewBox="0 0 24 24"
    strokeWidth="2.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M7.904 17.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l4.907 4.907a1.067 1.067 0 0 0 1.509 0l1.047 -1.047a1.067 1.067 0 0 0 0 -1.509l-4.907 -4.907l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563z" />
  </svg>
);

export const Next = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-chevron-right"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M9 6l6 6l-6 6" />
  </svg>
);
export const Previous = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-chevron-left"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M15 6l-6 6l6 6" />
  </svg>
);
export const Edit = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 48 48"
  >
    <rect x="0" y="0" width="48" height="48" fill="none" stroke="none" />
    <g
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="4"
    >
      <path d="M9.583 27.182C7.867 28.354 7.009 30.293 7.009 33c0 4.06 4.991 11 9.492 11h11.515c4.405 0 7.08-3.85 7.08-6.94V24.6a3.253 3.253 0 0 0-3.243-3.253a3.235 3.235 0 0 0-3.245 3.226v.11" />
      <path d="M10.981 29.445V7.662a3.217 3.217 0 0 1 6.435 0v15.986" />
      <path
        stroke-linejoin="round"
        d="M17.416 24v-4.192a2.804 2.804 0 0 1 5.608 0v4.62"
      />
      <path
        stroke-linejoin="round"
        d="M23 24.658v-2.85a2.804 2.804 0 0 1 5.608 0v3.195"
      />
      <path d="M11 8h30" />
      <path
        stroke-linejoin="round"
        d="m36 12.5l1.667-1.5L41 8l-3.333-3L36 3.5"
      />
    </g>
  </svg>
);
export const View = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 24"
  >
    <defs>
      <clipPath id="lineMdWatchLoop0">
        <rect width="24" height="12" />
      </clipPath>
      <symbol id="lineMdWatchLoop1">
        <path
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M23 16.5C23 10.4249 18.0751 5.5 12 5.5C5.92487 5.5 1 10.4249 1 16.5z"
          clip-path="url(#lineMdWatchLoop0)"
        >
          <animate
            attributeName="d"
            dur="6s"
            keyTimes="0;0.07;0.93;1"
            repeatCount="indefinite"
            values="M23 16.5C23 11.5 18.0751 12 12 12C5.92487 12 1 11.5 1 16.5z;M23 16.5C23 10.4249 18.0751 5.5 12 5.5C5.92487 5.5 1 10.4249 1 16.5z;M23 16.5C23 10.4249 18.0751 5.5 12 5.5C5.92487 5.5 1 10.4249 1 16.5z;M23 16.5C23 11.5 18.0751 12 12 12C5.92487 12 1 11.5 1 16.5z"
          />
        </path>
      </symbol>
      <mask id="lineMdWatchLoop2">
        <use href="#lineMdWatchLoop1" />
        <use href="#lineMdWatchLoop1" transform="rotate(180 12 12)" />
        <circle cx="12" cy="12" r="0" fill="#fff">
          <animate
            attributeName="r"
            dur="6s"
            keyTimes="0;0.03;0.97;1"
            repeatCount="indefinite"
            values="0;3;3;0"
          />
        </circle>
      </mask>
    </defs>
    <rect
      width="24"
      height="24"
      fill="currentColor"
      mask="url(#lineMdWatchLoop2)"
    />
  </svg>
);
