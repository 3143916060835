import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Pie } from "react-chartjs-2";

const CardForMeeting = ({ data }) => {
  const labels = data?.bookings?.map((booking) => booking.weekday);
  const bookingCounts = data?.bookings?.map((booking) => booking.booking_count);

  // Colors for each day of the week
  const colors = [
    "#FF6384", // Red
    "#36A2EB", // Blue
    "#FFCE56", // Yellow
    "#4BC0C0", // Aqua
    "#9966FF", // Purple
    "#FF8C00", // Dark Orange
    "#32CD32", // Lime Green
    // Add more colors if needed
  ];

  // Data for the pie chart
  const chartData = {
    labels: labels,
    datasets: [
      {
        data: bookingCounts,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };

  // Options for the pie chart
  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const body = (
    <>
      <div
        onClick={openModal}
        id="DataTables_Table_1_wrapper"
        className="dataTables_wrapper dt-bootstrap5 no-footer pb-5"
      >
        <div className="container-fluid ">
          <Pie data={chartData} options={options} />
        </div>
      </div>
    </>
  );
  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 mb-4">
        {" "}
        <div className="card card-datatable text-nowrap ">
          {" "}
          <div className="card-header">
            {data?.meetingRoomName || data?.meeting_room}
          </div>
          {body}
        </div>
      </div>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        className="w-10"
      >
        <Modal.Header closeButton className="h-0">
          {data?.meetingRoomName || data?.meeting_room}
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
      </Modal>
    </>
  );
};

export default CardForMeeting;
