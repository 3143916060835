import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import {
  useVendorCategoryCreateMutation,
  useVendorCategoryUpdateMutation
} from '../../Slices/spaceApi'
import Swal from 'sweetalert2'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { isFirstCharacterDigit } from '../../utils/validation'

const VendorCategoryAdd = ({ handleClose, operateId, operateObject }) => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const [input, setInput] = useState({ name: '' })
  const [validated, setValidated] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [addVendorCategory, result] = useVendorCategoryCreateMutation()
  const [updateVendorCategory, updated] = useVendorCategoryUpdateMutation()
  const handleInput = e => {
    setInput(prevInput => ({
      ...prevInput,
      [e.target.name]: e.target.value
    }))
  }
  useEffect(() => {
    operateId && operateObject ? setInput(operateObject) : console.log('')
  }, [operateId, operateObject])
  useEffect(() => {
    if (result?.isSuccess || updated.isSuccess) {
      handleClose()
      {
        !operateId
          ? toast.success('Vendor Category Added Successfully', {
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff'
              }
            })
          : toast.success('Vendor Category Updated Successfully', {
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff'
              }
            })
      }

      {
      }
    }
  }, [result, updated])
  const handleAdd = event => {
    setIsSubmit(true)
    const form = event.currentTarget
    //.log(form.checkValidity())
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
    } else {
      setValidated(false)
      !operateId
        ? addVendorCategory({
            spaceId: spaceId || currentSpaceId,
            input
          })
        : updateVendorCategory({
            spaceId: spaceId || currentSpaceId,
            id: operateId,
            input
          })
    }
  }
  useEffect(() => {
    if (result.isError) {
      const firstError = result.error.data.error
      Swal.fire({
        title: 'Required!',
        icon: 'warning',
        html: firstError,
        confirmButtonText: 'OK'
      })
    }
    if (updated.isError) {
      const firstError = updated.error.data.error
      Swal.fire({
        title: 'Required!',
        icon: 'warning',
        html: firstError,
        confirmButtonText: 'OK'
      })
    }
  }, [result, updated])
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='w-10'
      >
        <Modal.Header closeButton className=''>
          <Modal.Title as='h5'>
            {operateId ? 'Update Vendor Category' : 'Add Vendor Category'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAdd}>
            <Row className='mb-3 mt-3'>
              <Form.Group as={Col} controlId='vendorcategory'>
                <Form.Label className='mb-2'> Category Name</Form.Label>
                <Form.Control
                  className='viewonly'
                  required
                  type='text'
                  pattern='[A-Za-z].*'
                  placeholder='Vendor Category'
                  //=''
                  value={input.name}
                  name='name'
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type='invalid'>
                    {!input.name ? (
                      'This field is Required'
                    ) : isFirstCharacterDigit(input.name) ? (
                      'Must start with an alphabet'
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>

            {result.isLoading || updated.isLoading ? (
              <>
                <Button className='btn btn-primary border border-secondary'>
                  Loading...{' '}
                  <Spinner
                    animation='border'
                    size='sm'
                    role='status'
                    variant='primary'
                    aria-hidden='true'
                  />
                </Button>
              </>
            ) : (
              <Button
                className='btn btn-primary border border-secondary mr-3'
                type='submit'
              >
                {operateId ? 'Update  Category' : 'Add Category'}
              </Button>
            )}
            <Button className='btn btn-secondary' onClick={handleClose}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default VendorCategoryAdd
