import React, { useState, useEffect } from "react";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { Link } from "react-router-dom";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { useSelector } from "react-redux";
import {
  useCreateCancellationPolicyMutation,
  useCreatePackageDurationMutation,
  useUpdateCancellationPolicyMutation,
  useUpdatePackageDurationMutation,
} from "../../Slices/spaceApi";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { useGlobalContext } from "../../Context/GlobalContext";
import * as Icon from "react-bootstrap-icons";
const DurationModal = ({
  policyData: initialData,

  title,
  submitButtonText,
  onCloseModal,
  onOpenModal,
}) => {
  const { employessPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [createDuration, resultStats] = useCreatePackageDurationMutation();
  const [updateDuration, resultStats1] = useUpdatePackageDurationMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const [apiSpinner, setApiSpinner] = useState(false);

  const [policyData, setpolicyData] = useState({
    spaceDuration: "",
    day: "",
  });

  const [show, setShow] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    spaceDuration: "",
    // email: "",
    day: "",
  });

  useEffect(() => {
    if (resultStats1.isError == false && initialData?.id) {
      setpolicyData(initialData);
    }
  }, [initialData]);

  const handleClose = () => {
    setShow(false);
    onCloseModal();
    setErrorMessage("");

    setValidationErrors({
      spaceDuration: "",
      // email: "",
      day: "",
    });
    setpolicyData({
      spaceDuration: "",
      day: "",
    });
  };
  const handleOpen = () => {
    setShow(true);
    onOpenModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name == "spaceDuration") {
      if (value.length <= 100) {
        setpolicyData({
          ...policyData,
          [name]: value,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: "",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: "Max Name Limit is 100 Character",
        });
      }
    } else if (name === "day") {
      setpolicyData({
        ...policyData,
        [name]: parseFloat(value),
      });
    } else {
      setpolicyData({
        ...policyData,
        [name]: value,
      });
    }
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const handleSubmitAdd = (event) => {
    event.preventDefault();
    const { id, spaceDuration, day } = policyData;

    let formIsValid = true;

    const newValidationErrors = {
      spaceDuration: spaceDuration ? "" : "Enter Duration Name",
      // email: email ? "" : "Please Enter Email",
      day: day ? "" : "Please Enter Days",
      // team: team ? "" : "Please Select Team",
    };

    Object.keys(newValidationErrors).forEach((fieldName) => {
      if (newValidationErrors[fieldName] !== "") {
        formIsValid = false;
      }
    });
    if (formIsValid) {
      let updateData = {
        id,
        spaceDuration,
        day,
      };
      setApiSpinner(true);
      createDuration({
        spaceId: spaceId || currentSpaceId,
        data: updateData,
      });
    } else {
      setValidationErrors(newValidationErrors);
    }
  };

  const updatepolicyData = (event) => {
    event.preventDefault();
    const { id, spaceDuration, day } = policyData;
    let formIsValid = true;
    const newValidationErrors = {
      spaceDuration: spaceDuration ? "" : "Enter Duration Name",
      day: day ? "" : "Please Enter Days",
    };

    Object.keys(newValidationErrors).forEach((fieldName) => {
      if (newValidationErrors[fieldName] !== "") {
        formIsValid = false;
      }
    });
    if (formIsValid) {
      let updateData = {
        id,
        spaceDuration,
        day,
      };

      setApiSpinner(true);
      updateDuration({
        spaceId: spaceId || currentSpaceId,
        durationId: updateData?.id,
        data: updateData,
      });
    } else {
      setValidationErrors(newValidationErrors);
    }
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      requiredAlert(resultStats?.error?.data?.error);
    } else if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Duration Added SuccessFully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      handleClose();
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats1?.error?.data?.error);
    } else if (resultStats1?.isSuccess) {
      setApiSpinner(false);

      toast.success("Duration Updated Successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      handleClose();
    }
  }, [resultStats1]);

  return (
    <>
      {title === "Add Duration" ? (
        <Button
          variant="primary"
          onClick={employessPermissions[0]?.create ? handleOpen : null}
          className="send-invoice-btn"
        >
          {employessPermissions[0]?.create ? (
            <span className="invoice">
              <Plus /> {title}
            </span>
          ) : (
            <span className="invoice">
              <Plus /> {title}
              <Lock />
            </span>
          )}
        </Button>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              {employessPermissions[0]?.update ? (
                "Update Duration"
              ) : (
                <span>
                  Update <Lock />
                </span>
              )}
            </Tooltip>
          }
        >
          <Button
            variant="white"
            className="mx-2 p-0"
            onClick={employessPermissions[0]?.update ? handleOpen : null}
          >
            <EditIcon />
          </Button>
        </OverlayTrigger>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={
            title === "Add Duration" ? handleSubmitAdd : updatepolicyData
          }
        >
          <Modal.Body>
            <section>
              <div>
                <Form.Group className="mb-2 mt-2">
                  <label htmlFor="spaceDuration">Duration Title</label>
                  <input
                    type="text"
                    name="spaceDuration"
                    className="form-control"
                    placeholder="Weekly"
                    value={policyData?.spaceDuration}
                    onChange={handleInputChange}
                  />
                  {validationErrors.spaceDuration && (
                    <small style={{ color: "red", marginLeft: "5px" }}>
                      *{validationErrors.spaceDuration}
                    </small>
                  )}
                </Form.Group>

                <Form.Group className="mb-2">
                  <label htmlFor="day">Days</label>

                  <input
                    type="number"
                    name="day"
                    className="form-control"
                    placeholder="Enter Days"
                    value={policyData?.day}
                    onChange={handleInputChange}
                    min={0}
                    // step="0.01"
                  />

                  {validationErrors.day && (
                    <small style={{ color: "red", marginLeft: "5px" }}>
                      *{validationErrors?.day}
                    </small>
                  )}
                </Form.Group>
                {/* <Form.Group className="mb-2">
                  <label htmlFor="deductionRate">Deduction Rate</label>

                  <input
                    type="number"
                    name="deductionRate"
                    className="form-control"
                    placeholder="Enter Deduction Rate"
                    value={policyData?.deductionRate}
                    onChange={handleInputChange}
                    min={0}
                    step="0.01"
                  />

                  {validationErrors?.deductionRate && (
                    <small style={{ color: "red", marginLeft: "5px" }}>
                      *{validationErrors?.deductionRate}
                    </small>
                  )}
                </Form.Group> */}
              </div>
            </section>
            <div className="mt-4">
              <Button
                variant="primary"
                disabled={
                  apiSpinner || !policyData?.spaceDuration || !policyData?.day
                  //   !policyData?.deductionRate
                }
                type="submit"
              >
                {submitButtonText}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                style={{
                  backgroundColor: "grey !important",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default DurationModal;
