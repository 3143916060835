import Cookie from "js-cookie"

const SetCookie = (cookiename, usrin, expires = 15) => {
    const options = {
        secure: true,
        sameSite: "strict",
        path: "/"
    };
    if (expires !== null) {
        options.expires = expires;
    }
    Cookie.set(cookiename, usrin, options);
}

export const SetSessionCookie = (cookiename, usrin) => {
    Cookie.set(cookiename, usrin, {
        secure: true,
        sameSite: "strict",
        path: "/"
    })
}

export default SetCookie