import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import './viewstyle.css'

import Select from 'react-select'
import { useSpaceTypesQuery } from '../../Slices/spaceApi'
import Loader from '../Loader/Loader'

const DiscountView = ({ handleClose, operateId, data }) => {
  const { data: data1 } = useSpaceTypesQuery()
  const [selectedOptions, setSelectedOptions] = useState([])
  useEffect(() => {
    if (data && data1) {
      let selected = data.validOnBookings.map(x => {
        let found = data1.find(obj => obj.id === x) // Correct usage of find()
        return { value: found.id, label: found.name } // Return the found object
      })
      setSelectedOptions(selected)
    }
  }, [data1, data])
  const customMultiValueRemove = () => {
    return null // Return null to hide the remove value sign
  }
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='w-10 '
      >
        <Modal.Header closeButton className=''>
          <Modal.Title>
            <h5>Category</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <>
              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Discount Code:</h6>
                  <p className='mb-0' id='code' name='name'>
                    {data.code}
                  </p>
                </div>
              </Row>

              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Valid Till:</h6>
                  <p className='mb-0' id='valid' name='name'>
                    {data.validTill}
                  </p>
                </div>
              </Row>
              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Amount:</h6>
                  <p className='mb-0' id='amount' name='name'>
                    {data.amount}
                  </p>
                </div>
              </Row>

              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Max Usage:</h6>
                  <p className='mb-0' id='maxusage' name='name'>
                    {data.maxUsage}
                  </p>
                </div>
              </Row>
              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Max Usage Per Customer:</h6>
                  <p className='mb-0' id='maxusage' name='name'>
                    {data.maxUsagePerCustomer}
                  </p>
                </div>
              </Row>

              {/* <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Valid on Booking:</h6>
                </div>
                {data1 && data.validOnBookings && (
                  <>
                    {data.validOnBookings.map((id, index) => {
                      let value = data1.find(item => item.id === id)
                      let name = value ? value.name : '' // Accessing name attribute, default to empty string if not found
                      return (
                        <p
                          className='mb-0'
                          key={id}
                          id='validOnBooking'
                          name='name'
                        >
                          {index + 1}: {name}
                        </p>
                      )
                    })}
                  </>
                )}
              </Row> */}

              {/* allowedToRegisteredMember */}

              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Fixed Amount?</h6>
                  <p className='mb-0' id='AssettName' name='name'>
                    {data.fixedAmount ? 'Yes' : 'No'}
                  </p>
                </div>
              </Row>
              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Allowed To Registered Member?</h6>
                  <p className='mb-0' id='AssettName' name='name'>
                    {data.allowedToRegisteredMember ? 'Yes' : 'No'}
                  </p>
                </div>
              </Row>

              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>
                    Allowed To Non Registered Member?
                  </h6>
                  <p className='mb-0' id='AssettName' name='name'>
                    {data.allowedToNonRegisteredMember ? 'Yes' : 'No'}
                  </p>
                </div>
              </Row>
              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Is this Valid On Products?</h6>
                  <p className='mb-0' id='AssettName' name='name'>
                    {data.validOnProducts ? 'Yes' : 'No'}
                  </p>
                </div>
              </Row>

              <Row className='mt-3'>
                <Form.Label>
                  <h6>Valid On Spaces</h6>
                </Form.Label>
                <Select
                  value={selectedOptions}
                  // onChange={handleMultiChange}
                  isDisabled
                  className=''
                  isClearable={false}
                  options={selectedOptions}
                  isMulti
                  components={{ MultiValueRemove: customMultiValueRemove }}
                  styles={{
                    control: provided => ({
                      ...provided,
                      backgroundColor: 'transparent' // Set background color to transparent
                    })
                  }}
                />
              </Row>
              {/*  */}
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DiscountView
