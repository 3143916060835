import React from "react";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import { Modal, Button, Form } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useState } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";

const TeamDataView = ({
  profileImage,
  name,
  email,
  status,
  locations,
  more_info,
  Locations,
}) => {
  const { employessPermissions } = useGlobalContext();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {employessPermissions[0]?.read ? (
              "View Team"
            ) : (
              <span>
                View
                <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          onClick={employessPermissions[0]?.read ? handleShow : null}
        >
          <Preview style={{ width: "24px", height: "24px" }} />
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Team Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {profileImage && (
            <div className="media justify-content-center">
              <div className="media-aside">
                <img
                  src={
                    profileImage ||
                    "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                  }
                  alt=""
                  className="rounded-circle border"
                  style={{ width: "20rem", height: "20rem" }}
                />
              </div>
            </div>
          )}
          {name && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Name: <span className="text-muted">{name}</span>
                </h5>
              </div>
            </div>
          )}
          {email && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Email: <span className="text-muted">{email}</span>
                </h5>
              </div>
            </div>
          )}

          {more_info && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  More Info: <span className="text-muted">{more_info}</span>
                </h5>
              </div>
            </div>
          )}

          {/* {locations && (
            <div className="row">
              <div className="col-md-12 form-group d-flex">
                <h5 className=" d-flex">
                  Locations :
                  <span className="text-muted  d-flex">
                    {locations?.map((item) => {
                      if (locations == item.id) {
                        return <div key={item.id}>{item?.name} </div>;
                      }
                      return null;
                    })}
                  </span>
                </h5>
              </div>
            </div>
          )} */}
          {status && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  Status:{" "}
                  <span className="text-muted">
                    {status === true ? "Active" : "Inactive"}
                  </span>
                </h5>
              </div>
            </div>
          )}

          {locations && (
            <div className="row">
              <div className="col-md-12 form-group">
                <h5>
                  <b> Locations :</b>
                </h5>
                <div className="location-list">
                  {locations?.map((locationId) => {
                    const location = Locations?.find(
                      (loc) => loc.id === locationId
                    );
                    if (location) {
                      return (
                        <div className="col-md-12" key={locationId}>
                          <Form.Check
                            type="checkbox"
                            id={locationId}
                            label={location.name}
                            checked
                            readOnly
                            className="pe-none"
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default TeamDataView;
