export function isFirstCharacterDigit(str) {
  console.log(/^\d/.test(str));
  return /^\d/.test(str);
}
export function emailMaxLength(str) {
  return str.length < 50;
}
export function isValidEmail(email) {
  const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexPattern.test(email);
}
export function validPhoneNumber(str) {
  return /^\d{11}$/.test(str);
}
export const isValidAccountNumber = (accountNumber) => {
  // Regular expression to match only numeric characters
  // const numericRegex = /^[0-9]+$/;
  const numericRegex = /^\d{8,24}$/;
  return numericRegex.test(accountNumber);
};
export const isValidInteger = (value) => {
  // Regular expression to match integer values
  const integerRegex = /^-?\d+$/;
  return integerRegex.test(value.toString());
};
