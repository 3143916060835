import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { requiredAlert } from "../../utils/Alerts";
import { useSelector } from "react-redux";
import { useUpdateSpaceMutation } from "../../Slices/spaceApi";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";

const Amenities = ({ data, facilities }) => {
  const { settingsPermissions } = useGlobalContext();

  const constant = facilities?.map((item) => item.id);

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [updateFacilities, resultStats] = useUpdateSpaceMutation();
  const [changed, setChanged] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [values, setValues] = useState({
    facilities: constant || [],
  });

  const handleChange = (e) => {
    const { checked, id } = e.target;

    if (id.startsWith("facility_")) {
      const facilityId = Number(id.replace("facility_", ""));

      if (checked) {
        setValues((prevValues) => ({
          ...prevValues,
          facilities: [...prevValues.facilities, facilityId],
        }));
      } else {
        setValues((prevValues) => ({
          ...prevValues,
          facilities: prevValues.facilities.filter(
            (item) => item !== facilityId
          ),
        }));
      }
    }

    setChanged(true);
  };

  useEffect(() => {
    if (values?.facilities?.length === data?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [values]);

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (e) => {
    const { checked, id } = e.target;

    if (id === "select-all") {
      setSelectAll(checked);
      setValues((prevValues) => ({
        ...prevValues,
        facilities: checked ? data.map((facility) => facility.id) : [],
      }));
    }

    setChanged(true);
  };

  const handleInput = (e) => {
    e.preventDefault();
    setApiCall(true);
    updateFacilities({
      spaceId: spaceId || currentSpaceId,
      data: values,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiCall(false);
      requiredAlert(resultStats?.error?.data?.message);
    }
    if (resultStats?.isSuccess) {
      setApiCall(false);

      toast.success("Amenities Updated Successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setChanged(false);
    }
  }, [resultStats]);
  return (
    <>
      <div className="card">
        <Form
          className="Amenities p-4"
          onSubmit={settingsPermissions[0]?.update ? handleInput : null}
        >
          <h2 className="text-d">
            <img
              loading="lazy"
              src="https://d2w68ocb6l47bj.cloudfront.net/v20220818/_redesign/img/img_amenities_icon.svg"
              alt="icon"
              width="26px"
              height="24px"
              style={{ verticalAlign: "baseline" }}
            />
            Amenities
          </h2>

          <div className="mb-2">
            <Form.Check
              type="checkbox"
              id="select-all"
              label={<b>Select All</b>}
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
          </div>

          {/* {data?.length > 0 && (
            <div className="row mb-4">
              {data?.map((item, index) => {
                const facilityId = `facility_${item.id}`; // Generate a unique id for each facility checkbox
                return (
                  <div className="col-md-4" key={item.id}>
                    <Form.Check
                      type="checkbox"
                      value={item.name}
                      defaultChecked={facilities?.find(
                        (uff) => uff.name === item.name
                      )}
                      onChange={handleChange}
                      id={facilityId} // Use the unique id for each facility checkbox
                      label={item.name}
                    />
                  </div>
                );
              })}
            </div>
          )} */}

          {data?.length > 0 && (
            <div className="row mb-4">
              {data?.map((item, index) => {
                const facilityId = `facility_${item.id}`;
                return (
                  <div className="col-md-4" key={item.id}>
                    <Form.Check
                      type="checkbox"
                      value={item.name}
                      checked={values?.facilities.includes(item.id)}
                      onChange={handleChange}
                      id={facilityId}
                      label={item.name}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <div className="row">
            <div className="col-md-3">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={
                  !changed || !settingsPermissions[0]?.update || apiCall
                }
              >
                {settingsPermissions[0]?.update ? (
                  <>
                    Save Changes
                    {apiCall ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "5px",
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <span>Save Changes{Lock2}</span>
                )}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Amenities;
