import React from "react";
import { Dropdown, Form } from "react-bootstrap";
import { ChevronDown, FileEarmarkArrowUp } from "react-bootstrap-icons";
import Select from "react-select";
import Swal from "sweetalert2";
import {
  copyTableContent,
  downloadPDF,
  printContent,
  tableToCSV,
} from "../../../utils/exports";
const ChartsFilter = ({
  location,
  setLocation,
  time,
  setTime,
  allSpaces,
  selectedOption,
  setSelectedOption,
  setStart,
  setEnd,
  handleChange,
  mySpace,
  tableRef,
  chartExport,
}) => {
  return (
    <>
      <div className=" col-12  ">
        <div className="row mt-4">
          <div className={`col-sm-12`}>
            <Select
              isMulti
              name="colors"
              placeholder={mySpace?.name}
              options={allSpaces?.spaces || []}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              // className='basic-multi-select  custom-select'
              // classNamePrefix='select'
              onChange={(selectedOptions, actionMeta) =>
                handleChange(selectedOptions, setSelectedOption)
              }
              value={selectedOption}
            />
          </div>
        </div>
      </div>

      <hr></hr>
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-9 ">
            <div className=" row align-items-end">
              <div className="col-12 col-lg-5 d-flex align-items-center mt-2 mt-lg-0">
                <Form.Label htmlFor="start" className="mb-0 mr-2  min-w-4  ">
                  From:
                </Form.Label>
                <Form.Control
                  type="date"
                  name="start"
                  id="start"
                  value={time.start}
                  max={time.end}
                  onChange={(e) =>
                    setTime((prev) => ({ ...prev, start: e.target.value }))
                  }
                />
              </div>
              <div className="col-12 col-lg-5 d-flex align-items-center mt-2 mt-lg-0">
                <Form.Label
                  htmlFor="end"
                  className="mb-0 mr-2 ml-2 min-w-4 p-2"
                >
                  To:
                </Form.Label>
                <Form.Control
                  type="date"
                  name="end"
                  id="end"
                  value={time.end}
                  min={time.start}
                  onChange={(e) =>
                    setTime((prev) => ({ ...prev, end: e.target.value }))
                  }
                />
              </div>
              <div className="col-lg-2 col-12   mt-2 mt-lg-0 align-items-end d-flex flex-column-reverse ">
                <div
                  className="btn btn-primary "
                  onClick={(e) => {
                    if (
                      (time.start && time.end) ||
                      (!time.start && !time.end)
                    ) {
                      setStart(time.start);
                      setEnd(time.end);
                    } else {
                      Swal.fire({
                        title: "Required!",
                        icon: "warning",
                        html: "Enter Valid Start and End Time",
                        confirmButtonText: "OK",
                      });
                    }
                  }}
                >
                  Filter
                </div>
              </div>
            </div>
          </div>

          {chartExport && tableRef && (
            <div
              className={`col-12  col-md-3  text-end 
             
            `}
            >
              <Dropdown className="btn-group ">
                <Dropdown.Toggle
                  variant="secondary"
                  className="btn-label-primary"
                  id="dropdown-basic-button"
                  aria-haspopup="dialog"
                  aria-expanded="true"
                >
                  <i className="ti ti-file-export me-sm-1">
                    <FileEarmarkArrowUp width={15} height={15} />
                  </i>
                  <span className="d-none d-sm-inline-block text-align-center">
                    Export
                  </span>
                  <span className="">
                    <ChevronDown width={15} height={15} />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div
                    onClick={() => printContent(tableRef)}
                    className="dropdown-item"
                  >
                    Print
                  </div>

                  <div
                    className="dropdown-item"
                    onClick={() => tableToCSV(tableRef)}
                  >
                    CSV
                  </div>

                  <div
                    className="dropdown-item"
                    onClick={() => downloadPDF(tableRef)}
                  >
                    PDF
                  </div>
                  <div
                    onClick={() => copyTableContent(tableRef)}
                    className="dropdown-item"
                  >
                    Copy
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default ChartsFilter;
