import React, { useEffect } from "react";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import {
  useDeleteCommpanyMutation,
  useDeleteGroupMutation,
  useDeleteUserForSpaceMutation,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useState } from "react";

const DeleteKisiGroup = ({ id, name }) => {
  const { membersPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [deleteGroup, resultStats] = useDeleteGroupMutation();

  const DeleteKisiGroup = (e) => {
    e.preventDefault();
    confirmationAlert("Want to Delete This Group?").then((willDelete) => {
      if (willDelete) {
        setApiSpinner(true);
        deleteGroup({ spaceId: spaceId || currentSpaceId, groupId: id });
      }
    });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert(`The Group is Deleted Successfully.`);
      setTimeout(() => {
        setApiSpinner(false);
      }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
      setTimeout(() => {
        setApiSpinner(false);
      }, 500);
    }
  }, [resultStats]);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {membersPermissions[0]?.delete ? (
              "Delete Group"
            ) : (
              <span>
                Delete <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          onClick={membersPermissions[0]?.delete ? DeleteKisiGroup : null}
          disabled={apiSpinner}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default DeleteKisiGroup;
