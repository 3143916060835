import React, { useState } from "react";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import { Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const ViewTeam = ({ id }) => {
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" style={{ transition: "none" }}>
            Team's Details
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          as={Link}
          to={`/team/${id}/view`}
          style={{ transition: "none" }}
        >
          <Preview />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default ViewTeam;
