import React, { useEffect } from "react";
// import "./index.css";
import {
  TabProfile,
  TabPassword,
  TabInformation,
  Tabs,
  Tab,
} from "../AddSettings";
import { Info, Lock, Manage, Team } from "../../Assets/MemberSvgs/Icon";
import { User } from "../../Assets/MemberSvgs/Icon";
import { useGetSpaceDataQuery } from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import {
  useGetCitiesQuery,
  useGetCountiresQuery,
  useGetFacilitiesQuery,
} from "../../Slices/constantApi";
import { Toaster } from "react-hot-toast";
import Loader from "../Loader/Loader";
import ManageAccount from "../AddSettings/ManageAccount";
import RolesAndPermissions from "../AddSettings/RolesAndPermissions";
import Employees from "./Employees";
import Teams from "./Teams";
import UserPasswordSetup from "../LoginForgetpsw/UserPasswordSetup";
import { useGlobalContext } from "../../Context/GlobalContext";
import PermissionPage from "../MainShared/PermissionPage";
import * as Icon from "react-bootstrap-icons";
const PeoplesManagement = () => {
  const { employessPermissions, teamsPermissions } = useGlobalContext();
  const { memberKey, setMemberKey } = useGlobalContext();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Peoples' Management");
  }, []);
  // const { currentSpaceId, spaceId } = useSelector((item) => item.spaceReducer);
  // const { data: spaceData, isLoading } = useGetSpaceDataQuery(
  //   spaceId || currentSpaceId
  // );
  // const { data: facilities, isLoading: loadingFacilities } =
  //   useGetFacilitiesQuery();
  // const { data: cities, isLoading: loadingCities } = useGetCitiesQuery();
  // const { data: countries, isLoading: loadingCountries } =
  //   useGetCountiresQuery();

  useEffect(() => {
    setMemberKey("Employee");
  }, []);
  // if (isLoading || loadingFacilities || loadingCities || loadingCountries) {
  //   return <Loader height={6.6} />;
  // }

  return (
    <>
      <section className="container-fluid app-user-list with-bottom-padding">
        <Tabs
          defaultActiveKey={memberKey}
          id="justify-tab-example"
          className="mb-3"
          variant="underline"
          justify
        >
          {employessPermissions[0]?.read ? (
            <Tab
              style={{ marginRight: "5px" }}
              eventKey="Employee"
              title={
                <span className="d-flex align-items-center">
                  <Icon.Person /> &nbsp;Employees
                </span>
              }
            >
              <Employees />
            </Tab>
          ) : (
            <Tab
              style={{ marginRight: "5px" }}
              eventKey="Employee"
              title={
                <span className="d-flex align-items-center">
                  <Icon.Person /> &nbsp;Employees{Lock}
                </span>
              }
            >
              <PermissionPage />
            </Tab>
          )}
          {teamsPermissions[0]?.read ? (
            <Tab
              eventKey="Team"
              title={
                <span className="d-flex align-items-center">
                  <Icon.People /> &nbsp;Team
                </span>
              }
            >
              <Teams />
            </Tab>
          ) : (
            <Tab
              eventKey="Team"
              title={
                <span className="d-flex align-items-center">
                  <Icon.People /> &nbsp;Team{Lock}
                </span>
              }
            >
              <PermissionPage />
            </Tab>
          )}
        </Tabs>
        {/* <Toaster position="top-right" reverseOrder={false} /> */}
      </section>
    </>
  );
};

export default PeoplesManagement;
