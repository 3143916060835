import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import {
  useVendorCategoryListQuery,
  useVendorGetQuery
} from '../../Slices/spaceApi'
import Loader from '../Loader/Loader'
import VendorContext from '../../Context/VendorContext'
import { useSelector } from 'react-redux'

const VendorView = ({ handleClose, operateId, operateObject: data }) => {
  // const { data, isLoading, refetch } = useVendorGetQuery({
  //   spaceId: 1,
  //   id: operateId
  // })
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const [step, setStep] = useState(1)
  const handlePrev = () => {
    setStep(prevState => {
      return prevState - 1
    })
  }
  // const { categoryList: data1 } = useContext(VendorContext);
  const { data: data1, isLoading } = useVendorCategoryListQuery({
    spaceId: spaceId || currentSpaceId
    // page_size: pageSize,
    // page: pageNumber,
    // name: searchValue,
  })
  const [category, setCategory] = useState([])
  useEffect(() => {
    if (data1?.categories?.length !== 0) {
      const updatedArray = data1?.categories?.map(({ id, name }) => ({
        value: id,
        label: name
      }))
      setCategory(updatedArray)
    }
  }, [data1, isLoading])

  const handleAdd = event => {
    const form = event.currentTarget
    //.log(form.checkValidity())
    event.preventDefault()

    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      if (step === 4) {
      } else {
        setStep(prevStep => {
          return prevStep + 1
        })
      }
    }
  }

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='w-10 '
      >
        <Modal.Header closeButton className=''>
          <Modal.Title>
            <h5>Vendor</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <>
              <Form noValidate onSubmit={handleAdd}>
                {step === 1 ? (
                  <>
                    <h5>Personal Information</h5>
                    <Row className='mb-2'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Vendor Name:</h6>
                        <p className='mb-0 viewonly'>{data?.vendor_name}</p>
                      </div>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Contact Person Name:</h6>
                        <p className='mb-0 viewonly'>
                          {data?.contact_person_name}
                        </p>
                      </div>
                    </Row>

                    <Row className='mb-3'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Category:</h6>
                        <p className='mb-0 viewonly'>
                          {data.vendor_category
                            ? category.find(
                                item => item.value === data.vendor_category
                              )?.label || '-'
                            : '-'}
                        </p>
                      </div>
                    </Row>

                    <Row className='mb-3'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Email:</h6>
                        <p className='mb-0 viewonly'>{data.email}</p>
                      </div>
                    </Row>
                    <Row className='mb-3'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Phone Number:</h6>
                        <p className='mb-0 viewonly'>{data.phone_number}</p>
                      </div>
                    </Row>
                    <Row className='mb-3'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Physical Address:</h6>
                        <p className='mb-0 viewonly'>{data.physical_address}</p>
                      </div>
                    </Row>

                    <Button type='submit'>Next</Button>
                  </>
                ) : step === 2 ? (
                  <>
                    <h5>Financial Information </h5>
                    <Row className='mb-2'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>
                          Tax Identification Number:
                        </h6>
                        <p className='mb-0 viewonly'>
                          {data.tax_identification_number}
                        </p>
                      </div>
                    </Row>
                    <Row className='mb-2'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Account Number:</h6>
                        <p className='mb-0 viewonly'>{data.account_number}</p>
                      </div>
                    </Row>

                    <Row className='mb-2'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>
                          Business Registration Number:
                        </h6>
                        <p className='mb-0 viewonly'>
                          {data.business_registration_number}
                        </p>
                      </div>
                    </Row>
                    <Row className='mb-2'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Date of Registration:</h6>
                        <p className='mb-0 viewonly'>
                          {data.date_of_registration}
                        </p>
                      </div>
                    </Row>

                    <Row className='mb-2'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Routing Number:</h6>
                        <p className='mb-0 viewonly'>{data.routing_number}</p>
                      </div>
                    </Row>
                    <Row className='mb-2'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Vendor Account Number:</h6>
                        <p className='mb-0 viewonly'>
                          {data.vendor_account_number}
                        </p>
                      </div>
                    </Row>

                    <Row className='mb-3'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Bank Name:</h6>
                        <p className='mb-0 viewonly'>{data.bank_name}</p>
                      </div>
                    </Row>

                    <Row className='mb-3'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Legal Business Name:</h6>
                        <p className='mb-0 viewonly'>
                          {data.legal_business_name}
                        </p>
                      </div>
                    </Row>

                    <Row className='mb-3'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>Payment Terms:</h6>
                        <p className='mb-0 viewonly'>{data.payment_terms}</p>
                      </div>
                    </Row>

                    <Button onClick={handlePrev} className='mr-3'>
                      Prev
                    </Button>

                    <Button type='submit'>Next</Button>
                  </>
                ) : step === 3 ? (
                  <>
                    {' '}
                    <h5>Additional Information</h5>
                    <Row className='mb-3'>
                      <div className='d-flex align-items-center'>
                        <h6 className='me-3 mb-0'>
                          Preferred Communication Method:
                        </h6>
                        <p className='mb-0 viewonly'>
                          {data.preferred_communication_method}
                        </p>
                      </div>
                    </Row>
                    <Row className='mb-3'>
                      <Form.Group controlId='contract_information' as={Col}>
                        <Form.Label>
                          <h6>Contract Information</h6>
                        </Form.Label>
                        <p>{data.contract_information}</p>
                      </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                      <Form.Group controlId='insurance_information' as={Col}>
                        <Form.Label>
                          <h6>Insurance Information</h6>
                        </Form.Label>
                        <p>{data.insurance_information}</p>
                      </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                      <Form.Group controlId='notes_comments' as={Col}>
                        <Form.Label>
                          <h6>Notes </h6>
                        </Form.Label>
                        <p>{data.notes_comments}</p>
                      </Form.Group>
                    </Row>
                    <Button
                      onClick={handlePrev}
                      className='btn btn-primary mr-3'
                    >
                      Prev
                    </Button>
                    <Button type='submit btn-primary'>Next</Button>
                  </>
                ) : step === 4 ? (
                  <>
                    <h5>Compliance and Legal</h5>
                    <Row className='mb-3'>
                      <Form.Group controlId='performance_metrics' as={Col}>
                        <Form.Label>
                          <h6>Performance Metrics</h6>
                        </Form.Label>
                        <p>{data.performance_metrics}</p>
                      </Form.Group>
                    </Row>
                    <Row className='mb-3'>
                      <Form.Group controlId='terms_and_conditions' as={Col}>
                        <Form.Label>
                          <h6>Terms And Conditions</h6>
                        </Form.Label>
                        <p>{data.terms_and_conditions}</p>
                      </Form.Group>
                    </Row>
                    <Button onClick={handlePrev}>Prev</Button>

                    {/* <Button
                      className='btn btn-secondary ml-3'
                      onClick={handleClose}
                    >
                      CLose
                    </Button> */}
                  </>
                ) : (
                  <>{step}</>
                )}
              </Form>
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default VendorView
