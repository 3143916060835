import React, { useEffect } from "react";

import { useGetKisiGroupsQuery } from "../../Slices/spaceApi";

import { Nav, Row, Tab, Tabs } from "react-bootstrap";

import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../Context/GlobalContext";
import KisiRole from "./KisiRole";
import KisiAccessGroup from "./KisiAccessGroup";
import KisiAssignableResource from "./KisiAssignableResources";
import KisiBookableResources from "./KisiBookableResources";
import KisiSpacePackage from "./KisiSpacePackage";
import KisiMeetingPackage from "./KisiMeetingPackage";
import KisiFloorPlanGroup from "./KisiFloorPlansGroup";
import KisiDayPass from "./KisiDayPass";
const KisiIntegrationPage = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle, isKisiIntegrated } = useGlobalContext();

  const { data: KisiGroups, isLoading: kisiGroupLoading } =
    useGetKisiGroupsQuery({ spaceId: spaceId || currentSpaceId });

  useEffect(() => {
    setDashBoardTitle("Kisi Integration");
  }, []);
  return (
    <>
      {isKisiIntegrated ? (
        <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
          <Tabs
            defaultActiveKey="Account"
            id="justify-tab-example"
            className="mb-3"
            variant="underline"
            justify
          >
            <Tab
              eventKey="Account"
              title={
                <span className="d-flex align-items-center">
                  <Icon.DoorOpen /> &nbsp;Access Groups
                </span>
              }
            >
              <KisiAccessGroup KisiGroups={KisiGroups} />
            </Tab>
            <Tab
              eventKey="Floorplans"
              title={
                <span className="d-flex align-items-center">
                  <Icon.House /> &nbsp;Floors
                </span>
              }
            >
              <KisiFloorPlanGroup KisiGroups={KisiGroups} />
            </Tab>
            <Tab
              eventKey="SpacePackage"
              title={
                <span className="d-flex align-items-center">
                  <Icon.Box /> &nbsp;Space Packages
                </span>
              }
            >
              <KisiSpacePackage KisiGroups={KisiGroups} />
            </Tab>
            <Tab
              eventKey="MeetingPackage"
              title={
                <span className="d-flex align-items-center">
                  <Icon.PersonWorkspace /> &nbsp;Meeting Packages
                </span>
              }
            >
              <KisiMeetingPackage KisiGroups={KisiGroups} />
            </Tab>
            <Tab
              eventKey="Account1"
              title={
                <span className="d-flex align-items-center">
                  <Icon.PcDisplayHorizontal /> &nbsp;Assignable Resources
                </span>
              }
            >
              <KisiAssignableResource KisiGroups={KisiGroups} />
            </Tab>
            <Tab
              eventKey="kisiBookable"
              title={
                <span className="d-flex align-items-center">
                  <Icon.WindowStack /> &nbsp;Bookable Resources
                </span>
              }
            >
              <KisiBookableResources KisiGroups={KisiGroups} />
            </Tab>

            <Tab
              eventKey="Social"
              title={
                <span className="d-flex align-items-center">
                  <Icon.PersonVideo2 /> &nbsp; Roles
                </span>
              }
            >
              <KisiRole KisiGroups={KisiGroups} />
            </Tab>

            <Tab
              eventKey="Social1"
              title={
                <span className="d-flex align-items-center">
                  <Icon.WindowDesktop /> &nbsp;Day Pass
                </span>
              }
            >
              <KisiDayPass KisiGroups={KisiGroups} />
            </Tab>
          </Tabs>
        </section>
      ) : (
        <h3 className="ml-4">Please integrate Kisi to proceed further.</h3>
      )}
    </>
  );
};

export default KisiIntegrationPage;
