import React, { useContext, useEffect, useRef, useState } from "react";

import { Form } from "react-bootstrap";

import TabularReport from "../TabularReports";
import { useSelector } from "react-redux";
import {
  useAllSpacesQuery,
  useOwnerLocationQuery,
  useBookingByWeekDayReportQuery,
  useSpaceGetQuery,
} from "../../../Slices/spaceApi";
import Loader from "../../Loader/Loader";
import { Pie } from "react-chartjs-2";
import CardForMeeting from "./CardForMeeting";
import ChartsFilter from "../Visitors/ChartsFilter";
import { handleChange } from "../../../utils/sharedReports";

const BookingByWeekDays = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [time, setTime] = useState({ start, end });
  const [selectedOption, setSelectedOption] = useState(null);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  useEffect(() => {
    if (selectedOption) {
      const locationString = selectedOption
        ?.map((option) => option.id)
        .join(",");

      setLocation(locationString);
    } else {
      setLocation("");
    }
  }, [selectedOption]);

  const { data, isLoading, isError } = useBookingByWeekDayReportQuery({
    spaceId: spaceId || currentSpaceId,
    start,
    end,
    location,
  });

  return (
    <div className="container-fluid with-bottom-padding p_16 mt-3">
      <ChartsFilter
        location={location}
        setLocation={setLocation}
        time={time}
        setTime={setTime}
        allSpaces={allSpaces}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        setStart={setStart}
        setEnd={setEnd}
        handleChange={handleChange}
        mySpace={mySpace}
      />
      <div className="row mt-4">
        {!isLoading &&
          data?.map((item, index) => (
            <CardForMeeting key={index} data={item}></CardForMeeting>
          ))}
      </div>
    </div>
  );
};

export default BookingByWeekDays;
// {!isLoading && <Pie data={chartData} options={options} />}
