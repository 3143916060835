import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import {
  useAddOfferMutation,
  useDeleteOfferMutation,
  useDeletePartnerMutation,
  useGetOffersQuery,
  useGetPartnersQuery,
  useUpdateOfferMutation,
  useUpdatePartnerMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import OfferForm from "./OfferForm";
import { useSortableData } from "../../utils/SortingFunction";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

const OfferList = ({ categories }) => {
  const { partnerId } = useParams();
  const { partnerAndOffersPermissions } = useGlobalContext();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Offers");
  }, []);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [searchOffer, setSearchOffer] = useState("");

  const { data, isLoading: loading } = useGetOffersQuery({
    spaceId: spaceId || currentSpaceId,
    partnerId,
  });

  const { data: partners } = useGetPartnersQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedOfferView, setSelectedOfferView] = useState(null);

  const [deleteOffer, resultStats] = useDeleteOfferMutation();
  const [updateOffer, resultStats1] = useUpdateOfferMutation();
  const [createOffer, resultStats2] = useAddOfferMutation();
  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const today = new Date();
  const tomorrow = new Date(today);
  const nextTomorrow = new Date(today);

  tomorrow.setDate(today.getDate() + 1); // Add one day to the current date
  nextTomorrow.setDate(today.getDate() + 2);

  const tomorrowFormatted = tomorrow.toISOString().slice(0, 10); // Format tomorrow's date in YYYY-MM-DD format

  const nextTomorrowFormatted = nextTomorrow.toISOString().slice(0, 10); // Format tomorrow's date in YYYY-MM-DD format

  const initialFormOfferData = {
    partner: partnerId,
    discount_percentage: "",
    start_date: tomorrowFormatted,
    end_date: nextTomorrowFormatted,
    description: "",
    image: "",
    name: "",
  };
  const [file, setFile] = useState(null);

  const [formData, setFormData] = useState(initialFormOfferData);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const [showOffer, setShowOffer] = useState(false);

  const handleCloseOffer = () => {
    setFile(null);
    setShowOffer(false);
  };

  const [showOfferView, setShowOfferView] = useState(false);

  const handleCloseOfferView = () => setShowOfferView(false);

  const handleShowOfferView = (partner) => {
    setShowOfferView(true);
    setSelectedOfferView(partner);
  };

  const handleActionClick = (partner) => {
    setSelectedOffer(partner);
    setFormData({ ...partner, partner: partner.partner.id });
    setShowOffer(true);
  };

  const handleDeleteOffer = (id) => {
    confirmationAlert("Want to delete offer?").then((willDelete) => {
      if (willDelete) {
        deleteOffer({
          spaceId: spaceId || currentSpaceId,
          offerId: id,
        });
      }
    });
  };

  const handleUpdateOffer = (e) => {
    e.preventDefault();

    let dataWithoutImage;

    if (formData?.image.startsWith("https")) {
      // Create a copy of formData excluding the "image" key
      dataWithoutImage = { ...formData };
      delete dataWithoutImage.image;

      //   delete dataWithoutImage.id;
    } else {
      //   delete dataWithoutImage.id;

      // If image does not start with "https", use formData as is
      dataWithoutImage = formData;
    }
    setApiSpinner(true);
    updateOffer({
      spaceId: spaceId || currentSpaceId,
      offerId: selectedOffer.id,
      data: dataWithoutImage,
    });
  };

  const { items, requestSort, sortConfig } = useSortableData(
    data?.offers || []
  );

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Offer Deleted Successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);

      const errorMessage = Object?.values(resultStats1?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats1?.isSuccess) {
      setApiSpinner(false);

      // setTimeout(() => {
      handleCloseOffer();
      // }, 1500);

      toast.success("Offer Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats1]);

  useEffect(() => {
    if (resultStats2?.isError) {
      setApiSpinner(false);

      const errorMessage = Object?.values(resultStats2?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
    if (resultStats2?.isSuccess) {
      setApiSpinner(false);

      // setTimeout(() => {
      handleClose();
      // to reset
      setFormData(initialFormOfferData);
      setFile("");
      // }, 1500);

      toast.success("Offer Added SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats2]);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const filteredItems = items
    // ?.slice()
    // .reverse()
    ?.filter((val) => {
      if (searchOffer == "") {
        return val;
      } else if (
        val?.description?.toLowerCase()?.includes(searchOffer?.toLowerCase())
      ) {
        return val;
      }
    });

  const postOffer = () => {
    setApiSpinner(true);
    createOffer({ data: formData, spaceId: spaceId || currentSpaceId });
  };
  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}`;
  }
  function getRandomColor() {
    const getRandomComponent = () => Math.floor(Math.random() * 128); // Adjusted to generate darker colors
    const color = `rgb(${getRandomComponent()}, ${getRandomComponent()}, ${getRandomComponent()})`;
    return color;
  }
  function getInitials(name) {
    const nameParts = name?.split(" ");
    let initials = "";

    for (let i = 0; i < Math.min(2, nameParts?.length); i++) {
      initials += nameParts[i]?.charAt(0)?.toUpperCase();
    }

    return initials;
  }
  const oneColor = getRandomColor();
  return (
    <div style={{ padding: "0px 16px" }}>
      <Breadcrumb className="custom-breadcrumb ml-auto">
        <Breadcrumb.Item>
          <Link to="/partners">Pertners List</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {partners?.partners?.find((item) => item.id == partnerId)?.name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="card">
        <div className="row" style={{ padding: "1.5rem" }}>
          <div className="col-12 col-sm-6 col-md-4">
            {/* {data?.offers.length > 0 && ( */}
            <div className="media">
              <div className="media-aside mr-2">
                {partners?.partners?.find((item) => item.id == partnerId)
                  ?.image ? (
                  <img
                    className="rounded-circle"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                    }}
                    src={
                      partners?.partners?.find((item) => item.id == partnerId)
                        ?.image
                    }
                    alt={
                      partners?.partners?.find((item) => item.id == partnerId)
                        ?.name
                    }
                  />
                ) : (
                  <div
                    className="rounded-circle"
                    style={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: getRandomColor(),
                      color: "#fff",
                      fontSize: "16px",
                    }}
                  >
                    {getInitials(
                      partners?.partners?.find((item) => item.id == partnerId)
                        ?.name
                    )}
                  </div>
                )}
              </div>
              <div className="media-body my-auto">
                <span className="mb-0 table-avatar d-flex flex-direction-col">
                  {
                    partners?.partners?.find((item) => item.id == partnerId)
                      ?.name
                  }
                </span>
                <span className="mb-0 table-avatar">
                  {
                    partners?.partners?.find((item) => item.id == partnerId)
                      ?.contact_email
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-8">
            <Button
              variant="primary"
              className="send-invoice-btn float-right"
              onClick={
                partnerAndOffersPermissions[0]?.create ? handleShow : null
              }
            >
              <span className="invoice">
                <Icon.Plus />{" "}
                {partnerAndOffersPermissions[0]?.create ? (
                  "Create Offer"
                ) : (
                  <span>
                    Create Offer <Lock />
                  </span>
                )}
              </span>
            </Button>

            <Modal
              show={show}
              onHide={handleClose}
              id="packagePopUp"
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title as="h5">Create Offer </Modal.Title>
              </Modal.Header>
              <form>
                <Modal.Body>
                  <OfferForm
                    formData={formData}
                    setFormData={setFormData}
                    partners={partners?.partners}
                    fromOffer={true}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    file={file}
                    setFile={setFile}
                    partnerId={partnerId}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" disabled onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    disabled={
                      formData?.discount_percentage <= 0 ||
                      apiSpinner ||
                      isUploading
                    }
                    onClick={postOffer}
                  >
                    Create Offer{" "}
                    {apiSpinner ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                        style={{
                          width: "20px",
                          height: "20px",
                          marginLeft: "5px",
                        }}
                      ></div>
                    ) : (
                      ""
                    )}
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </div>
        </div>
        <div className="spacePackage table-responsive">
          <table className="table  table-stripedCustom">
            <thead>
              <tr>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Image</div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Discount Percentage</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("discount_percentage")}
                        className={getClassNamesFor("discount_percentage")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("discount_percentage")}
                        className={getClassNamesFor("discount_percentage")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Start Date</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("start_date")}
                        className={getClassNamesFor("start_date")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("start_date")}
                        className={getClassNamesFor("start_date")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>End Date</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("end_date")}
                        className={getClassNamesFor("end_date")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("end_date")}
                        className={getClassNamesFor("end_date")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={6} className="text-left fs-5">
                    Loading..
                  </td>
                </tr>
              )}
              {!loading && filteredItems?.length > 0 ? (
                filteredItems?.map((item, index) => {
                  let originalIndex = filteredItems.length - index - 1;
                  const {
                    image,
                    discount_percentage,
                    start_date,
                    end_date,
                    id,
                  } = item;
                  return (
                    <tr key={index}>
                      <td>
                        <div className="media">
                          <div className="media-aside mr-2">
                            {/* <img
                              src={image || "https://placehold.jp/90x90.png"}
                              onError={(e) =>
                                (e.target.src =
                                  "https://placehold.jp/90x90.png")
                              }
                              alt=""
                              className="rounded-circle"
                              style={{ width: "38px", height: "38px" }}
                            /> */}
                            {image ? (
                              <img
                                className="rounded-circle"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                                src={image}
                                alt="image"
                              />
                            ) : (
                              <div
                                className="rounded-circle"
                                style={{
                                  width: "38px",
                                  height: "38px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  backgroundColor: oneColor,
                                  color: "#fff",
                                  fontSize: "16px",
                                }}
                              >
                                {getInitials(
                                  partners?.partners?.find(
                                    (item) => item.id == partnerId
                                  )?.name
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>{discount_percentage} %</td>
                      <td>{formatDateTime(start_date)}</td>
                      <td>{formatDateTime(end_date)}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip">
                                {partnerAndOffersPermissions[0]?.read ? (
                                  "View"
                                ) : (
                                  <span>
                                    View <Lock />
                                  </span>
                                )}
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="white"
                              className="p-0"
                              id="viewPackageOpen"
                              onClick={() =>
                                partnerAndOffersPermissions[0]?.read
                                  ? handleShowOfferView(item)
                                  : null
                              }
                            >
                              <Preview />
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip">
                                {" "}
                                {partnerAndOffersPermissions[0]?.update ? (
                                  "Update"
                                ) : (
                                  <span>
                                    Update <Lock />
                                  </span>
                                )}
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="white"
                              className="mx-2 p-0"
                              onClick={() =>
                                partnerAndOffersPermissions[0]?.update
                                  ? handleActionClick(item)
                                  : null
                              }
                            >
                              <EditIcon />
                            </Button>
                          </OverlayTrigger>
                          <div>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  {" "}
                                  {partnerAndOffersPermissions[0]?.delete ? (
                                    "Delete"
                                  ) : (
                                    <span>
                                      Delete <Lock />
                                    </span>
                                  )}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="white"
                                className="p-0"
                                onClick={() =>
                                  partnerAndOffersPermissions[0]?.delete
                                    ? handleDeleteOffer(id)
                                    : null
                                }
                              >
                                <DeleteIcon title="Delete Offer" />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : !loading && filteredItems.length == 0 ? (
                <tr>
                  <td colSpan={5} className="text-left fs-5">
                    {searchOffer == "" ? "No Offers !" : "No offer found"}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>

      {selectedOffer?.start_date && (
        <Modal
          show={showOffer}
          onHide={handleCloseOffer}
          id="packagePopUp"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">Update Offer</Modal.Title>
          </Modal.Header>
          <form>
            <Modal.Body>
              <OfferForm
                setFormData={setFormData}
                formData={formData}
                partners={partners?.partners}
                fromOffer={true}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                file={file}
                setFile={setFile}
                partnerId={partnerId}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" disabled onClick={handleCloseOffer}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={handleUpdateOffer}
                disabled={
                  (!formData?.discount_percentage && !formData?.description) ||
                  apiSpinner ||
                  isUploading
                }
              >
                Update{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}

      {selectedOfferView?.start_date && (
        <Modal
          show={showOfferView}
          onHide={handleCloseOfferView}
          id="packagePopUp"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title as="h5">View Offer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="row">
                <div className="col-md-12 form-group">
                  <img src={selectedOfferView?.image} alt="" width={"100%"} />

                  {selectedOfferView?.image ? (
                    <img
                      // className="rounded-circle"
                      style={{
                        width: "100%",
                        // height: "50px",
                        // objectFit: "cover",
                      }}
                      src={selectedOfferView?.image}
                      alt="image"
                    />
                  ) : (
                    <div
                      className=""
                      style={{
                        width: "100%",
                        // height: "38px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: oneColor,
                        color: "#fff",
                        fontSize: "48px",
                      }}
                    >
                      {getInitials(
                        partners?.partners?.find((item) => item.id == partnerId)
                          ?.name
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 form-group">
                  <h5>
                    Discount Percentage:{" "}
                    <span className="text-muted">
                      {selectedOfferView?.discount_percentage} {" %"}
                    </span>
                  </h5>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 form-group">
                  <h5>
                    Start Date:{" "}
                    <span className="text-muted">
                      {selectedOfferView?.start_date}
                    </span>
                  </h5>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 form-group">
                  <h5>
                    End Date:{" "}
                    <span className="text-muted">
                      {selectedOfferView?.end_date}
                    </span>
                  </h5>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 form-group">
                  <h5>
                    Description:{" "}
                    <span className="text-muted">
                      {selectedOfferView?.description}
                    </span>
                  </h5>
                </div>
              </div>
            </div>
            <Button variant="secondary" onClick={handleCloseOfferView}>
              Cancel
            </Button>
          </Modal.Body>
          {/* <Modal.Footer>
          
          </Modal.Footer> */}
        </Modal>
      )}
    </div>
  );
};

export default OfferList;
