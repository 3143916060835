import React, { useEffect } from "react";
import { AccountSetting } from "../Components/AddSettings/";
import { useGlobalContext } from "../Context/GlobalContext";
export const SettingPage = () => {
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Settings");
  }, []);
  return (
    <div>
      <AccountSetting />
    </div>
  );
};
