import React from "react";

const PackageCalculations = ({
  input,
  isOverAllTax,
  serviceCharges,
  rentalFee,
}) => {
  console.log("Input is", input);
  console.log("Is overall tax is", isOverAllTax);
  console.log("serviceCharges is", serviceCharges);
  console.log("rentalFee is", rentalFee);

  return (
    <>
      {input?.inclusiveTax ? (
        ""
      ) : (
        <div>
          {input?.taxRateObj && !input?.taxRateObj?.invalid ? (
            <div>
              {input?.taxRateObj?.setOveralTax ? (
                <div className="d-flex justify-content-between">
                  <p>Over-All Tax: {input?.taxRateObj?.serviceCharges} %</p>

                  <p>
                    Total Price:{" "}
                    {parseInt(input?.quantity) *
                      (input?.serviceCharges +
                        input?.rentalFee +
                        input?.serviceCharges *
                          (input?.taxRateObj?.serviceCharges / 100) +
                        input?.rentalFee *
                          (input?.taxRateObj?.serviceCharges / 100))}
                  </p>
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <p>Service Tax: {input?.taxRateObj?.serviceCharges} %</p>
                  <p>Rental Tax: {input?.taxRateObj?.rentalFee} %</p>
                  <p>
                    Total Price:{" "}
                    {parseInt(input?.quantity) *
                      (input?.serviceCharges +
                        input?.rentalFee +
                        input?.serviceCharges *
                          (input?.taxRateObj?.serviceCharges / 100) +
                        input?.rentalFee *
                          (input?.taxRateObj?.rentalFee / 100))}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div>
              {isOverAllTax ? (
                <div className="d-flex justify-content-between">
                  <p>Over-All Tax: {serviceCharges} %</p>

                  <p>
                    Total Price:{" "}
                    {parseInt(input?.quantity) *
                      (input?.serviceCharges +
                        input?.rentalFee +
                        input?.serviceCharges * (serviceCharges / 100) +
                        input?.rentalFee * (serviceCharges / 100))}
                  </p>
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <p>Service Tax: {serviceCharges} %</p>
                  <p>Rental Tax: {rentalFee} %</p>
                  <p>
                    Total Price:{" "}
                    {parseInt(input?.quantity) *
                      (input?.serviceCharges +
                        input?.rentalFee +
                        input?.serviceCharges * (serviceCharges / 100) +
                        input?.rentalFee * (rentalFee / 100))}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PackageCalculations;
