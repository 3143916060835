import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Row, Spinner } from 'react-bootstrap'
import {
  useAccountCreateMutation,
  useAccountUpdateMutation,
  useInvoicedProductReportQuery
} from '../../Slices/spaceApi'
import Swal from 'sweetalert2'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { isFirstCharacterDigit } from '../../utils/validation'
// import '../../app.css'
const AccountAdd = ({ handleClose, operateId, operateObject }) => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const [input, setInput] = useState({
    name: '',
    accountCode: '',
    description: '',
    accountType: null
  })
  const [validated, setValidated] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [addAccount, result] = useAccountCreateMutation()
  const [updateAccount, updated] = useAccountUpdateMutation()

  const handleInput = e => {
    setInput(prevInput => ({
      ...prevInput,
      [e.target.name]: e.target.value
    }))
  }
  useEffect(() => {
    if (operateId) {
      setInput(operateObject)
    }
  }, [operateId, operateObject])
  useEffect(() => {
    if (result?.isSuccess) {
      handleClose()
      toast.success('Account Added Successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      })
    }
    if (updated?.isSuccess) {
      handleClose()
      toast.success('Account Updated Successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      })
    }
  }, [result, updated])
  const handleAdd = event => {
    setIsSubmit(true)
    const form = event.currentTarget

    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
    } else {
      setValidated(false)
      !operateId
        ? addAccount({
            spaceId: spaceId || currentSpaceId,
            input
          })
        : updateAccount({
            spaceId: spaceId || currentSpaceId,
            id: operateId,
            input
          })
    }
  }
  useEffect(() => {
    if (result.isError) {
      const firstError = result.error.data.error
      Swal.fire({
        title: 'Required!',
        icon: 'warning',
        html: firstError,
        confirmButtonText: 'OK'
      })
    }
    if (updated.isError) {
      const firstError = updated.error.data.error
      Swal.fire({
        title: 'Required!',
        icon: 'warning',
        html: firstError,
        confirmButtonText: 'OK'
      })
    }
  }, [result, updated])
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='w-10'
      >
        <Modal.Header closeButton className=''>
          <Modal.Title as='h5'>
            {!operateId ? 'Add Account' : 'Update Account'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAdd}>
            <Row className='mb-3 mt-3'>
              <Form.Group controlId='Account'>
                <Form.Label className='mb-2'>
                  <h6>Account Name</h6>
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  required
                  pattern='[A-Za-z].*'
                  type='text'
                  placeholder='Receiving Account '
                  value={input.name}
                  name='name'
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type='invalid'>
                    {!input.name ? (
                      'This field is Required'
                    ) : isFirstCharacterDigit(input.name) ? (
                      'Must start with an alphabet'
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row className='mb-3 mt-3'>
              <Form.Group controlId='Account'>
                <Form.Label className='mb-2'>
                  <h6>Account Code</h6>{' '}
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  type='text'
                  required
                  placeholder='XYZ123'
                  value={input.accountCode}
                  name='accountCode'
                  onChange={handleInput}
                ></Form.Control>

                {validated && !input.accountCode && (
                  <Form.Control.Feedback type='invalid'>
                    This field is Required*
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>

            <Row className='mb-3 mt-3'>
              <Form.Group controlId='accountType'>
                <Form.Label className='mb-2'>
                  <h6>Account Type</h6>{' '}
                </Form.Label>
                <Form.Select
                  className='viewonly'
                  type='text'
                  required
                  placeholder='accountType'
                  value={input.accountType || ''}
                  name='accountType'
                  onChange={handleInput}
                >
                  <option value='' disabled>
                    Select a Period
                  </option>

                  <option value='1'>Sales</option>
                  <option value='2'>Payment</option>
                  <option value='3'>Deposit</option>
                </Form.Select>

                {validated && !input.accountType && (
                  <Form.Control.Feedback type='invalid'>
                    This field is Required*
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row className='mb-3 mt-3'>
              <Form.Group controlId='Account'>
                <Form.Label className='mb-2'>
                  <h6>Description</h6>
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  required
                  as='textarea'
                  rows={3} // Set the number of rows to 3
                  placeholder='Enter Description'
                  value={input.description}
                  name='description'
                  onChange={handleInput}
                  style={{ overflowY: 'scroll' }} // Add scrollbar style
                ></Form.Control>

                {validated && !input.description && (
                  <Form.Control.Feedback type='invalid'>
                    This field is Required*
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>

            {result.isLoading || updated.isLoading ? (
              <>
                <Button className='btn btn-primary '>
                  Loading...{' '}
                  <Spinner
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                    variant='primary'
                  />
                </Button>
              </>
            ) : (
              <Button className='btn btn-primary' type='submit'>
                {operateId ? 'Update Account' : 'Add Account'}
              </Button>
            )}
            <Button className='btn btn-secondary ml-3' onClick={handleClose}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AccountAdd
