import React, { useEffect } from "react";
import FloorTable from "../Components/FloorPlans/FloorTable";
import { ViewFloorPlan } from "../Components/FloorPlans/ViewFloorPlan";
import FloorUpdate from "../Components/FloorPlans/FloorUpdate";
import { useGlobalContext } from "../Context/GlobalContext";

export const FloorPlans = () => {
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Floor Plans");
  }, []);

  return <FloorTable />;
};

export const ViewFloorPlans = () => {
  return <ViewFloorPlan />;
};
