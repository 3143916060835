import { useEffect, useState } from "react";
import DeleteMeetingPopUp from "./DeleteMeetingPopUp";
import UpdateMeetingPopUp from "./UpdateMeetingPopUp";
import ViewMeetingPopup from "./ViewMeetingPopup";
import MeetingModal from "./MeetingModal";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import { useGetMeetingRoomQuery } from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import meetingRoomPng from "../../Assets/dummyImages/meetingRoom.png";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import PermissionPage from "../MainShared/PermissionPage";
import { Pagination } from "react-bootstrap";
import Select from "react-select";

import { colorStyles } from "../../utils/constant";
import { useGetSpaceTypesQuery } from "../../Slices/constantApi";
import Loader from "../Loader/Loader";
const ReactDataTable3 = () => {
  const { resourcesPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const { isLoading: isLoading, data: FetchMeetingData } =
    useGetMeetingRoomQuery({
      id: spaceId || currentSpaceId,
      room_type: 1,
      page_size: itemsPerPage?.value,
      page: activePage,
    });
  const { data: FetchSpaceTypes } = useGetSpaceTypesQuery();

  const [search, setSearch] = useState("");
  // const [viewMeetIndex, setViewMeetIndex] = useState(0);

  const { items, requestSort, sortConfig } = useSortableData(
    FetchMeetingData?.meetingRooms || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);
    setLoading(true);
  };
  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];
  useEffect(() => {
    setLoading(false);
  }, [FetchMeetingData]);
  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };
  const filteredItems = items
    // ?.slice()
    // .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (val.title.toLowerCase().includes(search.toLowerCase())) {
        return val;
      }
    });
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="col-12 mt-4">
        <div className="d-flex justify-content-between ">
          <div className="col-12  col-md-4 col-lg-6">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Resource"
              className="form-control"
            />
          </div>

          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
            <MeetingModal FetchSpaceTypes={FetchSpaceTypes} />
          </div>
        </div>
      </div>
      <hr />
      <div className="col-12 ">
        <div className="">
          <div className="col-12 d-flex justify-content-end">
            <label
              style={{
                display: "flex",
                whiteSpace: "nowrap",
                fontWeight: "normal",
              }}
            >
              <small className="mr-2 mt-2 title"> Show</small>
              <Select
                options={options}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handlePage}
                value={itemsPerPage}
                id="pages-select"
                styles={colorStyles}
              />
              <small className="ml-2 mt-2"> entries</small>
            </label>
          </div>
        </div>
      </div>
      {resourcesPermissions[0]?.read ||
      resourcesPermissions[0]?.create ||
      resourcesPermissions[0]?.update ||
      resourcesPermissions[0]?.delete ? (
        <div className="memberPackage table-responsive">
          <table className="table table-stripedCustom">
            <thead>
              <tr>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Name</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("title")}
                        className={getClassNamesFor("title")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("title")}
                        className={getClassNamesFor("title")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Capacity</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("capacity")}
                        className={getClassNamesFor("capacity")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("capacity")}
                        className={getClassNamesFor("capacity")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>
                  <div
                    className="d-flex align-items-center sort"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div>Price</div>
                    <div className="d-flex" style={{ flexDirection: "column" }}>
                      <Icon.ChevronUp
                        onClick={() => requestSort("price")}
                        className={getClassNamesFor("price")}
                        style={{ fontSize: "10px" }}
                      />

                      <Icon.ChevronDown
                        onClick={() => requestSort("price")}
                        className={getClassNamesFor("price")}
                        style={{ fontSize: "10px" }}
                      />
                    </div>
                  </div>
                </th>
                <th>Space Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td colSpan={5} className="fs-5">
                    isLoading...
                  </td>
                </tr>
              )}
              {!isLoading && filteredItems.length > 0 ? (
                filteredItems.map((item, index) => {
                  let originalIndex = items.length - index - 1;
                  const {
                    id,
                    capacity,
                    price,
                    title,
                    imageUrls,
                    spaceType,
                    bookable,
                  } = item;
                  return (
                    <tr key={index}>
                      <td>
                        <div className="media">
                          <div className="media-aside logo-list mr-2">
                            <img
                              src={
                                imageUrls ||
                                meetingRoomPng ||
                                "https://placehold.jp/90x90.png"
                              }
                              alt=""
                              onError={(e) => (e.target.src = meetingRoomPng)}
                              // style={{
                              //   // marginRight: "8px",
                              //   borderRadius: "50%",
                              //   width: "48px",
                              //   height: "48px",
                              //   objectFit: "cover",
                              // }}
                            />
                          </div>
                          <div className="media-body my-auto">
                            <span
                              className="mb-0 table-avatar"
                              // onClick={() => viewMeeting(originalIndex)}
                            >
                              {/* <b> {title}</b>
                               */}
                              {title
                                ? title.length > 25
                                  ? title.substring(0, 25) + "..."
                                  : title
                                : "----"}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>{capacity}</td>
                      {bookable ? <td>{price}</td> : <td>----</td>}
                      {/* <td>{spaceType}</td> */}
                      <td>
                        {FetchSpaceTypes?.find((type) => type.id === spaceType)
                          ?.name || "Unknown"}
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {/* <ViewMeetingPopup
                            index={originalIndex}
                            items={items}
                          
                          /> */}
                          <UpdateMeetingPopUp
                            items={items}
                            // index={originalIndex}
                            index={index}
                            FetchSpaceTypes={FetchSpaceTypes}
                          />
                          <DeleteMeetingPopUp
                            items={items}
                            // index={originalIndex}
                            index={index}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : !isLoading && filteredItems?.length == 0 ? (
                <tr>
                  <td colSpan={5} className="text-left fs-5">
                    {search == ""
                      ? "No Meeting Rooms !"
                      : "No meeting room found"}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          {FetchMeetingData?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing{" "}
                  {
                    // FetchMeetingData?.meetingRooms?.length
                    filteredItems?.length
                  }{" "}
                  of {FetchMeetingData?.totalCount} entires
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: FetchMeetingData?.totalPages }).map(
                    (_, i) => (
                      <Pagination.Item
                        key={i}
                        active={i + 1 === activePage}
                        onClick={
                          i + 1 === activePage
                            ? null
                            : () => handlePageSelect(i + 1)
                        }
                      >
                        {i + 1}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={activePage === FetchMeetingData?.totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <PermissionPage />
        </>
      )}
    </>
  );
};

export default ReactDataTable3;
