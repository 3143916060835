import React, { useState } from "react";
import "./index.css";
import {
  PaymentDetails,
  RightBottomForm,
  MemberHeader,
  AddCustomer,
  AddNote,
} from "../AddInvoices";

import { Select } from ".";
import Data from "../AddInvoices/dummy.json";

import { CancelIcon, SettingIcon } from "../../Assets/InvoiceSvgs/Icons";
import SalesPerson from "../AddInvoices/SalesPerson";
import SubTotal from "../AddInvoices/SubTotal";
import { colorStyles } from "../../utils/constant";
import {
  useCreateInvoiceV2Mutation,
  useGetAllInvoicesQuery,
  useGetAllMembersQuery,
  useGetAllSpaceUserQuery,
  useGetMeetingRoomPackageQuery,
  useGetPackageQuery,
  useGetSpaceDataQuery,
  useGetSpaceInvoiceQuery,
} from "../../Slices/spaceApi";
import { useEffect } from "react";
import { requiredAlert } from "../../utils/Alerts";
import { Toaster, toast } from "react-hot-toast";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import PaymentDetails1 from "../AddInvoices/PaymentDetails1";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Breadcrumb } from "react-bootstrap";

const AddInvoiceForMember = () => {
  const { id } = useParams();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const {
    setDashBoardTitle,
    rentalFee,
    serviceCharges,
    isOverAllTax,
    currency,
  } = useGlobalContext();

  useEffect(() => {
    setDashBoardTitle("Invoice Add");
  }, []);
  const [dueDate, setDueDate] = useState(() => {
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    return nextWeek;
  });
  const { packageLoading, data: packageData } = useGetPackageQuery(
    spaceId || currentSpaceId
  );

  const { isLoading: loadingMeetingRoomPackage, data: meetingData } =
    useGetMeetingRoomPackageQuery(spaceId || currentSpaceId);
  const { isLoading: dataLoading, data: data } = useGetSpaceDataQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { isLoading: loadingUsers, data: userData } = useGetAllMembersQuery(
    spaceId || currentSpaceId
  );
  const { isLoading: invoicesLoading, data: FetchSpaceInvoice } =
    useGetAllInvoicesQuery({
      spaceId: spaceId || currentSpaceId,
      page_size: 10,
      page: 1,
    });
  const [createManuallyInvoice, resultStats] = useCreateInvoiceV2Mutation();

  const [invoiceData, setInvoiceData] = useState({
    member: id,
    space: spaceId || currentSpaceId,
    packages: [
      {
        packageId: "",
        quantity: 1,
      },
    ],
    meeting_packages: [
      {
        packageId: "",
        quantity: 1,
      },
    ],
    // start_date: "2023-10-16T00:10",
    invoice: {
      tax: 0,
      discount_percentage: 0,
      remarks: "",
    },
    remarks: "",
  });

  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(0);
  const handleChange = (e) => {
    setInvoiceData({ ...invoiceData, member: e.userId });
  };

  // const [serviceList, setServiceList] = useState([
  //   {
  //     packageId: packageData?.[0]?.id,
  //     quantity: 1,
  //     cost: packageData?.[0]?.price,
  //     price: packageData?.[0]?.price,
  //   },
  // ]);
  const [serviceList, setServiceList] = useState([
    {
      packageId: packageData?.[0]?.id,
      quantity: 1,
      serviceCharges: packageData?.[0]?.serviceCharges,
      rentalFee: packageData?.[0]?.rentalFee,
      price: packageData?.[0]?.serviceCharges + packageData?.[0]?.rentalFee,
    },
  ]);
  //   useEffect(() => {
  //     setServiceList({
  //       packageId: packageData?.[0]?.id,
  //       quantity: 1,
  //       cost: packageData?.[0]?.price,
  //       price: packageData?.[0]?.price,
  //     });
  //   }, [packageData]);

  // const addItem = () => {
  //   setServiceList([
  //     ...serviceList,
  //     {
  //       packageId: packageData?.[0]?.id,
  //       quantity: 1,
  //       cost: packageData?.[0]?.price,
  //       price: packageData?.[0]?.price,
  //     },
  //   ]);
  // };

  // const removeItem = (index) => {
  //   const list = [...serviceList];
  //   list.splice(index, 1);
  //   setServiceList(list);
  // };

  // const handleFormChange = (index, event) => {
  //   let data = [...serviceList];
  //   data[index][event.target.name] = event.target.value;

  //   let findPackage = packageData?.find(
  //     (item) => item.id == serviceList?.[index]?.packageId
  //   );

  //   let quantity = serviceList?.[index]?.quantity;

  //   data[index]["cost"] = findPackage?.price;
  //   data[index]["price"] = (findPackage?.price * +quantity).toFixed(2);

  //   setServiceList(data);
  // };
  const addItem = () => {
    setServiceList([
      ...serviceList,
      {
        packageId: packageData?.[0]?.id,
        quantity: 1,
        serviceCharges: packageData?.[0]?.serviceCharges,
        rentalFee: packageData?.[0]?.rentalFee,
        price: packageData?.[0]?.serviceCharges + packageData?.[0]?.rentalFee,
      },
    ]);
  };

  const removeItem = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleFormChange = (index, event) => {
    let data = [...serviceList];
    data[index][event.target.name] = event.target.value;

    let findPackage = packageData?.find(
      (item) => item.id == serviceList?.[index]?.packageId
    );

    let quantity = serviceList?.[index]?.quantity;

    // data[index]["cost"] = findPackage?.price;
    data[index]["serviceCharges"] = findPackage?.serviceCharges;
    data[index]["rentalFee"] = findPackage?.rentalFee;

    // data[index]["price"] = (findPackage?.price * +quantity).toFixed(2);
    data[index]["price"] = (
      (findPackage?.serviceCharges + findPackage?.rentalFee) *
      +quantity
    ).toFixed(2);

    setServiceList(data);
  };

  // const [serviceMeeting, setServiceMeeting] = useState([
  //   {
  //     packageId: meetingData?.[0]?.id,
  //     quantity: 1,
  //     cost: meetingData?.[0]?.price,
  //     price: meetingData?.[0]?.price,
  //   },
  // ]);
  const [serviceMeeting, setServiceMeeting] = useState([
    {
      packageId: meetingData?.[0]?.id,
      quantity: 1,
      serviceCharges: meetingData?.[0]?.serviceCharges,
      rentalFee: meetingData?.[0]?.rentalFee,
      price: meetingData?.[0]?.serviceCharges + meetingData?.[0]?.rentalFee,
    },
  ]);

  // const meetingAddItem = () => {
  //   setServiceMeeting([
  //     ...serviceMeeting,
  //     {
  //       packageId: meetingData?.[0]?.id,
  //       quantity: 1,
  //       cost: meetingData?.[0]?.price,
  //       price: meetingData?.[0]?.price,
  //     },
  //   ]);
  // };

  // const meetingRemoveItem = (index) => {
  //   const list = [...serviceMeeting];
  //   list.splice(index, 1);
  //   setServiceMeeting(list);
  // };

  // const handle = (e) => {
  //   setInvoiceData({ ...invoiceData, remarks: e.target.value });
  // };

  // const handleMeetingFormChange = (index, event) => {
  //   let data = [...serviceMeeting];
  //   data[index][event.target.name] = event.target.value;

  //   let findPackage = meetingData.find(
  //     (item) => item.id == serviceMeeting?.[index]?.packageId
  //   );

  //   let quantity = serviceMeeting?.[index]?.quantity;

  //   data[index]["cost"] = findPackage?.price;
  //   data[index]["price"] = (findPackage?.price * +quantity).toFixed(2);

  //   setServiceMeeting(data);
  // };
  const meetingAddItem = () => {
    setServiceMeeting([
      ...serviceMeeting,
      {
        packageId: meetingData?.[0]?.id,
        quantity: 1,
        serviceCharges: meetingData?.[0]?.serviceCharges,
        rentalFee: meetingData?.[0]?.rentalFee,
        price: meetingData?.[0]?.serviceCharges + meetingData?.[0]?.rentalFee,
      },
    ]);
  };
  const meetingRemoveItem = (index) => {
    const list = [...serviceMeeting];
    list.splice(index, 1);
    setServiceMeeting(list);
  };

  const handle = (e) => {
    setInvoiceData({ ...invoiceData, remarks: e.target.value });
  };

  const handleMeetingFormChange = (index, event) => {
    let data = [...serviceMeeting];
    data[index][event.target.name] = event.target.value;

    let findPackage = meetingData?.find(
      (item) => item.id == serviceMeeting?.[index]?.packageId
    );

    let quantity = serviceMeeting?.[index]?.quantity;

    data[index]["serviceCharges"] = findPackage?.serviceCharges;
    data[index]["rentalFee"] = findPackage?.rentalFee;

    data[index]["price"] = (
      (findPackage?.serviceCharges + findPackage?.rentalFee) *
      +quantity
    ).toFixed(2);

    setServiceMeeting(data);
  };
  function convertDateFormat(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    const outputDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return outputDate;
  }

  const addInvoice = (e) => {
    e.preventDefault();
    const { remarks } = invoiceData;

    const updateSpacePackageIds = serviceList?.map((item) => ({
      quantity: parseInt(item.quantity),
      package: parseInt(item.packageId),
    }));

    const updateMeetingPackageIds = serviceMeeting?.map((item) => ({
      quantity: parseInt(item.quantity),
      package: parseInt(item.packageId),
    }));

    const timestamp = Date.parse(dueDate) / 1000;
    const invoice = {
      tax: tax,
      discount_percentage: parseFloat(discount),
      remarks: remarks,
    };
    let updateData = {
      member: parseInt(invoiceData?.member),
      space: parseInt(invoiceData?.space),
      meeting_packages: updateMeetingPackageIds,
      packages: updateSpacePackageIds,
      // start_date: convertDateFormat(new Date().toISOString()),
      invoice: invoice,
    };
    if (!data?.bankName) {
      requiredAlert("Please Add Bank Details First");
    } else {
      createManuallyInvoice({
        spaceId: data?.spaceId,
        data: updateData,
      });
    }
  };
  const [foundUser, setFoundUser] = useState([]);

  useEffect(() => {
    const user = userData?.users?.find((item) => item.id == id);

    setFoundUser(user);
  }, [userData, id]);
  const navigate = useNavigate();
  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
    if (resultStats?.isSuccess) {
      toast.success("SuccessFully Added!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate("/invoice/list");
    }
  }, [resultStats]);

  if (
    packageLoading ||
    loadingMeetingRoomPackage ||
    loadingUsers ||
    invoicesLoading ||
    dataLoading
  ) {
    return <Loader height={6.6} />;
  }
  return (
    <>
      <Breadcrumb className="custom-breadcrumb ml-auto">
        <Breadcrumb.Item>
          <Link to="/member/list">All Members</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {foundUser?.company ? (
            <Link to={`/company/${id}/view1`}>{foundUser?.name}</Link>
          ) : (
            <Link to={`/individual/${id}/view`}>{foundUser?.name}</Link>
          )}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Creat Invoice</Breadcrumb.Item>
      </Breadcrumb>
      <section className="container-fluid addMember">
        <div className="">
          <div className="row">
            <div className="col-xl-9 col-12">
              <div className="card d-flex">
                {/* Header Start */}
                <MemberHeader
                  {...data}
                  data={Data}
                  FetchSpaceInvoice={FetchSpaceInvoice}
                  dueDate={dueDate}
                  setDueDate={setDueDate}
                />

                {/* HORIZONTAL ROW */}
                <hr className="invoice-spacing" />

                {/* Body */}
                <div className="card-body invoice-padding pt-0">
                  <div className="row invoice-spacing">
                    <div className="col-lg-6">
                      <h6 className="invoice-to-title mb-4">Invoice To:</h6>
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        placeholder="Address"
                        value={
                          userData?.users?.find(
                            (item, idx) => item.id == invoiceData?.member
                          )?.name
                        }
                        disabled
                      />
                      {invoiceData?.member && (
                        <div className="mt-3">
                          <p className="card-text my-1">
                            {
                              userData?.users?.find(
                                (item, idx) => item.id == invoiceData?.member
                              )?.address
                            }
                          </p>
                          <p className="card-text my-1">
                            {
                              userData?.users?.find(
                                (item, idx) => item.id == invoiceData?.member
                              )?.phoneNo
                            }
                          </p>
                          <p className="card-text my-1">
                            {
                              userData?.users?.find(
                                (item, idx) => item.id == invoiceData?.member
                              )?.email
                            }
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 d-flex justify-content-lg-end mt-lg-0 mt-4">
                      <PaymentDetails1
                        // data={data}
                        // discount={discount}
                        // serviceList={serviceList}
                        // serviceMeeting={serviceMeeting}
                        data={data}
                        serviceList={serviceList}
                        serviceMeeting={serviceMeeting}
                        tax={serviceCharges}
                        rentalFee={rentalFee}
                        discount={discount}
                      />
                    </div>
                  </div>
                </div>

                {/* {packageData?.length > 0 && (
                  <div className="card-body invoice-padding invoice-product-details border">
                    <h6>Packages</h6>
                    <form className="source-item">
                      <div>
                        {serviceList?.map((input, index) => (
                          <div key={index} className="repeater-wrapper my-2">
                            <div className="">
                              <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                                <div className="row w-100 pe-lg-0 pe-3 py-4">
                                  <div className="col-lg-5 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Item
                                    </p>
                                    <select
                                      name="packageId"
                                      value={input.packageId}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                      className="form-control"
                                    >
                                      {packageData?.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-lg-3 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      Cost
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="24"
                                      name="cost"
                                      value={input.cost}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-lg-2 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      quantity
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="quantity"
                                      value={input.quantity}
                                      onChange={(event) =>
                                        handleFormChange(index, event)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-2 col-12 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Price
                                    </p>
                                    <p className="card-text mb-0">
                                      {+serviceList?.[index]?.price || 0}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-between border-left invoice-product-actions py-2 px-2">
                                  <CancelIcon index={() => removeItem(index)} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="">
                        <div className="px-0 mt-3">
                          <button
                            type="button"
                            className="btn btn-primary send-invoice-btn"
                            data-repeater-create=""
                            onClick={addItem}
                          >
                            Add Item
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                {meetingData?.length > 0 && (
                  <div className="card-body invoice-padding invoice-product-details">
                    <h6>Meeting Packages</h6>
                    <form className="source-item">
                      <div>
                        {serviceMeeting?.map((input, index) => (
                          <div key={index} className="repeater-wrapper my-2">
                            <div className="">
                              <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                                <div className="row w-100 pe-lg-0  pe-3  py-4">
                                  <div className="col-lg-5 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Item
                                    </p>
                                    <select
                                      name="packageId"
                                      value={input.packageId}
                                      onChange={(event) =>
                                        handleMeetingFormChange(index, event)
                                      }
                                      className="form-control"
                                    >
                                      {meetingData?.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item?.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-lg-3 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      Cost
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="24"
                                      name="cost"
                                      value={input?.cost}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-lg-2 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      quantity
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="quantity"
                                      value={input?.quantity}
                                      onChange={(event) =>
                                        handleMeetingFormChange(index, event)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-2 col-12 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Price
                                    </p>
                                    <p className="card-text mb-0">
                                      {+serviceMeeting?.[index]?.price || 0}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-between border-left invoice-product-actions py-2 px-2">
                                  <CancelIcon
                                    index={() => meetingRemoveItem(index)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="">
                        <div className="px-0 mt-3">
                          <button
                            type="button"
                            className="btn btn-primary send-invoice-btn"
                            data-repeater-create=""
                            onClick={meetingAddItem}
                          >
                            Add Item
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )} */}
                {packageData?.length > 0 && (
                  <div className="card-body invoice-padding invoice-product-details border">
                    <h6>Packages</h6>
                    <form className="source-item">
                      <div>
                        {serviceList?.map((input, index) => (
                          <div
                            key={index}
                            //  className="repeater-wrapper my-2"
                            className={`my-2 ${
                              index % 2 == 0 ? "bg-white" : "bg-light"
                            }`}
                          >
                            <div className="">
                              <div
                                className="col-12 d-flex  position-relative pe-0 pl-3 fs-6"
                                // product-details-border
                              >
                                <div className="row w-100 pe-lg-0 pe-3 py-4">
                                  <div className="col-12">
                                    <div className="row w-100">
                                      <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                        <p className="card-text col-title mb-md-50 mb-0">
                                          Item
                                        </p>
                                        <select
                                          name="packageId"
                                          value={input.packageId}
                                          onChange={(event) =>
                                            handleFormChange(index, event)
                                          }
                                          className="form-control"
                                        >
                                          {packageData?.map((item, index) => {
                                            return (
                                              <option
                                                key={index}
                                                value={item.id}
                                              >
                                                {item.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      <div className="col-lg-4 col-12 my-lg-0 my-2">
                                        <p className="card-text col-title mb-0">
                                          quantity
                                        </p>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="1"
                                          min={1}
                                          name="quantity"
                                          value={input.quantity}
                                          onChange={(event) =>
                                            handleFormChange(index, event)
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="row w-100 mt-3">
                                      <div className="col-lg-4 col-12 my-lg-0 my-2">
                                        <p className="card-text col-title mb-0">
                                          Service Charges
                                        </p>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="0"
                                          name="serviceCharges"
                                          value={input.serviceCharges}
                                          disabled
                                        />
                                      </div>
                                      <div className="col-lg-4 col-12 my-lg-0 my-2">
                                        <p className="card-text col-title mb-0">
                                          Rental Fee
                                        </p>
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder="0"
                                          name="rentalFee"
                                          value={input.rentalFee}
                                          disabled
                                        />
                                      </div>

                                      <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                                        <p className="card-text col-title mb-md-50 mb-0">
                                          Price
                                        </p>
                                        <p className="card-text mb-0">
                                          {+serviceList?.[index]?.price}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="d-flex flex-column align-items-center justify-content-between invoice-product-actions py-2 px-2"
                                  // border-left
                                >
                                  <CancelIcon index={() => removeItem(index)} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="">
                        <div className="px-0 mt-3">
                          <button
                            type="button"
                            className="btn btn-primary send-invoice-btn"
                            data-repeater-create=""
                            onClick={addItem}
                          >
                            Add Item
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                {meetingData?.length > 0 && (
                  <div className="card-body invoice-padding invoice-product-details">
                    <h6>Meeting Packages</h6>
                    <form className="source-item">
                      <div>
                        {serviceMeeting?.map((input, index) => (
                          <div
                            key={index}
                            className={`my-2 ${
                              index % 2 == 0 ? "bg-white" : "bg-light"
                            }`}
                            // className="repeater-wrapper my-2"
                          >
                            <div className="">
                              <div className="col-12 d-flex  position-relative pe-0 pl-3 fs-6">
                                <div className="row w-100 pe-lg-0  pe-3  py-4">
                                  <div className="row w-100">
                                    <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Item
                                      </p>
                                      <select
                                        name="packageId"
                                        value={input.packageId}
                                        onChange={(event) =>
                                          handleMeetingFormChange(index, event)
                                        }
                                        className="form-control"
                                      >
                                        {meetingData?.map((item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        quantity
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="1"
                                        min={1}
                                        name="quantity"
                                        value={input?.quantity}
                                        onChange={(event) =>
                                          handleMeetingFormChange(index, event)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="row w-100 mt-3">
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Service Charges
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="24"
                                        name="cost"
                                        value={input?.serviceCharges}
                                        disabled
                                      />
                                    </div>
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Rental Fee
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="24"
                                        name="cost"
                                        value={input?.rentalFee}
                                        disabled
                                      />
                                    </div>

                                    <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Price
                                      </p>
                                      <p className="card-text mb-0">
                                        {+serviceMeeting?.[index]?.price}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-2 px-2">
                                  <CancelIcon
                                    index={() => meetingRemoveItem(index)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="">
                        <div className="px-0 mt-3">
                          <button
                            type="button"
                            className="btn btn-primary send-invoice-btn"
                            data-repeater-create=""
                            onClick={meetingAddItem}
                          >
                            Add Item
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                {/* <div className="row invoice-padding py-5 align-items-center">
                  <div className="col-md-3">Total Discount:</div>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control"
                      min={0}
                      max={100}
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row invoice-padding align-items-center">
                  <div className="col-md-3">Tax:</div>
                  <div className="col-md-9">
                    <input
                      type="number"
                      className="form-control"
                      min={0}
                      max={100}
                      value={tax}
                      onChange={(e) => setTax(e.target.value)}
                    />
                  </div>
                </div> */}

                <div className="card-body invoice-padding">
                  <div className="row invoice-sales-total-wrapper align-items-center">
                    <div className="col-lg-7">
                      <SalesPerson name={data?.name} />
                    </div>
                    <div className="col-lg-4 offset-lg-1 d-flex justify-content-lg-end justify-content-center mt-5 mt-lg-0">
                      <SubTotal
                        serviceList={serviceList}
                        discount={discount}
                        serviceMeeting={serviceMeeting}
                        tax={serviceCharges}
                        rentalFee={rentalFee}
                        setDiscount={setDiscount}
                        isOverAllTax={isOverAllTax}
                      />
                    </div>
                  </div>
                </div>
                <hr />
                {/* <AddNote /> */}
                <>
                  <div className="form-group mb-4 invoice-padding">
                    <label
                      htmlFor="note"
                      className="form-label font-weight-bold mb-0"
                    >
                      Note:
                    </label>
                    <textarea
                      className="form-control"
                      rows="2"
                      name="remarks"
                      id="remarkss"
                      value={invoiceData?.remarks}
                      onChange={handle}
                      placeholder={"Write a note here"}
                    ></textarea>
                  </div>
                </>
              </div>
            </div>

            <div className="col-xl-3 col-12">
              <div>
                <div className="card">
                  <div className="card-body">
                    <button
                      className="btn btn-outline-primary mb-2 w-100"
                      onClick={addInvoice}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddCustomer />
        <Toaster position="top-right" />
      </section>
    </>
  );
};

export default AddInvoiceForMember;
