import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  useBookingSlotsMutation,
  useCreateBookingMutation,
  useCreateFlexDeskBookingMutation,
  useUpdateMeetingBookingMutation,
} from "../../Slices/spaceApi";
import { requiredAlert } from "../../utils/Alerts";
import { DataOfTime, colorStyles } from "../../utils/constant";
import Select from "react-select";
import { useSelector } from "react-redux";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
// import { convertLocalTimestampToUTC } from "../../utils/convertLocalTimestampToUTC";
import * as Icon from "react-bootstrap-icons";
import { useGlobalContext } from "../../Context/GlobalContext";
const AddFlexDeskModal = ({
  companies,
  meetingData,
  handleClose,
  Users,
  bookingDate,
  calenderSelect,
  selectedTime,
  selectedMeetingRoom,
}) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { slotSize } = useGlobalContext();
  const [createBooking, resultStats] = useCreateFlexDeskBookingMutation();
  const [updateBooking, resultStats2] = useUpdateMeetingBookingMutation();
  const [filteredResource, setFilteredResource] = useState(
    meetingData?.filter((item) => item.spaceType !== 19 && item.bookable)
  );
  const [bookingSlots, resultStats1] = useBookingSlotsMutation();
  const [apiSpinner, setApiSpinner] = useState(false);
  const [meetingRoomId, setMeetingRoomId] = useState(selectedMeetingRoom);
  const [meetingSlots, setMeetingSlots] = useState([]);
  const [slotError, setSlotError] = useState("");
  const [bookingId, setBokingId] = useState("");

  const [rows, setRows] = useState([
    {
      name: "",
    },
  ]);
  useEffect(() => {
    if (meetingData?.length > 0) {
      setFilteredResource(
        meetingData?.filter((item) => item.spaceType !== 19 && item.bookable)
      );
    }
  }, [meetingData]);
  const handleAddRow = () => {
    const newRow = {
      name: "",
    };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleInputChange = (event, index) => {
    let data = [...rows];
    data[index][event.target.name] = event.target.value;

    // let findPackage = fetchPackages?.find(
    //   (item) => item.id == rows?.[index]?.packageId
    // );

    // let quantity = rows?.[index]?.quantity;

    // // data[index]["cost"] = findPackage?.price;
    // data[index]["serviceCharges"] = findPackage?.serviceCharges;
    // data[index]["rentalFee"] = findPackage?.rentalFee;

    // // data[index]["price"] = (findPackage?.price * +quantity).toFixed(2);
    // data[index]["price"] = (
    //   (findPackage?.serviceCharges + findPackage?.rentalFee) *
    //   +quantity
    // ).toFixed(2);

    setRows(data);
  };

  useEffect(() => {
    if (filteredResource && filteredResource.length > 0) {
      setMeetingRoomId(selectedMeetingRoom || filteredResource?.[0]?.id);

      // getBookingSlots(
      //   bookingDate,
      //   selectedMeetingRoom
      // );
    }
  }, [filteredResource]);

  const utcTime = new Date();

  const localTime = new Date(
    utcTime.getTime() - utcTime.getTimezoneOffset() * 60000
  );

  const minute = localTime.getMinutes();

  if (minute % 30 !== 0) {
    const diff = minute < 30 ? 30 - minute : 60 - minute;
    localTime.setTime(localTime.getTime() + diff * 60 * 1000);
  }

  const [data, setData] = useState({
    capacity: 1,
    // startTime: getCurrentDate(),
    startTime: calenderSelect
      ? `${bookingDate}T${selectedTime?.start?.split(":")[0]}:${
          selectedTime?.start?.split(":")[1]
        }`
      : `${bookingDate}T${new Date()
          ?.toISOString()
          ?.split("T")[1]
          ?.slice(0, 5)}`,
    endTime: new Date(localTime.getTime() + parseInt(slotSize) * 60 * 1000)
      .toISOString()
      .slice(0, 16),
    paidByCard: false,
    id: companies?.[0]?.id,
  });
  const [selectedOption, setSelectedOption] = useState("company");

  useEffect(() => {
    if (companies && companies.length > 0) {
      setData({ ...data, id: companies?.[0]?.id });
    }
  }, [companies]);

  function roundToNearest30Minutes(date) {
    // convert UTC time to local time
    const localTime = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );

    const minutes = localTime.getMinutes();
    if (minutes <= 29) {
      localTime.setMinutes(0);
    } else {
      localTime.setMinutes(30);
    }
    return localTime.toISOString().slice(0, 16);
  }

  function handleDate(e) {
    const name = e.target.name;
    const value = e.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
    getBookingSlots(value, meetingRoomId);
  }

  async function getBookingSlots(selectedDate, meetingRoomId) {
    const slots = await bookingSlots({
      spaceId: spaceId || currentSpaceId,
      data: {
        meetingRoom: meetingRoomId,
        date: selectedDate,
      },
    });
    setMeetingSlots(slots?.data || []);
  }

  const handleMeetingRoomId = (e) => {
    setMeetingRoomId(e.id);
    // getBookingSlots(data?.startTime, e.id);
  };
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  function getCurrentDateTime() {
    const today = new Date();
    const year = today.getFullYear();
    let month = (today.getMonth() + 1).toString().padStart(2, "0");
    let day = today.getDate().toString().padStart(2, "0");
    let hours = today.getHours().toString().padStart(2, "0");
    let minutes = today.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  const [visitorModal, setVisitorModal] = useState(false);
  const [isCompanyChecked, setIsCompanyChecked] = useState(true);
  const [isIndividualChecked, setIsIndividualChecked] = useState(false);

  const handleCompanyChange = () => {
    setIsCompanyChecked(true);
    setIsIndividualChecked(false);
  };

  const handleIndividualChange = () => {
    setIsCompanyChecked(false);
    setIsIndividualChecked(true);
  };

  function handle(event) {
    const name = event.target.name;
    let value = event.target.value;
    setData((prevData) => ({ ...prevData, [name]: value }));
  }

  const handleCompany = (e) => {
    setData({ ...data, id: e.id });
  };

  const postMeetingPackage = (e) => {
    e.preventDefault();

    // const selectedDate = data?.startTime;
    // const initialTime = meetingSlots[selectedStart]?.start;
    // const lastTime = meetingSlots[selectedEnd]?.end;
    // const [startHours, startMinutes, startSeconds] = initialTime?.split(":");
    // const [endHours, endMinutes, endSeconds] = lastTime?.split(":");
    // const [year, month, day] = selectedDate?.split("-");
    // const startDateTime = new Date(
    //   year,
    //   month - 1,
    //   day,
    //   startHours,
    //   startMinutes,
    //   startSeconds
    // );
    // const endDateTime = new Date(
    //   year,
    //   month - 1,
    //   day,
    //   endHours,
    //   endMinutes,
    //   endSeconds
    // );

    // const startTimestamp = startDateTime.getTime();
    // const endTimestamp = endDateTime.getTime();

    // const startIsPM = startDateTime.getHours() >= 12;
    // const endIsAM = endDateTime.getHours() < 12;

    // let adjustedEndTimestamp = endTimestamp;

    // if (startIsPM && endIsAM) {
    //   const endDate = new Date(endTimestamp);
    //   endDate.setDate(endDate.getDate() + 1);
    //   adjustedEndTimestamp = endDate.getTime();
    // }

    // const startEpochTimestamp = Math.floor(startTimestamp / 1000);
    // const endEpochTimestamp = Math.floor(adjustedEndTimestamp / 1000);

    // let capacity = data?.capacity;
    // capacity = +capacity;
    // let startTime = data.startTime;
    // let endTime = endDateTime;
    // let member = +data?.id;
    // let paidByCard = data?.paidByCard;
    // let meetingRoom = meetingRoomId;

    // // let startTimeEpoch = Date?.parse(startTimeISO);
    // // let endTimeEpoch = Date?.parse(endTimeISO);
    // let updateData = {
    //   capacity,
    //   startTime,
    //   meetingRoom,

    //   endTime,
    //   member,
    //   paidByCard,
    // };
    let capacity = data?.capacity;
    capacity = +capacity;
    let startTime = data.startTime;
    let startDate = startTime.split("T")[0];
    let endTime = endDateTime;
    let endTimeFormatted = `${startDate}T${endTime}`;
    let member = +data?.id;
    let paidByCard = data?.paidByCard;
    // let meetingRoom = meetingRoomId;
    let meetingRoom = null;
    let startTimeEpoch = Date.parse(startTime);
    let endTimeEpoch = Date.parse(endTimeFormatted);
    let updateData = {
      capacity,
      startTime: startTimeEpoch / 1000,
      endTime: endTimeEpoch / 1000,
      member,
      paidByCard,
      meetingRoom,
    };

    setApiSpinner(true);
    createBooking({ spaceId: spaceId || currentSpaceId, updateData });
  };

  const postVisitors = (e) => {
    e.preventDefault();
    const filteredData = rows.filter((item) => item.name !== "");

    updateBooking({
      spaceId: spaceId || currentSpaceId,
      id: bookingId,
      updateData: {
        visitor: filteredData,
      },
    });
  };

  useEffect(() => {
    if (resultStats2?.isError) {
      requiredAlert(resultStats2?.error?.data?.message);
    }

    if (resultStats2?.isSuccess) {
      // setTimeout(() => {
      handleClose();
      setVisitorModal(false);
      setBokingId("");
      // }, 1500);
      toast.success("Visitor(s) added successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats2]);

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      setVisitorModal(false);
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }

    if (resultStats?.isSuccess) {
      setBokingId(resultStats?.data?.id);
      handleClose();
      setVisitorModal(false);
      setApiSpinner(false);

      // handleClose();
      setSlotError("");

      setTimeout(() => {
        setData({
          ...data,
          capacity: 1,
          startTime: new Date().toLocaleString(),
          endTime: new Date(
            Date.now() + parseInt(slotSize) * 60 * 1000
          ).toLocaleString(),
          paidByCard: false,
          id: companies?.[0]?.id,
        });
        setMeetingRoomId(filteredResource?.[0]?.id);
      }, 1500);
      toast.success("Booking created successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  const formatTime = (timeString) => {
    const date = new Date(`2023-10-29T${timeString}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${amOrPm}`;
  };

  const [selectedStart, setSelectedStart] = useState(meetingSlots[0]?.start);
  const [selectedEnd, setSelectedEnd] = useState(meetingSlots[0]?.end);

  useEffect(() => {
    if (calenderSelect) {
      const selectedSlotIndex = meetingSlots.findIndex(
        (slot) =>
          slot.start === selectedTime.start && slot.end === selectedTime.end
      );
      if (selectedSlotIndex >= 0) {
        setSelectedStart(selectedSlotIndex);
        setSelectedEnd(selectedSlotIndex);
        setSlotError("");
      } else {
        setSlotError(
          "Selected slot is not available.Please select from the following slots."
        );
      }
    }
  }, [calenderSelect, selectedTime, meetingSlots]);

  const handleSlotClick = (index) => {
    if (selectedStart === null) {
      setSelectedStart(index);
      setSelectedEnd(index);
    } else {
      const previousSlot = meetingSlots[selectedEnd];
      const currentSlot = meetingSlots[index];

      if (
        selectedEnd === index - 1 &&
        previousSlot?.end === currentSlot?.start
      ) {
        setSelectedEnd(index);
      } else {
        setSelectedStart(index);
        setSelectedEnd(index);
      }
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setData(
      option == "company"
        ? { ...data, id: companies?.[0]?.id }
        : { ...data, id: Users?.[0]?.id }
    );
  };
  const [endDateTime, setEndDateTime] = useState("");

  useEffect(() => {
    const startTimeParts = data?.startTime?.split("T")[1]?.split(":");
    const hours = parseInt(startTimeParts?.[0], 10);
    const minutes = parseInt(startTimeParts?.[1], 10);
    const startDateTime = new Date();
    startDateTime?.setHours(hours);
    startDateTime?.setMinutes(minutes);

    const endDateTime = new Date(
      startDateTime?.getTime() + parseInt(slotSize) * 60 * 1000
    );
    const endHours = endDateTime?.getHours()?.toString()?.padStart(2, "0");
    const endMinutes = endDateTime?.getMinutes()?.toString()?.padStart(2, "0");
    const newEndTime = `${endHours}:${endMinutes}`;

    setEndDateTime(newEndTime);
  }, [data?.startTime]);

  // useEffect(() => {
  //   if (selectedTime?.start) {
  //     const start = new Date(selectedTime.start);
  //     const end = new Date(start.getTime() + 60 * 60 * 1000); // Adding 1 hour to start time
  //     const endIsoString = end.toISOString();
  //     const endFormatted = endIsoString.slice(0, 16); // Format end time for input[type="datetime-local"]
  //     setEndDateTime(endFormatted);

  //     // Convert end time to epoch timestamp with start time's date
  //     const endWithStartDate = new Date(selectedTime.start);
  //     endWithStartDate.setHours(end.getHours());
  //     endWithStartDate.setMinutes(end.getMinutes());
  //     const endTimestamp = Math.floor(endWithStartDate.getTime() / 1000);
  //     console.log("End Time Epoch Timestamp:", endTimestamp);
  //   }
  // }, [selectedTime]);

  return (
    <div>
      <Modal
        show={true}
        onHide={() => {
          setSlotError("");
          setVisitorModal(false);
          handleClose();
        }}
        backdrop="static"
        keyboard={false}
        id="meetingPopUp"
        size="lg"
      >
        <Modal.Header className="" closeButton>
          <Modal.Title as="h5">Create Flex Desk Booking</Modal.Title>
        </Modal.Header>

        <form onSubmit={postMeetingPackage}>
          <Modal.Body>
            <>
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <div className="d-flex">
                    <div className="col-12 col-md-6">
                      <input
                        type="radio"
                        id="companyOption"
                        name="option"
                        value="company"
                        checked={selectedOption === "company"}
                        onChange={() => handleOptionChange("company")}
                      />
                      <label htmlFor="companyOption" className="ml-2">
                        Company
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        id="individualOption"
                        name="option"
                        value="individual"
                        checked={selectedOption === "individual"}
                        onChange={() => handleOptionChange("individual")}
                      />
                      <label htmlFor="individualOption" className="ml-2">
                        Individual
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                {selectedOption === "company" && (
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="companySelect" className="mb-1">
                      Company
                    </label>
                    <Select
                      options={companies}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={colorStyles}
                      value={companies?.filter((obj) => obj.id == data?.id)}
                      onChange={handleCompany}
                      id="companySelect"
                    />
                  </div>
                )}

                {selectedOption === "individual" && (
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="individualSelect" className="mb-1">
                      Individual
                    </label>
                    <Select
                      options={Users}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      styles={colorStyles}
                      value={Users?.filter((obj) => obj.id == data?.id)}
                      onChange={handleCompany}
                      id="individualSelect"
                    />
                  </div>
                )}

                {/* <div className="form-group col-12 col-md-6">
                  <label htmlFor="meetingId" className="mb-1">
                    Resource
                  </label>
                  <Select
                    options={filteredResource}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    styles={colorStyles}
                    value={filteredResource?.filter(
                      (obj) => obj.id == meetingRoomId
                    )}
                    onChange={handleMeetingRoomId}
                    id="meetingId"
                  />
                </div> */}
              </div>

              <div className="d-flex col-12">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="startTime">From</label>
                  <div className="input-group">
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <Icon.Calendar2Check />
                      </span>
                    </div>
                    <input
                      type="datetime-local"
                      name="startTime"
                      id="startTime"
                      className="form-control"
                      placeholder=""
                      value={data?.startTime}
                      min={getCurrentDateTime()}
                      onChange={handleDate}
                    />
                  </div>
                </div>

                {/* New input field for end time */}
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="endTime">To</label>
                  <input
                    type="time"
                    name="endTime"
                    id="endTime"
                    className="form-control"
                    placeholder=""
                    value={endDateTime}
                    // min={roundToNearest30Minutes(new Date(data?.startTime))}
                    onChange={(e) => setEndDateTime(e.target.value)}
                  />
                </div>
              </div>
              {/* <div
                  className="meeting-slots-container h-25"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  {slotError}
                  {meetingSlots.length > 0
                    ? meetingSlots?.map((slot, index) => {
                        return (
                          <div
                            key={index}
                            className={`meeting-slot ${
                              index >= selectedStart && index <= selectedEnd
                                ? "selected"
                                : ""
                            }`}
                            style={{
                              border: "1px solid #358EF1",
                              cursor: "pointer",
                              margin: "8px",
                              padding: "6px",
                              borderRadius: "25px",
                              // justifyContent: "center",
                              maxHeight: "38px",
                              // alignItems: "center",
                              backgroundColor:
                                index >= selectedStart && index <= selectedEnd
                                  ? "#007bff"
                                  : "transparent",
                              color:
                                index >= selectedStart && index <= selectedEnd
                                  ? "#fff"
                                  : "initial",
                            }}
                            onClick={() => handleSlotClick(index)}
                          >
                            {`${formatTime(slot.start)} - ${formatTime(
                              slot.end
                            )}`}
                          </div>
                        );
                      })
                    : "No Booking Slots Available on This Date."}
                </div> */}
            </>
          </Modal.Body>
          <div className="ml-3 mb-3 mt-4">
            <button
              className="btn btn-primary"
              disabled={
                apiSpinner ||
                // !meetingSlots[selectedStart]?.start ||
                !data?.id
              }
              type="submit"
            >
              Create Booking
              {apiSpinner ? (
                <div
                  className=" spinner-border text-primary"
                  role="status"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "5px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </button>
            <button
              className="btn btn-secondary ml-3"
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              Cancel
            </button>
          </div>
          {/* <Modal.Footer className="">
              <div className="mt-4">
                <Button variant="primary" disabled={apiSpinner} type="submit">
                  Create Booking
                  {apiSpinner ? (
                    <div
                      className=" text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                <button
                  className="btn btn-secondary ml-3"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer> */}
        </form>

        {/* {visitorModal && (
          <form onSubmit={postVisitors}>
            <Modal.Body>
              <>
                <div className="card-body invoice-padding ">
                  {rows?.map((input, index) => (
                    <div key={index} className="repeater-wrapper my-2">
                      <div className="">
                        <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                          <div className="row w-100 pe-lg-0 pe-3 ">
                            <div className="col-10  my-2">
                              <p className="card-text col-title mb-0">
                                Visitor {index + 1}{" "}
                                <span className="optional">(Optional)</span>
                              </p>
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Visitor Name"
                                  min={1}
                                  name="name"
                                  value={input?.name}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                                {index != 0 ? (
                                  <div className="ml-3">
                                    <p
                                      className="bg-white"
                                      onClick={() => handleRemoveRow(index)}
                                    >
                                      <CancelIcon />
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="">
                  <div className="px-0 mt-3">
                    <button
                      type="button"
                      className="btn btn-primary send-invoice-btn"
                      data-repeater-create=""
                      onClick={handleAddRow}
                    >
                      Add More Visitors
                    </button>
                  </div>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer className="mt-auto">
              <div className="mt-4">
                <Button variant="primary" disabled={apiSpinner} type="submit">
                  Save Visitor(s)
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                <button
                  className="btn btn-secondary ml-3"
                  onClick={(e) => {
                    e.preventDefault();

                    handleClose();
                    setVisitorModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          </form>
        )} */}
      </Modal>
    </div>
  );
};

export default AddFlexDeskModal;
