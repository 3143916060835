import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";

import * as Icon from "react-bootstrap-icons";
import {
  // useGetPackageDetailsForMemberQuery,
  useUpdateCompanyMutation,
} from "../../Slices/spaceApi";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const UpdateCompanyDetails = ({
  companyId,
  companyName,
  companyEmail,
  profileImg,
  address,
  phoneNo,
  contactPerson,
  status,
  // securityDeposit,
  FetchPackageData,
  FetchMeetingPackageData,
}) => {
  const { membersPermissions } = useGlobalContext();
  const navigate = useNavigate();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateSpaceCompany, resultStats] = useUpdateCompanyMutation();
  const [apiSpinner, setApiSpinner] = useState(false);

  const [data, setData] = useState({
    spaceId: spaceId || currentSpaceId,
    name: companyName,
    email: companyEmail,
    profileImg: profileImg,
    address: address,
    phoneNo: phoneNo,
    status: status,
    // securityDeposit: securityDeposit,
    contactPerson: contactPerson,
  });

  const [packagesData, setPackagesData] = useState({
    meetingPackageIds: [
      {
        packageId: 1,
        quantity: 1,
      },
    ],
    spacePackageIds: [
      {
        packageId: 1,
        quantity: 1,
      },
    ],
    startTime: "2023-10-05T08:00:00",
    meetingPackageIds: [],
  });

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setIsUploading(false);
    setFile("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [file, setFile] = useState(false);
  const [phone, setPhone] = useState(data.phoneNo);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneStatus, setPhoneStatus] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [companyResponse, setCompanyResponse] = useState(null);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  // const handlePhone = (e) => {
  //   const numericValue = e.target.value.replace(/\D/g, "");
  //   setPhone(numericValue);
  //   setData({ ...data, [e.target.name]: numericValue });
  //   if (numericValue.length > 10 && numericValue.length < 16) {
  //     setData({ ...data, [e.target.name]: numericValue });
  //     setErrorMessage(""); // Clear the error message
  //     setPhoneStatus(false);
  //   } else {
  //     setPhoneStatus(true);
  //     setErrorMessage("Invalid Phone");
  //   }
  // };
  const handlePhone = (e) => {
    // const numericValue = e.replace(/\D/g, "");
    const numericValue = e;
    setPhone(numericValue);
    setData({ ...data, phoneNo: numericValue });
    if (numericValue?.length > 10 && numericValue?.length < 16) {
      setData({ ...data, phoneNo: numericValue });
      setErrorMessage("");
      setPhoneStatus(false);
    } else {
      setPhoneStatus(true);
      setErrorMessage("Invalid Phone");
    }
  };
  const handle = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  useEffect(() => {
    if (!file == "" && file !== null) {
      setIsUploading(true);

      if (file.size >= 1e7) {
        errorAlertImage("File is too Big");
        setFile("");
        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        setData({ ...data, profileImg: [] });
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setData({ ...data, profileImg: resp.data?.[0] });
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile("");
        requiredAlert("Please Select Image File");
        setIsUploading(false);
      }
    }
  }, [file]);

  const [rows, setRows] = useState([
    {
      packageId: FetchPackageData?.[0]?.id,
      quantity: 1,
      // price: FetchPackageData?.[0]?.price,
      // amount: FetchPackageData?.[0]?.price,
    },
  ]);
  const [meetingRows, setMeetingRows] = useState([
    {
      packageId: FetchMeetingPackageData?.[0]?.id,
      quantity: 1,
      // price: FetchMeetingPackageData?.[0]?.price,
      // amount: FetchMeetingPackageData?.[0]?.price,
    },
  ]);

  const handleAddRow = () => {
    const newRow = {
      packageId: FetchPackageData?.[0]?.id,
      quantity: 1,
      // price: FetchPackageData?.[0]?.price,
      // amount: FetchPackageData?.[0]?.price,
    };
    setRows([...rows, newRow]);
  };
  const handleAddMeetingRow = () => {
    const newRow = {
      packageId: FetchMeetingPackageData?.[0]?.id,
      quantity: 1,
      price: FetchMeetingPackageData?.[0]?.price,
      amount: FetchMeetingPackageData?.[0]?.price,
    };
    setMeetingRows([...meetingRows, newRow]);
  };
  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRemoveMeetingRow = (index) => {
    const updatedRows = [...meetingRows];
    updatedRows.splice(index, 1);
    setMeetingRows(updatedRows);
  };

  const handleInputChange = (event, index) => {
    let data = [...rows];
    data[index][event.target.name] = event.target.value;
    setRows(data);
  };

  const handleMeetingInputChange = (event, index) => {
    let data = [...meetingRows];
    data[index][event.target.name] = event.target.value;
    setMeetingRows(data);
  };
  const handleChange = (event) => {
    setData({ ...data, startTime: event.target.value });
  };
  const postAsignPackage = (e) => {
    e.preventDefault();
    const { spacePackageIds, meetingPackageIds, startTime } = data;
    // let timeStampStartTime = toTimestamp(startTime);

    const updateSpacePackageIds = rows?.map((item) => ({
      quantity: parseInt(item.quantity),
      package: parseInt(item.packageId),
    }));

    const updateMeetingPackageIds = meetingRows?.map((item) => ({
      quantity: parseInt(item.quantity),
      package: parseInt(item.packageId),
    }));

    let updateData = {
      member: companyResponse,
      space: spaceId || currentSpaceId,
      packages: updateSpacePackageIds,
      meeting_packages: updateMeetingPackageIds,
      // spacePackageIds,
      // meetingPackageIds,
      start_date: startTime,
    };
  };

  const updateCompany = (e) => {
    e.preventDefault();
    const {
      spaceId,
      name,
      email,
      profileImg,
      address,
      // securityDeposit,
      phoneNo,
      status,
      contactPerson,
    } = data;
    let updateSpaceId = +spaceId;

    let updateData = {
      // spaceId: parseInt(spaceId || currentSpaceId),
      name,
      email,
      profileImg,
      address,
      phoneNo,
      status,
      // securityDeposit,
      contactPerson,
      company: true,
    };

    if (file) {
      setApiSpinner(true);

      updateSpaceCompany({
        spaceId: spaceId || currentSpaceId,
        companyId,
        updateData: updateData,
      });
    } else {
      let filename = null;
      if (profileImg) {
        const parts = profileImg.split("/");
        const lastPart = parts[parts.length - 1];
        filename = lastPart.split("?")[0];
        if (filename.includes("%20")) {
          filename = filename.replace(/%20/g, " ");
        }
        setApiSpinner(true);

        updateSpaceCompany({
          spaceId: spaceId || currentSpaceId,
          companyId,

          updateData: {
            // spaceId: spaceId || currentSpaceId,
            name,
            email,
            address,
            phoneNo,
            status,
            // securityDeposit,
            contactPerson,
            profileImg: filename,
            company: true,
          },
        });
      } else {
        setApiSpinner(true);

        updateSpaceCompany({
          spaceId: spaceId || currentSpaceId,
          companyId,

          updateData: {
            // spaceId: spaceId || currentSpaceId,
            name,
            email,
            address,
            phoneNo,
            status,
            // securityDeposit,
            contactPerson,
            profileImg: "",
            company: true,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (resultStats?.error) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.message);
    }

    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      if (resultStats?.data?.id) {
        setCompanyResponse(resultStats?.data?.id);
      } else {
        setCompanyResponse(resultStats?.data?.companyId);
      }
      toast.success("SuccessFully Added!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      handleClose();
    }
  }, [resultStats]);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" style={{ transition: "none" }}>
            {membersPermissions[0]?.update ? (
              "Update"
            ) : (
              <span>
                Update <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="mx-2 p-0"
          onClick={membersPermissions[0]?.update ? handleShow : null}
          style={{ transition: "none" }}
        >
          {" "}
          <EditIcon />
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Update Company
            {/* <b>{companyName}</b> */}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={updateCompany}>
          <Modal.Body>
            <section>
              {/* <div className={`${companyResponse ? "d-none" : ""}`}> */}
              <div>
                <div className="form-group">
                  <label htmlFor="name">Company Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    placeholder="Enter Company Name"
                    className="form-control"
                    value={data?.name}
                    onChange={handle}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Valid Email"
                    id="email"
                    required
                    value={data?.email}
                    className="form-control"
                    onChange={handle}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Contact Person</label>
                  <input
                    type="text"
                    name="contactPerson"
                    id="contactPerson"
                    required
                    placeholder="Enter Contact Person Name"
                    className="form-control"
                    value={data?.contactPerson}
                    onChange={handle}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="name">Address</label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Enter Valid Address"
                    className="form-control"
                    value={data?.address}
                    onChange={handle}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Phone</label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    name="phoneNo"
                    value={data.phoneNo}
                    // onChange={handleInfo}
                    // min={0}
                    required
                    // onChange={(e) => {
                    //   setCompanyInfo({
                    //     ...companyInfo,
                    //     phoneNo: e,
                    //   });
                    // }}
                    onChange={handlePhone}
                  />
                  {/* <input
                    type="text"
                    name="phoneNo"
                    placeholder="Enter 11-15 Digit Phone Number"
                    id="phoneNo"
                    required
                    value={data?.phoneNo}
                    className="form-control"
                    onChange={handlePhone}
                  /> */}
                  {errorMessage === "Invalid Phone" && (
                    <p style={{ color: "red" }}>
                      *Invalid Phone Number Length(11-15 Digits)
                    </p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="address">Status</label>
                  <select
                    name="status"
                    className="form-control"
                    value={data?.status}
                    onChange={handle}
                  >
                    <option value={1}>Active</option>
                    <option value={2}>Inactive</option>
                  </select>
                </div>
                {/* <div className="form-group">
                  <label htmlFor="securityDeposit">Security Deposit</label>
                  <input
                    type="number"
                    name="securityDeposit"
                    placeholder="Enter Ammount"
                    id="securityDeposit"
                    min={0}
                    value={data?.securityDeposit}
                    className="form-control"
                    onChange={handle}
                  />
                </div> */}
                <div className="form-group">
                  <label className="fs-6 mb-2">
                    Profile Picture <span className="optional">(Optional)</span>
                  </label>
                  <div className="media">
                    <div className="media-aside uploadgn-self-start">
                      <img
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : profileImg
                            ? profileImg
                            : "https://placehold.jp/90x90.png"
                        }
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                        }}
                        className="rounded"
                      />
                    </div>
                    <div>
                      <input
                        id="real-file"
                        name="file"
                        type="file"
                        hidden="hidden"
                        onChange={handleFileChange}
                      />
                    </div>
                    <div className="media-body mt-2">
                      {isUploading ? (
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Uploading...</span>
                        </div>
                      ) : (
                        <button
                          id="custom-button"
                          onClick={upload}
                          className="btn btn-secondary me-2 btn-sm"
                        >
                          Upload
                        </button>
                      )}
                      <p className="card-text mt-2">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                        Allowed Max size of 10Mb
                        {/* <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              Allowed Max size of 10Mb
                            </Tooltip>
                          }
                        >
                          <Button variant="white" className="mx-2 p-0">
                            <Icon.InfoCircle
                              style={{ color: "currentcolor" }}
                            />
                          </Button>
                        </OverlayTrigger> */}
                      </p>
                      {/* {!data?.profileImg ? (
                        <div className="invalid-feedback">
                          Please select an image.
                        </div>
                      ) : null} */}
                    </div>
                  </div>
                </div>
              </div>
              {/* {companyResponse && (
                <>
                  <div className="form-group">
                    <label htmlFor="package-facilities" className="mb-1">
                      Space Packages (Select Multiple)
                    </label>
                    {FetchPackageData?.length > 0 &&
                      rows?.map((input, index) => (
                        <div key={index} className="repeater-wrapper my-2">
                          <div className="">
                            <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0 pe-3">
                                <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0">
                                  <p className="card-text col-title mb-md-50 mb-0">
                                    Item
                                  </p>
                                  <select
                                    name="packageId"
                                    value={input?.packageId}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    className="form-control"
                                  >
                                    {FetchPackageData?.map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>

                                <div className="col-lg-4 col-12 my-lg-0 my-2">
                                  <p className="card-text col-title mb-0">
                                    Quantity
                                  </p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="1"
                                    min={1}
                                    name="quantity"
                                    value={input?.quantity}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                                <button
                                  className="bg-white"
                                  onClick={() => handleRemoveRow(index)}
                                >
                                  <CancelIcon />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {FetchPackageData?.length <= 0 && (
                      <p>No Package is Available</p>
                    )}
                    <div className="">
                      <div className="px-0 mt-3">
                        <button
                          type="button"
                          className="btn btn-primary send-invoice-btn"
                          data-repeater-create=""
                          onClick={handleAddRow}
                        >
                          Add Item
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="meetingPackageIds" className="mb-1">
                      Meeting Packages (Select Multiple)
                    </label>

                    {FetchMeetingPackageData.length > 0 &&
                      meetingRows?.map((input, index) => (
                        <div key={index} className="repeater-wrapper my-2">
                          <div className="">
                            <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0 pe-3">
                                <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0">
                                  <p className="card-text col-title mb-md-50 mb-0">
                                    Item
                                  </p>
                                  <select
                                    name="packageId"
                                    value={input?.packageId}
                                    onChange={(e) =>
                                      handleMeetingInputChange(e, index)
                                    }
                                    className="form-control"
                                  >
                                    {FetchMeetingPackageData?.map(
                                      (item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                </div>

                                <div className="col-lg-4 col-12 my-lg-0 my-2">
                                  <p className="card-text col-title mb-0">
                                    Quantity
                                  </p>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="1"
                                    min={1}
                                    name="quantity"
                                    value={input?.quantity}
                                    onChange={(e) =>
                                      handleMeetingInputChange(e, index)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                                <button
                                  className="bg-white"
                                  onClick={() => handleRemoveMeetingRow(index)}
                                >
                                  <CancelIcon />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {FetchMeetingPackageData?.length <= 0 && (
                      <p>No Meeting Package is Available</p>
                    )}
                    <div className="">
                      <div className="px-0 mt-3">
                        <button
                          type="button"
                          className="btn btn-primary send-invoice-btn"
                          data-repeater-create=""
                          onClick={handleAddMeetingRow}
                        >
                          Add Item
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">Enter Date and Time:</label>
                    <input
                      type="datetime-local"
                      name="startTime"
                      className="form-control"
                      value={data?.startTime}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )} */}
            </section>
            <>
              <Button
                variant="primary"
                disabled={isUploading || apiSpinner}
                type="submit"
              >
                Update Company{" "}
                {isUploading || apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <Button
                variant="secondary"
                disabled={apiSpinner}
                onClick={handleClose}
                className="ml-3"
              >
                Cancel
              </Button>
            </>
          </Modal.Body>
          {/* <Modal.Footer>
          
            <>
              <Button
                variant="primary"
                disabled={isUploading || apiSpinner}
                type="submit"
              >
                Update Company{" "}
                {isUploading || apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <Button
                variant="primary"
                disabled={apiSpinner}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </>
          </Modal.Footer> */}
        </form>
      </Modal>
    </div>
  );
};

export default UpdateCompanyDetails;
