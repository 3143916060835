export const handleDates = (event, refetch) => {
  event.preventDefault()
  //   console.log('Filtering data from:', start, 'to', end)
  refetch()
  // setStart('')
  // setEnd('')
}
export const handleChange = (selectedOption, setSelectedOption) => {
  setSelectedOption(selectedOption)
  // console.log(`Option selected:`, selectedOption)
}

export const handlePagesize = (event, setPage, setPage_size) => {
  setPage(1)
  setPage_size(event.target.value || 10)
  // console.log('Selected value:', event.target.value)
}
