import React, { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import {
  useAddPaymentMutation,
  useAssignPackageToMemberMutation,
  useCreateCompanyMutation,
} from "../../Slices/spaceApi";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";

import axios from "axios";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";

import { useGlobalContext } from "../../Context/GlobalContext";

const AddPaymentModal = ({
  totalAmount,
  paymentMethods,
  invoiceId,
  refund,
}) => {
  const { membersPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [addPayment, resultStats] = useAddPaymentMutation();
  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [amountEmptyError, setAmountEmptyError] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const resetForm = () => {};

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setPaymentAmount("");
    setPaymentMethod("");
    setAmountEmptyError(false);
    setPaymentMethodError(false);
  };
  const handleShow = () => setShow(true);
  const handlePaymentMethod = (e) => {
    setPaymentMethod(e.id);
  };
  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }

    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      setTimeout(() => {
        resetForm();
      }, 1500);
      handleClose();

      toast.success(`${refund ? "Refund" : "Payment"} Added Successfully`, {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  const handleAddPayment = (e) => {
    e.preventDefault();
    if (!paymentAmount && !paymentMethod) {
      setAmountEmptyError(true);
      setPaymentMethodError(true);

      return;
    }
    if (!paymentAmount) {
      setAmountEmptyError(true);
      return;
    }
    if (!paymentMethod) {
      setPaymentMethodError(true);
      return;
    }
    setApiSpinner(true);
    const updatedData = {
      amount: parseFloat(paymentAmount),
      paymentMethod: parseInt(paymentMethod),
      type: refund ? 2 : 1,
    };
    refund
      ? addPayment({
          spaceId: spaceId || currentSpaceId,
          invoiceId: invoiceId,
          data: updatedData,
        })
      : addPayment({
          spaceId: spaceId || currentSpaceId,
          invoiceId: invoiceId,
          data: updatedData,
        });
  };

  return (
    <>
      <Button
        // variant="primary"
        onClick={membersPermissions[0]?.create ? handleShow : null}
        className="btn btn-primary w-100"
      >
        {membersPermissions[0]?.create ? (
          <>
            {!refund ? (
              <span className="invoice">
                <Icon.CurrencyDollar className="mb-1" /> Add Payment
              </span>
            ) : (
              <span className="invoice ">
                <Icon.CashStack className="mb-1" /> Issue Refund
              </span>
            )}
          </>
        ) : (
          <span className="invoice">
            <>
              {!refund ? (
                <span className="invoice">
                  <Icon.CurrencyDollar className="mb-1" /> Add Payment
                </span>
              ) : (
                <span className="invoice">
                  <Icon.CashStack className="mb-1" /> Issue Refund
                </span>
              )}
            </>
            <Lock />
          </span>
        )}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fade
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h3">
            {refund ? "Issue Refund" : "Add Payment"}
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleAddPayment}>
          <Modal.Body>
            <section>
              <div
              //   className={`${companyResponse ? "d-none" : ""}`}
              >
                {!refund && (
                  <div class="d-flex justify-content-between bg-lighter p-2 mb-3">
                    <p class="mb-0">Invoice Balance:</p>
                    <p class="fw-medium mb-0">{totalAmount}</p>
                  </div>
                )}

                <div className="form-group">
                  <label htmlFor="paymentAmount">
                    {refund ? "Refund Ammount" : "Payment Amount"}
                  </label>

                  <input
                    type="number"
                    className={`form-control ${
                      amountError || amountEmptyError ? "is-invalid" : ""
                    }`}
                    placeholder="100"
                    name="paymentAmount"
                    value={paymentAmount}
                    onChange={(e) => {
                      const amountValue = e.target.value;
                      setPaymentAmount(amountValue);
                      setAmountError(amountValue < 0);
                      setAmountEmptyError(amountValue === "");
                    }}
                    onBlur={() => setAmountEmptyError(paymentAmount === "")}
                    onFocus={() => setAmountEmptyError(false)}
                    min={0.00001}
                    step={0.00001}
                    // required
                  />
                  {amountError && (
                    <div className="invalid-feedback">
                      Amoun cannot be negative.
                    </div>
                  )}
                  {amountEmptyError && (
                    <div className="invalid-feedback">
                      Amount cannot be empty.
                    </div>
                  )}
                </div>

                <div class="mb-3">
                  <label class="form-label" for="payment-method">
                    {refund ? "Refund Method" : "Payment Method"}
                  </label>

                  {/* <Select
                    name="paymentMethod"
                    options={paymentMethods}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    styles={colorStyles}
                    value={paymentMethods?.find(
                      (obj) => obj.id === paymentMethod
                    )}
                    onChange={handlePaymentMethod}
                  /> */}
                  <Select
                    className={`${paymentMethodError ? "is-invalid" : ""}`}
                    name="paymentMethod"
                    options={paymentMethods}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    styles={colorStyles}
                    value={paymentMethods?.find(
                      (obj) => obj.id === paymentMethod
                    )}
                    onChange={(selectedOption) => {
                      setPaymentMethod(selectedOption?.id);
                      setPaymentMethodError(!selectedOption);
                    }}
                  />
                  {paymentMethodError && (
                    <div className="invalid-feedback">
                      Please select a payment method.
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <Button
                  variant="primary"
                  disabled={isUploading || apiSpinner}
                  type="submit"
                >
                  {refund ? "Issue Refund" : "Add Payment"}{" "}
                  {isUploading || apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </Button>
                <button
                  className="btn btn-secondary ml-3"
                  disabled={apiSpinner || isUploading}
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </section>
          </Modal.Body>
          {/* <Modal.Footer> */}
        </form>
      </Modal>
    </>
  );
};

export default AddPaymentModal;
