import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EmojiPicker from "react-emoji-picker";

import { useSelector } from "react-redux";
import {
  useCreateAnnouncementMutation,
  useGetAllMembersQuery,
} from "../../Slices/spaceApi";
import Select from "react-select";
import { colorStyles } from "../../utils/constant";
import { useNavigate } from "react-router-dom";
import {
  FaApple,
  FaCloudUploadAlt,
  FaFileExport,
  FaFileUpload,
  FaPaperPlane,
  FaTimes,
} from "react-icons/fa";
import Loader from "../Loader/Loader";
import toast from "react-hot-toast";
import { requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import { useGlobalContext } from "../../Context/GlobalContext";

const EmailComposer = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Announcements");
  }, []);
  const { data: allMembers, isLoading: loading } = useGetAllMembersQuery(
    spaceId || currentSpaceId
  );

  const [createAnnouncement, resultStats] = useCreateAnnouncementMutation();

  const [message, setMessage] = useState("");
  const [activeTab, setActiveTab] = useState("message");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [emailTo, setEmailTo] = useState([]);
  const [emailToError, setEmailToError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [apiCallCheck, setApiCallCheck] = useState(false);

  const handle = (e) => {
    setEmailToError("");
    if (e.some((option) => option.id === "all")) {
      setEmailTo(allMembers?.users?.map((facility) => facility.id));
    } else {
      setEmailTo(e.map((x) => x?.id));
    }
  };
  const handleSubject = (e) => {
    setSubjectError("");
    setSubject(e.target.value);
  };

  const [uploadFiles, setUploadFiles] = useState([]);

  const handleFileChange = (e) => {
    const filesArray = Array.from(e.target.files);
    setUploadFiles([...uploadFiles, ...filesArray]);
  };

  const removeFile = (index) => {
    const newFiles = [...uploadFiles];
    newFiles.splice(index, 1);
    setUploadFiles(newFiles);
  };
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const insertEmoji = (emoji) => {
    const emojiChar = emoji.native || emoji;
    const cursorPos = message.length;
    const newMessage =
      message.slice(0, cursorPos) + emojiChar + message.slice(cursorPos);
    setMessage(newMessage);
    setShowEmojiPicker(false);
  };

  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    if (!emailTo?.length > 0) {
      setEmailToError("*Please Select Members");
      return;
    } else if (!subject) {
      setSubjectError("*Please Enter Subject");
    } else if (!message) {
      setMessageError("*Please Write Message");
    } else {
      const fd = new FormData();
      uploadFiles.forEach((file, index) => {
        fd.append(`images[${index}]`, file);
      });
      setApiCallCheck(true);
      axios
        .post("/uploadImage/", fd)
        .then((response) => {
          const data = {
            to: emailTo,
            subject: subject,
            content: message,
            attachments: response.data,
          };
          createAnnouncement({
            spaceId: spaceId || currentSpaceId,
            data: data,
          });
        })
        .catch((error) => {
          setApiCallCheck(false);
          console.error("Error uploading files:", error);
          // Handle error as needed
        });
    }
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiCallCheck(false);

      requiredAlert(resultStats?.error?.data?.message);
    }

    if (resultStats?.isSuccess) {
      setApiCallCheck(false);

      toast.success("Announcement Created Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setTimeout(() => {
        navigate("/announcements");
      }, 1000);
    }
  }, [resultStats]);

  if (loading) {
    return <Loader height={6.6} />;
  }
  return (
    <div className="container mt-5">
      <h1>Email Composer</h1>

      <form>
        <div className="mb-3">
          <label htmlFor="to" className="form-label">
            To:
          </label>
          {allMembers?.users?.length > 0 ? (
            <Select
              options={[{ id: "all", name: "All" }, ...allMembers?.users]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              styles={colorStyles}
              closeMenuOnSelect={false}
              value={allMembers?.users?.filter((obj) =>
                emailTo?.includes(obj.id)
              )}
              onChange={handle}
              isMulti
              id="email-to"
            />
          ) : (
            <div
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                // navigate("/member/list");
                navigate("/");
              }}
            >
              Please Add Members First
            </div>
          )}
          {emailToError && (
            <p style={{ color: "red", marginLeft: "10px" }}>{emailToError}</p>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="subject" className="form-label">
            Subject:
          </label>
          <input
            type="text"
            className="form-control"
            id="subject"
            name="subject"
            required
            value={subject}
            onChange={(e) => handleSubject(e)}
          />
          {subjectError && (
            <p style={{ color: "red", marginLeft: "10px" }}>{subjectError}</p>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="message" className="form-label">
            Message:
          </label>

          <ReactQuill
            value={message}
            onChange={(content) => {
              setMessageError("");
              setMessage(content);
            }}
            // style={{ height: "20rem" }}
            className="form-control"
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                ["clean"],
              ],
            }}
          />
          {messageError && (
            <p style={{ color: "red", marginLeft: "10px" }}>{messageError}</p>
          )}
        </div>

        {/* <div className="mt-2">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "attachFile" ? "active" : ""
                }`}
                type="button"
                onClick={() => setActiveTab("attachFile")}
              >
                Attach File
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "emoji" ? "active" : ""}`}
                type="button"
                onClick={() => setActiveTab("emoji")}
              >
                😊
              </button>
            </li>
          </ul>
        </div> */}

        <div className="mt-3">
          {/* {activeTab === "attachFile" && (
            <> */}
          <label htmlFor="attachment" className="btn btn-primary">
            <FaCloudUploadAlt style={{ marginRight: "5px" }} />
            Attach File ({uploadFiles?.length})
          </label>
          <input
            type="file"
            className="form-control"
            style={{ display: "none" }}
            id="attachment"
            name="attachment"
            multiple
            onChange={handleFileChange}
          />
          <div className="selected-files">
            {uploadFiles?.map((file, index) => (
              <div key={index} className="selected-file">
                {file.name}
                <FaTimes onClick={() => removeFile(index)} />
              </div>
            ))}
          </div>
          {/* </>
          )} */}

          {/* {activeTab === "emoji" && (
            <div className="emoji-picker-container">
              <EmojiPicker onSelect={insertEmoji} />
            </div>
          )} */}
        </div>

        <button
          type="button"
          className="btn btn-primary mt-3"
          onClick={(e) => sendEmail(e)}
          disabled={apiCallCheck}
        >
          Send Email{" "}
          <FaPaperPlane style={{ marginLeft: "5px", marginBottom: "5px" }} />
          {apiCallCheck ? (
            <div
              className="spinner-border text-primary"
              role="status"
              style={{
                width: "20px",
                height: "20px",
                marginLeft: "5px",
              }}
            ></div>
          ) : (
            ""
          )}
        </button>
      </form>
    </div>
  );
};

export default EmailComposer;
