import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast, Toaster } from "react-hot-toast";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import axios from "axios";
import { useUpdateSpaceMutation } from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { requiredAlert } from "../../utils/Alerts";

const AddKisiForm = ({ spaceData }) => {
  const { membersPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [updateGeneral, resultStats] = useUpdateSpaceMutation();

  const [step, setStep] = useState(1);
  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);

  const [kisiInfo, setKisiInfo] = useState({
    kisiApiKey: "",
    kisiOrganizationId: "",
    kisiPlaceId: "",
  });

  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [placeOptions, setPlaceOptions] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setApiSpinner(false);

    setStep(1);
    setKisiInfo({
      kisiApiKey: "",
      kisiOrganizationId: "",
      kisiPlaceId: "",
    });
  };
  const handleShow = () => setShow(true);

  const handleInfo = (e) => {
    setKisiInfo({ ...kisiInfo, [e.target.name]: e.target.value });
  };

  const handleApiKeySubmit = async (e) => {
    e.preventDefault();
    setApiSpinner(true);

    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: { kisiApiKey: kisiInfo?.kisiApiKey },
    });
  };

  const handleOrganizationSubmit = async (e) => {
    e.preventDefault();
    setApiSpinner(true);

    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: { kisiOrganizationId: parseInt(kisiInfo?.kisiOrganizationId) },
    });
  };

  const handlePlaceSubmit = async (e) => {
    e.preventDefault();
    setApiSpinner(true);

    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: { kisiPlaceId: parseInt(kisiInfo?.kisiPlaceId) },
    });
  };
  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error);
      setApiSpinner(false);
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      if (step == 1) {
        setOrganizationOptions(resultStats?.data);
        setStep(2);
      } else if (step == 2) {
        setPlaceOptions(resultStats?.data);
        setStep(3);
      } else if (step == 3) {
        toast.success("Kisi Integrated Successfully.", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        handleClose();
      }
    }
  }, [resultStats]);

  return (
    <>
      <Button
        variant="primary"
        onClick={membersPermissions[0]?.create ? handleShow : null}
        className="send-invoice-btn mt-5"
      >
        {membersPermissions[0]?.create ? (
          <span className="invoice">
            {spaceData?.kisiApiKey ? "Update" : "Integrate"} Kisi
          </span>
        ) : (
          <span className="invoice">
            {spaceData?.kisiApiKey ? "Update" : "Integrate"} Kisi
            <Lock />
          </span>
        )}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fade
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h3">Kisi Integration</Modal.Title>
        </Modal.Header>
        <form
          onSubmit={
            step === 1
              ? handleApiKeySubmit
              : step === 2
              ? handleOrganizationSubmit
              : handlePlaceSubmit
          }
        >
          <Modal.Body>
            {step === 1 && (
              <div className="form-group">
                <label htmlFor="kisiApiKey">Enter Kisi Api Key</label>
                <input
                  type="text"
                  name="kisiApiKey"
                  className="form-control"
                  placeholder="KISI API Key"
                  value={kisiInfo.kisiApiKey}
                  onChange={handleInfo}
                  required
                />
              </div>
            )}

            {step === 2 && (
              <div className="form-group">
                <label htmlFor="kisiOrganizationId">Kisi Organization</label>
                <select
                  name="kisiOrganizationId"
                  className="form-control"
                  value={kisiInfo.kisiOrganizationId}
                  onChange={handleInfo}
                  required
                >
                  <option value="">Select Organization</option>
                  {organizationOptions?.map((org) => (
                    <option key={org.id} value={org.id}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {step === 3 && (
              <div className="form-group">
                <label htmlFor="kisiPlaceId">Place</label>
                <select
                  name="kisiPlaceId"
                  className="form-control"
                  value={kisiInfo.kisiPlaceId}
                  onChange={handleInfo}
                  required
                >
                  <option value="">Select Place</option>
                  {placeOptions?.map((place) => (
                    <option key={place.id} value={place.id}>
                      {place.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="mt-4">
              <Button variant="primary" disabled={apiSpinner} type="submit">
                {step === 3 ? "Integrate" : "Next"}{" "}
                {apiSpinner && (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                disabled={apiSpinner || isUploading}
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
      {/* <Toaster /> */}
    </>
  );
};

export default AddKisiForm;
