import React, { useEffect } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import "./styles.css";
import toast, { Toaster } from "react-hot-toast";
import { useAssetCategoryListQuery } from "../../Slices/spaceApi";
import { Tab, Tabs } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import AssetList from "./AssetList";
import AssetCategory from "./AssetCategory";
import { useSelector } from "react-redux";

// import AssetContext from "../../Context/AssetContext";
const Asset = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  // const { data: categories } = useAssetCategoryListQuery({
  //   spaceId: spaceId || currentSpaceId,
  // });
  return (
    <>
      {/* <AssetContext.Provider value={{ categories }}> */}
      <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
        {/* <div className="card mt-5">
            <div className="container mt-5">
              <div className="row ">
                <Link to="/asset" className="col-md-2 ">
                  <button className="btn btn-primary btn-md w-100 mb-3">
                    Asset
                  </button>
                </Link>

                <Link to="/asset/category" className="col-md-2">
                  <button className="btn btn-primary btn-md w-100 mb-3">
                    Asset Category
                  </button>
                </Link>
              </div>
            </div>

            <Outlet />
          </div> */}
        <Tabs
          defaultActiveKey="Account"
          id="justify-tab-example"
          className="mb-3"
          variant="underline"
          justify
        >
          <Tab
            eventKey="Account"
            title={
              <span className="d-flex align-items-center">
                <Icon.Archive /> &nbsp;Assets List
              </span>
            }
          >
            {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <AssetList />
          </Tab>
          <Tab
            eventKey="Social"
            title={
              <span className="d-flex align-items-center">
                <Icon.UiChecksGrid /> &nbsp; Assets Category
              </span>
            }
          >
            {/* <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <AssetCategory />
          </Tab>
        </Tabs>
      </section>
      {/* </AssetContext.Provider> */}
    </>
  );
};

export default Asset;
