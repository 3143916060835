import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";

import { Link } from "react-router-dom";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { useGlobalContext } from "../../Context/GlobalContext";

const ViewCompanyEmployeeMiddlWare = ({ id }) => {
  const { membersPermissions } = useGlobalContext();

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {membersPermissions[0]?.update ? (
              "Employee Details"
            ) : (
              <span>
                Update <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        {membersPermissions[0]?.update ? (
          <Button
            variant="white"
            className="mx-2 p-0"
            as={Link}
            to={`/company/employee/${id}/view`}
          >
            <Preview />
          </Button>
        ) : (
          <Button variant="white" className="mx-2 p-0">
            <Preview />
          </Button>
        )}
      </OverlayTrigger>
    </div>
  );
};

export default ViewCompanyEmployeeMiddlWare;
