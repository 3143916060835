import React, { useState } from "react";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import { Button } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useDeleteRoleMutation,
  useDeleteTeamMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useEffect } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { ReactComponent as Lock } from "../../Assets//Lock.svg";

function DeleteRole({ id, name }) {
  const { rolesPermissions } = useGlobalContext();
  console.log("Id and name is", id, name);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [deleteRole, resultStats] = useDeleteRoleMutation();
  const [apiSpinner, setApiSpinner] = useState(false);
  const handleDelete = (e) => {
    e.preventDefault();
    confirmationAlert("Want to Delete Role?").then((willDelete) => {
      if (willDelete) {
        setApiSpinner(true);
        deleteRole({
          spaceId: spaceId || currentSpaceId,
          id: id,
        });
      }
    });
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert(`Role ${name} Deleted Successfully.`);
      setTimeout(() => {
        setApiSpinner(false);
      }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(
        resultStats?.error?.data?.error || resultStats?.error?.data
      );
      setTimeout(() => {
        setApiSpinner(false);
      }, 500);
    }
  }, [resultStats]);
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip" style={{ transition: "none" }}>
            {rolesPermissions[0]?.delete ? (
              "Delete Role"
            ) : (
              <span>
                Delete
                <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          id="viewMeetingOpen"
          onClick={rolesPermissions[0]?.delete ? handleDelete : null}
          style={{ transition: "none" }}
          disabled={apiSpinner}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
}

export default DeleteRole;
