import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateSpaceMutation } from "../../Slices/spaceApi";
import toast from "react-hot-toast";
import { requiredAlert } from "../../utils/Alerts";
import { Form } from "react-bootstrap";

const AutoEmailSent = ({ spaceData }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();
  const [autoEmailSent, setAutoEmailSent] = useState(
    spaceData?.auto_email_invoice
  );
  useEffect(() => {
    setAutoEmailSent(spaceData?.auto_email_invoice);
  }, [spaceData]);
  const [apiSpinner, setApiSpinner] = useState(false);
  const handleAutoEmailSent = () => {
    setAutoEmailSent((prevValue) => {
      const newValue = !prevValue;
      return newValue;
    });
    setApiSpinner(true);
    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: {
        auto_email_invoice: !autoEmailSent,
      },
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Invoice Sent as Email Configured SuccessFully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 1000);
      setApiSpinner(false);
    }
  }, [resultStats]);
  return (
    <div>
      <div className="card">
        <div className="row p-4">
          <h4>Invoice Sent Configuration</h4>

          <div className="form-group">
            <div className="d-flex">
              <small className="">Auto Sent Invoice as Email</small>
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch1"
                  label=""
                  className="ml-4"
                  value={autoEmailSent}
                  checked={autoEmailSent}
                  onChange={handleAutoEmailSent}
                  disabled={apiSpinner}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoEmailSent;
