import React, { useState, useEffect } from "react";

export function TimeAgo({ date }) {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    function updateTimeAgo() {
      const parsedDate = new Date(date);
      const now = new Date();

      const elapsed = now - parsedDate;
      const seconds = Math.floor(elapsed / 1000);

      if (seconds < 60) {
        setTimeAgo("just now");
      } else {
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (hours > 0) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        }
      }
    }

    updateTimeAgo(); // Calculate the initial "time ago" value

    const intervalId = setInterval(updateTimeAgo, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [date]);

  return <span>{timeAgo}</span>;
}
