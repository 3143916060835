import React, { useEffect } from "react";
import { IntegryJS } from "@integry/sdk";
import { useSelector } from "react-redux";
import {
  useGetIntegryHashQuery,
  usePostCallBackUrlMutation,
} from "../../Slices/spaceApi";

const IntegryWidget = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: integryHash, isLoading: hashLoading } = useGetIntegryHashQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [postCallBackUrl, resultStats] = usePostCallBackUrlMutation();

  useEffect(() => {
    if (integryHash) {
      const integryHandler = new IntegryJS({
        appKey: "447d5011-0c08-4bd6-9e79-31d9b6b4c5c2",
        hash: integryHash?.hash,
        userId: (spaceId || currentSpaceId).toString(),
        xIntegryConfig: {
          appAuth: {
            apiKey: "", // Add your App API key here. It will be used to authenticate the user
          },
        },
        options: {
          title: "Apps", // Add title here e.g. "Apps"
          tags: ["Production"], // Which Apps do you want to show? Pass tag names in an array e.g. ["Production","Beta"]
          objects: {
            Member: {
              id: 1,
              name: "Alice",
              email: "alice@example.com",
              phone: "555-555-1234",
              address: "Lahore, Pakistan",
            },
            Event: {
              id: 1,
              name: "Product Launch Event",
              description:
                "Join us for the exciting product launch event and be the first to experience our innovative features.",
              created_at: "2023-05-25T00:00:00Z",
              start_time: "2023-05-25T09:00:00Z",
              end_time: "2023-05-25T10:00:00Z",
            },
            Ticket: {
              id: 1,
              name: "Please improve search functionality",
              assignees: ["John", "Jane"],
              creator: "Alice Johnson",
              due_date: "2023-05-25T10:30:00Z",
              status: "IN_PROGRESS",
              description:
                "Can you please enhance the search functionality? It would greatly benefit our users!",
              project: "ABC Company",
              ticket_type: "task",
              account: "ACME Corp",
              parent_ticket: "",
              tags: ["feature-request", "search"],
              created_at: "2023-05-25T09:15:00Z",
              updated_at: "2023-05-25T09:30:00Z",
              completed_at: null,
              ticket_url: "https://abccompany.com/project/5/issue/12",
              priority: "MEDIUM",
              severity: "LOW",
              customer_name: "Bob Johnson",
              estimated_hours: 5.5,
              attachments: [
                {
                  name: "screenshot.png",
                  url: "https://abccompany.com/project/5/issue/12/attachments/screenshot.png",
                },
              ],
            },
            Meeting: {
              id: 1,
              title: "Sample Meeting",
              description: "This is a sample meeting",
              start_time: "2023-05-26T09:00:00Z",
              end_time: "2023-05-26T10:00:00Z",
              location: "Online",
              URL: "",
              attendees: [
                {
                  email: "attendee1@example.com",
                  status: "Accepted",
                },
                {
                  email: "attendee2@example.com",
                  status: "Pending",
                },
              ],
              created_at: "2023-05-25T19:00:00Z",
              updated_at: "2023-05-25T19:05:00Z",
            },
            Lead: {
              id: "1234567890",
              owner: "John Doe",
              lead_source: "Website Advertisement",
              title: "Marketing Manager",
              company: "ABC Corporation",
              first_name: "Jane",
              last_name: "Smith",
              addresses: [
                {
                  street_1: "123 Main Street",
                  street_2: "Apt 4B",
                  city: "New York",
                  state: "NY",
                  postal_code: "10001",
                  country: "USA",
                  address_type: "Billing",
                },
              ],
              email_addresses: [
                {
                  email_address: "jane.smith@example.com",
                  email_address_type: "Personal",
                },
              ],
              phone_numbers: [
                {
                  phone_number: "+15555555555",
                  phone_number_type: "Work",
                },
              ],
              updated_at: "2023-04-15T09:30:00Z",
              created_at: "2023-01-20T14:45:00Z",
              converted_date: "2023-03-25T18:20:00Z",
              converted_contact: "John Brown",
              converted_account: "ABC Corporation",
            },
            Invoice: {
              type: "PURCHASE",
              contact: "John",
              number: "INV98765",
              issue_date: "2023-05-10T15:30:00Z",
              due_date: "2023-05-25T15:30:00Z",
              paid_on_date: "2023-05-15T15:30:00Z",
              memo: "Monthly Subscription",
              company: "ABC Corporation",
              currency: "EUR",
              exchange_rate: "1.2",
              total_discount: 10,
              sub_total: 500,
              total_tax_amount: 25,
              total_amount: 515,
              balance: 515,
              updated_at: "2023-05-15T15:30:00Z",
              payments: ["PAY123", "PAY456"],
              line_items: [
                {
                  item_name: "Product A",
                  quantity: 2,
                  unit_price: 200,
                  line_total: 400,
                },
                {
                  item_name: "Product B",
                  quantity: 1,
                  unit_price: 100,
                  line_total: 100,
                },
              ],
              invoice_status: "PAID",
              shipping_address: {
                street: "123 Main Street",
                city: "New York",
                state: "NY",
                postal_code: "10001",
                country: "USA",
              },
              billing_address: {
                street: "456 Elm Street",
                city: "Los Angeles",
                state: "CA",
                postal_code: "90001",
                country: "USA",
              },
            },
            Expense: {
              id: 1,
              transaction_date: "2023-05-25T05:51:45.844Z",
              remote_created_at: "2020-03-31T00:00:00Z",
              account: "",
              contact: "",
              total_amount: 10000,
              currency: "USD",
              exchange_rate: "2.9",
              company: "",
              memo: "New employee supplies",
              lines: [
                {
                  net_amount: 25.54,
                  company: "",
                  item: "",
                  account: "",
                  contact: "",
                  description: "MacBook Pro",
                  exchange_rate: "2.9",
                },
              ],
              categories: [],
            },
            Employee: {
              id: 1,
              employee_number: "8",
              company: "ABC Corporation",
              first_name: "Emily",
              last_name: "Smith",
              display_full_name: "Emily Smith",
              username: "emilysmith",
              work_email: "emily.smith@abc.com",
              personal_email: "emily.smith@gmail.com",
              mobile_phone_number: "+987654321",
              manager: "John Doe",
              team: "Marketing",
              pay_group: "Salary",
              gender: "FEMALE",
              ethnicity: "ASIAN",
              marital_status: "MARRIED",
              date_of_birth: "1993-05-07T09:30:00Z",
              start_date: "2023-02-15T08:00:00Z",
              created_at: "2023-02-15T08:00:00Z",
              employment_status: "ACTIVE",
              termination_date: null,
              avatar: "https://example.com/emily_smith.jpg",
              job_title: "Marketing Specialist",
              department: "Marketing",
              location: "New York",
              salary: "$60,000",
            },
            Deal: {
              id: 1,
              name: "Sample Deal",
              description: "This is a sample Deal",
              value: 5000,
              currency: "USD",
              client_id: "Client_1",
              stage: "Prospecting",
              probability: 50,
              close_date: "2023-06-30T00:00:00Z",
              created_at: "2023-05-25T18:00:00Z",
              updated_at: "2023-05-25T18:05:00Z",
            },
            Company: {
              id: 1,
              legal_name: "Waystar Royco, Inc.",
              display_name: "Waystar Royco",
              created_at: "2023-05-25T19:00:00Z",
              updated_at: "2023-05-25T19:05:00Z",
            },
            Client: {
              id: 1,
              name: "Sample Client",
              address:
                "123 Sample Street, Sample City, Sample State, Sample Country",
              email: "sample.client@example.com",
              phone: "+1 1234567890",
              billing_info: {
                credit_card: "**** **** **** 1234",
                expiry_date: "2025-12",
              },
              created_at: "2023-05-25T16:00:00Z",
              updated_at: "2023-05-25T16:05:00Z",
            },
            Contact: {
              id: 1,
              firstName: "Alice",
              lastName: "Johnson",
              gender: "female",
              age: 33,
              dateOfBirth: "1990-01-01",
              email: "alice@example.com",
              phone: "555-555-1234",
              address: {
                street: "123 Main St",
                city: "New York",
                state: "NY",
                postalCode: "10001",
                country: "USA",
              },
              lastContacted: "2023-01-15",
              twitter: "@alicejohnson",
              website: "https://www.example.com",
              linkedIn: "https://www.linkedin.com/in/example",
              bio: "Alice Johnson is a software engineer with over 10 years of experience.",
              relationship: "professional",
              tags: ["tech", "conference", "networking"],
              jobTitle: "Software Engineer",
              company: "Example Corp.",
              dateCreated: "2023-04-14T12:00:00Z",
              dateUpdated: "2023-04-14T12:00:00Z",
              nextMeeting: {
                date: "2023-05-01",
                topic: "Project Planning",
                location: "New York",
              },
              notes: "Met at the 2022 Tech Conference in San Francisco.",
            },
          },
        },
      });

      integryHandler.init({
        containerId: "x-integry-container",
        showApps: true,
        renderMode: IntegryJS.RenderModes.INLINE,
        renderAppPageMode: IntegryJS.RenderModes.MODAL,
        renderFlowSetupMode: IntegryJS.RenderModes.MODAL,
        viewStyle: IntegryJS.ViewStyles.COMFORTABLE,
      });

      integryHandler.eventEmitter.on("did-save-integration", (data) => {
        console.log(data);
        console.log("Callback URL: " + data.callbackUrl);
        postCallBackUrl({
          spaceId: spaceId || currentSpaceId,
          data: data,
        });
      });
    }
  }, [integryHash]);

  return <div id="x-integry-container"></div>;
};

export default IntegryWidget;
