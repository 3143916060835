import React from "react";
import { Button, Col, Form, Modal, ModalBody, Row } from "react-bootstrap";
import { Check2, X } from "react-bootstrap-icons";
import { useProfileGetQuery } from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import { LoaderIcon } from "react-hot-toast";
import { useSelector } from "react-redux";
import { formatDateTime } from "../../utils/formateDateTime";

const PurchaseView = ({ handleClose, operateId, operateObject: data }) => {
  // const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  // const {
  //   data: profile,
  //   isLoading: profileLoading,
  //   isError: profileIsError,
  // } = useProfileGetQuery({
  //   spaceId: spaceId || currentSpaceId,
  //   id: data.member,
  // });

  return (
    <div>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="w-10"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Row>Purchased By: {data?.member?.name}</Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Row>
            <h6 className='bold'>Items</h6>
          </Row> */}
          {data?.items?.map((item, index) => (
            <React.Fragment key={index}>
              <Row className="mb-3 mt-3">
                <div className="d-flex align-items-center">
                  <strong className="me-3 mb-0">Product Name:</strong>
                  &nbsp;{item?.product?.name}
                </div>
              </Row>
              <Row className="mb-3 mt-3">
                <div className="d-flex align-items-center">
                  <h6 className="me-3 mb-0">Product Quantity:</h6>
                  <p className="mb-0" id="" name="quantity">
                    {item?.quantity}
                  </p>
                </div>
              </Row>
              <Row className="mb-3 mt-3">
                <div className="d-flex align-items-center">
                  <h6 className="me-3 mb-0">Product Description:</h6>
                  <p className="mb-0" id="description" name="description">
                    {item?.product?.description}
                  </p>
                </div>
              </Row>{" "}
            </React.Fragment>
          ))}
          <Row className="mb-3 mt-3">
            <div className="d-flex align-items-center">
              <h6 className="me-3 mb-0">Purchased at:</h6>
              <p className="mb-0" id="AssettName" name="name">
                {formatDateTime(data?.date)}
              </p>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PurchaseView;
