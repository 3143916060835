import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  AddMember,
  EditMember,
  ViewMember,
  ListMember,
} from "../Components/AddInvoices";
import Loader from "../Components/Loader/Loader";
import {
  useGetPackageQuery,
  useGetSpaceDataQuery,
  useGetSpaceInvoiceQuery,
  useGetAllSpaceUserQuery,
  useGetMeetingRoomPackageQuery,
  useGetAllMembersQuery,
  useGetAllInvoicesQuery,
  useProductListQuery,
} from "../Slices/spaceApi";
import { Lock } from "../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../Context/GlobalContext";

export const AddMembers = () => {
  const { InvoicesPermissions } = useGlobalContext();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Add Invoice");
  }, []);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { isLoading, data: packageData } = useGetPackageQuery(
    spaceId || currentSpaceId
  );

  const { isLoading: loadingMeetingRoomPackage, data: meetingData } =
    useGetMeetingRoomPackageQuery(spaceId || currentSpaceId);
  const { isLoading: loading, data: spaceData } = useGetSpaceDataQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { isLoading: productLoading, data: productData } = useProductListQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { isLoading: loadingUsers, data: userData } = useGetAllMembersQuery(
    spaceId || currentSpaceId
  );

  const { isLoading: invoicesLoading, data: FetchSpaceInvoice } =
    useGetAllInvoicesQuery({
      spaceId: spaceId || currentSpaceId,
      page_size: 10,
      page: 1,
    });
  if (
    isLoading ||
    loading ||
    loadingMeetingRoomPackage ||
    loadingUsers ||
    invoicesLoading ||
    productLoading
  ) {
    return <Loader height={6.6} />;
  }

  return (
    <>
      <div className="reports">
        <AddMember
          data={spaceData}
          FetchSpaceInvoice={FetchSpaceInvoice}
          packageData={packageData}
          userData={userData?.users}
          meetingData={meetingData}
          productData={productData?.products}
        />
      </div>
    </>
  );
};

export const EditMembers = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { isLoading: loading, data: spaceData } = useGetSpaceDataQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { isLoading: loadingUsers, data: userData } = useGetAllSpaceUserQuery(
    spaceId || currentSpaceId
  );

  const { isLoading, data: packageData } = useGetPackageQuery(
    spaceId || currentSpaceId
  );

  const { isLoading: loadingMeetingRoomPackage, data: meetingData } =
    useGetMeetingRoomPackageQuery(spaceId || currentSpaceId);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Invoice Edit");
  }, []);
  return (
    <>
      <div className="reports">
        <EditMember
          data={spaceData}
          userData={userData}
          packageData={packageData}
          meetingData={meetingData}
        />
      </div>
    </>
  );
};

export const ViewMembers = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Invoice View");
  }, []);
  const { isLoading: loading, data: spaceData } = useGetSpaceDataQuery({
    spaceId: spaceId || currentSpaceId,
  });

  return (
    <>
      <div className="reports">
        <ViewMember data={spaceData} />
      </div>
    </>
  );
};

export const ListMembers = () => {
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Invoice List");
  }, []);
  return (
    <>
      <div className="reports">
        <ListMember />
      </div>
    </>
  );
};
