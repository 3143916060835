import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useSelector } from "react-redux";
import { useUpdateSpaceMutation } from "../../Slices/spaceApi";
import toast from "react-hot-toast";
import { confirmationStripeAlert, requiredAlert } from "../../utils/Alerts";

function InvoiceInstructions({ spaceData }) {
  const { settingsPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();
  const [text, setText] = useState(spaceData?.invoiceInstructions); // Using 'text' instead of 'email'
  const [error, setError] = useState("");
  const [textChanged, setTextChanged] = useState(false);

  const handleTextChange = (e) => {
    setText(e.target.value);
    setTextChanged(true);
    setError(""); // Clear error when the user types in the text field
  };

  useEffect(() => {
    setText(spaceData?.invoiceInstructions);
  }, [spaceData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation logic removed for text input

    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: { invoiceInstructions: text }, // Using 'text' instead of 'email'
    });
  };

  const handleRevoke = (e) => {
    e.preventDefault();
    confirmationStripeAlert("Want To Revoke Invoice Instructions?").then(
      (revoke) => {
        if (revoke) {
          updateGeneral({
            spaceId: spaceId || currentSpaceId,
            data: { invoiceInstructions: "" }, // Adjusted to clear brand email
          });
        }
      }
    );
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Invoice Instructions Configured Successfully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 1000);
      setTextChanged(false);
    }
  }, [resultStats]);

  return (
    <div className="card p-4">
      <h4 className="mb-2">Invoice Instructions Configuration</h4>

      <h6>
        Would you like to set up custom invoice instructions for the space? This
        will result in displaying instructions on your members' invoices.
      </h6>
      {/* <form onSubmit={handleSubmit}> */}
      <div className="mb-3 col-md-12">
        <label htmlFor="text" className="form-label">
          Invoice Instructions:
        </label>
        <textarea
          value={text}
          className="form-control"
          id="text"
          onChange={handleTextChange}
          rows="4"
        />
        {error && <p style={{ color: "red", marginTop: "1rem" }}>{error}</p>}
      </div>
      <div>
        <button
          className="btn ml-3 btn-primary"
          disabled={!textChanged}
          onClick={handleSubmit}
        >
          Submit
        </button>
        <button
          className="btn ml-3 btn-outline-secondary"
          //   disabled={!textChanged}
          onClick={handleRevoke}
        >
          Revoke
        </button>
      </div>
      {/* </form> */}
    </div>
  );
}

export default InvoiceInstructions;
