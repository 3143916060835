import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { LogoDetails, SubTotal } from "../AddInvoices";
import {
  useConvertToInvoiceQuery,
  useGetMeetingRoomPackageQuery,
  useGetPackageQuery,
  useGetSpaceDataQuery,
  useSentQuotationMutation,
  useUpdateQuotationsQuery,
} from "../../Slices/spaceApi";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../Loader/Loader";
import {
  useGetCitiesQuery,
  useGetCountiresQuery,
} from "../../Slices/constantApi";
import { useGlobalContext } from "../../Context/GlobalContext";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useDispatch } from "react-redux";
import { sendAcceptRequest } from "../../Pages/Actions";
import {
  NewconfirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import * as Icon from "react-bootstrap-icons";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { Breadcrumb } from "react-bootstrap";

function QuotationView() {
  const {
    quotationsPermission,
    InvoicesPermissions,
    rentalFee,
    serviceCharges,
    isOverAllTax,
    currency,
  } = useGlobalContext();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("View Quotation");
  }, []);
  const dispatch = useDispatch();
  const Loading = useSelector((state) => state.loading);
  const error = useSelector((state) => state.error);
  const successMessage = useSelector((state) => state.successMessage);

  const { setToggleSidebar } = useGlobalContext();
  const { id } = useParams();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: quotation, isLoading: loading } = useUpdateQuotationsQuery({
    spaceId: spaceId || currentSpaceId,
    id: id,
  });

  const { data: spaceData, isLoading: spaceLoading } = useGetSpaceDataQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { data: fetchPackages, isLoading: packageLoading } = useGetPackageQuery(
    spaceId || currentSpaceId
  );

  const { data: fetchMeetingPackages, isLoading: meetingPackageLoading } =
    useGetMeetingRoomPackageQuery(spaceId || currentSpaceId);

  const { data: cities, isLoading: loadingCities } = useGetCitiesQuery();
  const { data: countries, isLoading: loadingCountries } =
    useGetCountiresQuery();

  const [sentQuotation, resultStats] = useSentQuotationMutation();

  const convertToInvoice = () => {
    dispatch(sendAcceptRequest(id, spaceId || currentSpaceId));
  };
  const [rows, setRows] = useState([]);
  const [meetingRows, setMeetingRows] = useState([]);
  const [customerCountry, setCustomerCountry] = useState([]);
  const [customerCity, setCustomerCity] = useState([]);

  useEffect(() => {
    setRows(
      quotation?.packages?.map((item) => ({
        packageId: item?.package,
        quantity: item?.quantity,
        price: fetchPackages?.find((item1) => item1.id === item.package)?.price,
        amount:
          fetchPackages?.find((item1) => item1.id === item.package)?.price *
          item.quantity,
      }))
    );

    setMeetingRows(
      quotation?.meetingPackages?.map((item) => ({
        packageId: item.package,
        quantity: item.quantity,
        price: fetchMeetingPackages?.find((item1) => item1.id === item.package)
          ?.price,
        amount:
          fetchMeetingPackages?.find((item1) => item1.id === item.package)
            ?.price * item.quantity,
      }))
    );
  }, [quotation, fetchPackages, fetchMeetingPackages]);
  const afterDiscount = () => {
    let total = quotation?.subTotal;
    const discountAmount = (total * quotation?.discount) / 100;
    total -= discountAmount;
    return total;
  };

  const downloadFile = (name) => {
    // create a new jsPDF instance
    const doc = new jsPDF();

    // convert HTML content to canvas
    html2canvas(document.querySelector("#content")).then((canvas) => {
      // add canvas to PDF
      const imgData = canvas.toDataURL("image/png");
      const imgProps = doc.getImageProperties(imgData);
      const pdfWidth = doc.internal.pageSize.getWidth() - 20;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(imgData, "PNG", 10, 30, pdfWidth, pdfHeight);

      // save PDF
      doc.save(`${name}.pdf`);
    });
  };

  const print = async () => {
    // await setToggleSidebar(false);

    const style = document.createElement("style");
    style.id = "print-styles";

    window.print();
  };
  // const print = async () => {
  //   await setToggleSidebar(false);
  //   let printContents = document.getElementById("content").innerHTML;
  //   let originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;

  //   window.print();
  //   document.body.innerHTML = originalContents;
  // };
  const sentQuotationAsEmail = (e) => {
    e.preventDefault();
    NewconfirmationAlert(
      "Are you sure to sent this invoice as Email!",
      "",
      "Yes! Sent.",
      "No! Cancel."
    ).then((sent) => {
      if (sent) {
        sentQuotation({
          spaceId: spaceId || currentSpaceId,
          quotationId: id,
        });
      }
    });
  };
  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Quotation has been sent as Email.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error || "An Error Occured!");
    }
  }, [resultStats]);

  if (
    loading ||
    spaceLoading ||
    loadingCities ||
    loadingCountries ||
    packageLoading ||
    meetingPackageLoading
  ) {
    return <Loader height={6.6} />;
  }

  return (
    <>
      <section
        className="container-fluid addMember"
        style={{ padding: "0px 16px" }}
      >
        <Breadcrumb className="custom-breadcrumb ml-auto">
          <Breadcrumb.Item>
            <Link to="/quotation/list">Quotations List</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>View Quotation</Breadcrumb.Item>
        </Breadcrumb>
        <div className="">
          <div className="row">
            <div className="col-xl-9 viewInvoice" id="content">
              <div className="card d-flex">
                <div className="card-body invoice-padding pb-0">
                  <div
                    className="d-flex justify-content-between flex-md-row flex-sm-row  invoice-spacing mt-0"
                    style={{ alignItems: "start" }}
                  >
                    <LogoDetails {...spaceData} />
                    <div
                      className="quotationTo mt-md-0 align-items-lg-end"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "end",
                        margin: "0px",
                      }}
                    >
                      <h4 className="invoice-title me-0 text-lg-end mb-lg-4">
                        Quotation&nbsp;&nbsp;
                        <span className="fw-bold">
                          {"#00" + quotation?.quotationId || quotation?.id}
                        </span>
                      </h4>
                      <div className="d-flex align-items-center mb-1 voiceFix">
                        <p className="mb-0 invoice-date-title">
                          Published Date:&nbsp;&nbsp;
                        </p>
                        <p className="mb-0 fw-bold text-end">
                          {quotation?.createdAt &&
                            new Date(quotation?.createdAt).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="invoice-spacing" />

                {/* <div className="card-body invoice-padding pt-0">
                    <div className="row row-bill-to invoice-spacing">
                      <div className="col-lg-6">
                        <InvoiceToDetails
                          FetchSpaceInvoice={FetchSpaceInvoice}
                        />
                      </div>
                      <div className="col-lg-6  d-flex justify-content-lg-end mt-lg-0 mt-4">
                        <div>
                          <h6 className="invoice-to-title">Bill to:</h6>
                          <table>
                            <tbody>
                              <tr>
                                <td className="pe-3">Total Due:</td>
                                <td>
                                  <strong>
                                    {FetchSpaceInvoice?.price &&
                                    FetchSpaceInvoice?.discountPercentage
                                      ? (
                                          FetchSpaceInvoice?.price -
                                          (FetchSpaceInvoice?.discountPercentage /
                                            100) *
                                            FetchSpaceInvoice?.price
                                        ).toFixed(2)
                                      : FetchSpaceInvoice?.price.toFixed(2)}
                                  </strong>
                                </td>
                              </tr>
                              <tr>
                                <td className="pe-3">City:</td>
                                <td>{data?.city ? data?.city : "Lahore"}</td>
                              </tr>
                              <tr>
                                <td className="pe-3">Bank Name:</td>
                                <td>
                                  {data?.bankName
                                    ? data?.bankName
                                    : "Allied Bank"}
                                </td>
                              </tr>
                              <tr>
                                <td className="pe-3">Branch Name:</td>
                                <td>
                                  {data?.branchName
                                    ? data?.branchName
                                    : "Samnabad"}
                                </td>
                              </tr>
                              <tr>
                                <td className="pe-3">Country:</td>
                                <td>
                                  {data?.country ? data?.country : "Pakistan"}
                                </td>
                              </tr>
                              <tr>
                                <td className="pe-3">IBAN:</td>
                                <td>
                                  {data?.iban
                                    ? data?.iban
                                    : "ETD95476213874685"}
                                </td>
                              </tr>
                              <tr>
                                <td className="pe-3">SWIFT Code:</td>
                                <td>
                                  {data?.swiftCode
                                    ? data?.swiftCode
                                    : "BR91905"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}

                <div className="card-body">
                  <h6>Quotation To:</h6>
                  <div className="row">
                    <div className="col">
                      <h4 className=" mb-0">
                        <b>{quotation?.customer?.name}</b>
                      </h4>
                      <p className="mb-1">{quotation?.customer?.email}</p>
                      <p className="mb-1">
                        {quotation?.customer?.address},
                        {quotation?.customer?.countryName},
                        {quotation?.customer?.stateName},
                        {quotation?.customer?.cityName},
                      </p>
                      <p className="mb-1">
                        {quotation?.customer?.phone_number}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="">Package</th>
                        <th className="">Service Charges</th>
                        <th className="">Rental Fee</th>
                        <th className="">Quantity</th>
                        <th className="">Tax Rate</th>

                        <th className="">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quotation?.packages?.map((item, idx) => {
                        return (
                          // <tr key={idx}>
                          //   <td className="">
                          //     <p className="card-text font-weight-bold mb-1">
                          //       <span>{item.package?.name}</span>

                          //     </p>
                          //   </td>
                          //   <td className="">
                          //     <p className="card-text font-weight-bold mb-1">
                          //       {item.package?.serviceCharges}
                          //     </p>
                          //   </td>
                          //   <td className="">
                          //     <p className="card-text font-weight-bold mb-1">
                          //       {item.package?.rentalFee}
                          //     </p>
                          //   </td>
                          //   <td className="">
                          //     <span className="">{item?.quantity || 1}</span>
                          //   </td>
                          //   <td className="">
                          //     <span className="">
                          //       {(item.quantity || 1) *
                          //         (item.package.serviceCharges +
                          //           item.package.rentalFee)}{" "}
                          //       {currency}
                          //     </span>
                          //   </td>
                          // </tr>
                          <tr key={idx}>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {item.package.name}
                              </p>
                            </td>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {item.package.serviceCharges}
                              </p>
                            </td>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {item.package.rentalFee}
                              </p>
                            </td>
                            <td className="">
                              <span className="">{item.quantity || 1}</span>
                            </td>
                            {/* <td className="">
                            <span className="">Tax Rate</span>
                          </td>
                          <td className="">
                            <span className="">
                              {(item.quantity || 1) *
                                (item.package.serviceCharges +
                                  item.package.rentalFee)}{" "}
                              {currency}
                            </span>
                          </td> */}
                            {item?.package?.inclusiveTax ? (
                              <td>N/A</td>
                            ) : (
                              <td className="">
                                {item?.package?.taxRateObj && (
                                  <span className="">
                                    {item?.package?.taxRateObj?.name}
                                    <br />
                                  </span>
                                )}

                                {item?.package?.taxRateObj ? (
                                  item?.package?.taxRateObj?.setOveralTax ? (
                                    <small>
                                      Overall Tax:{" "}
                                      {
                                        item?.package?.taxRateObj
                                          ?.serviceCharges
                                      }
                                    </small>
                                  ) : (
                                    <>
                                      <small className="text-nowrap">
                                        Service Charges:{" "}
                                        {
                                          item?.package?.taxRateObj
                                            ?.serviceCharges
                                        }{" "}
                                        %
                                      </small>
                                      <br />
                                      <small className="text-nowrap">
                                        Rental Fee:{" "}
                                        {item?.package?.taxRateObj?.rentalFee} %
                                      </small>
                                    </>
                                  )
                                ) : isOverAllTax ? (
                                  <small>Overall Tax: {serviceCharges}</small>
                                ) : (
                                  <>
                                    <small className="text-nowrap">
                                      Service Charges: {serviceCharges} %
                                    </small>
                                    <br />
                                    <small className="text-nowrap">
                                      Rental Fee: {rentalFee} %
                                    </small>
                                  </>
                                )}
                              </td>
                            )}
                            {item?.package?.inclusiveTax ? (
                              <td>
                                {(item.quantity || 1) *
                                  (item.package.serviceCharges +
                                    item.package.rentalFee)}
                              </td>
                            ) : (
                              <td className="">
                                <span className="">
                                  {item?.package?.taxRateObj
                                    ? item?.package?.taxRateObj?.setOveralTax
                                      ? (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            item?.package?.taxRateObj
                                              ?.serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee *
                                            item?.package?.taxRateObj
                                              ?.serviceCharges) /
                                            100)
                                      : (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            item?.package?.taxRateObj
                                              ?.serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee *
                                            item?.package?.taxRateObj
                                              ?.rentalFee) /
                                            100)
                                    : isOverAllTax
                                    ? (item.quantity || 1) *
                                      (item.package.serviceCharges +
                                        (item.package.serviceCharges *
                                          serviceCharges) /
                                          100 +
                                        item.package.rentalFee +
                                        (item.package.rentalFee *
                                          serviceCharges) /
                                          100)
                                    : (item.quantity || 1) *
                                      (item.package.serviceCharges +
                                        (item.package.serviceCharges *
                                          serviceCharges) /
                                          100 +
                                        item.package.rentalFee +
                                        (item.package.rentalFee * rentalFee) /
                                          100)}{" "}
                                  {currency}
                                </span>
                              </td>
                            )}
                          </tr>
                        );
                      })}

                      {quotation?.meetingPackages?.map((item, idx) => {
                        return (
                          // <tr key={idx}>
                          //   <td className="">
                          //     <p className="card-text font-weight-bold mb-1">

                          //       <span>{item.package?.name}</span>
                          //     </p>
                          //   </td>
                          //   <td className="">
                          //     <p className="card-text font-weight-bold mb-1">
                          //       {item.package?.serviceCharges}
                          //     </p>
                          //   </td>
                          //   <td className="">
                          //     <p className="card-text font-weight-bold mb-1">
                          //       {item.package?.rentalFee}
                          //     </p>
                          //   </td>
                          //   <td className="">
                          //     <span className="">{item.quantity || 1}</span>
                          //   </td>
                          //   <td className="">
                          //     <span className="">
                          //       {(item.quantity || 1) *
                          //         (item.package.serviceCharges +
                          //           item.package.rentalFee)}{" "}
                          //       {currency}
                          //     </span>
                          //   </td>
                          // </tr>
                          <tr key={idx}>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {item.package.name}
                              </p>
                            </td>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {item.package.serviceCharges}
                              </p>
                            </td>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {item.package.rentalFee}
                              </p>
                            </td>
                            <td className="">
                              <span className="">{item.quantity || 1}</span>
                            </td>
                            {/* <td className="">
                          <span className="">Tax Rate</span>
                        </td>
                        <td className="">
                          <span className="">
                            {(item.quantity || 1) *
                              (item.package.serviceCharges +
                                item.package.rentalFee)}{" "}
                            {currency}
                          </span>
                        </td> */}
                            {item?.package?.inclusiveTax ? (
                              <td>N/A</td>
                            ) : (
                              <td className="">
                                {item?.package?.taxRateObj && (
                                  <span className="">
                                    {item?.package?.taxRateObj?.name}
                                    <br />
                                  </span>
                                )}

                                {item?.package?.taxRateObj ? (
                                  item?.package?.taxRateObj?.setOveralTax ? (
                                    <small>
                                      Overall Tax:{" "}
                                      {
                                        item?.package?.taxRateObj
                                          ?.serviceCharges
                                      }
                                    </small>
                                  ) : (
                                    <>
                                      <small className="text-nowrap">
                                        Service Charges:{" "}
                                        {
                                          item?.package?.taxRateObj
                                            ?.serviceCharges
                                        }{" "}
                                        %
                                      </small>
                                      <br />
                                      <small className="text-nowrap">
                                        Rental Fee:{" "}
                                        {item?.package?.taxRateObj?.rentalFee} %
                                      </small>
                                    </>
                                  )
                                ) : isOverAllTax ? (
                                  <small>Overall Tax: {serviceCharges}</small>
                                ) : (
                                  <>
                                    <small className="text-nowrap">
                                      Service Charges: {serviceCharges} %
                                    </small>
                                    <br />
                                    <small className="text-nowrap">
                                      Rental Fee: {rentalFee} %
                                    </small>
                                  </>
                                )}
                              </td>
                            )}

                            {item?.package?.inclusiveTax ? (
                              <td>
                                {(item.quantity || 1) *
                                  (item.package.serviceCharges +
                                    item.package.rentalFee)}
                              </td>
                            ) : (
                              <td className="">
                                <span className="">
                                  {item?.package?.taxRateObj
                                    ? item?.package?.taxRateObj?.setOveralTax
                                      ? (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            item?.package?.taxRateObj
                                              ?.serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee *
                                            item?.package?.taxRateObj
                                              ?.serviceCharges) /
                                            100)
                                      : (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            item?.package?.taxRateObj
                                              ?.serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee *
                                            item?.package?.taxRateObj
                                              ?.rentalFee) /
                                            100)
                                    : isOverAllTax
                                    ? (item.quantity || 1) *
                                      (item.package.serviceCharges +
                                        (item.package.serviceCharges *
                                          serviceCharges) /
                                          100 +
                                        item.package.rentalFee +
                                        (item.package.rentalFee *
                                          serviceCharges) /
                                          100)
                                    : (item.quantity || 1) *
                                      (item.package.serviceCharges +
                                        (item.package.serviceCharges *
                                          serviceCharges) /
                                          100 +
                                        item.package.rentalFee +
                                        (item.package.rentalFee * rentalFee) /
                                          100)}{" "}
                                  {currency}
                                </span>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {quotation?.purchase && (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="">Product</th>

                          <th className="">Price</th>

                          <th className="">Quantity</th>
                          <th className="">Tax Rate</th>

                          <th className="">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quotation?.purchase?.items?.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.product.name}
                                </p>
                              </td>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.product.price}
                                </p>
                              </td>

                              <td className="">
                                <span className="">{item.quantity || 1}</span>
                              </td>
                              {/* <td>
                                  <span className="">Tax Rate</span>
                                </td> */}
                              <td className="">
                                {item?.product?.taxRateObj && (
                                  <span className="">
                                    {item?.product?.taxRateObj?.name}
                                    <br />
                                  </span>
                                )}

                                {item?.product?.taxRateObj ? (
                                  item?.product?.taxRateObj?.setOveralTax ? (
                                    <small>
                                      Overall Tax:{" "}
                                      {
                                        item?.product?.taxRateObj
                                          ?.serviceCharges
                                      }
                                    </small>
                                  ) : (
                                    <>
                                      <small className="text-nowrap">
                                        Service Charges:{" "}
                                        {
                                          item?.product?.taxRateObj
                                            ?.serviceCharges
                                        }{" "}
                                        %
                                      </small>
                                      <br />
                                      <small className="text-nowrap">
                                        Rental Fee:{" "}
                                        {item?.product?.taxRateObj?.rentalFee} %
                                      </small>
                                    </>
                                  )
                                ) : isOverAllTax ? (
                                  <small>Overall Tax: {serviceCharges}</small>
                                ) : (
                                  <>
                                    <small className="text-nowrap">
                                      Service Charges: {serviceCharges} %
                                    </small>
                                    <br />
                                    <small className="text-nowrap">
                                      Rental Fee: {rentalFee} %
                                    </small>
                                  </>
                                )}
                              </td>
                              {/* <td className="">
                                  <span className="">
                                    {(item.quantity || 1) * item.product.price}{" "}
                                    {currency}
                                  </span>
                                </td> */}
                              <td className="">
                                <span className="">
                                  {item?.product?.taxRateObj
                                    ? item?.product?.taxRateObj?.setOveralTax
                                      ? (item.quantity || 1) *
                                        (item.product.price +
                                          (item.product.price *
                                            item?.product?.taxRateObj
                                              ?.serviceCharges) /
                                            100)
                                      : (item.quantity || 1) *
                                          (item.product.price +
                                            (item.product.price *
                                              item?.product?.taxRateObj
                                                ?.serviceCharges) /
                                              100) +
                                        (item.product.price *
                                          item?.product?.taxRateObj
                                            ?.rentalFee) /
                                          100
                                    : isOverAllTax
                                    ? (item.quantity || 1) *
                                      (item.product.price +
                                        (item.product.price * serviceCharges) /
                                          100)
                                    : (item.quantity || 1) *
                                      (item.product.price +
                                        (item.product.price * serviceCharges) /
                                          100 +
                                        (item.product.price * rentalFee) /
                                          100)}{" "}
                                  {currency}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
                <hr className="invoice-spacing mt-0" />

                <div className="total-price d-flex justify-content-end mr-3">
                  <>
                    <div className="invoice-total-wrapper">
                      <div className="invoice-total-item">
                        <p className="invoice-total-title">Subtotal:</p>
                        <p className="invoice-total-amount">
                          {isNaN(quotation?.subTotal) ? 0 : quotation?.subTotal}{" "}
                          {currency}
                        </p>
                      </div>
                      {/* {quotation?.setOveralTax && (
                        <div className="invoice-total-item">
                          <p className="invoice-total-title">Overall Tax:</p>
                          <p className="invoice-total-amount">
                            {quotation?.serviceCharges}%
                          </p>
                        </div>
                      )}
                      {!quotation?.setOveralTax && (
                        <>
                          <div className="invoice-total-item">
                            <p className="invoice-total-title">
                              Service Charges:
                            </p>
                            <p className="invoice-total-amount">
                              {quotation?.serviceCharges} %
                            </p>
                          </div>
                          <div className="invoice-total-item">
                            <p className="invoice-total-title">Rental Fee:</p>
                            <p className="invoice-total-amount">
                              {quotation?.rentalFee} %
                            </p>
                          </div>
                        </>
                      )} */}
                      <div className="invoice-total-item">
                        <p className="invoice-total-title">Tax Amount:</p>
                        <p className="invoice-total-amount">
                          {quotation?.totalTax} {currency}
                        </p>
                      </div>
                      <hr />
                      <div className="invoice-total-item p-1">
                        {/* {quotation?.setOveralTax ? (
                              <p className="invoice-total-amount ml-auto">
                                {FetchSpaceInvoice?.subtotal +
                                  (FetchSpaceInvoice?.serviceCharges / 100) *
                                    FetchSpaceInvoice?.subtotal}
                              </p>
                            ) : ( */}
                        <p className="invoice-total-amount ml-auto">
                          {/* {FetchSpaceInvoice?.subtotal +
                                  (FetchSpaceInvoice?.serviceCharges / 100) *
                                    FetchSpaceInvoice?.subtotal +
                                  (FetchSpaceInvoice?.rentalFee / 100) *
                                    FetchSpaceInvoice?.subtotal} */}
                          {quotation?.subTotal + quotation?.totalTax} {currency}
                        </p>
                        {/* )} */}
                      </div>

                      <div className="invoice-total-item">
                        <p className="invoice-total-title">Discount:</p>
                        <p className="invoice-total-amount">
                          {quotation?.discount_percentage > 100
                            ? 100
                            : quotation?.discount_percentage || 0}{" "}
                          %
                        </p>
                      </div>

                      {/* <div className="invoice-total-item">
                        <p className="invoice-total-title">Adjustment:</p>
                        <p className="invoice-total-amount">
                          {FetchSpaceInvoice?.adjustment || 0}
                        </p>
                      </div> */}
                      <div className="invoice-total-item"></div>
                      <hr className="my-2" />
                      <div className="invoice-total-item p-1">
                        <p className="invoice-total-title">Total:</p>
                        <p className="invoice-total-amount">
                          {isNaN(
                            quotation?.total.toFixed(2) < 0
                              ? "N/A"
                              : quotation?.total.toFixed(2)
                          )
                            ? 0
                            : quotation?.total.toFixed(2) < 0
                            ? "N/A"
                            : quotation?.total.toFixed(2)}{" "}
                          {currency}
                        </p>
                      </div>
                    </div>
                  </>
                </div>

                {/* <div className="total-price d-flex justify-content-end mr-3">
                  <SubTotal
                    price={quotation?.subTotal}
                    tax={quotation?.serviceCharges}
                    rentalFee={quotation?.rentalFee}
                    discount={quotation?.discount}
                    isOverAllTax={quotation?.setOveralTax}
                  />
                </div> */}
                {/* {FetchSpaceInvoice?.remarks && (
                    <>
                      <hr className="invoice-spacing mt-0" />
                      <div className="card-body invoice-padding pt-0">
                        <div className="row">
                          <div className="col-12">
                            <span className="font-weight-bold">Note: </span>
                            <span>
                              {FetchSpaceInvoice?.remarks ||
                                "You haven't added note while creating invoice"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )} */}
              </div>
            </div>

            <div className="col-xl-3 hidePrint">
              <div className="card">
                <div className="card-body">
                  <button
                    className="btn btn-primary text-white  mb-2 w-100"
                    onClick={sentQuotationAsEmail}
                  >
                    <Icon.Send /> Send via Email
                  </button>
                  <button
                    className="btn btn-outline-secondary mb-2 w-100"
                    onClick={() =>
                      downloadFile(quotation?.customer?.name || quotation?.id)
                    }
                  >
                    Download
                  </button>
                  <button
                    className="btn btn-outline-secondary mb-2 w-100"
                    onClick={() => print()}
                  >
                    Print
                  </button>
                  {quotationsPermission[0].update ? (
                    <Link
                      to={`/update/${id}`}
                      className="btn btn-outline-secondary mb-2 w-100"
                    >
                      Edit
                    </Link>
                  ) : (
                    <div className="btn btn-outline-secondary mb-2 w-100">
                      Edit
                      <Lock />
                    </div>
                  )}

                  <button
                    className="btn btn-success text-success mb-2 w-100"
                    onClick={
                      InvoicesPermissions[0]?.create ? convertToInvoice : null
                    }
                  >
                    {InvoicesPermissions[0]?.create ? (
                      "Convert To Invoice"
                    ) : (
                      <span>
                        Convert To Invoice
                        <Lock />
                      </span>
                    )}
                  </button>
                  {/* <button
                      className="btn btn-success mb-2 w-100"
                      // onClick={handleShowPayment}
                    >
                      Send via Email
                    </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default QuotationView;
