import React, { useState } from "react";

import quickbook from "../../Assets/qb2.png";

import { useSelector } from "react-redux";
import { useGlobalContext } from "../../Context/GlobalContext";
import {
  useGetQuickBookURLQuery,
  useRevokeStripeKeyMutation,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";
import {
  confirmationAlert,
  confirmationStripeAlert,
  requiredAlert,
} from "../../utils/Alerts";
import toast from "react-hot-toast";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useNavigate } from "react-router-dom";

const QuickBookIntegration = (spaceData) => {
  const { settingsPermissions } = useGlobalContext();
  const [isAdding, setIsAdding] = useState(false);

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: getQuickBookURL, quickbookloading } = useGetQuickBookURLQuery(
    { spaceId: spaceId || currentSpaceId },
    { skip: !isAdding }
  );
  useEffect(() => {
    if (isAdding) {
      //   setIsAdding(false);
    }
  }, [isAdding]);
  console.log("Get quick book url is", getQuickBookURL);
  console.log("is adding is ", isAdding);
  const [revokeStripeKey, resultStats1] = useRevokeStripeKeyMutation();
  const [url, setUrl] = useState(getQuickBookURL?.url || "");

  const [stripeKey, setStripeKey] = useState(spaceData?.stripeKey);
  const [isStripeChanged, setIsStripeChanged] = useState(false);
  const [quickBook, setQuickBook] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (getQuickBookURL?.url && !quickbookloading) {
      setUrl(getQuickBookURL?.url);
      setIsAdding(false);

      // Check if the URL starts with http:// or https://

      // Navigate to the QuickBooks URL
    }
  }, [getQuickBookURL, quickbookloading]);

  console.log("url is sfsdfsdfdsfsdfsdf", url);
  //   useEffect(() => {
  //     setQuickBook(spaceData?.quickBook);
  //   }, [spaceData]);

  //   useEffect(() => {
  //     if (resultStats?.isError) {
  //       requiredAlert(resultStats?.error?.data?.error);
  //     }
  //     if (resultStats?.isSuccess) {
  //       toast.success("Stripe key Added Successfully.", {
  //         style: {
  //           borderRadius: "10px",
  //           background: "#333",
  //           color: "#fff",
  //         },
  //       });
  //       // setStripeKey(resultStats?.data?.stripeKey);
  //       setIsStripeChanged(false);
  //     }
  //   }, [resultStats]);
  //   useEffect(() => {
  //     if (resultStats1?.isError) {
  //       requiredAlert(resultStats1?.error?.data?.message);
  //     }
  //     if (resultStats1?.isSuccess) {
  //       toast.success("Stripe key Revoked Successfully.", {
  //         style: {
  //           borderRadius: "10px",
  //           background: "#333",
  //           color: "#fff",
  //         },
  //       });
  //       // setStripeKey(resultStats?.data?.stripeKey);
  //       // setIsStripeChanged(false);
  //     }
  //   }, [resultStats1]);
  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return (
    <div className="col-12 col-md-6">
      <div className="card text-center" style={{ height: "380px" }}>
        <img
          src={quickbook}
          alt="Logo 1"
          className="card-img-top w-100 h-25 mx-auto px-5 py-1 mt-3"
        />
        <div className="card-body mt-5">
          <h6 className="card-title mt-4 mb-3">
            Integrating with QuickBook enables you to have financials updates.
          </h6>
          {/* <p className="card-text card p-3">{stripeKey}</p> */}
          <button
            className="btn btn-primary mt-5 "
            //   disabled={!settingsPermissions[0]?.update}
            onClick={
              settingsPermissions[0]?.update
                ? () => {
                    setIsAdding(true);
                  }
                : null
            }
          >
            {quickBook ? (
              settingsPermissions[0]?.update ? (
                "Update"
              ) : (
                <span>Update{Lock2}</span>
              )
            ) : settingsPermissions[0]?.update ? (
              "Integrate"
            ) : (
              <span>Integrate{Lock2}</span>
            )}
          </button>

          {/* {stripeKey ? (
            <button
              className="btn bg-primary text-white ml-3"
              disabled={!settingsPermissions[0]?.update}
              onClick={
                settingsPermissions[0]?.update ? handleRevokeStripeKey : null
              }
              // style={{ backgroundColor: "red !important" }}
            >
              {settingsPermissions[0]?.update ? (
                "Revoke Key"
              ) : (
                <span>Revoke Key{Lock2}</span>
              )}
            </button>
          ) : null} */}
        </div>
      </div>
    </div>
  );
};

export default QuickBookIntegration;
