import React, { useState } from "react";
import { Flatpickr } from ".";
import { hash } from "../../Assets/InvoiceSvgs/Icons";
import Logo1 from "../../Assets/dummyImages/Logo1.png";

const InvoiceHeader = ({
  imageUrls,
  city,
  address,
  number,
  FetchSpaceInvoice,
  dueDate,
  name,
  setDueDate,
  invoiceId,
}) => {
  const [date] = useState(new Date());

  const MAX_WORDS_PER_LINE = 5;

  function formatAddress(address) {
    if (!address) {
      return "Office 149, 450 South Brand Brooklyn San Diego County, CA 91905, USA";
    }

    const words = address.split(" ");
    const firstLineWords = words.slice(0, MAX_WORDS_PER_LINE);
    const secondLineWords = words.slice(MAX_WORDS_PER_LINE);

    const firstLine = firstLineWords.join(" ") + "\n";
    const secondLine = secondLineWords.join(" ");

    return firstLine + secondLine;
  }

  return (
    <>
      <div className="row m-sm-4 m-0">
        <div
          className="d-flex justify-content-between flex-lg-row flex-sm-row flex-md-column flex-column invoice-spacing mt-0"
          style={{ alignItems: "start" }}
        >
          <div className="invoice-header mb-5 mb-lg-0">
            <div className="d-flex svg-illustration mb-4 gap-2 align-items-center">
              <div className="app-brand-logo demo">
                <img
                  src={imageUrls?.[0] || Logo1}
                  alt="logo-img"
                  style={{ width: "50px", borderRadius: "50%" }}
                  onError={(e) => (e.target.src = Logo1)}
                />
              </div>
              <span className="app-brand-text fw-bold fs-4">{name}</span>
            </div>
            <p className="mb-2">{formatAddress(address)}</p>
            <p className="mb-2">{number ? number : null}</p>
          </div>
          <div className="invoice-number-date mt-md-0">
            <div className="d-flex align-items-center justify-content-md-end mb-3">
              <span className="h4 text-capitalize mb-0 text-nowrap">
                Invoice
              </span>
              <div className="input-group input-group-merge">
                <div className="input-group-prepend">
                  <div className="input-group-text border-radius">{hash}</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  disabled
                  value={"00" + invoiceId}
                />
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 voiceFix">
              <span className="fw-normal">Date: </span>
              <Flatpickr
                className="form-control w-px-150 date-picker flatpickr-input"
                value={date}
                disabled={true}
              />
            </div>
            <div className="d-flex align-items-center mb-3 voiceFix">
              <span className="fw-normal">Due Date:</span>
              <Flatpickr
                className="form-control w-px-150 date-picker flatpickr-input"
                value={dueDate}
                setreadonly="true"
                data-test="my-custom-value"
                onChange={(duedate) => setDueDate(duedate)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceHeader;
