import React, { useEffect, useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import { useUpdateSpaceMutation } from "../../Slices/spaceApi";
import { requiredAlert } from "../../utils/Alerts";
import toast from "react-hot-toast";
import { useGlobalContext } from "../../Context/GlobalContext";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";

function ColorPicker({ data }) {
  const { settingsPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();

  const [displayColorPickers, setDisplayColorPickers] = useState([
    false,
    false,
    false,
  ]);
  const [colors, setColors] = useState(["#F17013", "#70C15C", "#3366FF"]);
  useEffect(() => {
    if (data?.brandColors) {
      setColors(data?.brandColors);
    }
  }, [data]);
  const [isChanged, setIsChanged] = useState(false);
  const handleClick = (index) => {
    const updatedDisplayColorPickers = [...displayColorPickers];
    updatedDisplayColorPickers[index] = !updatedDisplayColorPickers[index];
    setDisplayColorPickers(updatedDisplayColorPickers);
  };

  const handleClose = (index) => {
    const updatedDisplayColorPickers = [...displayColorPickers];
    updatedDisplayColorPickers[index] = false;
    setDisplayColorPickers(updatedDisplayColorPickers);
  };

  const handleChange = (color, index) => {
    const updatedColors = [...colors];
    updatedColors[index] = color.hex;
    setColors(updatedColors);
    setIsChanged(true);
  };

  const postBrandColors = () => {
    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: {
        brandColors: colors,
      },
    });
    setIsChanged(false);
  };

  const styles = reactCSS({
    default: {
      swatch: {
        position: "relative",
        padding: "5px",
        background: "#fff",
        borderRadius: "10px",

        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        zIndex: 1,
      },
      popover: {
        position: "absolute",
        top: "-350px",
        left: 0,
        zIndex: 2,
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
    if (resultStats?.isSuccess) {
      toast.success("Brand Colors Updated Successfully!", {
        style: {
          borderRadius: "6px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);
  return (
    <div className="card">
      <div className="card-header">
        <h4>Brand Colors</h4>
      </div>
      <div className="ml-5">
        <p>
          <b>Primary Color:</b> Use for primary buttons and other primary action
          elements.
        </p>
        <p>
          <b>Secondary Color:</b> Use for links and other interactive elements.
        </p>
        <p>
          <b>Tertiary Color:</b> Use for additional accent colors or to
          differentiate elements.
        </p>
      </div>

      <div className="card-body">
        <div className="row ml-4">
          {colors?.map((color, index) => (
            <div key={index} className="col-md-4">
              <div style={styles.swatch} onClick={() => handleClick(index)}>
                <div
                  style={{
                    background: color,
                    width: "50px",
                    height: "50px",
                    borderRadius: "10px",
                  }}
                />
              </div>
              {index === 0 ? (
                <p key={index}>Primary </p>
              ) : index === 1 ? (
                <p key={index}>Secondary </p>
              ) : index === 2 ? (
                <p key={index}>Tertiary </p>
              ) : null}
              {displayColorPickers[index] ? (
                <div style={styles.popover}>
                  <div
                    style={styles.cover}
                    onClick={() => handleClose(index)}
                  />
                  <SketchPicker
                    color={color}
                    onChange={(newColor) => handleChange(newColor, index)}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </div>

        <button
          className="mt-5 btn btn-primary"
          disabled={!isChanged || !settingsPermissions[0]?.update}
          onClick={postBrandColors}
        >
          {settingsPermissions[0]?.update ? "Save" : <span>Save{Lock2}</span>}
        </button>
      </div>
    </div>
  );
}

export default ColorPicker;
