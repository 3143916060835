import React, { useState } from "react";

import kisi from "../../Assets/kisi.webp";
import { useGlobalContext } from "../../Context/GlobalContext";
import AddKisiForm from "./AddKisiForm";

const KisiIntegration = ({ spaceData }) => {
  const { settingsPermissions } = useGlobalContext();
  return (
    <div className="col-12 col-md-6">
      <div className="card text-center" style={{ height: "380px" }}>
        <img
          src={kisi}
          alt="Logo 1"
          className="card-img-top  mx-auto px-5 py-1 mt-3"
        />
        <div className="card-body">
          <h6 className="card-title mb-3">
            All your space plans and members will br re-synced with KISI.
          </h6>

          <AddKisiForm spaceData={spaceData} />
        </div>
      </div>
    </div>
  );
};

export default KisiIntegration;
