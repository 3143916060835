import React, { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast, Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import {
  useAssignPackageMutation,
  useAssignPackageToMemberMutation,
  useCreateCompanyMutation,
  useCreatePurchaseMutation,
  useGetCompanyUsersQuery,
  useGetFloorRoomsForMemberQuery,
  useMultiplePackagesCompanyMutation,
} from "../../Slices/spaceApi";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import AsignPackage from "./AsignPackage";
import axios from "axios";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";

const PurchasesModal = ({
  id,
  FetchPackageData,
  FetchMeetingPackageData,
  isEdit,
}) => {
  const { membersPermissions, currency } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [isUploading, setIsUploading] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = today.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  const [assignProduct, resultStats] = useCreatePurchaseMutation();
  const [file, setFile] = useState("");
  const [floor, setFloor] = useState("");
  const [roomId, setRoomId] = useState("");
  const [roomType, setRoomType] = useState("");

  const [data, setData] = useState({
    meetingPackageIds: [
      {
        packageId: 1,
        quantity: 1,
        manageStock: true,
        productQuantity: 1,
      },
    ],
    spacePackageIds: [
      {
        packageId: 1,
        quantity: 1,
        manageStock: true,
        productQuantity: 1,
      },
    ],
    startTime: getCurrentDate(),
    meetingPackageIds: [],
    carryForward: "",
    companyShare: "",
    discount: 0,
  });
  const resetForm = () => {
    setData({
      packageId: FetchPackageData?.[0]?.id,
      spacePackageIds: [],
      startTime: getCurrentDate(),
      meetingPackageIds: [],
      carryForward: "",
      companyShare: "",
      discount: 0,
    });

    setFile("");
  };

  const handleDiscountChange = (event) => {
    setData({ ...data, discount: event.target.value });
  };

  function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
  }

  const [show, setShow] = useState(false);
  const [cnicNumber, setCnicNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => {
    setIsUploading(false);
    setFile("");
    setShow(false);
    resetForm();
    setFloor("");
    setRoomId("");
    setRoomType("");
    setRows([
      {
        packageId: 0,
        quantity: 1,
        manageStock: true,
        productQuantity: 1,
      },
    ]);
    setMeetingRows([
      {
        packageId: 0,
        quantity: 1,
        manageStock: true,
        productQuantity: 1,
      },
    ]);
  };
  const handleShow = () => setShow(true);

  const [rows, setRows] = useState([
    {
      packageId: 0,
      quantity: 1,
      manageStock: true,
      productQuantity: 1,
    },
  ]);
  const [meetingRows, setMeetingRows] = useState([
    {
      packageId: 0,
      quantity: 1,
    },
  ]);

  const handleAddRow = () => {
    const newRow = {
      packageId: 0,
      quantity: 1,
      manageStock: true,
      productQuantity: 1,
      // price: FetchPackageData?.[0]?.price,
      // amount: FetchPackageData?.[0]?.price,
    };
    setRows([...rows, newRow]);
  };
  const handleAddMeetingRow = () => {
    const newRow = {
      packageId: 0,
      quantity: 1,
      // price: FetchMeetingPackageData?.[0]?.price,
      // amount: FetchMeetingPackageData?.[0]?.price,
    };
    setMeetingRows([...meetingRows, newRow]);
  };
  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRemoveMeetingRow = (index) => {
    const updatedRows = [...meetingRows];
    updatedRows.splice(index, 1);
    setMeetingRows(updatedRows);
  };

  // const handleInputChange = (event, index) => {
  //   let data = [...rows];
  //   data[index][event.target.name] = event.target.value;
  //   setRows(data);
  // };
  const handleInputChange = (event, index) => {
    console.log("Event is", event);
    let data = [...rows];
    data[index].packageId = event.id;
    data[index].manageStock = event.manageStock;
    data[index].productQuantity = event.quantity;
    setRows(data);
  };
  const handleRowQuantity = (event, index) => {
    let data = [...rows];
    if (event.target.value > data[index].productQuantity) {
      requiredAlert(
        `The available maximum quantity for this product is
        ${data[index]?.productQuantity}`
      );
    } else {
      data[index].quantity = event.target.value;
      setRows(data);
    }
  };

  // const handleMeetingInputChange = (event, index) => {
  //   let data = [...meetingRows];
  //   data[index][event.target.name] = event.target.value;
  //   setMeetingRows(data);
  // };

  const handleMeetingInputChange = (event, index) => {
    let data = [...meetingRows];
    data[index].packageId = event.id;
    setMeetingRows(data);
  };
  const handleMeetingQuantityChanged = (event, index) => {
    let data = [...meetingRows];
    data[index].quantity = event.target.value;
    setMeetingRows(data);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.error);
    }

    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      handleClose();

      toast.success("Purchase Done Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  const handleChange = (event) => {
    setData({ ...data, startTime: event.target.value });
  };

  const postAsignPackage = (e) => {
    e.preventDefault();

    const updateSpacePackageIds = rows
      ?.map((item) => ({
        quantity: parseInt(item.quantity),
        product: parseInt(item.packageId),
      }))
      .filter((item) => item.quantity !== 0 && item.package !== 0);

    let updateData = {
      items: updateSpacePackageIds,
      member: parseInt(id),
    };

    console.log("Update data is", updateData);
    setApiSpinner(true);
    assignProduct({
      spaceId: spaceId || currentSpaceId,
      data: updateData,
    });
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={membersPermissions[0]?.create ? handleShow : null}
        className="send-invoice-btn"
      >
        {membersPermissions[0]?.create ? (
          <span className="invoice">
            <Plus /> Add Product
          </span>
        ) : (
          <span className="invoice">
            <Plus /> Add Product
            <Lock />
          </span>
        )}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        fade
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h3">Add Products</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <section>
            <>
              <div className="form-group">
                <label htmlFor="package-facilities" className="mb-1">
                  Purchase Product(s)
                </label>
                {FetchPackageData?.length > 0 &&
                  rows?.map((input, index) => (
                    <div key={index} className="repeater-wrapper my-2">
                      <div className="">
                        <div className="col-12 d-flex product-details-border position-relative pe-0 pl-3 fs-6">
                          <div className="row w-100 pe-lg-0 pe-3">
                            <div className="col-12 mb-lg-0 mb-2 mt-lg-0">
                              <p className="card-text col-title mb-md-50 mb-0">
                                Select Product to Purchase
                              </p>

                              <Select
                                name="packageId"
                                options={FetchPackageData}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                styles={colorStyles}
                                value={FetchPackageData?.find(
                                  (obj) => obj.id === rows?.[index]?.packageId
                                )}
                                onChange={(selectedOption) =>
                                  handleInputChange(selectedOption, index)
                                }
                              />
                            </div>
                            {input.manageStock && (
                              <div className="col-12 my-lg-0 my-2">
                                <p className="card-text col-title mb-0 mt-2">
                                  Quantity
                                </p>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="1"
                                  min={1}
                                  name="quantity"
                                  value={input?.quantity}
                                  onChange={(e) => handleRowQuantity(e, index)}
                                  disabled={!input.manageStock}
                                />
                              </div>
                            )}
                          </div>
                          <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-4 px-2">
                            <p
                              className="bg-white"
                              onClick={() => handleRemoveRow(index)}
                            >
                              <CancelIcon />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {FetchPackageData?.length <= 0 && (
                  <p>No Product is Available</p>
                )}
                <div className="">
                  <div className="px-0 mt-3">
                    <button
                      type="button"
                      className="btn btn-link send-invoice-btn"
                      data-repeater-create=""
                      onClick={handleAddRow}
                    >
                      Associate More Products
                    </button>
                  </div>
                </div>
              </div>
            </>

            <Button
              variant="primary"
              disabled={apiSpinner}
              onClick={postAsignPackage}
            >
              {isEdit ? "Update" : "Assign Product"}{" "}
              {apiSpinner ? (
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "5px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </Button>
          </section>
        </Modal.Body>
        {/* <Modal.Footer> */}
      </Modal>
    </>
  );
};

export default PurchasesModal;
