import React, { useState, useEffect } from "react";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { Link } from "react-router-dom";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { useSelector } from "react-redux";
import {
  useCreateCancellationPolicyMutation,
  useUpdateCancellationPolicyMutation,
} from "../../Slices/spaceApi";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { useGlobalContext } from "../../Context/GlobalContext";
import * as Icon from "react-bootstrap-icons";
const PolicyModal = ({
  policyData: initialData,

  title,
  submitButtonText,
  onCloseModal,
  onOpenModal,
  type,
}) => {
  const { employessPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [createPolicy, resultStats] = useCreateCancellationPolicyMutation();
  const [updatePolicy, resultStats1] = useUpdateCancellationPolicyMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const [apiSpinner, setApiSpinner] = useState(false);
  // const [policyData, setpolicyData] = useState(initialData);
  const [policyData, setpolicyData] = useState({
    name: "",
    hours: "",
    deductionRate: "",
  });

  const [show, setShow] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    // email: "",
    hours: "",
    deductionRate: "",
  });

  useEffect(() => {
    if (resultStats1.isError == false && initialData?.id) {
      setpolicyData(initialData);
    }
  }, [initialData]);

  const handleClose = () => {
    setShow(false);
    onCloseModal();
    setErrorMessage("");

    setValidationErrors({
      name: "",
      // email: "",
      hours: "",
      deductionRate: "",
    });
    setpolicyData({
      name: "",
      hours: "",
      deductionRate: "",
    });
  };
  const handleOpen = () => {
    setShow(true);
    onOpenModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name == "name") {
      if (value.length <= 100) {
        setpolicyData({
          ...policyData,
          [name]: value,
        });
        setValidationErrors({
          ...validationErrors,
          [name]: "",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: "Max Name Limit is 100 Character",
        });
      }
    } else if (name === "hours") {
      setpolicyData({
        ...policyData,
        [name]: parseFloat(value),
      });
    } else if (name === "deductionRate") {
      setpolicyData({
        ...policyData,

        deductionRate: parseFloat(value),
      });
    } else {
      setpolicyData({
        ...policyData,
        [name]: value,
      });
    }
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const handleSubmitAdd = (event) => {
    event.preventDefault();
    const { id, name, hours, deductionRate } = policyData;

    let formIsValid = true;

    const newValidationErrors = {
      name: name ? "" : "Enter Policy Name",
      // email: email ? "" : "Please Enter Email",
      hours: hours ? "" : "Please Enter Hours",
      // team: team ? "" : "Please Select Team",
      deductionRate: deductionRate ? "" : "Please Enter Deduction Rate",
    };

    Object.keys(newValidationErrors).forEach((fieldName) => {
      if (newValidationErrors[fieldName] !== "") {
        formIsValid = false;
      }
    });
    if (formIsValid) {
      let updateData = {
        id,
        name,
        hours,
        deductionRate,
        type,
      };

      setApiSpinner(true);
      createPolicy({
        spaceId: spaceId || currentSpaceId,
        data: updateData,
      });
    } else {
      setValidationErrors(newValidationErrors);
    }
  };

  const updatepolicyData = (event) => {
    event.preventDefault();
    const { id, name, hours, deductionRate } = policyData;
    let formIsValid = true;
    const newValidationErrors = {
      name: name ? "" : "Enter Employee Name",
      hours: hours ? "" : "Please Enter Hours",
      deductionRate: deductionRate ? "" : "Please Enter Deduction Rate",
    };

    Object.keys(newValidationErrors).forEach((fieldName) => {
      if (newValidationErrors[fieldName] !== "") {
        formIsValid = false;
      }
    });
    if (formIsValid) {
      let updateData = {
        id,
        name,
        hours,
        deductionRate,
        type,
      };

      setApiSpinner(true);
      updatePolicy({
        spaceId: spaceId || currentSpaceId,
        policyId: updateData?.id,
        data: updateData,
      });
    } else {
      setValidationErrors(newValidationErrors);
    }
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);
      requiredAlert(resultStats?.error?.data?.error);
    } else if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Policy Added SuccessFully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      handleClose();
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats1?.error?.data?.error);
    } else if (resultStats1?.isSuccess) {
      setApiSpinner(false);

      toast.success("Policy Updated Successfully!", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      handleClose();
    }
  }, [resultStats1]);

  return (
    <>
      {title === "Add Policy" ? (
        <Button
          variant="primary"
          onClick={employessPermissions[0]?.create ? handleOpen : null}
          className="send-invoice-btn"
        >
          {employessPermissions[0]?.create ? (
            <span className="invoice">
              <Plus /> {title}
            </span>
          ) : (
            <span className="invoice">
              <Plus /> {title}
              <Lock />
            </span>
          )}
        </Button>
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              {employessPermissions[0].update ? (
                "Update Policy"
              ) : (
                <span>
                  Update <Lock />
                </span>
              )}
            </Tooltip>
          }
        >
          <Button
            variant="white"
            className="mx-2 p-0"
            onClick={employessPermissions[0]?.update ? handleOpen : null}
          >
            <EditIcon />
          </Button>
        </OverlayTrigger>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="">
          <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={title === "Add Policy" ? handleSubmitAdd : updatepolicyData}
        >
          <Modal.Body>
            <section>
              <div>
                <Form.Group className="mb-2 mt-2">
                  <label htmlFor="name">Title</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Befor 30 minutes"
                    value={policyData?.name}
                    onChange={handleInputChange}
                  />
                  {validationErrors.name && (
                    <small style={{ color: "red", marginLeft: "5px" }}>
                      *{validationErrors.name}
                    </small>
                  )}
                </Form.Group>

                <Form.Group className="mb-2">
                  <label htmlFor="hours">Hours</label>

                  <input
                    type="number"
                    name="hours"
                    className="form-control"
                    placeholder="Enter Hours"
                    value={policyData?.hours}
                    onChange={handleInputChange}
                    min={0}
                    step="0.01"
                  />

                  {validationErrors.hours && (
                    <small style={{ color: "red", marginLeft: "5px" }}>
                      *{validationErrors?.hours}
                    </small>
                  )}
                </Form.Group>
                <Form.Group className="mb-2">
                  <label htmlFor="deductionRate">Deduction Rate</label>

                  <input
                    type="number"
                    name="deductionRate"
                    className="form-control"
                    placeholder="Enter Deduction Rate"
                    value={policyData?.deductionRate}
                    onChange={handleInputChange}
                    min={0}
                    step="0.01"
                  />

                  {validationErrors?.deductionRate && (
                    <small style={{ color: "red", marginLeft: "5px" }}>
                      *{validationErrors?.deductionRate}
                    </small>
                  )}
                </Form.Group>
              </div>
            </section>
            <div className="mt-4">
              <Button
                variant="primary"
                disabled={
                  apiSpinner ||
                  !policyData?.name ||
                  !policyData?.hours ||
                  !policyData?.deductionRate
                }
                type="submit"
              >
                {submitButtonText}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                style={{
                  backgroundColor: "grey !important",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default PolicyModal;
