import React from "react";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import * as Icon from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";

import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useUpdateUserMutation } from "../../Slices/spaceApi";
import axios from "axios";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { useGlobalContext } from "../../Context/GlobalContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const UpdateUser = ({
  userId,
  userName,
  userEmail,
  phoneNo,
  status,
  address,
  profileImg,
  cnicImage,
  // securityDeposit,

  cnicNo,
  description,
  experienceYears,
  cnicBack,
}) => {
  const { membersPermissions } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [isCnicUploading, setIsCnicUploading] = useState(false);
  const [isProfileImageUploading, setIsProfileImageUploading] = useState(false);
  const [data, setData] = useState({
    spaceId: spaceId || currentSpaceId,
    name: userName,
    email: userEmail,
    profileImg: profileImg,
    address: address,
    phoneNo: phoneNo,
    status: status,
    cnicImage: cnicImage,
    cnicBack: cnicBack,
    cnicNo: cnicNo,
    description: description,
    experienceYears: experienceYears,
    // securityDeposit: securityDeposit,
  });

  const [updateSpaceUser, resultStats] = useUpdateUserMutation();
  const [phone, setPhone] = useState(data.phoneNo);
  const [errorMessage, setErrorMessage] = useState("");
  const [phoneStatus, setPhoneStatus] = useState(true);
  const [apiSpinner, setApiSpinner] = useState(false);

  const [file, setFile] = useState("");
  const [file2, setFile2] = useState("");
  const [isCnicBackUploading, setIsCnicBackUploading] = useState(false);

  const [fileCnicBack, setFileCnicBack] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setIsProfileImageUploading(false);
    setIsCnicUploading(false);
    setIsCnicBackUploading("");
    setShow(false);
    setFile("");
    setFile2("");
    setFileCnicBack("");
  };
  const handleCnicBack = (e) => {
    e.preventDefault();
    document.getElementById("cnic-back").click();
  };

  useEffect(() => {
    if (!fileCnicBack == "" && fileCnicBack !== null) {
      setIsCnicBackUploading(true);

      if (fileCnicBack.size >= 1e7) {
        errorAlertImage("fileCnicBack is too Big");
        setFileCnicBack("");
        setIsCnicBackUploading(false);
      } else if (fileCnicBack.type.includes("image")) {
        const fd = new FormData();
        setData({ ...data, cnicBack: [] });
        fd.append("image", fileCnicBack);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setData({ ...data, cnicBack: resp.data?.[0] });
            setIsCnicBackUploading(false);
          })
          .catch((err) => {
            setIsCnicBackUploading(false);
            setFileCnicBack("");
            requiredAlert("Something Went Wrong! Image Cannot Be Updated.");
          });
      } else {
        setFileCnicBack("");
        requiredAlert("Please Select Image File");
        setIsProfileImageUploading(false);
      }
    }
  }, [fileCnicBack]);

  const handleShow = () => setShow(true);

  const handlePhone = (e) => {
    // const numericValue = e.target.value.replace(/\D/g, "");
    const numericValue = e;

    setPhone(numericValue);

    if (numericValue?.length > 10 && numericValue?.length < 16) {
      setData({ ...data, phoneNo: numericValue });
      setErrorMessage("");
      setPhoneStatus(false);
    } else {
      setPhoneStatus(true);
      setErrorMessage("Invalid Phone");
    }
  };
  const upload = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };
  const uploadcnic = (e) => {
    e.preventDefault();
    document.getElementById("real-file2").click();
  };

  useEffect(() => {
    if (!file == "" && file !== null) {
      setIsProfileImageUploading(true);

      if (file.size >= 1e7) {
        errorAlertImage("File is too Big");
        setFile("");
        setIsProfileImageUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        setData({ ...data, profileImg: [] });
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setData({ ...data, profileImg: resp.data?.[0] });
            setIsProfileImageUploading(false);
          })
          .catch((err) => {
            setIsProfileImageUploading(false);
            setFile("");
            requiredAlert("Something Went Wrong! Image Cannot Be Updated.");
          });
      } else {
        setFile("");
        requiredAlert("Please Select Image File");
        setIsProfileImageUploading(false);
      }
    }
  }, [file]);

  useEffect(() => {
    if (file2 !== null && file2 !== "") {
      setIsCnicUploading(true);
      if (file2.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile2(null);
        setIsCnicUploading(false);
      } else if (file2.type?.includes("image")) {
        const fd = new FormData();
        fd.append("image", file2);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setData({ ...data, cnicImage: resp.data?.[0] });
            setIsCnicUploading(false);
          })
          .catch((err) => {
            setIsCnicUploading(false);
            setFile2("");
            requiredAlert("Something Went Wrong! Image Cannot Be Updated.");
          });
      } else {
        setFile2("");
        setIsCnicUploading(false);

        requiredAlert("Please Select Image File");
      }
    }
  }, [file2]);

  const handle = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };
  const updateUser = (e) => {
    e.preventDefault();
    const {
      spaceId,
      name,
      email,
      profileImg,
      address,
      phoneNo,
      status,
      // securityDeposit,
      cnicImage,
      cnicBack,
      cnicNo,
      description,
      experienceYears,
    } = data;
    let updateSpaceId = +spaceId;

    let updateData = {
      spaceId: spaceId || currentSpaceId,
      name,
      email,
      profileImg,
      address,
      // securityDeposit,
      phoneNo,
      status,

      cnicImage,
      cnicBack,
      cnicNo,
      description,
      experienceYears,
      company: false,
    };

    if (file) {
      setApiSpinner(true);
      updateSpaceUser({
        spaceId: spaceId || currentSpaceId,
        userId,
        updateData: updateData,
      });
    } else {
      let filename = null;
      if (profileImg) {
        const parts = profileImg.split("/");
        const lastPart = parts[parts.length - 1];
        filename = lastPart.split("?")[0];
        setApiSpinner(true);

        updateSpaceUser({
          spaceId: spaceId || currentSpaceId,
          userId,
          updateData: {
            spaceId: updateSpaceId,
            name,
            email,
            address,
            // securityDeposit,
            phoneNo,
            status,

            cnicNo,
            cnicImage,
            cnicBack,
            description,
            experienceYears,
            profileImg: filename,
            company: false,
          },
        });
      } else {
        setApiSpinner(true);

        updateSpaceUser({
          spaceId: spaceId || currentSpaceId,
          userId,
          updateData: {
            spaceId: updateSpaceId,
            name,
            email,
            address,
            // securityDeposit,
            phoneNo,
            status,

            cnicNo,
            cnicImage,
            cnicBack,
            description,
            experienceYears,
            profileImg,
            company: false,
          },
        });
      }
    }
  };
  useEffect(() => {
    if (resultStats?.error) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.message);
    }

    if (resultStats?.isSuccess) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      setApiSpinner(false);

      toast.success("User Updated SuccessFully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);
  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {membersPermissions[0]?.update ? (
              "Update User"
            ) : (
              <span>
                Update <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="mx-2 p-0"
          onClick={membersPermissions[0]?.update ? handleShow : null}
        >
          <EditIcon />
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update {data?.name}</Modal.Title>
        </Modal.Header>
        <form onSubmit={updateUser}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                required
                placeholder="John Doe"
                className="form-control"
                value={data?.name}
                onChange={handle}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                placeholder="abc@gmail.com"
                id="email"
                required
                value={data?.email}
                className="form-control"
                onChange={handle}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Phone</label>
              <PhoneInput
                placeholder="Enter phone number"
                // name="phoneNo"
                value={phone}
                // onChange={handleInfo}
                // min={0}
                required={phoneStatus}
                // pattern="\d{11,15}"
                // onChange={(e) => {
                //   setUserData({ ...userData, phoneNo: e });
                // }}
                onChange={handlePhone}
              />
              {/* <input
                type="text"
                name="phoneNo"
                placeholder="Enter 11-15 Digit Phone Number"
                id="phoneNo"
                required={phoneStatus}
                value={phone}
                min={0}
                className="form-control"
                onChange={handlePhone}
              /> */}
              {errorMessage === "Invalid Phone" && (
                <p style={{ color: "red" }}>*Invalid Phone Number Length</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="address">Status</label>
              <select
                name="status"
                className="form-control"
                value={data?.status}
                onChange={handle}
              >
                <option value={1}>Active</option>
                <option value={2}>Inactive</option>
              </select>
            </div>

            {/* <div className="form-group">
              <label htmlFor="email">Security Deposit</label>
              <input
                type="number"
                name="securityDeposit"
                placeholder="Enter Amount"
                id="securityDeposit"
                value={data?.securityDeposit}
                min={0}
                className="form-control"
                onChange={handle}
              />
            </div> */}

            <div className="form-group">
              <label htmlFor="phone">
                ID Card Number <span className="optional">(Optional)</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="xxxxx-xxxxxxx-x"
                name="cnicNo"
                value={data.cnicNo}
                onChange={handle}
                pattern="\d{13}"
              />

              <span className="error">
                Enter 13 Digits ID Card Number Without Dashes!
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="description">
                About <span className="optional">(Optional)</span>
              </label>
              <textarea
                rows={5}
                className="form-control"
                name="description"
                value={data?.description}
                onChange={handle}
              />
            </div>
            <div className="form-group">
              <label htmlFor="experienceYears">
                Expirence years <span className="optional">(Optional)</span>
              </label>
              <input
                type="number"
                className="form-control"
                name="experienceYears"
                value={data.experienceYears}
                onChange={handle}
                // required
                // onBlur={handleFocus}
                // pattern="^[0-9]{5}-[0-9]{7}-[0-9]$"
                min="0"
                onKeyDown={(e) => {
                  const allowedKeys = ["Backspace", "Delete"];
                  if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                // focused={validation?.experienceYears.toString()}
              />
              <span className="error">Pleaae Add Experience Years</span>
            </div>
            <div className="form-group">
              <label htmlFor="name">
                Address <span className="optional">(Optional)</span>
              </label>
              <input
                type="text"
                name="address"
                id="address"
                placeholder="ABC Street, XYZ Country"
                className="form-control"
                value={data?.address}
                onChange={handle}
              />
            </div>
            <div className="form-group">
              <label className="fs-6 mb-2">
                Profile Picture <span className="optional">(Optional)</span>
              </label>
              <div className="media">
                <div className="media-aside uploadgn-self-start">
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : profileImg || "https://placehold.jp/90x90.png"
                    }
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    className="rounded"
                  />
                </div>
                <div>
                  <input
                    id="real-file"
                    name="file"
                    type="file"
                    hidden="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="media-body mt-2">
                  {isProfileImageUploading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Uploading...</span>
                    </div>
                  ) : (
                    <button
                      id="custom-button"
                      onClick={upload}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                  )}
                  <p className="card-text mt-2">
                    <Icon.InfoCircle style={{ color: "currentcolor" }} />{" "}
                    &nbsp;Allowed Max size of 10Mb
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                      }
                    >
                      <Button variant="white" className="mx-2 p-0">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />
                      </Button>
                    </OverlayTrigger> */}
                  </p>
                  {/* {!data?.profileImg ? (
                    <div className="invalid-feedback">
                      Please select an image.
                    </div>
                  ) : null} */}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="fs-6 mb-2">
                ID Card Picture Front{" "}
                <span className="optional">(Optional)</span>
              </label>
              <div className="media">
                <div className="media-aside align-self-start">
                  <img
                    src={
                      file2
                        ? URL.createObjectURL(file2)
                        : cnicImage || "https://placehold.jp/90x90.png"
                    }
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    className="rounded"
                    alt=""
                  />
                </div>
                <div>
                  <input
                    id="real-file2"
                    name="file"
                    type="file"
                    hidden="hidden"
                    onChange={(event) => setFile2(event.target.files[0])}
                  />
                </div>
                <div className="media-body mt-2">
                  {isCnicUploading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Uploading...</span>
                    </div>
                  ) : (
                    <button
                      id="custom-button"
                      onClick={uploadcnic}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                  )}
                  <p className="card-text mt-2">
                    <Icon.InfoCircle style={{ color: "currentcolor" }} /> &nbsp;
                    Allowed Max size of 10Mb
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                      }
                    >
                      <Button variant="white" className="mx-2 p-0">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />
                      </Button>
                    </OverlayTrigger> */}
                  </p>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label className="fs-6 mb-2">
                ID Card Picture Back{" "}
                <span className="optional">(Optional)</span>
              </label>
              <div className="media">
                <div className="media-aside align-self-start">
                  <img
                    src={
                      fileCnicBack
                        ? URL.createObjectURL(fileCnicBack)
                        : cnicBack || "https://placehold.jp/90x90.png"
                    }
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    className="rounded"
                    alt=""
                  />
                </div>
                <div>
                  <input
                    id="cnic-back"
                    name="file"
                    type="file"
                    hidden="hidden"
                    onChange={(event) => setFileCnicBack(event.target.files[0])}
                  />
                </div>
                <div className="media-body mt-2">
                  {isCnicBackUploading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Uploading...</span>
                    </div>
                  ) : (
                    <button
                      id="custom-button"
                      onClick={handleCnicBack}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                  )}
                  <p className="card-text mt-2">
                    <Icon.InfoCircle style={{ color: "currentcolor" }} /> &nbsp;
                    Allowed Max size of 10Mb
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                      }
                    >
                      <Button variant="white" className="mx-2 p-0">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />
                      </Button>
                    </OverlayTrigger> */}
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              type="submit"
              disabled={
                isProfileImageUploading || isCnicUploading || apiSpinner
              }
            >
              Save Changes
              {isProfileImageUploading || isCnicUploading || apiSpinner ? (
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "5px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default UpdateUser;
