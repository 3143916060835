import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import ViewInvoicePopup from "./ViewInvoicePopup";
import DeleteInvoicePopup from "./DeleteInvoicePopup";
import UpdateInvoicePopup from "./UpdateInvoicePopup";
// import { useState } from "react";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import {
  useGetAllInvoicesQuery,
  useGetSpaceInvoiceQuery,
} from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { Badge, Pagination } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import invoice1Png from "../../Assets/dummyImages/1.png";
import invoice2Png from "../../Assets/dummyImages/2.png";
import Loader from "../Loader/Loader";
import { useGlobalContext } from "../../Context/GlobalContext";
import HeaderCard from "../../Pages/HeaderCard";

const ListMember = () => {
  const { InvoicesPermissions, invoiceStatus, currency } = useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [activePage, setActivePage] = useState(1);
  // const [status, setStatus] = useState({
  //   label: "Select All",
  //   value: 4,
  // });
  const [status, setStatus] = useState(invoiceStatus);
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState([]);

  const { isLoading: fetchingData, data: FetchSpaceInvoice } =
    useGetAllInvoicesQuery({
      spaceId: spaceId || currentSpaceId,
      page_size: itemsPerPage.value,
      page: activePage,
      name: searchQuery,
      status: status?.value,
    });
  useEffect(() => {
    setLoading(false);
  }, [FetchSpaceInvoice]);
  useEffect(() => {
    setCardData([
      {
        svg: <Icon.Person size={30} />,
        title: "Clients",
        value: FetchSpaceInvoice?.analytics?.clients || 0,
      },
      {
        svg: <Icon.FileEarmarkText size={30} />,
        title: "Invoices",
        value: FetchSpaceInvoice?.analytics?.invoices || 0,
      },
      {
        svg: <Icon.Check2All size={30} />,
        title: "Paid",
        value: FetchSpaceInvoice?.analytics?.paid || 0,
        currency: currency,
      },
      {
        svg: <Icon.SlashCircle size={30} />,
        title: "UnPaid",
        value: FetchSpaceInvoice?.analytics?.unPaid || 0,
        currency: currency,
      },

      // Add more objects as needed
    ]);
  }, [FetchSpaceInvoice]);
  const { items, requestSort, sortConfig } = useSortableData(
    FetchSpaceInvoice?.invoices || []
  );
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
    // setActivePage(1);
    // setLoading(true);
  };
  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      setLoading(true);
      setSearchQuery(search);
      setActivePage(1);
    };
    clearTimeout(timer);
    if (search.length > 0) {
      timer = setTimeout(debounceSearch, 1000);
    } else {
      setSearchQuery("");
      setActivePage(1);
      setLoading(false);
    }
    return () => clearTimeout(timer);
  }, [search]);
  function timeStampToDate(timeStamp) {
    let timeMili = timeStamp * 1000;
    let date = new Date(+timeMili);
    return date.toLocaleDateString();
  }

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);
    setLoading(true);
  };

  const handleStatus = (e) => {
    setStatus(e);
    setLoading(true);
  };

  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];

  const optionsStatus = [
    {
      label: "All Status",
      value: 4,
    },
    {
      label: "Un Paid",
      value: 0,
    },
    {
      label: "Paid",
      value: 1,
    },
    {
      label: "Over Due",
      value: 2,
    },
    // {
    //   label: "Active",
    //   value: 3,
    // },
  ];
  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}`;
  }
  const images = [invoice1Png, invoice2Png];
  if (fetchingData) {
    return <Loader height={6.6} />;
  }
  return (
    <>
      <HeaderCard data={cardData} />
      <section
        className="container-fluid invoice-list-wrapper with-bottom-padding"
        style={{ padding: "0px 16px" }}
      >
        <div className="card">
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-between ">
              <div className="col-12  col-md-4 col-lg-6">
                <input
                  type="search"
                  className="form-control"
                  value={search}
                  onChange={handleSearch}
                  placeholder="Search Invoice"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
                {InvoicesPermissions[0]?.create ? (
                  <Link
                    to="/invoice/add"
                    className="btn btn-primary"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Create Invoice
                    </span>
                  </Link>
                ) : (
                  <div
                    className="btn btn-primary"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Create <Lock />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr />
          <div className="col-12 d-flex flex-column flex-md-row justify-content-between ">
            <div className="col-12 col-md-6 my-3 my-lg-0 col-lg-4 d-flex">
              <span className="mt-2">Filter By &nbsp;</span>
              <Select
                options={optionsStatus}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handleStatus}
                value={status}
                placeholder={"All Status"}
                styles={colorStyles}
              />
            </div>
            <div className="col-12 col-md-2 mb-3">
              <div className="">
                <div className="col-12 d-flex justify-content-end ">
                  <label
                    style={{
                      display: "flex",
                      whiteSpace: "nowrap",
                      fontWeight: "normal",
                    }}
                  >
                    <small className="mr-2 mt-2 title"> Show</small>
                    <Select
                      options={options}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      onChange={handlePage}
                      value={itemsPerPage}
                      id="pages-select"
                      styles={colorStyles}
                    />
                    <small className="ml-2 mt-2"> entries</small>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive mt-3">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>#</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Client</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("companyName")}
                          className={getClassNamesFor("companyName")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("companyName")}
                          className={getClassNamesFor("companyName")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Price</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("price")}
                          className={getClassNamesFor("price")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("price")}
                          className={getClassNamesFor("price")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Due Date</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Publish Date</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("publishDate")}
                          className={getClassNamesFor("publishDate")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("publishDate")}
                          className={getClassNamesFor("publishDate")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Paid Date</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("publishDate")}
                          className={getClassNamesFor("publishDate")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("publishDate")}
                          className={getClassNamesFor("publishDate")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Status</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      ></div>
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8} className="fs-5">
                      <Loader height={6.6} />
                    </td>
                  </tr>
                )}
                {!loading &&
                  (items?.length > 0 ? (
                    items?.map((item, index) => {
                      const {
                        id,
                        invoiceId,
                        total,
                        due_date,
                        issued_date,
                        paid_date,
                        member,
                        paid,
                      } = item;
                      const { profileImg, email, name } = item?.member || {};

                      return (
                        <tr key={index}>
                          <td style={{ color: "#7367f0", fontWeight: "bold" }}>
                            #{invoiceId || id}
                          </td>

                          <td>
                            <div className="media">
                              <div className="media-aside logo-list mr-2">
                                <img
                                  src={
                                    profileImg ||
                                    images[
                                      Math.floor(Math.random() * images.length)
                                    ]
                                  }
                                  onError={(e) =>
                                    (e.target.src =
                                      images[
                                        Math.floor(
                                          Math.random() * images.length
                                        )
                                      ])
                                  }
                                  alt=""
                                  style={{
                                    // marginRight: "8px",
                                    borderRadius: "50%",
                                    width: "38px",
                                    height: "38px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="media-body my-auto d-flex flex-column">
                                <span className="mb-0 table-avatar">
                                  <b>{name || member}</b>
                                </span>
                                <small className="mb-0 text-muted">
                                  {email || (
                                    <Badge bg={"badge bg-label-danger"}>
                                      {"No E-mail Provided"}
                                    </Badge>
                                  )}
                                </small>
                              </div>
                            </div>
                          </td>
                          <td>
                            {total.toFixed(2)} <small>{currency}</small>
                          </td>

                          <td>{formatDateTime(due_date)}</td>

                          <td>{formatDateTime(issued_date)}</td>
                          <td>
                            {paid_date
                              ? formatDateTime(paid_date)
                              : "--/--/----"}
                          </td>

                          <td>
                            <Badge
                              bg={
                                paid
                                  ? "badge bg-label-success"
                                  : "badge bg-label-danger"
                              }
                            >
                              {paid ? "Paid" : "Unpaid"}
                            </Badge>
                          </td>
                          <td className="d-flex flex-nowrap">
                            <ViewInvoicePopup {...item} />
                            <UpdateInvoicePopup {...item} />
                            <DeleteInvoicePopup {...item} />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-left fs-5">
                        {search == "" ? "No Record !" : "No invoice found"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {!loading && FetchSpaceInvoice?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing {FetchSpaceInvoice?.invoices.length} of{" "}
                  {FetchSpaceInvoice?.totalCount} entires
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: FetchSpaceInvoice?.totalPages }).map(
                    (_, i) => (
                      <Pagination.Item
                        key={i}
                        active={i + 1 === activePage}
                        onClick={
                          i + 1 === activePage
                            ? ""
                            : () => handlePageSelect(i + 1)
                        }
                      >
                        {i + 1}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={activePage === FetchSpaceInvoice?.totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default ListMember;
