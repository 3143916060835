import React, { useState } from "react";
import "./index.css";
import {
  PaymentDetails,
  RightBottomForm,
  MemberHeader,
  AddCustomer,
  AddNote,
} from ".";

import { Select } from ".";
import Data from "./dummy.json";

import { CancelIcon, SettingIcon } from "../../Assets/InvoiceSvgs/Icons";
import SalesPerson from "./SalesPerson";
import SubTotal from "./SubTotal";
import { colorStyles } from "../../utils/constant";
import {
  useCreateInvoiceV2Mutation,
  useCreateManuallyInvoiceMutation,
  useMultiplePackagesCompanyMutation,
} from "../../Slices/spaceApi";
import { useEffect } from "react";
import { requiredAlert } from "../../utils/Alerts";
import { Toaster, toast } from "react-hot-toast";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { Link, useNavigate } from "react-router-dom";
import PaymentDetails1 from "./PaymentDetails1";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../../Context/GlobalContext";
import { localDateTimeToUTC } from "../../utils/localDateTimeToUTC";
import { Breadcrumb } from "react-bootstrap";
import PackageCalculations from "./PackageCalculations";
import ProductCalculations from "./ProductCalculations";

const AddMember = ({
  data,
  packageData,
  userData,
  meetingData,
  FetchSpaceInvoice,
  productData,
}) => {
  const { rentalFee, serviceCharges, isOverAllTax, currency } =
    useGlobalContext();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [dueDate, setDueDate] = useState(() => {
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    return nextWeek;
  });
  const [createManuallyInvoice, resultStats] = useCreateInvoiceV2Mutation();
  const [apiSpinner, setApiSpinner] = useState(false);

  const [invoiceData, setInvoiceData] = useState({
    member: userData?.[0]?.id,
    space: spaceId || currentSpaceId,
    packages: [
      {
        packageId: "",
        quantity: 1,
      },
    ],
    meeting_packages: [
      {
        packageId: "",
        quantity: 1,
      },
    ],
    start_date: "2023-10-16T00:10",
    invoice: {
      tax: 0,
      discount_percentage: 0,
      remarks: "",
    },
    remarks: "",
  });

  const [discount, setDiscount] = useState(0);
  const [tax, setTax] = useState(serviceCharges);
  const handleChange = (e) => {
    setInvoiceData({ ...invoiceData, member: e.id });
  };

  // const [serviceList, setServiceList] = useState([
  //   {
  //     packageId: packageData?.[0]?.id,
  //     quantity: 1,
  //     cost: packageData?.[0]?.price,
  //     price: packageData?.[0]?.price,
  //   },
  // ]);
  const [serviceList, setServiceList] = useState([
    {
      packageId: packageData?.[0]?.id,
      quantity: 1,
      serviceCharges: packageData?.[0]?.serviceCharges,
      inclusiveTax: packageData?.[0]?.inclusiveTax,
      rentalFee: packageData?.[0]?.rentalFee,
      price: packageData?.[0]?.serviceCharges + packageData?.[0]?.rentalFee,
      taxRateObj: packageData?.[0]?.taxRateObj,
    },
  ]);
  const [productList, setProductList] = useState([
    {
      productId: productData?.[0]?.id,
      productQuantity: productData?.[0]?.quantity,
      manageStock: productData?.[0]?.manageStock,
      quantity: 1,
      price: productData?.[0]?.price,
      taxRateObj: productData?.[0]?.taxRateObj,
    },
  ]);
  // const addItem = () => {
  //   setServiceList([
  //     ...serviceList,
  //     {
  //       packageId: packageData?.[0]?.id,
  //       quantity: 1,
  //       cost: packageData?.[0]?.price,
  //       price: packageData?.[0]?.price,
  //     },
  //   ]);
  // };

  const addItem = () => {
    setServiceList([
      ...serviceList,
      {
        packageId: packageData?.[0]?.id,
        quantity: 1,
        serviceCharges: packageData?.[0]?.serviceCharges,
        inclusiveTax: packageData?.[0]?.inclusiveTax,

        rentalFee: packageData?.[0]?.rentalFee,
        price: packageData?.[0]?.serviceCharges + packageData?.[0]?.rentalFee,
        taxRateObj: packageData?.[0]?.taxRateObj,
      },
    ]);
  };

  const removeItem = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };
  const removeProduct = (index) => {
    const list = [...productList];
    list.splice(index, 1);
    setProductList(list);
  };

  const addProduct = () => {
    setProductList([
      ...productList,
      {
        productId: productData?.[0]?.id,
        productQuantity: productData?.[0]?.quantity,
        manageStock: productData?.[0]?.manageStock,
        quantity: 1,
        price: productData?.[0]?.price,
        taxRateObj: productData?.[0]?.taxRateObj,
      },
    ]);
  };
  const handleProductChange = (index, event) => {
    let data = [...productList];
    data[index][event.target.name] = event.target.value;

    let findProduct = productData?.find(
      (item) => item.id == productList?.[index]?.productId
    );

    let quantity = productList?.[index]?.quantity;

    // data[index]["cost"] = findPackage?.price;
    data[index]["manageStock"] = findProduct?.manageStock;
    data[index]["productQuantity"] = findProduct?.quantity;

    // data[index]["price"] = (findProduct?.price * +quantity).toFixed(2);
    data[index]["price"] = (findProduct?.price * +quantity).toFixed(2);
    data[index]["taxRateObj"] = findProduct?.taxRateObj;

    setProductList(data);
  };

  const handleFormChange = (index, event) => {
    let data = [...serviceList];
    data[index][event.target.name] = event.target.value;

    let findPackage = packageData?.find(
      (item) => item.id == serviceList?.[index]?.packageId
    );

    let quantity = serviceList?.[index]?.quantity;

    // data[index]["cost"] = findPackage?.price;
    data[index]["serviceCharges"] = findPackage?.serviceCharges;
    data[index]["rentalFee"] = findPackage?.rentalFee;
    // inclusiveTax:packageData?.[0]?.inclusiveTax,
    data[index]["inclusiveTax"] = findPackage?.inclusiveTax;

    // data[index]["price"] = (findPackage?.price * +quantity).toFixed(2);
    data[index]["price"] = (
      (findPackage?.serviceCharges + findPackage?.rentalFee) *
      +quantity
    ).toFixed(2);
    data[index]["taxRateObj"] = findPackage?.taxRateObj;
    setServiceList(data);
  };

  // const [serviceMeeting, setServiceMeeting] = useState([
  //   {
  //     packageId: meetingData?.[0]?.id,
  //     quantity: 1,
  //     cost: meetingData?.[0]?.price,
  //     price: meetingData?.[0]?.price,
  //   },
  // ]);
  const [serviceMeeting, setServiceMeeting] = useState([
    {
      packageId: meetingData?.[0]?.id,
      quantity: 1,
      serviceCharges: meetingData?.[0]?.serviceCharges,
      rentalFee: meetingData?.[0]?.rentalFee,
      inclusiveTax: meetingData?.[0]?.inclusiveTax,

      price: meetingData?.[0]?.serviceCharges + meetingData?.[0]?.rentalFee,
      taxRateObj: meetingData?.[0]?.taxRateObj,
    },
  ]);

  // const meetingAddItem = () => {
  //   setServiceMeeting([
  //     ...serviceMeeting,
  //     {
  //       packageId: meetingData?.[0]?.id,
  //       quantity: 1,
  //       cost: meetingData?.[0]?.price,
  //       price: meetingData?.[0]?.price,
  //     },
  //   ]);
  // };
  const meetingAddItem = () => {
    setServiceMeeting([
      ...serviceMeeting,
      {
        packageId: meetingData?.[0]?.id,
        quantity: 1,
        serviceCharges: meetingData?.[0]?.serviceCharges,
        rentalFee: meetingData?.[0]?.rentalFee,
        inclusiveTax: meetingData?.[0]?.inclusiveTax,

        price: meetingData?.[0]?.serviceCharges + meetingData?.[0]?.rentalFee,
        taxRateObj: meetingData?.[0]?.taxRateObj,
      },
    ]);
  };
  const meetingRemoveItem = (index) => {
    const list = [...serviceMeeting];
    list.splice(index, 1);
    setServiceMeeting(list);
  };

  const handle = (e) => {
    setInvoiceData({ ...invoiceData, remarks: e.target.value });
  };

  const handleMeetingFormChange = (index, event) => {
    let data = [...serviceMeeting];
    data[index][event.target.name] = event.target.value;

    let findPackage = meetingData?.find(
      (item) => item.id == serviceMeeting?.[index]?.packageId
    );

    let quantity = serviceMeeting?.[index]?.quantity;

    data[index]["serviceCharges"] = findPackage?.serviceCharges;
    data[index]["rentalFee"] = findPackage?.rentalFee;
    // inclusiveTax:meetingData?.[0]?.inclusiveTax,
    data[index]["inclusiveTax"] = findPackage?.inclusiveTax;

    data[index]["price"] = (
      (findPackage?.serviceCharges + findPackage?.rentalFee) *
      +quantity
    ).toFixed(2);
    data[index]["taxRateObj"] = findPackage?.taxRateObj;

    setServiceMeeting(data);
  };
  function convertDateFormat(inputDate) {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    const outputDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return outputDate;
  }

  const addInvoice = (e) => {
    e.preventDefault();
    const { id, remarks } = invoiceData;

    const updateSpacePackageIds = serviceList?.map((item) => ({
      quantity: parseInt(item.quantity),
      package: parseInt(item.packageId),
    }));

    const updateMeetingPackageIds = serviceMeeting?.map((item) => ({
      quantity: parseInt(item.quantity),
      package: parseInt(item.packageId),
    }));

    const updateProductIds = productList?.map((item) => ({
      quantity: parseInt(item.quantity),
      product: parseInt(item.productId),
    }));

    const timestamp = Date.parse(dueDate) / 1000;
    const invoice = {
      tax: tax,
      discount_percentage: parseFloat(discount) || 0,
      remarks: remarks,
    };
    let updateData = {
      member: invoiceData?.member,
      space: invoiceData?.space,
      meeting_packages: updateMeetingPackageIds,
      packages: updateSpacePackageIds,
      purchase: {
        items: updateProductIds,
        member: invoiceData?.member,
      },
      // start_date: convertDateFormat(new Date().toISOString()),
      invoice: invoice,
    };

    setApiSpinner(true);
    createManuallyInvoice({
      spaceId: data?.spaceId,
      data: updateData,
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      requiredAlert(resultStats?.error?.data?.message || "An Error Occured");
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);

      toast.success("Invoice Created Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate("/invoice/list");
    }
  }, [resultStats]);

  return (
    <>
      <section
        className="container-fluid addMember"
        style={{ padding: "0px 16px" }}
      >
        <Breadcrumb className="custom-breadcrumb ml-auto">
          <Breadcrumb.Item>
            <Link to="/invoice/list">Invoice List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Invoice Add</Breadcrumb.Item>
          {/* <Breadcrumb.Item>
          <Link to={`/invoice/${id}/edit`}>Invoice Edit</Link>
        </Breadcrumb.Item> */}
        </Breadcrumb>
        <div className="row">
          <div className="col-lg-9 col-12 mb-lg-0 mb-4">
            <div className="card d-flex">
              {/* Header Start */}
              <MemberHeader
                {...data}
                data={Data}
                FetchSpaceInvoice={FetchSpaceInvoice}
                dueDate={dueDate}
                setDueDate={setDueDate}
              />

              {/* HORIZONTAL ROW */}
              <hr className="" />

              {/* Body */}
              <div className="row p-sm-4 p-0">
                <div className="col-md-6 col-sm-5 col-12 mb-sm-0 mb-4">
                  <h6 className="mb-4">Invoice To:</h6>
                  <Select
                    onChange={handleChange}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.id}
                    styles={colorStyles}
                    value={userData?.find((obj) => obj?.id === invoiceData?.id)}
                    options={userData}
                  />
                  {invoiceData?.id && (
                    <div className="mt-3">
                      <p className="card-text my-1">
                        {
                          userData?.find(
                            (item, idx) => item.id == invoiceData?.id
                          )?.address
                        }
                      </p>
                      <p className="card-text my-1">
                        {
                          userData?.find(
                            (item, idx) => item.id == invoiceData?.id
                          )?.phoneNo
                        }
                      </p>
                      <p className="card-text my-1">
                        {
                          userData?.find(
                            (item, idx) => item.id == invoiceData?.id
                          )?.email
                        }
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-lg-6 d-flex justify-content-lg-end mt-lg-0 mt-4">
                  <PaymentDetails1
                    data={data}
                    serviceList={serviceList}
                    serviceMeeting={serviceMeeting}
                    productList={productList}
                    tax={serviceCharges}
                    rentalFee={rentalFee}
                    discount={discount}
                    isOverAllTax={isOverAllTax}
                  />
                </div>
              </div>

              {packageData?.length > 0 && (
                <div className="card-body invoice-padding invoice-product-details border">
                  <h6>Packages</h6>
                  <form className="source-item">
                    <div>
                      {serviceList?.map((input, index) => (
                        <div
                          key={index}
                          //  className="repeater-wrapper my-2"
                          className={`my-2 ${
                            index % 2 == 0 ? "bg-white" : "bg-light"
                          }`}
                        >
                          <div className="">
                            <div
                              className="col-12 d-flex  position-relative pe-0 pl-3 fs-6"
                              // product-details-border
                            >
                              <div className="row w-100 pe-lg-0 pe-3 py-4">
                                <div className="col-12">
                                  <div className="row w-100">
                                    <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Item
                                      </p>
                                      <select
                                        name="packageId"
                                        value={input.packageId}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                        className="form-control"
                                      >
                                        {packageData?.map((item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        quantity
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="1"
                                        min={1}
                                        name="quantity"
                                        value={input?.quantity}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="row w-100 mt-3">
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Service Charges
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="0"
                                        name="serviceCharges"
                                        value={input.serviceCharges}
                                        disabled
                                      />
                                    </div>
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Rental Fee
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="0"
                                        name="rentalFee"
                                        value={input.rentalFee}
                                        disabled
                                      />
                                    </div>

                                    <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Price
                                      </p>
                                      <p className="card-text mb-0">
                                        {+serviceList?.[index]?.price}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <hr className="mt-4" />
                                <PackageCalculations
                                  input={input}
                                  isOverAllTax={isOverAllTax}
                                  serviceCharges={serviceCharges}
                                  rentalFee={rentalFee}
                                />
                              </div>

                              <div
                                className="d-flex flex-column align-items-center justify-content-between invoice-product-actions py-2 px-2"
                                // border-left
                              >
                                <CancelIcon index={() => removeItem(index)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="">
                      <div className="px-0 mt-3">
                        <button
                          type="button"
                          className="btn btn-primary send-invoice-btn"
                          data-repeater-create=""
                          onClick={addItem}
                        >
                          Add Item
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {meetingData?.length > 0 && (
                <div className="card-body invoice-padding invoice-product-details">
                  <h6>Meeting Packages</h6>
                  <form className="source-item">
                    <div>
                      {serviceMeeting?.map((input, index) => (
                        <div
                          key={index}
                          className={`my-2 ${
                            index % 2 == 0 ? "bg-white" : "bg-light"
                          }`}
                          // className="repeater-wrapper my-2"
                        >
                          <div className="">
                            <div className="col-12 d-flex  position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0  pe-3  py-4">
                                <div className="row w-100">
                                  <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Item
                                    </p>
                                    <select
                                      name="packageId"
                                      value={input.packageId}
                                      onChange={(event) =>
                                        handleMeetingFormChange(index, event)
                                      }
                                      className="form-control"
                                    >
                                      {meetingData?.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item?.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      quantity
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="quantity"
                                      value={input?.quantity}
                                      onChange={(event) =>
                                        handleMeetingFormChange(index, event)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row w-100 mt-3">
                                  <div className="col-lg-4 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      Service Charges
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="24"
                                      name="cost"
                                      value={input?.serviceCharges}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-lg-4 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      Rental Fee
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="24"
                                      name="cost"
                                      value={input?.rentalFee}
                                      disabled
                                    />
                                  </div>

                                  <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Price
                                    </p>
                                    <p className="card-text mb-0">
                                      {+serviceMeeting?.[index]?.price}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-2 px-2">
                                <CancelIcon
                                  index={() => meetingRemoveItem(index)}
                                />
                              </div>
                            </div>
                            <hr className="mt-4" />
                            <PackageCalculations
                              input={input}
                              isOverAllTax={isOverAllTax}
                              serviceCharges={serviceCharges}
                              rentalFee={rentalFee}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="">
                      <div className="px-0 mt-3">
                        <button
                          type="button"
                          className="btn btn-primary send-invoice-btn"
                          data-repeater-create=""
                          onClick={meetingAddItem}
                        >
                          Add Item
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {productData?.length > 0 && (
                <div className="card-body invoice-padding invoice-product-details">
                  <h6>Products</h6>
                  <form className="source-item">
                    <div>
                      {productList?.map((input, index) => (
                        <div
                          key={index}
                          className={`my-2 ${
                            index % 2 == 0 ? "bg-white" : "bg-light"
                          }`}
                          // className="repeater-wrapper my-2"
                        >
                          <div className="">
                            <div className="col-12 d-flex  position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0  pe-3  py-4">
                                <div className="row w-100">
                                  <div className="col-lg-6 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Product
                                    </p>
                                    <select
                                      name="productId"
                                      value={input.productId}
                                      onChange={(event) =>
                                        handleProductChange(index, event)
                                      }
                                      className="form-control"
                                    >
                                      {productData?.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item?.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-lg-3 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      quantity
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="quantity"
                                      value={input?.quantity}
                                      onChange={(event) =>
                                        handleProductChange(index, event)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 col-12 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Price
                                    </p>
                                    <p className="card-text mb-0">
                                      {+productList?.[index]?.price}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-2 px-2">
                                <CancelIcon
                                  index={() => removeProduct(index)}
                                />
                              </div>
                            </div>
                            <hr className="mt-4" />
                            <ProductCalculations
                              input={input}
                              isOverAllTax={isOverAllTax}
                              serviceCharges={serviceCharges}
                              rentalFee={rentalFee}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="">
                      <div className="px-0 mt-3">
                        <button
                          type="button"
                          className="btn btn-primary send-invoice-btn"
                          data-repeater-create=""
                          onClick={addProduct}
                        >
                          Add Product
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {/* <div className="row invoice-padding py-5 align-items-center">
                <div className="col-md-3">Total Discount:</div>
                <div className="col-md-9">
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    max={100}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
              </div> */}

              <div className="card-body invoice-padding">
                <div className="row invoice-sales-total-wrapper align-items-center">
                  <div className="col-lg-7">
                    <SalesPerson name={data?.name} />
                  </div>
                  <div className="col-lg-4 offset-lg-1 d-flex justify-content-lg-end justify-content-center mt-5 mt-lg-0">
                    <SubTotal
                      serviceList={serviceList}
                      serviceMeeting={serviceMeeting}
                      productList={productList}
                      tax={serviceCharges}
                      rentalFee={rentalFee}
                      discount={discount}
                      setDiscount={setDiscount}
                      isOverAllTax={isOverAllTax}
                    />
                  </div>
                </div>
              </div>
              <hr />
              {/* <AddNote /> */}
              <>
                <div className="form-group mb-4 invoice-padding">
                  <label
                    htmlFor="note"
                    className="form-label font-weight-bold mb-0"
                  >
                    Note:
                  </label>
                  <textarea
                    className="form-control"
                    rows="2"
                    name="remarks"
                    id="remarkss"
                    value={invoiceData?.remarks}
                    onChange={handle}
                    placeholder={"Write a note here"}
                  ></textarea>
                </div>
              </>
            </div>
          </div>

          <div className="col-xl-3 col-12">
            <div
              className="card"
              style={{
                position: window.innerWidth > 1201 ? "fixed" : "static",
                minWidth:
                  window.innerWidth > 1201
                    ? window.innerWidth < 1440
                      ? "18%"
                      : "20%"
                    : "auto",
              }}
            >
              <div className="card-body">
                <button
                  className="btn btn-outline-primary mb-2 w-100"
                  disabled={apiSpinner}
                  onClick={addInvoice}
                >
                  Save
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>

        <AddCustomer />
        <Toaster position="top-right" />
      </section>
    </>
  );
};

export default AddMember;
