import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../Context/GlobalContext";

const SubTotal = ({
  serviceList,
  serviceMeeting,
  productList,
  discount,
  price,
  tax,
  rentalFee,
  isOverAllTax,
  setDiscount,
}) => {
  const { currency } = useGlobalContext();
  // const serviceListTotal = serviceList?.reduce((total, serviceItem) => {
  //   total += +serviceItem.price;
  //   return total;
  // }, 0);
  const serviceListTotal = serviceList?.reduce((total, serviceItem) => {
    // Calculate tax based on taxRateObj
    let taxAmount = 0;
    if (serviceItem?.inclusiveTax) {
      taxAmount = 0;
    } else if (
      serviceItem?.taxRateObj &&
      serviceItem?.taxRateObj?.setOveralTax
    ) {
      // Apply tax percentage to serviceCharges and rentalFee
      const serviceChargesTax =
        (serviceItem.serviceCharges *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.rentalFee *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (
      serviceItem?.taxRateObj &&
      !serviceItem?.taxRateObj?.setOveralTax
    ) {
      const serviceChargesTax =
        (serviceItem.serviceCharges *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.rentalFee * parseFloat(serviceItem.taxRateObj.rentalFee)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (!serviceItem?.taxRateObj && isOverAllTax) {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.serviceCharges * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.rentalFee * tax) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    } else {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.serviceCharges * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.rentalFee * rentalFee) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    }

    // Add tax to the total
    total +=
      parseFloat(serviceItem.price) +
      taxAmount * parseInt(serviceItem?.quantity);

    return total;
  }, 0);
  console.log("Service list total is", serviceListTotal);

  // const serviceMeetingTotal = serviceMeeting?.reduce((total, serviceItem) => {
  //   total += +serviceItem.price;
  //   return total;
  // }, 0);

  const serviceMeetingTotal = serviceMeeting?.reduce((total, serviceItem) => {
    // Calculate tax based on taxRateObj
    let taxAmount = 0;
    if (serviceItem?.inclusiveTax) {
      taxAmount = 0;
    } else if (
      serviceItem?.taxRateObj &&
      serviceItem?.taxRateObj?.setOveralTax
    ) {
      // Apply tax percentage to serviceCharges and rentalFee
      const serviceChargesTax =
        (serviceItem.serviceCharges *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.rentalFee *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (
      serviceItem?.taxRateObj &&
      !serviceItem?.taxRateObj?.setOveralTax
    ) {
      const serviceChargesTax =
        (serviceItem.serviceCharges *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.rentalFee * parseFloat(serviceItem.taxRateObj.rentalFee)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (!serviceItem?.taxRateObj && isOverAllTax) {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.serviceCharges * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.rentalFee * tax) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    } else {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.serviceCharges * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.rentalFee * rentalFee) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    }

    // Add tax to the total
    total +=
      parseFloat(serviceItem.price) +
      taxAmount * parseInt(serviceItem?.quantity);

    return total;
  }, 0);

  const productListTotal = productList?.reduce((total, serviceItem) => {
    // Calculate tax based on taxRateObj
    let taxAmount = 0;
    if (serviceItem?.taxRateObj && serviceItem?.taxRateObj?.setOveralTax) {
      // Apply tax percentage to serviceCharges and rentalFee
      const serviceChargesTax =
        (serviceItem.price *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;

      taxAmount = serviceChargesTax;
    } else if (
      serviceItem?.taxRateObj &&
      !serviceItem?.taxRateObj?.setOveralTax
    ) {
      const serviceChargesTax =
        (serviceItem.price *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.price * parseFloat(serviceItem.taxRateObj.rentalFee)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (!serviceItem?.taxRateObj && isOverAllTax) {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.price * tax) / 100; // Assuming 10% tax rate if not
      taxAmount = defaultServiceChargesTax;
    } else {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.price * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.price * rentalFee) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    }

    // Add tax to the total
    total += parseFloat(serviceItem.price) + taxAmount;

    return total;
  }, 0);

  const [isDiscount, setIsDiscount] = useState(false);
  const [isTotal, setIsTotal] = useState(false);
  const subtotal =
    price || serviceListTotal + serviceMeetingTotal + productListTotal;

  const discountAmount = (discount / 100) * subtotal;

  const total = subtotal - discountAmount;
  const [totalAmmount, setTotalAmmount] = useState(total);

  useEffect(() => {
    if (isDiscount) {
      setTotalAmmount(total);
      setIsDiscount(false);
    }
  }, [total, isDiscount]);
  useEffect(() => {
    setTotalAmmount(total);
  }, [subtotal]);

  useEffect(() => {
    if (isTotal) {
      const discountValue =
        // Math.ceil(
        ((subtotal - totalAmmount) / subtotal) * 100;
      // );

      setDiscount(discountValue);
      setIsTotal(false);
    }
  }, [totalAmmount, isTotal]);

  return (
    <>
      <div className="invoice-total-wrapper">
        <div className="invoice-total-item">
          <p className="invoice-total-title">Subtotal:</p>
          <p className="invoice-total-amount">
            {isNaN(subtotal) ? 0 : subtotal}{" "}
            <small className="fw-bold">{currency}</small>
          </p>
        </div>

        <div className="invoice-total-item">
          <p className="invoice-total-title">Discount:</p>
          {/* <p className="invoice-total-amount">
              {discount > 100 ? 100 : discount || 0} %
            </p> */}
          <input
            type="number"
            className="form-control w-50 h-25"
            placeholder="0 %"
            min={0}
            max={100}
            value={discount}
            onChange={(e) => {
              setDiscount(e.target.value);
              setIsDiscount(true);
            }}
          />
        </div>

        <div className="invoice-total-item"></div>
        <hr className="my-2" />
        {/* <div className="invoice-total-item">
            <p className="invoice-total-title">Total:</p>
            <input
              type="number"
              className="form-control w-50 h-25"
              placeholder="0 %"
              min={0}
              // max={100}
              value={totalAmmount}
              onChange={(e) => {
                setTotalAmmount(e.target.value);
                setIsTotal(true);
              }}
            />
          </div> */}
        <div className="invoice-total-item">
          <p className="invoice-total-title mt-2">Total:</p>
          <div className="form-group w-75">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">{currency}</span>
              </div>
              <input
                type="number"
                className="form-control"
                placeholder="Total"
                min={0}
                value={totalAmmount}
                onChange={(e) => {
                  setTotalAmmount(e.target.value);
                  setIsTotal(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubTotal;
