import React, { useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { useState } from "react";
import * as Icon from "react-bootstrap-icons";

import { useSelector } from "react-redux";
import {
  Badge,
  Button,
  Dropdown,
  OverlayTrigger,
  Pagination,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { ReactComponent as Plus } from "../Assets/Plus.svg";
import { ReactComponent as Preview } from "../Assets/Preview.svg";

import {
  useDeleteTourMutation,
  useGetAlltoursQuery,
  useUpdateTourMutation,
} from "../Slices/spaceApi";
import { useSortableData } from "../utils/SortingFunction";
import { colorStyles } from "../utils/constant";
import { ReactComponent as DeleteIcon } from "../Assets/DeleteIcon.svg";
import { ReactComponent as EditIcon } from "../Assets/EditIcon.svg";
import { ReactComponent as Paid } from "../Assets/InvoiceIcon.svg";
import { ReactComponent as Lock } from "../Assets/Lock.svg";

import Loader from "../Components/Loader/Loader";
import {
  ConfirmationAlert,
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../utils/Alerts";
import { useGlobalContext } from "../Context/GlobalContext";

const TourList = () => {
  const navigate = useNavigate();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Tours List");
  }, []);
  const [searchQuery, setSearchQuery] = useState("");
  const [search, setSearch] = useState("");
  const [activePage, setActivePage] = useState(1);
  const { toursPermissions } = useGlobalContext();
  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const [status, setStatus] = useState({
    label: "Status",
    value: "all",
  });
  const [visited, setVisited] = useState({
    label: "All",
    value: "all",
  });
  const [status1, setStatus1] = useState({
    label: "All",
    value: "all",
  });

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [loading, setLoading] = useState(false);

  const { data: tours, isLoading: fetchingData } = useGetAlltoursQuery({
    spaceId: spaceId || currentSpaceId,
    page: activePage,
    page_size: itemsPerPage.value,
    name: searchQuery,
    status: status.value,
    time: status1.value,
    visited: visited.value,
  });

  useEffect(() => {
    setLoading(false);
  }, [tours]);

  const { items, requestSort, sortConfig } = useSortableData(
    tours?.tours || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);

    setLoading(true);
  };
  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      setLoading(true);
      setSearchQuery(search);
      // setActivePage(1);
    };
    clearTimeout(timer);
    if (search.length > 0) {
      timer = setTimeout(debounceSearch, 1000);
    } else {
      setSearchQuery("");
      // setActivePage(1);
      setLoading(false);
    }
    return () => clearTimeout(timer);
  }, [search]);
  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];
  const optionsStatus = [
    {
      label: "Status",
      value: "",
    },
    {
      label: "Approved",
      value: 2,
    },
    {
      label: "Not Approved",
      value: 1,
    },
    {
      label: "Waiting Approval",
      value: 3,
    },
  ];
  const optionsStatus2 = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Visited",
      value: "True",
    },
    {
      label: "Not Visited",
      value: "False",
    },
  ];

  const optionsStatus1 = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Todays Tours",
      value: 2,
    },
    {
      label: "Past Tours",
      value: 1,
    },

    {
      label: "Pending Visit",
      value: 3,
    },
  ];

  const handleStatus = (e) => {
    setStatus(e);
    setLoading(true);
  };
  const handleVisitedStatus = (e) => {
    setVisited(e);
    setLoading(true);
  };
  const handleStatus1 = (e) => {
    setStatus1(e);
    setLoading(true);
  };

  const [deleteVisit, resultStats] = useDeleteTourMutation();
  const [updateTour, resultStats1] = useUpdateTourMutation();

  const handleApproveTour = (item) => {
    if (item.status == 2) {
      requiredAlert("Tour is already approved.");
      return;
    }
    if (item.status == 1) {
      requiredAlert("Disapproved tour cannot be approved");
      return;
    }
    ConfirmationAlert("Do You Want to Approve This Tour?").then((approve) => {
      if (approve) {
        updateTour({
          spaceId: spaceId || currentSpaceId,
          tourId: item.id,
          data: {
            status: 2,
          },
        });
      }
    });
  };

  const handleDisapproveTour = (item) => {
    if (item.status == 2) {
      requiredAlert("Approved Tours can not be Disapproved");
      return;
    }
    if (item.status == 1) {
      requiredAlert("Tour is already disapproved");
      return;
    }
    ConfirmationAlert("Do You Want to Dispprove This Tour?").then((approve) => {
      if (approve) {
        updateTour({
          spaceId: spaceId || currentSpaceId,
          tourId: item.id,
          data: {
            status: 2,
          },
        });
      }
    });
  };

  const handleDeleteTour = (item) => {
    confirmationAlert("Do You Want to Delete Tour?").then((willDelete) => {
      if (willDelete) {
        deleteVisit({
          spaceId: spaceId || currentSpaceId,
          tourId: item.id,
        });
      }
    });
  };
  function formatDateTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}, ${formattedTime}`;
  }
  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Tour Deleted Successfully.");
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
    }
  }, [resultStats]);
  useEffect(() => {
    if (resultStats1?.isSuccess) {
      userDeleteAlert("Tour Approved Successfully.");
    }
    if (resultStats1?.isError) {
      requiredAlert(resultStats1?.error?.data?.message);
    }
  }, [resultStats1]);

  const handleStatusAction = (item, value) => {
    // updateTicketByAPi({
    //   spaceId: spaceId || currentSpaceId,
    //   data: { status: value },
    //   ticketId: item.id,
    // });
  };
  if (fetchingData) {
    return <Loader height={6.6} />;
  }

  return (
    <>
      <section
        className="container-fluid invoice-list-wrapper with-bottom-padding"
        style={{ padding: "0px 16px" }}
      >
        <div className="card">
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-between ">
              <div className="col-12  col-md-4 col-lg-6">
                <input
                  type="search"
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    // setLoading(true);
                  }}
                  placeholder="Search Tours"
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
                {toursPermissions[0]?.create ? (
                  <Link
                    to="/tour/add"
                    className="btn btn-primary"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Add Tour
                    </span>
                  </Link>
                ) : (
                  <div
                    className="btn btn-primary mt-2"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Add Tours
                      <Lock />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="col-12 ">
            <div className="">
              <div className="col-12 d-flex justify-content-end">
                <label
                  style={{
                    display: "flex",
                    whiteSpace: "nowrap",
                    fontWeight: "normal",
                  }}
                >
                  <small className="mr-2 mt-2 title"> Show</small>
                  <Select
                    options={options}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    onChange={handlePage}
                    value={itemsPerPage}
                    id="pages-select"
                    styles={colorStyles}
                  />
                  <small className="ml-2 mt-2"> entries</small>
                </label>
              </div>
            </div>
          </div>

          <span className="ml-5">Search By Filters:</span>
          <div className="row ml-4 mr-4">
            <div className="col-12 col-md-4 mt-2">
              <Select
                options={optionsStatus}
                defaultValue={{
                  label: "Status",
                  value: "all",
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handleStatus}
                value={status}
                styles={colorStyles}
              />
            </div>

            <div className="col-12 col-md-4 mt-2">
              <Select
                options={optionsStatus1}
                defaultValue={{
                  label: "All",
                  value: "all",
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handleStatus1}
                value={status1}
                styles={colorStyles}
              />
            </div>
            <div className="col-12 col-md-4 mt-2">
              <Select
                options={optionsStatus2}
                defaultValue={{
                  label: "All",
                  value: "all",
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={handleVisitedStatus}
                value={visited}
                styles={colorStyles}
              />
            </div>
          </div>

          <div className="table-responsive mt-3">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Name</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("name")}
                          className={getClassNamesFor("name")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("name")}
                          className={getClassNamesFor("name")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Email</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("email")}
                          className={getClassNamesFor("email")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("email")}
                          className={getClassNamesFor("email")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Phone Number</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("phone_number")}
                          className={getClassNamesFor("phone_number")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("phone_number")}
                          className={getClassNamesFor("phone_number")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Date Time</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("tour_datetime")}
                          className={getClassNamesFor("tour_datetime")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("tour_datetime")}
                          className={getClassNamesFor("tour_datetime")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Status</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("status")}
                          className={getClassNamesFor("status")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("status")}
                          className={getClassNamesFor("status")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8} className="fs-5">
                      <Loader />
                    </td>
                  </tr>
                )}
                {!loading &&
                  (items?.length > 0 ? (
                    items?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <b>
                              {" "}
                              {item?.name && item?.name?.length > 20
                                ? item.name.substring(0, 20) + "..."
                                : item.name || "---"}
                            </b>
                          </td>
                          <td>{item?.email}</td>
                          <td>{item?.phone_number}</td>
                          <td>{formatDateTime(item?.tour_datetime)}</td>

                          <td>
                            {/* {item?.status === 1 && (
                              <Badge bg="badge bg-label-danger">
                                Not Approved
                              </Badge>
                            )}
                            {item?.status === 2 && (
                              <Badge bg="badge bg-label-success">
                                Approved
                              </Badge>
                            )}
                            {item?.status === 3 && (
                              <Badge bg="badge bg-label-info">
                                Waiting Approval
                              </Badge>
                            )} */}
                            <Dropdown>
                              <Dropdown.Toggle
                                // variant={getVariant().toLowerCase()}
                                // variant="badge bg-label-success"
                                variant={
                                  item.status == 1
                                    ? "badge bg-label-danger"
                                    : item.status == 2
                                    ? "badge bg-label-success"
                                    : "badge bg-label-info"
                                }
                                id="dropdown-basic"
                              >
                                {item.status == 1
                                  ? "Not Approved"
                                  : item.status == 2
                                  ? "Approved"
                                  : "Waiting Approval"}
                                <Icon.CaretDownFill className="ml-1" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    toursPermissions[0]?.update
                                      ? handleDisapproveTour(item)
                                      : null
                                  }
                                >
                                  Disapproved
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    toursPermissions[0]?.update
                                      ? handleApproveTour(item)
                                      : null
                                  }
                                >
                                  Approve
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td className="d-flex flex-nowrap">
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  {" "}
                                  {toursPermissions[0]?.update ? (
                                    "View"
                                  ) : (
                                    <span>
                                      View <Lock />
                                    </span>
                                  )}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="white"
                                className="p-0"
                                onClick={() =>
                                  toursPermissions[0]?.read
                                    ? navigate(`/tourView/${item.id}`)
                                    : null
                                }
                              >
                                <Preview />
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  {toursPermissions[0]?.update ? (
                                    "Update"
                                  ) : (
                                    <span>
                                      Update <Lock />
                                    </span>
                                  )}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="white"
                                className="p-0 ml-1"
                                onClick={() =>
                                  toursPermissions[0]?.update
                                    ? navigate(`/tourUpdate/${item.id}`)
                                    : null
                                }
                              >
                                <EditIcon />
                              </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip">
                                  {toursPermissions[0]?.delete ? (
                                    "Delete"
                                  ) : (
                                    <span>
                                      Delete <Lock />
                                    </span>
                                  )}
                                </Tooltip>
                              }
                            >
                              <Button
                                variant="white"
                                className="p-0 ml-1"
                                onClick={() =>
                                  toursPermissions[0]?.delete
                                    ? handleDeleteTour(item)
                                    : null
                                }
                              >
                                <DeleteIcon />
                              </Button>
                            </OverlayTrigger>

                            {/* <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                variant="none"
                              
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <Icon.ThreeDotsVertical
                                  style={{
                                    cursor: "pointer",
                                    color: "black",
                                  }}
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    toursPermissions[0]?.update
                                      ? handleApproveTour(item)
                                      : null
                                  }
                                >
                                  {toursPermissions[0]?.update ? (
                                    "Approve"
                                  ) : (
                                    <span>
                                      Approve <Lock />
                                    </span>
                                  )}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    toursPermissions[0]?.delete
                                      ? handleDisapproveTour(item)
                                      : null
                                  }
                                >
                                  {toursPermissions[0]?.update ? (
                                    "Disapprove"
                                  ) : (
                                    <span>
                                      Disapprove <Lock />
                                    </span>
                                  )}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown> */}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-left fs-5">
                        {searchQuery == "" ? "No Record !" : "No tour found"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {!loading && tours?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing {tours?.tours?.length} of {tours?.totalCount}
                  &nbsp; entries
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: tours?.totalPages }).map((_, i) => (
                    <Pagination.Item
                      key={i}
                      active={i + 1 === activePage}
                      onClick={
                        i + 1 === activePage
                          ? ""
                          : () => handlePageSelect(i + 1)
                      }
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={activePage === tours?.totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default TourList;
