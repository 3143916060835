import React, { useEffect } from "react";
import AddTour from "./AddTour";
import { useParams } from "react-router-dom";
import { useGetTourQuery } from "../Slices/spaceApi";
import Loader from "../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { useGlobalContext } from "../Context/GlobalContext";

const TourUpdate = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Update Tour");
  }, []);
  const { id } = useParams();

  const { data, isLoading } = useGetTourQuery({
    spaceId: spaceId || currentSpaceId,
    tourId: id,
  });

  if (isLoading) {
    return <Loader height={6.6} />;
  }
  return (
    <AddTour
      tour_datetimePre={data?.tour_datetime}
      emailPre={data?.email}
      namePre={data?.name}
      phone_numberPre={data?.phone_number}
      space={data?.space}
      edit={true}
      tourId={id}
      response={data?.response}
    />
  );
};

export default TourUpdate;
