import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as EditIcon } from "../../Assets/EditIcon.svg";
import MeetingFields from "./MeetingFields";
import MeetingInputs from "./MeetingInputs";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useUpdateMeetingRoomMutation } from "../../Slices/spaceApi";
import { useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";
import { useGlobalContext } from "../../Context/GlobalContext";
import * as Icon from "react-bootstrap-icons";
import Select from "react-select";
import { colorStyles } from "../../utils/constant";
const UpdateMeetingPopUp = ({ items, index, FetchSpaceTypes }) => {
  const { resourcesPermissions, billingOption } = useGlobalContext();

  const {
    title,
    price,
    bookable,
    capacity,
    id,
    imageUrls,
    inclusiveTax,
    spaceType,
    spaceTypeString,
  } = items[index];

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateMeetingRoom, resultStats] = useUpdateMeetingRoomMutation();
  const [show, setShow] = useState(false);
  const [allDone, setAllDone] = useState(true);
  const [file, setFile] = useState(false);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [errorSpaceType, setErrorSpaceType] = useState(false);
  const [updateMeeting, setUpdateMeeting] = useState({
    capacity,
    title,
    price,
    bookable: bookable || false,
    inclusiveTax,
    imageUrls: imageUrls,
    room_type: 1,
    spaceType,
  });

  const handleUpdateMeeting = (item) => {
    setUpdateMeeting({
      capacity: item.capacity,
      title: item.title,
      price: item.price,
      bookable: item.bookable,
      inclusiveTax: item.inclusiveTax,
      imageUrls: item.imageUrls,
      room_type: 1,
      spaceType: item.spaceType,
    });
  };
  const onBlurSpaceType = (e) => {
    if (!updateMeeting.spaceType) {
      setErrorSpaceType(true);
    }
  };

  const onFocusSpaceType = (e) => {
    if (updateMeeting.spaceType) {
      setErrorSpaceType(false);
    }
  };
  const handleType = (e) => {
    setUpdateMeeting({ ...updateMeeting, spaceType: e.id });
    setErrorSpaceType(false);
  };

  useEffect(() => {
    handleUpdateMeeting({
      title,
      price,
      bookable,
      capacity,
      imageUrls,
      inclusiveTax,
      spaceType,
    });
  }, [title, price, capacity, imageUrls, bookable, inclusiveTax, spaceType]);

  const handleClose = () => {
    setIsUploading(false);

    setShow(false);
  };
  const handleShow = () => setShow(true);

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  // useEffect(() => {
  //   if (!file == "") {
  //     if (file.size >= 1e7) {
  //       errorAlertImage("File is too Big");
  //       setFile("");
  //     } else {
  //     }
  //   }
  //   if (file) {
  //     const fd = new FormData();
  //     setUpdateMeeting({ ...updateMeeting, imageUrls: [] });
  //     fd.append("image", file);
  //     axios.post("/uploadImage/", fd).then((resp) => {
  //       setUpdateMeeting({ ...updateMeeting, imageUrls: resp.data });
  //     });
  //   }
  // }, [file]);

  useEffect(() => {
    if (!file == "" && file !== null) {
      setIsUploading(true);

      if (file.size >= 1e7) {
        errorAlertImage("File is too Big");
        setIsUploading(false);

        setFile("");
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        setUpdateMeeting({ ...updateMeeting, imageUrls: [] });
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setUpdateMeeting({ ...updateMeeting, imageUrls: resp.data });
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile("");
        requiredAlert("Please Select Image File");
        setIsUploading(false);
      }
    }
  }, [file]);
  const handleInclusiveTax = (event) => {
    setUpdateMeeting({
      ...updateMeeting,
      inclusiveTax: event.target.checked,
    });
  };
  const handleBookable = (event) => {
    setUpdateMeeting({
      ...updateMeeting,
      bookable: event.target.checked,
    });
  };
  const updateMeetingSpace = (e) => {
    e.preventDefault();

    const {
      capacity,
      imageUrls,
      price,
      bookable,
      title,
      inclusiveTax,
      spaceType,
    } = updateMeeting;

    if (allDone) {
      return;
    }
    if (!file) {
      let filename = null;
      if (
        Array.isArray(imageUrls) &&
        imageUrls.length > 0 &&
        typeof imageUrls[0] === "string"
      ) {
        const parts = imageUrls[0].split("/");
        const lastPart = parts[parts.length - 1];
        filename = lastPart.split("?")[0];
        if (filename.includes("%20")) {
          filename = filename.replace(/%20/g, " ");
        }
        setApiSpinner(true);
        updateMeetingRoom({
          spaceId: spaceId || currentSpaceId,
          id,
          updateMeeting: {
            capacity,
            imageUrls: [filename],
            price,
            bookable: bookable || false,
            spaceType,
            title,
            inclusiveTax,
            room_type: 1,
          },
        });
      }
    } else {
      setApiSpinner(true);
      updateMeetingRoom({
        spaceId: spaceId || currentSpaceId,
        id,
        updateMeeting,
      });
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      // requiredAlert(resultStats?.error?.data?.message);
      requiredAlert(resultStats?.error?.data?.error);
    }

    if (resultStats?.isSuccess) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      setApiSpinner(false);
      setFile(false);

      toast.success("Meeting Room Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  const onchange = (e) => {
    setUpdateMeeting({ ...updateMeeting, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      updateMeeting?.capacity > 0 &&
      // updateMeeting.price.match("[1-9]+") &&
      updateMeeting?.title &&
      // ?.match(
      //   "^(?=.*[A-Za-z0-9])[A-Za-z0-9!@#$%^&* ]{6,}$"
      // )
      updateMeeting.imageUrls.length > 0
    ) {
      setAllDone(false);
    } else {
      setAllDone(true);
    }
  }, [updateMeeting]);

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {resourcesPermissions[0]?.update ? (
              "Update"
            ) : (
              <span>
                Update <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="mx-2 p-0"
          onClick={resourcesPermissions[0]?.update ? handleShow : null}
        >
          <EditIcon />
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Resource</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="mb-2">
              <label htmlFor="name">Image</label>

              <div className="media">
                <div className="media-aside uploadgn-self-start">
                  <img
                    src={file ? URL.createObjectURL(file) : imageUrls}
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    className="rounded"
                  />
                </div>
                <div>
                  <input
                    id="real-file"
                    name="file"
                    type="file"
                    hidden="hidden"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="media-body mt-2">
                  {/* <button
                    id="custom-button"
                    onClick={upload}
                    className="btn btn-primary me-2 btn-sm"
                  >
                    Upload
                  </button> */}
                  {isUploading ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Uploading...</span>
                    </div>
                  ) : (
                    <button
                      id="custom-button"
                      onClick={upload}
                      className="btn btn-secondary me-2 btn-sm"
                    >
                      Upload
                    </button>
                  )}
                  <p className="card-text mt-2">
                    <Icon.InfoCircle style={{ color: "currentcolor" }} />
                    &nbsp;Allowed Max size of 10Mb
                    {/* <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                      }
                    >
                      <Button variant="white" className="mx-2 p-0">
                        <Icon.InfoCircle style={{ color: "currentcolor" }} />
                      </Button>
                    </OverlayTrigger> */}
                  </p>
                  {!isUploading && !updateMeeting?.imageUrls?.length > 0 ? (
                    <div className="invalid-feedback">
                      Please select an image.
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {MeetingInputs()?.map((input) => {
              if (input.id === 1 || input.id === 2) {
                return (
                  <MeetingFields
                    key={input.id}
                    {...input}
                    value={updateMeeting[input.name]}
                    onChange={onchange}
                  />
                );
              }
            })}
            {updateMeeting?.bookable && (
              <>
                {MeetingInputs()?.map((input) => {
                  if (input.id === 3) {
                    return (
                      <MeetingFields
                        key={input.id}
                        {...input}
                        value={updateMeeting[input.name]}
                        onChange={onchange}
                      />
                    );
                  }
                })}
              </>
            )}
            {billingOption == 1 && updateMeeting?.bookable && (
              <div className="custom-formCheck form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inclusiveTax"
                  checked={updateMeeting?.inclusiveTax}
                  onChange={(e) => handleInclusiveTax(e)}
                />
                <label className="form-check-label" htmlFor="inclusiveTax">
                  Price includes state Taxes.
                </label>
              </div>
            )}

            <div className="custom-formcheck form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="bookable"
                checked={updateMeeting?.bookable}
                onChange={(e) => handleBookable(e)}
              />
              <label className="form-check-label" htmlFor="bookAble">
                Slot Based Booking
              </label>
            </div>
            <div className="mb-2">
              <label htmlFor="space-type" className="mb-1">
                Space Type
              </label>
              <Select
                className={errorSpaceType ? "reactSelectActive" : ""}
                options={FetchSpaceTypes}
                defaultValue={{ id: spaceType }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                styles={colorStyles}
                value={FetchSpaceTypes?.find(
                  (obj) => obj.id === updateMeeting.spaceType
                )}
                onBlur={onBlurSpaceType}
                onChange={handleType}
                onFocus={onFocusSpaceType}
              />
              {errorSpaceType && (
                <span className="e">Select The SpaceType</span>
              )}
            </div>

            <div className="mt-4">
              <Button
                variant="primary"
                disabled={apiSpinner || isUploading}
                onClick={updateMeetingSpace}
              >
                Save Changes{" "}
                {apiSpinner ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "5px",
                    }}
                  ></div>
                ) : (
                  ""
                )}
              </Button>
              <button
                className="btn btn-secondary ml-3"
                disabled={apiSpinner}
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
          
          </Modal.Footer> */}
        </form>
      </Modal>
    </>
  );
};

export default UpdateMeetingPopUp;
