import React, { useEffect, useState } from "react";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import axios from "axios";
import getCurrentDate from "../../utils/getCurrentDate";
import * as Icon from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";

const OfferForm = ({
  partners,
  formData,
  setFormData,
  fromOffer,
  isUploading,
  setIsUploading,
  setFile,
  file,
  setPartnerCheck,
  partnerId,
}) => {
  const [touchedFields, setTouchedFields] = useState({
    discount_percentage: true,
  });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });

  // };
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the form data based on the input name
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      // If the changed field is "start_date", also update "end_date"
      ...(name === "start_date" && { end_date: value }),
    }));
  };

  const handleInputBlur = (field) => {
    setTouchedFields({
      ...touchedFields,
      [field]: true,
    });
  };

  // useEffect(() => {
  //   if (fromOffer == true) {
  //     setFormData({
  //       ...formData,
  //       partner: partners?.[0]?.id,
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       partner: partnerId,
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (file !== null && file !== "") {
      setIsUploading(true);
      if (file?.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile(null);
        setIsUploading(false);
      } else if (file?.type?.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setFormData({ ...formData, image: resp.data?.[0] });
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile(null);
        requiredAlert("Please Select Image File");
        setIsUploading(false);
      }
    }
    // setImgError(false);
  }, [file]);

  const uploadFile = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };

  // useEffect(() => {
  //   setPackageValues({ ...packageValues, images: uploadedImageUrls });
  // }, [uploadedImageUrls]);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
    // setImgError(false);
  };

  return (
    <div className="">
      <div className="mb-2">
        <label htmlFor="name">
          Image <span className="optional">(Optional)</span>
        </label>

        <div className="media">
          <div className="media-aside align-self-start">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : formData?.image
                  ? formData?.image
                  : "https://placehold.jp/90x90.png"
              }
              style={{
                width: "90px",
                height: "90px",
                objectFit: "cover",
              }}
              className="rounded"
              alt=""
            />
          </div>
          <div>
            <input
              id="real-file"
              name="file"
              type="file"
              hidden="hidden"
              onChange={handleFileChange}
            />
          </div>

          <div className="media-body mt-2">
            {isUploading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Uploading...</span>
              </div>
            ) : (
              <button
                id="custom-button"
                onClick={uploadFile}
                className="btn btn-secondary me-2 btn-sm"
              >
                Upload
              </button>
            )}
            <p className="card-text mt-2">
              <Icon.InfoCircle style={{ color: "currentcolor" }} />
              &nbsp; Allowed Max size of 10Mb
              {/* <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                }
              >
                <Button variant="white" className="mx-2 p-0">
                  <Icon.InfoCircle style={{ color: "currentcolor" }} />
                </Button>
              </OverlayTrigger> */}
            </p>
            {/* {imgError ? (
              <div className="invalid-feedback">Please select an image.</div>
            ) : null} */}
          </div>
        </div>
      </div>

      <div className="mb-2">
        <label htmlFor="partner" className="form-label">
          Partner
        </label>
        {partners?.length > 0 ? (
          <select
            className="form-select"
            id="partner"
            name="partner"
            // value={formData?.partner}
            defaultValue={partnerId}
            disabled={fromOffer}
            onChange={handleChange}
          >
            {partners?.map((partner) => (
              <option
                key={partner.id}
                value={partner.id}
                // selected={partner.id === partnerId}
              >
                {partner.name}
              </option>
            ))}
          </select>
        ) : (
          <button
            className="btn-link"
            onClick={(e) => {
              e.preventDefault();
              setPartnerCheck(true);
            }}
          >
            Please Add Partners to Create Offer
          </button>
        )}
      </div>
      <div className="mb-2">
        <label htmlFor="discount_percentage" className="form-label">
          Offer Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Offer Name"
          name="name"
          value={formData?.name}
          onChange={handleChange}
          // onBlur={() => handleInputBlur("discount_percentage")}
        />
        {/* {touchedFields.discount_percentage &&
          (formData.discount_percentage === "" ||
            formData.discount_percentage <= 0) && (
            <div className="invalid-feedback">
              Discount Percentage must be greater than 0.
            </div>
          )} */}
      </div>
      <div className="mb-2">
        <label htmlFor="discount_percentage" className="form-label">
          Discount Percentage %
          <br />
          <Icon.InfoCircle />{" "}
          <small>
            {/* (If the discount is not in percentage, kindly specify the unit of
            measurement alongside the discount amount in description.) */}
            Specify the discount unit if not in percentage in the description.
          </small>
        </label>
        <input
          type="number"
          // className={`form-control ${
          //   touchedFields.discount_percentage &&
          //   (formData.discount_percentage === "" ||
          //     formData.discount_percentage <= 0)
          //     ? "is-invalid"
          //     : ""
          // }`}
          className="form-control"
          id="discount_percentage"
          placeholder="Add a discount %"
          name="discount_percentage"
          value={formData?.discount_percentage}
          onChange={handleChange}
          // onBlur={() => handleInputBlur("discount_percentage")}
        />
        {/* {touchedFields.discount_percentage &&
          (formData.discount_percentage === "" ||
            formData.discount_percentage <= 0) && (
            <div className="invalid-feedback">
              Discount Percentage must be greater than 0.
            </div>
          )} */}
      </div>
      <div className="mb-2">
        <label htmlFor="start_date" className="form-label">
          Start Date
        </label>
        <div className="input-group ">
          <div className="input-group-append">
            <span
              className="input-group-text"
              style={{
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
            >
              <Icon.Calendar2Check />
            </span>
          </div>
          <input
            type="date"
            className="form-control"
            id="start_date"
            name="start_date"
            value={formData?.start_date}
            onChange={handleChange}
            min={getCurrentDate()}
          />
        </div>
      </div>
      <div className="mb-2">
        <label htmlFor="end_date" className="form-label">
          End Date
        </label>
        <div className="input-group ">
          <div className="input-group-append">
            <span
              className="input-group-text"
              style={{
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
              }}
            >
              <Icon.Calendar2Check />
            </span>
          </div>
          <input
            type="date"
            className="form-control"
            id="end_date"
            name="end_date"
            value={formData?.end_date}
            onChange={handleChange}
            // min={getCurrentDate()}
            min={formData?.start_date}
          />
        </div>
      </div>

      <div className="mb-2">
        <label htmlFor="description">
          Description <span className="optional">(Optional)</span>
        </label>
        <textarea
          className="form-control"
          id="description"
          name="description"
          placeholder="Enter a  description"
          value={formData?.description}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default OfferForm;
