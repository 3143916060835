import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
import loginImg from "../../Assets/loginImg.svg";
import "./index.css";
import axios from "axios";
import { useGlobalAuthContext } from "../../AuthContext/AuthContext";
import { EyeOff, EyeOn } from "../../Assets/SettingSvgs/Icon";
import Spinner from "react-bootstrap/Spinner";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  LOCATIONID,
  SPACECREDS,
  SPACEID,
  SPACEIDS,
} from "../../Slices/spaceSlice";
import { requiredAlert } from "../../utils/Alerts";
import GetCookie from "../../utils/getCookie";
import SetCookie from "../../utils/setCookie";
import logo1 from "../../Assets/nDesk/LogoFull.png";
import { useGetSpaceDataQuery } from "../../Slices/spaceApi";
import NewUserCheck from "./NewUserCheck";
import Loader from "../Loader/Loader";

const Login = () => {
  const Dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [pswIcon, setPswIcon] = useState(false);
  const [type0, setType0] = useState(true);
  const [load, setLoad] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);

  const [remember, setRemember] = useState(false);
  const [check, setCheck] = useState(false);
  const { email, setEmail } = useGlobalContext();
  const Navigate = useNavigate();
  const { dispatch } = useGlobalAuthContext();

  const oldPsw = () => {
    setType0(!type0);
    setPswIcon(!pswIcon);
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      const response = await axios.post("/login/", {
        email,
        password,
      });
      const tokenL = response.data;
      const TokenS = tokenL["login-token"];

      // if (response.data.userType !== "owner") {
      //   console.log("I am member");
      //   // Set cookies
      //   document.cookie = `token=${
      //     response?.data?.["login-token"].token
      //   }; path=/; expires=${new Date(
      //     response?.data?.["login-token"].token.expiresIn * 1000
      //   ).toUTCString()}`;
      //   document.cookie = `userId=${response.data.userId}; path=/`;
      //   document.cookie = `spaceId=${response.data.Spaces[0]}; path=/`;
      //   document.cookie = `creds=${response.data.creds}; path=/`;
      //   document.cookie = `billing_option=${response.data.billing_option}; path=/`;
      //   // Redirect to the members' site
      //   window.location.href = "https://members.ndesks.com";
      //   return;
      // }
      if (response.data.userType !== "owner") {
        // Set cookies with domain attribute
        document.cookie = `token=${
          response?.data?.["login-token"].token
        }; path=/; domain=.ndesks.com; samesite=None; expires=${new Date(
          response?.data?.["login-token"].token.expiresIn * 1000
        ).toUTCString()}`;
        document.cookie = `userId=${response.data.userId}; path=/; domain=.ndesks.com; samesite=None;`;
        document.cookie = `spaceId=${response.data.Spaces[0]}; path=/; domain=.ndesks.com; samesite=None;`;
        document.cookie = `creds=${response.data.creds}; path=/; domain=.ndesks.com; samesite=None;`;
        document.cookie = `billing_option=${response.data.billing_option}; path=/; domain=.ndesks.com; samesite=None;`;
        // Redirect to the members' site
        window.location.href = "https://members.ndesks.com";
        return;
      }

      dispatch({ type: "LOGIN2", payload: TokenS });

      SetCookie("session-token", TokenS !== undefined ? TokenS.token : null);

      if (remember) {
        SetCookie("token", TokenS !== undefined ? TokenS.token : null);
      }
      Dispatch(SPACEID(response?.data?.spaces[0]));

      // SetCookie("locationId", response?.data?.spaces[0]);
      // Dispatch(LOCATIONID(response?.data?.spaces[0]));

      Dispatch(SPACECREDS(response?.data?.creds));
      Dispatch(SPACEIDS(response?.data?.spaces));

      Navigate("/ndesks");
    } catch (error) {
      setLoad(false);
      requiredAlert(error?.response?.data?.message || "No Internet Connection");
    }
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setRemember(true);
    } else {
      setRemember(false);
    }
  };
  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setEmailValid(e.target.validity.valid);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordValid(e.target.validity.valid);
  };

  useEffect(() => {
    if (GetCookie("token") || GetCookie("session-token")) {
      Navigate("/");
    }
  }, []);

  return (
    <section id="login" className="vh-100">
      <div className="row w-100 m-0 row-length">
        <Link to="/" className="login-logo d-md-block d-none">
          <img src={logo1} alt="login-logo" />
        </Link>
        <div
          className="col-md-8 p-5 d-none d-md-flex justify-content-center"
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <div className="px-5">
            <img src={loginImg} alt="login-img" />
          </div>
        </div>
        <div className="col-md-4 bg-white relative" style={{ height: "100vh" }}>
          <div className="center-form " style={{ padding: "5rem" }}>
            <Link to="/" className="d-block  text-center">
              <img style={{ width: "145px" }} src={logo1} alt="login-logo" />
            </Link>
            <h3 className="card-title mb-2 text-center text-md-start">
              Welcome to nDesks! 👋🏻
            </h3>
            <p className="card-text">
              Please sign-in to your account and start the adventure
            </p>
            <form onSubmit={submit} className="auth-login-form mt-2">
              <div role="group" className="form-group">
                <label htmlFor="login-email" className="d-block mb-0">
                  Email
                </label>
                <div>
                  <input
                    id="login-email"
                    name="email"
                    type="email"
                    required
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="john@example.com"
                    className={`form-control mt-2 ${
                      emailValid ? "" : "is-invalid"
                    }`}
                  />
                </div>
                {!emailValid && (
                  <div className="invalid-feedback">
                    Please enter correct email
                  </div>
                )}
              </div>
              <fieldset className="form-group" id="__BVID__53">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="login-password" className="mb-0">
                      Password
                    </label>
                    <Link
                      className="text-decoration-none"
                      to="/forgetPsw"
                      target="_self"
                    >
                      <small>Forgot Password?</small>
                    </Link>
                  </div>
                  <div className="input-group mt-2">
                    <input
                      id="login-password"
                      name="password"
                      type={type0 ? "password" : "text"}
                      required
                      value={password}
                      onChange={handlePasswordChange}
                      className={`form-control-merge  form-control ${
                        passwordValid ? "" : "is-invalid"
                      }`}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text" onClick={oldPsw}>
                        {pswIcon ? EyeOn : EyeOff}
                      </div>
                    </div>
                  </div>
                </div>
                {!passwordValid && (
                  <div className="invalid-feedback">Please enter password</div>
                )}
              </fieldset>
              <fieldset className="form-group" id="__BVID__58">
                <Form.Check
                  type="checkbox"
                  value={"remember"}
                  onChange={handleChange}
                  id="Remember me"
                  label="Remember me for 15 days"
                />
              </fieldset>
              <Button
                type="submit"
                className="btn btn-primary btn-block mt-2"
                disabled={load}
              >
                {load && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </>
                )}
                {!load ? "Sign in" : null}
              </Button>
            </form>
            <div className="mt-3 text-center">
              New to our platform?{" "}
              <span
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/register")}
              >
                Create an account
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
