import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import {
  useDeletevisitorMutation,
  useDeletevisitsMutation,
  useGetAllVisitorsQuery,
  useGetAllVisitsQuery,
} from "../../Slices/spaceApi";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";

import { Badge, Pagination, Tab, Tabs } from "react-bootstrap";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import { ReactComponent as Plus } from "../../Assets/Plus.svg";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import Loader from "../Loader/Loader";

import DeleteVisit from "./DeleteVisit";
import UpdateVisit from "./UpdateVisit";
import ViewVisitor from "./ViewVisitor";
import { useGlobalContext } from "../../Context/GlobalContext";
import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import UpdateVisitor from "./UpdateVisitor";

const VisitorsTable = () => {
  const { visitsPermissions } = useGlobalContext();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Visitors List");
  }, []);
  const [searchQuery, setSearchQuery] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [search, setSearch] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const [status, setStatus] = useState({
    label: "Select All",
    value: 0,
  });
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [loading, setLoading] = useState(false);
  // const { data: visitsData, isLoading: fetchingData } = useGetAllVisitsQuery({
  //   spaceId: spaceId || currentSpaceId,
  //   page: activePage,
  //   page_size: itemsPerPage.value,
  //   name: searchQuery,
  // });
  const [deleteVisit, resultStats] = useDeletevisitorMutation();
  const { data: visitorData, isLoading: fetchingData } = useGetAllVisitorsQuery(
    {
      spaceId: spaceId || currentSpaceId,
      page: activePage,
      page_size: itemsPerPage.value,
      name: searchQuery,
    }
  );

  useEffect(() => {
    setLoading(false);
  }, [visitorData]);
  const { items, requestSort, sortConfig } = useSortableData(
    visitorData?.visitors || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const handlePageSelect = (page) => {
    setActivePage(page);
    setLoading(true);
  };

  const handlePage = (e) => {
    setitemsPerPage(e);
    setActivePage(1);

    setLoading(true);
  };

  const handleStatus = (e) => {
    setStatus(e);
  };
  useEffect(() => {
    let timer;
    const debounceSearch = () => {
      setLoading(true);
      setSearchQuery(search);
      // setActivePage(1);
    };
    clearTimeout(timer);
    if (search.length > 0) {
      timer = setTimeout(debounceSearch, 1000);
    } else {
      setSearchQuery("");
      // setActivePage(1);
      setLoading(false);
    }
    return () => clearTimeout(timer);
  }, [search]);
  const formateDate = (createdAt) => {
    const date = new Date(createdAt);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];

  const optionsStatus = [
    {
      label: "All",
      value: 0,
    },
    {
      label: "Individual",
      value: 1,
    },
    {
      label: "Company",
      value: 2,
    },
  ];
  // const [apiSpinner, setApiSpinner] = useState(false);
  const [visitorName, setVisitorName] = useState("");
  const handleDeleteVisitor = (item) => {
    confirmationAlert("Do You Want to Delete This Visitor?").then(
      (willDelete) => {
        if (willDelete) {
          // setApiSpinner(true);
          setVisitorName(item.name);
          deleteVisit({
            spaceId: spaceId || currentSpaceId,
            id: item.id,
          });
        }
      }
    );
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert(`Visitor ${visitorName} Deleted Successfully.`);
      // navigate("/visitors/list");
      setVisitorName("");
      // setTimeout(() => {
      //   setApiSpinner(false);
      // }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
      setVisitorName("");
      // setTimeout(() => {
      //   setApiSpinner(false);
      // }, 500);
    }
  }, [resultStats]);

  return (
    <>
      <section
        className="container-fluid invoice-list-wrapper with-bottom-padding"
        style={{ padding: "0px 16px" }}
      >
        <div className="card">
          <div className="col-12 mt-4">
            <div className="d-flex justify-content-between ">
              <div className="col-12  col-md-4 col-lg-6">
                <input
                  type="search"
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    // setLoading(true);
                  }}
                  placeholder="Search Visitor"
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
                {visitsPermissions[0]?.create ? (
                  <Link
                    to="/visitors/add"
                    className="btn btn-primary"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Add Visitor
                    </span>
                  </Link>
                ) : (
                  <div
                    className="btn btn-primary"
                    tabIndex="0"
                    aria-controls="DataTables_Table_0"
                    type="button"
                  >
                    <span className="invoice">
                      <Plus /> Add Visit
                      <Lock />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr />

          <div className="col-12 ">
            <div className="">
              <div className="col-12 d-flex justify-content-end">
                <label
                  style={{
                    display: "flex",
                    whiteSpace: "nowrap",
                    fontWeight: "normal",
                  }}
                >
                  <small className="mr-2 mt-2 title"> Show</small>
                  <Select
                    options={options}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    onChange={handlePage}
                    value={itemsPerPage}
                    id="pages-select"
                    styles={colorStyles}
                  />
                  <small className="ml-2 mt-2"> entries</small>
                </label>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>#</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("id")}
                          className={getClassNamesFor("id")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Visitor</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("customer:name")}
                          className={getClassNamesFor("customer:name")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("customer:name")}
                          className={getClassNamesFor("customer:name")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>E-Mail</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("subTotal")}
                          className={getClassNamesFor("subTotal")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("subTotal")}
                          className={getClassNamesFor("subTotal")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Phone #</div>
                      <div
                        className="d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => requestSort("dueDate")}
                          className={getClassNamesFor("dueDate")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ||
                  (fetchingData && (
                    <tr>
                      <td colSpan={8} className="fs-5">
                        <Loader />
                      </td>
                    </tr>
                  ))}

                {!loading && items?.length > 0 ? (
                  items?.map((item, index) => {
                    {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              color: "#7367f0",
                              fontWeight: "bold",
                            }}
                          >
                            #{item.visitorId || item.id}
                          </td>

                          <td>
                            <div className="media">
                              <div className="media-body my-auto d-flex flex-column">
                                <span className="mb-0 table-avatar">
                                  <b>
                                    {" "}
                                    {
                                      // item?.name
                                      item?.name?.length > 20
                                        ? item?.name?.slice(0, 20) + "..."
                                        : item?.name
                                    }
                                  </b>
                                </span>
                              </div>
                            </div>
                          </td>

                          <td>
                            {
                              // item?.email
                              item?.email?.length > 20
                                ? item?.email?.slice(0, 20) + "..."
                                : item?.email || (
                                    <Badge bg="badge bg-label-danger">
                                      No Email Provided
                                    </Badge>
                                  )
                            }
                          </td>

                          <td>
                            {
                              item?.phone_number || "--------------"
                              // (
                              //   <Badge bg="danger">Phone No not Provided</Badge>
                              // )
                            }
                          </td>

                          {/* <td>{item?.location}</td> */}
                          <td className="d-flex flex-nowrap">
                            <UpdateVisitor {...item} />

                            <div>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {visitsPermissions[0].delete ? (
                                      "Delete Visitor"
                                    ) : (
                                      <span>
                                        Delete
                                        <Lock />
                                      </span>
                                    )}
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="white"
                                  className="p-0"
                                  onClick={
                                    visitsPermissions[0].delete
                                      ? () => handleDeleteVisitor(item)
                                      : null
                                  }
                                  // disabled={apiSpinner}
                                >
                                  <DeleteIcon />
                                </Button>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-left fs-5">
                      {search == "" ? "No Record !" : "No visitor found"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {!loading && visitorData?.totalPages > 1 && (
            <>
              <div className="m-0 border-bottom" />
              <div className="pagination-custom flex-column flex-lg-row">
                <p className="text-muted mb-0">
                  Showing {visitorData?.visitors?.length} of{" "}
                  {visitorData?.totalCount}
                  &nbsp; entries
                </p>
                <Pagination className="mt-lg-0 mt-2">
                  <Pagination.Prev
                    className="previous"
                    onClick={() => {
                      setActivePage(activePage - 1);
                      setLoading(true);
                    }}
                    disabled={activePage === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: visitorData?.totalPages }).map(
                    (_, i) => (
                      <Pagination.Item
                        key={i}
                        active={i + 1 === activePage}
                        onClick={
                          i + 1 === activePage
                            ? ""
                            : () => handlePageSelect(i + 1)
                        }
                      >
                        {i + 1}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    className="next"
                    onClick={() => {
                      setActivePage(activePage + 1);
                      setLoading(true);
                    }}
                    disabled={activePage === visitorData?.totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default VisitorsTable;
