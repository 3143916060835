import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { Amenities, Select } from "./";
import { errorAlertImage } from "../../utils/Alerts";
import { DataOfTime } from "../../utils/constant";
import { requiredAlert } from "../../utils/Alerts";
import { toast } from "react-hot-toast";
import { DashLg } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import {
  useGetCurrencyQuery,
  useUpdateSpaceMutation,
} from "../../Slices/spaceApi";
import TimeHours from "./timeHours.json";
import Logo1 from "../../Assets/dummyImages/Logo1.png";
import { Lock, Lock1, Lock2 } from "../../Assets/MemberSvgs/Icon";
import { useGlobalContext } from "../../Context/GlobalContext";

function BookingSlots({ spaceData }) {
  const { settingsPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateGeneral, resultStats] = useUpdateSpaceMutation();

  const [allowedSlots, setAllowedSlots] = useState(0);

  useEffect(() => {
    setAllowedSlots(spaceData?.max_booking_slots);
  }, [spaceData]);

  const [slotChanged, setSlotChanged] = useState(false);

  const handleSlotChanged = (e) => {
    setSlotChanged(true);
    setAllowedSlots(e.target.value);
  };

  const updateSlotChanged = () => {
    updateGeneral({
      spaceId: spaceId || currentSpaceId,
      data: { max_booking_slots: parseInt(allowedSlots) },
    });
  };

  const handleClose = () => {
    setSlotChanged(false);
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object?.values(resultStats?.error?.data)?.find(
        (error) => error.length > 0
      );

      requiredAlert(errorMessage);
    }
    if (resultStats?.isSuccess) {
      setTimeout(() => {
        toast.success("Booking Slots Configured SuccessFully!", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      }, 1000);

      handleClose();
    }
  }, [resultStats]);
  return (
    <div>
      <div className="card">
        <div className="row p-4">
          <h4>Booking Slots Configuration</h4>

          <div className="form-group">
            <label htmlFor="phoneNo">
              Set number of slots a member can book in a day. Input 0 to keep it
              unlimited.
            </label>
            <input
              type="number"
              className="form-control col-sm-12 col-md-3"
              placeholder="Please add max slots for a booking"
              name="phoneNo"
              value={allowedSlots}
              onChange={handleSlotChanged}
              min={0}
              required
              // pattern="\d{11,15}"
            />

            <div className="row mt-5">
              <div className="col-md-12">
                <button
                  className="btn btn-primary mt-2 "
                  disabled={!slotChanged || !settingsPermissions[0]?.update}
                  onClick={
                    settingsPermissions[0]?.update ? updateSlotChanged : null
                  }
                >
                  {settingsPermissions[0]?.update ? (
                    "Save Changes"
                  ) : (
                    <span>Save Changes{Lock2}</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingSlots;
