import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetAllEmployeesQuery,
  useGetAllLocationsQuery,
  useGetAllTeamsQuery,
  useGetRolesQuery,
} from "../../Slices/spaceApi";
import { Pagination } from "react-bootstrap";

import { useSortableData } from "../../utils/SortingFunction";
import * as Icon from "react-bootstrap-icons";
import DeleteEmployee from "./DeleteEmployee";
import { colorStyles } from "../../utils/constant";
import Select from "react-select";
import Loader from "../Loader/Loader";
import EmployeeForm from "./EmployeeForm";
import { Badge } from "react-bootstrap";
import ViewEmployee from "./ViewEmployee";

const Employees = () => {
  const [search, setSearch] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [updateModalVisible, setUpdateModalVisible] = useState(false);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const handlePageSelect = (page) => {
    setActivePage(page);
  };
  const handleOpenUpdateModal = (team) => {
    setSelectedTeam(team);
    setUpdateModalVisible(true);
  };
  const options = [
    {
      label: 10,
      value: 10,
    },
    {
      label: 20,
      value: 20,
    },
    {
      label: 30,
      value: 30,
    },
    {
      label: 50,
      value: 50,
    },
  ];
  const [itemsPerPage, setitemsPerPage] = useState({
    label: 10,
    value: 10,
  });
  const handlePage = (e) => {
    setitemsPerPage(e);
  };

  const { data: getTeams, isLoading: loading } = useGetAllTeamsQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: Roles, isLoading: rolesLoading } = useGetRolesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: Locations, isLoading: locationLoading } =
    useGetAllLocationsQuery({
      spaceId: spaceId || currentSpaceId,
    });

  const { data: getEmployees, isLoading: employeesLoading } =
    useGetAllEmployeesQuery({
      spaceId: spaceId || currentSpaceId,
    });

  const { items, requestSort, sortConfig } = useSortableData(
    getEmployees?.owner_spaces || []
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const filteredItems = items
    // ?.slice()
    // .reverse()
    ?.filter((val) => {
      if (search == "") {
        return val;
      } else if (
        val?.email?.toLowerCase()?.includes(search.toLowerCase()) ||
        val?.name?.toLowerCase()?.includes(search.toLowerCase())
      ) {
        return val;
      }
    });

  function formatDate(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedDate}`;
  }
  function formatTime(dateTimeString) {
    let date = new Date(dateTimeString);

    const dateFormatter = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedDate = dateFormatter.format(date);
    const formattedTime = timeFormatter.format(date);

    return `${formattedTime}`;
  }
  if (loading || employeesLoading || locationLoading || rolesLoading) {
    return <Loader height={6.6} />;
  }

  return (
    // <section className="app-user-list">
    <div className="card">
      <div className="col-12 mt-4">
        <div className="d-flex justify-content-between ">
          <div className="col-12  col-md-4 col-lg-6">
            <input
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Employee"
              className="form-control"
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-end">
            <EmployeeForm
              // employeeData={{
              //   name: "",
              //   email: "",
              //   profileImage: "",
              //   locations: [],
              //   role: 0,
              //   team: "",
              //   status: 1,
              // }}
              Teams={getTeams}
              Roles={Roles?.roles}
              Locations={Locations?.spaces}
              isReadable={false}
              onCloseModal={() => setShowModal(false)} // Close modal from here
              onOpenModal={() => setShowModal(true)}
              showModal={showModal}
              teamCheck={false}
              title="Add Employee"
              submitButtonText="Add Employee"
            />
          </div>
        </div>
      </div>
      <div className="table-responsive mt-3">
        <table className="table">
          <thead>
            <tr>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Name</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("employees:name")}
                      className={getClassNamesFor("employees:name")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("employees:name")}
                      className={getClassNamesFor("employees:name")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Team</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("team")}
                      className={getClassNamesFor("team")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("team")}
                      className={getClassNamesFor("team")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Last Login</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("lastlogin")}
                      className={getClassNamesFor("lastlogin")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("lastlogin")}
                      className={getClassNamesFor("lastlogin")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Status</div>
                  <div className="d-flex" style={{ flexDirection: "column" }}>
                    <Icon.ChevronUp
                      onClick={() => requestSort("status")}
                      className={getClassNamesFor("status")}
                      style={{ fontSize: "10px" }}
                    />

                    <Icon.ChevronDown
                      onClick={() => requestSort("status")}
                      className={getClassNamesFor("status")}
                      style={{ fontSize: "10px" }}
                    />
                  </div>
                </div>
              </th>
              <th>
                <div
                  className="d-flex align-items-center sort"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>Role</div>
                  <div
                    className="d-flex"
                    style={{ flexDirection: "column" }}
                  ></div>
                </div>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={8} className="fs-5">
                  <Loader />
                </td>
              </tr>
            )}
            {!loading && filteredItems?.length > 0 ? (
              filteredItems?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="media">
                        <div className="media">
                          <div className="media-aside logo-list mr-2">
                            <img
                              src={
                                item?.profileImage ||
                                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="media-body my-auto d-flex flex-column">
                          <span className="mb-0 table-avatar">
                            <b>
                              {item?.name?.length > 25
                                ? `${item?.name.slice(0, 25)}...`
                                : item?.name}
                            </b>
                          </span>
                          <small className="mb-0 text-muted">
                            {item?.email || (
                              <Badge bg={"badge bg-label-danger"}>
                                {"No E-mail Provided"}
                              </Badge>
                            )}
                          </small>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="media-body my-auto d-flex flex-column">
                        {getTeams?.teams?.map((team) => (
                          <span key={team.id} className="mb-0 table-avatar">
                            {item.team === team.id ? (
                              <span>{team.name}</span>
                            ) : null}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td>
                      <div className="my-auto d-flex flex-column">
                        <span className="mb-0">
                          {item.lastLogin ? (
                            formatDate(item.lastLogin)
                          ) : (
                            <Badge bg="badge bg-label-danger">
                              Not Login Yet
                            </Badge>
                          )}
                        </span>
                        <span className="mb-0">
                          {item.lastLogin ? formatTime(item.lastLogin) : ""}
                        </span>
                      </div>
                    </td>

                    <td>
                      <Badge
                        bg={
                          item?.status === 1
                            ? "badge bg-label-success"
                            : "badge bg-label-danger"
                        }
                      >
                        {item?.status === 1 ? "Active" : "Inactive"}
                      </Badge>
                    </td>
                    <td>
                      <div className="media-body my-auto d-flex flex-column">
                        {/* <span className="mb-0 table-avatar">{item.role}</span> */}
                        {Roles?.roles?.map((role) => {
                          if (role.id === item.role) {
                            return role.name;
                          }
                          return null;
                        })}
                      </div>
                    </td>

                    <td className="d-flex flex-nowrap">
                      <ViewEmployee
                        {...item}
                        Teams={getTeams}
                        Locations={Locations?.spaces}
                        Roles={Roles?.roles}
                      />

                      <EmployeeForm
                        employeeData={{
                          id: selectedTeam?.id,
                          name: selectedTeam?.name,
                          email: selectedTeam?.email,
                          profileImage: selectedTeam?.profileImage,
                          role: selectedTeam?.role,
                          locations: selectedTeam?.locations,
                          team: selectedTeam?.team || "",
                          status: selectedTeam?.status,
                        }}
                        isReadable={true}
                        Teams={getTeams}
                        Locations={Locations?.spaces}
                        Roles={Roles?.roles}
                        // onSubmit={updateEmployeeData}
                        onCloseModal={() => setUpdateModalVisible(false)}
                        onOpenModal={() => handleOpenUpdateModal(item)}
                        showModal={updateModalVisible}
                        teamCheck={false}
                        title="Update Employee"
                        submitButtonText="Update Employee"
                      />
                      <DeleteEmployee {...item} />
                    </td>
                  </tr>
                );
              })
            ) : !loading && filteredItems?.length == 0 ? (
              <tr>
                <td colSpan={6} className="text-left fs-5">
                  {search == "" ? "No Employee !" : "No Employee found"}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>

      {!loading && getEmployees?.totalPages > 1 && (
        <>
          <div className="m-0 border-bottom" />
          <div className="pagination-custom flex-column flex-lg-row">
            <p className="text-muted mb-0">
              Showing {getEmployees?.owner_spaces.length} of{" "}
              {getEmployees?.totalCount} entires
            </p>
            <Pagination className="mt-lg-0 mt-2">
              <Pagination.Prev
                className="previous"
                onClick={() => {
                  setActivePage(activePage - 1);
                  // setLoading(true);
                }}
                disabled={activePage === 1}
              >
                Previous
              </Pagination.Prev>
              {Array.from({
                length: getEmployees?.totalPages,
              }).map((_, i) => (
                <Pagination.Item
                  key={i}
                  active={i + 1 === activePage}
                  onClick={() => handlePageSelect(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                className="next"
                onClick={() => {
                  setActivePage(activePage + 1);
                  // setLoading(true);
                }}
                disabled={activePage === getEmployees?.totalPages}
              >
                Next
              </Pagination.Next>
            </Pagination>
          </div>
        </>
      )}
    </div>
    // </section>
  );
};

export default Employees;
