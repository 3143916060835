export default function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();

  // Add leading zeros to month and day if they are less than 10
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  // Return the formatted date (yyyy-mm-dd)
  return `${year}-${month}-${day}`;
}
