import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import {
  useAssetCategoryListQuery,
  useAssetGetQuery
} from '../../Slices/spaceApi'
import Loader from '../Loader/Loader'
import { useSelector } from 'react-redux'
// import AssetContext from "../../context/AssetContext";

const AssetView = ({ handleClose, operateId, data }) => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  // const { data, isLoading, refetch } = useAssetGetQuery({
  //   spaceId:spaceId|| currentSpaceId,
  //   id: operateId
  // })
  // useEffect(() => {
  //   refetch({ spaceId:spaceId|| currentSpaceId, id: operateId })
  // }, [])
  // const { categories: data1 } = useContext(AssetContext);
  const {
    data: data1,
    isLoading,
    isError,
    error,
    refetch
  } = useAssetCategoryListQuery({
    spaceId: spaceId || currentSpaceId
    // page_size: pageSize,
    // page: pageNumber,
    // name: searchValue,
  })
  const [category, setCategory] = useState([])
  useEffect(() => {
    const updatedArray = data1?.categories?.map(({ id, name }) => ({
      value: id,
      label: name
    }))

    setCategory(updatedArray)
  }, [data1])
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='w-10 '
      >
        <Modal.Header closeButton className=''>
          <Modal.Title>
            <h5>View Asset</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <>
              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Asset Name:</h6>
                  <p className='mb-0' id='AssettName' name='name'>
                    {data.name}
                  </p>
                </div>
              </Row>
              <Row className='mb-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Category:</h6>
                  <p className='mb-0 viewonly'>
                    {data && data.category
                      ? category.find(item => item?.value === data?.category)
                          ?.label || '-'
                      : '-'}
                  </p>
                </div>
              </Row>

              <Row className='mb-2'>
                <Col md='5'>
                  <h6 className='d-inline me-2'>Unit Value:</h6>
                  <p className='d-inline'>{data.value}</p>
                </Col>
                {/* <Col md='5'>
                  <h6 className='d-inline me-2'>Quantity:</h6>
                  <p className='d-inline'>{data.quantity}</p>
                </Col> */}
              </Row>

              <Row className='mb-3 mt-3'>
                <div className='d-flex align-items-center'>
                  <h6 className='me-3 mb-0'>Sku:</h6>
                  <p className='mb-0' id='sku' name='sku'>
                    {data.sku}
                  </p>
                </div>
              </Row>
              <Row className='mb-3 mt-3'>
                <Form.Label>
                  <h6>Description:</h6>
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  required
                  disabled
                  id='description'
                  as='textarea'
                  rows={3} // Set the number of rows to 3
                  placeholder='Description'
                  value={data.notes}
                  name='description'
                  // onChange={handleInput}
                  style={{ overflowY: 'scroll' }} // Add scrollbar style
                ></Form.Control>
              </Row>
            </>
          ) : (
            <Loader />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AssetView
