import React from "react";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import {
  useGetMeetingRoomPackageQuery,
  useGetPackageQuery,
  useGetSpaceDashboardQuery,
} from "../../Slices/spaceApi";
import { Tab, Tabs } from "./";
import CompanyTab from "./CompanyTab";
import UserTab from "./UserTab";
import Loader from "../Loader/Loader";
import ProgressCard from "../Dashboard/ProgressCard";
import * as Icon from "react-bootstrap-icons";
import {
  useGetPaymentMethodsQuery,
  useGetSpaceTypesQuery,
} from "../../Slices/constantApi";
export default function AddMemberList() {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data: dashboardData, isLoading } = useGetSpaceDashboardQuery(
    spaceId || currentSpaceId
  );
  const { isLoading: paymentMethodLoading, data: FetchPaymentMethod } =
    useGetPaymentMethodsQuery();
  const { data: SpaceTypes, spaceTypesLoading } = useGetSpaceTypesQuery();

  const { data: FetchPackageData, packageLoading } = useGetPackageQuery(
    spaceId || currentSpaceId
  );

  const { isLoading: loading, data: FetchMeetingPackageData } =
    useGetMeetingRoomPackageQuery(spaceId || currentSpaceId);

  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "author", label: "Author" },
    { value: "editor", label: "Editor" },
    { value: "maintainer", label: "Maintainer" },
    { value: "subscriber", label: "Subscriber" },
  ];

  const planOptions = [
    { value: "basic", label: "Basic" },
    { value: "company", label: "Company" },
    { value: "enterprise", label: "Enterprise" },
    { value: "team", label: "Team" },
  ];

  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];

  const options = [
    { value: "10", label: "10" },
    { value: "25", label: "25" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
  ];

  if (
    packageLoading ||
    isLoading ||
    spaceTypesLoading ||
    paymentMethodLoading
  ) {
    return <Loader height={6.6} />;
  }
  console.log("Payment Methods are", FetchPaymentMethod);
  return (
    <div>
      {!isLoading &&
      dashboardData &&
      (!dashboardData?.amenities > 0 ||
        !dashboardData?.meetingRooms > 0 ||
        !dashboardData?.spacePackages > 1 ||
        !dashboardData?.users > 0) ? (
        <div className="card">
          <ProgressCard dashboardData={dashboardData} currentStep={4} />
        </div>
      ) : null}

      <section className="container-fluid app-user-list with-bottom-padding">
        <Tabs
          defaultActiveKey="Account"
          id="justify-tab-example"
          className="mb-3"
          variant="underline"
          justify
        >
          <Tab
            style={{ marginRight: "5px" }}
            eventKey="Account"
            title={
              <span className="d-flex align-items-center">
                <Icon.Building /> &nbsp;Companies
              </span>
            }
          >
            <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
              SpaceTypes={SpaceTypes}
              PaymentMethods={FetchPaymentMethod}
            />
          </Tab>
          <Tab
            eventKey="Social"
            title={
              <span className="d-flex align-items-center">
                <Icon.Person /> &nbsp; Individuals
              </span>
            }
          >
            <UserTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
              SpaceTypes={SpaceTypes}
              PaymentMethods={FetchPaymentMethod}
            />
          </Tab>
        </Tabs>
        <Toaster position="top-right" reverseOrder={false} />
      </section>
    </div>
  );
}
