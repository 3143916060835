import React, { useEffect, useState } from "react";
import { ReactComponent as Preview } from "../../Assets/Preview.svg";
import {
  Button,
  Form,
  OverlayTrigger,
  Pagination,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { Eye } from "react-bootstrap-icons";

import Swal from "sweetalert2";
// import PurchaseView from './Purchases/PurchaseView'
import { useSelector } from "react-redux";
import { usePurchaseListQuery } from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import { formatDateTime } from "../../utils/formateDateTime";
import PurchaseView from "./PurchaseView";

const Purchase = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sidePage, setSidePage] = useState(true);
  const [sideName, setName] = useState("");
  const [list, setList] = useState([]);
  const [operateId, setOperateId] = useState(null);
  const [operateObject, setOperateObject] = useState(null);
  const { data, isLoading, isError, error, refetch, isFetching } =
    usePurchaseListQuery({
      spaceId: spaceId || currentSpaceId,
      page_size: pageSize,
      page: pageNumber,
      name: searchValue,
    });

  // const [deletePurchase, dltResults] = usePurchaseDeleteMutation()

  const handleClose = () => {
    setSidePage((state) => !state);
  };
  const handleSearchInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  // useEffect(() => {
  //   refetch()
  // }, [searchValue])
  const handlePageSize = (e) => {
    setPageNumber(1);
    setPageSize(e.target.value);
  };
  useEffect(() => {
    if (data) {
      setList(data?.purchases);
      //.log(data)
    }
  }, [data, isLoading, isError, error]);

  const sort = (sortIn, sortTo) => {
    // if (sortTo === 'id') {
    //   if (sortIn === 'asc') {
    //     setList(state => [...state].sort((a, b) => a.id - b.id))
    //   } else if (sortIn === 'dsc') {
    //     setList(state => [...state].sort((b, a) => a.id - b.id))
    //   }
    // } else if (sortTo === 'space') {
    //   if (sortIn === 'asc') {
    //     setList(state => [...state].sort((a, b) => a.space - b.space))
    //   } else if (sortIn === 'dsc') {
    //     setList(state => [...state].sort((b, a) => a.space - b.space))
    //   }
    // } else if (sortTo === 'name') {
    //   if (sortIn === 'asc') {
    //     setList(state =>
    //       [...state].sort((a, b) => a.name.localeCompare(b.name))
    //     )
    //   } else if (sortIn === 'dsc') {
    //     setList(state =>
    //       [...state].sort((b, a) => a.name.localeCompare(b.name))
    //     )
    //   }
    // }
  };
  // const PurchaseDelete = id => {
  //   Swal.fire({
  //     title: 'Do you want to Delete?',
  //     text: `You won't be able to revert this!`,
  //     showDenyButton: true,
  //     icon: 'warning',
  //     confirmButtonText: 'Yes! Delete',
  //     denyButtonText: `No, Cancel`
  //   }).then(result => {
  //     if (result.isConfirmed) {
  //       deletePurchase({ spaceId:spaceId|| currentSpaceId, id })

  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Your work has been saved',
  //         showConfirmButton: false,
  //         timer: 1500
  //       })
  //     }
  //   })
  // }
  // useEffect(() => {}, [dltResults])
  const handlePageNumber = (e) => {
    //.log('e', e)
    setPageNumber(e);
  };
  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };
  const totalPages = data ? Math.ceil(data?.totalCount / pageSize) : 0;
  const startIndex = (pageNumber - 1) * pageSize + 1;
  const endIndex =
    pageNumber * pageSize > data?.totalCount
      ? data?.totalCount
      : pageNumber * pageSize;

  // Generating dynamic pagination items
  let paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === pageNumber}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }
  return (
    <>
      <div className="container-fluid card  m-0 mt-3 p-0 w-100 col-12">
        <div className=" col-12 mt-4 ">
          <div className="d-flex justify-content-between ">
            <div className="col-12  col-md-4 col-lg-6">
              <Form.Control
                type="text"
                placeholder="Search..."
                className="form-control "
                value={searchValue}
                onChange={handleSearchInputChange}
              />
            </div>
            {/* <div className=' col-12 col-md-6 col-lg-4 d-flex justify-content-end'>
              <div className=' app-email-compose'>
                <Button
                  className='send-invoice-btn btn btn-primary'
                  onClick={() => {
                    setSidePage(true)
                    setName('add')
                  }}
                >
                  Add Product
                </Button>
              </div>
            </div> */}
          </div>
        </div>
        <hr></hr>
        <div className="col-12">
          <div>
            <div className="col-12 d-flex justify-content-end">
              <label
                style={{
                  display: "flex",
                  whiteSpace: "nowrap",
                  fontWeight: "normal",
                }}
              >
                <small className="mr-2 mt-2 title"> Show</small>
                <Form.Select
                  aria-label="Change Page size"
                  onChange={handlePageSize}
                  value={pageSize}
                  className=""
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="50">50</option>
                </Form.Select>
                <small className="ml-2 mt-2"> entries</small>
              </label>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          {isFetching ? (
            <Loader />
          ) : (
            <table className="table mt-4">
              <thead>
                <tr>
                  <th>
                    <div
                      className="d-flex align-items-center sort heading "
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>#</div>
                      <div
                        className=" d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort("asc", "id")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort("dsc", "id")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort heading"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Name </div>
                      <div
                        className=" d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort("asc", "name")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort("dsc", "name")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>

                  <th>
                    <div
                      className="d-flex align-items-center sort heading"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Quantity </div>
                      <div
                        className=" d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort("asc", "name")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort("dsc", "name")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      className="d-flex align-items-center sort heading"
                      style={{ justifyContent: "space-between" }}
                    >
                      <div>Date </div>
                      <div
                        className=" d-flex"
                        style={{ flexDirection: "column" }}
                      >
                        <Icon.ChevronUp
                          onClick={() => sort("asc", "name")}
                          style={{ fontSize: "10px" }}
                        />

                        <Icon.ChevronDown
                          onClick={() => sort("dsc", "name")}
                          style={{ fontSize: "10px" }}
                        />
                      </div>
                    </div>
                  </th>

                  <th>
                    <div
                      className="d-flex align-items-center sort heading"
                      style={{ justifyContent: "space-between" }}
                    >
                      Actions
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <>
                    <Loader />
                  </>
                ) : (
                  // isError ? (
                  //   <>
                  //     <tr>
                  //       {' '}
                  //       <td>{error}</td>
                  //     </tr>
                  //   </>
                  // )
                  // :
                  <>
                    {list?.length === 0 ? (
                      <>
                        <tr>
                          <td>No Data</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {list?.map((data, index) => (
                          <React.Fragment key={index}>
                            <tr key={index}>
                              <td className="table-avatar">{data?.id}</td>
                              <td className="table-avatar">
                                {data?.member?.name ||
                                  "Waiting for Member Name "}
                              </td>
                              <td className="table-avatar">
                                {data?.items?.length}
                              </td>
                              <td className="table-avatar">
                                {formatDateTime(data?.date)}
                              </td>
                              <td className="d-flex flex-nowrap">
                                <div>
                                  {" "}
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip">
                                        View Purchase
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="white"
                                      className="p-0"
                                      onClick={() => {
                                        setName("view");
                                        setOperateId(data?.id);
                                        setOperateObject(data);
                                        setSidePage(true);
                                      }}
                                    >
                                      <Preview />
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="m-0 border-bottom"></div>
        <div className=" pagination-custom flex-column flex-lg-row">
          <p className="text-muted mb-0">
            {" "}
            Showing {data?.purchases?.length} of {data?.totalCount} entries
          </p>
          {data?.totalCount > pageSize && (
            <Pagination className="mt-lg-0 mt-2">
              <Pagination.Prev
                className="previous"
                onClick={() => handlePageChange(pageNumber - 1)}
                disabled={pageNumber === 1}
              >
                Prev.
              </Pagination.Prev>
              {paginationItems}
              <Pagination.Next
                className="next"
                onClick={() => handlePageChange(pageNumber + 1)}
                disabled={pageNumber === totalPages}
              >
                Next
              </Pagination.Next>
            </Pagination>
          )}
        </div>
      </div>

      {sidePage && sideName === "update" && operateId ? (
        <>
          {/* <PurchaseAdd
            handleClose={handleClose}
            operateId={operateId}
            operateObject={operateObject}
          /> */}
        </>
      ) : sidePage && sideName === "add" ? (
        <>{/* <PurchaseAdd handleClose={handleClose} /> */}</>
      ) : sidePage && sideName === "view" ? (
        <>
          <PurchaseView
            handleClose={handleClose}
            operateId={operateId}
            operateObject={operateObject}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Purchase;
