import React, { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Nav,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";

import { Percent, Receipt } from "react-bootstrap-icons";

import * as Icon from "react-bootstrap-icons";
import OccupancyReportTable from "./OccupancyReportTable";
const OccupancyShared = () => {
  const [page, setPage] = useState(0);

  return (
    <>
      <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
        <Tabs
          defaultActiveKey="occupancyTable"
          id="justify-tab-example"
          // className='mb-3'
          variant="underline"
          justify
        >
          <Tab
            // style={{ marginRight: '5px' }}
            eventKey="occupancyTable"
            title={
              <span className="d-flex align-items-center">
                <Icon.Clipboard2Data /> &nbsp;Occupancy Report
              </span>
            }
          >
            {/* <CompanyTab
              FetchPackageData={FetchPackageData}
              FetchMeetingPackageData={FetchMeetingPackageData}
            /> */}
            <OccupancyReportTable />
          </Tab>
          {/* <Tab
            eventKey='summary'
            title={
              <span className='d-flex align-items-center'>
                <Icon.Person /> &nbsp;Payment Summary
              </span>
            }
          >
            <PaymentSummary />
          </Tab> */}
          {/* <Tab
            eventKey='incomeType'
            title={
              <span className='d-flex align-items-center'>
                <Icon.Person /> &nbsp;Payment By Income Type
              </span>
            }
          >
          
            <PaymentByIncomeType />
          </Tab> */}
        </Tabs>
      </section>
    </>
  );
};

export default OccupancyShared;
