import React, { useEffect } from "react";

import Bookings from "../Components/Booking/Bookings";
import { useSelector } from "react-redux";
import {
  useGetAllCompaniesQuery,
  useGetAllIndividualsQuery,
  useGetAllUpComingBookingQuery,
  useGetMeetingRoomQuery,
} from "../Slices/spaceApi";
import BookingList from "../Components/Booking/BookingList";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Loader from "../Components/Loader/Loader";
import BookingTableView from "../Components/Booking/BookingTableView";
import { useGlobalContext } from "../Context/GlobalContext";
export const BookingListPage = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Bookings");
  }, []);

  const { data: FetchCompanies, isLoading: loading } = useGetAllCompaniesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: FetchUsers, isLoading: userLoading } =
    useGetAllIndividualsQuery({
      spaceId: spaceId || currentSpaceId,
    });
  const { data: FetchMeetingData, isLoading } = useGetMeetingRoomQuery({
    id: spaceId || currentSpaceId,
    room_type: 1,
  });

  const { isLoading: bookingLoading, data: FetchUpComingBooking } =
    useGetAllUpComingBookingQuery({
      spaceId: spaceId || currentSpaceId,
      past: "False",
    });
  if (bookingLoading || loading || isLoading || userLoading) {
    return <Loader height={6.6} />;
  }

  return (
    <div>
      <BookingList
        companies={FetchCompanies?.users}
        meetingData={FetchMeetingData?.meetingRooms}
        FetchUpComingBooking={FetchUpComingBooking?.bookingss}
        Users={FetchUsers?.users}
      />
      {/* <Tabs
        defaultActiveKey="Account"
        id="justify-tab-example"
        className="mb-3 mt-3 ml-3 "
        variant="underline"
        justify
      >
        <Tab
          
          eventKey="Account"
          title={<span className="d-flex align-items-center">Calender</span>}
        >
          <BookingList
            companies={FetchCompanies?.users}
            meetingData={FetchMeetingData}
            FetchUpComingBooking={FetchUpComingBooking?.bookingss}
            Users={FetchUsers?.users}
          />
        </Tab>
        <Tab
          eventKey="Social"
          title={<span className="d-flex align-items-center">Table</span>}
        >
          <BookingTableView
            companies={FetchCompanies?.users}
            meetingData={FetchMeetingData}
            FetchUpComingBooking={FetchUpComingBooking}
            Users={FetchUsers?.users}
          />
        </Tab>
      </Tabs> */}
    </div>
  );
};
