import React from 'react'
import {Login ,Forgetpsw} from '../Components/LoginForgetpsw'

export const LoginPage = () => {
  return (
   <Login/>
  )
}

export const ForgetPage = () => {
  return (
    <Forgetpsw/>
  )
}