import html2pdf from 'html2pdf.js'

export const downloadPDF = tableRef => {
  // Ensure the ref is current and data is loaded
  if (tableRef.current) {
    const element = tableRef.current // The element to convert to PDF
    const options = {
      margin: 1,
      filename: 'table.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
    }
    // Use html2pdf to convert the element
    html2pdf().set(options).from(element).save()
  }
}
export const printContent = tableRef => {
  if (tableRef.current) {
    const printWindow = window.open(
      '',
      'PRINT',
      'height=650,width=900,top=100,left=150'
    )

    // Begin document
    printWindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    )

    // Include styles: iterate over document's stylesheets
    Array.from(document.styleSheets).forEach(styleSheet => {
      if (styleSheet.href) {
        // For external stylesheets
        printWindow.document.write(
          '<link rel="stylesheet" href="' +
            styleSheet.href +
            '" type="text/css" />'
        )
      } else {
        // For internal style blocks
        const rules = styleSheet.cssRules || styleSheet.rules
        const rulesText = Array.from(rules).reduce(
          (prev, curr) => prev + curr.cssText,
          ''
        )
        printWindow.document.write('<style>' + rulesText + '</style>')
      }
    })

    // Close head and open body
    printWindow.document.write('</head><body>')
    // Insert tableRef content
    printWindow.document.write(tableRef.current.outerHTML)
    // Close body and html
    printWindow.document.write('</body></html>')

    printWindow.document.close()
    printWindow.focus()

    // Print and close the print window
    printWindow.onload = () => {
      setTimeout(() => {
        // Ensure styles are loaded
        printWindow.print()
        printWindow.close()
      }, 500)
    }
  }
}
export const copyTableContent = tableRef => {
  if (tableRef.current) {
    const selection = window.getSelection()
    const range = document.createRange()

    // Clear any existing selections
    selection.removeAllRanges()

    // Select the table content
    range.selectNodeContents(tableRef.current)
    selection.addRange(range)

    // Use the Clipboard API to copy the selected content
    try {
      const successful = document.execCommand('copy')
      const msg = successful ? 'successful' : 'unsuccessful'
      console.log('Copying text command was ' + msg)
    } catch (err) {
      console.error('Unable to copy', err)
    }

    // Clear the selection
    selection.removeAllRanges()
  }
}
export function tableToCSV (tableRef) {
  let csvContent = ''

  // Check if tableRef actually refers to a table
  if (tableRef.current && tableRef.current.nodeName === 'TABLE') {
    const rows = tableRef.current.querySelectorAll('tr')

    rows.forEach((row, rowIndex) => {
      let rowData = []
      const cells = row.querySelectorAll('th, td') // Include both th and td

      cells.forEach(cell => {
        let cellText = cell.innerText.replace(/"/g, '""') // Escape double quotes
        rowData.push(`"${cellText}"`) // Enclose cell text in double quotes
      })

      csvContent += rowData.join(',') + '\r\n' // Join cells with comma, rows with newline
    })

    // Trigger download
    triggerCSVDownload(csvContent)
  } else {
    console.error('The provided reference does not refer to a table.')
  }
}
export function triggerCSVDownload (csvContent) {
  // Create a Blob with CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  // Create a link and trigger the download
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.setAttribute('href', url)
  link.setAttribute('download', 'table_data.csv')
  document.body.appendChild(link) // Required for Firefox

  link.click()

  document.body.removeChild(link) // Clean up
  URL.revokeObjectURL(url) // Free up resources
}
