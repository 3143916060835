import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";
import * as Icon from "react-bootstrap-icons";
import { formatDate } from "../../../utils/displayDateTime";
import Logo1 from "../../../Assets/dummyImages/Logo1.png";

import {
  useAllSpacesQuery,
  useGetInvoiceDetailsReportQuery,
  useOwnerLocationQuery,
  useSpaceGetQuery,
  useVisitorReportQuery,
} from "../../../Slices/spaceApi";

import TabularReport from "../TabularReports";
import { useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
import { InvoiceToDetails, LogoDetails, SalesPerson } from "../../AddInvoices";
import { useGlobalContext } from "../../../Context/GlobalContext";
const InvoiceView = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const {
    setToggleSidebar,
    InvoicesPermissions,
    setSettingsKey,
    currency,
    rentalFee,

    serviceCharges,

    isOverAllTax,
  } = useGlobalContext();
  const tableRef = useRef(null);
  // const { mySpace, ownerLocation, allSpaces } = useContext(AppContext);

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [page_size, setPage_size] = useState(10);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const { data, isLoading, refetch, isFetching } =
    useGetInvoiceDetailsReportQuery({
      spaceId: spaceId || currentSpaceId,
      start,
      end,
      page_size,
      page: page,
      location: location,
    });
  useEffect(() => {
    if ((data, !isLoading)) {
      setList(data?.invoices);
    }
  }, [data]);
  console.log("list is", list);
  // useEffect(() => {
  //   refetch()
  // }, [selectedValue])
  const body = isFetching ? (
    <Loader />
  ) : (
    <div ref={tableRef} style={{ pageBreakBefore: "always" }}>
      {!isLoading && data && list.length > 0 ? (
        list?.map((data, index) => (
          <div
            className="invoice"
            key={index}
            style={{ pageBreakBefore: "always" }}
          >
            <div className="row">
              <div
                className="col-xl-9 viewInvoice"
                id="invoice-pdf"

                // ref={(el) => (tableRef.current[index] = el)}
              >
                <div className="card d-flex">
                  <div className="card-body invoice-padding pb-0">
                    <div
                      className="d-flex justify-content-between flex-lg-row flex-sm-row flex-column invoice-spacing invoice-header mt-0 "
                      style={{ alignItems: "start" }}
                    >
                      {/* <LogoDetails {...data} {...data?.sapce} />
                       */}

                      <>
                        <div className="mb-5 mb-lg-0 logo-details">
                          <div>
                            <span className="logo-img me-2">
                              <img
                                id="logo-img"
                                src={data?.space?.imageUrls[0] || Logo1}
                                // src="https://source.unsplash.com/random"
                                style={{
                                  width: "50px",
                                  borderRadius: "50%",
                                  height: "50px",
                                }}
                                onError={(e) => {
                                  console.error("Error loading image:", e);
                                  e.target.src = Logo1;
                                }}
                                // onLoad={handleImageLoad}
                                alt="logo-img"
                              />
                            </span>

                            <span className="fw-bold fs-4">
                              {data?.space?.name}
                            </span>
                          </div>
                          <p className="card-text my-1">
                            {data?.space?.address}
                          </p>
                          <p className="card-text m-0">{data?.space?.number}</p>
                        </div>
                      </>
                      <div
                        className="invoice-number-date mt-md-0 align-items-lg-end"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // alignItems: "end",
                          margin: "0px",
                        }}
                      >
                        <h4 className="fw-medium mb-2">
                          INVOICE #{data?.invoiceId}
                        </h4>

                        <div className="d-flex align-items-center mb-1 voiceFix">
                          <p className="mb-0 invoice-date-title">
                            {data?.paid ? "Paid Date" : "Published Date:"}
                            &nbsp;&nbsp;
                          </p>
                          <p className="mb-0 fw-bold text-end">
                            {data?.paid
                              ? new Date(data?.paid_date).toLocaleDateString()
                              : new Date(
                                  data?.issued_date
                                ).toLocaleDateString()}
                          </p>
                        </div>
                        {data?.paid ? (
                          ""
                        ) : (
                          <div className="d-flex align-items-center mb-1 voiceFix">
                            <p className="mb-0 invoice-date-title">
                              Due Date:&nbsp;&nbsp;
                            </p>
                            <p className="mb-0 fw-bold text-end">
                              {new Date(data?.due_date).toLocaleDateString()}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <hr className="invoice-spacing" />

                  <div className="card-body invoice-padding pt-0">
                    <div className="row row-bill-to  invoice-spacing">
                      <div className="col-md-6">
                        <InvoiceToDetails FetchSpaceInvoice={data} />
                      </div>
                      <div className="col-md-6 bill-to d-flex justify-content-md-end mt-md-0 mt-4">
                        <div>
                          <h6 className="invoice-to-title">
                            Pay Bill at Company Account:
                          </h6>
                          <table>
                            <tbody>
                              <tr>
                                <td className="pe-3">Total Due:</td>
                                <td>
                                  <strong>{data?.total?.toFixed(2)}</strong>{" "}
                                  <small className="fw-bold">
                                    {currency && currency}
                                  </small>
                                </td>
                              </tr>
                              {data?.space?.city && (
                                <tr>
                                  <td className="pe-3">City:</td>
                                  <td>
                                    {data?.space?.city ? data?.space?.city : ""}
                                  </td>
                                </tr>
                              )}

                              <tr>
                                <td className="pe-3">Bank Name:</td>
                                <td>
                                  {data?.space?.bankName ? (
                                    data?.space?.bankName
                                  ) : (
                                    <div
                                      style={{
                                        textDecoration: "underline",
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      //   onClick={addBankDetails}
                                    >
                                      No Bank Details Provided
                                    </div>
                                  )}
                                </td>
                              </tr>
                              {data?.space?.branchName && (
                                <tr>
                                  <td className="pe-3">Branch Name:</td>
                                  <td>
                                    {data?.space?.branchName
                                      ? data?.space?.branchName
                                      : ""}
                                  </td>
                                </tr>
                              )}
                              {data?.space?.country && (
                                <tr>
                                  <td className="pe-3">Country:</td>
                                  <td>
                                    {data?.space?.country
                                      ? data?.space?.country
                                      : ""}
                                  </td>
                                </tr>
                              )}
                              {data?.space?.iban && (
                                <tr>
                                  <td className="pe-3">IBAN:</td>
                                  <td>
                                    {data?.space?.iban ? data?.space?.iban : ""}
                                  </td>
                                </tr>
                              )}
                              {data?.space?.swiftCode && (
                                <tr>
                                  <td className="pe-3">SWIFT Code:</td>
                                  <td>
                                    {data?.space?.swiftCode
                                      ? data?.space?.swiftCode
                                      : ""}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="">
                            {data?.type == 1
                              ? "Packages"
                              : data?.type == 2
                              ? "Services"
                              : data?.type == 3
                              ? "Security Deposit"
                              : data?.type == 4
                              ? "Public Meeting Room"
                              : data?.type == 5
                              ? "Public Hot Desk"
                              : "Memeber Booking"}
                          </th>

                          {data?.type == 1 ? (
                            <>
                              {" "}
                              <th className="">Service Charges</th>
                              <th className="">Rental Fee</th>
                            </>
                          ) : data?.type == 2 ? (
                            <>
                              {" "}
                              <th className="">Service Charges</th>
                              <th className="">Rental Fee</th>
                            </>
                          ) : (
                            <th className="">Amount</th>
                          )}

                          <th className="">Quantity</th>
                          {data?.type == 6 || data?.type == 4 ? (
                            <th className="">Tax Amount</th>
                          ) : (
                            <th className="">Tax Rate</th>
                          )}

                          <th className="">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.type == 2 ? (
                          <tr>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                Hot Desk
                              </p>
                            </td>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {data?.subtotal}
                              </p>
                            </td>
                            <td className="">
                              <span className="">1</span>
                            </td>
                            <td className="">
                              <span className="">{data?.subtotal}</span>
                            </td>
                          </tr>
                        ) : data?.type == 3 ? (
                          <tr>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                SecurityDeposit
                              </p>
                            </td>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {data?.subtotal}
                              </p>
                            </td>
                            <td className="">
                              <span className="">1</span>
                            </td>
                            <td className="">
                              <span className="">N/A</span>
                            </td>
                            <td className="">
                              <span className="">{data?.subtotal}</span>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        {data?.type == 4 && (
                          <tr>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                Public Meeting Booking
                              </p>
                            </td>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {data?.subtotal}
                              </p>
                            </td>
                            <td className="">
                              <span className="">1</span>
                            </td>
                            <td className="">
                              <span className="">{data?.totalTax}</span>
                            </td>
                            <td className="">
                              <span className="">
                                {" "}
                                {data?.subtotal + data?.totalTax}
                              </span>
                            </td>
                          </tr>
                        )}
                        {data?.type == 5 && (
                          <tr>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                Hot Desk
                              </p>
                            </td>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {data?.subtotal}
                              </p>
                            </td>
                            <td className="">
                              <span className="">1</span>
                            </td>
                            <td className="">
                              <span className="">{data?.totalTax}</span>
                            </td>
                            <td className="">
                              <span className="">
                                {data?.subtotal + data?.totalTax}
                              </span>
                            </td>
                          </tr>
                        )}

                        {data?.type == 6 && (
                          <tr>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                Member Booking
                              </p>
                            </td>
                            <td className="">
                              <p className="card-text font-weight-bold mb-1">
                                {data?.subtotal}
                              </p>
                            </td>
                            <td className="">
                              <span className="">1</span>
                            </td>
                            <td className="">
                              <span className="">{data?.totalTax}</span>
                            </td>
                            <td className="">
                              <span className="">{data?.total}</span>
                            </td>
                          </tr>
                        )}

                        {data?.assignedPackage?.meeting_packages?.map(
                          (item, idx) => {
                            return (
                              <tr key={idx}>
                                <td className="">
                                  <p className="card-text font-weight-bold mb-1">
                                    {item.package.name}
                                  </p>
                                </td>
                                <td className="">
                                  <p className="card-text font-weight-bold mb-1">
                                    {item.package.serviceCharges}
                                  </p>
                                </td>
                                <td className="">
                                  <p className="card-text font-weight-bold mb-1">
                                    {item.package.rentalFee}
                                  </p>
                                </td>
                                <td className="">
                                  <span className="">{item.quantity || 1}</span>
                                </td>

                                {item?.package?.inclusiveTax ? (
                                  <td>N/A</td>
                                ) : (
                                  <td className="">
                                    {item?.package?.taxRate && (
                                      <span className="">
                                        {item?.package?.taxRate?.name}
                                        <br />
                                      </span>
                                    )}

                                    {item?.package?.taxRate ? (
                                      item?.package?.taxRate?.setOveralTax ? (
                                        <small>
                                          Overall Tax:{" "}
                                          {
                                            item?.package?.taxRate
                                              ?.serviceCharges
                                          }
                                        </small>
                                      ) : (
                                        <>
                                          <small className="text-nowrap">
                                            Service Charges:{" "}
                                            {
                                              item?.package?.taxRate
                                                ?.serviceCharges
                                            }{" "}
                                            %
                                          </small>
                                          <br />
                                          <small className="text-nowrap">
                                            Rental Fee:{" "}
                                            {item?.package?.taxRate?.rentalFee}{" "}
                                            %
                                          </small>
                                        </>
                                      )
                                    ) : isOverAllTax ? (
                                      <small>
                                        Overall Tax: {serviceCharges}
                                      </small>
                                    ) : (
                                      <>
                                        <small className="text-nowrap">
                                          Service Charges: {serviceCharges} %
                                        </small>
                                        <br />
                                        <small className="text-nowrap">
                                          Rental Fee: {rentalFee} %
                                        </small>
                                      </>
                                    )}
                                  </td>
                                )}
                                {item?.package?.inclusiveTax ? (
                                  <td>
                                    {(item.quantity || 1) *
                                      (item.package.serviceCharges +
                                        item.package.rentalFee)}
                                  </td>
                                ) : (
                                  <td className="">
                                    <span className="">
                                      {item?.package?.taxRate
                                        ? item?.package?.taxRate?.setOveralTax
                                          ? (item.quantity || 1) *
                                            (item.package.serviceCharges +
                                              (item.package.serviceCharges *
                                                item?.package?.taxRate
                                                  ?.serviceCharges) /
                                                100 +
                                              item.package.rentalFee +
                                              (item.package.rentalFee *
                                                item?.package?.taxRate
                                                  ?.serviceCharges) /
                                                100)
                                          : (item.quantity || 1) *
                                            (item.package.serviceCharges +
                                              (item.package.serviceCharges *
                                                item?.package?.taxRate
                                                  ?.serviceCharges) /
                                                100 +
                                              item.package.rentalFee +
                                              (item.package.rentalFee *
                                                item?.package?.taxRate
                                                  ?.rentalFee) /
                                                100)
                                        : isOverAllTax
                                        ? (item.quantity || 1) *
                                          (item.package.serviceCharges +
                                            (item.package.serviceCharges *
                                              serviceCharges) /
                                              100 +
                                            item.package.rentalFee +
                                            (item.package.rentalFee *
                                              serviceCharges) /
                                              100)
                                        : (item.quantity || 1) *
                                          (item.package.serviceCharges +
                                            (item.package.serviceCharges *
                                              serviceCharges) /
                                              100 +
                                            item.package.rentalFee +
                                            (item.package.rentalFee *
                                              rentalFee) /
                                              100)}{" "}
                                      {currency}
                                    </span>
                                  </td>
                                )}
                              </tr>
                            );
                          }
                        )}

                        {data?.assignedPackage?.packages?.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.package.name}
                                </p>
                              </td>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.package.serviceCharges}
                                </p>
                              </td>
                              <td className="">
                                <p className="card-text font-weight-bold mb-1">
                                  {item.package.rentalFee}
                                </p>
                              </td>
                              <td className="">
                                <span className="">{item.quantity || 1}</span>
                              </td>

                              {item?.package?.inclusiveTax ? (
                                <td>N/A</td>
                              ) : (
                                <td className="">
                                  <span className="">
                                    {item?.package?.taxRate
                                      ? item?.package?.taxRate?.name
                                      : ""}
                                    <br />
                                    {item?.package?.taxRate &&
                                    item?.package?.taxRate?.setOveralTax ? (
                                      <small>
                                        Overall Tax:{" "}
                                        {item?.package?.taxRate?.serviceCharges}
                                      </small>
                                    ) : (
                                      <>
                                        <small className="text-nowrap">
                                          Service Charges:{" "}
                                          {
                                            item?.package?.taxRate
                                              ?.serviceCharges
                                          }{" "}
                                          %
                                        </small>
                                        <br />
                                        <small className="text-nowrap">
                                          Rental Fee:{" "}
                                          {item?.package?.taxRate?.rentalFee} %
                                        </small>
                                      </>
                                    )}
                                  </span>
                                </td>
                              )}

                              {item?.package?.inclusiveTax ? (
                                <td>
                                  {(item.quantity || 1) *
                                    (item.package.serviceCharges +
                                      item.package.rentalFee)}
                                </td>
                              ) : (
                                <td className="">
                                  <span className="">
                                    {item?.package?.taxRate
                                      ? item?.package?.taxRate?.setOveralTax
                                        ? (item.quantity || 1) *
                                          (item.package.serviceCharges +
                                            (item.package.serviceCharges *
                                              item?.package?.taxRate
                                                ?.serviceCharges) /
                                              100 +
                                            item.package.rentalFee +
                                            (item.package.rentalFee *
                                              item?.package?.taxRate
                                                ?.serviceCharges) /
                                              100)
                                        : (item.quantity || 1) *
                                          (item.package.serviceCharges +
                                            (item.package.serviceCharges *
                                              item?.package?.taxRate
                                                ?.serviceCharges) /
                                              100 +
                                            item.package.rentalFee +
                                            (item.package.rentalFee *
                                              item?.package?.taxRate
                                                ?.rentalFee) /
                                              100)
                                      : isOverAllTax
                                      ? (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee *
                                            serviceCharges) /
                                            100)
                                      : (item.quantity || 1) *
                                        (item.package.serviceCharges +
                                          (item.package.serviceCharges *
                                            serviceCharges) /
                                            100 +
                                          item.package.rentalFee +
                                          (item.package.rentalFee * rentalFee) /
                                            100)}{" "}
                                    {currency}
                                  </span>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  {data?.assignedPackage?.purchase && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="">Product</th>

                            <th className="">Price</th>

                            <th className="">Quantity</th>
                            <th className="">Tax Rate</th>

                            <th className="">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.assignedPackage?.purchase?.items?.map(
                            (item, idx) => {
                              return (
                                <tr key={idx}>
                                  <td className="">
                                    <p className="card-text font-weight-bold mb-1">
                                      {item.product.name}
                                    </p>
                                  </td>
                                  <td className="">
                                    <p className="card-text font-weight-bold mb-1">
                                      {item.product.price}
                                    </p>
                                  </td>

                                  <td className="">
                                    <span className="">
                                      {item.quantity || 1}
                                    </span>
                                  </td>

                                  {item?.product?.inclusiveTax ? (
                                    <td>N/A</td>
                                  ) : (
                                    <td className="">
                                      {item?.product?.taxRateObj && (
                                        <span className="">
                                          {item?.product?.taxRateObj?.name}
                                          <br />
                                        </span>
                                      )}

                                      {item?.product?.taxRateObj ? (
                                        item?.product?.taxRateObj
                                          ?.setOveralTax ? (
                                          <small>
                                            Overall Tax:{" "}
                                            {
                                              item?.product?.taxRateObj
                                                ?.serviceCharges
                                            }
                                          </small>
                                        ) : (
                                          <>
                                            <small className="text-nowrap">
                                              Service Charges:{" "}
                                              {
                                                item?.product?.taxRateObj
                                                  ?.serviceCharges
                                              }{" "}
                                              %
                                            </small>
                                            <br />
                                            <small className="text-nowrap">
                                              Rental Fee:{" "}
                                              {
                                                item?.product?.taxRateObj
                                                  ?.rentalFee
                                              }{" "}
                                              %
                                            </small>
                                          </>
                                        )
                                      ) : isOverAllTax ? (
                                        <small>
                                          Overall Tax: {serviceCharges}
                                        </small>
                                      ) : (
                                        <>
                                          <small className="text-nowrap">
                                            Service Charges: {serviceCharges} %
                                          </small>
                                          <br />
                                          <small className="text-nowrap">
                                            Rental Fee: {rentalFee} %
                                          </small>
                                        </>
                                      )}
                                    </td>
                                  )}

                                  {item?.product?.inclusiveTax ? (
                                    <td>
                                      (item.quantity || 1) *
                                      (item.product.price)
                                    </td>
                                  ) : (
                                    <td className="">
                                      <span className="">
                                        {item?.product?.taxRateObj
                                          ? item?.product?.taxRateObj
                                              ?.setOveralTax
                                            ? (item.quantity || 1) *
                                              (item.product.price +
                                                (item.product.price *
                                                  item?.product?.taxRateObj
                                                    ?.serviceCharges) /
                                                  100)
                                            : (item.quantity || 1) *
                                                (item.product.price +
                                                  (item.product.price *
                                                    item?.product?.taxRateObj
                                                      ?.serviceCharges) /
                                                    100) +
                                              (item.product.price *
                                                item?.product?.taxRateObj
                                                  ?.rentalFee) /
                                                100
                                          : isOverAllTax
                                          ? (item.quantity || 1) *
                                            (item.product.price +
                                              (item.product.price *
                                                serviceCharges) /
                                                100)
                                          : (item.quantity || 1) *
                                            (item.product.price +
                                              (item.product.price *
                                                serviceCharges) /
                                                100 +
                                              (item.product.price * rentalFee) /
                                                100)}{" "}
                                        {currency}
                                      </span>
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}

                  <hr className="invoice-spacing mt-0" />

                  <div className="card-body invoice-padding">
                    <div className="row invoice-sales-total-wrapper ">
                      <div className="col-md-7">
                        <SalesPerson name={data?.space?.name} />
                        {data?.discountCode && (
                          <div className="d-flex align-items-center mb-1 sales-person">
                            <label
                              htmlFor="salesperson"
                              className="form-label mb-0 me-2"
                            >
                              Discount Code:
                            </label>
                            <small style={{ fontWeight: "bold" }}>
                              {data?.discountCode?.code}
                            </small>
                          </div>
                        )}
                        {data?.payments && (
                          <div>
                            <h5>Payments</h5>
                            {data?.payments?.map((payment, index) => (
                              <div key={payment.id} className="d-flex">
                                <h6 className="">Payment {index + 1}:</h6>{" "}
                                &nbsp;
                                {payment.amount}
                                {currency}{" "}
                                {new Date(payment.date).toLocaleString()}
                                <br />
                              </div>
                            ))}
                          </div>
                        )}

                        {data?.refunds && (
                          <div>
                            <h5>Refunds</h5>
                            {data?.refunds?.map((payment, index) => (
                              <div key={payment.id} className="d-flex">
                                <h6 className="">Refund {index + 1}:</h6> &nbsp;
                                {payment.amount}
                                {currency}{" "}
                                {new Date(payment.date).toLocaleString()}
                                <br />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="total col-md-4 offset-md-1 d-flex justify-content-end  mt-5 mt-md-0">
                        <>
                          <div className="invoice-total-wrapper">
                            <div className="invoice-total-item">
                              <p className="invoice-total-title">Subtotal:</p>
                              <p className="invoice-total-amount">
                                {isNaN(data?.subtotal) ? 0 : data?.subtotal}{" "}
                                <small className="fw-bold">{currency}</small>
                              </p>
                            </div>

                            <div className="invoice-total-item">
                              <p className="invoice-total-title">Tax Amount:</p>
                              <p className="invoice-total-amount">
                                {data?.totalTax?.toFixed(0) || 0}{" "}
                                <small className="fw-bold">{currency}</small>
                              </p>
                            </div>
                            <hr />
                            <div className="invoice-total-item p-1">
                              <p className="invoice-total-amount ml-auto">
                                {data?.subtotal + data?.totalTax}{" "}
                                <small className="fw-bold">{currency}</small>
                              </p>
                            </div>

                            <div className="invoice-total-item">
                              <p className="invoice-total-title">Discount:</p>
                              <p className="invoice-total-amount">
                                {data?.discount_percentage > 100
                                  ? 100
                                  : data?.discount_percentage?.toFixed(2) ||
                                    0}{" "}
                                %
                              </p>
                            </div>
                            {data?.discountCode && (
                              <div className="invoice-total-item">
                                <p className="invoice-total-title">
                                  Discount Code:
                                </p>
                                <p className="invoice-total-amount">
                                  {data?.discountCode?.amount}{" "}
                                  {data?.discountCode?.fixedAmount ? (
                                    <>{currency}</>
                                  ) : (
                                    "%"
                                  )}
                                </p>
                              </div>
                            )}

                            <div className="invoice-total-item">
                              <p className="invoice-total-title">Adjustment:</p>
                              <p className="invoice-total-amount">
                                {data?.adjustment || 0}
                              </p>
                            </div>
                            <div className="invoice-total-item"></div>
                            <hr className="my-2" />
                            <div className="invoice-total-item p-1">
                              <p className="invoice-total-title">Total:</p>
                              <p className="invoice-total-amount">
                                {isNaN(
                                  data?.total.toFixed(2) < 0
                                    ? "N/A"
                                    : data?.total.toFixed(2)
                                )
                                  ? 0
                                  : data?.total.toFixed(2) < 0
                                  ? "N/A"
                                  : data?.total.toFixed(2)}{" "}
                                <small className="fw-bold">{currency}</small>
                              </p>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>

                  {data?.remarks && (
                    <>
                      <hr className="invoice-spacing mt-0" />
                      <div className="card-body invoice-padding pt-0">
                        <div className="row">
                          <div className="col-12">
                            <span className="font-weight-bold">Note: </span>
                            <span>
                              {data?.remarks ||
                                "You haven't added note while creating invoice"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : list.length === 0 ? (
        <>
          <tr>
            <td>No Data</td>
          </tr>
        </>
      ) : (
        <tr>
          <td>Data is Loading</td>
        </tr>
      )}
    </div>
  );
  return (
    <>
      {data && !isLoading && list ? (
        <TabularReport
          key={"bookingByWeekDays"}
          Child={() => body}
          data={data}
          isLoading={isLoading}
          mySpace={mySpace}
          allSpaces={allSpaces}
          tableRef={tableRef}
          list={list}
          setList={setList}
          ownerLocation={ownerLocation}
          page={page}
          setPage={setPage}
          page_size={page_size}
          setPage_size={setPage_size}
          location={location}
          setLocation={setLocation}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          refetch={refetch}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          setOptions={setOptions}
        />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default InvoiceView;
