import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../Loader/Loader";
import TabularReport from "../TabularReports";
import {
  useAllSpacesQuery,
  useOwnerLocationQuery,
  useSpaceGetQuery,
  usePaymentSummaryReportQuery,
  useListPaymentMethodsQuery,
} from "../../../Slices/spaceApi";
import { formateDate } from "../../../utils/formateDate";
import { useSelector } from "react-redux";
const PaymentSummary = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { data: paymentMethods, isLoading: paymentsIsLoading } =
    useListPaymentMethodsQuery();
  const tableRef = useRef(null);
  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId });
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const [page_size, setPage_size] = useState(10);
  const [location, setLocation] = useState(mySpace?.spaceId || null);
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const { data, isLoading, refetch, isFetching } = usePaymentSummaryReportQuery(
    {
      spaceId: spaceId || currentSpaceId,
      start,
      end,
      page_size,
      page: page,
      location: location,
    }
  );
  useEffect(() => {
    if ((data, !isLoading)) {
      setList(data.payments);
    }
  }, [data]);
  useEffect(() => {}, [data, isLoading, list]);

  // useEffect(() => {
  //   refetch()
  // }, [selectedValue])
  const body = isFetching ? (
    <Loader />
  ) : (
    <div className=" table-responsive">
      <table ref={tableRef} className="table   dataTable">
        <thead>
          <tr>
            <th>#</th>
            <th>Amount</th>
            <th>Transaction Type</th>
            <th>Date</th>

            <th>Payment Method</th>
          </tr>
        </thead>
        <tbody className="table-border-bottom-0">
          {!isLoading && data && list.length > 0 ? (
            list.map((data, index) => (
              <tr key={index}>
                <td>{data.id}</td>
                <td> {data.amount.toFixed(2)}</td>
                {/* Formatting amount as currency */}
                <td>
                  {data.manual ? (
                    <>
                      <div className=" badge bg-label-success">Manual</div>
                    </>
                  ) : (
                    <>
                      <div className="badge bg-label-info">Automated</div>
                    </>
                  )}
                </td>
                <td>{data?.date ? formateDate(data.date) : "----"}</td>
                {/* data.paymentMethod */}
                <td>
                  {paymentMethods?.find(
                    (pay) => pay?.id === data?.paymentMethod
                  )?.name || "-"}
                </td>
              </tr>
            ))
          ) : list.length === 0 ? (
            <tr>
              <td>No Data</td>
            </tr>
          ) : (
            <>
              <tr>
                <td>Loading</td>
              </tr>
            </>
          )}
        </tbody>
      </table>{" "}
    </div>
  );
  return (
    <>
      {data && !isLoading && list ? (
        <TabularReport
          key={"bookingByWeekDays"}
          Child={() => body}
          data={data}
          isLoading={isLoading}
          mySpace={mySpace}
          allSpaces={allSpaces}
          tableRef={tableRef}
          list={list}
          setList={setList}
          ownerLocation={ownerLocation}
          page={page}
          setPage={setPage}
          page_size={page_size}
          setPage_size={setPage_size}
          location={location}
          setLocation={setLocation}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          refetch={refetch}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          setOptions={setOptions}
        />
      ) : (
        <Loader></Loader>
      )}
    </>
  );
};

export default PaymentSummary;
