import { createSlice } from "@reduxjs/toolkit";
import GetCookie from "../utils/getCookie";
import SetCookie from "../utils/setCookie";

const encodedIds = GetCookie("ids"); // Your URL-encoded string
const decodedIds = decodeURIComponent(encodedIds); // Decoding the URL-encoded string
const idsArray = decodedIds.split(",").map(Number);
const Initialization = {
  currentSpaceId: GetCookie("spaces"),
  currentCredentials: GetCookie("creds"),
  spaceId: null,
  spaceCrede: null,
  spaces:
    GetCookie("ids")?.length === 1 ? [GetCookie("ids")].map(Number) : idsArray,
  // locationId: "",
  // currentLocationId: GetCookie("locationId"),
};
const spaceReducer = createSlice({
  name: "space",
  initialState: Initialization,
  reducers: {
    SPACEID(state, actions) {
      SetCookie("spaces", actions.payload);
      // SetSessionCookie("spaces", actions.payload)
      state.spaceId = actions.payload;
    },
    SPACECREDS(state, actions) {
      SetCookie("creds", actions.payload);
      // SetSessionCookie("creds", actions.payload)
      state.spaceCrede = actions.payload;
    },
    SPACEIDS(state, actions) {
      SetCookie("ids", actions.payload);
      // SetSessionCookie("creds", actions.payload)
      state.spaces = actions.payload;
    },
    // LOCATIONID(state, actions) {
    //   // SetSessionCookie("creds", actions.payload)
    //   state.locationId = actions.payload;
    // },
  },
});

export const { SPACEID, SPACECREDS, SPACEIDS } = spaceReducer.actions;
export default spaceReducer.reducer;
