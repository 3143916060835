import React, { lazy, Suspense, useEffect } from "react";
import { LoginPage, ForgetPage } from "./Pages/LoginForgetpswPage";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import axios from "axios";
import { useGlobalAuthContext } from "./AuthContext/AuthContext";
import DashboardPage from "./Pages/DashboardPage";
import { SpacePackage } from "./Pages/PackagePage";
import {
  AddMembers,
  EditMembers,
  ListMembers,
  ViewMembers,
} from "./Pages/InvoicePage";
import {
  // AddMemberEdits,
  AddMemberLists,
  // AddMemberViews,
} from "./Pages/MemberPage";

import { SettingPage } from "./Pages/SettingPage";
import { BookingListPage } from "./Pages/BookingPage";
import { ViewFloorPlans, FloorPlans, UpdateFloor } from "./Pages/FloorPlans";
import Loader from "./Components/Loader/Loader";
import GetCookie from "./utils/getCookie";
import { AddQuotations, QuotationLists } from "./Pages/QuotationPage";
import QuotationUpdate from "./Components/Quotations/QuotationUpdate";
import QuotationView from "./Components/Quotations/QuotationView";
import { VisitorsList, VisitorsRegistration } from "./Pages/VisitorsPage";
import AddVisitor from "./Components/Visitors/AddVisitor";
import AddVisit from "./Components/Visitors/AddVisit";
import VisitorView from "./Components/Visitors/VisitorView";
import VisitUpdate from "./Components/Visitors/VisitUpdate";
// import CompanyAndUsers from "./Components/AddMember/CompanyAndUsers";
import FloorUpdate from "./Components/FloorPlans/FloorUpdate";
import NewUserCheck from "./Components/LoginForgetpsw/NewUserCheck";
import PeoplesManagement from "./Components/Management/PeoplesManagement";
import UserPasswordSetup from "./Components/LoginForgetpsw/UserPasswordSetup";
import WorkPlaceManagement from "./Components/Management/WorkPlaceManagement";
import CryptoJS from "crypto-js";
import TeamView from "./Components/Management/TeamView";
import AddRole from "./Components/Management/AddRole";
import UpdateRole from "./Components/Management/UpdateRole";
import ConfirmDeactivation from "./Components/LoginForgetpsw/ConfirmDeactivation";
import DetailedCompanyPage from "./Components/AddMember/DetailedCompanyPage";
import DetailedIndividualPage from "./Components/AddMember/DetailedIndividualPage";
import AddInvoiceForMember from "./Components/AddMember/AddInvoiceForMember";
import { useGlobalContext } from "./Context/GlobalContext";
import PermissionPage from "./Components/MainShared/PermissionPage";
import Page404 from "./Components/MainShared/Page404";
import { AccountSetting } from "./Components/AddSettings";
import TourList from "./Pages/TourList";
import AddTour from "./Pages/AddTour";
import TourUpdate from "./Pages/TourUpdate";
import Partners from "./Pages/Partners";
import OfferList from "./Components/Offers/OfferList";
import TicketPage from "./Pages/TicketPage";
import ViewTicketPage from "./Pages/ViewTicketPage";
import DashboardAppPage from "./Pages/DashboardAppPage";
import EmailComposer from "./Components/Announcements/EmailComposer";
import VisitorsTable from "./Components/Visitors/VisitorsTable";
import AnnouncementTable from "./Components/Announcements/AnnouncementTable";
import SignUpPage from "./Components/LoginForgetpsw/SignUpPage";
import DetailedCompanyEmployee from "./Components/AddMember/DetailedCompanyEmployee";
import QuestionsList from "./Components/TourQuestions/QuestionsList";
import ViewTour from "./Pages/ViewTour";
import Vendor from "./Components/Vendors/VendorShared";
// import VendorCategory from "./Components/Vendors/VendorCategory";
import Asset from "./Components/Asset/AssetShared";
import Expense from "./Components/Expense/ExpenseShared";
import Account from "./Components/Accounts/Account";
import TaxRates from "./Components/Tax Rates/TaxRates";
import Discount from "./Components/Discounts/Discount";

import Chart from "./Components/Chart of Accounts/Chart";
import Product from "./Components/Products/Product";
import Reports from "./Components/Reports/Reports";
import Visitor from "./Components/Reports/Visitors/Visitor";
import RevenueShared from "./Components/Reports/Revenue/RevenueShared";
import DiscountShared from "./Components/Reports/Discounts/DiscountShared";
import BookingShared from "./Components/Reports/Bookings/BookingShared";
import ProductReport from "./Components/Reports/Products/ProductReport";
import FinanceShared from "./Components/Reports/Finance/FinanceShared";
import DeskShared from "./Components/Reports/Desks/DeskShared";
import InvoiceShared from "./Components/Reports/Invoice/InvoiceShared";

import KPIBookingShared from "./Components/Reports/BookingKPI/KPIBookingShared";
import ProductShared from "./Components/Products/ProductShared";
import HotDeskBookingList from "./Components/Booking/HotDeskBookingList";
import FlexDeskTable from "./Components/Booking/FlexDeskTable";
import QuickBookCallBack from "./Components/AddSettings/QuickBookCallBack";
import OccupancyShared from "./Components/Reports/Occupancy/OccupancyShared";
import KisiIntegrationPage from "./Components/Integrations/KisiIntegrationPage";
import CheckinShared from "./Components/Reports/Checkin/CheckinShared";
const SharedMainPage = lazy(() => import("./Pages/SharedMainPage"));

const App = () => {
  // useEffect(() => {
  //   if (performance.navigation.type === 1) {
  //     window.location.href = "/";
  //   }
  // }, []);
  const {
    InvoicesPermissions,
    rolesPermissions,
    resourcesPermissions,
    floorsPermissions,
    locationsPermissions,
    teamsPermissions,
    employessPermissions,
    visitsPermissions,
    membersPermissions,
    packagesPermissions,
    quotationsPermission,
    bookingsPermissions,
    settingsPermissions,
    setIsLoggedIn,
    partnerAndOffersPermissions,
    toursPermissions,
    ticketsPermissions,
    announcementPermissions,
    communityPermissions,
  } = useGlobalContext();

  function encryptText(text, secretKey) {
    const key = CryptoJS.enc.Hex.parse(secretKey);
    const iv = CryptoJS.lib.WordArray.random(16); // Random IV
    const options = {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    };
    const ciphertext = CryptoJS.AES.encrypt(text, key, options);
    const encryptedText = iv
      .concat(ciphertext.ciphertext)
      .toString(CryptoJS.enc.Base64);
    return encryptedText;
  }

  const originalText = "Seth Shaheer Don The Great";
  const secretKey = "01234567890123456789012345678901"; // Replace with a valid 32-byte key

  const encryptedText = encryptText(originalText, secretKey);

  const { spaceCrede, currentCredentials } = useSelector(
    (item) => item.spaceReducer
  );

  const location = useLocation();

  const RequireAuth = ({ children }) => {
    return GetCookie("token") || GetCookie("session-token") ? (
      children
    ) : (
      <Navigate to="/login" />
    );
  };

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  // axios.defaults.baseURL = "https://auto.ndesks.com/";
  axios.defaults.headers.common["Credentials"] =
    currentCredentials || spaceCrede;

  // useEffect(() => {}, [currentCredentials, spaceCrede]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes location={location}>
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/forgetPsw" element={<ForgetPage />} />
        <Route exact path="/register" element={<SignUpPage />} />
        <Route exact path="/userPsw/:uuid" element={<UserPasswordSetup />} />
        <Route
          exact
          path="/activate/user/:uuid"
          element={<ConfirmDeactivation />}
        />
        <Route
          exact
          path="/quickbook"
          element={
            <RequireAuth>
              {InvoicesPermissions[0]?.read ? (
                <QuickBookCallBack />
              ) : (
                <PermissionPage />
              )}
            </RequireAuth>
          }
        />
        <Route exact path="/" element={<SharedMainPage />}>
          <Route
            index
            exact
            element={
              <RequireAuth>
                <DashboardPage />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/invoice/list"
            element={
              <RequireAuth>
                {InvoicesPermissions[0]?.read ? (
                  <ListMembers />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/invoice/:id/view"
            element={
              <RequireAuth>
                {InvoicesPermissions[0]?.read ? (
                  <ViewMembers />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/invoice/:id/edit"
            element={
              <RequireAuth>
                {InvoicesPermissions[0]?.update ? (
                  <EditMembers />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/invoice/add"
            element={
              <RequireAuth>
                {InvoicesPermissions[0]?.create ? (
                  <AddMembers />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/invoice/:id/add"
            element={
              <RequireAuth>
                {InvoicesPermissions[0]?.create ? (
                  <AddInvoiceForMember />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/member/list"
            element={
              <RequireAuth>
                {membersPermissions[0]?.read ? (
                  <AddMemberLists />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          {/* <Route
            exact
            path="/member/view"
            element={
              <RequireAuth>
                {membersPermissions[0]?.read ? (
                  <AddMemberViews />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          /> */}

          <Route
            exact
            path="/booking/list"
            element={
              <RequireAuth>
                {bookingsPermissions[0]?.read ? (
                  <BookingListPage />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/booking/hotdesk"
            element={
              <RequireAuth>
                {bookingsPermissions[0]?.read ? (
                  <HotDeskBookingList />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/booking/flexdesk"
            element={
              <RequireAuth>
                {bookingsPermissions[0]?.read ? (
                  <FlexDeskTable />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/package/addspace"
            element={
              <RequireAuth>
                {packagesPermissions[0]?.read ? (
                  <SpacePackage />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/floorPlans"
            element={
              <RequireAuth>
                <FloorPlans />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/floorPlans/:id/view"
            element={
              <RequireAuth>
                {floorsPermissions[0]?.read ? (
                  <ViewFloorPlans />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/floorPlans/:id/update"
            element={
              <RequireAuth>
                {floorsPermissions[0]?.update ? (
                  <FloorUpdate />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/company/:id/view1"
            element={
              <RequireAuth>
                {membersPermissions[0]?.read ? (
                  <DetailedCompanyPage />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/company/employee/:id/view"
            element={
              <RequireAuth>
                {membersPermissions[0]?.read ? (
                  <DetailedCompanyEmployee />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/individual/:id/view"
            element={
              <RequireAuth>
                {membersPermissions[0]?.read ? (
                  <DetailedIndividualPage />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/setting/accountsetting"
            element={
              <RequireAuth>
                <SettingPage />
                {/* <AccountSetting /> */}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/quotation/list"
            element={
              <RequireAuth>
                {quotationsPermission[0]?.read ? (
                  <QuotationLists />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/quotation/add"
            element={
              <RequireAuth>
                {quotationsPermission[0]?.create ? (
                  <AddQuotations />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/update/:id"
            element={
              <RequireAuth>
                {quotationsPermission[0]?.update ? (
                  <QuotationUpdate />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/quotation/:id/view"
            element={
              <RequireAuth>
                {quotationsPermission[0]?.update ? (
                  <QuotationView />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/visits/:id/view"
            element={
              <RequireAuth>
                {visitsPermissions[0]?.read ? (
                  <VisitorView />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/visitUpdate/:id"
            element={
              <RequireAuth>
                {visitsPermissions[0]?.update ? (
                  <VisitUpdate />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/visit/list"
            element={
              <RequireAuth>
                {visitsPermissions[0]?.read ? (
                  <VisitorsList />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/visitors/list"
            element={
              <RequireAuth>
                {visitsPermissions[0]?.read ? (
                  <VisitorsTable />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/visitors/add"
            element={
              <RequireAuth>
                {visitsPermissions[0]?.create ? (
                  <AddVisitor />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/visit/add"
            element={
              <RequireAuth>
                {visitsPermissions[0]?.create ? (
                  <VisitorsRegistration />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/ndesks"
            element={
              <RequireAuth>
                <NewUserCheck />
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/people/Management"
            element={
              <RequireAuth>
                {employessPermissions[0]?.read || teamsPermissions[0]?.read ? (
                  <PeoplesManagement />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/workplace/Management"
            element={
              <RequireAuth>
                {locationsPermissions[0]?.read || rolesPermissions[0]?.read ? (
                  <WorkPlaceManagement />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/team/:id/view"
            element={
              <RequireAuth>
                {teamsPermissions[0]?.read ? <TeamView /> : <PermissionPage />}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/role/add"
            element={
              <RequireAuth>
                {rolesPermissions[0]?.create ? <AddRole /> : <PermissionPage />}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/tourUpdate/:id"
            element={
              <RequireAuth>
                {toursPermissions[0]?.update ? (
                  <TourUpdate />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/tourView/:id"
            element={
              <RequireAuth>
                {toursPermissions[0]?.read ? <ViewTour /> : <PermissionPage />}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/tour/list"
            element={
              <RequireAuth>
                {toursPermissions[0]?.read ? <TourList /> : <PermissionPage />}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/questions/list"
            element={
              <RequireAuth>
                {toursPermissions[0]?.read ? (
                  <QuestionsList />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/tour/add"
            element={
              <RequireAuth>
                {toursPermissions[0]?.create ? <AddTour /> : <PermissionPage />}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/update/:id/role"
            element={
              <RequireAuth>
                {rolesPermissions[0]?.update ? (
                  <UpdateRole />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/partners"
            element={
              <RequireAuth>
                {partnerAndOffersPermissions[0]?.read ? (
                  <Partners />
                ) : (
                  <PermissionPage />
                )}
                {/* {rolesPermissions[0]?.create ? <AddRole /> : <PermissionPage />} */}
              </RequireAuth>
            }
          />

          <Route
            exact
            path="/:partnerId/offers"
            element={
              <RequireAuth>
                {partnerAndOffersPermissions[0]?.read ? (
                  <OfferList />
                ) : (
                  <PermissionPage />
                )}
                {/* {rolesPermissions[0]?.create ? <AddRole /> : <PermissionPage />} */}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/tickets"
            element={
              <RequireAuth>
                {ticketsPermissions[0]?.read ? (
                  <TicketPage />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/ticket/:id"
            element={
              <RequireAuth>
                {ticketsPermissions[0]?.read ? (
                  <ViewTicketPage />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          {/* Following Are routes of  */}

          <Route
            exact
            path="/community"
            element={
              <RequireAuth>
                {communityPermissions[0]?.read ? (
                  <DashboardAppPage />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/kisi-integration"
            element={
              <RequireAuth>
                {communityPermissions[0]?.read ? (
                  <KisiIntegrationPage />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/emailComposer"
            element={
              <RequireAuth>
                {announcementPermissions[0]?.create ? (
                  <EmailComposer />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />
          <Route
            exact
            path="/announcements"
            element={
              <RequireAuth>
                {announcementPermissions[0]?.read ? (
                  <AnnouncementTable />
                ) : (
                  <PermissionPage />
                )}
              </RequireAuth>
            }
          />

          {/* Financials Routes */}

          <Route exact path="/vendor" element={<Vendor />} />

          <Route exact path="/asset" element={<Asset />} />
          <Route exact path="/expense" element={<Expense />} />
          <Route exact path="/discount-code" element={<Discount />} />

          <Route exact path="/account" element={<Account />} />
          <Route exact path="/taxrate" element={<TaxRates />} />
          <Route exact path="/account-chart" element={<Chart />} />
          <Route exact path="/products" element={<ProductShared />} />
          <Route exact path="/reports" element={<Reports />} />
          <Route exact path="/visit-report" element={<Visitor />} />
          <Route exact path="/product-report" element={<ProductReport />} />
          <Route exact path="/discount-report" element={<DiscountShared />} />

          <Route exact path="/revenue-report" element={<RevenueShared />} />
          <Route exact path="/booking-report" element={<BookingShared />} />
          <Route exact path="/finance-report" element={<FinanceShared />} />
          <Route exact path="/desks-report" element={<DeskShared />} />
          <Route exact path="/invoice-report" element={<InvoiceShared />} />
          <Route exact path="/occupancy-report" element={<OccupancyShared />} />
          <Route exact path="/checkin-report" element={<CheckinShared />} />

          <Route
            exact
            path="/bookingkpi-report"
            element={<KPIBookingShared />}
          />
        </Route>

        <Route exact path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  );
};

export default App;
