import React, { useState, useEffect } from "react";
// import { Flatpickr } from "../AddInvoices";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import "./AddQuotation.css";
import { Toaster, toast } from "react-hot-toast";
import axios from "axios";

import { errorAlertImage, requiredAlert } from "../../utils/Alerts";

import { LogoDetails, SubTotal } from "../AddInvoices";
import {
  useCreateQuotationsMutation,
  useGetQuotationsQuery,
  useProductListQuery,
} from "../../Slices/spaceApi";
import { CancelIcon } from "../../Assets/InvoiceSvgs/Icons";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetCitiesQuery, useGetStatesQuery } from "../../Slices/constantApi";
import { useGlobalContext } from "../../Context/GlobalContext";
import QuoteSubTotal from "./QuoteSubTotal";
import { Breadcrumb } from "react-bootstrap";
import PackageCalculations from "../AddInvoices/PackageCalculations";
import ProductCalculations from "../AddInvoices/ProductCalculations";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
function AddQuotation({
  fetchPackages,
  fetchMeetingPackages,
  cities,
  countries,
  spaceData,
  productData,
  quotationId,
}) {
  const { rentalFee, serviceCharges, isOverAllTax, geoLocation, currency } =
    useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [createQuotations, resultStats] = useCreateQuotationsMutation();
  const [apiSpinner, setApiSpinner] = useState(false);

  const navigate = useNavigate();
  const setDate = () => {
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 7);
    return nextWeek;
  };
  const [statesArray, setStatesArray] = useState([]);

  const [citiesArray, setCititesArray] = useState([]);

  const [formData, setFormData] = useState({
    id: "1",
    packages: [
      {
        quantity: 1,
        package: "",
      },
    ],
    meetingPackageIds: [],
    quotationDate: setDate(),
    subTotal: 1,
    gst: 0,
    discount: "",
    subTotal: 0,
    terms: ["Term 1", "Term 2"],
    attachments: [],
    space: spaceId || currentSpaceId,
    customer: {
      country: 10,
      countryName: spaceData.countryName || countries[0],
      stateName: spaceData.stateName || statesArray[0], //
      cityName: spaceData.cityName || citiesArray[0], // ""
      isCompany: false,
      businessName: "",
      address: "",
      city: 15,
      postalCode: "",
      state: "",
      email: "",
      phone: "",
    },
    total: "",
  });

  const [apiCall, setApiCall] = useState(false);
  const [initialApiCall, setInititalApiCall] = useState(true);
  useEffect(() => {
    if (countries.length > 0) {
      setApiCall(true);
    }
  }, countries);
  const { data: states, isLoading: statesLoading } = useGetStatesQuery(
    {
      countryName: formData?.customer?.countryName,
    },
    {
      skip: formData?.customer?.countryName == "" || !apiCall,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: Cities, isLoading: citiesLoading } = useGetCitiesQuery(
    {
      stateName: formData?.customer?.stateName,
    },
    {
      skip: formData?.customer?.stateName == "" || !apiCall,
      refetchOnMountOrArgChange: true,
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "customer.stateName") {
      setInititalApiCall(false);
    }
    const [attribute, property] = name.split(".");

    if (property === "postalCode" && /^\d{12}$/.test(value)) {
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      [attribute]: {
        ...prevState[attribute],
        [property]: value,
      },
    }));
  };

  const handleMemberChange = (isCompany) => {
    setFormData((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        isCompany: isCompany,
      },
    }));
  };

  const [discount, setDiscount] = useState(0);

  const handleDate = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      quotationDate: value,
    }));
  };
  // const [rows, setRows] = useState([]);
  // const [meetingRows, setMeetingRows] = useState([]);
  // useEffect(() => {
  //   setRows(
  //     fetchPackages?.map((item) => ({
  //       packageId: item.package,
  //       quantity: item.quantity,
  //       price: fetchPackages?.find((item1) => item1.id === item.package)?.price,
  //       amount:
  //         fetchPackages?.find((item1) => item1.id === item.package)?.price *
  //         item.quantity,
  //     }))
  //   );

  //   setMeetingRows(
  //     fetchMeetingPackages?.map((item) => ({
  //       packageId: item.package,
  //       quantity: item.quantity,
  //       price: fetchMeetingPackages?.find((item1) => item1.id === item.package)
  //         ?.price,
  //       amount:
  //         fetchMeetingPackages?.find((item1) => item1.id === item.package)
  //           ?.price * item.quantity,
  //     }))
  //   );
  // }, [fetchPackages, fetchMeetingPackages]);

  const [rows, setRows] = useState([
    {
      packageId: fetchPackages?.[0]?.id,
      quantity: 1,
      serviceCharges: fetchPackages?.[0]?.serviceCharges,
      inclusiveTax: fetchPackages?.[0]?.inclusiveTax,

      rentalFee: fetchPackages?.[0]?.rentalFee,
      price: fetchPackages?.[0]?.serviceCharges + fetchPackages?.[0]?.rentalFee,
      taxRateObj: fetchPackages?.[0]?.taxRateObj,
    },
  ]);
  const [meetingRows, setMeetingRows] = useState([
    {
      packageId: fetchMeetingPackages?.[0]?.id,
      quantity: 1,
      serviceCharges: fetchMeetingPackages?.[0]?.serviceCharges,
      inclusiveTax: fetchMeetingPackages?.[0]?.inclusiveTax,

      rentalFee: fetchMeetingPackages?.[0]?.rentalFee,
      price:
        fetchMeetingPackages?.[0]?.serviceCharges +
        fetchMeetingPackages?.[0]?.rentalFee,
      taxRateObj: fetchMeetingPackages?.[0]?.taxRateObj,
    },
  ]);

  const [productList, setProductList] = useState([
    {
      productId: productData?.[0]?.id,
      productQuantity: productData?.[0]?.quantity,
      manageStock: productData?.[0]?.manageStock,
      quantity: 1,
      price: productData?.[0]?.price,
      taxRateObj: productData?.[0]?.taxRateObj,
    },
  ]);

  const removeProduct = (index) => {
    const list = [...productList];
    list.splice(index, 1);
    setProductList(list);
  };

  const addProduct = () => {
    setProductList([
      ...productList,
      {
        productId: productData?.[0]?.id,
        productQuantity: productData?.[0]?.quantity,
        manageStock: productData?.[0]?.manageStock,
        quantity: 1,
        price: productData?.[0]?.price,
        taxRateObj: productData?.[0]?.taxRateObj,
      },
    ]);
  };
  const handleProductChange = (index, event) => {
    let data = [...productList];
    data[index][event.target.name] = event.target.value;

    let findProduct = productData?.find(
      (item) => item.id == productList?.[index]?.productId
    );

    let quantity = productList?.[index]?.quantity;

    // data[index]["cost"] = findPackage?.price;
    data[index]["manageStock"] = findProduct?.manageStock;
    data[index]["productQuantity"] = findProduct?.quantity;

    // data[index]["price"] = (findProduct?.price * +quantity).toFixed(2);
    data[index]["price"] = (findProduct?.price * +quantity).toFixed(2);
    data[index]["taxRateObj"] = findProduct?.taxRateObj;

    setProductList(data);
  };
  // useEffect(() => {
  //   setRows({
  //     packageId: fetchPackages?.[0]?.id,
  //     quantity: 1,
  //     price: fetchPackages?.[0]?.price,
  //     amount: fetchPackages?.[0]?.price,
  //   });
  //   setMeetingRows({
  //     packageId: fetchMeetingPackages?.[0]?.id,
  //     quantity: 1,
  //     price: fetchMeetingPackages?.[0]?.price,
  //     amount: fetchMeetingPackages?.[0]?.price,
  //   });
  // }, [fetchPackages, fetchMeetingPackages]);

  const [country, setCountry] = useState([]);

  // Assuming you have fetched the countries and stored them in the `countries` variable
  // useEffect(() => {
  //   // Simulating the fetched country data
  //   const getCountry = {
  //     country: countries?.[0]?.id,
  //     name: countries?.[0]?.name,
  //   };

  //   setCountry(getCountry);
  // }, []);

  const [discountPercentage, setDiscountPercentage] = useState(0);

  const handleAddRow = () => {
    const newRow = {
      packageId: fetchPackages?.[0]?.id,
      quantity: 1,
      serviceCharges: fetchPackages?.[0]?.serviceCharges,
      inclusiveTax: fetchPackages?.[0]?.inclusiveTax,

      rentalFee: fetchPackages?.[0]?.rentalFee,
      price: fetchPackages?.[0]?.serviceCharges + fetchPackages?.[0]?.rentalFee,
      taxRateObj: fetchPackages?.[0]?.taxRateObj,
    };
    setRows([...rows, newRow]);
  };
  const handleAddMeetingRow = () => {
    const newRow = {
      packageId: fetchMeetingPackages?.[0]?.id,
      quantity: 1,
      serviceCharges: fetchMeetingPackages?.[0]?.serviceCharges,
      inclusiveTax: fetchMeetingPackages?.[0]?.inclusiveTax,

      rentalFee: fetchMeetingPackages?.[0]?.rentalFee,
      price:
        fetchMeetingPackages?.[0]?.serviceCharges +
        fetchMeetingPackages?.[0]?.rentalFee,
      taxRateObj: fetchMeetingPackages?.[0]?.taxRateObj,
    };
    setMeetingRows([...meetingRows, newRow]);
  };
  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const handleRemoveMeetingRow = (index) => {
    const updatedRows = [...meetingRows];
    updatedRows.splice(index, 1);
    setMeetingRows(updatedRows);
  };

  const handleInputChange = (event, index) => {
    let data = [...rows];

    data[index][event.target?.name] = event.target.value;

    let findPackage = fetchPackages?.find(
      (item) => item.id == rows?.[index]?.packageId
    );

    let quantity = rows?.[index]?.quantity;

    // data[index]["cost"] = findPackage?git aprice;
    data[index]["serviceCharges"] = findPackage?.serviceCharges;
    data[index]["inclusiveTax"] = findPackage?.inclusiveTax;

    data[index]["rentalFee"] = findPackage?.rentalFee;

    // data[index]["price"] = (findPackage?.price * +quantity).toFixed(2);
    data[index]["price"] = (
      (findPackage?.serviceCharges + findPackage?.rentalFee) *
      +quantity
    ).toFixed(2);
    data[index]["taxRateObj"] = findPackage?.taxRateObj;
    setRows(data);
  };

  const handleMeetingInputChange = (event, index) => {
    let data = [...meetingRows];

    data[index][event.target.name] = event.target.value;

    let findPackage = fetchMeetingPackages?.find(
      (item) => item.id == meetingRows?.[index]?.packageId
    );

    let quantity = meetingRows?.[index]?.quantity;

    data[index]["serviceCharges"] = findPackage?.serviceCharges;
    data[index]["rentalFee"] = findPackage?.rentalFee;
    data[index]["inclusiveTax"] = findPackage?.inclusiveTax;

    data[index]["price"] = (
      (findPackage?.serviceCharges + findPackage?.rentalFee) *
      +quantity
    ).toFixed(2);
    data[index]["taxRateObj"] = findPackage?.taxRateObj;
    setMeetingRows(data);
  };

  const handleDiscountChange = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      discount: value,
    }));
  };
  const handleGstChange = (e) => {
    const value = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      gst: value,
    }));
  };
  const [formErrors, setFormErrors] = useState({
    customer: {
      businessName: "",
      address: "",
      city: "",
      postalCode: "",
      state: "",
      email: "",
      phone: "",
    },
  });
  const handleBlur = (event) => {
    const { name } = event.target;
    const errors = { ...formErrors };

    // Perform validation based on field name
    switch (name) {
      case "customer.businessName":
        if (!formData.customer.businessName) {
          errors.customer.businessName = "Please enter Business Name";
        } else {
          errors.customer.businessName = "";
        }
        break;
      case "customer.address":
        if (!formData.customer.address) {
          errors.customer.address = "Please enter Address";
        } else {
          errors.customer.address = "";
        }
        break;

      case "customer.city":
        if (!formData.customer.city) {
          errors.customer.city = "Please select City";
        } else {
          errors.customer.city = "";
        }
        break;

      case "customer.postalCode":
        if (!formData.customer.postalCode) {
          errors.customer.postalCode = "Please enter Postal Code";
        } else {
          errors.customer.postalCode = "";
        }
        break;

      case "customer.state":
        if (!formData.customer.state) {
          errors.customer.state = "Please enter State";
        } else {
          errors.customer.state = "";
        }
        break;
      case "customer.email":
        if (!formData.customer.email) {
          errors.customer.email = "Please enter Email";
        } else if (!/\S+@\S+\.\S+/.test(formData.customer.email)) {
          errors.customer.email = "Please enter a valid email";
        } else {
          errors.customer.email = "";
        }
        break;
      case "customer.phone":
        if (
          !formData.customer.phone ||
          formData.customer.phone.length < 11 ||
          formData.customer.phone.length > 15
        ) {
          errors.customer.phone = "Please enter 11-15 digits Phone Number";
        } else {
          errors.customer.phone = "";
        }
        break;
      default:
        break;
    }

    setFormErrors(errors);
  };

  const getSubTotal = () => {
    let rowsTotal =
      rows?.reduce((total, item) => total + parseInt(item?.amount, 10), 0) || 0;
    let meetingRowsTotal =
      meetingRows?.reduce(
        (total, item) => total + parseInt(item?.amount, 10),
        0
      ) || 0;

    let subTotal = rowsTotal + meetingRowsTotal;
    return subTotal;
  };
  const getAfterDiscount = () => {
    let total = 0;
    rows.forEach((row) => {
      const { quantity, price } = row;
      if (quantity && price) {
        const amount = quantity * price;
        total += amount;
      }
    });

    meetingRows.forEach((row) => {
      const { quantity, price } = row;
      if (quantity && price) {
        const amount = quantity * price;
        total += amount;
      }
    });

    const discountAmount = (total * formData?.discount) / 100;
    total -= discountAmount;

    return total;
  };
  const getTotalAmount = () => {
    let total = 0;
    rows.forEach((row) => {
      const { quantity, price } = row;
      if (quantity && price) {
        const amount = quantity * price;
        total += amount;
      }
    });

    meetingRows.forEach((row) => {
      const { quantity, price } = row;
      if (quantity && price) {
        const amount = quantity * price;
        total += amount;
      }
    });

    const discountAmount = (total * formData?.discount) / 100;
    total -= discountAmount;
    const gstAmount = (total * formData?.gst) / 100;
    total += gstAmount;

    return total;
  };

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      discount: discountPercentage,
    }));
  }, [discountPercentage]);

  const quotationSubmit = (e) => {
    e.preventDefault();

    setFormData((prevState) => ({
      ...prevState,
      total: getTotalAmount(),
    }));

    const updateSpacePackageIds = rows?.map((item) => ({
      quantity: item.quantity,
      package: item.packageId,
    }));

    const updateMeetingPackageIds = meetingRows?.map((item) => ({
      quantity: item.quantity,
      package: item.packageId,
    }));
    const updateProductIds = productList?.map((item) => ({
      quantity: parseInt(item.quantity),
      product: parseInt(item.productId),
    }));
    const updateData = {
      packages: updateSpacePackageIds,
      meetingPackages: updateMeetingPackageIds,
      quotationDate: new Date(formData.quotationDate).toISOString(),
      purchase: {
        items: updateProductIds,
        // member: invoiceData?.member,
        member: null,
      },
      subTotal: formData.subTotal,
      // gst: formData.gst,
      discount: discount,
      terms: formData.terms,
      subTotal: getSubTotal(),
      attachments: formData.attachments,
      space: spaceId || currentSpaceId,
      customer: {
        isCompany: formData.customer.isCompany,
        email: formData.customer.email,
        address: formData.customer.address,
        phone_number: formData.customer.phone,
        postalcode: formData.customer.postalCode,
        name: formData.customer.businessName,
        cityName: formData.customer.cityName,
        countryName: formData.customer.countryName,
        stateName: formData.customer.stateName,
      },
      total: getTotalAmount(),
    };
    setApiSpinner(true);
    createQuotations({
      spaceId: spaceId || currentSpaceId,
      quotationData: updateData,
    });
  };

  useEffect(() => {
    if (states?.length > 0) {
      setStatesArray(states);
      setFormData((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          stateName: initialApiCall ? spaceData.stateName : states?.[0],
        },
      }));
    }
  }, [states]);
  useEffect(() => {
    if (Cities?.length > 0) {
      setCititesArray(Cities);
      setFormData((prevState) => ({
        ...prevState,
        customer: {
          ...prevState.customer,
          cityName: initialApiCall ? spaceData.cityName : Cities?.[0],
        },
      }));
    }
  }, [Cities]);
  console.log("Form data is ", formData);
  function handleCountryChange(event) {
    setApiCall(true);
    setInititalApiCall(false);
    setFormData((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        countryName: event.target.value,
      },
    }));
  }
  const handlePhoneChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      customer: {
        ...prevState.customer,
        phone: value,
      },
    }));
  };
  useEffect(() => {
    if (resultStats?.isError) {
      setApiSpinner(false);

      const errorResponse = resultStats?.error;

      if (errorResponse && errorResponse?.data) {
        if (
          errorResponse.data.email &&
          errorResponse?.data?.email?.[0] === "This field may not be blank."
        ) {
          requiredAlert("Email Must Not Be Blank");
        }

        if (
          errorResponse?.data?.name &&
          errorResponse?.data?.name?.[0] === "This field may not be blank."
        ) {
          requiredAlert("Name Must Not Be Blank");
        }
        if (
          errorResponse.data.phone_number &&
          errorResponse?.data?.phone_number?.[0] ===
            "This field may not be blank."
        ) {
          requiredAlert("Phone Number Must Not Be Blank");
        }
        if (
          errorResponse.data.postalcode &&
          errorResponse?.data?.postalcode?.[0] ===
            "This field may not be blank."
        ) {
          requiredAlert("Zip Code Must Not Be Blank");
        }
        if (
          errorResponse?.data?.packages?.[0]?.quantity &&
          errorResponse?.data?.packages?.[0]?.quantity?.[0] ===
            "Ensure this value is greater than or equal to 1."
        ) {
          requiredAlert("Ensure the quantity is greater than or equal to 1.");
        }
        if (errorResponse?.data?.message) {
          requiredAlert(errorResponse?.data?.message);
        }
      } else {
        requiredAlert("An error occurred.");
      }
    }
    if (resultStats?.isSuccess) {
      setApiSpinner(false);
      toast.success("Quotation Created Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate("/quotation/list");
    }
  }, [resultStats]);

  return (
    <>
      <section
        className="container-fluid addQuotation"
        style={{ padding: "0px 16px" }}
      >
        <Breadcrumb className="custom-breadcrumb ml-auto">
          <Breadcrumb.Item>
            <Link to="/quotation/list">Quotations List</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Add Quotation</Breadcrumb.Item>
        </Breadcrumb>
        <div className="row">
          <div className="col-xl-9 col-12">
            <div className="card d-flex">
              <div className="card-body invoice-padding">
                <div className="row invoice-spacing">
                  <div className="col-lg-6">
                    <LogoDetails {...spaceData} />
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center mb-1">
                      <label
                        htmlFor="quotationNo"
                        className="d-flex align-items-center mb-0"
                        style={{ minWidth: "150px" }}
                      >
                        Quotation No
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        name="quotationNo"
                        disabled
                        className="form-control"
                        // value={"#00" + (isNaN(quotationId) ? 1 : quotationId)}
                        value={"#00" + spaceData?.quotationId}
                      />
                    </div>
                    <div className="d-flex mt-2 align-items-center">
                      <label
                        htmlFor="quotationNo"
                        style={{ minWidth: "150px" }}
                        className="d-flex mb-0 align-items-center"
                      >
                        Quotation Valid
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <Flatpickr
                        className="form-control"
                        name="quotationDate"
                        value={formData?.quotationDate}
                        data-test="my-custom-value"
                        onChange={handleDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="quotations-main my-3">
                  <div className="row">
                    <form>
                      <div className="quotation-to col-12 col-md-12 mt-3 mt-md-0">
                        <div className="d-flex">
                          <h4>Quotation To &nbsp;</h4>
                          <h6>(Your Client)</h6>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <select
                              name="customer.country"
                              value={formData?.customer?.countryName}
                              onChange={(event) => handleCountryChange(event)}
                              className="form-control"
                              placeholder="Country"
                              defaultValue={spaceData?.countryName}
                              style={{
                                WebkitAppearance: "menulist",
                                MozAppearance: "menulist",
                                appearance: "menulist",
                                // Add any other custom styles you need here
                              }}
                            >
                              {countries?.map((item, index) => (
                                <option
                                  key={index}
                                  value={item}
                                  selected={item == spaceData?.countryName}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <select
                              name="customer.stateName"
                              id="state"
                              className="form-control my-2"
                              placeholder="State"
                              value={formData?.customer?.stateName}
                              onChange={handleChange}
                              // defaultValue={spaceData?.stateName}
                              onBlur={handleBlur}
                              style={{
                                WebkitAppearance: "menulist",
                                MozAppearance: "menulist",
                                appearance: "menulist",
                              }}
                            >
                              {states?.map((item, index) => (
                                <option
                                  key={index}
                                  value={item}
                                  selected={item == spaceData?.stateName}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>

                            {formErrors.customer.state && (
                              <p style={{ color: "red" }}>
                                *{formErrors.customer.state}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row d-flex">
                          <div className="col-6">
                            <select
                              name="customer.cityName"
                              value={formData?.customer?.cityName}
                              onChange={handleChange}
                              className="form-control"
                              onBlur={handleBlur}
                              placeholder="City"
                              style={{
                                WebkitAppearance: "menulist",
                                MozAppearance: "menulist",
                                appearance: "menulist",
                              }}
                            >
                              {/* <option>{formData.customer.cityName}</option> */}
                              {Cities?.map((item, index) => (
                                <option
                                  key={index}
                                  value={item}
                                  selected={item == spaceData?.cityName}
                                >
                                  {item}
                                </option>
                              ))}
                            </select>

                            {formErrors.customer.city && (
                              <p style={{ color: "red" }}>
                                *{formErrors.customer.city}
                              </p>
                            )}
                          </div>
                          <div className="col-6">
                            <input
                              type="number"
                              name="customer.postalCode"
                              id="postalCode"
                              className="form-control"
                              placeholder="Postal Code/ZIP Code(54000)"
                              value={formData.customer.postalCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              min={0}
                            />
                            {formErrors.customer.postalCode && (
                              <p style={{ color: "red" }}>
                                *{formErrors.customer.postalCode}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <input
                              type="text"
                              className="form-control my-2"
                              name="customer.businessName"
                              id="businessName"
                              placeholder="ABC Company"
                              value={formData.customer.businessName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {formErrors.customer.businessName && (
                              <p style={{ color: "red" }}>
                                *{formErrors.customer.businessName}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <input
                              type="text"
                              name="customer.address"
                              className="form-control my-2"
                              id="address"
                              placeholder="ABC Street, XYZ Country"
                              value={formData.customer.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {formErrors.customer.address && (
                              <p style={{ color: "red" }}>
                                *{formErrors.customer.address}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <input
                              type="email"
                              name="customer.email"
                              className="form-control my-2"
                              id="email"
                              placeholder="abc@gmail.com"
                              value={formData.customer.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {formErrors.customer.email && (
                              <p style={{ color: "red" }}>
                                *{formErrors.customer.email}
                              </p>
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <PhoneInput
                              placeholder="Enter phone number"
                              name="customer.phone"
                              value={formData.customer.phone}
                              required
                              // onChange={(e) => {
                              //   setFormData((prevState) => ({
                              //     ...prevState,
                              //     customer: {
                              //       phone: e,
                              //     },
                              //   }));
                              // }}
                              onChange={handlePhoneChange}
                            />
                            {/* <input
                              type="number"
                              name="customer.phone"
                              className="form-control my-2"
                              id="phone"
                              placeholder="11-15 Digits Phone Number*"
                              value={formData.customer.phone}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              min={0}
                            /> */}
                            {/* {formErrors.customer.phone && (
                              <p style={{ color: "red" }}>
                                *{formErrors.customer.phone}
                              </p>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="d-flex my-4">
                      <div className="form-check ml-3 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="customer.isCompany"
                          id="individualRadio"
                          value={false}
                          checked={formData.customer.isCompany === false}
                          onChange={() => handleMemberChange(false)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="individualRadio"
                        >
                          Individual
                        </label>
                      </div>

                      <div className="form-check ml-3 mb-3 col-6">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="customer.isCompany"
                          id="companyRadio"
                          value={true}
                          checked={formData.customer.isCompany === true}
                          onChange={() => handleMemberChange(true)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="companyRadio"
                        >
                          Company
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body invoice-padding invoice-product-details border">
                <h6>Packages</h6>
                <form className="source-item">
                  <div>
                    {fetchPackages?.length > 0 &&
                      rows?.map((input, index) => (
                        <div
                          key={index}
                          className={`my-2 ${
                            index % 2 == 0 ? "bg-white" : "bg-light"
                          }`}
                        >
                          <div className="">
                            <div
                              className="col-12 d-flex  position-relative pe-0 pl-3 fs-6"
                              // product-details-border
                            >
                              <div className="row w-100 pe-lg-0 pe-3 py-4">
                                <div className="col-12">
                                  <div className="row w-100">
                                    <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Item
                                      </p>
                                      <select
                                        name="packageId"
                                        value={input.packageId}
                                        onChange={(event) =>
                                          handleInputChange(event, index)
                                        }
                                        className="form-control"
                                      >
                                        {fetchPackages?.map((item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        quantity
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="1"
                                        min={1}
                                        name="quantity"
                                        value={input?.quantity}
                                        onChange={(event) =>
                                          handleInputChange(event, index)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="row w-100 mt-3">
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Service Charges
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="0"
                                        name="serviceCharges"
                                        value={input.serviceCharges}
                                        disabled
                                      />
                                    </div>
                                    <div className="col-lg-4 col-12 my-lg-0 my-2">
                                      <p className="card-text col-title mb-0">
                                        Rental Fee
                                      </p>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder="0"
                                        name="rentalFee"
                                        value={input.rentalFee}
                                        disabled
                                      />
                                    </div>

                                    <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                                      <p className="card-text col-title mb-md-50 mb-0">
                                        Price
                                      </p>
                                      <p className="card-text mb-0">
                                        {+rows?.[index]?.price}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <hr className="mt-4" />
                                <PackageCalculations
                                  input={input}
                                  isOverAllTax={isOverAllTax}
                                  serviceCharges={serviceCharges}
                                  rentalFee={rentalFee}
                                />
                              </div>

                              <div
                                className="d-flex flex-column align-items-center justify-content-between invoice-product-actions py-2 px-2"
                                // border-left
                              >
                                <CancelIcon
                                  index={() => handleRemoveRow(index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {fetchPackages?.length <= 0 && (
                      <p>No Package is Available</p>
                    )}
                  </div>
                  <div className="">
                    <div className="px-0 mt-3">
                      <button
                        type="button"
                        className="btn btn-primary send-invoice-btn"
                        data-repeater-create=""
                        onClick={handleAddRow}
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="card-body invoice-padding invoice-product-details border">
                <h6>Meeting Packages</h6>
                <form className="source-item">
                  <div>
                    {fetchMeetingPackages?.length > 0 &&
                      meetingRows?.map((input, index) => (
                        <div
                          key={index}
                          className={`my-2 ${
                            index % 2 == 0 ? "bg-white" : "bg-light"
                          }`}
                        >
                          {/* className="repeater-wrapper my-2" */}

                          <div className="">
                            <div className="col-12 d-flex  position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0  pe-3  py-4">
                                <div className="row w-100">
                                  <div className="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Item
                                    </p>
                                    <select
                                      name="packageId"
                                      value={input.packageId}
                                      onChange={(event) =>
                                        handleMeetingInputChange(event, index)
                                      }
                                      className="form-control"
                                    >
                                      {fetchMeetingPackages?.map(
                                        (item, index) => {
                                          return (
                                            <option key={index} value={item.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                  <div className="col-lg-4 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      quantity
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="quantity"
                                      value={input?.quantity}
                                      onChange={(event) =>
                                        handleMeetingInputChange(event, index)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row w-100 mt-3">
                                  <div className="col-lg-4 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      Service Charges
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="24"
                                      name="cost"
                                      value={input?.serviceCharges}
                                      disabled
                                    />
                                  </div>
                                  <div className="col-lg-4 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      Rental Fee
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="24"
                                      name="cost"
                                      value={input?.rentalFee}
                                      disabled
                                    />
                                  </div>

                                  <div className="col-lg-4 col-12 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Price
                                    </p>
                                    <p className="card-text mb-0">
                                      {+meetingRows?.[index]?.price}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-2 px-2">
                                <CancelIcon
                                  index={() => handleRemoveMeetingRow(index)}
                                />
                              </div>
                            </div>
                            <hr className="mt-4" />
                            <PackageCalculations
                              input={input}
                              isOverAllTax={isOverAllTax}
                              serviceCharges={serviceCharges}
                              rentalFee={rentalFee}
                            />
                          </div>
                        </div>
                      ))}
                    {fetchMeetingPackages?.length <= 0 && (
                      <p>No Meeting Packages is Available</p>
                    )}
                  </div>
                  <div className="">
                    <div className="px-0 mt-3">
                      <button
                        type="button"
                        className="btn btn-primary send-invoice-btn"
                        data-repeater-create=""
                        onClick={handleAddMeetingRow}
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {productData?.length > 0 && (
                <div className="card-body invoice-padding invoice-product-details">
                  <h6>Products</h6>
                  <form className="source-item">
                    <div>
                      {productList?.map((input, index) => (
                        <div
                          key={index}
                          className={`my-2 ${
                            index % 2 == 0 ? "bg-white" : "bg-light"
                          }`}
                          // className="repeater-wrapper my-2"
                        >
                          <div className="">
                            <div className="col-12 d-flex  position-relative pe-0 pl-3 fs-6">
                              <div className="row w-100 pe-lg-0  pe-3  py-4">
                                <div className="row w-100">
                                  <div className="col-lg-6 col-12 mb-lg-0 mb-2 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Product
                                    </p>
                                    <select
                                      name="productId"
                                      value={input.productId}
                                      onChange={(event) =>
                                        handleProductChange(index, event)
                                      }
                                      className="form-control"
                                    >
                                      {productData?.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item?.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-lg-3 col-12 my-lg-0 my-2">
                                    <p className="card-text col-title mb-0">
                                      quantity
                                    </p>
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="1"
                                      min={1}
                                      name="quantity"
                                      value={input?.quantity}
                                      onChange={(event) =>
                                        handleProductChange(index, event)
                                      }
                                    />
                                  </div>
                                  <div className="col-lg-3 col-12 mt-lg-0 mt-2">
                                    <p className="card-text col-title mb-md-50 mb-0">
                                      Price
                                    </p>
                                    <p className="card-text mb-0">
                                      {+productList?.[index]?.price}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex flex-column align-items-center justify-content-between  invoice-product-actions py-2 px-2">
                                <CancelIcon
                                  index={() => removeProduct(index)}
                                />
                              </div>
                            </div>
                            <hr className="mt-4" />
                            <ProductCalculations
                              input={input}
                              isOverAllTax={isOverAllTax}
                              serviceCharges={serviceCharges}
                              rentalFee={rentalFee}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="">
                      <div className="px-0 mt-3">
                        <button
                          type="button"
                          className="btn btn-primary send-invoice-btn"
                          data-repeater-create=""
                          onClick={addProduct}
                        >
                          Add Product
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {/* <div className="row invoice-padding py-5 align-items-center">
                <div className="col-md-3">Total Discount:</div>
                <div className="col-md-9">
                  <input
                    type="number"
                    name="discount"
                    className="form-control"
                    min={0}
                    max={100}
                    placeholder="0 %"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
              </div> */}

              <div className="col-lg-4  mt-5 mt-lg-0 ml-auto">
                {/* <QuoteSubTotal
                  serviceList={rows}
                  serviceMeeting={meetingRows}
                  tax={serviceCharges}
                  rentalFee={rentalFee}
                  discount={discount}
                  isOverAllTax={isOverAllTax}
                  setDiscount={setDiscount}
                /> */}

                <SubTotal
                  serviceList={rows}
                  serviceMeeting={meetingRows}
                  productList={productList}
                  tax={serviceCharges}
                  rentalFee={rentalFee}
                  discount={discount}
                  isOverAllTax={isOverAllTax}
                  setDiscount={setDiscount}
                />
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-12">
            <div
              className="card"
              style={{
                position: window.innerWidth > 1201 ? "fixed" : "static",
                minWidth:
                  window.innerWidth > 1201
                    ? window.innerWidth < 1440
                      ? "18%"
                      : "20%"
                    : "auto",
              }}
            >
              <div className="card-body">
                <button
                  className="btn btn-outline-primary mb-2 w-100"
                  onClick={quotationSubmit}
                  disabled={apiSpinner}
                >
                  Save & Continue{" "}
                  {apiSpinner ? (
                    <div
                      className="spinner-border text-primary"
                      role="status"
                      style={{
                        width: "20px",
                        height: "20px",
                        marginLeft: "5px",
                      }}
                    ></div>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddQuotation;
