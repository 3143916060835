import React from "react";
import PermissionPic from "../../Assets/Images/packages/Permission.png";

function PermissionPage() {
  return (
    <div className="container d-flex justify-content-center align-items-center vh-50 ">
      <div className="card text-center w-100">
        <img
          src={PermissionPic}
          alt="Permission Denied"
          className="img-fluid mb-4"
        />
        <p className="text-center">You are not authorized for this section.</p>
      </div>
    </div>
  );
}

export default PermissionPage;
