import React from "react";
import { useGlobalContext } from "../../Context/GlobalContext";

const PackagePrice = ({
  priceWithTax,
  priceService,
  priceRental,
  taxRateObj,
}) => {
  
  const { rentalFee, serviceCharges, isOverAllTax, currency } =
    useGlobalContext();

  return (
    <div>
      {priceWithTax ? (
        <div className="d-flex justify-content-between">
          <p>
            Total Price:{" "}
            {parseFloat(priceService) + parseFloat(priceRental) || 0}
          </p>
        </div>
      ) : taxRateObj ? (
        taxRateObj?.setOveralTax ? (
          <div className="d-flex justify-content-between">
            <p>Over-All Tax: {taxRateObj?.serviceCharges} %</p>

            <p>
              Total Price:{" "}
              {parseFloat(priceService) +
                parseFloat(priceRental) +
                parseFloat(priceService) * (taxRateObj?.serviceCharges / 100) +
                parseFloat(priceRental) * (taxRateObj?.serviceCharges / 100)}
            </p>
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <p>Service Tax: {taxRateObj?.serviceCharges} %</p>
            <p>Rental Tax: {taxRateObj?.rentalFee} %</p>
            <p>
              Total Price:{" "}
              {parseFloat(priceService) +
                parseFloat(priceRental) +
                parseFloat(priceService) * (taxRateObj?.serviceCharges / 100) +
                priceRental * (taxRateObj?.rentalFee / 100)}
            </p>
          </div>
        )
      ) : isOverAllTax ? (
        <div className="d-flex justify-content-between">
          <p>Over-All Tax: {serviceCharges} %</p>

          <p>
            Total Price:{" "}
            {parseFloat(priceService) +
              parseFloat(priceRental) +
              parseFloat(priceService) * (serviceCharges / 100) +
              parseFloat(priceRental) * (serviceCharges / 100)}
          </p>
        </div>
      ) : (
        <div className="d-flex justify-content-between">
          <p>Service Tax: {serviceCharges} %</p>
          <p>Rental Tax: {rentalFee} %</p>
          <p>
            Total Price:{" "}
            {parseFloat(priceService) +
              parseFloat(priceRental) +
              parseFloat(priceService) * (serviceCharges / 100) +
              priceRental * (rentalFee / 100)}
          </p>
        </div>
      )}
    </div>
  );
};

export default PackagePrice;
