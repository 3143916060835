import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  useGetAllResourcesQuery,
  useGetFloorPlansQuery,
  useGetPackageQuery,
  useUpdateFloorKisiGroupsMutation,
  useUpdatePackageKisiGroupsMutation,
} from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import { useGlobalContext } from "../../Context/GlobalContext";
import { requiredAlert } from "../../utils/Alerts";
import toast from "react-hot-toast";

const KisiFloorPlanGroup = ({ KisiGroups }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateFloorGroup, resultStats] = useUpdateFloorKisiGroupsMutation();
  const { currency } = useGlobalContext();

  const [entries, setEntries] = useState([]);

  const { isLoading: loading, data: FetchPackageData } = useGetFloorPlansQuery(
    spaceId || currentSpaceId
  );

  useEffect(() => {
    if (FetchPackageData && KisiGroups) {
      const prepopulatedEntries = FetchPackageData?.filter(
        (resource) => resource.kisiAccessGroups?.length > 0
      ).map((resource) => ({
        resource: {
          value: resource.id,
          label: resource.name,
        },
        groups: resource.kisiAccessGroups
          .map((groupId) => KisiGroups.find((group) => group.id === groupId))
          .filter(Boolean)
          .map((group) => ({
            value: group.id,
            label: group.name,
          })),
      }));

      setEntries(prepopulatedEntries || []);
    }
  }, [FetchPackageData, KisiGroups]);

  const resourceOptions = FetchPackageData?.map((resource) => ({
    value: resource.id,
    label: resource.name,
  }));

  const groupOptions = KisiGroups?.map((group) => ({
    value: group.id,
    label: group.name,
  }));

  const handleAddRow = () => {
    setEntries([...entries, { resource: null, groups: [] }]);
  };

  const handleRemoveRow = (index) => {
    const newEntries = [...entries];
    newEntries.splice(index, 1);
    setEntries(newEntries);
  };

  const handleResourceChange = (index, selectedResource) => {
    const newEntries = [...entries];
    newEntries[index].resource = selectedResource;
    setEntries(newEntries);
  };

  const handleGroupsChange = (index, selectedGroups) => {
    const newEntries = [...entries];
    newEntries[index].groups = selectedGroups;
    setEntries(newEntries);
  };

  const handleSave = async () => {
    const dataToSave = entries?.map((entry) => ({
      floor: entry.resource?.value,
      group: entry.groups?.map((group) => group.value),
    }));

    updateFloorGroup({
      spaceId: spaceId || currentSpaceId,
      data: dataToSave,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error);
    }

    if (resultStats?.isSuccess) {
      toast.success("Floor Group Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);
  if (loading) {
    return <Loader />;
  }
  return (
    <Container className="mt-3 card p-3">
      {entries?.map((entry, index) => (
        <Row key={index} className="align-items-center mt-3">
          <Col xs={12} md={5}>
            <Form.Group controlId={`resourceSelect-${index}`}>
              <Select
                options={resourceOptions}
                value={entry.resource}
                placeholder="Select floor"
                onChange={(selectedResource) =>
                  handleResourceChange(index, selectedResource)
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={5}>
            <Form.Group controlId={`groupsSelect-${index}`}>
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={groupOptions}
                placeholder="Select access groups"
                value={entry.groups}
                onChange={(selectedGroups) =>
                  handleGroupsChange(index, selectedGroups)
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={2} className="mt-3 mt-md-0">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleRemoveRow(index)}
            >
              X
            </span>
          </Col>
        </Row>
      ))}
      <div className="d-flex mt-4">
        <Button className="btn btn-secondary" onClick={handleAddRow}>
          Add
        </Button>
      </div>

      <div className="d-flex justify-content-end mt-4">
        <Button onClick={handleSave}>Save</Button>
      </div>
    </Container>
  );
};

export default KisiFloorPlanGroup;
