import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  useGetSpaceDataQuery,
  useUpdateDayPassKisiGroupsMutation,
} from "../../Slices/spaceApi";
import { requiredAlert } from "../../utils/Alerts";
import toast from "react-hot-toast";

const KisiDayPass = ({ KisiGroups }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updatePackageGroup, resultStats] =
    useUpdateDayPassKisiGroupsMutation();

  const { data, isLoading } = useGetSpaceDataQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [daypassAccessGroups, setDaypassAccessGroups] = useState([]);

  useEffect(() => {
    if (data) {
      setDaypassAccessGroups(data?.kisiAccessGroups || []);
    }
  }, [data]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {
    if (daypassAccessGroups) {
      const preselectedGroups = KisiGroups?.filter((group) =>
        daypassAccessGroups?.includes(group.id)
      )?.map((group) => ({
        value: group.id,
        label: group.name,
      }));
      setSelectedGroups(preselectedGroups);
    }
  }, [daypassAccessGroups, KisiGroups]);

  const groupOptions = KisiGroups?.map((group) => ({
    value: group.id,
    label: group.name,
  }));

  const handleGroupsChange = (selectedGroups) => {
    setSelectedGroups(selectedGroups);
  };

  const handleSave = async () => {
    const dataToSave = {
      kisiDayPassAccessGroups: selectedGroups?.map((group) => group.value),
    };

    updatePackageGroup({
      spaceId: spaceId || currentSpaceId,
      data: dataToSave,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error);
    }

    if (resultStats?.isSuccess) {
      toast.success("Daypass Group Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);

  return (
    <section>
      <Container className="mt-3 card p-3">
        <Row className="align-items-center mt-3">
          <Col xs={12} md={5} className=" text-end">
            <label htmlFor="" className="mt-2">
              Day Pass
            </label>
          </Col>
          <Col xs={12} md={5}>
            <Form.Group controlId="groupsSelect">
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={groupOptions}
                placeholder="Select access groups"
                value={selectedGroups}
                onChange={handleGroupsChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-4">
          <Button onClick={handleSave}>Save</Button>
        </div>
      </Container>
    </section>
  );
};

export default KisiDayPass;
