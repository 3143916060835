import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Dropdown,
  InputGroup,
  Pagination,
  Row,
} from "react-bootstrap";
import { Form } from "react-bootstrap";

import {
  copyTableContent,
  downloadPDF,
  printContent,
  tableToCSV,
} from "../../utils/exports";
import {
  handleChange,
  handleDates,
  handlePagesize,
} from "../../utils/sharedReports";
import Select from "react-select";
import {
  ChevronBarDown,
  ChevronDown,
  FileEarmarkArrowUp,
} from "react-bootstrap-icons";
import Swal from "sweetalert2";
const TabularReport = ({
  Child,
  data,
  isLoading,
  refetch,
  mySpace,
  allSpaces,
  tableRef,
  spaceTypes,
  spaceType,
  setSpaceType,
  list,
  setList,
  ownerLocation,
  page_size,
  setPage_size,
  page,
  setPage,
  location,
  setLocation,
  start,
  setStart,
  end,
  setEnd,
  setOptions,
  options,
  setSelectedOption,
  selectedOption,
  rooms,
  setSelectedRoom,
  setCompanyId,
  companyData,
  hideDate,
  hideSearch,
  checkinByDay,
  day,
  setDay,
  member,
  setMember,
  memberSearch,
  membersList,
}) => {
  // const [allSpaces, setAllSpaces] = useState([])

  const [time, setTime] = useState({ start, end });

  useEffect(() => {}, [allSpaces]);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  // useEffect(() => {
  //   refetch();
  // }, [page]);

  const totalPages = data ? Math.ceil(data?.totalCount / page_size) : 0;
  const startIndex = (page - 1) * page_size + 1;
  const endIndex =
    page * page_size > data?.totalCount ? data?.totalCount : page * page_size;

  // Generating dynamic pagination items
  let paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === page}
        onClick={() => handlePageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  // useEffect(() => {
  //   refetch();
  // }, [page_size]);

  // useEffect(() => {
  //   refetch();
  // }, [location]);
  // useEffect(() => {
  //   if ((data, !isLoading && !isError)) {
  //     setList(data.bookingAvgRevenue)
  //   }
  // }, [data])

  useEffect(() => {
    if (allSpaces) {
      setOptions(() =>
        allSpaces?.spaces?.map((data) => ({
          value: data?.id,
          label: data?.name,
        }))
      );
    }
  }, [allSpaces, ownerLocation]);
  useEffect(() => {
    if (selectedOption) {
      const locationString = selectedOption
        .map((option) => option.value)
        .join(",");

      setLocation(locationString);
    } else {
      setLocation("");
    }
  }, [selectedOption]);
  const handleMemberChange = (selectedOption) => {
    setMember(selectedOption.value);
  };

  return (
    <>
      <div className=" container-fluid card  m-0 mt-3 p-0 w-100 col-12">
        <div className=" col-12  ">
          <div className="d-flex mt-4">
            <div
              className={`col-12  ${
                rooms || companyData || spaceTypes ? "col-md-4" : "col-md-6"
              } `}
            >
              {!hideSearch && (
                <Select
                  isMulti
                  name="colors"
                  placeholder={mySpace?.name}
                  options={options}
                  // className='basic-multi-select  custom-select'
                  // classNamePrefix='select'
                  onChange={(selectedOptions, actionMeta) =>
                    handleChange(selectedOptions, setSelectedOption)
                  }
                  value={selectedOption}
                />
              )}

              {/* {checkinByDay && } */}
            </div>
            {rooms && (
              <div className="col-sm-12  col-md-4 mt-2 mt-md-0">
                <Form.Select
                  onChange={(e) => {
                    setSelectedRoom(e.target.value);
                  }}
                  placeholder="Select Meeting Room" // Adding an aria-label for accessibility
                  defaultValue={0}
                >
                  <option value={0}>All Rooms</option>
                  {rooms?.map(({ id, title }) => (
                    <option key={id} value={id}>
                      {title}
                    </option>
                  ))}
                </Form.Select>
              </div>
            )}

            {spaceTypes?.length > 0 && (
              <div className="col-12  col-md-4 mt-2 mt-md-0">
                <Form.Select
                  onChange={(e) => {
                    setSpaceType(e.target.value);
                  }}
                  placeholder="All Space Types"
                  defaultValue={0}
                >
                  <option value={0}>All Types</option>
                  {spaceTypes?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            )}

            {companyData && (
              <div className="col-sm-12  col-md-4 mt-2 mt-md-0">
                <Form.Select
                  onChange={(e) => {
                    setCompanyId(e.target.value);
                  }}
                  placeholder="Select Meeting Room" // Adding an aria-label for accessibility
                  defaultValue={" "}
                >
                  <option value=" ">All Companies</option>
                  {companyData?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
              </div>
            )}

            <div
              className={`col-sm-12  col-md-2  d-flex justify-content-end mt-2 mt-md-0 col-sm-12  ${
                rooms || companyData || spaceTypes ? "col-md-2" : "col-md-6"
              } `}
            >
              <Dropdown className="btn-group ">
                <Dropdown.Toggle
                  variant="secondary"
                  className="btn-label-primary"
                  id="dropdown-basic-button"
                  aria-haspopup="dialog"
                  aria-expanded="true"
                >
                  <i className="ti ti-file-export me-sm-1">
                    <FileEarmarkArrowUp width={15} height={15} />
                  </i>
                  <span className="d-none d-sm-inline-block text-align-center">
                    Export
                  </span>
                  <span className="">
                    <ChevronDown width={15} height={15} />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div
                    onClick={() => printContent(tableRef)}
                    className="dropdown-item"
                  >
                    Print
                  </div>

                  <div
                    className="dropdown-item"
                    onClick={() => tableToCSV(tableRef)}
                  >
                    CSV
                  </div>

                  <div
                    className="dropdown-item"
                    onClick={() => downloadPDF(tableRef)}
                  >
                    PDF
                  </div>
                  <div
                    onClick={() => copyTableContent(tableRef)}
                    className="dropdown-item"
                  >
                    Copy
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {checkinByDay && (
          <div className="col-12 col-lg-5 d-flex align-items-center mt-2">
            <Form.Label htmlFor="end" className="mb-0 mr-2 ml-2 min-w-4 p-2">
              Day
            </Form.Label>
            <Form.Control
              type="date"
              name="day"
              id="day"
              value={day}
              onChange={(e) => setDay(e.target.value)}
            />
          </div>
        )}

        {memberSearch && (
          <div className="col-10 col-md-6 ml-3  d-flex align-items-center mt-2">
            <b>Member:</b>{" "}
            <Select
              options={membersList?.map((m) => ({
                value: m.id,
                label: m.name,
              }))}
              value={
                member
                  ? {
                      value: member,
                      label: membersList?.find((m) => m.id === member)?.name,
                    }
                  : null
              }
              onChange={handleMemberChange}
              placeholder="Select a member"
              isSearchable
              className="ml-3 col-12 col-md-10"
            />
          </div>
        )}
        <hr></hr>

        {/* <div className="col-12">
          <div className="row">
            {!hideDate && (
              <div className="col-12 col-xl-7 ">
                <div className=" row align-items-end">
                  <div className="col-12 col-lg-5 d-flex align-items-center mt-2 mt-lg-0">
                    <Form.Label
                      htmlFor="start"
                      className="mb-0 mr-2  min-w-4  "
                    >
                      From:
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="start"
                      id="start"
                      value={time.start}
                      max={time.end}
                      onChange={(e) =>
                        setTime((prev) => ({ ...prev, start: e.target.value }))
                      }
                    />
                  </div>
                  <div className="col-12 col-lg-5 d-flex align-items-center mt-2 mt-lg-0">
                    <Form.Label
                      htmlFor="end"
                      className="mb-0 mr-2 ml-2 min-w-4 p-2"
                    >
                      To:
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="end"
                      id="end"
                      value={time.end}
                      min={time.start}
                      onChange={(e) =>
                        setTime((prev) => ({ ...prev, end: e.target.value }))
                      }
                    />
                  </div>
                  <div className="col-lg-2 col-12   mt-2 mt-lg-0 align-items-end d-flex flex-column-reverse ">
                    <div
                      className="btn btn-primary "
                      onClick={(e) => {
                        if (
                          (time.start && time.end) ||
                          (!time.start && !time.end)
                        ) {
                          setStart(time.start);
                          setEnd(time.end);
                        } else {
                          Swal.fire({
                            title: "Required!",
                            icon: "warning",
                            html: "Enter Valid Start and End Time",
                            confirmButtonText: "OK",
                          });
                        }
                      }}
                    >
                      Filter
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="col-12 col-xl-5 mt-2 mt-xl-0">
              <div className="d-flex bg-danger justify-content-end">
                <label
                  className="d-flex align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  Show
                  <select
                    onChange={(e) => handlePagesize(e, setPage, setPage_size)}
                    name="DataTables_Table_1_length"
                    aria-controls="DataTables_Table_1"
                    className="form-select"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                  entries
                </label>
              </div>
            </div>
          </div>
        </div> */}
        <div className="col-12">
          <div className="row">
            <div className={`col-12 col-xl-7 ${hideDate ? "invisible" : ""}`}>
              <div className="row align-items-end">
                <div className="col-12 col-lg-5 d-flex align-items-center mt-2 mt-lg-0">
                  <Form.Label htmlFor="start" className="mb-0 mr-2 min-w-4">
                    From:
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="start"
                    id="start"
                    value={time.start}
                    max={time.end}
                    onChange={(e) =>
                      setTime((prev) => ({ ...prev, start: e.target.value }))
                    }
                  />
                </div>
                <div className="col-12 col-lg-5 d-flex align-items-center mt-2 mt-lg-0">
                  <Form.Label
                    htmlFor="end"
                    className="mb-0 mr-2 ml-2 min-w-4 p-2"
                  >
                    To:
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="end"
                    id="end"
                    value={time.end}
                    min={time.start}
                    onChange={(e) =>
                      setTime((prev) => ({ ...prev, end: e.target.value }))
                    }
                  />
                </div>
                <div className="col-lg-2 col-12 mt-2 mt-lg-0 align-items-end d-flex flex-column-reverse">
                  <div
                    className="btn btn-primary"
                    onClick={() => {
                      if (
                        (time.start && time.end) ||
                        (!time.start && !time.end)
                      ) {
                        setStart(time.start);
                        setEnd(time.end);
                      } else {
                        Swal.fire({
                          title: "Required!",
                          icon: "warning",
                          html: "Enter Valid Start and End Time",
                          confirmButtonText: "OK",
                        });
                      }
                    }}
                  >
                    Filter
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-5 mt-2 mt-xl-0">
              <div className="d-flex justify-content-end">
                <label
                  className="d-flex align-items-center"
                  style={{ gap: "0.5rem" }}
                >
                  Show
                  <select
                    onChange={(e) => handlePagesize(e, setPage, setPage_size)}
                    name="DataTables_Table_1_length"
                    aria-controls="DataTables_Table_1"
                    className="form-select"
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                  </select>
                  entries
                </label>
              </div>
            </div>
          </div>
        </div>

        {Child()}
        <div className="m-0 border-bottom"></div>
        <div className=" pagination-custom flex-column flex-lg-row">
          <p className="text-muted mb-0">
            {endIndex !== 0
              ? `Showing ${endIndex - startIndex + 1} of ${
                  data?.totalCount
                } entries`
              : `Showing 0 of ${data?.totalCount} entries`}
          </p>
          {data?.totalCount > page_size && (
            <Pagination className="mt-lg-0 mt-2">
              <Pagination.Prev
                className="previous"
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
              >
                Prev.
              </Pagination.Prev>
              {paginationItems}
              <Pagination.Next
                className="next"
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
              >
                Next
              </Pagination.Next>
            </Pagination>
          )}
        </div>
      </div>
    </>
  );
};

export default TabularReport;
