import React from "react";
import { useEffect } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../Assets/DeleteIcon.svg";
import {
  useDeleteAnnouncementsMutation,
  useDeleteInvoiceV2Mutation,
  useDeleteSpaceInvoiceMutation,
} from "../../Slices/spaceApi";
import { ReactComponent as Lock } from "../../Assets/Lock.svg";

import {
  confirmationAlert,
  requiredAlert,
  userDeleteAlert,
} from "../../utils/Alerts";
import { useGlobalContext } from "../../Context/GlobalContext";
import { useState } from "react";

const DeleteAnnouncement = ({ id }) => {
  const { announcementPermissions } = useGlobalContext();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [deleteAnnouncement, resultStats] = useDeleteAnnouncementsMutation();
  const [apiSpinner, setApiSpinner] = useState(false);
  const handleDeleteAnnouncement = (e) => {
    e.preventDefault();
    confirmationAlert("Want to Delete This Announcement?").then(
      (willDelete) => {
        setApiSpinner(true);
        if (willDelete) {
          deleteAnnouncement({
            spaceId: spaceId || currentSpaceId,
            id: id,
          });
        }
      }
    );
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      userDeleteAlert("Announcement Deleted Successfully.");
      setTimeout(() => {
        setApiSpinner(false);
      }, 1000);
    }
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.message);
      setTimeout(() => {
        setApiSpinner(false);
      }, 500);
    }
  }, [resultStats]);

  return (
    <div>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="tooltip">
            {announcementPermissions[0]?.delete ? (
              "Delete Announcement"
            ) : (
              <span>
                Delete <Lock />
              </span>
            )}
          </Tooltip>
        }
      >
        <Button
          variant="white"
          className="p-0"
          onClick={
            announcementPermissions[0]?.delete ? handleDeleteAnnouncement : null
          }
          disabled={apiSpinner}
        >
          <DeleteIcon />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default DeleteAnnouncement;
