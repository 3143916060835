import axios from "axios";
import { toast } from "react-hot-toast";
import { requiredAlert } from "../utils/Alerts";

export const sendAcceptRequest = (id, spaceId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "ACCEPT_REQUEST" });
      const response = await axios.get(
        `/space/${spaceId}/quotations/${id}/accept`
      );

      if (
        response.data.message == "user with this Email already exists"
        // response.data.message.email[0] ===
        //   "profile info with this email already exists."
      ) {
        requiredAlert("The user with this email already exists");
      } else {
        dispatch({ type: "ACCEPT_SUCCESS", payload: response.data });
        toast.success("Successfully Converted to Invoice!");
      }
    } catch (error) {
      dispatch({ type: "ACCEPT_FAILURE", payload: error.message });
      if (
        error.response.data.message === "Quotation has already been accepted"
      ) {
        requiredAlert(`Error: ${error.response.data.message}`);
      } else if (error.response.data.message === "Quotation has been expired") {
        requiredAlert(`Error: ${error.response.data.message}`);
      } else {
        requiredAlert(
          `Error: ${error.response.data.message}` || `Error: ${error.response}`
        );
      }
    }
  };
};
