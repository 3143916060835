import React, { useState } from "react";
import {
  useDiscountReportQuery,
  useRevenueReportQuery,
} from "../../../Slices/spaceApi";
import { Button, Dropdown, Form, Nav, Tab, Tabs } from "react-bootstrap";
import {
  CalendarMonth,
  Percent,
  PersonFill,
  Receipt,
} from "react-bootstrap-icons";
import * as Icon from "react-bootstrap-icons";
import RevMonth from "./RevMonth";
import RevMember from "./RevMember";

const RevenueShared = () => {
  const [page, setPage] = useState(0);
  return (
    <>
      <section className="container-fluid  with-bottom-padding pt-0 p-3  mt-0">
        <Tabs
          defaultActiveKey="revMember"
          id="justify-tab-example"
          // className='mb-3'
          variant="underline"
          justify
        >
          <Tab
            // style={{ marginRight: '5px' }}
            eventKey="revMember"
            title={
              <span className="d-flex align-items-center">
                <Icon.FileEarmarkPerson /> &nbsp;Revenue By Member
              </span>
            }
          >
            <RevMember />
          </Tab>

          <Tab
            // style={{ marginRight: '5px' }}
            eventKey="revmonth"
            title={
              <span className="d-flex align-items-center">
                <Icon.CalendarDate /> &nbsp;Revenue By Month
              </span>
            }
          >
            <RevMonth />
          </Tab>
        </Tabs>
      </section>
    </>
  );
};

export default RevenueShared;
