import React, { useEffect, useState } from "react";
import { Container, Form, Button, Breadcrumb } from "react-bootstrap";
import {
  useAddTourMutation,
  useGetAllLocationsQuery,
  useGetQuotationsQuery,
  useGetTourQuestionsQuery,
  useTourSlotsMutation,
  useUpdateTourMutation,
} from "../Slices/spaceApi";
import { useSelector } from "react-redux";
import { requiredAlert } from "../utils/Alerts";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import getCurrentDateTime from "../utils/getCurrentDateTime";
import getCurrentDate from "../utils/getCurrentDate";
import { useGlobalContext } from "../Context/GlobalContext";
import * as Icon from "react-bootstrap-icons";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const AddTour = ({
  namePre,
  emailPre,
  phone_numberPre,
  tour_datetimePre,
  space,
  edit,
  tourId,
  response,
}) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const navigate = useNavigate();
  const { setDashBoardTitle } = useGlobalContext();
  useEffect(() => {
    setDashBoardTitle("Add Tour");
  }, []);
  const { data: getLocations } = useGetAllLocationsQuery({
    spaceId: spaceId || currentSpaceId,
  });
  const [getTourSlots, resultStats2] = useTourSlotsMutation();
  // const [datePart, timePart] = tour_datetimePre?.split("T");
  const { data: questionsData, isLoading: questionsLoading } =
    useGetTourQuestionsQuery({ spaceId: spaceId || currentSpaceId });

  const [datePart, timePart] = tour_datetimePre
    ? tour_datetimePre.split("T")
    : [null, null];

  const [createTour, resultStats] = useAddTourMutation();
  const [updateTour, resultStats1] = useUpdateTourMutation();
  const [tourSlots, setTourSlots] = useState([]);
  const [apiSpinner, setApiSpinner] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(
    // space || getLocations?.spaces?.[0]?.id
    spaceId || currentSpaceId
  );
  const [selectedSlot, setSelectedSlot] = useState(timePart || null);
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const handleSlotClick = (startTime) => {
    // Update the selected slot in the state
    setSelectedSlot(startTime);
  };
  const utcTime = new Date();

  // Convert UTC time to local time
  const localTime = new Date(
    utcTime.getTime() - utcTime.getTimezoneOffset() * 60000
  );
  const minutes = localTime.getMinutes();
  const roundedMinutes = Math.ceil(minutes / 15) * 15;
  localTime.setMinutes(roundedMinutes, 0, 0);

  const [name, setName] = useState(namePre || "");
  const [email, setEmail] = useState(emailPre || "");
  const [phoneNumber, setPhoneNumber] = useState(phone_numberPre || "");
  const [tourDatetime, setTourDatetime] = useState(
    datePart || getCurrentDate()
    //  new Date()?.toISOString().slice(0, 16)
  );

  useEffect(() => {
    if (tour_datetimePre) {
      setTourDatetime(datePart);
      setSelectedSlot(timePart);
    }
  }, [tour_datetimePre]);
  async function handleTourSlots(selectedDate) {
    const slots = await getTourSlots({
      spaceId: spaceId || currentSpaceId,
      data: {
        date: selectedDate,
      },
    });

    setTourSlots(slots?.data || []);
  }

  useEffect(() => {
    if (tourDatetime) {
      handleTourSlots(tourDatetime);
    }
  }, [tourDatetime]);
  const [answers, setAnswers] = useState([]);
  const [validationStatus, setValidationStatus] = useState({});

  useEffect(() => {
    if (edit && response?.length > 0) {
      response?.map((item, index) => {
        setAnswers((prevAnswers) => [
          ...prevAnswers,
          {
            question: item?.question?.id,
            answer: item?.answer,
          },
        ]);
      });
    }
  }, [response]);

  const handleInputChange = (e, questionId) => {
    const { value } = e.target;

    const answerIndex = answers.findIndex(
      (answer) => answer.question === questionId
    );

    // If the answer exists, update it; otherwise, create a new answer object
    if (answerIndex !== -1) {
      setAnswers((prevAnswers) => [
        ...prevAnswers.slice(0, answerIndex),
        { question: questionId, answer: value },
        ...prevAnswers.slice(answerIndex + 1),
      ]);
    } else {
      setAnswers((prevAnswers) => [
        ...prevAnswers,
        { question: questionId, answer: value },
      ]);
    }
  };
  const validateInput = (value) => {
    // Replace with your validation logic
    return value.length > 0; // Example: Check if the value is not empty
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const combinedDateTime = `${tourDatetime}T${selectedSlot}`;

    if (edit) {
      setApiSpinner(true);

      updateTour({
        spaceId: spaceId || currentSpaceId,
        data: {
          name: name,
          email: email,
          phone_number: phoneNumber,
          // tour_datetime: tourDatetime,
          tour_datetime: combinedDateTime,
          status: 2,
          response: answers,
        },
        tourId: tourId,
      });
    } else {
      setApiSpinner(true);

      createTour({
        spaceId: spaceId || currentSpaceId,
        data: {
          name: name,
          email: email,
          phone_number: phoneNumber,
          // tour_datetime: tourDatetime,
          tour_datetime: combinedDateTime,
          status: 2,
          response: answers,
        },
      });
    }
  };

  useEffect(() => {
    if (resultStats.isError) {
      setApiSpinner(false);

      const errorResponse = resultStats?.error;
      console.log("Error is", errorResponse);
      if (errorResponse && errorResponse?.data) {
        if (
          errorResponse.data.email &&
          errorResponse?.data?.email[0] ===
            "visitor with this email already exists."
        ) {
          requiredAlert("Visitor with this email already exists.");
        }
        if (errorResponse.data.tour_datetime) {
          requiredAlert("Please select date and valid slot.");
        }
        if (errorResponse.data.name) {
          requiredAlert("Ensure name has no more than 100 characters");
        }
        // if (
        //   !errorResponse.data.email &&
        //   !errorResponse.data.expected_visit_datetime

        // ) {
        //   requiredAlert("Error");
        // }
      } else {
        requiredAlert("An error occurred.");
      }
    }

    if (resultStats.isSuccess) {
      // setApiSpinner(false);
      setApiSpinner(false);

      toast.success("Tour Added Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate("/tour/list");
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isError) {
      setApiSpinner(false);
      const errorMessage = Object.values(resultStats?.error?.data).find(
        (error) => error.length > 0
      );
      requiredAlert(errorMessage);
    }
    if (resultStats1?.isSuccess) {
      setApiSpinner(false);

      toast.success("Tour Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      navigate("/tour/list");
    }
  }, [resultStats1]);

  const [touchedFields, setTouchedFields] = useState({
    name: false,
    email: false,
    phoneNumber: false,
    tourDatetime: false,
  });

  const handleFieldBlur = (fieldName) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [fieldName]: true,
    }));
  };

  const isNameValid = touchedFields.name ? !!name : true;
  const isEmailValid =
    !touchedFields.email ||
    (!!email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
  const isPhoneValid =
    !touchedFields.phoneNumber ||
    (!!phoneNumber && phoneNumber.length >= 11 && phoneNumber.length <= 15);
  const isDateTimeValid = !touchedFields.tourDatetime || !!tourDatetime;

  const isFormValid =
    !!name &&
    !!email &&
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) &&
    !!phoneNumber &&
    phoneNumber.length >= 11 &&
    phoneNumber.length <= 15 &&
    !!tourDatetime;

  const formatTime = (timeString) => {
    const date = new Date(`2023-10-29T${timeString}`);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format

    return `${formattedHours}:${minutes < 10 ? "0" : ""}${minutes} ${amOrPm}`;
  };

  return (
    <div
      className="registration container-fluid"
      style={{ padding: "0px 16px" }}
    >
      {edit ? (
        <Breadcrumb className="custom-breadcrumb ml-auto">
          <Breadcrumb.Item>
            <Link to="/tour/list">Tour List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{name}</Breadcrumb.Item>
        </Breadcrumb>
      ) : (
        <Breadcrumb className="custom-breadcrumb ml-auto">
          <Breadcrumb.Item>
            <Link to="/tour/list">Tour List</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Add Tour</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <div className="card mx-auto">
        <div className="p-4 mb-3">
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="John Doe"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onBlur={() => handleFieldBlur("name")}
                    isInvalid={!isNameValid}
                  />
                  <Form.Control.Feedback type="invalid">
                    {touchedFields.name && !name && "Name is required."}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="abc@gmail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={() => handleFieldBlur("email")}
                    isInvalid={!isEmailValid}
                  />
                  <Form.Control.Feedback type="invalid">
                    {touchedFields.email &&
                      !isEmailValid &&
                      "Invalid email address."}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">Phone</Form.Label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    // name="phoneNo"
                    value={phoneNumber}
                    // onChange={handleInfo}
                    // min={0}
                    onChange={(e) => setPhoneNumber(e)}
                    onBlur={() => handleFieldBlur("phoneNumber")}
                    isInvalid={!isPhoneValid}
                    min={0}
                  />
                  {/* <Form.Control
                    type="number"
                    placeholder="11-15 Digits Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    onBlur={() => handleFieldBlur("phoneNumber")}
                    isInvalid={!isPhoneValid}
                    min={0}
                  /> */}
                  <Form.Control.Feedback type="invalid">
                    {touchedFields.phoneNumber &&
                      !isPhoneValid &&
                      "Invalid phone number (at least 11 characters and less than equal to 15)."}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              {questionsData?.tourQuestions?.length > 0 &&
                questionsData?.tourQuestions?.map((item, index) => (
                  <div className="col-12" key={index}>
                    <Form.Group className="mb-3">
                      <Form.Label className="mb-1">
                        {item.question}
                        {item.isRequired ? (
                          ""
                        ) : (
                          <span className="optional">(Optional)</span>
                        )}
                      </Form.Label>
                      <Form.Control
                        type={item.questionType === 1 ? "text" : "number"}
                        placeholder="Enter answer"
                        required={item.isRequired}
                        value={
                          answers?.find(
                            (answer) => answer?.question === item.id
                          )?.answer || ""
                        }
                        onChange={(e) => handleInputChange(e, item.id)}
                        min={0}
                      />
                    </Form.Group>
                  </div>
                ))}

              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-1">Tour Date</Form.Label>
                  <div className="input-group ">
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        style={{
                          borderTopLeftRadius: "5px",
                          borderBottomLeftRadius: "5px",
                        }}
                      >
                        <Icon.Calendar2Check />
                      </span>
                    </div>
                    <Form.Control
                      type="date"
                      value={tourDatetime}
                      onChange={(e) => setTourDatetime(e.target.value)}
                      onBlur={() => handleFieldBlur("tourDatetime")}
                      isInvalid={!isDateTimeValid}
                      // min={getCurrentDateTime()}
                      min={getCurrentDate()}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {touchedFields.tourDatetime &&
                      !isDateTimeValid &&
                      "Date and Time are required."}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group className="mb-2">
                  <Form.Label className="mb-1">Location</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                  >
                    {getLocations?.spaces?.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-md-12">
                {selectedLocation && (
                  <div className="fw-bold mb-3" style={{ fontSize: "14px" }}>
                    {`We would love to show you ${
                      getLocations?.spaces.find(
                        (item) => item.id == selectedLocation
                      ).name
                    }. Please let us know when you are available and we will do our best to receive you on that date and time.`}
                  </div>
                )}
              </div>
            </div>

            <div
              className="meeting-slots-container h-25"
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {tourSlots.length > 0
                ? tourSlots?.map((slot, index) => {
                    return (
                      <div
                        key={index}
                        className="meeting-slot"
                        style={{
                          border: "1px solid #358EF1",
                          cursor: "pointer",
                          margin: "5px",
                          padding: "5px",
                          borderRadius: "25px",
                          justifyContent: "center",
                          maxHeight: "38px",
                          alignItems: "center",
                          backgroundColor:
                            selectedSlot === slot.start
                              ? "#007bff"
                              : "transparent",

                          color:
                            selectedSlot === slot.start ? "#fff" : "initial",
                          // backgroundColor:
                          //   index >= selectedStart && index <= selectedEnd
                          //     ? "#007bff"
                          //     : "transparent",
                          // color:
                          //   index >= selectedStart && index <= selectedEnd
                          //     ? "#fff"
                          //     : "initial",
                        }}
                        // onClick={() => handleSlotClick(index)}
                        onClick={() => handleSlotClick(slot.start)}
                      >
                        {`${formatTime(slot.start)} - ${formatTime(slot.end)}`}
                      </div>
                    );
                  })
                : "No Booking Slots Available on This Date."}
            </div>

            {/* <div className="mb-3">
              {!edit && (
                <ReCAPTCHA
                  sitekey="6Let1QEpAAAAAG7ivDvE1dZIjA_7GMfBgpoFw0kw"
                  onChange={handleCaptchaChange}
                />
              )}
            </div> */}

            <Button
              variant="primary"
              type="submit"
              className="float-right"
              disabled={
                !isFormValid || apiSpinner
                //  || (!isCaptchaVerified && !edit)
              }
            >
              {edit ? "Update Tour" : "Request a Tour"}
              {apiSpinner ? (
                <div
                  className="spinner-border text-primary"
                  role="status"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "5px",
                  }}
                ></div>
              ) : (
                ""
              )}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddTour;
