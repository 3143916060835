import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { errorAlertImage, requiredAlert } from "../../utils/Alerts";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";
const TicketForm = ({
  categories,
  formData,
  setFormData,
  members,
  isUploading,
  setIsUploading,
  purpose,
  setPurpose,
}) => {
  const [file, setFile] = useState(null);
  const [purposeError, setPurposeError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "purpose") {
      if (value?.length > 255) {
        setPurposeError("Ensure Purpose has no more than 255 character");
        setPurpose(true);
        return;
      } else {
        setPurpose(false);
        setFormData({ ...formData, [name]: value });
        setPurposeError("");
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     category: categories.find(
  //       (category) => category.id === parseInt(formData.mainCategory)
  //     )?.children?.[0]?.id,
  //   });
  // }, [formData?.mainCategory]);
  useEffect(() => {
    if (file !== null && file !== "") {
      setIsUploading(true);
      if (file.size >= 1e7) {
        errorAlertImage("File is too big!");
        setFile(null);
        setIsUploading(false);
      } else if (file.type.includes("image")) {
        const fd = new FormData();
        fd.append("image", file);
        axios
          .post("/uploadImage/", fd)
          .then((resp) => {
            setFormData({ ...formData, attach_photo: resp.data?.[0] });
            setIsUploading(false);
          })
          .catch((error) => {
            setIsUploading(false);

            setFile(null);
          });
      } else {
        setFile(null);
        requiredAlert("Please Select Image File");
        setIsUploading(false);
      }
    }
    // setImgError(false);
  }, [file]);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
    // setImgError(false);
  };

  const uploadFile = (e) => {
    e.preventDefault();
    document.getElementById("real-file").click();
  };
  return (
    <>
      <div className="mb-2">
        {/* Main Category Select */}
        <Form.Group controlId="creator">
          <Form.Label>Member</Form.Label>
          <Form.Select
            name="creator"
            onChange={handleChange}
            value={formData.creator}
            required
          >
            {members?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {members?.length <= 0 && (
          <>
            <p className="mb-0 mt-1">There's no existing member</p>
            <Link to={"/member/list"}>Add Member</Link>
          </>
        )}
      </div>

      <div className="mb-2">
        {/* Main Category Select */}
        <Form.Group controlId="mainCategory">
          <Form.Label>Main Category</Form.Label>
          <Form.Select
            name="mainCategory"
            onChange={handleChange}
            value={formData.mainCategory}
            required
          >
            {categories?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>

      <div className="mb-2">
        {/* Category Select */}
        <Form.Group controlId="category">
          <Form.Label>Category</Form.Label>
          <Form.Select
            name="category"
            onChange={handleChange}
            value={formData?.category}
            required
          >
            {formData.mainCategory &&
              categories
                .find(
                  (category) => category.id === parseInt(formData.mainCategory)
                )
                .children.map((childCategory) => (
                  <option
                    key={childCategory.id}
                    value={childCategory.id}
                    selected={childCategory.id === formData?.category}
                  >
                    {childCategory.name}
                  </option>
                ))}
          </Form.Select>
        </Form.Group>
      </div>

      <div className="mb-2">
        {/* Purpose */}
        <Form.Group controlId="purpose">
          <Form.Label>Purpose</Form.Label>
          <Form.Control
            type="text"
            name="purpose"
            placeholder="Enter purpose"
            onChange={handleChange}
            value={formData.purpose}
            required
          />
          {purpose || purposeError ? (
            <div className="invalid-feedback">
              *{purposeError || "Please enter a purpose"}
            </div>
          ) : (
            ""
          )}
          {/* {purposeError && (
            <div className="invalid-feedback">*{purposeError}.</div>
          )} */}
        </Form.Group>
      </div>

      <div className="mb-2">
        {/* Description */}
        <Form.Group controlId="description">
          <Form.Label>
            Description <span className="optional">(Optional)</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            placeholder="Enter description"
            onChange={handleChange}
            value={formData.description}
            required
          />
        </Form.Group>
      </div>

      <div className="mb-2">
        <label htmlFor="name">
          Image <span className="optional">(Optional)</span>
        </label>

        <div className="media">
          <div className="media-aside align-self-start">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : formData?.attach_photo
                  ? formData?.attach_photo
                  : "https://placehold.jp/90x90.png"
              }
              style={{
                width: "90px",
                height: "90px",
                objectFit: "cover",
              }}
              className="rounded"
              alt=""
            />
          </div>
          <div>
            <input
              id="real-file"
              name="file"
              type="file"
              hidden="hidden"
              onChange={handleFileChange}
            />
          </div>

          <div className="media-body mt-2">
            {isUploading ? (
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Uploading...</span>
              </div>
            ) : (
              <button
                id="custom-button"
                onClick={uploadFile}
                className="btn btn-secondary me-2 btn-sm"
              >
                Upload
              </button>
            )}
            <p className="card-text mt-2">
              {/* Allowed Max size of 10Mb */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip">Allowed Max size of 10Mb</Tooltip>
                }
              >
                <Button variant="white" className="mx-2 p-0">
                  <Icon.InfoCircle style={{ color: "currentcolor" }} />
                </Button>
              </OverlayTrigger>
            </p>
            {/* {imgError ? (
              <div className="invalid-feedback">Please select an image.</div>
            ) : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketForm;
