import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PasswordImg from "../../Assets/dummyImages/passwordset.png";
import "./index.css";
import { EyeOff, EyeOn } from "../../Assets/SettingSvgs/Icon";
import Spinner from "react-bootstrap/Spinner";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { requiredAlert } from "../../utils/Alerts";
import RemoveCookie from "../../utils/removeCookie";
import logo1 from "../../Assets/nDesk/LogoFull.png";
import { useSetPasswordMutation } from "../../Slices/spaceApi";
import { toast } from "react-hot-toast";

const UserPasswordSetup = () => {
  const [createPassword, resultStats] = useSetPasswordMutation();
  const Dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pswIcon, setPswIcon] = useState(false);
  const [confirmPswIcon, setConfirmPswIcon] = useState(false);
  const [type0, setType0] = useState(true);
  const [type1, setType1] = useState(true);
  const [load, setLoad] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);

  const [passwordValid, setPasswordValid] = useState(true);
  const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
  const [uuid, setUUID] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;

    const uuidRegex = /\/([^/]+)\/?$/;
    const match = currentUrl.match(uuidRegex);

    if (match) {
      const extractedUuid = match[1];

      setUUID(extractedUuid);
    }
  }, []);

  const oldPsw = () => {
    setType0(!type0);
    setPswIcon(!pswIcon);
  };
  const oldCPsw = () => {
    setType1(!type1);
    setConfirmPswIcon(!confirmPswIcon);
  };

  const submit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
      const passwordSet = {
        password: password,
      };
      createPassword({
        spaceId: 1,
        uuid: uuid,
        password: passwordSet,
      });
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setPasswordMatch(true);
    setConfirmPasswordValid(e.target.validity.valid);
  };
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    setPasswordValid(e.target.validity.valid);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(`Expired or doesnot exist.`);
    }
    if (resultStats?.isSuccess) {
      toast.success("Password Set Successfully", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });

      RemoveCookie("spaces");
      RemoveCookie("token");
      RemoveCookie("creds");
      RemoveCookie("session-token");
      RemoveCookie("ids");

      sessionStorage.removeItem("token");
      sessionStorage.removeItem("spaces");
      sessionStorage.removeItem("creds");

      navigate("/login");
      window.location.reload();
    }
  }, [resultStats]);

  return (
    <section id="login" className="vh-100">
      <div className="row w-100 m-0 row-length">
        <Link to="/" className="login-logo d-md-block d-none">
          <img src={logo1} alt="login-logo" />
        </Link>
        <div
          className="col-md-8 p-5 d-none d-md-flex justify-content-center"
          style={{ height: "100vh" }}
        >
          <div className="px-5">
            <img
              src={PasswordImg}
              alt="login-img"
              style={{ maxHeight: "100%" }}
            />
          </div>
        </div>
        <div
          className="col-md-4 p-4 bg-white relative"
          style={{ height: "100vh" }}
        >
          <div className="center-form mt-4">
            <Link to="/" className="d-block d-md-none text-center">
              <img style={{ width: "145px" }} src={logo1} alt="login-logo" />
            </Link>
            <h2 className="card-title mb-2 text-center text-md-start">
              Welcome to nDesks!
            </h2>
            <form onSubmit={submit} className="auth-login-form mt-2">
              <fieldset className="form-group" id="__BVID__53">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="login-password" className="mb-0">
                      Password
                    </label>
                  </div>
                  <div className="input-group">
                    <input
                      id="login-password"
                      name="password"
                      type={type0 ? "password" : "text"}
                      required
                      value={password}
                      onChange={handlePasswordChange}
                      className={`form-control-merge form-control ${
                        passwordValid ? "" : "is-invalid"
                      }`}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text" onClick={oldPsw}>
                        {pswIcon ? EyeOn : EyeOff}
                      </div>
                    </div>
                  </div>
                </div>
                {!passwordValid && (
                  <div className="invalid-feedback">Please Enter Password</div>
                )}
              </fieldset>
              <fieldset className="form-group" id="__BVID__53">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <label htmlFor="login-password" className="mb-0">
                      Confirm Password
                    </label>
                  </div>
                  <div className="input-group">
                    <input
                      id="login-password"
                      name="password"
                      type={type1 ? "password" : "text"}
                      required
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      className={`form-control-merge form-control ${
                        confirmPasswordValid ? "" : "is-invalid"
                      }`}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text" onClick={oldCPsw}>
                        {confirmPswIcon ? EyeOn : EyeOff}
                      </div>
                    </div>
                  </div>
                </div>
                {!confirmPasswordValid && (
                  <div className="invalid-feedback">
                    Please Enter Confirm password
                  </div>
                )}
                {!passwordMatch && (
                  <div className="invalid-feedback">
                    Password Does Not Match
                  </div>
                )}
              </fieldset>
              <Button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={load}
              >
                {load && (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Loading...</span>
                  </>
                )}
                {!load ? "Set Password" : null}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserPasswordSetup;
