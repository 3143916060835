import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'

import Swal from 'sweetalert2'
import toast from 'react-hot-toast'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import {
  useSpaceTypesQuery,
  useDiscountCreateMutation,
  useDiscountUpdateMutation
} from '../../Slices/spaceApi'
import { isValidInteger } from '../../utils/validation'

// import '../../app.css'
const DiscountAdd = ({ handleClose, operateId, operateObject }) => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const [input, setInput] = useState({
    code: '',
    validTill: '',
    amount: 0,
    fixedAmount: false,
    maxUsage: 0,
    maxUsagePerCustomer: 0,
    allowedToRegisteredMember: false,
    allowedToNonRegisteredMember: false,
    validOnProducts: false,
    validOnBookings: []
  })
  const [validated, setValidated] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [addDiscount, result] = useDiscountCreateMutation()
  const [updateDiscount, updated] = useDiscountUpdateMutation()
  const { data, isLoading, isError, error } = useSpaceTypesQuery()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [spaces, setSpaces] = useState([])
  const handleMultiChange = selected => {
    setSelectedOptions(selected)
  }
  useEffect(() => {
    setInput(prevInput => ({
      ...prevInput,
      validOnBookings: selectedOptions.map(selected => selected.value)
    }))
  }, [selectedOptions])
  const handleInput = e => {
    setInput(prevInput => ({
      ...prevInput,
      [e.target.name]: e.target.value
    }))
  }
  useEffect(() => {
    // Check if meetings are loaded and there is no error
    if (!isLoading && !isError) {
      // Map the meetings array to the format required by react-select
      const mappedOptions = data.map(({ id, name }) => ({
        value: id,
        label: name
      }))
      // Set the options state
      setSpaces(mappedOptions)
    } else if (isError) {
    }
  }, [data, isLoading, isError])
  const [count, setCount] = useState(1)
  useEffect(() => {
    if (spaces.length !== 0 && input?.validOnBookings.length !== 0 && count) {
      const selectedOpt = input.validOnBookings.map(id => {
        const ind = spaces.findIndex(data => data.value === id)
        return ind !== -1 ? spaces[ind] : null // Check if index is found
      })
      setSelectedOptions(selectedOpt)
      setCount(0)
    }
  }, [input.validOnBookings, spaces])

  useEffect(() => {
    if (operateId) {
      setInput(operateObject)
    }
  }, [operateId, operateObject])
  useEffect(() => {
    if (result?.isSuccess) {
      handleClose()
      toast.success('Discount Added Successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      })
    }
    if (updated?.isSuccess) {
      handleClose()
      toast.success('Discount Updated Successfully', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff'
        }
      })
    }
  }, [result, updated])
  const handleAdd = event => {
    setIsSubmit(true)
    const form = event.currentTarget

    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
    } else {
      setValidated(false)
      !operateId
        ? addDiscount({
            spaceId: spaceId || currentSpaceId,
            input
          })
        : updateDiscount({
            spaceId: spaceId || currentSpaceId,
            id: operateId,
            input
          })
    }
  }

  useEffect(() => {
    setInput(prevInput =>
      !input.validTill
        ? {
            ...prevInput,
            validTill: new Date().toISOString().split('T')[0]
          }
        : { ...prevInput }
    )
  }, [])
  const handleCheckBox = event => {
    setInput(prevState => {
      let name = event.target.name
      return { ...prevState, [name]: !prevState[name] }
    })
  }
  useEffect(() => {
    if (result.isError) {
      const firstError = result.error.data.error
      Swal.fire({
        title: 'Required!',
        icon: 'warning',
        html: firstError,
        confirmButtonText: 'OK'
      })
    }
    if (updated.isError) {
      const firstError = updated.error.data.error
      Swal.fire({
        title: 'Required!',
        icon: 'warning',
        html: firstError,
        confirmButtonText: 'OK'
      })
    }
  }, [result, updated])
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        className='w-10'
      >
        <Modal.Header closeButton className=''>
          <Modal.Title as='h5'>
            {!operateId ? 'Add Discount' : 'Update Discount'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAdd}>
            <Row className='mb-3 mt-3'>
              <Form.Group controlId='Discount'>
                <Form.Label className='mb-2'>
                  <h6>Discount Code</h6>
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  required
                  pattern='[A-Za-z].*'
                  type='text'
                  placeholder='Discount Name'
                  value={input.code}
                  name='code'
                  onChange={handleInput}
                ></Form.Control>

                {validated && !input?.code && (
                  <Form.Control.Feedback type='invalid'>
                    This field is Required*
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row className='mb-3 mt-3'>
              <Form.Group as={Col} md={5} controlId='Discount'>
                <Form.Label className='mb-2'>
                  {!input.fixedAmount ? <h6>Amount %</h6> : <h6>Amount</h6>}{' '}
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  type='number'
                  required
                  min={0}
                  max={!input.fixedAmount && 100}
                  placeholder='Amount'
                  value={input.amount}
                  name='amount'
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type='invalid'>
                    {!input.amount && !isValidInteger(input.amount) ? (
                      'This field is Required'
                    ) : !isValidInteger(input.amount) && input.fixedAmount ? (
                      'Must be a Positive Integer'
                    ) : (input.amount > 100 || input.amount < 0) &&
                      !input.fixedAmount ? (
                      'Must be a valid percentage'
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group as={Col} md={5} controlId='maxUsage'>
                <Form.Label className='mb-2'>
                  <h6>Max Usage</h6>{' '}
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  type='number'
                  required
                  min={0}
                  placeholder='Add Max Usage'
                  value={input.maxUsage}
                  name='maxUsage'
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type='invalid'>
                    {!input.maxUsage && !isValidInteger(input.maxUsage) ? (
                      'This field is Required'
                    ) : !isValidInteger(input.maxUsage) ? (
                      'Must be a Positive Integer'
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Row>
            <Row className='mb-3 mt-3'>
              <Form.Group as={Col} md={5} controlId='Discount'>
                <Form.Label className='mb-2'>
                  <h6>Usage Per Customer</h6>
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  required
                  type='number'
                  min={0}
                  // Set the number of rows to 3
                  placeholder='Add Max Usage'
                  value={input.maxUsagePerCustomer}
                  name='maxUsagePerCustomer'
                  onChange={handleInput}
                ></Form.Control>

                {validated && (
                  <Form.Control.Feedback type='invalid'>
                    {!input.maxUsagePerCustomer &&
                    !isValidInteger(input.maxUsagePerCustomer) ? (
                      'This field is Required'
                    ) : !isValidInteger(input.maxUsagePerCustomer) ? (
                      'Must be a Positive Integer'
                    ) : (
                      <></>
                    )}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group as={Col} md={5} controlId='validTill'>
                <Form.Label className='mb-2'>
                  <h6>Valid Till</h6>
                </Form.Label>
                <Form.Control
                  className='viewonly'
                  required
                  type='date'
                  // Set the number of rows to 3
                  placeholder='Valid Till'
                  value={input.validTill}
                  name='validTill'
                  onChange={handleInput}
                ></Form.Control>
              </Form.Group>
            </Row>
            <Row className='mb-2'>
              <Form.Group md='5' as={Col}>
                <Form.Check
                  type='checkbox'
                  id='custom-checkbox1'
                  className='fs-7'
                  label='Is amount fixed?'
                  name='fixedAmount'
                  checked={input.fixedAmount}
                  onChange={handleCheckBox}
                />
              </Form.Group>
              <Form.Group md='5' as={Col}>
                <Form.Check
                  type='checkbox'
                  id='custom-checkbox2'
                  className='fs-7'
                  label='Is Valid On Products?'
                  name='validOnProducts'
                  checked={input.validOnProducts}
                  onChange={handleCheckBox}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Check
                  type='switch'
                  id='custom-checkbox3'
                  className='fs-7'
                  label='Allowed To Registered Member?'
                  name='allowedToRegisteredMember'
                  checked={input.allowedToRegisteredMember}
                  onChange={handleCheckBox}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Check
                  type='switch'
                  id='custom-checkbox4'
                  className='fs-7'
                  label='Allowed To Non Registered Member?'
                  name='allowedToNonRegisteredMember'
                  checked={input.allowedToNonRegisteredMember}
                  onChange={handleCheckBox}
                />
              </Form.Group>
            </Row>
            <Row className='mt-3'>
              <Form.Label className='mb-2'>
                <h6>Valid On Spaces</h6>
              </Form.Label>
              <Select
                value={selectedOptions}
                onChange={handleMultiChange}
                isDisabled={!spaces}
                options={spaces || [{ value: '0', label: 'loading' }]}
                isMulti
                isClearable
              />
            </Row>
            <Row className='mb-2'></Row>
            {result.isLoading || updated.isLoading ? (
              <>
                <Button className='btn btn-primary mr-3' disabled>
                  Loading...
                  <Spinner
                    animation='border'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                    variant='primary'
                  />
                </Button>
              </>
            ) : (
              <Button className='btn btn-primary mr-3' type='submit'>
                {operateId ? 'Update Discount Code' : 'Add Discount Code'}
              </Button>
            )}{' '}
            <Button className='btn btn-secondary' onClick={handleClose}>
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DiscountAdd
