import React from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../Context/GlobalContext";
const PaymentDetails1 = ({
  serviceList,
  serviceMeeting,
  productList,
  discount,
  data,
  tax,
  rentalFee,
  isOverAllTax,
}) => {
  const { setSettingsKey, currency } = useGlobalContext();

  const services = (serviceList?.[0]?.packageId ? [...serviceList] : []).concat(
    serviceMeeting?.[0]?.packageId ? [...serviceMeeting] : []
  );

  const navigate = useNavigate();
  let { total } = services?.reduce(
    (total, serviceItem) => {
      total.total += +serviceItem.price;
      return total;
    },
    {
      total: 0,
    }
  );
  let { serviceTax } = services?.reduce(
    (serviceTax, serviceItem) => {
      serviceTax.serviceTax += +serviceItem.serviceCharges;
      return serviceTax;
    },
    {
      serviceTax: 0,
    }
  );
  let { rentalCharges } = services?.reduce(
    (rentalCharges, serviceItem) => {
      rentalCharges.rentalCharges += +serviceItem.rentalFee;
      return rentalCharges;
    },
    {
      rentalCharges: 0,
    }
  );

  const serviceListTotal = serviceList?.reduce((total, serviceItem) => {
    // Calculate tax based on taxRateObj
    let taxAmount = 0;
    if (serviceItem?.inclusiveTax) {
      taxAmount = 0;
    } else if (
      serviceItem?.taxRateObj &&
      serviceItem?.taxRateObj?.setOveralTax
    ) {
      const serviceChargesTax =
        (serviceItem.serviceCharges *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.rentalFee *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (
      serviceItem?.taxRateObj &&
      !serviceItem?.taxRateObj?.setOveralTax
    ) {
      const serviceChargesTax =
        (serviceItem.serviceCharges *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.rentalFee * parseFloat(serviceItem.taxRateObj.rentalFee)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (!serviceItem?.taxRateObj && isOverAllTax) {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.serviceCharges * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.rentalFee * tax) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    } else {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.serviceCharges * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.rentalFee * rentalFee) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    }

    // Add tax to the total
    total +=
      parseFloat(serviceItem.price) +
      taxAmount * parseInt(serviceItem?.quantity);

    return total;
  }, 0);
  console.log("Service list total is", serviceListTotal);

  const serviceMeetingTotal = serviceMeeting?.reduce((total, serviceItem) => {
    // Calculate tax based on taxRateObj
    let taxAmount = 0;
    if (serviceItem?.inclusiveTax) {
      taxAmount = 0;
    } else if (
      serviceItem?.taxRateObj &&
      serviceItem?.taxRateObj?.setOveralTax
    ) {
      // Apply tax percentage to serviceCharges and rentalFee
      const serviceChargesTax =
        (serviceItem.serviceCharges *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.rentalFee *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (
      serviceItem?.taxRateObj &&
      !serviceItem?.taxRateObj?.setOveralTax
    ) {
      const serviceChargesTax =
        (serviceItem.serviceCharges *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.rentalFee * parseFloat(serviceItem.taxRateObj.rentalFee)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (!serviceItem?.taxRateObj && isOverAllTax) {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.serviceCharges * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.rentalFee * tax) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    } else {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.serviceCharges * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.rentalFee * rentalFee) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    }

    // Add tax to the total
    total +=
      parseFloat(serviceItem.price) +
      taxAmount * parseInt(serviceItem?.quantity);

    return total;
  }, 0);

  const productListTotal = productList?.reduce((total, serviceItem) => {
    // Calculate tax based on taxRateObj
    let taxAmount = 0;
    if (serviceItem?.taxRateObj && serviceItem?.taxRateObj?.setOveralTax) {
      // Apply tax percentage to serviceCharges and rentalFee
      const serviceChargesTax =
        (serviceItem.price *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;

      taxAmount = serviceChargesTax;
    } else if (
      serviceItem?.taxRateObj &&
      !serviceItem?.taxRateObj?.setOveralTax
    ) {
      const serviceChargesTax =
        (serviceItem.price *
          parseFloat(serviceItem.taxRateObj.serviceCharges)) /
        100;
      const rentalFeeTax =
        (serviceItem.price * parseFloat(serviceItem.taxRateObj.rentalFee)) /
        100;
      taxAmount = serviceChargesTax + rentalFeeTax;
    } else if (!serviceItem?.taxRateObj && isOverAllTax) {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.price * tax) / 100; // Assuming 10% tax rate if not
      taxAmount = defaultServiceChargesTax;
    } else {
      // Apply default tax percentages
      const defaultServiceChargesTax = (serviceItem.price * tax) / 100; // Assuming 10% tax rate if not specified
      const defaultRentalFeeTax = (serviceItem.price * rentalFee) / 100; // Assuming 15% tax rate if not specified
      taxAmount = defaultServiceChargesTax + defaultRentalFeeTax;
    }

    // Add tax to the total
    total += parseFloat(serviceItem.price) + taxAmount;

    return total;
  }, 0);

  const subtotal =
    serviceListTotal + serviceMeetingTotal + productListTotal || 0;

  const discountAmount = (discount / 100) * subtotal;

  const totalAmount = subtotal - discountAmount;
  const addBankDetails = () => {
    setSettingsKey("Information");
    navigate("/setting/accountsetting");
  };

  return (
    <div>
      <h6 className="mb-4"> Pay Bill at Company Account:</h6>
      <table>
        <tbody>
          <tr>
            <td className="pe-4">Total Due:</td>
            <td>
              <span className="fw-medium">
                {" "}
                {/* {(
                  total +
                  (tax / 100) * total +
                  (rentalFee / 100) * total -
                  (discount / 100) * total
                ).toFixed(2) < 0
                  ? 0
                  : (
                      total +
                      (tax / 100) * serviceTax +
                      (rentalFee / 100) * rentalCharges -
                      (discount / 100) * total
                    ).toFixed(2)} */}
                {totalAmount}
              </span>{" "}
              <small className="fw-bold">{currency}</small>
            </td>
          </tr>
          {data?.city && (
            <tr>
              <td className="pe-4">City:</td>
              <td>{data?.city ? data?.city : ""}</td>
            </tr>
          )}

          <tr>
            <td className="pe-3">Bank Name:</td>
            <td>
              {data?.bankName ? (
                data?.bankName
              ) : (
                <div
                  style={{
                    textDecoration: "underline",
                    color: "blue",
                    cursor: "pointer",
                  }}
                  onClick={addBankDetails}
                >
                  Add Bank Details
                </div>
              )}
            </td>
          </tr>
          {data?.branchName && (
            <tr>
              <td className="pe-3">Branch Name:</td>
              <td>{data?.branchName ? data?.branchName : ""}</td>
            </tr>
          )}
          {data?.country && (
            <tr>
              <td className="pe-3">Country:</td>
              <td>{data?.country ? data?.country : ""}</td>
            </tr>
          )}
          {data?.iban && (
            <tr>
              <td className="pe-3">IBAN:</td>
              <td>{data?.iban ? data?.iban : ""}</td>
            </tr>
          )}
          {data?.swiftCode && (
            <tr>
              <td className="pe-3">SWIFT Code:</td>
              <td>{data?.swiftCode ? data?.swiftCode : ""}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentDetails1;
