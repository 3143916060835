import React, { useContext, useEffect, useRef, useState } from 'react'
import Loader from '../../Loader/Loader'

import { formatDate } from '../../../utils/displayDateTime'

import TabularReport from '../TabularReports'
import {
  useAllSpacesQuery,
  useOwnerLocationQuery,
  useBookingMemberReportQuery,
  useSpaceGetQuery
} from '../../../Slices/spaceApi'
import { useSelector } from 'react-redux'
import { formatDateTime } from '../../../utils/formateDateTime'
import dayjs from 'dayjs'
const BookingList = () => {
  const { spaceId, currentSpaceId } = useSelector(item => item.spaceReducer)

  const tableRef = useRef(null)
  // const { mySpace, ownerLocation, allSpaces } = useContext(AppContext)

  const { data: mySpace, spaceLoading } = useSpaceGetQuery({
    spaceId: spaceId || currentSpaceId
  })

  const { data: ownerLocation, isLoading: ownerLocationIsLoading } =
    useOwnerLocationQuery({ spaceId: spaceId || currentSpaceId })
  const { data: allSpaces, isLoading: allSpacesIsLoading } = useAllSpacesQuery({
    spaceId: spaceId || currentSpaceId
  })

  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')

  const [page_size, setPage_size] = useState(10)
  const [location, setLocation] = useState(mySpace?.spaceId || null)
  const [page, setPage] = useState(1)
  const [list, setList] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [options, setOptions] = useState([])
  const { data, isLoading, refetch, isFetching } = useBookingMemberReportQuery({
    spaceId: spaceId || currentSpaceId,
    start,
    end,
    page_size,
    page: page,
    location: location
  })
  useEffect(() => {
    if ((data, !isLoading)) {
      setList(data.bookings)
    }
  }, [data])
  useEffect(() => {}, [data, isLoading, list])

  // useEffect(() => {
  //   refetch()
  // }, [selectedValue])
  const body = isFetching ? (
    <Loader />
  ) : (
    <div className='table-responsive'>
      <table ref={tableRef} className='table  '>
        <thead>
          <tr>
            <th>#</th>
            <th>Member Name</th>
            <th>Hours</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody className='table-border-bottom-0'>
          {!isLoading && data && list.length > 0 ? (
            list.map((data, index) => (
              <tr key={index}>
                <td>{data.id}</td>
                <td>{data.member.name}</td>
                <td>{data.hoursUsed.toFixed(1)}</td>
                {/* Assuming hoursUsed is a number that can be fractional */}
                <td>
                  {formatDateTime(
                    dayjs
                      .unix(data.startTime)
                      .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                  )}
                </td>
                <td>
                  {' '}
                  {formatDateTime(
                    dayjs.unix(data.endTime).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                  )}
                </td>
                <td>
                  {data.cancel ? (
                    <>
                      <div className='badge bg-label-danger badge badge-fixed-width '>
                        Canceled
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='badge bg-label-success badge-fixed-width '>
                        Active
                      </div>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : list.length === 0 ? (
            <tr>
              <td>No Data</td>
            </tr>
          ) : (
            <tr>
              <td>Data is Loading</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
  return (
    <>
      {data && !isLoading && list ? (
        <TabularReport
          key={'bookingByWeekDays'}
          Child={() => body}
          data={data}
          isLoading={isLoading}
          mySpace={mySpace}
          allSpaces={allSpaces}
          tableRef={tableRef}
          list={list}
          setList={setList}
          ownerLocation={ownerLocation}
          page={page}
          setPage={setPage}
          page_size={page_size}
          setPage_size={setPage_size}
          location={location}
          setLocation={setLocation}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
          refetch={refetch}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          options={options}
          setOptions={setOptions}
        />
      ) : (
        <Loader></Loader>
      )}
    </>
  )
}

export default BookingList
