import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  useGetAllResourcesQuery,
  useUpdateMeetingRoomKisiGroupsMutation,
} from "../../Slices/spaceApi";
import Loader from "../Loader/Loader";
import { requiredAlert } from "../../utils/Alerts";
import toast from "react-hot-toast";

const KisiAssignableResource = ({ KisiGroups }) => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [updateMeetignRoomGroup, resultStats] =
    useUpdateMeetingRoomKisiGroupsMutation();
  const [entries, setEntries] = useState([]);
  const { data: allResources, isLoading: resourceLoading } =
    useGetAllResourcesQuery({
      spaceId: spaceId || currentSpaceId,
    });

  useEffect(() => {
    if (allResources && KisiGroups) {
      const prepopulatedEntries = allResources?.meetingRooms
        ?.filter(
          (resource) =>
            !resource.bookable && resource.kisiAccessGroups?.length > 0
        )
        .map((resource) => ({
          resource: {
            value: resource.id,
            label: resource.title,
          },
          groups: resource.kisiAccessGroups
            .map((groupId) => KisiGroups.find((group) => group.id === groupId))
            .filter(Boolean) // Filter out undefined values in case of unmatched IDs
            .map((group) => ({
              value: group.id,
              label: group.name,
            })),
        }));

      setEntries(prepopulatedEntries || []);
    }
  }, [allResources, KisiGroups]);

  const resourceOptions = allResources?.meetingRooms
    ?.filter((resource) => !resource.bookable)
    ?.map((resource) => ({
      value: resource.id,
      label: resource.title,
    }));

  const groupOptions = KisiGroups?.map((group) => ({
    value: group.id,
    label: group.name,
  }));

  const handleAddRow = () => {
    setEntries([...entries, { resource: null, groups: [] }]);
  };

  const handleRemoveRow = (index) => {
    const newEntries = [...entries];
    newEntries.splice(index, 1);
    setEntries(newEntries);
  };

  const handleResourceChange = (index, selectedResource) => {
    const newEntries = [...entries];
    newEntries[index].resource = selectedResource;
    setEntries(newEntries);
  };

  const handleGroupsChange = (index, selectedGroups) => {
    const newEntries = [...entries];
    newEntries[index].groups = selectedGroups;
    setEntries(newEntries);
  };

  const handleSave = async () => {
    const dataToSave = entries?.map((entry) => ({
      meetingRoom: entry.resource?.value,
      group: entry.groups?.map((group) => group.value),
    }));

    updateMeetignRoomGroup({
      spaceId: spaceId || currentSpaceId,
      data: dataToSave,
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      requiredAlert(resultStats?.error?.data?.error);
    }

    if (resultStats?.isSuccess) {
      toast.success("Resources Updated Successfully.", {
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
    }
  }, [resultStats]);
  if (resourceLoading) {
    return <Loader />;
  }
  return (
    <Container className="mt-3 card p-3">
      {entries?.map((entry, index) => (
        <Row key={index} className="align-items-center mt-3">
          <Col xs={12} md={5}>
            <Form.Group controlId={`resourceSelect-${index}`}>
              <Select
                options={resourceOptions}
                value={entry.resource}
                placeholder="Select assignable resource"
                onChange={(selectedResource) =>
                  handleResourceChange(index, selectedResource)
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={5}>
            <Form.Group controlId={`groupsSelect-${index}`}>
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={groupOptions}
                placeholder="Select access groups"
                value={entry.groups}
                onChange={(selectedGroups) =>
                  handleGroupsChange(index, selectedGroups)
                }
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={2} className="mt-3 mt-md-0">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => handleRemoveRow(index)}
            >
              X
            </span>
          </Col>
        </Row>
      ))}
      <div className="d-flex mt-4">
        <Button className="btn btn-secondary" onClick={handleAddRow}>
          Add Resource
        </Button>
      </div>

      <div className="d-flex justify-content-end mt-4">
        <Button onClick={handleSave}>Save</Button>
      </div>
    </Container>
  );
};

export default KisiAssignableResource;
